import { Grid } from '@/components'
import { Card } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import LandingPortoEducImage from '@/assets/images/landing-porto-educ.png'
import {
  Flex,
  Typography,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import * as S from './styles'

export const PortoEducSection: React.FC = () => {
  return (
    <S.Container>
      <Card sx={{ background: 'var(--porto-primitive-off-white)' }}>
        <S.Grid columns={['1fr', '1fr 1fr']} gap="40px">
          <S.Image src={LandingPortoEducImage} alt="porto educ banner" />
          <Flex
            sx={{
              flexDirection: 'column',
              gap: '16px',
            }}
          >
            <Typography as="h3" variant="porto-title-3-bold">
              Quer vender mais com o Portal das Imobiliárias?
            </Typography>
            <Typography as="p" variant="porto-text-body-2-regular">
              Acesse materiais de suporte e trilhas de conhecimento na Porto
              Educ.
            </Typography>
          </Flex>
        </S.Grid>
      </Card>
    </S.Container>
  )
}
