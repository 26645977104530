import CardImage from '@/assets/images/cartao-porto.png'
import {
  Flex,
  Typography,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Card } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import Choice from '../Forms/Choice'
import * as S from './styles'

export const CardCartaoPorto: React.FC<{ checkboxName?: string }> = ({
  checkboxName = 'send_sms_cartao_porto',
}) => {
  return (
    <Card
      sx={{
        border: 0,
        background: 'var(--neutras-off-white, #EBF1EC)',
      }}
    >
      <Flex
        sx={{
          alignItems: 'flex-start',
          gap: '16px',
        }}
      >
        <Flex sx={{ flexDirection: 'column', gap: '8px' }}>
          <Choice
            type="checkbox"
            name={checkboxName}
            label="Indicar cliente pré-aprovado para o cartão de crédito Porto Bank."
          />
          <Typography variant="porto-text-body-2-regular">
            O cliente receberá um e-mail com o convite exclusivo para aquisição
            do Cartão Porto Bank.
          </Typography>
          <Typography
            variant="porto-text-caption-regular"
            color="neutras-black-70"
          >
            Sujeito à análise de crédito.
          </Typography>
        </Flex>
        <S.Image src={CardImage} />
      </Flex>
    </Card>
  )
}
