import { Card, Tag } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { LazyImage } from '../Utilities'
import Button from '../Actions/Button'
import { ButtonContainer, CardDescription, CardTitle, TagContainer } from './styles'

const NewsCard = (props: INewsCardProps) => {
  const { image, title, description, url, buttonLabel, sx = {}, tag } = props

  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0 8px',
        ...sx,
      }}
    >
      <LazyImage
        style={{ borderRadius: '16px', height: '180px', objectFit: 'cover' }}
        fallbackHeight="180px"
        fallbackWidth="100%"
        src={image}
        alt=""
      />
      <TagContainer>{tag && <Tag color="pink">{tag}</Tag>}</TagContainer>
      <CardTitle variant="porto-title-6-semibold" content={title} />
      <CardDescription variant="porto-text-body-2-regular" content={description} />
      {url && (
        <ButtonContainer to={url}>
          <Button type="button" kind="ghost">
            {buttonLabel}
          </Button>
        </ButtonContainer>
      )}
    </Card>
  )
}

export default NewsCard
