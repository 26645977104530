import { FormikProvider, useFormik } from 'formik'
import { useEffect } from 'react'

import { Forms } from '@/components'
import { useContract } from '@/modules'
import { useGTM } from '@/tools'

import { Action, Actions } from '../../../Details/styles'

import validationSchema from './schema'

const getBudget = ({ contract }) =>
  contract?.budgets.find(budget => budget.id === contract.payload?.payment?.budget)

const Pdf = ({ wizard }) => {
  const contract = useContract()
  const { setDataLayer, setCustomData } = useGTM()

  const form = useFormik({
    initialValues: {
      receiver_policy: contract.payload.contract?.receiver_policy || 'IMOBILIARIA',
    },
    validationSchema,
    onSubmit: policy => {
      contract.update({
        contract: {
          ...contract.payload.contract,
          receiver_policy: policy.receiver_policy,
        },
      })

      setDataLayer({
        event: 'solicitacao-servico',
        nome_servico: 'seguro-fianca',
        tipo_servico: `enviar-apolice:${policy.receiver_policy}`,
        retorno: 'sucesso',
        descricao: 'Sucesso ao selecionar para quem o PDF da apólice deve ser enviado',
      })

      wizard.next()
    },
  })

  useEffect(() => {
    const budget = getBudget({ contract })

    setCustomData({
      page: {
        name: `Portal Imobiliária - Orcamentos - Seguro Incendio - Resumo Envio de Apólice`,
        product: 'aluguel-fianca',
      },
      site: {
        brand: 'portoseguro',
        portal: 'imobiliarias',
      },
      orcamento: {
        protocolo: contract.policy?.proposal,
        tipo: contract.product,
        parceiro_id: contract.partner?.id,
        susep: contract.broker?.id,
        plano: budget?.description,
      },
    })

    setDataLayer({
      event: 'step_change',
      titulo: 'Portal Imobiliária - orcamentos - seguro incendio',
      etapa: '/area-logada/orcamentos/seguro-incendio/resumo-enviar-apolice',
    })
  }, [])

  return (
    <FormikProvider value={form}>
      <form onSubmit={form.handleSubmit}>
        <Forms.SelectGroup
          name="receiver_policy"
          data-gtm-type="select"
          data-gtm-name={form.values.receiver_policy}
          data-gtm-subname="envio-pdf-apolice"
          resetValueOnUnmount={false}
          options={[
            {
              label: 'Segurado e Imobiliária',
              value: 'IMOBILIARIA',
            },
            {
              label: 'Segurado e Corretor',
              value: 'CORRETOR',
            },
            {
              label: 'Todos',
              value: 'TODOS',
            },
          ]}
        />

        <Actions>
          <Action
            data-gtm-type="click"
            data-gtm-clicktype="button"
            data-gtm-name="voltar"
            icon="ArrowLeft"
            onClick={wizard.previous}
            kind="smooth"
          >
            Voltar
          </Action>

          <Action
            data-gtm-type="click"
            data-gtm-clicktype="button"
            data-gtm-name="proxima-etapa"
            icon="ArrowRight"
            onClick={() => form.handleSubmit()}
          >
            Próxima etapa
          </Action>
        </Actions>
      </form>
    </FormikProvider>
  )
}

export default Pdf
