import { RentItem } from '@/services/api/rentCollection'
import Excel from 'exceljs'

interface DataTableItem {
  header: string
  key: keyof RentItem
  width: number
  config: { color: string }
  cellNote: string
  style?: Partial<Excel.Style>
}

export const dataTable: DataTableItem[] = [
  {
    header: 'CPF/CNPJ',
    key: 'document',
    width: 21,
    config: { color: 'BLUE' },
    cellNote: 'PREENCHIMENTO OBRIGATÓRIO, INSIRA APENAS OS NÚMEROS, SEM PONTUAÇÃO E TRAÇOS.',
  },
  {
    header: 'NOME DE REGISTRO DO LOCATARIO',
    key: 'tenant',
    width: 21,
    config: { color: 'BLUE' },
    cellNote: 'PREENCHIMENTO OBRIGATÓRIO.',
  },
  {
    header: 'DDD + CELULAR',
    key: 'phones',
    width: 21,
    config: { color: 'BLUE' },
    cellNote: 'PREENCHIMENTO OBRIGATÓRIO. PARA MAIS DE UM CELULAR, ADICIONE SEPARADO POR VÍRGULA.',
  },
  {
    header: 'CEP',
    key: 'zipcode',
    width: 21,
    config: { color: 'BLUE' },
    cellNote: 'PREENCHIMENTO OBRIGATÓRIO.',
  },
  {
    header: 'ENDEREÇO DO IMOVEL',
    key: 'address',
    width: 21,
    config: { color: 'BLUE' },
    cellNote: 'PREENCHIMENTO OBRIGATÓRIO PARA CEP GENÉRICO.',
  },
  {
    header: 'NUMERO DO IMOVEL',
    key: 'number',
    width: 21,
    config: { color: 'BLUE' },
    cellNote: 'PREENCHIMENTO OBRIGATÓRIO',
  },
  {
    header: 'COMPLEMENTO DO IMOVEL',
    key: 'complement',
    width: 27,
    config: { color: 'BLUE' },
    cellNote: 'PREENCHIMENTO FACULTATIVO.',
  },
  {
    header: 'CIDADE DO IMOVEL',
    key: 'city',
    width: 21,
    config: { color: 'BLUE' },
    cellNote: 'PREENCHIMENTO OBRIGATÓRIO PARA CEP GENÉRICO.',
  },
  {
    header: 'UF',
    key: 'uf',
    width: 3,
    config: { color: 'BLUE' },
    cellNote: 'PREENCHIMENTO OBRIGATÓRIO PARA CEP GENÉRICO.',
  },
  {
    header: 'VENCIMENTO DO ALUGUEL',
    key: 'dueDate',
    width: 27,
    config: { color: 'BLUE' },
    cellNote: 'PREENCHIMENTO OBRIGATÓRIO. FORMATO: dd/mm/aaaa',
    style: { numFmt: 'dd/mm/yyyy' },
  },
  {
    header: 'VALOR DOS DEBITOS',
    key: 'debits',
    width: 21,
    config: { color: 'BLUE' },
    cellNote: 'PREENCHIMENTO OBRIGATÓRIO.',
    style: { numFmt: 'R$#,##0.00' },
  },
]

if (
  !!process.env.REACT_APP_ENABLE_SOCIAL_NAME &&
  !!process.env.REACT_APP_ENABLE_SOCIAL_NAME_ASSESSORIA_DE_COBRANCA
) {
  dataTable.splice(2, 0, {
    header: 'NOME SOCIAL DO LOCATARIO',
    key: 'tenantSocialName',
    width: 21,
    config: { color: 'BLUE' },
    cellNote: 'PREENCHIMENTO FACULTATIVO.',
  })
}

export const colors = {
  BLUE: {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FF0A0047' },
    bgColor: { argb: '00000000' },
  },
}
