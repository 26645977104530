import React, { FC, ReactElement } from 'react'
import { Icon, ModalCloseButton, ModalContent, ModalTitle } from './styles'
import { FeatherIcons, Ionicons } from '@/components/Icons'
import { Modals } from '@/components'

interface Props {
  gtmSubname?: string
  onDismiss?: React.MouseEventHandler<HTMLButtonElement>
}

const ModalBase: FC<Props> = ({ children, onDismiss, gtmSubname }): ReactElement => {
  return (
    <Modals.Base>
      <ModalContent>
        <ModalCloseButton onClick={onDismiss} 
        data-gtm-type=	'click'
        data-gtm-clicktype=	'botao'
        data-gtm-name=	"fechar-modal"
        data-gtm-subname=	{gtmSubname}
        >
          <FeatherIcons name="x" />
        </ModalCloseButton>
        <Icon>
          <Ionicons name="happy-outline" />
        </Icon>
        <ModalTitle>Obrigado pela informação!</ModalTitle>
        {children}
      </ModalContent>
    </Modals.Base>
  )
}

export default ModalBase
