import { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { api } from '@/services'
import { formatDate } from '@/tools'
import { useOrganizations } from '@/modules'
import { Conditional, Content as ContentComponent, Table } from '@/components'

import * as S from './styles'

const Listing = () => {
  const history = useHistory()
  const isMounted = useRef(false)

  const { setActiveOrganization } = useOrganizations()

  const [isLoading, setIsLoading] = useState(true)
  const [organizations, setOrganizations] = useState([])

  const { Heading, Loader } = ContentComponent

  const handleOrganizations = useCallback(async () => {
    const data = await api.organizations.get()

    setOrganizations(data)
    setIsLoading(false)
  }, [])

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true

      handleOrganizations()
    }
  }, [handleOrganizations])

  return (
    <S.Container>
      <Heading
        title="Organizações"
        subtitle="Gerencie todas as organizações do sistema."
        actions={[
          {
            icon: 'plus',
            label: 'Cadastrar nova organização',
            onClick: () => history.push('/organizacoes/cadastro'),
          },
        ]}
      />

      <S.Content>
        <Conditional when={isLoading}>
          <Loader message="Aguarde, buscando as organizações." />
        </Conditional>
        <Conditional when={!isLoading}>
          <Table
            searchPlaceholder="Buscar organização"
            emptyMessage="Nenhuma organização encontrada."
            columns={[
              {
                Header: 'Organização',
                accessor: 'organization',
              },
              {
                Header: 'Perfil',
                accessor: 'role',
              },
              {
                Header: 'Criado em',
                accessor: 'createdAt',
                style: {
                  width: 150,
                },
              },
              {
                Header: '',
                accessor: 'actions',
                style: {
                  width: 300,
                  textAlign: 'right',
                },
              },
            ]}
            data={
              organizations && organizations.map(({ id, name, type, created_at }) => ({
                id,
                organization: name,
                role: {
                  BROKER: 'Corretora',
                  REAL_ESTATE: 'Imobiliária',
                }[type],
                createdAt: formatDate(created_at),
                actions: (
                  <S.Actions>
                    <S.Action
                      kind="smooth"
                      onClick={async () => {
                        try {
                          await setActiveOrganization(id)
                          history.replace('/')
                        } catch (e) {}
                      }}
                    >
                      Entrar no ambiente
                    </S.Action>

                    <S.Action
                      kind="ghost"
                      forwardedAs="a"
                      onClick={() => history.push(`/organizacoes/${id}/parceiros`)}
                    >
                      Editar
                    </S.Action>
                  </S.Actions>
                )
              }))
            }
          />
        </Conditional>
      </S.Content>
    </S.Container>
  )
}

export default Listing
