import banner from '@/assets/images/banner-image3.png'
import { SixthSectionContainer } from './styles'
import { Banner } from '@/components'

const SixthSection = () => {
  return (
    <SixthSectionContainer>
      <Banner
        title="Corretores, tragam suas imobiliárias para o Portal"
        subtitle="Ganhe mais tempo e dinheiro com seus parceiros na plataforma."
        button={{ label: 'Convidar imobiliárias parceiras' }}
        image={banner}
        url="https://corretor.portoseguro.com.br/corretoronline/"
      />
    </SixthSectionContainer>
  )
}

export default SixthSection
