import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { rem, rgba } from 'polished'
import theme from 'styled-theming'

import { themes } from '@/styles'

export const Select = styled.select<any>`
  all: unset;

  width: 100%;
  height: 48px;
  padding: 0 16px;
  margin-top: 8px;

  display: flex;
  align-items: center;

  transition: 0.3s;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid ${themes.utilities.border};

  background-color: ${theme('mode', {
    light: '#fff',
    dark: '#1c1f25',
  })};

  font-weight: 600;
  cursor: pointer;
  font-size: ${rem('10px')};
  font-family: ${themes.fonts.text};

  color: ${themes.text.high};
  -webkit-text-fill-color: ${themes.text.high};

  &::placeholder {
    color: ${themes.text.low};
    -webkit-text-fill-color: ${themes.text.low};
  }

  &:focus {
    transition: 0.3s;
    box-shadow: 0 0 0 3px ${(props) => rgba(themes.palletes.primary, 0.12)};
    border-color: ${(props) => themes.palletes.primary};

    ${media.greaterThan('huge')`
      box-shadow: 0 0 0 4px ${(props) => rgba(themes.palletes.primary, 0.12)};
    `}
  }

  ${media.greaterThan('huge')`
    height: 55px;
    font-size: ${rem('13px')};
  `}

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.7;
      cursor: not-allowed;
    `}

  ${(props) =>
    props.error &&
    css`
      border-radius: 4px 4px 0 0;
      border-color: ${rgba(themes.palletes.danger, 0.5)};
      background-color: ${rgba(themes.palletes.danger, 0.02)};

      &:focus {
        border-color: ${themes.palletes.danger};
        background-color: ${rgba(themes.palletes.danger, 0.03)};
        box-shadow: 0 0 0 3px ${(props) => rgba(themes.palletes.danger, 0.1)};

        ${media.greaterThan('huge')`
          box-shadow: 0 0 0 4px ${(props) => rgba(themes.palletes.danger, 0.1)};
        `}
      }
    `}
`
