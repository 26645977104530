import styled from "styled-components"

export const ListItem = styled.li`
  & > * {
    vertical-align: top;
  }
`

export const Image = styled.img`
  aspect-ratio: 1;
  object-fit: cover;

  border-radius: 16px;

  width: 100%;
`