import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { useAuth } from '@/modules'
import * as S from './styles'
import { useIsMobile } from '@/tools'
import parceriaPremiada4 from '@/assets/images/parceria-premiada-4.png'
import parceriaPremiada2 from '@/assets/images/parceria-premiada-2.png'

const ParceriaPremiadaBanner = () => {
  const { isAuthenticated } = useAuth()
  const isMobile = useIsMobile()

  return (
    <S.Container>
      <S.Wrapper>
        <Typography content="ParceriaPremiada" variant="porto-title-3-bold" color="#BFCCEC" />
        {!isAuthenticated && (
          <Typography
            content="Portal das Imobiliárias"
            variant="porto-text-body-1-bold"
            color="#fff"
          />
        )}
        <S.DescriptionWrapper>
          <Typography
            content="Toda a sua produção de "
            variant="porto-text-body-2-regular"
            color="#fff"
          />
          <Typography content="Fiança Locatícia " variant="porto-text-body-2-bold" color="#fff" />
          <Typography content="e de " variant="porto-text-body-2-regular" color="#fff" />
          <Typography
            content="Título de Capitalização "
            variant="porto-text-body-2-bold"
            color="#fff"
          />
          <Typography
            content="pelo Portal das Imobiliárias te dá números da sorte para concorrer a sorteios mensais de "
            variant="porto-text-body-2-regular"
            color="#fff"
          />
          <Typography content="R$ 5 MIL REAIS." variant="porto-text-body-2-bold" color="#fff" />
        </S.DescriptionWrapper>
        {isAuthenticated && (
          <S.Button to="/campanhas/parceria-premiada">
            <Typography
              content="Confira seus números da sorte"
              variant="porto-text-body-1-bold"
              color="system-primitive-blue-500"
            />
          </S.Button>
        )}
      </S.Wrapper>
      <S.Image src={isMobile ? parceriaPremiada2 : parceriaPremiada4} />
    </S.Container>
  )
}

export default ParceriaPremiadaBanner
