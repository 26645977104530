import React, { useEffect } from 'react'
import { useFormik, FormikProvider } from 'formik'

import { IAddressValuesProps } from '@/components/Utilities/Address/interfaces'
import { Content, Grid, Forms, Modals, Address } from '@/components'
import { useDebounce } from '@/tools'

import { Actions, Action } from '../../../Details/styles'
import { useContract } from '@/modules'
import * as schema from '../../schema'

const address = {
  city: '',
  state: '',
  number: '',
  street: '',
  zipcode: '',
  complement: '',
  neighborhood: '',
}

const Addresses = ({ wizard }) => {
  const contract = useContract()

  const form = useFormik({
    initialValues: {
      billing: contract.payload.payment.addresses?.billing || address,
      mailing: contract.payload.payment.addresses?.mailing || address,
    },
    validationSchema: schema.addresses,
    onSubmit: (addresses) => {
      contract.update({
        payment: { ...contract.payload.payment, addresses },
      })
      wizard.next()
    },
  })
  const handleBlurBilling = () => {
    if (!!form.values.billing.zipcode && !form.errors.billing?.zipcode) {
      fetchAddress('billing', form.values.billing.zipcode)
    }
  }

  const handleBlurMailing = () => {
    if (!!form.values.mailing.zipcode && !form.errors.mailing?.zipcode) {
      fetchAddress('mailing', form.values.mailing.zipcode)
    }
  }

  const billingZipcode = useDebounce(form.values.billing?.zipcode)
  const mailingZipcode = useDebounce(form.values.mailing?.zipcode)

  const fetchAddress = async (
    type: 'billing' | 'mailing',
    zipcode?: string,
    initialValues?: IAddressValuesProps
  ) => {
    const address: any = await Modals.Address({
      zipcode,
      initialValues,
    })

    if (!address) return

    for (const [key, value] of Object.entries(address)) {
      form.setFieldValue(`${type}.${key}`, value)
    }
  }

  const resetAddress = (type: 'billing' | 'mailing') => {
    form.setFieldValue(type, address)
  }

  return (
    <FormikProvider value={form}>
      <form onSubmit={form.handleSubmit}>
        <Content.Message title="Atenção" kind="warning">
          Os endereços de <b>cobrança</b> e <b>correspondência</b> não são
          obrigatórios.
        </Content.Message>

        <Grid
          gap="1rem"
          space={['1rem 0', '2rem 0']}
          columns={['repeat(2,1fr)', 'repeat(3,1fr)']}
        >
          <Forms.InputGroup
            name="billing.zipcode"
            label="CEP de cobrança"
            placeholder="Digite o CEP de cobrança"
            mask="zipcode"
            onBlur={handleBlurBilling}
          />

          <Forms.InputGroup
            name="mailing.zipcode"
            label="CEP de correspondência"
            placeholder="Digite o CEP de correspondência"
            mask="zipcode"
            onBlur={handleBlurMailing}
          />
        </Grid>

        <Grid gap="1rem" columns={['auto', 'repeat(2,1fr)']}>
          <Address
            fetchAddress={(initialValues) =>
              fetchAddress('billing', billingZipcode, initialValues)
            }
            {...form.values.billing}
            title="Endereço de cobrança"
            action={{
              text: 'Remover',
              handler: () => resetAddress('billing'),
            }}
          />

          <Address
            fetchAddress={(initialValues) =>
              fetchAddress('mailing', mailingZipcode, initialValues)
            }
            {...form.values.mailing}
            title="Endereço de correspondência"
            action={{
              text: 'Remover',
              handler: () => resetAddress('mailing'),
            }}
          />
        </Grid>

        <Actions>
          <Action icon="ArrowLeft" onClick={wizard.previous} kind="smooth">
            Voltar
          </Action>

          <Action icon="ArrowRight" onClick={() => form.handleSubmit()}>
            Próxima etapa
          </Action>
        </Actions>
      </form>
    </FormikProvider>
  )
}

export default Addresses
