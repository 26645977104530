import React from 'react'

import { Grid, Forms } from '../../../index'

import Base from '../Base'

import { ICards } from './interfaces'

import { Container } from './styles'

const Cards: React.FC<ICards> = (props) => {
  const { InputGroup } = Forms

  return (
    <Base
      icon="CreditCard"
      title="Cartão de crédito"
      description={`Insira abaixo os dados do cartão de<br />crédito e do titular do cartão.`}
      onSubmit={{
        label: 'Adicionar cartão',
        handler: props.onSubmit,
      }}
      onDismiss={{
        label: 'Cancelar',
        handler: props.onDismiss,
      }}
    >
      <Container>
        <Grid columns="1.5fr 1fr" gap="1rem" space={['0 0 1rem', '0 0 2rem']}>
          <InputGroup
            label="Nome impresso no cartão"
            placeholder="Digite aqui o nome"
          />

          <InputGroup
            label="CPF do titular do cartão"
            placeholder="000.000.000-00"
          />
        </Grid>

        <Grid columns="2fr 1fr 1fr" gap="1rem">
          <InputGroup
            label="Número do cartão"
            placeholder="0000 0000 0000 0000"
          />

          <InputGroup label="Validade" placeholder="00/0000" />

          <InputGroup label="CVV" placeholder="000" />
        </Grid>
      </Container>
    </Base>
  )
}

export default Cards
