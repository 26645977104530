import { FC } from 'react'
import { useHistory } from 'react-router-dom'

import { Conditional, Content, Candle, GradientAside } from '@/components'
import {
  EffectMobile,
  Button,
  Footer,
  FormContainer,
  LayoutGrid,
  Logo,
  Message,
} from './styles'
import { ILayoutProps } from './interfaces'
import { dashText } from '@/tools'

const Layout: FC<ILayoutProps> = ({
  children,
  isLoading,
  message,
  isBack,
  isError,
  onSubmit,
}) => {
  const history = useHistory()

  return (
    <LayoutGrid>
      <GradientAside />
      <EffectMobile>
        <Candle />
      </EffectMobile>
      <FormContainer>
        <div>
          <Logo />
        </div>

        <Conditional when={message !== undefined}>
          <Conditional when={isError || isLoading}>
            <Content.Message
              title={message?.title}
              kind={message?.color}
              isLoading={isLoading}
            >
              <Message dangerouslySetInnerHTML={{ __html: message?.message }} />
            </Content.Message>
          </Conditional>
        </Conditional>

        {children}

        <Footer>
          <Conditional when={isBack === undefined ? true : false}>
            <Button
              kind="ghost"
              type="button"
              onClick={() => history.goBack()}
              data-gtm-type="click"
              data-gtm-clicktype="button"
              data-gtm-name="voltar"
            >
              Voltar
            </Button>
          </Conditional>

          <Button
            isLoading={isLoading}
            isDisabled={onSubmit?.isDisabled}
            type="submit"
            onClick={onSubmit?.handler}
            data-gtm-type="click"
            data-gtm-clicktype="button"
            data-gtm-name={dashText(onSubmit?.text)}
          >
            {onSubmit?.text}
          </Button>
        </Footer>
      </FormContainer>
    </LayoutGrid>
  )
}

export default Layout
