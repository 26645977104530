import { useEffect, useState } from 'react'
import { FormikProvider, useFormik } from 'formik'

import { Actions, Forms, Grid, Modals } from '@/components'
import {
  CloseButton,
  Icon,
  Footer,
  Item,
  Header,
  Subtitle,
  Title,
  Options,
} from './styles'
import { SvgIcons } from '@/components/SvgIcons'


import schema from './schema'

namespace FilterBatchs {
  export interface Values {
    status?: string[]
    start?: string
    end?: string
  }

  export type Props = Modals.Promised.PromisedModalProps<FilterBatchs.Values> &
    FilterBatchs.Values

  const Component = (props: FilterBatchs.Props) => {
    const [isChecked, setIsChecked] = useState(true)

    const form = useFormik<FilterBatchs.Values>({
      initialValues: {
        status: props.status || [],
        start: props.start || undefined,
        end: props.end || undefined,
      },
      validationSchema: schema,
      onSubmit: props.onSubmit,
    })
    const selectedCalculateds = () => {
      setIsChecked(!false)
      if (form.values.status.length > 0 && isChecked) {
        form.setFieldValue('status', [])
      }
    }

    return (
      <Modals.Content padding={true} size="medium" onDismiss={props.onDismiss}>
        <FormikProvider value={form}>
          <Header>
            <Icon />
            <Title>Filtros </Title>
            <CloseButton onClick={() => props.onDismiss()}>
              {SvgIcons.closeIcon}
            </CloseButton>
          </Header>
          <Item>
            <Subtitle>Período</Subtitle>
            <Options firstColumn={true}>
              <Grid columns="repeat(2, 1fr)" gap="1rem">
                <Forms.Datepicker
                  name="start"
                  label="Data de envio (inicial)"
                  placeholderText="Digite a data inicial"
                />

                <Forms.Datepicker
                  name="end"
                  label="Data de envio (final)"
                  placeholderText="Digite a data final"
                />
              </Grid>
            </Options>
          </Item>
          <Item>
            <Subtitle>Status</Subtitle>
            <Options firstColumn={false}>
              <Forms.Choice
                type="checkbox"
                name="status"
                value="budget"
                label="Em Processamento"
              />
              <Forms.Choice
                type="checkbox"
                name="status"
                value="policy_partial_emitted"
                label="Concluído parcialmente"
              />
              <Forms.Choice
                type="checkbox"
                name="status"
                value="budget_partial_calculated"
                label="Calculado parcialmente"
              />
              <Forms.Choice
                type="checkbox"
                name="status"
                value="policy_emitted"
                label="Concluído"
              />
              <Forms.Choice
                type="checkbox"
                name="status"
                value="budget_calculated"
                label="Calculado"
              />
              <Forms.Choice
                type="checkbox"
                name="status"
                value="expired_period_term"
                label="Vigência expirada"
              />
              <Forms.Choice
                type="checkbox"
                name="status"
                value="critical"
                label="Crítica"
              />
              <Forms.Choice
                type="checkbox"
                name="status"
                value="failure"
                label="Falha na Integração"
              />
            </Options>
          </Item>

          <Footer>
            <Actions.Button
              onClick={() => {
                form.handleSubmit()
              }}
            >
              Aplicar filtros
            </Actions.Button>
            <Actions.Button kind="simple" onClick={selectedCalculateds}>
              Remover Filtros
            </Actions.Button>
          </Footer>
        </FormikProvider>
      </Modals.Content>
    )
  }

  export const openModal =
    Modals.Promised.createModal<FilterBatchs.Props>(Component)
}

export default FilterBatchs
