import styled, { css } from 'styled-components'
import media from 'styled-media-query'

import { themes } from '@/styles'
import { rem, size } from 'polished'

export const Container = styled.div``

export const Content = styled.div`
  ${media.greaterThan('medium')`
    display: flex;
    align-items: flex-start;
  `}
`

export const List = styled.div`
  border-radius: 8px;
  border: 1px solid ${themes.utilities.border};
`

export const Heading = styled.div`
  padding: 8px 16px;

  display: flex;
  align-items: center;

  cursor: pointer;

  ${media.greaterThan('huge')`
    padding: 16px 24px;
  `}
`

export const Pin = styled.div`
  ${size('8px')}

  margin-right: 8px;

  border-radius: 100%;
  border: 1px solid ${themes.utilities.border};

  ${media.greaterThan('huge')`
    ${size('14px')}
  `}
`

export const Title = styled.p`
  font-weight: 600;
  font-size: ${rem('9px')};
  color: ${themes.text.high};

  ${media.greaterThan('huge')`
    font-size: ${rem('13px')};
  `}
`

export const Body = styled.div`
  padding: 8px 24px 24px;

  ${media.greaterThan('huge')`
    padding: 8px 32px 32px;
  `}
`

export const Item = styled.div<{ active?: boolean }>`
  &:not(:last-of-type) {
    border-bottom: 1px solid ${themes.utilities.border};
  }

  ${({ active }) =>
    active &&
    css`
      ${Pin} {
        background-color: ${themes.palletes.primary};
        box-shadow: 0 0 0 1px ${themes.palletes.primary};
      }
    `}
`

export const Resume = styled.div`
  flex: 1;
  border-radius: 8px;
  border: 1px solid ${themes.utilities.border};

  ${media.greaterThan('medium')`
    margin-left: 8px;
  `}

  ${media.greaterThan('huge')`
    margin-left: 24px;
  `}

  ${media.lessThan('medium')`
    margin-top: 16px;
  `}
`

export const Select = styled.select`
  all: unset;

  padding: 8px 16px;

  cursor: pointer;
  font-weight: 600;
  appearance: menulist;
  box-sizing: border-box;
  font-size: ${rem('10px')};
  color: ${themes.text.high};

  ${media.greaterThan('huge')`
    padding: 16px 24px;

    font-size: ${rem('14px')};
  `}
`

export const Note = {
  List: styled.div``,

  Item: styled.div`
    padding: 8px 16px;

    display: grid;
    grid-template-columns: repeat(2, 1fr);

    font-size: ${rem('9px')};
    color: ${themes.text.higher};
    border-top: 1px solid ${themes.utilities.border};

    ${media.greaterThan('huge')`
      padding: 16px 24px;

      font-size: ${rem('13px')};
    `}
  `,

  Label: styled.p``,

  Value: styled.p`
    justify-self: right;
  `,
}

export const Message = styled.p`
  padding: 16px 24px;
  font-size: ${rem('13px')};
  color: ${themes.text.higher};
  border-top: 1px solid ${themes.utilities.border};

  ${media.lessThan('huge')`
    padding: 16px 14px;

    font-size: ${rem('10px')};
  `}
`
