import { Flex, Typography } from "@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base"
import { Icon } from "@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto"
import { useCurrentOrganization } from "@/modules/auth"

export const InfoCard =  ({
  icon,
  iconColor = 'porto-primitive-black-100',
  background = 'transparent',
  title,
  subtitle,
  legend
}) => {
  const profile = useCurrentOrganization()
  const isBroker = profile.type === 'BROKER'

  const handleSubtitle = subtitle.replace('{percent}', isBroker ? "0,8" : "0,6")

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        gap: '.5rem',
        backgroundColor: background,
        padding: '1rem',
        borderRadius: '8px'
      }}
    >
      <Icon
        name={icon}
        color={iconColor}
      />
      <Typography
        content={title}
        variant="porto-title-6-bold"
      />
      <Typography
        content={handleSubtitle}
        variant="porto-text-body-2-regular"
      />
      <Typography
        content={legend}
        variant="porto-text-caption-regular"
        color='porto-primitive-black-70'
      />
    </Flex>
  )
}