import { useHistory } from "react-router"

import { Box, Flex, Typography } from "@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base"
import { Button, Icon, Notification } from "@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto"

import { Card, OnboardingWrapper } from "./../ui"

const DigitalSignature = ({
  wizard,
  currentStep,
}) => {

  const history = useHistory()
  const handleFinishClick = () => history.go(0)

  return (
    <OnboardingWrapper
      currentStep={currentStep}
      wizard={wizard}
      hideNextButton={true}
      hidePreviousButton={true}
      beforeStepperChildren={(
        <Notification
          icon="circlecheck"
          title="Dados enviados com sucesso!"
          variant="success"
        />
      )}
    >
      <Card>
        <Flex
          sx={{
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Icon
            name="edit"
            size="lg"
            color="porto-banking-primary"
          />
          <Typography
            variant="porto-title-5-semibold"
            content={`
              Agora você e o corretor de seguros só precisam realizar
              a assinatura eletrônica enviados por e-mail!
            `}
          />
          <Typography
            variant="porto-text-body-2-regular"
            content={`
              Um e-mail está sendo enviado para você e outro para o
              corretor de seguros. A assinatura eletrônica é rápida e segura.
              Assine e peça para que o corretor de seguros assine também.
            `}
          />
          <Box
            sx={{
              marginTop: "1rem",
            }}
          >
            <Button
              type="button"
              onClick={handleFinishClick}
            >
              Concluir
            </Button>
          </Box>
        </Flex>
      </Card>
    </OnboardingWrapper>
  )
}

export default DigitalSignature
