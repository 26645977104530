import dayjs from 'dayjs'

export const canResend = ({ status, payload, metadata }): boolean => {
  const isFailure = (
    status === 'FAILURE' &&
    [
      "@aluguel/integration-failure",
      "@aluguel/pac-failure",
      "@aluguel/pac-analysis-error",
      "@aluguel/pac-status",
    ].includes(metadata?.error?.code)
  )
  const isValidPeriod = dayjs().startOf('day').isSameOrBefore(dayjs(payload?.contract?.periodContractTerm?.start))
  return isFailure && isValidPeriod
}
