import { Tag } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'

import { BUDGET_TEXT_KEYS, STATUS_TYPE } from '../../data/status.data'
import { BudgetStatus, BudgetType } from '../../enums'

export type TagColor =
  | 'white'
  | 'gray'
  | 'blue-seguros'
  | 'blue-bank'
  | 'blue-saude'
  | 'green'
  | 'red'
  | 'yellow'
  | 'violet'
  | 'pink'
export type TagType = 'soft' | 'hard'

interface StatusType {
  color: TagColor
  type: TagType
}

type StatusProps = {
  product: BudgetType
  status: BudgetStatus
}

export default function Status({ product, status }: StatusProps) {
  const statusTextKeys = BUDGET_TEXT_KEYS[product]
  const { color, type } =
    STATUS_TYPE[status] || ({ color: 'gray', type: 'soft' } as StatusType)

  return (
    <Tag color={color} type={type} size="small">
      {statusTextKeys[status]}
    </Tag>
  )
}
