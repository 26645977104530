import dayjs from 'dayjs'
import { FormikProvider, useFormik } from 'formik'
import { useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { Conditional, Content, Grid } from '@/components'
import { useContract } from '@/modules'
import { dashText, useGTM } from '@/tools'

import { Action, Actions } from '../../../Details/styles'

import { Products } from '@/screens/Contracts/Products/shared/entities'
import { api } from '@/services'
import { useQuery } from 'react-query'
import Plan from './Plan'
import validationSchema from './schema'
import * as S from './styles'

const getBudget = ({ contract }) =>
  contract?.budgets.find(budget => budget.id === contract.payload?.payment?.budget)

const Plans = ({ wizard }) => {
  const { setDataLayer, setCustomData } = useGTM()

  const contract = useContract<Products.IMOBILIARIA>()
  const history = useHistory()

  const validityInvalid = useMemo(() => {
    const startPeriod = dayjs(contract.payload.contract?.period.start).utc()
    const today = dayjs().utc()

    return startPeriod.isBefore(today, 'day')
  }, [contract.payload.contract?.period.start])

  useQuery(
    ['elegibility', 'verifyElegibility', contract.id],
    () =>
      api.elegibility.verifyElegibilityFromContract({
        contractId: contract.id,
      }),
    {
      enabled: !!contract.id,
      refetchOnWindowFocus: false,
    },
  )

  const form = useFormik({
    initialValues: {
      budget: contract.payload.payment?.budget || '',
    },
    validationSchema,
    onSubmit: plan => {
      contract.update({
        payment: { ...contract.payload.payment, budget: plan.budget },
      })

      const budget = contract?.budgets.find(budget => budget.id === plan.budget)

      setDataLayer({
        event: 'atualizar_carrinho',
        products: [
          {
            id: budget.id,
            name: budget.description,
            brand: 'porto-seguro',
            price: budget.quotation?.prize?.total,
            category: contract.payload.contract.plan,
            variant: budget.coverages.map(c => c.description).join(','),
            valor_parcela: budget.quotation?.prize?.total / 12,
            quantity: 1,
          },
        ],
      })

      wizard.next()
    },
  })

  const DuplicatedContract = {
    broker: contract.broker.id,
    partner: contract.partner.id,
    payload: contract.payload,
  }

  useEffect(() => {
    const budget = getBudget({ contract })

    setCustomData({
      page: {
        name: `Portal Imobiliária - Orcamentos - Seguro Incendio - Resumo Selecionar Cotacao Desejada`,
        product: 'aluguel-fianca',
      },
      site: {
        brand: 'portoseguro',
        portal: 'imobiliarias',
      },
      orcamento: {
        protocolo: contract.policy?.proposal,
        tipo: contract.product,
        parceiro_id: contract.partner?.id,
        susep: contract.broker?.id,
        plano: budget?.description,
      },
    })

    setDataLayer({
      event: 'step_change',
      titulo: 'Portal Imobiliária - orcamentos - seguro incendio',
      etapa: '/area-logada/orcamentos/seguro-incendio/resumo-selecionar-cotacao-desejada',
    })

    setDataLayer({
      event: 'resultado_simulacao',
      tipo_orcamento: `${dashText(budget?.description || 'sem-plano-selecionado')}`,
      retorno: 'sucesso',
      products: contract.budgets.map(b => ({
        id: b.id,
        name: b.description,
        brand: 'porto-seguro',
        price: b.quotation?.prize?.total,
        category: contract.payload.contract.plan,
        variant: b.coverages.map(c => c.description).join(','),
        valor_parcela: b.quotation?.prize?.total / 12,
      })),
    })
  }, [])

  return (
    <FormikProvider value={form}>
      <form onSubmit={form.handleSubmit}>
        <S.Plans>
          {contract.budgets.map(budget => (
            <Plan
              id={budget.id}
              title={budget.description}
              type={budget.flag}
              benefits={budget.benefits}
              coverages={budget.coverages}
              insurance={budget.quotation.prize.total}
              quotation={budget.quotation}
              active={budget.id === form.values.budget}
              onClick={() => form.setFieldValue('budget', budget.id)}
            />
          ))}
        </S.Plans>

        <Conditional when={!!form.errors.budget || validityInvalid}>
          <Grid space="1rem 0 0">
            <Content.Message title="Atenção" kind="danger">
              {form.errors.budget ||
                'A data de vigência do orçamento está desatualizada. Duplique o orçamento e informe uma nova vigência para seguir com a contratação.'}
            </Content.Message>
          </Grid>
        </Conditional>

        <Actions>
          <Conditional when={validityInvalid}>
            <Action
              icon="copy"
              onClick={() => {
                history.push('/contratos/novo/imobiliaria', DuplicatedContract)
              }}
              data-gtm-type="click"
              data-gtm-clicktype="button"
              data-gtm-name="duplicar-orcamento"
            >
              Duplicar Orçamento
            </Action>
          </Conditional>

          <Conditional when={!validityInvalid}>
            <Action
              icon="ArrowRight"
              onClick={() => form.handleSubmit()}
              data-gtm-type="click"
              data-gtm-clicktype="button"
              data-gtm-name="proxima-etapa"
            >
              Próxima etapa
            </Action>
          </Conditional>
        </Actions>
      </form>
    </FormikProvider>
  )
}

export default Plans
