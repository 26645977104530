import React, { useEffect } from 'react'
import { useFormik, FormikProvider } from 'formik'

import { Grid, Conditional, Content } from '@/components'
import { useContract, useCurrentOrganization } from '@/modules'
import { dashText, useGTM } from '@/tools'

import { Actions, Action } from '../../../Details/styles'
import { paymentsSchema } from './schema'

import DebitAccount from './DebitAccount'
import CreditCard from './CreditCard'
import Methods from './Methods'

const getBudget = ({ contract }) => contract?.budgets.find(
  (budget) => budget.id === contract.payload?.payment?.budget
)

const Payments = ({ wizard }) => {

  const contract = useContract()
  const organization = useCurrentOrganization()
  const { setDataLayer, setCustomData } = useGTM()

  useEffect(() => {

    const budget = getBudget({ contract })

    setCustomData({
      page: {
        name: `Portal Imobiliária - Orcamentos - Seguro Incendio - Resumo Formas de Pagamentos`,
        product: 'aluguel-fianca',
      },
      site: {
        brand: 'portoseguro',
        portal: 'imobiliarias',
      },
      orcamento: {
        protocolo: contract.policy?.proposal,
        tipo: contract.product,
        parceiro_id: contract.partner?.id,
        susep: contract.broker?.id,
        plano: budget?.description,
      },
    })

    setDataLayer({
      event: 'step_change',
      titulo: 'Portal Imobiliária - orcamentos - seguro incendio',
      etapa: '/area-logada/orcamentos/seguro-incendio/resumo-formas-de-pagamento',
    })
  }, [])

  const form = useFormik({
    initialValues: {
      method: '',
      installment: '',
      card: {
        number: '',
        expiration_date: '',
      },
      debit: {
        document: '',
        agency: '',
        account: '',
        digit: '',
        bank: '',
      },
    },
    validationSchema: paymentsSchema,
    onSubmit: async (values) => {
      values.debit.document = values.debit?.document.replace(/\.|-|\//g, '')
      const payment = paymentsSchema.cast(values)
      contract.payload.contract.beneficiaries =
        contract.payload.contract.beneficiaries.map((beneficiary) => {
          return {
            ...beneficiary,
            document: beneficiary.document.replace(/\.|-|\//g, ''),
          }
        })

      const { addresses, budget } = contract.payload.payment

      // const newAddress = Object.fromEntries(
      //   Object.entries(addresses).filter(([, value]) => value.zipcode)
      // )

      // const newPayloadPayment = {
      //   ...payment,
      //   ...(Object.keys(newAddress).length ? { addresses: newAddress } : {}),
      // }

      try {

        contract.update({
          payment: { ...payment, budget: budget },
        })

        await contract.sync({
          ...contract.payload,
          payment: { ...payment, budget: budget },
        })

        const order = await contract.order({
          ...contract.payload,
          payment: { ...payment, budget: budget },
        })

        const orderBudget = order.budgets.find((budget) => budget.id === order.payload.payment?.budget)
        const method = orderBudget.payment_methods.find((method) => method.id === order.payload.payment.method)
        const installment = method.installments.find((installment) => installment.id === order.payload.payment.installment)
        const variant = orderBudget.coverages.map((coverage) => dashText(coverage.description))

        console.log('gtm')
        setDataLayer({
          event: "transacao",
          retorno: "sucesso",
          descricao: "proposta transmitida com sucesso",
          ecommerce: {
            purchase: {
              actionField: {
                option: dashText(method.description),
                id: order.id,
                revenue: orderBudget.quotation.prize.total,
                coupon: null,
                products: [
                  {
                    id: order.policy.proposal,
                    name: `Seguro Incêndio | ${orderBudget.description} | ${contract.status}`,
                    brand: "porto-seguro",
                    price: orderBudget.quotation.prize.total,
                    valor_parcela: installment.first,
                    valor_aluguel: undefined, /* não informado no incêndio */
                    category: order.payload.contract.plan,
                    variant: variant.join(', '),
                  }
                ]
              }
            }
          }
        })

        contract.updateAll({
          ...contract,
          policy: order.policy,
          payload: {
            ...contract.payload,
            payment: { ...payment, budget: budget },
          },
        })

      } catch (e) {
        setDataLayer({
          event: "transacao",
          retorno: "erro",
          descricao: "proposta falhou na transmissão",
          erro: {
            codigo: e.response?.status || "erro não mapeado",
            servico: "Transmissão da proposta - Seguro Incêndio",
            mensagem: e.response?.data?.message || "proposta falhou na transmissão"
          }
        })
      }
    },
  })

  const isCreditCardMethod = ['62', '97'].includes(form.values.method)

  const isDebitAccountMethod = ['52', '55'].includes(form.values.method)

  const budgetSelected = contract.budgets.find(({ id }) => contract.payload.payment.budget === id)
  const { payment_methods } = budgetSelected

  const paymentMethods = payment_methods.filter(pm => {

    const brokerSettings = organization.partnerships.find(p => p.partner.id === contract.partner.id)

    if ([
      '62', /* cartão de crédito */
      '97', /* cartão porto seguro */
    ].includes(pm.id)) { return false }
    switch (pm.id) {
      /* boleto à vista */
      case '11': {
        return brokerSettings.settings['porto_imobiliaria.payments.bankSlip.enabled']
      }
      /* fatura mensal s/ entrada */
      case '41': {
        return brokerSettings.settings['porto_imobiliaria.payments.invoiceWithoutDown.enabled']
      }
      /* fatura mensal (1 parcela a vista no boleto) */
      case '81': {
        return brokerSettings.settings['porto_imobiliaria.payments.invoiceWithDown.enabled']
      }
    }
    return []
  })

  return (
    <FormikProvider value={form}>
      <Methods
        payments={paymentMethods}
        onChange={({ installment, method }) => {
          form.setFieldValue('installment', installment)
          form.setFieldValue('method', method)
        }}
      />

      <Conditional when={!!form.values.installment && isCreditCardMethod}>
        <CreditCard />
      </Conditional>

      <Conditional when={!!form.values.installment && isDebitAccountMethod}>
        <DebitAccount />
      </Conditional>

      <Conditional when={!!form.errors.installment}>
        <Grid space="1rem 0 0">
          <Content.Message title="Atenção" kind="warning">
            Por favor escolha a forma de pagamento e a parcela para continuar.
          </Content.Message>
        </Grid>
      </Conditional>

      <Actions>
        <Action
          data-gtm-type="click"
          data-gtm-clicktype="button"
          data-gtm-name="voltar"
          icon="ArrowLeft"
          onClick={wizard.previous}
          kind="smooth"
        >
          Voltar
        </Action>

        <Action
          data-gtm-type="click"
          data-gtm-clicktype="button"
          data-gtm-name="proxima-etapa"
          icon="ArrowRight"
          onClick={() => form.handleSubmit()}
        >
          Transmitir proposta
        </Action>
      </Actions>
    </FormikProvider>
  )
}

export default Payments
