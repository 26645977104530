import React, { FC, ReactElement } from 'react'
import { ModalSectionTitle, ModalSectionDescription } from './styles'

interface Props {
  title?: string
}

const ModalSection: FC<Props> = ({ title, children }): ReactElement => {
  return (
    <ModalSectionDescription>
      {title ? <ModalSectionTitle>{title}</ModalSectionTitle> : null}
      {children}
    </ModalSectionDescription>
  )
}

export default ModalSection
