import styled from 'styled-components'
import media from 'styled-media-query'
import { rem, rgba } from 'polished'
import theme from 'styled-theming'

import { themes } from '@/styles'
import ActionsComponent from '../Actions'

import { Forms } from '../index'

const borderColor = theme('mode', {
  light: '#eeeef7',
  dark: '#26272f',
})

const headBackground = theme('mode', {
  light: '#fafbff',
  dark: '#17181d',
})

export const Container = styled.div`
  border-radius: 12px;
  box-shadow: 0 20px 25px -5px ${rgba('#000', 0.05)};
  background-color: ${themes.page.colors.background};
`

export const Head = styled.div`
  padding: 8px 16px;

  display: flex;
  align-items: center;

  ${media.greaterThan('large')`
    padding: 16px 24px;
  `}

  ${media.greaterThan('huge')`
    padding: 24px 32px;
  `}
`

export const Search = styled(Forms.Input)`
  width: initial;

  background-color: ${headBackground};

  ${media.lessThan('large')`
    height: 36px;
    padding: 8px;
  `}
`

export const Actions = styled.div`
  margin-left: auto;
`

export const Action = styled(ActionsComponent.Button)<any>`
  &:not(:last-of-type) {
    margin-right: 8px;
  }
`

export const Content = styled.table`
  width: 100%;

  text-align: left;
  border-collapse: collapse;

  thead {
    font-size: ${rem('7px')};
    color: ${themes.text.low};
    background-color: ${headBackground};
    border-top: 1px solid ${borderColor};
    border-bottom: 1px solid ${borderColor};

    ${media.greaterThan('large')`
      font-size: ${rem('9px')};
    `}

    ${media.greaterThan('huge')`
      font-size: ${rem('13px')};
    `}

    th {
      padding: 8px 4px;

      ${media.greaterThan('large')`
        padding: 16px 8px;
      `}

      ${media.greaterThan('huge')`
        padding: 24px 16px;
      `}
    }
  }

  tbody {
    font-size: ${rem('8px')};
    color: ${themes.text.high};

    ${media.greaterThan('large')`
      font-size: ${rem('10px')};
    `}

    ${media.greaterThan('huge')`
      font-size: ${rem('14px')};
    `}

    tr {
      &:not(:last-of-type) {
        border-bottom: 1px solid ${themes.utilities.border};
      }

      td {
        padding: 8px;

        ${media.greaterThan('huge')`
          padding: 12px;
        `}
      }
    }
  }

  th,
  td {
    font-weight: normal;

    &:first-of-type {
      padding-left: 16px;

      ${media.greaterThan('large')`
        padding-left: 24px;
      `}

      ${media.greaterThan('huge')`
        padding-left: 32px;
      `}
    }

    &:last-of-type {
      padding-right: 16px;

      ${media.greaterThan('large')`
        padding-right: 24px;
      `}

      ${media.greaterThan('huge')`
        padding-right: 32px;
      `}
    }
  }
`
