/**
 * Hook que retorna quais features estão habilitadas usando condicionais de ambiente, perfil, data e etc.
 */
export function useFeatureEnable() {
  const socialName = !!process.env.REACT_APP_ENABLE_SOCIAL_NAME

  const socialNameImobiliaria =
    !!process.env.REACT_APP_ENABLE_SOCIAL_NAME_IMOBILIARIA
  const socialNameAluguel = !!process.env.REACT_APP_ENABLE_SOCIAL_NAME_ALUGUEL
  const socialNameFiancaUnificada =
    !!process.env.REACT_APP_ENABLE_SOCIAL_NAME_FIANCA_UNIFICADA
  const socialNameAssessoriaDeCobranca =
    !!process.env.REACT_APP_ENABLE_SOCIAL_NAME_ASSESSORIA_DE_COBRANCA
  const socialNameCapitalizacao =
    !!process.env.REACT_APP_ENABLE_SOCIAL_NAME_CAPITALIZACAO

  return {
    /**
     * Controla se o front exibirá as funcionalidades voltadas a nome social de forma geral.
     *
     * Caso `true` exibe as funcionalidades de nome social. Caso `false` esconde a funcionalidade em toda aplicação.
     */
    socialName,

    /**
     * Controla a liberação da captura de nome social por produto
     */
    socialNameProducts: {
      /**
       * Quando `true` ativa a captura de nome social para jornada do **Seguro Incêndio**
       */
      socialNameImobiliaria: socialName && socialNameImobiliaria,
      /**
       * Quando `true` ativa a captura de nome social para jornada do **Fiança Legado**
       */
      socialNameAluguel: socialName && socialNameAluguel,
      /**
       * Quando `true` ativa a captura de nome social para jornada do **Fiança Unificada**
       */
      socialNameFiancaUnificada: socialName && socialNameFiancaUnificada,
      /**
       * Quando `true` ativa a captura de nome social para jornada do **Capitalização**
       */
      socialNameCapitalizacao: socialName && socialNameCapitalizacao,
      /**
       * Quando `true` ativa a captura de nome social para jornada da **Assessoria de cobrança**
       */
      socialNameAssessoriaDeCobranca:
        socialName && socialNameAssessoriaDeCobranca,
    },
  } as const
}
