import React, { useRef } from 'react'
import 'cropperjs/dist/cropper.css'

import { Content } from '../../../Modals'

import { CropProps } from './types'
import * as S from './styles'

const Crop = (props: CropProps) => {
  const cropperRef = useRef(null)

  const handleClop = (event) => {
    event.preventDefault()

    const imageElement = cropperRef?.current
    const cropper = imageElement?.cropper

    cropper.getCroppedCanvas().toBlob(props.onCrop)
  }

  const handleCancel = () => {
    props.onCancel()
  }

  return (
    <Content
      icon="image"
      title="Recortar foto"
      onCancel={{
        text: 'Cancelar',
        onClick: handleCancel,
      }}
      onSubmit={{
        text: 'Recortar foto',
        onClick: handleClop,
      }}
    >
      <S.Container>
        <S.Crop
          src={props.src}
          aspectRatio={1}
          guides={false}
          ref={cropperRef}
          responsive={true}
        />
      </S.Container>
    </Content>
  )
}

export default Crop
