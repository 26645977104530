import React, { ReactNode } from 'react'
import { useLocation } from 'react-router-dom'

import Header from './Header'
import Menu from './Menu'

import { Container, Content } from './styles'

type Props = {
  children: ReactNode
}

export default function Administrator(props: Props) {
  const location = useLocation()

  const showMenuLink = location.pathname !== '/'

  return (
    <Container>
      <Header />

      <Content>
        {showMenuLink && <Menu />}

        {props.children}
      </Content>
    </Container>
  )
}
