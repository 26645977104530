import {
  Flex,
  Typography,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import {
  Button,
  Card,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { BaseCard } from '../BaseCard'
import { IInfoAndActionProps } from './interfaces'
import { useState } from 'react'
import { Base, Content } from '@/components/Modals'
import { Grid, Icons } from '@/components'

export const InfoAndActionSection = ({
  anchor_id,
  title,
  values,
  variant = 'primary',
}: IInfoAndActionProps) => {
  const [showModal, setShowModal] = useState(false)

  const toggleShowPayments = () => {
    setShowModal(!showModal)
  }

  return (
    <BaseCard title={title} variant={variant} id={anchor_id}>
      <Flex
        sx={{
          flexDirection: 'column',
          gap: '1rem',
          alignItems: 'flex-start',
        }}
      >
        <Typography
          content={values.info}
          variant="porto-text-body-1-regular"
          color="porto-primitive-black-75"
        />
        {values.action.type === 'link' && (
          <Button
            as="a"
            href={values.action.url}
            target="_blank"
            icon={values?.action?.icon}
          >
            {values.action.label}
          </Button>
        )}
        {values.action.type === 'modal' && (
          <>
            <Button onClick={toggleShowPayments}>{values.action.label}</Button>

            {showModal && (
              <Content
                onDismiss={toggleShowPayments}
                title={values.action.modalTitle}
              >
                <Grid gap="32px">
                  <Typography as="span" variant="porto-text-body-1-regular">
                    {values.action.modalSubtitle}
                  </Typography>
                  <Grid gap="24px">
                    {values.action.links.map((e) => (
                      <Typography
                        variant="porto-text-body-1-regular"
                        as="a"
                        color='system-text-highlight'
                        href={e.url}
                        target='_blank'
                        sx={{
                          width: 'fit-content',
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px'
                        }}
                      >
                        {e.label} <Icons.FeatherIcons name='chevron-right' />
                      </Typography>
                    ))}
                  </Grid>
                </Grid>
              </Content>
            )}
          </>
        )}
      </Flex>
    </BaseCard>
  )
}
