import * as yup from 'yup'

import { birthdateValidator } from '@/tools/validators'

import organizationSchema from '../../shared/Organization/schema'
import { underAgeValidation } from './utils'
import { isCPF } from 'brazilian-values'
import { BRLMoneyToNumber } from '@/tools'

export const document = yup
  .string()
  .required('Campo CPF é obrigatório.')
  .test('document-test', 'Por favor insira um CPF válido.', value => !!value && isCPF(value))

export const address = yup.object().shape({
  zipcode: yup
    .string()
    .min(9, 'Por favor insira um CEP válido.')
    .required('Campo CEP é obrigatório.'),
  street: yup.string().required('Por favor confirme a rua.'),
  number: yup.string().required('Campo número é obrigatório.'),
  complement: yup.string(),
  neighborhood: yup.string().required('Por favor confirme o bairro.'),
  city: yup.string().required('Por favor confirme a cidade.'),
  state: yup.string().required('Por favor confirme o estado.'),
})

export const suitor = {
  document,
  name: yup.string().required('Campo nome é obrigatório.'),
  email: yup
    .string()
    .email('Por favor insira um e-mail válido.')
    .required('Campo e-mail é obrigatório.'),
  phone: yup
    .string()
    .matches(/\(\d{2,}\) \d{4,}\-\d{4}/g, 'Por favor insira um número válido.')
    .required('Campo telefone/celular é obrigatório.'),
  birthdate: yup
    .string()
    .test('invalid-date', 'Favor informar data de nascimento válida.', birthdateValidator)
    .required('Campo é obrigatório.'),
  emancipated: yup.boolean().when('birthdate', {
    is: value => underAgeValidation(value),
    then: yup
      .boolean()
      .oneOf([true], 'O pretendente é menor de idade e precisa ser emancipado para prosseguir.'),
  }),
  civil_status: yup
    .string()
    .notOneOf([''], 'Selecione o estado civil.')
    .required('Selecione o estado civil.'),
  spouse_document: yup.string().when('civil_status', {
    is: value => value === 'AMASIADO' || value === 'CASADO',
    then: document,
  }),
  employment: yup.object().shape({
    bond: yup
      .string()
      .notOneOf([''], 'Selecione o vínculo empregatício.')
      .required('Selecione o vínculo empregatício.'),
    company: yup.string().when('bond', {
      is: value => ['2', '13', '1', '8'].includes(value),
      then: yup.string().required('Campo nome da empresa é obrigatório.'),
    }),
    phone: yup.string(),
    salary: yup.string().required('Campo salário é obrigatório.'),
    others: yup.string(),
  }),
  nacionality: yup
    .string()
    .required('Campo nacionalidade é obrigatório.')
    .oneOf(['1', '2'], 'Campo nacionalidade é obrigatório'),
  emigrant: yup.bool(),
  residence_country: yup.string().when('emigrant', {
    is: true,
    then: yup
      .string()
      .notOneOf([''], 'Campo país residente é obrigatório.')
      .required('Campo país residente é obrigatório.'),
  }),
  address,
}

export default yup.object().shape({
  ...organizationSchema,

  contract_plan: yup
    .string()
    .oneOf(['basic', 'traditional', 'university'], 'Selecione o plano')
    .required('Selecione o plano.'),

  contract_commission: yup.string().required('Campo Comissão é obrigatório.'),
  contract_period_start: yup
    .string()
    .nullable()
    .required('Campo início de contrato é obrigatório.'),
  contract_period_end: yup.string().nullable().required('Campo fim de contrato é obrigatório.'),

  suitors_main: yup.object().shape({
    ...suitor,
  }),

  estate_address: address,
  estate_type: yup
    .string()
    .notOneOf([''], 'Selecione o tipo de imóvel.')
    .required('Selecione o tipo de imóvel.'),
  estate_reason: yup.string().notOneOf([''], 'Selecione o motivo.').required('Selecione o motivo.'),
  estate_readjustmentIndex: yup
    .string()
    .notOneOf([''], 'Selecione o índice de contrato.')
    .required('Selecione o índice de contrato.'),

  coverages: yup.object().shape({
    rent: yup
      .string()
      .test({
        message: 'Valor de IS deve ser superior a R$ 12.000,00',
        test: function (value) {
          const rentValue = parseFloat(value)

          if (isNaN(rentValue)) return false
          if (rentValue < 12000) return false

          return true
        },
      })
      .required('Cobertura aluguel é obrigatória.')
      .transform(value => `${value ? BRLMoneyToNumber(`${value}`) : ''}`),
  }),
})
