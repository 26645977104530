import { Box, Flex, Typography } from "@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base"
import { Forms } from "@/components"
import { BaseCard } from "../../../../components/BaseCard"
import { useIsMobile } from "@/tools"

const MaxTitleAndRentValue = () => {
  return (
    <Typography
      content="O valor máximo do título é de R$ 30.000. Para valores superiores, será dividido o valor total em títulos iguais, limitados a R$ 30.000 cada."
      variant="porto-text-caption-regular"
      sx={{ color: 'var(--system-primitive-neutral-700)' }}
    />
  )
}

export const TitleAndRent = () => {
  const isMobile = useIsMobile()

  return (
    <BaseCard
      title="Valor do título e aluguel"
      subtitle="Insira no valor total do título a soma dos aluguéis mensais que serão garantidos. Se quiser garantir gastos adicionais como IPTU e condomínio, some ao valor total."
    >
      <Flex
        sx={{
          gap: '1rem',
          flexDirection: { xs: 'column', lg: 'row' },
        }}
      >
        <Forms.InputGroup
          name="contract.total_value"
          label="Valor total do título"
          mask="money"
          placeholder="R$"
          data-test-id="contract-total-value-title"
        />
        {isMobile && <MaxTitleAndRentValue />}
        <Forms.InputGroup
          name="contract.monthly_value"
          label="Valor do aluguel mensal"
          mask="money"
          placeholder="R$"
          data-test-id="monthly-rent-value-contract"
        />
      </Flex>
      {!isMobile && (
        <Box sx={{ marginTop: '1rem' }}>
          <MaxTitleAndRentValue />
        </Box>
      )}
    </BaseCard>
  )
}