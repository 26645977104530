import { Actions, Forms, Grid } from '@/components'
import { FieldArray, FieldArrayRenderProps, useField } from 'formik'
import React, { useEffect } from 'react'

import { SocialNameField } from '@/components/Forms/SocialNameField'
import { api } from '@/services'
import { applyPhoneMask, useDebounce, useFeatureEnable } from '@/tools'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Card } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { isCPF } from 'brazilian-values'
import { useMutation } from 'react-query'
import { useInitialStateForm } from '../form'
import { isValidMobilePhone } from '@brazilian-utils/brazilian-utils'

const CustomerField: React.FC<
  {
    index: number
  } & FieldArrayRenderProps
> = ({ index, push, remove, form }) => {
  const currentCustomer = index + 1
  const { resend } = useInitialStateForm()

  const [field, meta] = useField(`customers[${index}].document`)
  const debouncedDocument = useDebounce(field.value)

  const featureEnable = useFeatureEnable()

  const fetchCustomer = useMutation(async (document: string) => {
    const customer = await api.customers.getByDocument(document)

    const phone = isValidMobilePhone(customer.phone)
      ? { phone: applyPhoneMask(customer.phone) }
      : {}

    const customers = form.values.customers
    customers[index] = {
      ...customers[index],
      name: customer.name || '',
      birthdate: customer.birthdate || '',
      ...phone,
    }

    await form.setFieldValue(`customers`, customers)
  })

  useEffect(() => {
    if (meta.touched && !meta.error && isCPF(debouncedDocument))
      fetchCustomer.mutate(debouncedDocument)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedDocument, meta.touched, meta.error])

  return (
    <>
      <Typography as="span" variant="porto-title-6-medium">
        {!index ? 'Inquilino principal' : `${currentCustomer}º inquilino`}
      </Typography>

      <Grid gap="16px">
        <Grid columns={['1fr', '1fr 1fr', '1fr 2fr']} gap="16px">
          <Forms.InputGroup
            name={`customers[${index}].document`}
            readOnly={resend || fetchCustomer.isLoading}
            loading={fetchCustomer.isLoading}
            resetValueOnUnmount={false}
            label={!index ? 'CPF' : `CPF do ${currentCustomer}º inquilino`}
            placeholder={!index ? 'Digite o CPF' : `Digite o CPF do ${currentCustomer}º inquilino`}
            mask="cpf"
          />
        </Grid>
        <Grid columns={['1fr', '1fr 1fr', '2fr 1fr']} gap="16px">
          <Forms.InputGroup
            name={`customers[${index}].name`}
            label="Nome completo"
            readOnly={resend}
            placeholder="Digite o nome completo"
            resetValueOnUnmount={false}
          />
        </Grid>

        {featureEnable.socialNameProducts.socialNameFiancaUnificada && (
          <SocialNameField
            textFieldName={`customers[${index}].socialName`}
            toggleName={`customers[${index}].hasSocialName`}
            readonly={resend}
          />
        )}

        <Grid columns={['1fr', '1fr 1fr', '1fr 2fr']} gap="16px">
          <Forms.InputGroup
            name={`customers[${index}].phone`}
            label="Celular"
            readOnly={resend}
            placeholder="Digite o celular"
            mask="cellphone"
            resetValueOnUnmount={false}
          />

          <Forms.InputGroup
            name={`customers[${index}].email`}
            label="E-mail"
            readOnly={resend}
            placeholder="Digite o e-mail"
            resetValueOnUnmount={false}
          />

          <Forms.InputGroup
            name={`customers[${index}].birthdate`}
            label="Data de nascimento"
            placeholder="Digite a data de nascimento"
            mask="date"
            readOnly={resend}
            resetValueOnUnmount={false}
          />
        </Grid>
      </Grid>

      <Grid columns={['1fr', 'repeat(2, max-content)']} gap={['32px', '16px']}>
        {!!index && (
          <Actions.Button
            size="block"
            type="button"
            icon="right"
            kind="simple"
            isDisabled={resend}
            onClick={() => remove(index)}
          >
            Remover {index + 1}º inquilino
          </Actions.Button>
        )}

        {index === form.values.customers.length - 1 && (
          <Actions.Button
            size="block"
            type="button"
            kind="ghost"
            onClick={() => push('')}
            isDisabled={!!form.errors.customers || resend}
          >
            Adicionar {form.values.customers.length + 1}º inquilino
          </Actions.Button>
        )}
      </Grid>
    </>
  )
}

const Contract = () => {
  const { resend } = useInitialStateForm()

  return (
    <FieldArray name="customers">
      {helpers => (
        <Card>
          <Grid gap="16px">
            <Typography as="span" variant="porto-title-4-semibold" sx={{ marginBottom: '16px' }}>
              Inquilino(s)
            </Typography>

            {helpers.form.values.customers.map((_, index) => {
              return <CustomerField key={index} index={index} {...helpers} />
            })}

            {resend && (
              <Typography variant="porto-text-caption-regular">
                Funcionalidade disponível em breve
              </Typography>
            )}
          </Grid>
        </Card>
      )}
    </FieldArray>
  )
}

export default Contract
