import { useMemo, useState } from 'react'
import { useFormik, FormikProvider } from 'formik'
import * as yup from 'yup'
import { debounce } from 'lodash'

import {
  Box,
  Flex,
  Typography,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import {
  Button,
  Notification,
  RadioGroup,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'

import { Forms, toast } from '@/components'
import { api } from '@/services'

import { useNewAccount, ActionTypes } from './../context'
import { Link } from '../../components'
import { isEmail } from '@/tools/validators'
import { URLS } from '@/constants'
import { isValidPhone } from '@brazilian-utils/brazilian-utils'

enum ANSWER {
  CUPOLA_SUMMIT_2023 = 'CUPOLA_SUMMIT_2023',
  SUCURSAL = 'SUCURSAL',
  BROKER_RECOMMENDATION = 'BROKER_RECOMMENDATION',
  OTHER = 'OTHER',
}

const ANSWER_OPTIONS = {
  [ANSWER.SUCURSAL]: 'Apresentação na Sucursal',
  [ANSWER.BROKER_RECOMMENDATION]: 'Indicação de Corretor de Seguros',
  [ANSWER.OTHER]: 'Outros',
}

const UserExperienceForm = ({
  onChange,
}: {
  onChange: ({ answer, other_answer }) => void
}) => {
  const [answer, setAnswer] = useState()
  const [otherAnswer, setOtherAnswer] = useState('')

  const showOtherAnswerInput = useMemo(() => {
    return answer === ANSWER.OTHER
  }, [answer])

  const handleAnswerChange = (value) => {
    if (value !== ANSWER.OTHER) {
      setOtherAnswer('')
    }
    setAnswer(value)
    onChange({ answer: value, other_answer: otherAnswer })
  }

  const handleOtherAnswerKeyUp = debounce(function (e) {
    setOtherAnswer(e.target.value)
    onChange({ answer, other_answer: e.target.value })
  }, 500)

  return (
    <>
      <RadioGroup
        onChange={handleAnswerChange}
        titleProps={{
          content: 'Fala pra gente, como você conheceu o Portal?',
        }}
        options={Object.entries(ANSWER_OPTIONS).map(([value, text]) => ({
          value,
          text,
        }))}
      />
      {showOtherAnswerInput && (
        <Forms.InputGroup
          name="other_answer"
          label="Informe como conheceu (opcional)"
          onKeyUp={handleOtherAnswerKeyUp}
        />
      )}
    </>
  )
}

const PersonalData = ({ wizard }) => {
  const [isLoading, setIsLoading] = useState(false)
  const newAccount = useNewAccount()

  const [showExperienceMetadataError, setShowExperienceMetadataError] =
    useState(false)
  const [experienceMetadata, setExperienceMetadata] = useState({
    howDidYouHearAboutUs: {
      answer: undefined,
      other_answer: undefined,
      date: undefined,
    },
  })

  const handleUserExperienceFormChange = (howDidYouHearAboutUs) => {
    setExperienceMetadata({ howDidYouHearAboutUs })
    setShowExperienceMetadataError(false)
  }

  const form = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
    },
    validationSchema: yup.object().shape({
      name: yup.string().required('Campo Nome é obrigatório'),
      email: yup.string().when([], {
        is: () => !newAccount.state.check.hasAccreditation,
        then: yup
          .string()
          .required('Campo E-mail é obrigatório.')
          .test(
            'email-test',
            'Por favor insira um email válido.',
            (value) => !!value && isEmail(value)
          ),
      }),
      phone: yup
        .string()
        .test(
          'is-valid-phone',
          'Por favor insira um número válido',
          (value) => !!value && isValidPhone(value)
        )
        .required('Campo Telefone/celular é obrigatório.'),
    }),
    onSubmit: async ({ name, email, phone }) => {
      try {
        if (!experienceMetadata.howDidYouHearAboutUs.answer) {
          setShowExperienceMetadataError(true)
          return
        }

        setIsLoading(true)

        const partnerLead = {
          document: newAccount.state?.partnerLead.document,
          name,
          email,
          phone: phone.replace(/\D/g, ''),
          experienceMetadata,
        }

        const { email: maskedEmail } =
          await api.users.newAccount.preRegisterPartner(partnerLead)
        newAccount.dispatch({
          type: ActionTypes.UPDATE,
          payload: {
            partnerLead,
            partnerLeadResponse: { maskedEmail },
          },
        })

        wizard.next()
      } catch (e) {
        switch (e?.data.code) {
          case '@partners/email-exists': {
            toast(
              `
                E-mail já cadastrado! Use seu e-mail e sua senha
                para entrar na sua conta ou redefina sua senha.
              `,
              {
                type: 'error',
              }
            )
            break
          }
          default: {
            toast(
              `
                Ocorreu um erro inesperado. Por favor, tente novamente...
              `,
              {
                type: 'error',
              }
            )
          }
        }
      } finally {
        setIsLoading(false)
      }
    },
  })

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        gap: '1rem',
      }}
    >
      <Link label="Voltar" icon="arrowleft" to="/login" />
      <Typography variant="porto-title-3-semibold" content="Crie sua conta" />
      <Typography
        variant="porto-text-body-1-regular"
        content={
          newAccount.state.check.hasAccreditation
            ? `
          Informe seus dados, enviaremos uma mensagem
          para validar seus dados:
        `
            : `
          Informe seus dados, enviaremos uma mensagem
          para validar seu e-mail:
        `
        }
      />
      <FormikProvider value={form}>
        <form onSubmit={form.handleSubmit}>
          <Flex
            sx={{
              flexDirection: 'column',
              gap: '1rem',
            }}
          >
            <Forms.InputGroup
              name="name"
              label="Nome"
              placeholder="Digite seu nome"
            />
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: !newAccount.state.check.hasAccreditation
                  ? 'repeat(2, 1fr)'
                  : 'repeat(1, 1fr)',
                gap: '1rem',
              }}
            >
              {!newAccount.state.check.hasAccreditation && (
                <Forms.InputGroup
                  name="email"
                  label="E-mail"
                  placeholder="nome@email.com.br"
                />
              )}
              <Forms.InputGroup
                name="phone"
                label="Telefone/celular"
                placeholder="(00) 90000-0000"
                type="phone"
                mask="phone"
              />
            </Box>
          </Flex>
          <Flex
            sx={{
              marginTop: '2rem',
              flexDirection: 'column',
              gap: '1rem',
            }}
          >
            <UserExperienceForm onChange={handleUserExperienceFormChange} />
            {showExperienceMetadataError && (
              <Notification
                icon="circleinfo"
                title="Resposta obrigatória"
                variant="attention"
              />
            )}
          </Flex>
          <Box
            sx={{
              marginTop: '1.5rem',
            }}
          >
            <Typography variant="porto-text-caption-regular">
              Ao clicar em “Continuar”, você concorda com os
              <a
                href={
                  newAccount.state.profileType === 'BROKER'
                    ? URLS.USE_TERMS_PDF_BROKER
                    : URLS.USE_TERMS_PDF_REAL_ESTATE
                }
                target="_blank"
              >
                {' '}
                Termos e Condições de Uso{' '}
              </a>{' '}
              e com a nossa
              <a
                href="https://www.portoseguro.com.br/politica-de-privacidade"
                target="_blank"
              >
                {' '}
                Política de Privacidade{' '}
              </a>
              , que requer utilização e tratamento dos dados inseridos, além de
              permissão para receber nosso contato por e-mail.
            </Typography>
          </Box>
          <Box
            sx={{
              marginTop: '1.5rem',
            }}
          >
            <Button
              type="submit"
              size="large"
              isLoading={isLoading}
              isDisabled={isLoading}
            >
              Continuar
            </Button>
          </Box>
        </form>
      </FormikProvider>
    </Flex>
  )
}

export default PersonalData
