import * as yup from 'yup'

export default yup.object().shape({
  password: yup
    .string()
    .min(6, 'Mínimo de 6 caracteres.')
    .required('Campo nova senha é obrigatório.'),
  passwordConfirm: yup
    .string()
    .min(6, 'Mínimo de 6 caracteres.')
    .oneOf([yup.ref('password'), null], 'As senhas não coincidem.')
    .required('Confirme sua nova senha.'),
})
