import { HomeEquityStepComponent } from '..'
import Button from '@/components/Actions/Button'
import {
  Box,
  Flex,
  Typography,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { FormikProvider, useFormik } from 'formik'
import { BaseCard } from '../components'
import { useCurrentOrganization } from '@/modules'
import validationSchema from './validations'
import { PartnershipSusepCombo } from '@/components/PartnershipSusepCombo'
import { PartnershipSusep } from '@/services/api/partnership/types'
import { useState } from 'react'

export const HomeEquityPartnerDefinition = ({ nextStep = () => null }: HomeEquityStepComponent) => {
  const profile = useCurrentOrganization()
  const isBroker = profile.type === 'BROKER'
  const [hasSelected, setHasSelected] = useState(false)

  const getSusepNameFromCurrentOrganization = (susep): string => {
    const partnership = profile.partnerships.find(partnership => partnership.suseps.includes(susep))

    return partnership?.broker?.name || ''
  }

  const form = useFormik({
    initialValues: {
      susep: '',
      partner: '',
      partner_id: '',
    },
    validationSchema,
    onSubmit: values => {
      let susepPartnerData: {
        susep: string
        susep_name: string
        partner_id: string
        partner_name: string
      }

      if (isBroker) {
        susepPartnerData = {
          susep: values.susep,
          susep_name: '',
          partner_id: values.partner_id,
          partner_name: values.partner,
        }
      } else {
        susepPartnerData = {
          susep: values.susep,
          susep_name: getSusepNameFromCurrentOrganization(values.susep),
          partner_id: values.partner_id,
          partner_name: '',
        }
      }

      nextStep(susepPartnerData)
    },
  })

  const shouldShowError = Object.keys(form.touched).length > 0

  return (
    <FormikProvider value={form}>
      <Flex
        sx={{
          flexDirection: 'column',
          gap: '1rem',
          width: '100%',
        }}
      >
        <BaseCard title={isBroker ? 'Imobiliária' : 'Corretora de Seguros'}>
          <Box
            sx={{
              display: 'flex',
              gridTemplateColumns: { xs: '1fr', lg: '1fr' },
              marginTop: '32px',
            }}
          >
            <Typography
              content={
                isBroker
                  ? 'Para começar, selecione a imobiliária parceira para a indicação.'
                  : 'Para começar, selecione a corretora parceira para a indicação.'
              }
              variant="porto-text-body-1-regular"
              sx={{
                color: 'var(--neutras-black-75, #404040)',
                paddingBottom: '16px',
              }}
            />
          </Box>
          <PartnershipSusepCombo
            selectionCallback={(value: PartnershipSusep) => {
              if(value.susep === null){
                return
              }

              form.setValues({
                ...form.values,
                ...{
                  susep: value?.susep ?? '',
                  partner: value?.partner.name ?? '',
                  partner_id: value?.partner.id ?? '',
                },
              })
              
              setHasSelected(true)
            }}
            error={{
              susep: shouldShowError ? form?.errors['susep'] : null,
              partnership: shouldShowError ? form?.errors['partner'] : null,
            }}
            sx={{
              partnership: {
                width: '100%',
              },
              susep: {
                width: '100%',
              },
              box: {},
            }}
            product="any"
          ></PartnershipSusepCombo>
        </BaseCard>
      </Flex>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', lg: 'repeat(2, 1fr)' },
          gap: '1rem',
        }}
      >
        <Button
          onClick={() => form.handleSubmit()}
          isDisabled={!hasSelected}
        >
          Continuar
        </Button>
      </Box>
    </FormikProvider>
  )
}
