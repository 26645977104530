import styled from 'styled-components'
import media from 'styled-media-query'

export const InputsForm = styled.div`
  width: 75%;

  ${media.lessThan('medium')`  
    width: 100%;
  `}
`
