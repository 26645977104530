import { SvgIcons } from '@/components/SvgIcons'
import { Icons } from '..'
import { Conditional } from '../Utilities'
import {
  Container,
  Header,
  Icon,
  Wrapper,
  Content,
  WrapperLink,
  Links,
  LinkRedirect,
} from './styles'

interface CardTitleProps {
  icon: string
  title?: string
  children?: string
  link?: string
  iconLink?: string
  linkLabel?: string
  linkRedirect?: boolean
  onlyMessage?: boolean
}

const CardNotice = ({
  title,
  icon,
  children,
  linkLabel,
  iconLink,
  link,
  linkRedirect,
  onlyMessage,
}: CardTitleProps) => {
  return (
    <Container>
      <Content>
        <Icon onlyMessage={onlyMessage}>{SvgIcons[icon]}</Icon>
        <div>
          <Conditional when={!!title}>
            <Header>{title}</Header>
          </Conditional>
          <Wrapper dangerouslySetInnerHTML={{ __html: children }} />
          <Conditional when={!!linkLabel}>
            <WrapperLink>
              <div>
                <Conditional when={!linkRedirect}>
                  <Links
                    to={link}
                    dangerouslySetInnerHTML={{ __html: linkLabel }}
                  />
                </Conditional>
                <Conditional when={linkRedirect}>
                  <LinkRedirect
                    dangerouslySetInnerHTML={{ __html: linkLabel }}
                  />
                </Conditional>
                <Icons.FeatherIcons name={iconLink || ''} height={16} />
              </div>
            </WrapperLink>
          </Conditional>
        </div>
      </Content>
    </Container>
  )
}

export default CardNotice
