import React from 'react'

type Props = {
  when: boolean
  children: JSX.Element | JSX.Element[]
}

const Conditional = (props: Props) => {
  if (!props.when) return null

  return <>{props.children}</>
}

export default Conditional
