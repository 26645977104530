import Link from './Link'
import Form from './Form'
import Button from './Button'
import Floating from './Floating'

export default {
  Link,
  Form,
  Button,
  Floating,
}
