export function limitCaracter(
  caracter?: string,
  limit?: number,
  label?: string
) {
  if (caracter?.length > limit) {
    return caracter.substr(0, limit) + label
  }
  return caracter
}
