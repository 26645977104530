enum EProduct {
  PORTO_ALUGUEL = 'PORTO_ALUGUEL'
}

interface IBatch {
  broker: string;
  budgets: [];
  partner: string;
  product: EProduct.PORTO_ALUGUEL;
}

interface IINITIAL_STATE {
  batches: IBatch[]
}

export const INITIAL_STATE: IINITIAL_STATE = {
  batches: []
}

export const Types = {
  RESET_BATCHES: 'RESET_BATCHES',
}

export const aluguel = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case Types.RESET_BATCHES:
      return { ...state, ...payload }

    default:
      return state
  }
}

