import * as yup from 'yup'
import { isCPF, isCNPJ } from 'brazilian-values'

const documentValidation = (value: string) => {
  if (value.length <= 14) return isCPF(value)

  return isCNPJ(value)
}

const realEstatePattern = /^[0-9]*$/g
const susepPattern = /\w{5,5}[jfJF]/g

export default yup.object().shape({
  document: yup
    .string()
    .required('Campo CPF/CNPJ é obrigatório.')
    .test(
      'document-test',
      'Por favor insira um CPF/CNPJ válido.',
      (value) => !!value && documentValidation(value)
    ),
  name: yup.string().required('Campo nome é obrigatório.'),
  email: yup
    .string()
    .email('Por favor insira um e-mail válido.')
    .required('Campo e-mail é obrigatório.'),
  type: yup
    .string()
    .oneOf(
      ['BROKER', 'REAL_ESTATE'],
      'Campo perfil da organização obrigatório.'
    )
    .required('Campo perfil da organização obrigatório.'),
  address: yup.object().shape({
    zipcode: yup
      .string()
      .min(9, 'Por favor insira um CEP válido.')
      .required('Campo CEP é obrigatório.'),
    street: yup.string().required('Por favor confirme a rua.'),
    number: yup.string().required('Campo número é obrigatório.'),
    complement: yup.string(),
    neighborhood: yup.string().required('Por favor confirme o bairro.'),
    city: yup.string().required('Por favor confirme a cidade.'),
    state: yup.string().required('Por favor confirme o estado.'),
  }),
  owner: yup.object().shape({
    document: yup
      .string()
      .required('Campo CPF/CNPJ é obrigatório.')
      .test(
        'owner-document-test',
        'Por favor insira um CPF/CNPJ válido.',
        (value) => !!value && documentValidation(value)
      ),
    name: yup.string().required('Campo nome é obrigatório.'),
  }),
  realEstate: yup.mixed().when('type', {
    is: 'REAL_ESTATE',
    then: yup.object().shape({
      code: yup
        .string()
        .required('Campo código da imobiliária é obrigatório.')
        .matches(realEstatePattern, {
          message: 'Por favor insira um código válido.',
        }),
    }),
  }),
  susep: yup.mixed().when('type', {
    is: 'BROKER',
    then: yup.object().shape({
      porto: yup
        .string()
        .required('Campo SUSEP Porto é obrigatório')
        .matches(susepPattern, {
          message: 'Por favor insira uma SUSEP válida.',
        }),
      official: yup.string().required('Campo SUSEP Oficial é obrigatório'),
      extras: yup.array().of(
        yup
          .string()
          .required('Campo SUSEP Extra é obrigatório.')
          .matches(susepPattern, {
            message: 'Por favor insira uma SUSEP válida.',
          })
      ),
    }),
  }),
})
