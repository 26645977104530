import React from 'react'

import { IGridProps } from './interfaces'

import { Container } from './styles'

const Grid: React.FC<IGridProps> = (props) => (
  <Container {...props}>{props.children}</Container>
)

export default Grid
