import React from 'react'

import { Modals } from '@/components'

import ModalBase from '@/screens/FirstAccess/components/ModalBase'
import ModalSection from '@/screens/FirstAccess/components/ModalSection'


const AlreadyRegistered = (props) => {
  return (
    <ModalBase onDismiss={props?.onDismiss}>
      <ModalSection>
        Identificamos que <b>você já possui acesso ao Portal</b>. Volte para a{' '}
        <a href="/">página inicial</a> e realize o seu login. Caso tenha{' '}
        <b> esquecido sua senha</b>, <a href="/esqueci-senha">clique aqui</a>{' '}
        para recuperá-la.
      </ModalSection>
    </ModalBase>
  )
}

const AlreadyRegisteredModal = Modals.Promised.createModal(AlreadyRegistered)

export default AlreadyRegisteredModal
