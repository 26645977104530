import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { rem, size } from 'polished'
import theme from 'styled-theming'
import { Field as FormikField } from 'formik'

import { themes } from '@/styles'

import { FeatherIcons } from '../../Icons'

export const Marker = styled.div`
  ${size('14px')}

  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 8px;

  border: 1px solid
    ${theme('mode', {
      light: '#dbdee8',
      dark: '#393b42',
    })};

  ${media.greaterThan('huge')`
    ${size('18px')}
  `}
`

export const Icon = styled(FeatherIcons)`
  ${size('6px')}

  stroke-width: 4px;

  color: #ffff;

  ${media.greaterThan('huge')`
    ${size('10px')}
  `}
`

export const Label = styled.span`
  font-size: ${rem('14px')};
  color: ${themes.text.high};
  font-family: ${themes.fonts.title};

  ${media.greaterThan('huge')`
    font-size: ${rem('14px')};
  `}
`

export const Container = styled.label`
  display: inline-flex;
  align-items: center;

  cursor: pointer;
  user-select: none;

  &:hover {
    ${Marker} {
      border: 1px solid #2C3DA8;
    }

    ${Label} {
      color: ${themes.text.higher};
    }

    ${Icon} {
      color: #2C3DA8;
    }
  }
`

export const Field = styled(FormikField)`
  display: none;

  &:checked + ${Marker} {
    border-color: ${themes.palletes.primary};
    background-color: ${themes.palletes.primary};
  }

  &:checked + ${Marker} > ${Icon} {
    color: ${themes.utilities.border};
  }

  &:checked + ${Marker} + ${Label} {
    color: ${themes.text.higher};

    &:hover {
      color: ${themes.text.medium};
    }
  }

  ${(props) =>
    props.checked &&
    css`
      &:checked + ${Marker} {
        border-color: ${themes.palletes.primary};
        background-color: ${themes.palletes.primary};
      }

      &:checked + ${Marker} > ${Icon} {
        color: ${themes.utilities.border};
      }

      &:checked + ${Marker} + ${Label} {
        color: ${themes.text.higher};

        &:hover {
          color: ${themes.text.medium};
        }
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      + ${Marker}, + ${Marker} + ${Label} {
        cursor: not-allowed;
      }
    `}

  ${(props) =>
    props.type &&
    {
      checkbox: css`
        + ${Marker} {
          border-radius: 4px;
        }
      `,

      radio: css`
        + ${Marker} {
          border-radius: 100%;
        }
      `,
    }[props.type]}
`
