import { useState } from 'react'
import { useFormik, FormikProvider } from 'formik'
import * as yup from 'yup'

import {
  Box,
  Flex,
  Typography,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Button } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'

import { api } from '@/services'
import { Forms } from '@/components'
import { Link } from '../components'
import { useIsMobile } from '@/tools'
import { isEmail } from '@/tools/validators'

const Form = (props: { onComplete: ({ email: string }) => void }) => {
  const [isLoading, setIsLoading] = useState(false)
  const isMobile = useIsMobile()

  const form = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .required('Campo E-mail é obrigatório.')
        .test(
          'email-test',
          'Por favor insira um email válido.',
          (value) => !!value && isEmail(value)
        ),
    }),
    onSubmit: async ({ email }) => {
      try {
        setIsLoading(true)
        await api.users.auth.resetPassword(email)
        props?.onComplete({ email })
      } catch (error) {
        form.setFieldError('email', 'Este e-mail não está cadastrado.')
      } finally {
        setIsLoading(false)
      }
    },
  })

  return (
    <Flex
      sx={{
        flexDirection: 'column',
      }}
    >
      <Link label="Voltar" icon="arrowleft" to="/login" />
      <Typography
        sx={{ marginTop: '24px' }}
        color="system-primitive-neutral-900"
        variant="porto-title-5-medium"
        content="Esqueci minha senha"
      />
      <Typography
        sx={{ margin: '32px 0 24px' }}
        variant="porto-text-body-1-regular"
        content={`
          Não se preocupe, enviaremos um link para você redefinir sua senha.
          Preencha o e-mail usado para criar sua conta:
        `}
      />
      <FormikProvider value={form}>
        <form onSubmit={form.handleSubmit}>
          <Flex
            sx={{
              flexDirection: 'column',
              gap: '1rem',
            }}
          >
            <Forms.InputGroup
              name="email"
              label="E-mail"
              placeholder="nome@email.com.br"
            />
          </Flex>
          <Box
            sx={{
              marginTop: '32px',
            }}
          >
            <Button
              type="submit"
              size={isMobile ? 'block' : 'large'}
              isLoading={isLoading}
              isDisabled={isLoading}
            >
              Continuar
            </Button>
          </Box>
        </form>
      </FormikProvider>
    </Flex>
  )
}

export default Form
