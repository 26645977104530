import React from 'react'
import { useFormik, FormikProvider } from 'formik'

import { Grid, Content, Conditional } from '@/components'
import { useContract } from '@/modules'

import { Actions, Action } from '../../../Details/styles'
import { paymentsSchema } from '../../schema'

import DebitAccount from './DebitAccount'
import CreditCard from './CreditCard'
import Methods from './Methods'

const Payments = ({ wizard }) => {
  const contract = useContract()
  const [budget] = contract.budgets

  const form = useFormik({
    initialValues: {
      method: '',
      installment: '',
      card: {
        number: '',
        expiration_date: '',
      },
      debit: {
        document: '',
        agency: '',
        account: '',
        digit: '',
        bank: '',
      },
    },
    validationSchema: paymentsSchema,
    onSubmit: async (values) => {
      values.debit.document = values.debit?.document.replace(/\.|-|\//g, '')
      const payment = paymentsSchema.cast(values)

      contract.update({ payment: { budget: budget.id, ...payment } })
      await contract.sync({ payment: { budget: budget.id, ...payment } })
      const order = await contract.order({
        ...contract.payload,
        payment: { budget: budget.id, ...payment },
      })
      contract.updateAll({
        ...contract,
        policy: order.policy,
        payload: {
          ...contract.payload,
          payment: { budget: budget.id, ...payment },
        },
      })
    },
  })

  const isCreditCardMethod = ['62', '97'].includes(form.values.method)

  const isDebitAccountMethod = ['52', '55'].includes(form.values.method)

  return (
    <FormikProvider value={form}>
      <Methods
        payments={budget.payment_methods}
        onChange={({ installment, method }) => {
          form.setFieldValue('installment', installment)
          form.setFieldValue('method', method)
        }}
      />

      <Conditional when={!!form.errors.installment}>
        <Grid space="2rem 0 0">
          <Content.Message title="Atenção" kind="warning">
            Por favor escolha a forma de pagamento e a parcela para continuar.
          </Content.Message>
        </Grid>
      </Conditional>

      <Conditional when={!!form.values.installment && isCreditCardMethod}>
        <CreditCard />
      </Conditional>

      <Conditional when={!!form.values.installment && isDebitAccountMethod}>
        <DebitAccount />
      </Conditional>

      <Actions>
        <Action icon="ArrowLeft" onClick={wizard.previous} kind="ghost">
          Voltar
        </Action>

        <Action icon="ArrowRight" onClick={() => form.handleSubmit()}>
          Transmitir proposta
        </Action>
      </Actions>
    </FormikProvider>
  )
}

export default Payments
