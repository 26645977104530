import styled from 'styled-components'
import media from 'styled-media-query'
import { rem } from 'polished'

export const Row = styled.div`
  display: flex;
  gap: 16px;

  ${media.lessThan('medium')`
    flex-direction: column-reverse;
  `}
`

export const Col = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
`

export const Header = styled.div`
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: max-content max-content;
  gap: 16px;

  ${Col} {
    gap: unset;
  }

  ${media.lessThan('medium')`
    grid-template-columns: 1fr;
  `}
`

export const Container = styled(Col)`
  ${media.lessThan('medium')`
    margin-top: ${rem('-90px')};
  `}
`

export const ChildrenContainer = styled.div`
  width: 100%;
`
