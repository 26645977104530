import { IResultCardLeftInfoProps } from './interfaces'
import { ResultLeftBox } from '../../../styles'
import {
  Box,
  Typography,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'

export const ResultCardLeftInfo = ({
  label,
  value,
}: IResultCardLeftInfoProps) => {
  return (
    <ResultLeftBox>
      <Box
        sx={{
          display: 'grid',
          gridColumn: { xs: '1', lg: '1/3' },
        }}
      >
        <Typography
          content={value}
          variant="porto-title-7-semibold"
          color="porto-primitive-black-85"
        />
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridColumn: { xs: '1', lg: '1/3' },
        }}
      >
        {label && (
          <Typography
            content={label}
            variant="porto-text-label-regular"
            color="porto-primitive-black-70"
          />
        )}
      </Box>
    </ResultLeftBox>
  )
}
