import { FormikProvider, useFormik } from 'formik'
import { useState } from 'react'

import { Forms, Grid, Modals, toast, Tooltip } from '@/components'
import { api } from '@/services'

import Verification from '@/screens/Users/Account/Edit/Tabs/Email/Verification'
import { Container } from './styles'
import validationSchema from './validations'

type Props = Modals.Promised.PromisedModalProps

const EmailChangeModal = (props: Props) => {
  const [isLoading, setLoading] = useState(false)

  const onSubmit = async ({ currentEmail, email, sendConfirmation }) => {
    try {
      setLoading(true)

      await api.instance.v2.put(`/users/${currentEmail}`, {
        email
      },
        {
          headers: {
            'x-debug-level': 2,
            'x-disable-email-confirmation': sendConfirmation ? undefined : 'true',
          },
        })

      if (sendConfirmation) {
        const success = await Verification({ currentEmail, email })

        if (!success) throw new Error('Operation cancelled')
      }

      props.onSubmit({ success: true })

      toast('E-mail alterado com sucesso!.', {
        type: 'success',
      })
    } catch (error) {
      toast('Ocorreu um erro ao alterar o e-mail.', {
        type: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const form = useFormik({
    initialValues: {
      currentEmail: '',
      email: '',
      sendConfirmation: false,
    },
    validationSchema,
    onSubmit,
  })

  return (
    <Modals.Content
      icon="mail-outline"
      title="Alterar e-mail"
      onCancel={{ text: 'Cancelar', onClick: () => props.onDismiss() }}
      onSubmit={{
        text: 'Confirmar alteração',
        onClick: () => form.handleSubmit(),
      }}
      loader={{
        message: 'O e-mail está sendo alterado.',
        status: isLoading,
      }
      }
    >
      <Container>
        <FormikProvider value={form}>
          <form onSubmit={form.handleSubmit}>
            <Grid gap="2rem">
              <Forms.InputGroup
                label="Endereço de e-mail atual"
                type="email"
                placeholder="antigo.email@exemplo.com"
                name="currentEmail"
              />
              <Forms.InputGroup
                label="Novo endereço de e-mail"
                type="email"
                placeholder="novo.email@exemplo.com"
                name="email"
              />
              <Tooltip
                maxWidth={300}
                placement="left"
                content="Ao selecionar essa opção, um e-mail será disparado para o novo e-mail com um PIN, que deverá ser informado logo após a solicitação para que a alteração de e-mail seja concluída."
              >
                <Forms.Toggle
                  name="sendConfirmation"
                  label="Enviar e-mail de confirmação"
                />
              </Tooltip>
            </Grid>
          </form>
        </FormikProvider>
      </Container>
    </Modals.Content>
  )
}

EmailChangeModal.open = Modals.Promised.createModal<Props>(EmailChangeModal)

export default EmailChangeModal

