import { IAccordionCardProps } from './interfaces'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import { GridExpandMoreIcon } from '@mui/x-data-grid'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'

export const AccordionCard = ({
  title,
  expanded,
  children,
}: IAccordionCardProps) => {
  return (
    <Accordion
      sx={{
        backgroundColor: 'var(--porto-primitive-white)',
        border: '1px solid var(--porto-primitive-black-15)',
        width: '100%',
        padding: '.75rem',
        borderRadius: '.75rem !important',
        '&:before': {
          backgroundColor: 'transparent !important',
        },
        boxShadow: 'none',
      }}
      disableGutters
      defaultExpanded={expanded}
    >
      <AccordionSummary
        expandIcon={
          <GridExpandMoreIcon sx={{ color: '#0046C0', fontSize: '35px' }} />
        }
      >
        <Typography
          content={title}
          variant="porto-title-6-semibold"
          color="porto-primitive-black-85"
        />
      </AccordionSummary>

      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  )
}
