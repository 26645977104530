import { useContract } from '@/modules'
import { TextContentGroup } from '../TextContentGroup'

export const InsuranceBroker = () => {
  const contract = useContract()

  return (
    <TextContentGroup
      title="Corretora de Seguros"
      values={[
        [
          { title: "Susep", description: contract?.broker?.id || "-" },
          { title: "Nome", description: contract?.broker?.name || "-" },
          { title: "E-mail", description:contract?.broker?.email || "-" },
        ]
      ]}
    />
  )
}