import styled from 'styled-components'
import Card from '../Card'

export const CardWrapper = styled.div`
  display: flex;
  padding: 32px 32px 32px 64px;
  margin: 0;
  width: 100%;
  align-items: center;
  gap: 124px;
  justify-content: center;

  h1 {
    font-family: 'Porto Roobert';
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 52px;

    color: #5f5f5f;
  }
`
export const CardContainer = styled(Card)`
  display: flex;
  justify-content: center;
  background-color: #ffffff;
`

export const Image = styled.img.attrs(() => ({
  src: '/images/LP_Fianca.png',
  alt: 'Banner Fiança Essencial',
}))`
  cursor: pointer;
  border-radius: 12px;
  object-fit: cover;
  object-position: center;
  display: block;
  width: 100%;
`
