import { FormikProvider, useFormik } from 'formik'

import { Actions, Modals } from '@/components'
import {
  Button,
  ButtonClose,
  ButtonFilter,
  FormWrapper,
  Title,
  TitleHead,
  TitleLabel,
  TypeOrder,
} from './styles'
import { SvgIcons } from '@/components/SvgIcons'

import schema from './schema'
import { useEffect, useState } from 'react'

namespace Filter {
  export interface Values {
    order: string
  }

  export type Props = Modals.Promised.PromisedModalProps<Filter.Values> &
    Filter.Values

  const Component = (props: Filter.Props) => {
    const [selectFilter, setSelectFilter] = useState('')

    const form = useFormik<Filter.Values>({
      initialValues: {
        order: props.order || '',
      },
      validationSchema: schema,
      onSubmit: props.onSubmit,
    })

    const handleOrder = (event) => {
      form.setFieldValue('order', event.target.name)
      setSelectFilter(event.target.name)
    }

    useEffect(() => {
      setSelectFilter(form.values.order)
    }, [selectFilter])

    return (
      <Modals.Content padding={true} size="medium" colorIcon="primary" onDismiss={props.onDismiss}>
        <FormikProvider value={form}>
          <Title>
            <p />
            <TitleLabel>Filtros </TitleLabel>
            <ButtonClose onClick={() => props.onDismiss()}>
              {SvgIcons.closeIcon}
            </ButtonClose>
          </Title>
          <FormWrapper>
            <TitleHead>Ordenar por</TitleHead>
            <TypeOrder>
              <Button
                isActive={selectFilter === ''}
                name=""
                onClick={handleOrder}
              >
                Ordenacao padrao
              </Button>
              <Button
                isActive={selectFilter === 'Dados incorretos'}
                name="Dados incorretos"
                onClick={handleOrder}
              >
                Dados Incorretos
              </Button>
              <Button
                isActive={selectFilter === 'Pronto para cálculo'}
                name="Pronto para cálculo"
                onClick={handleOrder}
              >
                Pronto para cálculo
              </Button>
            </TypeOrder>
          </FormWrapper>
          <ButtonFilter>
            <Actions.Button
              onClick={() => {
                form.handleSubmit()
              }}
            >
              Aplicar filtros
            </Actions.Button>
          </ButtonFilter>
        </FormikProvider>
      </Modals.Content>
    )
  }

  export const openModal = Modals.Promised.createModal<Filter.Props>(Component)
}

export default Filter
