import { generateRangeNumbers } from "@/tools";
import { commission, prolabore } from "../commons";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  'porto_imobiliaria.batch.enabled': {
    type: 'toggle',
    title: 'Orçamento em lote',
    description: 'Habilitar',
  },
  'porto_imobiliaria.batch.comission.percentage': commission(
    generateRangeNumbers(10, 70),
    ({ getValue }) => !getValue('porto_imobiliaria.batch.enabled')
  ),
  'porto_imobiliaria.batch.prolabore': prolabore(
    'porto_imobiliaria.batch.enabled',
    'porto_imobiliaria.batch.prolabore',
    'porto_imobiliaria.batch.comission.percentage',
    false
  )
}
