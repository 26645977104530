import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`
  display: flex;

  flex-direction: column;
  gap: 32px;
`

export const First = styled.div`
  display: flex;

  justify-content: space-between;
  align-items: center;

  ${media.lessThan('medium')`
    flex-direction: column;
    gap: 24px;
    align-items: unset;
  `}
`

export const ButtonsContainer = styled.div`
  display: flex;

  align-items: center;
  gap: 24px;

  ${media.lessThan('medium')`
    justify-content: stretch;
    align-items: unset;
    flex-direction: column;
    gap: 16px;
  `}
`
