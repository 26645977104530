import { useState } from 'react'
import { Wizard, Steps, Step } from 'react-albus'
import { ErrorBoundary } from 'react-error-boundary'

import * as Screens from '@/screens'

import { Heading } from '../Details/styles'
import * as S from './styles'
import steps from './Steps/steps'

const Proposal = () => {
  const [activeSteps] = useState(
    ['plans', 'pdf', 'customers', 'payments'].filter(Boolean)
  )

  return (
    <S.Container>
      <S.Content>
        <Wizard
          render={(wizard: any) => (
            <ErrorBoundary
              fallback={
                <Screens.Errors
                  product="imobiliaria"
                  title="Um problema aconteceu com esta etapa do orçamento."
                  message="Algo inesperado aconteceu!"
                />
              }
            >
              <S.Progress>
                Etapa {wizard.history.index + 1}/{wizard.steps.length}
              </S.Progress>

              <Heading.Wrapper>
                <Heading.Title>
                  <Heading.Icon name={wizard.step.icon} />
                  {wizard.step.title}
                </Heading.Title>
                <Heading.Description>
                  {wizard.step.description}
                </Heading.Description>
              </Heading.Wrapper>

              <Steps>
                {steps
                  .filter(({ id }) => activeSteps.includes(id))
                  .map(({ render, ...step }) => (
                    <Step {...step} render={(wizard) => render({ wizard })} />
                  ))}
              </Steps>
            </ErrorBoundary>
          )}
        />
      </S.Content>
    </S.Container>
  )
}

export default Proposal
