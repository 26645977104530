import * as yup from 'yup'

export default yup.object().shape({
  period: yup.object({
    start: yup.string().required('Campo início de contrato é obrigatório.'),
    end: yup.string().required('Campo fim de contrato é obrigatório.'),
  }),

  periodContractTerm: yup.object({
    start: yup.string().required('Campo início de vigência é obrigatório.'),
    end: yup.string().required('Campo fim de vigência é obrigatório.'),
  }),
})
