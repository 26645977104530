export const products = {
  porto_aluguel: 'Fiança Locatícia',
  porto_imobiliaria: 'Seguro Incêndio',
}

export const categories = {
  residential: 'Residencial',
  commercial: 'Comercial',
  basic: 'Básico',
  traditional: 'Tradicional',
  university: 'Universitário',
  suseps: 'Suseps',
}

export const fields = {
  enabled: 'Status',
  comission: 'Comissão',
  coverages: 'Coberturas',
  prolabore: 'Pró-labore',
  service_clauses: 'Cláusulas de serviço',
  bankSlip: 'Boleto bancário',
  creditCard: 'Cartão de crédito',
  invoiceWithDown: 'Fatura mensal com entrada',
  invoiceWithoutDown: 'Fatura mensal sem entrada',
  portoSeguroCard: 'Cartão de crédito Porto Seguro',
  percentage: 'Porcentagem',
  variable: 'Variável',
}

export const values = {
  fire: 'Incêndio',
  content: 'Conteúdo',
  eletrical_damages: 'Danos elétricos',
  vehicle_impacts: 'Impacto de veículos',
  gale: 'Vendaval',
  civil_responsability: 'Responsabilidade civil',
  rental_loss: 'Perda de aluguel',
  riot: 'Tumúltos',
  rent: 'Aluguel',
  iptu: 'IPTU',
  condominium: 'Condomínio',
  water: 'Água',
  gas: 'Gás',
  electricity: 'Luz',
  property_damages: 'Danos ao imóvel',
  recision_fine: 'Rescisão de contrato',
  external_paint: 'Pintura externa',
  internal_paint: 'Pintura interna',
  479: 'Padrão gratuíto',
  553: 'Intermediário rede referenciada',
  485: 'Intermediário livre escolha',
  480: 'Total rede referenciada',
  548: 'Total livre escolha',
}
