import { generateRangeNumbers } from '@/tools'

const settings = {
  'porto_imobiliaria.payments': [
    {
      heading: 'Boleto',
      fields: {
        'porto_imobiliaria.payments.bankSlip.enabled': {
          type: 'toggle',
          title: 'Habilitar',
        },
      },
    },
    {
      heading: 'Fatura mensal com entrada',
      fields: {
        'porto_imobiliaria.payments.invoiceWithDown.enabled': {
          type: 'toggle',
          title: 'Habilitar',
        },
        'porto_imobiliaria.payments.invoiceWithDown.installments': {
          type: 'select',
          title: 'Número máximo de parcelas',
          disabled: ({ getValue }) =>
            !getValue('porto_imobiliaria.payments.invoiceWithDown.enabled'),
          description: 'Selecione a quantidade máxima de parcelas que será exibida',
          options: generateRangeNumbers(1, 12).map(value => ({
            label: `${value}x`,
            value,
          })),
        },
      },
    },
    {
      heading: 'Fatura mensal sem entrada',
      fields: {
        'porto_imobiliaria.payments.invoiceWithoutDown.enabled': {
          type: 'toggle',
          title: 'Habilitar',
        },
        'porto_imobiliaria.payments.invoiceWithoutDown.installments': {
          type: 'select',
          title: 'Número máximo de parcelas',
          disabled: ({ getValue }) =>
            !getValue('porto_imobiliaria.payments.invoiceWithoutDown.enabled'),
          description: 'Selecione a quantidade máxima de parcelas que será exibida',
          options: generateRangeNumbers(1, 11).map(value => ({
            label: `${value}x`,
            value,
          })),
        },
      },
    },
  ],
}

export default settings
