import { FormikProvider, useFormik } from 'formik'
import { useState } from 'react'
import * as yup from 'yup'

import { isCNPJ, isCPF } from 'brazilian-values'

import {
  Box,
  Flex,
  Typography,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Button } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'

import { Forms, toast } from '@/components'
import Chat from '@/components/Chat'
import { api } from '@/services'
import { useIsMobile } from '@/tools'

import { Link, Logo, NeedHelp } from '../../components'
import { Divider } from '../styles'
import { ActionTypes, useNewAccount } from './../context'

const Document = ({ wizard, hasAccreditationStep }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [openChat, setOpenChat] = useState(false)

  const newAccount = useNewAccount()
  const isMobile = useIsMobile()

  const form = useFormik({
    initialValues: {
      document: '',
    },
    validateOnChange: false,
    validationSchema: yup.object().shape({
      document: yup
        .string()
        .required('Campo obrigatório.')
        .test(
          'document-test',
          'Por favor insira um documento válido.',
          (value) => !!value && (isCPF(value) || isCNPJ(value))
        ),
    }),
    onSubmit: async ({ document: maskedDocument }) => {
      try {
        setIsLoading(true)

        const document = maskedDocument.replace(/\D/g, '')

        const check = await api.users.newAccount.checkPartnerDocument({
          document,
        })
        newAccount.dispatch({
          type: ActionTypes.UPDATE,
          payload: {
            check,
            partnerLead: { document },
          },
        })

        if (check.hasAccount) {
          form.setFieldError('document', 'Este documento já está cadastrado.')
          toast(
            `
              CNPJ/CPF já cadastrado! Use seu e-mail e sua senha
              para entrar na sua conta ou redefina sua senha.
            `,
            {
              type: 'warning',
              delay: 10000,
            }
          )
          return
        }

        if (check.hasAccreditation) {
          return wizard.push(hasAccreditationStep)
        }

        wizard.next()
      } catch (e) {
        toast(
          `
            Ocorreu um erro inesperado. Por favor, tente novamente...
          `,
          {
            type: 'error',
          }
        )
      } finally {
        setIsLoading(false)
      }
    },
  })

  return (
    <Flex
      sx={{
        flexDirection: 'column',
      }}
    >
      <Logo />
      <Link label="Voltar" icon="arrowleft" to="/login" />
      <Typography
        sx={{ marginTop: '1.5rem' }}
        color="system-primitive-neutral-900"
        variant="porto-title-5-medium"
        content="Crie sua conta"
      />
      <Typography
        sx={{ margin: '32px 0 16px' }}
        color="system-primitive-neutral-900"
        variant="porto-text-body-1-regular"
      >
        Primeiro, informe o CNPJ ou o CPF da sua imobiliária ou corretora:
      </Typography>
      <FormikProvider value={form}>
        <form onSubmit={form.handleSubmit}>
          <Flex
            sx={{
              flexDirection: 'column',
              gap: '1rem',
            }}
          >
            <Flex>
              <Forms.InputGroup
                name="document"
                label="CPF/CNPJ"
                placeholder="00.000.000/0000-00"
                mask="document"
                size="block"
              />
            </Flex>
          </Flex>

          <Box
            sx={{
              marginTop: '32px',
            }}
          >
            <Button
              type="submit"
              size={isMobile ? 'block' : 'large'}
              isLoading={isLoading}
              isDisabled={isLoading}
            >
              Continuar
            </Button>
          </Box>
        </form>
      </FormikProvider>
      <Divider />
      <NeedHelp callback={() => setOpenChat(true)} />
      <Chat open={openChat} />
    </Flex>
  )
}

export default Document
