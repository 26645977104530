import { Carousel } from '@/components'
import { FifthSectionCard } from '..'
import { Container, FifthSectionContainer, Subtitle, Title } from './styles'
import { data } from './data'

const FifthSection = () => {
  const renderCarousel = () => (
    <Carousel
      settings={{
        dots: true,
        slidesToShow: 4,
        infinite: false,
        arrows: false,
        adaptiveHeight: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      }}
    >
      {data.map((cardData, index) => (
        <FifthSectionCard
          key={index}
          image={cardData.image}
          label={cardData.label}
          title={cardData.title}
          text={cardData.text}
        />
      ))}
    </Carousel>
  )

  return (
    <FifthSectionContainer>
      <Title
        variant="porto-title-4-medium"
        content="Produtos e serviços disponíveis"
      />
      <Subtitle
        variant="porto-text-body-1-regular"
        content="Contrate produtos e serviços financeiros com facilidade."
      />
      <Container>{renderCarousel()}</Container>
    </FifthSectionContainer>
  )
}

export default FifthSection
