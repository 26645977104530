import styled from 'styled-components'
import media from 'styled-media-query'
import { rem } from 'polished'

import Actions from '../../Actions'

export const Container = styled.div`
  margin-bottom: 24px;

  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  ${media.greaterThan('huge')`
    margin-bottom: 32px;
  `}
`

export const Head = styled.div`
  flex: 1;
`

export const Title = styled.h1`
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.015em;

  margin-bottom: 8px;

  ${media.lessThan('medium')`
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 0.015em;
    margin-bottom: 8px;

  `}
`

export const Subtitle = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #404040;
  letter-spacing: 2%;

  ${media.lessThan('medium')`
    font-size: 16px;
    line-height: 24px;
    letter-spacing:  0.025em;
  `}
`

export const Right = styled.div`
  padding-left: 32px;
`

export const Action = styled(Actions.Button).attrs({
  variant: 'action',
})<any>`
  font-size: ${rem('16px')};

  &:not(:last-of-type) {
    margin-right: 16px;
  }
`
