import { useContract } from '@/modules'
import { Products } from '@/screens/Contracts/Products/shared/entities'
import { formatToCEP } from 'brazilian-values'
import { TextContentGroup } from '../TextContentGroup'

export const Property = () => {
  const contract = useContract<Products.CAPITALIZACAO>()

  return (
    <TextContentGroup
      title="Imóvel"
      values={[
        [{ title: 'CEP', description: formatToCEP(contract.payload?.estate?.address.zipcode) }],
        [
          { title: 'Endereço', description: contract.payload.estate.address.street },
          { title: 'Número', description: contract.payload.estate.address.number },
          {
            title: 'Complemento',
            description: contract.payload.estate.address.complement || 'Sem complemento',
          },
        ],
        [
          { title: 'Bairro', description: contract.payload.estate.address.neighborhood },
          { title: 'Cidade', description: contract.payload.estate.address.city },
          { title: 'Estado', description: contract.payload.estate.address.state },
        ],
      ]}
    />
  )
}
