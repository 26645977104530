import { useCallback, useEffect, useState } from 'react'
import { FormikProps, useFormikContext } from 'formik'
import { debounce } from 'lodash'

import {
  Box,
  Flex,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'

import { Forms, toast } from '@/components'
import { api } from '@/services'
import { uf } from '@/tools'

import { AttributesState } from './../context'
import { isCEP } from 'brazilian-values'

const dotToRef = (v, src) => v.split('.').reduce((o, i) => o[i], src)

const AddressForm = ({ formField }) => {
  const { initialValues, setFieldValue }: FormikProps<AttributesState> =
    useFormikContext()

  const [addressIsResolved, setAddressIsResolved] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const address = dotToRef(formField, initialValues)
    if (!address.zipcode) {
      return
    }
    setAddressIsResolved(true)
  }, [initialValues, formField])

  const fetchAddress = useCallback(
    debounce(async function (zipcode) {
      if (!isCEP(zipcode)) {
        return
      }

      const clearForm = () => {
        setFieldValue(`${formField}.zipcode`, '')
        setFieldValue(`${formField}.street`, '')
        setFieldValue(`${formField}.number`, '')
        setFieldValue(`${formField}.complement`, '')
        setFieldValue(`${formField}.neighborhood`, '')
        setFieldValue(`${formField}.city`, '')
        setFieldValue(`${formField}.state`, '')
      }

      try {
        setIsLoading(true)

        const address = await api.addresses.getByZipcode(zipcode)

        if (!address) {
          clearForm()
          return
        }

        setFieldValue(`${formField}.zipcode`, address.zipcode)
        setFieldValue(`${formField}.street`, address.street)
        setFieldValue(`${formField}.neighborhood`, address.neighborhood)
        setFieldValue(`${formField}.city`, address.city)
        setFieldValue(`${formField}.state`, address.state)
      } catch (error) {
        clearForm()
        toast('CEP informado não foi encontrado.', { type: 'warning' })
      } finally {
        setAddressIsResolved(true)
        setIsLoading(false)
      }
    }, 500),
    []
  )

  const handleZipCodeChange = (e) => {
    setAddressIsResolved(false)
    fetchAddress(e.target.value)
  }

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        gap: '.5rem',
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr',
            md: 'repeat(3, 1fr)',
          },
        }}
      >
        <Forms.InputGroup
          name={`${formField}.zipcode`}
          label="CEP"
          placeholder="00000-000"
          mask="zipcode"
          onKeyUp={(e) => handleZipCodeChange(e)}
          loading={isLoading}
        />
      </Box>
      {addressIsResolved && (
        <>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: {
                xs: '1fr',
                md: 'repeat(3, 1fr)',
              },
              gap: '1rem',
            }}
          >
            <Forms.InputGroup
              name={`${formField}.street`}
              label="Endereço"
              placeholder="Digite o endereço"
            />
            <Forms.InputGroup
              name={`${formField}.number`}
              label="Número"
              type="number"
              placeholder="000"
            />
            <Forms.InputGroup
              name={`${formField}.complement`}
              label="Complemento"
              placeholder="Digite o complemento"
            />
          </Box>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: {
                xs: '1fr',
                md: 'repeat(3, 1fr)',
              },
              gap: '1rem',
            }}
          >
            <Forms.InputGroup
              name={`${formField}.neighborhood`}
              label="Bairro"
              placeholder="Digite o bairro"
            />
            <Forms.InputGroup
              name={`${formField}.city`}
              label="Cidade"
              placeholder="Digite a cidade"
            />
            <Forms.SelectGroup
              name={`${formField}.state`}
              label="Estado"
              options={[
                {
                  label: 'Selecione o estado',
                  value: '',
                },
                ...uf.map((uf) => ({
                  label: uf.name,
                  value: uf.acronym,
                })),
              ]}
            />
          </Box>
        </>
      )}
    </Flex>
  )
}

export default AddressForm
