import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'

import { useContract } from '@/modules'
import { api } from '@/services'
import { Card, Icon } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { formatToPhone } from 'brazilian-values'
import { useMutation } from 'react-query'
import * as S from './styles'
import { Container } from './styles'
import { biometryAlias } from './textData'

export type BiometryStatus = 'pending' | 'done' | 'refused' | 'expired'

interface BiometryCardProps {
  responsible?: {
    name: string
    socialName?: string
    phone: string
  }
  status?: BiometryStatus
}

const donwloadButtonVariantAlias = {
  done: 'success',
  refused: 'danger',
}

const BiometryCard = ({ responsible, status = 'pending' }: BiometryCardProps) => {
  const contract = useContract()

  const downloadMutation = useMutation(async () => {
    if (status === 'done') await api.contracts.print(contract.id, 'APPROVE')

    if (status === 'refused') await api.contracts.print(contract.id, 'REFUSED')
  })

  return (
    <Container>
      <Card>
        <Typography variant="porto-title-4-semibold" content="Biometria" />

        <Typography
          variant="porto-text-body-1-regular"
          color="porto-primitive-black-70"
          content="A biometria facial e a análise de todos os dados informados do cliente garantem maior segurança na contratação."
        />

        <S.StatusCard status={status}>
          <Icon name={biometryAlias[status].iconName || 'circleclose'} />

          <S.InfoItem>
            <Typography
              variant="porto-text-body-1-bold"
              color="porto-primitive-black-100"
              content={biometryAlias[status].title}
            />

            <S.DescriptionContainer>
              {' '}
              <S.TextBody variant="porto-text-body-2-regular" color="porto-primitive-black-100">
                {biometryAlias[status].body}
              </S.TextBody>
              {!!biometryAlias[status].actionLabel && (
                <S.DownloadButton
                  kind="simple"
                  type="button"
                  variant={donwloadButtonVariantAlias[status]}
                  isLoading={downloadMutation.isLoading}
                  onClick={() => downloadMutation.mutate()}
                >
                  {biometryAlias[status].actionLabel}
                </S.DownloadButton>
              )}
            </S.DescriptionContainer>
          </S.InfoItem>
        </S.StatusCard>

        <S.CardNumber>
          <Typography
            variant="porto-title-6-medium"
            content="O link para coleta da biometria foi enviado por SMS para:"
          />
          <S.InfoContainer>
            <Icon name="user" color="porto-banking-primary" />
            <S.InfoItem>
              <Typography variant="porto-text-body-2-bold" content="Nome:" />
              <Typography sx={{ display: 'block' }} variant="porto-text-body-2-regular">
                {responsible?.socialName || responsible?.name}
              </Typography>
            </S.InfoItem>
          </S.InfoContainer>
          <S.InfoContainer>
            <Icon name="smartphone" color="porto-banking-primary" />
            <S.InfoItem>
              <Typography variant="porto-text-body-2-bold" content="Celular:" />
              <Typography sx={{ display: 'block' }} variant="porto-text-body-2-regular">
                {responsible?.phone && formatToPhone(responsible.phone)}
              </Typography>
            </S.InfoItem>
          </S.InfoContainer>
        </S.CardNumber>

        {status === 'pending' && (
          <Typography sx={{ marginTop: '16px' }} variant="porto-text-body-1-regular" as="p">
            O link é reenviado automaticamente via SMS, 3 vezes ao dia, com intervalo de 4 horas,
            pelo prazo de 7 dias. Após esse período o orçamento é cancelado automaticamente e será
            necessário gerar um novo.
            <Typography variant="porto-text-body-1-bold" as="strong">
              Não é possível reenviar o link manualmente.
            </Typography>
          </Typography>
        )}
      </Card>
    </Container>
  )
}

export default BiometryCard
