import styled from 'styled-components'
import media from 'styled-media-query'
import { rgba } from 'polished'

import { Actions as ActionsComponent } from '@/components'
import { themes } from '@/styles'

export const Container = styled.div``

export const Content = styled.div`
  border-radius: 12px;
  box-shadow: 0 20px 25px -5px ${rgba('#000', 0.05)};
  background-color: ${themes.page.colors.background};
`

export const Actions = styled.div`
  display: grid;
  grid-row-gap: 5px;
  grid-column-gap: 10px;
  grid-template-columns: 60% 25%;
  justify-content: right;

  ${media.lessThan('huge')`
    grid-template-columns: 50% 25%;
  `}

  ${media.lessThan('large')`
    grid-template-columns: 1fr;
  `}
`

export const Action = styled(ActionsComponent.Button).attrs({
  size: 'small',
})<any>``
