import { motion } from 'framer-motion'
import { rem, rgba, size } from 'polished'
import styled, { css } from 'styled-components'
import media from 'styled-media-query'

import themes from '@/styles/themes'

import { IMessageProps } from './interfaces'

export const Text = styled.p`
  flex: 1;

  color: ${themes.text.medium};
  font: 600 ${rem('8px')} ${themes.fonts.text};

  ${media.greaterThan('huge')`
    font-size: ${rem('11px')};
  `}
`

export const Container = styled(motion.div)<IMessageProps>`
  padding: 7px 16px 8px;

  display: flex;

  border-radius: 0 0 8px 8px;

  &:before {
    ${size('3px')}

    margin-top: 3px;
    margin-right: 4px;

    display: inline-block;

    /* content: ''; */
    border-radius: 100%;
    background-color: #d4d7e4;

    ${media.greaterThan('huge')`
      ${size('5px')}

      margin-top: 5px;
    `}
  }

  ${props =>
    props.kind &&
    {
      danger: css`
        background-color: ${rgba(themes.palletes.danger, 0.06)};

        &:before {
          background-color: ${themes.palletes.danger};
        }

        ${Text} {
          color: ${themes.palletes.danger};
        }
      `,
    }[props.kind]}
`
