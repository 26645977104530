import { toast } from '@/components'
import { useCurrentUser, useOrganizations } from '@/modules'
import { api } from '@/services'
import { RentItem } from '@/services/api/rentCollection'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Icon, Notification } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { ChangeEvent, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import XLSX from 'xlsx'
import Info from './Info'
import { downloadSheet } from './download-sheet'
import { sheetToRentItemMapper } from './sheet-to-rent-item-mapper'
import {
  Action,
  List,
  ListItem,
  NotificationWrapper,
  SheetContainer,
  SheetDownload,
} from './styles'

const Sheet = ({ isUpdating }: { isUpdating?: boolean }) => {
  const inputRef = useRef(null)
  const history = useHistory()
  const { store: organizationStore } = useOrganizations()
  const { store: currentUserStore } = useCurrentUser()
  const [isLoading, setIsLoading] = useState(false)

  const parseFile = async (file: File): Promise<RentItem[]> => {
    const workbook = XLSX.read(await file?.arrayBuffer(), {
      type: 'buffer',
      cellDates: true,
    })

    const sheet = workbook.Sheets[workbook.SheetNames[0]]

    return XLSX.utils
      .sheet_to_json(sheet, {
        defval: '',
        dateNF: 'dd"/"mm"/"yyyy',
      })
      .map(sheetToRentItemMapper)
  }

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true)

    try {
      const rentItems = await parseFile(event.target.files[0])
      const partner = organizationStore.active.id
      const phone = currentUserStore.phone

      await api.rentCollection.upload({
        partner,
        phone,
        rentItems,
      })

      history.replace('/sinistro/assessoria-de-cobranca', {
        includeSuccessMessage: 'Planilha importada com sucesso!',
      })
    } catch (error) {
      console.error(error)
      toast(
        'Ocorreu um erro. Verifique as regras de preenchimento da planilha e tente novamente!',
        { type: 'error' },
      )
    } finally {
      inputRef.current.value = null
      setIsLoading(false)
    }
  }

  const renderNewContent = () => {
    return (
      <>
        <Typography
          variant="porto-text-body-1-regular"
          color="system-primitive-neutral-900"
          content="1. Baixe a planilha modelo;"
        />
        <SheetDownload onClick={downloadSheet}>
          <Icon size={24} name="downloadfile" />
          <Typography
            variant="porto-text-caption-regular"
            color="system-primitive-neutral-900"
            content="Baixar planilha modelo"
          />
        </SheetDownload>
        <Typography
          variant="porto-text-body-1-regular"
          color="system-primitive-neutral-900"
          content="2. Preencha os dados dos clientes na planilha;"
        />
        <Typography
          variant="porto-text-body-1-regular"
          color="system-primitive-neutral-900"
          content="3. Importe a planilha"
        />
      </>
    )
  }

  const renderUpdatingContent = () => {
    return (
      <>
        <Typography
          variant="porto-text-body-1-regular"
          color="system-primitive-neutral-900"
          content={`Para adicionar ou atualizar o status de diversos clientes ao mesmo tempo, importe\nsua planilha modelo atualizada, considerando os seguintes critérios:`}
        />
        <SheetDownload onClick={downloadSheet}>
          <Icon size={24} name="downloadfile" />
          <Typography
            variant="porto-text-caption-regular"
            color="system-primitive-neutral-900"
            content="Baixar planilha modelo"
          />
        </SheetDownload>
        <List>
          <ListItem>
            <Typography
              variant="porto-text-body-1-regular"
              color="system-primitive-neutral-900"
              Ç
              content="Clientes que foram retirados da planilha, serão considerados com status "
            />
            <Typography
              variant="porto-text-body-1-bold"
              color="system-primitive-neutral-900"
              content='"pago"'
            />
            <Typography
              variant="porto-text-body-1-regular"
              color="system-primitive-neutral-900"
              content=";"
            />
          </ListItem>
          <ListItem>
            <Typography
              variant="porto-text-body-1-regular"
              color="system-primitive-neutral-900"
              content="Novos clientes adicionados à planilha, serão considerados com status "
            />
            <Typography
              variant="porto-text-body-1-bold"
              color="system-primitive-neutral-900"
              content='"pendente"'
            />
            <Typography
              variant="porto-text-body-1-regular"
              color="system-primitive-neutral-900"
              content=";"
            />
          </ListItem>
          <ListItem>
            <Typography
              variant="porto-text-body-1-regular"
              color="system-primitive-neutral-900"
              content="Clientes que permanecerem na planilha continuarão com status "
            />
            <Typography
              variant="porto-text-body-1-bold"
              color="system-primitive-neutral-900"
              content='"pendente"'
            />
            <Typography
              variant="porto-text-body-1-regular"
              color="system-primitive-neutral-900"
              content="."
            />
          </ListItem>
        </List>
      </>
    )
  }

  return (
    <>
      <SheetContainer>{isUpdating ? renderUpdatingContent() : renderNewContent()}</SheetContainer>
      <NotificationWrapper>
        <Notification
          icon="circleinfo"
          variant="attention"
          description={`Antes de importar a planilha, verifique se todos os dados obrigatórios estão\ncorretos. Não serão efetuadas cobranças para clientes com dados incorretos.`}
        />
      </NotificationWrapper>
      <Info />
      <Action
        isLoading={isLoading}
        onClick={() => {
          inputRef.current?.click()
        }}
      >
        Importar planilha
      </Action>
      <input
        ref={inputRef}
        accept=".xlsx"
        type="file"
        style={{ display: 'none' }}
        onChange={handleChange}
      />
    </>
  )
}

export default Sheet
