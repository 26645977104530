import {
  Box,
  Typography,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import cupolaSummitBannerImage from '@/assets/images/cupola-summit.png'
import { isBefore, parse } from 'date-fns'
import * as S from './styles'

const LIMIT_DATE = '15/05/2024'

export const CupolaSummitBanner = () => {
  const today = new Date()
  const isBeforeLimitDate = isBefore(
    today,
    parse(LIMIT_DATE, 'dd/MM/yyyy', today)
  )

  if (!isBeforeLimitDate) {
    return null
  }

  return (
    <Box
      sx={{
        backgroundColor: 'porto-primitive-white',
        padding: {
          xs: '1.5rem 1rem',
          md: '1.75rem 2rem',
          lg: '1.75rem 4rem',
        },
      }}
    >
      <S.Container>
        <S.Wrapper>
          <Typography color="#fff" variant="porto-title-3-bold">
            Venha nos visitar no Cupola Summit 2024
          </Typography>

          <Typography color="#fff" variant="porto-text-body-1-bold">
            Aproveite 15% OFF usando o cupom PORTOBANK
          </Typography>
          <Typography color="#fff" variant="porto-text-body-2-regular">
            O Cupola Summit 2024 acontecerá de 17 a 18 de maio, em Curitiba
            (PR). Garanta seu ingresso com 15% OFF e venha conhecer nossas
            Soluções para Alugar. Nos vemos lá!
          </Typography>
          <S.Button
            href="https://cupolasummit.com.br/"
            target="_blank"
            rel="noopener noreferer"
          >
            <Typography
              content="Acesse o site"
              variant="porto-text-body-1-bold"
              color="system-primitive-blue-500"
            />
          </S.Button>
        </S.Wrapper>
        <S.Image src={cupolaSummitBannerImage} />
      </S.Container>
    </Box>
  )
}
