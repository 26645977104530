export const firstStepForm = {
  susep: '',
  partner: '',
  partner_id: '',
  customer: {
    name: '',
    document: '',
    phone: '',
    email: '',
    birthdate: '',
    pep: {
      expose_person_flag: 'NO',
      name: '',
      document: '',
      degree_of_kinship: '',
    },
    civil_status: 'SINGLE',
    occupation: '',
    nationality: '',
    complement_document_type_id: 'RG',
    complement_document: '',
    complement_document_emission: '',
    complement_document_issuer: '',
    country_complement_document_id: '',
  },
  contract: {
    total_value: 'R$ ',
    monthly_value: 'R$ ',
    plan_id: '',
    plan_rescue: '',
    plan_validity: '',
  },
}
