import { onlyNumbers } from '@/tools'
import { isCEP, isCPF } from 'brazilian-values'
import dayjs from 'dayjs'
import * as yup from 'yup'

export const secondStepSchema = yup.object().shape({
  estate: yup.object().shape({
    type: yup.string().oneOf(['RESIDENCIAL', 'COMERCIAL']).required(),
    address: yup.object().shape({
      zipcode: yup
        .string()
        .required('Campo CEP é obrigatório.')
        .test('cep', 'Por favor insira um CEP válido.', value => {
          const valueDigits = value?.replace(/\D/g, '')
          return valueDigits?.length === 8
        }),
      street: yup.string().required('Por favor confirme a rua.'),
      number: yup
        .string()
        .matches(/^[0-9]*$/g, 'Utilize o campo de complemento para inserir letras.')
        .required('Por favor insira o número.'),
      complement: yup.string(),
      neighborhood: yup.string().required('Por favor confirme o bairro.'),
      city: yup.string().required('Por favor confirme a cidade.'),
      state: yup.string().required('Por favor confirme o estado.'),
    }),
  }),
  landlord: yup.object().shape({
    name: yup.string().required('Campo nome é obrigatório.'),
    document: yup
      .string()
      .required('Campo CPF é obrigatório.')
      .test('document-test', 'Por favor insira um CPF válido.', value => !!value && isCPF(value))
      .test(
        'document-test',
        'O documento deve ser diferente do documento do inquilino.',
        (value, context) => {
          const customerDocument = context.options.context.customerDocument
          const landlordDocument = onlyNumbers(value)

          if (customerDocument !== undefined) {
            return landlordDocument !== customerDocument
          }

          return true
        },
      ),
    phone: yup
      .string()
      .required('Campo telefone/celular é obrigatório.')
      .test('phone', 'Informe um número válido', value => {
        const valueDigits = value?.replace(/\D/g, '')
        return valueDigits?.length === 10 || valueDigits?.length === 11
      }),
    email: yup
      .string()
      .email('Por favor insira um e-mail válido.')
      .required('Campo e-mail é obrigatório.'),
    birthdate: yup
      .string()
      .required('Data de nascimento obrigatório.')
      .test('invalid-date', 'Data de nascimento inválida.', value => {
        if (!value?.length) return false
        const formattedDate = value.split('/').reverse().join('-')
        const date = new Date(formattedDate)

        if (!dayjs(date).isValid()) return false

        return dayjs(date).isBefore(dayjs()) && dayjs(date).year() >= 1900
      }),
    address_type: yup.string().oneOf(['RESIDENCIAL', 'COMERCIAL']).required(),
    address: yup.object().shape({
      zipcode: yup
        .string()
        .required('Campo CEP é obrigatório.')
        .test('cep', 'Por favor insira um CEP válido.', value => !!value && isCEP(value)),
      number: yup
        .string()
        .matches(/^[0-9]*$/g, 'Utilize o campo de complemento para inserir letras.')
        .required('Por favor insira o número.'),
    }),
  }),
})
