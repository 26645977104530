import _public from './public'
import _private from './private'
import administrator from './administrator'

const routes = {
  public: _public,
  private: _private,
  administrator,
} as const

export default routes
