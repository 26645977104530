import * as Screens from '@/screens'
import { RouteType } from '@/router/routes/types'

const routes: readonly RouteType[] = [
  {
    path: '/',
    exact: true,
    component: Screens.LandingPage,
  },

  {
    path: '/login',
    exact: true,
    component: Screens.Auth.Login,
  },

  {
    path: '/esqueci-senha',
    component: Screens.Auth.ForgotPassword,
  },

  {
    path: '/nova-senha',
    component: Screens.Users.Password.NewRegister,
  },

  {
    path: '/completar-cadastro',
    component: Screens.Users.Password.NewRegister,
  },
  {
    path: '/primeiro-acesso',
    component: Screens.Auth.FirstAccess,
  },
] as const

export default routes
