import React from 'react'

import { IHelperProps } from './interfaces'

import { Ionicons } from '../../../Icons'

import { Container, Icon, Content } from './styles'

const Helper: React.FC<IHelperProps> = (props) => (
  <Container>
    <Icon>
      <Ionicons name="information-outline" />
    </Icon>

    <Content dangerouslySetInnerHTML={{ __html: props.children as any }} />
  </Container>
)

Helper.defaultProps = {
  children: 'Hint text here',
}

export default Helper
