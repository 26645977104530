import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import * as S from './styles'

export const Table = ({
  headColumns,
  columns
}) => (
  <S.Table>
    <S.TableHead>
      <tr>
        {headColumns.map((title) => (
          <td key={`table_head_data_${title}`}>
            <Typography
              content={title}
              variant="porto-text-caption-bold"
            />
          </td>
        ))}
      </tr>
    </S.TableHead>
    <tbody>
      {columns.map((column) => (
        <tr key={JSON.stringify(column)}>
          {column.map((content) => (
            <S.TableData key={`table_data_${content}`}>
              <Typography
                content={content}
                variant="porto-text-body-2-regular"
              />
            </S.TableData>
          ))}
        </tr>
      ))}
    </tbody>
  </S.Table>
)