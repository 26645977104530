import { Flex, Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'

import { Loader } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { Container } from './styles'

const LoginSplash = ({ firstName }: { firstName?: string }) => {
  return (
    <Container>
      <Flex
        sx={{
          flexDirection: 'column',
          height: '100%',
          padding: '16px',
          gap: '48px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            gap: '16px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="porto-title-4-bold">
            {firstName ? `Olá ${firstName}!` : 'Boas vindas!'}
          </Typography>
          <Typography variant="porto-body-1-regular">
            Por favor, aguarde. Estamos buscando seus dados...
          </Typography>
        </Flex>
        <Loader size="48px" />
      </Flex>
    </Container>
  )
}

export default LoginSplash
