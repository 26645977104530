import axios from 'axios'

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/v2/`,
  validateStatus: () => true, // Disable axios error throws when status code > 400
})

export enum EnableResponseStatus {
  UNKNOWN = -1,
  ALREADY_REGISTERED = 409,
  EMAIL_SENT = 201,
  NOT_REGISTERED = 404,
}

type EnableResponse =
  | { status: EnableResponseStatus.UNKNOWN }
  | { status: EnableResponseStatus.ALREADY_REGISTERED }
  | { status: EnableResponseStatus.EMAIL_SENT; email: string }
  | { status: EnableResponseStatus.NOT_REGISTERED }

interface ParseEnableResponse {
  status: number
  data: {
    email?: string
  }
}

const parseEnableResponse = ({
  status,
  data,
}: ParseEnableResponse): EnableResponse => {
  if (status === EnableResponseStatus.EMAIL_SENT) {
    if (String(data?.email)) {
      return {
        status: EnableResponseStatus.EMAIL_SENT,
        email: data.email,
      }
    }

    return { status: EnableResponseStatus.UNKNOWN }
  }

  const statusAsCode = EnableResponseStatus[status]

  return {
    status: EnableResponseStatus[statusAsCode] ?? EnableResponseStatus.UNKNOWN,
  }
}

const createEnableRequest =
  (enableType: 'partners' | 'brokers') => async (document: string) =>
    instance
      .post<{ email?: string }>(`/${enableType}/${document}/enable`)
      .then(parseEnableResponse)

const enablePartner = createEnableRequest('partners')

const enableBroker = createEnableRequest('brokers')

export const enable = async (id: string): Promise<EnableResponse> => {
  try {
    const enableBrokerResult = await enableBroker(id)

    if (enableBrokerResult.status === EnableResponseStatus.NOT_REGISTERED) {
      return enablePartner(id)
    }

    return enableBrokerResult
  } catch (error) {
    return { status: EnableResponseStatus.UNKNOWN }
  }
}
