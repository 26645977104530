import { Modals } from '@/components'

import ModalBase from '@/screens/FirstAccess/components/ModalBase'
import ModalSection from '@/screens/FirstAccess/components/ModalSection'
import { TCreateModal } from '@/components/Modals/promised/types'

interface CustomProps {
  email: string
}

interface Props extends CustomProps, TCreateModal {}

const EmailSent = (props: Props) => {
  return (
    <ModalBase onDismiss={props?.onDismiss}>
      <ModalSection>
        Um e-mail contendo orientações sobre o primeiro acesso foi encaminhado
        para {props.email}.
      </ModalSection>
      <ModalSection title="Imobiliária:">
        Caso não tenha acesso ao e-mail cadastrado, entre em contato com a área
        responsável através do seguinte e-mail e solicite a atualização:
        <br />
        <a
          href="mailto:emissao.portoimobiliaria@portoseguro.com.br"
          target="_blank"
          rel="noopener noreferrer"
          data-gtm-type="click"
          data-gtm-clicktype="link"
          data-gtm-name="email"
        >
          emissao.portoimobiliaria@portoseguro.com.br
        </a>
      </ModalSection>
      <ModalSection title="Corretor:">
        Caso não tenha acesso ao e-mail cadastrado, realize a atualização pelo
        <br />
        <a
          href="https://cadastroimobiliaria.solucoesparalocacao.com.br?utm_source=col&utm_medium=corretor-online&utm_campaign=portal-das-imobiliarias"
          target="_blank"
          rel="noopener noreferrer"
        >
          Corretor Online (COL).
        </a>
      </ModalSection>
    </ModalBase>
  )
}

const EmailSentModal = Modals.Promised.createModal<Props>(EmailSent)

export default EmailSentModal
