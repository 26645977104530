import { useEffect, useState } from 'react'
import { useFormik, FormikProvider } from 'formik'
import { useHistory } from 'react-router-dom'

import { Button } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import {
  Box,
  Flex,
  Typography,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'

import { useCurrentUser } from '@/modules/auth'
import { Forms, toast } from '@/components'
import { api } from '@/services'
import { useGTM } from '@/tools'

import { FormShape, completeRegisterSchema } from './schema'
import * as S from './styles'

const CompleteRegister = () => {
  const { setCustomData, setDataLayer } = useGTM()
  const user = useCurrentUser()
  const history = useHistory()

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setCustomData({
      page: {
        name: 'Portal Imobiliária - Solicitar acesso',
      },
      site: {
        portal: 'imobiliarias',
      },
    })

    setDataLayer({
      event: 'step_change',
      titulo: 'portal Imobiliária - Solicitar acesso',
      etapa: '/completar-acesso',
    })
  }, [setCustomData, setDataLayer, user.store.document])

  const onSubmit = async (values) => {
    try {
      setIsLoading(true)

      await api.users.auth.updateProfile(user.store.email, values)
      await user.getCurrentUser()

      setDataLayer({
        event: 'resultado_consulta',
        nome_servico: 'cadastrar',
        tipo_busca: 'perfil',
        retorno: 'sucesso',
        descricao: 'Usuário cadastrado com sucesso',
      })

      toast('Seus dados foram cadastrados com sucesso!', {
        type: 'success',
      })

      history.go(0)
    } catch (error) {
      setDataLayer({
        event: 'resultado_consulta',
        nome_servico: 'cadastrar',
        tipo_busca: 'perfil',
        retorno: 'sucesso',
        descricao: 'Erro ao completar cadastro de usuário',
        erro: {
          codigo: error.response?.status,
          servico: 'cadastro de usuário',
          mensagem: error.response?.data?.message,
        },
      })

      toast(
        'Ocorreu um erro ao completar seu cadastro, por favor tente novamente.',
        { type: 'error' }
      )
    } finally {
      setIsLoading(false)
    }
  }

  const form = useFormik<FormShape>({
    initialValues: {
      name: user.store.name,
      cpf: '',
    },
    validationSchema: completeRegisterSchema,
    validateOnChange: false,
    onSubmit,
  })

  return (
    <S.ScreenContainer>
      <Typography
        content="Completar cadastro"
        variant="porto-title-3-bold"
        color="porto-primitive-black-85"
        sx={{ display: 'block' }}
      />
      <Typography
        content="Por favor, informe os dados abaixo para completar seu cadastro."
        variant="porto-text-body-1-regular"
        color="porto-primitive-black-85"
        sx={{ marginTop: '1rem' }}
      />
      <FormikProvider value={form}>
        <form onSubmit={form.handleSubmit}>
          <Flex
            sx={{
              marginTop: '1rem',
              flexDirection: 'column',
              gap: '1.5rem',
            }}
          >
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: '1rem',
              }}
            >
              <Forms.InputGroup
                name="name"
                label="Nome"
                placeholder="Digite seu nome"
              />
              <Forms.InputGroup
                name="cpf"
                label="CPF"
                placeholder="Digite seu CPF"
                mask="cpf"
              />
            </Box>
            <Box>
              <Button
                isLoading={isLoading}
                isDisabled={isLoading}
                type="submit"
                data-gtm-type="click"
                data-gtm-clicktype="button"
                data-gtm-name="completar-cadastro"
              >
                Completar cadastro
              </Button>
            </Box>
          </Flex>
        </form>
      </FormikProvider>
    </S.ScreenContainer>
  )
}

export default CompleteRegister
