import correctDate from 'text-mask-addons/dist/createAutoCorrectedDatePipe'
import numberMask from 'text-mask-addons/dist/createNumberMask'

export const money = numberMask({
  prefix: 'R$ ',
  thousandsSeparatorSymbol: '.',
  allowDecimal: true,
  decimalSymbol: ',',
})

export const percentage = numberMask({
  prefix: '',
  suffix: '%',
  allowDecimal: true,
  integerLimit: 3,
  decimalSymbol: ',',
  includeThousandsSeparator: false,
})

export const date = correctDate('dd/mm/yyyy')

export const shortDate = correctDate('dd/yyyy')
