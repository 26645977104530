import { Conditional, Icons } from '@/components'
import { SvgIcons } from '@/components/SvgIcons'
import { Container, HeaderAlert, HeaderSpan, Icon, Content } from './styles'

interface CardTitleProps {
  icon: string
  description?: string
  onlyMessage?: boolean
}

const CardNotice = ({ description, icon, onlyMessage }: CardTitleProps) => {
  return (
    <Container>
      <Content>
        <Icon onlyMessage={onlyMessage}>{SvgIcons[icon]}</Icon>
        <div>
          <Conditional when={!!description}>
            <HeaderAlert dangerouslySetInnerHTML={{ __html: description }} />
          </Conditional>
        </div>
      </Content>
    </Container>
  )
}

export default CardNotice
