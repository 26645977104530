import styled from 'styled-components'
import { rem } from 'polished'

export const Content = styled.div`
  margin-top: ${rem('20px')};
  display: grid;
  grid-template-columns: ${rem('41px')} auto;
  grid-template-rows: ${rem('41px')} auto;
  column-gap: ${rem('20px')};
  row-gap: ${rem('20px')};
`

export const IconContainer = styled.div`
  font-size: ${rem('32px')};
  background-color: #ffcdc6;
  border-radius: ${rem('8px')};
  text-align: center;
  padding-top: ${rem('3px')};
`

export const Title = styled.div`
  padding-top: ${rem('9px')};
  font-size: ${rem('18px')};
  color: var('--neutras-black-85', #1f1f1f);
  font-weight: bolder;
`

export const Paragraph = styled.div`
  font-size: ${rem('13px')};
  color: var(--neutras-black-75, #404040);
  grid-column: 1 / 3;
  line-height: ${rem('24px')};
`
