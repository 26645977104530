import { css, CSSProp } from 'styled-components'
import { size, rgba, linearGradient, rem } from 'polished'

import themes from '../themes'

export const clipText: CSSProp = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const fitImageToBox: CSSProp = css`
  ${size('100%')}

  object-fit: cover;
`

export const overlayBackdrop: CSSProp = css`
  ${size('100%')}

  position: absolute;
  top: 0;
  left: 0;

  background-color: ${themes.modals.backdrop};
`

export const clampText: any = (lines: number = 1) => css`
  overflow: hidden;

  display: -webkit-box;
  -webkit-line-clamp: ${lines};
  -webkit-box-orient: vertical;
`

export const defaultBoxShadow = css`
  box-shadow: 0 8px 10px ${rgba('#123440', 0.2)};
`

export const gradientWithPrimaryColor = linearGradient({
  colorStops: [`${themes.palletes.primary} 0%`, '#2C6BFF 50%'],
  toDirection: 'to top right',
})

export const pxToRem = (value: string | number) => rem(value, '16px')
