export default {
  'porto_essencial.payments': [
    {
      upperHeading: 'Formas de pagamento',
      heading: 'Fatura mensal sem entrada',
      fields: {
        'porto_essencial.payments.invoiceWithoutDown.enabled': {
          type: 'toggle',
          title: 'Habilitar',
        },
      },
    },
    {
      heading: 'Cartão de crédito',
      fields: {
        'porto_essencial.payments.creditCard.enabled': {
          type: 'toggle',
          title: 'Habilitar',
        },
      },
    },
  ],
}
