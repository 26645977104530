const onboardingBanks = [
  { id: '403', name: 'CORA SCD - Sociedade de Crédito Direto S.A' },
  { id: '001', name: 'BANCO DO BRASIL S.A (BB)' },
  { id: '237', name: 'BRADESCO S.A' },
  { id: '335', name: 'Banco Digio S.A' },
  { id: '260', name: 'NU PAGAMENTOS S.A (NUBANK)' },
  { id: '290', name: 'Pagseguro Internet S.A (PagBank)' },
  { id: '380', name: 'PicPay Servicos S.A.' },
  { id: '323', name: 'Mercado Pago - conta do Mercado Livre' },
  { id: '237', name: 'NEXT BANK' },
  { id: '637', name: 'BANCO SOFISA S.A (SOFISA DIRETO)' },
  { id: '077', name: 'BANCO INTER S.A' },
  { id: '341', name: 'ITAÚ UNIBANCO S.A' },
  { id: '104', name: 'CAIXA ECONÔMICA FEDERAL (CEF)' },
  { id: '033', name: 'BANCO SANTANDER BRASIL S.A' },
  { id: '212', name: 'BANCO ORIGINAL S.A' },
  { id: '756', name: 'BANCOOB (BANCO COOPERATIVO DO BRASIL)' },
  { id: '655', name: 'BANCO VOTORANTIM S.A' },
  { id: '655', name: 'NEON PAGAMENTOS S.A' },
  { id: '041', name: 'BANRISUL – BANCO DO ESTADO DO RIO GRANDE DO SUL S.A' },
  { id: '389', name: 'BANCO MERCANTIL DO BRASIL S.A' },
  { id: '422', name: 'BANCO SAFRA S.A' },
  { id: '070', name: 'BANCO DE BRASÍLIA (BRB)' },
  { id: '136', name: 'UNICRED COOPERATIVA' },
  { id: '741', name: 'BANCO RIBEIRÃO PRETO' },
  { id: '739', name: 'BANCO CETELEM S.A' },
  { id: '743', name: 'BANCO SEMEAR S.A' },
  { id: '100', name: 'PLANNER CORRETORA DE VALORES S.A' },
  { id: '096', name: 'BANCO B3 S.A' },
  { id: '747', name: 'Banco RABOBANK INTERNACIONAL DO BRASIL S.A' },
  { id: '748', name: 'SICREDI S.A' },
  { id: '752', name: 'BNP PARIBAS BRASIL S.A' },
  { id: '091', name: 'UNICRED CENTRAL RS' },
  { id: '399', name: 'KIRTON BANK' },
  { id: '108', name: 'PORTOCRED S.A' },
  { id: '757', name: 'BANCO KEB HANA DO BRASIL S.A' },
  { id: '102', name: 'XP INVESTIMENTOS S.A' },
  { id: '348', name: 'BANCO XP S/A' },
  { id: '340', name: 'SUPER PAGAMENTOS S/A (SUPERDITAL)' },
  { id: '364', name: 'GERENCIANET PAGAMENTOS DO BRASIL' },
  { id: '084', name: 'UNIPRIME NORTE DO PARANÁ' },
  { id: '180', name: 'CM CAPITAL MARKETS CCTVM LTDA' },
  { id: '066', name: 'BANCO MORGAN STANLEY S.A' },
  { id: '015', name: 'UBS BRASIL CCTVM S.A' },
  { id: '143', name: 'TREVISO CC S.A' },
  { id: '062', name: 'HIPERCARD BM S.A' },
  { id: '074', name: 'BCO. J.SAFRA S.A' },
  { id: '099', name: 'UNIPRIME CENTRAL CCC LTDA' },
  { id: '025', name: 'BANCO ALFA S.A.' },
  { id: '075', name: 'BCO ABN AMRO S.A' },
  { id: '040', name: 'BANCO CARGILL S.A' },
  { id: '190', name: 'SERVICOOP' },
  { id: '063', name: 'BANCO BRADESCARD' },
  { id: '191', name: 'NOVA FUTURA CTVM LTDA' },
  { id: '064', name: 'GOLDMAN SACHS DO BRASIL BM S.A' },
  { id: '097', name: 'CCC NOROESTE BRASILEIRO LTDA' },
  { id: '016', name: 'CCM DESP TRÂNS SC E RS' },
  { id: '012', name: 'BANCO INBURSA' },
  { id: '003', name: 'BANCO DA AMAZONIA S.A' },
  { id: '060', name: 'CONFIDENCE CC S.A' },
  { id: '037', name: 'BANCO DO ESTADO DO PARÁ S.A' },
  { id: '159', name: 'CASA CREDITO S.A' },
  { id: '172', name: 'ALBATROSS CCV S.A' },
  { id: '085', name: 'COOP CENTRAL AILOS' },
  { id: '114', name: 'CENTRAL COOPERATIVA DE CRÉDITO NO ESTADO DO ESPÍRITO SANTO' },
  { id: '036', name: 'BANCO BBI S.A' },
  { id: '394', name: 'BANCO BRADESCO FINANCIAMENTOS S.A' },
  { id: '004', name: 'BANCO DO NORDESTE DO BRASIL S.A.' },
  { id: '320', name: 'BANCO CCB BRASIL S.A' },
  { id: '189', name: 'HS FINANCEIRA' },
  { id: '105', name: 'LECCA CFI S.A' },
  { id: '076', name: 'BANCO KDB BRASIL S.A.' },
  { id: '082', name: 'BANCO TOPÁZIO S.A' },
  { id: '286', name: 'CCR DE OURO' },
  { id: '093', name: 'PÓLOCRED SCMEPP LTDA' },
  { id: '273', name: 'CCR DE SÃO MIGUEL DO OESTE' },
  { id: '157', name: 'ICAP DO BRASIL CTVM LTDA' },
  { id: '183', name: 'SOCRED S.A' },
  { id: '014', name: 'NATIXIS BRASIL S.A' },
  { id: '130', name: 'CARUANA SCFI' },
  { id: '127', name: 'CODEPE CVC S.A' },
  { id: '079', name: 'BANCO ORIGINAL DO AGRONEGÓCIO S.A' },
  { id: '081', name: 'BBN BANCO BRASILEIRO DE NEGOCIOS S.A' },
  { id: '118', name: 'STANDARD CHARTERED BI S.A' },
  { id: '133', name: 'CRESOL CONFEDERAÇÃO' },
  { id: '121', name: 'BANCO AGIBANK S.A' },
  { id: '083', name: 'BANCO DA CHINA BRASIL S.A' },
  { id: '138', name: 'GET MONEY CC LTDA' },
  { id: '024', name: 'BCO BANDEPE S.A' },
  { id: '095', name: 'BANCO CONFIDENCE DE CÂMBIO S.A' },
  { id: '094', name: 'BANCO FINAXIS' },
  { id: '276', name: 'SENFF S.A' },
  { id: '137', name: 'MULTIMONEY CC LTDA' },
  { id: '092', name: 'BRK S.A' },
  { id: '047', name: 'BANCO BCO DO ESTADO DE SERGIPE S.A' },
  { id: '144', name: 'BEXS BANCO DE CAMBIO S.A.' },
  { id: '126', name: 'BR PARTNERS BI' },
  { id: '301', name: 'BPP INSTITUIÇÃO DE PAGAMENTOS S.A' },
  { id: '173', name: 'BRL TRUST DTVM SA' },
  { id: '119', name: 'BANCO WESTERN UNION' },
  { id: '254', name: 'PARANA BANCO S.A' },
  { id: '268', name: 'BARIGUI CH' },
  { id: '107', name: 'BANCO BOCOM BBM S.A' },
  { id: '412', name: 'BANCO CAPITAL S.A' },
  { id: '124', name: 'BANCO WOORI BANK DO BRASIL S.A' },
  { id: '149', name: 'FACTA S.A. CFI' },
  { id: '197', name: 'STONE PAGAMENTOS S.A' },
  { id: '142', name: 'BROKER BRASIL CC LTDA' },
  { id: '184', name: 'BANCO ITAÚ BBA S.A' },
  { id: '634', name: 'BANCO TRIANGULO S.A (BANCO TRIÂNGULO)' },
  { id: '545', name: 'SENSO CCVM S.A' },
  { id: '132', name: 'ICBC DO BRASIL BM S.A' },
  { id: '298', name: 'VIPS CC LTDA' },
  { id: '129', name: 'UBS BRASIL BI S.A' },
  { id: '128', name: 'MS BANK S.A BANCO DE CÂMBIO' },
  { id: '194', name: 'PARMETAL DTVM LTDA' },
  { id: '310', name: 'VORTX DTVM LTDA' },
  { id: '163', name: 'COMMERZBANK BRASIL S.A BANCO MÚLTIPLO' },
  { id: '280', name: 'AVISTA S.A' },
  { id: '146', name: 'GUITTA CC LTDA' },
  { id: '279', name: 'CCR DE PRIMAVERA DO LESTE' },
  { id: '182', name: 'DACASA FINANCEIRA S/A' },
  { id: '278', name: 'GENIAL INVESTIMENTOS CVM S.A' },
  { id: '271', name: 'IB CCTVM LTDA' },
  { id: '021', name: 'BANCO BANESTES S.A' },
  { id: '246', name: 'BANCO ABC BRASIL S.A' },
  { id: '751', name: 'SCOTIABANK BRASIL' },
  { id: '208', name: 'BANCO BTG PACTUAL S.A' },
  { id: '746', name: 'BANCO MODAL S.A' },
  { id: '241', name: 'BANCO CLASSICO S.A' },
  { id: '612', name: 'BANCO GUANABARA S.A' },
  { id: '604', name: 'BANCO INDUSTRIAL DO BRASIL S.A' },
  { id: '505', name: 'BANCO CREDIT SUISSE (BRL) S.A' },
  { id: '196', name: 'BANCO FAIR CC S.A' },
  { id: '300', name: 'BANCO LA NACION ARGENTINA' },
  { id: '477', name: 'CITIBANK N.A' },
  { id: '266', name: 'BANCO CEDULA S.A' },
  { id: '122', name: 'BANCO BRADESCO BERJ S.A' },
  { id: '376', name: 'BANCO J.P. MORGAN S.A' },
  { id: '473', name: 'BANCO CAIXA GERAL BRASIL S.A' },
  { id: '745', name: 'BANCO CITIBANK S.A' },
  { id: '120', name: 'BANCO RODOBENS S.A' },
  { id: '265', name: 'BANCO FATOR S.A' },
  { id: '007', name: 'BNDES (Banco Nacional do Desenvolvimento Social)' },
  { id: '188', name: 'ATIVA S.A INVESTIMENTOS' },
  { id: '134', name: 'BGC LIQUIDEZ DTVM LTDA' },
  { id: '641', name: 'BANCO ALVORADA S.A' },
  { id: '029', name: 'BANCO ITAÚ CONSIGNADO S.A' },
  { id: '243', name: 'BANCO MÁXIMA S.A' },
  { id: '078', name: 'HAITONG BI DO BRASIL S.A' },
  { id: '111', name: 'BANCO OLIVEIRA TRUST DTVM S.A' },
  { id: '017', name: 'BNY MELLON BANCO S.A' },
  { id: '174', name: 'PERNAMBUCANAS FINANC S.A' },
  { id: '495', name: 'LA PROVINCIA BUENOS AIRES BANCO' },
  { id: '125', name: 'BRASIL PLURAL S.A BANCO' },
  { id: '488', name: 'JPMORGAN CHASE BANK' },
  { id: '065', name: 'BANCO ANDBANK S.A' },
  { id: '492', name: 'ING BANK N.V' },
  { id: '250', name: 'BANCO BCV' },
  { id: '145', name: 'LEVYCAM CCV LTDA' },
  { id: '494', name: 'BANCO REP ORIENTAL URUGUAY' },
  { id: '253', name: 'BEXS CC S.A' },
  { id: '269', name: 'HSBC BANCO DE INVESTIMENTO' },
  { id: '213', name: 'BCO ARBI S.A' },
  { id: '139', name: 'INTESA SANPAOLO BRASIL S.A' },
  { id: '018', name: 'BANCO TRICURY S.A' },
  { id: '630', name: 'BANCO INTERCAP S.A' },
  { id: '224', name: 'BANCO FIBRA S.A' },
  { id: '600', name: 'BANCO LUSO BRASILEIRO S.A' },
  { id: '623', name: 'BANCO PAN' },
  { id: '204', name: 'BANCO BRADESCO CARTOES S.A' },
  { id: '479', name: 'BANCO ITAUBANK S.A' },
  { id: '456', name: 'BANCO MUFG BRASIL S.A' },
  { id: '464', name: 'BANCO SUMITOMO MITSUI BRASIL S.A' },
  { id: '613', name: 'OMNI BANCO S.A' },
  { id: '652', name: 'ITAÚ UNIBANCO HOLDING BM S.A' },
  { id: '653', name: 'BANCO INDUSVAL S.A' },
  { id: '069', name: 'BANCO CREFISA S.A' },
  { id: '370', name: 'BANCO MIZUHO S.A' },
  { id: '249', name: 'BANCO INVESTCRED UNIBANCO S.A' },
  { id: '318', name: 'BANCO BMG S.A' },
  { id: '626', name: 'BANCO FICSA S.A' },
  { id: '270', name: 'SAGITUR CC LTDA' },
  { id: '366', name: 'BANCO SOCIETE GENERALE BRASIL' },
  { id: '113', name: 'MAGLIANO S.A' },
  { id: '131', name: 'TULLETT PREBON BRASIL CVC LTDA' },
  { id: '011', name: 'C.SUISSE HEDGING-GRIFFO CV S.A (Credit Suisse)' },
  { id: '611', name: 'BANCO PAULISTA' },
  { id: '755', name: 'BOFA MERRILL LYNCH BM S.A' },
  { id: '089', name: 'CCR REG MOGIANA' },
  { id: '643', name: 'BANCO PINE S.A' },
  { id: '140', name: 'EASYNVEST - TÍTULO CV S.A' },
  { id: '707', name: 'BANCO DAYCOVAL S.A' },
  { id: '288', name: 'CAROL DTVM LTDA' },
  { id: '101', name: 'RENASCENCA DTVM LTDA' },
  { id: '487', name: 'DEUTSCHE BANK S.A BANCO ALEMÃO' },
  { id: '233', name: 'BANCO CIFRA' },
  { id: '177', name: 'GUIDE' },
  { id: '633', name: 'BANCO RENDIMENTO S.A' },
  { id: '218', name: 'BANCO BS2 S.A' },
  { id: '292', name: 'BS2 DISTRIBUIDORA DE TÍTULOS E INVESTIMENTOS' },
  { id: '169', name: 'BANCO OLÉ BONSUCESSO CONSIGNADO S.A' },
  { id: '293', name: 'LASTRO RDV DTVM LTDA' },
  { id: '285', name: 'FRENTE CC LTDA' },
  { id: '080', name: 'B&T CC LTDA' },
  { id: '753', name: 'NOVO BANCO CONTINENTAL S.A BM' },
  { id: '222', name: 'BANCO CRÉDIT AGRICOLE BR S.A' },
  { id: '754', name: 'BANCO SISTEMA' },
  { id: '098', name: 'CREDIALIANÇA CCR' },
  { id: '610', name: 'BANCO VR S.A' },
  { id: '712', name: 'BANCO OURINVEST S.A' },
  { id: '010', name: 'CREDICOAMO' },
  { id: '283', name: 'RB CAPITAL INVESTIMENTOS DTVM LTDA' },
  { id: '217', name: 'BANCO JOHN DEERE S.A' },
  { id: '117', name: 'ADVANCED CC LTDA' },
  { id: '336', name: 'BANCO C6 S.A - C6 BANK' },
  { id: '654', name: 'BANCO DIGIMAIS S.A' },
  { id: '461', name: 'ASAAS I.P.SA' },
]

export default onboardingBanks
