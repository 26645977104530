import { Icon } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { data } from './data'
import {
  FourthSectionContainer,
  GridContainer,
  GridItem,
  GridItemText,
  GridItemTitle,
  Title,
} from './styles'

const FourthSection = () => {
  return (
    <FourthSectionContainer>
      <Title
        color="porto-primitive-white"
        variant="porto-title-4-medium"
        content="Mais benefícios para você!"
      />
      <GridContainer>
        {data.map(({ title, text }, index) => (
          <GridItem key={index}>
            <Icon size="md" color="#a0a8d8" name="circlecheck" />
            <GridItemTitle
              color="porto-primitive-white"
              variant="porto-text-body-1-bold"
              content={title}
            />
            <GridItemText
              color="porto-primitive-white"
              variant="porto-text-body-1-regular"
              content={text}
            />
          </GridItem>
        ))}
      </GridContainer>
    </FourthSectionContainer>
  )
}

export default FourthSection
