import { createEffect } from 'effector'

import { api } from '@/services'
import { ContractStore } from '../store'

export const fetch = createEffect('fetchContract', {
  handler: async ({ id }) => {
    const contract = await api.contracts.get(id)
    return contract
  },
})

ContractStore.on(fetch.pending, (state, pending) => ({
  ...state,
  _status: pending ? 'pending' : state._status,
}))
  .on(fetch.fail, (state) => ({
    ...state,
    _status: 'not-found',
  }))
  .on(fetch.doneData, (state, contract) => ({
    ...contract,
    external_status: contract.status,
    _status: 'fulfilled',
  }))
