import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  padding: 16px 12px 16px 12px;



`

export const ContainerWrapper = styled.form`

  position: relative;

.custom-arrow::before,
.custom-arrow::after {
  content: '';
  position: absolute ;
  width: 0;
  height: 0;

  left: 50%;
  top:50%; 

  transform: translate(-50%, -50%)
}


.rotate {
transform: rotate(180deg);
}
  

`

export const InputSelect = styled.select`
  display: flex;
  appearance: none;
  border-radius: 4px;
  height: 100%;
  width: 304px;
  padding: 1em 4em 1em 1.5em;
  border: 1px solid #b3b3b3;
  cursor: pointer;
  font-family: 'Porto Roobert';
  font-style: normal;
  font-weight: 500;

  &:focus {
    outline: none;
    border: 1px solid #2C3DA8;
  }
`



