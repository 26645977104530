import React from 'react'

import { IChoiceProps } from './interfaces'

import { Container, Field, Icon, Label, Marker } from './styles'

const Choice: React.FC<IChoiceProps> = ({ label, ...props }) => {
  return (
    <Container>
      <Field {...props} />

      <Marker>
        <Icon name="check" />
      </Marker>

      <Label>{label}</Label>
    </Container>
  )
}

export default Choice
