import { Flex, Typography } from "@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base"
import { Icon } from "@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto"

export const Error = ({ error }: { error: string }) => (
  <Flex
    sx={{
      alignItems: 'center',
      justifyContent: 'center',
      gap: '1rem'
    }}
  >
    <Icon
      name="alert_circle"
      size="md"
      color="system-brand-negative"
    />
    <Typography
      content={error}
      variant="porto-text-body-1-regular"
      color="system-brand-negative"
    />
  </Flex>
)