import banner from '@/assets/images/portal-imobiliarias-banner-titulo-capitalizacao-2024.png'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { useMemo } from 'react'

import * as S from './styles'

const FINAL_DATE = new Date('2024-10-10T03:00:00.000Z')

const TituloCapitalizacaoBanner = () => {
  const isVisible = useMemo(() => {
    const today = new Date()

    return today < FINAL_DATE
  }, [])

  if (!isVisible) return <></>

  return (
    <S.Container>
      <S.WrapperContent>
        <Typography
          content="Título de Capitalização Aluguel com adesão grátis da Tag Porto Bank!"
          variant="porto-title-3-bold"
          color="#0A0047"
        />
        <S.DescriptionWrapper>
          <Typography
            content="Novas contratações contarão com este benefício. Só até 10 de outubro! Ofereça aos seus clientes e boas vendas!"
            variant="porto-text-body-2-regular"
            color="#0A0047"
          />
        </S.DescriptionWrapper>
      </S.WrapperContent>
      <S.WrapperImage>
        <S.Image src={banner} />
      </S.WrapperImage>
    </S.Container>
  )
}

export default TituloCapitalizacaoBanner
