import { useField } from "formik"

import { RadioGroup } from "@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto"
import { Flex } from "@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base"

import { useCurrentOrganization } from "@/modules"

import { BaseCard } from "./../../../../components/BaseCard"
import { isCapitalizacaoCreditCardEnabled } from "@/tools/partnership"

export const Payment = ({
  contract,
}) => {

  const profile = useCurrentOrganization()

  const { setValue } = useField('payment.type')[2]

  const paymentOptions = [
    {
      text: 'Boleto',
      value: 'boleto'
    },
    isCapitalizacaoCreditCardEnabled({
      profile,
      susep: contract.broker.id
    }) && {
      text: 'Cartão de crédito',
      value: 'paymentLink'
    }
  ].filter(Boolean)

  return (
    <BaseCard title="Pagamento">
      <Flex
        sx={{
          flexDirection: "column",
          gap: "1rem"
        }}
      >
        <RadioGroup
          titleProps={{ content: 'Forma de pagamento' }}
          initialChecked="boleto"
          options={paymentOptions}
          onChange={setValue}
          data-test-id="payment-method"
        />
      </Flex>
    </BaseCard>
  )
}
