import { themes } from '@/styles'
import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 8px;

  margin-bottom: 16px;

  position: relative;
  @media(max-width: 888px) {
   position: none ;
  }
  ${media.lessThan('medium')`
  margin-bottom: 12px;
    
  `}
`

export const Icon = styled.div`
  ${media.lessThan('medium')`
    display: none ;
  `}
  color: ${themes.text.higher};
  margin-right: 20px;
  display: flex;

  svg {
    width: 20px;
    color: #94621f;
  }
`

export const Title = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height, or 140% */

    letter-spacing: 0.02em;

    color: #2F2663;
    margin-bottom: 16px;

  ${media.lessThan('medium')`
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */

    letter-spacing: 0.025em;   
  `}
`

export const Wrapper = styled.span`
  font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
/* or 150% */

letter-spacing: 0.025em;

/* Black 100% */

color: #000000;

  ${media.lessThan('medium')`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;


    letter-spacing: 0.03em;
  `}

  a {
    display: inline-block;
    position: relative;
    color: ${themes.palletes.primary};
    font-weight: bold;
  }

  a:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: ${themes.palletes.primaryHover};
    transform-origin: bottom left;
    transition: transform 0.25s ease-out;
  }

  a:hover:after {
    transform: scaleX(1.0);
    transform-origin: bottom left;
  }
`
export const Image = styled.img`
  width: 100%;
  height: 100% ;
  border-radius: 8px;

  @media(max-width: 888px) {
    display: none;
  }
`

export const Content = styled.div`
  position: absolute;
  left: 32%;
  padding: 16px 16px 0px 0px;
  @media(max-width: 888px) {
   position: unset ;
   left: 0%;
   padding: 16px 16px 6px 6px;

  }

`
export const Link = styled.div`
  ${media.lessThan('medium')`
    margin-left: 0px;
  `}
  font-weight: 400;

  font-family: ${themes.fonts.text};
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.025em;
  text-decoration-line: none;

  color: ${themes.palletes.primary};
`
export const WrapperLink = styled.div`
  padding: 27px 0px 18px 0px ;
  display: flex;
  
 div {
   display: flex;
   align-items: center;
   align-self: center;
 }
  a {
    display: inline-block;
    position: relative;
    color: ${themes.palletes.primary};
    font-weight: bold;
  }

  a:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: ${themes.palletes.primaryHover};
    transform-origin: bottom left;
    transition: transform 0.25s ease-out;
  }

  a:hover:after {
    transform: scaleX(1.1);
    transform-origin: bottom left;
  }
  display: flex;
  align-items: center;

  svg {
    color: ${themes.palletes.primary};
  }
`

export const ContainerImage = styled.div`
  display: flex;
  width: 100% ;
  height:236px ;
 position: relative ;

 border-radius: 8px;
 background: rgba(215, 224, 245, 0.3);

  @media(min-width: 889px) {
  height:300px ;
  }
 @media(max-width: 888px) {
  height:100% ;
  }

  @media(min-width: 1008px) {
  height:270px ;
  }
  @media(min-width: 1033px) {
  height:250px ;
  }
  @media(min-width: 1050px) {
  height:245px ;
  }

  @media(min-width: 1351px) and (max-width: 1368px) {
  height:270px ;
  }
  @media(min-width: 1198px) and (max-width: 1350px) {
  height:285px ;
  }
  @media (min-width: 1171px) and (max-width: 1197px) {
  height:310px ;
  }

`