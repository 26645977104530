import styled from 'styled-components'
import { css } from "@pol-npm/riscos-financeiros-dashboard-ui/dist/components/_shared"

export const Table = styled.table`
  border-collapse: collapse;

  & td {
    padding: .5rem;
  }
`

export const TableHead = styled.thead(
  css({
    sx: {
      backgroundColor: "porto-primitive-black-15",
      "& td:first-of-type": {
        width: { xs: "130px", lg: "232px" }
      }
    }
  })
)

export const TableData = styled.td`
  border: 1px solid var(--porto-primitive-black-15);
`