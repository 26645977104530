import breakpoints from '@pol-npm/riscos-financeiros-dashboard-ui/dist/theme/breakpoints'
import styled from 'styled-components'

export const Container = styled.div`
  &::before {
    content: '';
    position: fixed;
    background: white;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin: 32px 0 44px;
`

export const CardsContainer = styled.div`
  margin-top: 96px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px 24px;

  @media (max-width: ${breakpoints.md}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${breakpoints.sm}) {
    grid-template-columns: repeat(1, 1fr);
    margin-top: 80px;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`
