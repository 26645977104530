import { Flex, Typography } from "@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base"
import { ICardProps } from "./interfaces"
import * as S from './styles'

export const Card = ({
  benefits,
  image,
  title,
}: ICardProps) => (
  <Flex sx={{
    flexDirection: 'column',
    border: "1px solid var(--porto-primitive-black-15)",
    borderRadius: "16px",
    padding: '24px',
    gap: '1rem',
    width: '100%',
    maxWidth: '376px',
  }}>
    <S.Image
      src={image.src}
      alt={image.alt}
    />
    <Typography
      content={title}
      variant="porto-title-5-semibold"
    />
    <ul
      style={{
        marginLeft: "1rem",
      }}
    >
      {benefits.map((benefit, index) => (
        <S.ListItem key={`${benefit}_${index}`}>
          <Typography
            content={benefit}
            variant="porto-text-body-2-regular"
          />
        </S.ListItem>
      ))}
    </ul>
  </Flex>
)