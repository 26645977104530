import React from 'react'

import { Icons, Tooltip } from '@/components'
import { useAuth } from '@/modules'

import { Container, Content, Logo, Title, Actions, Action } from './styles'

export default function Header() {
  const auth = useAuth()

  return (
    <Container>
      <Content>
        <Logo />

        <Title>
          Painel
          <br />
          de controle
        </Title>

        <Actions>
          <Tooltip content="Sair">
            <Action onClick={() => auth.handleLogout()}>
              <Icons.FeatherIcons name="log-out" />
            </Action>
          </Tooltip>
        </Actions>
      </Content>
    </Container>
  )
}
