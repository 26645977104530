import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const StyledSvgDesktop = css`
  top: 0;
  left: calc(0% - 20vh);
  transform: rotate(0deg);
  height: 100%;
`

export const StyledSvg = styled.svg`
  display: none;

  ${media.lessThan('medium')`
    display: block;
    position: absolute;
    z-index: 100;
    filter: drop-shadow(-2px 4px 10px #66cef5);
    transform: rotate(76deg);
    height: 100vw;
    width: 100%;
    top: -20vw;
  `};
`
