import React from 'react'

import { Container, Icon } from './styles'

export default function Menu() {
  return (
    <Container>
      <Icon />
      Voltar ao menu
    </Container>
  )
}
