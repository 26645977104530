import { Conditional } from '@/components'
import { SvgIcons } from '@/components/SvgIcons'
import { Container, Header, Icon, Wrapper, Content } from './styles'

interface CardProps {
  icon?: string
  title?: string
  children: string
  background?: string
  colorLink?: string
  border?: string
}

const Card = ({
  title,
  icon,
  children,
  background,
  colorLink,
  border,
}: CardProps) => {
  return (
    <Container style={{ background: `${background}` }} border={border}>
      <Content>
        <Conditional when={!!icon}>
          <Icon>{SvgIcons[icon]}</Icon>
        </Conditional>
        <div>
          <Conditional when={!!title}>
            <Header>{title}</Header>
          </Conditional>
          <Wrapper
            dangerouslySetInnerHTML={{ __html: children }}
            colorLink={colorLink}
          />
        </div>
      </Content>
    </Container>
  )
}

export default Card
