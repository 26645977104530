import { Modals } from '@/components'

import ModalBase from '@/screens/FirstAccess/components/ModalBase'
import ModalSection from '@/screens/FirstAccess/components/ModalSection'

const NotRegistered = (props) => {
  return (
    <ModalBase onDismiss={props?.onDismiss} gtmSubname="solicitar-acesso">
      <ModalSection>
        Identificamos que você ainda não possui um cadastro na Porto.
      </ModalSection>
      <ModalSection title="Imobiliária:">
        <a
          href="https://cadastroimobiliaria.solucoesparalocacao.com.br?utm_source=col&utm_medium=corretor-online&utm_campaign=portal-das-imobiliarias"
          target="_blank"
          rel="noopener noreferrer"
          data-gtm-type="click"
          data-gtm-clicktype="botao"
          data-gtm-name="clique-aqui"
          data-gtm-subname="solicitar-acesso"
        >
          Clique aqui{' '}
        </a>
        para seguirmos com o seu credenciamento e, após a conclusão, você terá
        acesso a todos os benefícios e parceria Porto Seguro!
      </ModalSection>
      <ModalSection title="Corretor:">
        Identificamos que você ainda não possui um cadastro na Porto. Procure a
        sucursal Porto Seguro mais próxima e solicite seu cadastro.
      </ModalSection>
    </ModalBase>
  )
}

const NotRegisteredModal = Modals.Promised.createModal(NotRegistered)

export default NotRegisteredModal
