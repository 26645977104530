import { createStore } from 'effector'

export interface Personalization {
  broker: string
  broker_name: string
  id: string
  url_logo: string
  url_prefix: string
  loading: boolean
}

const personalizationStore = createStore<Personalization>({
  broker: '',
  broker_name: '',
  id: '',
  url_logo: '',
  url_prefix: '',
  loading: true,
})

export { personalizationStore }
