import { FC, ReactElement } from 'react'
import { ModalSectionTitle, ModalSectionDescription } from './styles'

interface Props {
  title?: string
  color?: string
}

const ModalSection: FC<Props> = ({ title, color, children }): ReactElement => {
  return (
    <ModalSectionDescription>
      {title ? (
        <ModalSectionTitle color={color}>{title}</ModalSectionTitle>
      ) : null}
      {children}
    </ModalSectionDescription>
  )
}

export default ModalSection
