import { createEffect } from 'effector'

import { api } from '@/services'
import { toast } from '@/components'

import { ContractStore } from '../store'

export const calculate = createEffect('calculateBudget', {
  handler: async () => {
    const payload = ContractStore.getState().payload
    const contractId = ContractStore.getState().id

    await api.contracts.update(contractId, payload)
    await api.contracts.calculate(contractId)
  },
})

ContractStore.on(calculate.pending, (state, pending) => ({
  ...state,
  _status: pending ? 'calculating' : 'fulfilled',
  external_status: 'PENDING',
}))
  .on(calculate.fail, (state) => {
    toast('Ocorreu um erro ao tentar atualizar o contrato.', {
      type: 'error',
    })

    return state
  })
  .on(calculate.doneData, (state) => {
    return {
      ...state,
    }
  })
