import { useState } from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Arrow, Direction, Icon, Dot, SliderContainer } from './styles'
import { ICarouselProps } from './interfaces'

const ArrowOverride = (props: {
  onClick?: React.MouseEventHandler<HTMLDivElement>
  direction: Direction
}) => {
  const { onClick, direction } = props
  return (
    <Arrow onClick={onClick} $direction={direction}>
      <Icon
        name={direction === Direction.Right ? 'chevron-right' : 'chevron-left'}
      />
    </Arrow>
  )
}

const Carousel = (props: ICarouselProps) => {
  const { children, settings } = props

  const [dotIndex, setDotIndex] = useState(0)

  return (
    <SliderContainer
      {...settings}
      afterChange={setDotIndex}
      nextArrow={<ArrowOverride direction={Direction.Right} />}
      prevArrow={<ArrowOverride direction={Direction.Left} />}
      customPaging={(i: number) => <Dot $selected={i === dotIndex} />}
    >
      {children}
    </SliderContainer>
  )
}

export default Carousel
