import styled from 'styled-components'
import media from 'styled-media-query'
import { rem, size } from 'polished'

import { Grid as GridComponent } from '@/components'
import { themes } from '@/styles'
import { pxToRem } from '@/styles/sheets/helpers'
import { FeatherIcons } from '@/components/Icons'

export const Container = styled.div``

export const Grid = styled(GridComponent).attrs({
  columns: ['auto', 'repeat(3, 1fr)'],
  gap: ['.5rem', '1rem'],
})``

export const GridAddress = styled(GridComponent).attrs({
  columns: ['auto', 'repeat(2, 1fr)'],
  gap: ['.5rem', '1rem'],
})``

export const ToggleWrapper = styled.div`
  margin-bottom: 20px;
`

export const Services = {
  Wrap: styled.div`
    margin-top: 32px;
  `,

  Title: styled.h6`
    margin-bottom: 16px;
  `,

  List: styled.div`
    display: flex;
    flex-wrap: wrap;
  `,

  Item: styled.span<{ action?: boolean }>`
    padding: 4px 6px;
    margin-right: 6px;
    margin-bottom: 6px;

    border-radius: 30px;
    font-weight: 600;
    font-size: ${rem('10px')};
    color: ${themes.text.high};
    background-color: ${themes.page.colors.content};
  `,
}

export const Label = styled.h5`
  color: ${themes.palletes.primary};

  margin-top: 1.5rem;
  margin-bottom: 1rem;

  padding-left: 8px;

  ${media.lessThan('huge')`
    margin-top: 1rem;
    margin-bottom: 0.4rem;
  `}
`

export const NotificationWrapper = styled.div`
  margin-top: ${pxToRem('32px')};
`

export const TooltipWrapper = styled.div`
  align-items: center;
  justify-content: center;
  margin-top: 2.8rem;
  margin-left: 10px;

  ${media.lessThan('huge')`
    margin-top: 2.3rem;
  `}
`

export const DatepickerWrapper = styled.div`
  display: flex;
`

export const Icon = styled(FeatherIcons)`
  ${size('12px')}

  cursor: help;
  color: ${themes.palletes.primary};

  &:not(:last-of-type) {
    margin-right: 8px;
  }

  ${media.greaterThan('large')`
    ${size('16px')}
  `}

  ${media.greaterThan('huge')`
    ${size('18px')}
  `}
`
