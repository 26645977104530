import {
  Box,
  Flex,
  Link,
  Typography,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import {
  Card,
  Notification,
  Stepper,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'

import { useAuth } from '@/modules'
import StaticPage from '@/modules/static-page'
import { AlsoOffer, BoxCards, Container, Header } from '@/modules/static-page/components'
import * as S from './styles'

const UserActionBar = () => {
  const auth = useAuth()

  return (
    <Flex
      sx={{
        padding: '1rem',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Link onClick={() => auth.handleLogout()}>
        <Typography variant="porto-text-body-1-regular" content="sair do sistema" />
      </Link>
    </Flex>
  )
}

const Hero = () => {
  return (
    <Container
      addPaddingTop={true}
      sx={{
        backgroundColor: 'porto-primitive-off-white',
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr',
            md: '1fr .7fr',
          },
          gap: '2rem',
          alignItems: 'center',
        }}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            gap: '1rem',
          }}
        >
          <Typography
            variant="porto-title-5-semibold"
            color="porto-primitive-black-85"
            content="Acompanhe"
          />
          <Card>
            <Stepper
              currentStep={5}
              showStepsNumber={true}
              steps={[
                { label: '' },
                { label: '' },
                { label: '' },
                { label: '' },
                { label: 'Assinatura do contrato' },
              ]}
            />
          </Card>
          <Box>
            <Notification
              icon="circlequestion"
              title={`
                Tanto você quanto o corretor de seguros devem assinar o
                contrato para que o Portal das Imobiliárias fique disponível.
              `}
              description={`
                Se ainda não tiver assinado, verifique sua caixa de e-mail. Peça também
                para o corretor de seguros assinar. A assinatura eletrônica é rápida e segura.
              `}
            />
          </Box>
        </Flex>
      </Box>
    </Container>
  )
}

const AwaitingRegistrationComplete = () => {
  return (
    <S.Container>
      <StaticPage
        page={[
          {
            Component: Header,
            props: {
              title: 'Que bom que você chegou!',
              description:
                'Descubra vantagens exclusivas de ser um parceiro Porto enquanto aguarda a aprovação do seu cadastro.',
            },
          },
          {
            Component: Hero,
          },
          {
            Component: BoxCards,
            props: {
              variant: 'primary',
              anchor_id: 'coverages',
              title: 'Enquanto isso, veja os benefícios',
              values: [
                {
                  columns: 4,
                  cards: [
                    {
                      icon: 'circlecheck',
                      title: 'Análise cadastral ágil e sem custo',
                      subtitle:
                        'A análise cadastral do seu inquilino é feita pela Porto e você imobiliária não tem nenhum custo com este processo.',
                    },
                    {
                      icon: 'circlecheck',
                      title: 'Garantia de recebimento',
                      subtitle:
                        'Com a Porto, sua imobiliária tem garantia do recebimento das taxas administrativas.',
                    },
                    {
                      icon: 'circlecheck',
                      title: 'Assinatura eletrônica grátis',
                      subtitle:
                        'Sua imobiliária com acesso gratuito a pacotes de assinatura eletrônica.',
                    },
                    {
                      icon: 'circlecheck',
                      title: 'Apoio de equipe especializada',
                      subtitle:
                        'Apoio técnico gratuito da Porto com os processos de cobrança em uma eventual inadimplência ou ação de despejo.',
                    },
                    {
                      icon: 'circlecheck',
                      title: 'Plataforma de apoio a venda',
                      subtitle:
                        'Acesso a treinamentos online e gravados, materiais técnicos de produtos, além de conteúdos para apoiar o seu negócio.',
                    },
                    {
                      icon: 'circlecheck',
                      title: 'Portal exclusivo para imobiliária',
                      subtitle:
                        'Otimize suas operações permitindo o gerenciamento do negócio de forma mais eficiente.',
                    },
                  ],
                },
              ],
            },
          },
          {
            Component: AlsoOffer,
            props: {
              variant: 'primary',
              title: 'Produtos disponíveis',
              values: [
                {
                  icon: 'fireflame',
                  upperTitle: 'Garantia de aluguel',
                  title: 'Seguro Incêndio',
                  description:
                    'Cobertura contra incêndio para imóveis alugados. Obrigatório por lei.',
                },
                {
                  icon: 'receiptcheck',
                  upperTitle: 'Garantia de aluguel',
                  title: 'Fiança Locatícia',
                  description:
                    'Substitui o fiador ou caução na locação de imóveis comerciais ou residenciais.',
                },
                {
                  icon: 'duplicate',
                  upperTitle: 'Garantia de aluguel',
                  title: 'Fiança Locatícia Essencial',
                  description:
                    'Substituiu o fiador, tem a vigência do contrato de locação e serviços assistenciais para o imóvel.',
                },
                {
                  icon: 'ticket',
                  upperTitle: 'Garantia de aluguel',
                  title: 'Título de Capitalização',
                  description:
                    'Substituiu o fiador, não precisa de comprovação de renda e o inquilino resgata o valor no final do contrato.',
                },
              ],
            },
          },
          {
            Component: UserActionBar,
          },
        ]}
      />
    </S.Container>
  )
}

export default AwaitingRegistrationComplete
