import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  Container,
  Content,
  Coverages,
  Button,
  Title,
  ContainerButton,
  Icon,
  Link,
  IconLink,
} from './styles'

import { formatMoneyBRL } from '@/tools'
import { Conditional, Loading, Tooltip } from '@/components'

import { PortoImobiliaria } from '@/screens/Contracts/Products/Imobiliaria/constants'
import ModalSelectPayments from '@/screens/Consultations/Listing/components/ModalSelectPayments'

import { PaymentsPagesProps } from './interfaces'

export const DetailsProduct = (props) => {
  const history = useHistory()
  const [pressed, setPressed] = useState(false)
  const [status, setStatus] = useState<'printing' | 'loading' | ''>('')

  const budget = props.details?.budgets.find((budget) => {
    return budget.id === props.details?.policy?.budget.toString()
  })

  const pagesProps: PaymentsPagesProps = {
    pressed,
    setPressed,
    status,
    setStatus,
  }

  return (
    <Container>
      <Title>Mais informações</Title>
      <Content>
        <Conditional when={status === 'printing'}>
          <Loading message="Aguarde, gerando PDF." />
        </Conditional>
        <Conditional when={props.statusContract === 'budget'}>
          <Coverages.Section>
            <Coverages.List>
              <thead>
                <tr>
                  <th>N° do orçamento</th>
                  <th>Valor do seguro</th>
                </tr>
              </thead>
              <tbody>
                {props.details.budgets.map((item) => (
                  <tr>
                    <td>{item.id}</td>
                    <td>{formatMoneyBRL(item.quotation.prize.total)}</td>
                    <td className="button-modal">
                      <Tooltip content="Escolher forma de pagamento e gerar PDF.">
                        <Link
                          onClick={() => {
                            setPressed(true)
                          }}
                        >
                          <IconLink name="printer" />
                        </Link>
                      </Tooltip>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Coverages.List>
          </Coverages.Section>
        </Conditional>
        <Conditional when={props.statusContract === 'refused'}>
          <Coverages.Section>
            <Coverages.List>
              <thead>
                <tr>
                  <th>N° da proposta</th>
                  <th>N° do orçamento</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{props.details.policy?.proposal}</td>
                  <td>{props.details.policy?.budget}</td>
                </tr>
              </tbody>
            </Coverages.List>
          </Coverages.Section>
        </Conditional>
        <Conditional when={props.statusContract === 'proposal'}>
          <Coverages.Section>
            <Coverages.List>
              <thead>
                <tr>
                  <th>N° da proposta</th>
                  <th>N° do orçamento</th>
                  <th>Valor do seguro</th>
                  <th>Tipo do orçamento</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{props.details.policy?.proposal}</td>
                  <td>{props.details.policy?.budget}</td>
                  <td>{formatMoneyBRL(budget?.quotation.prize.total)}</td>
                  <td>
                    {PortoImobiliaria.Plan[props.details.payload.contract.plan]}
                  </td>
                </tr>
              </tbody>
            </Coverages.List>
          </Coverages.Section>
        </Conditional>
        <Conditional when={props.statusContract === 'policy'}>
          <Coverages.Section>
            <Coverages.List>
              <thead>
                <tr>
                  <th>N° da apólice</th>
                  <th>N° da proposta</th>
                  <th>N° do orçamento</th>
                  <th>Valor do seguro</th>
                  <th>Tipo de plano</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{props.details.policy?.id}</td>
                  <td>{props.details.policy?.proposal}</td>
                  <td>{props.details.policy?.budget}</td>
                  <td>{formatMoneyBRL(budget?.quotation.prize.total)}</td>
                  <td>
                    {PortoImobiliaria.Plan[props.details.payload.contract.plan]}
                  </td>
                </tr>
              </tbody>
            </Coverages.List>
          </Coverages.Section>
        </Conditional>
      </Content>
      <ContainerButton>
        <Button
          onClick={() =>
            history.push({
              pathname: '/contratos/novo/imobiliaria',
              state: { ...props.details, renovation: true },
            })
          }
        >
          Editar dados
        </Button>
        <Conditional when={props.details?.renewal_ready}>
          <Button
            onClick={() =>
              history.push(`/contratos/${props.details.id}`, {
                state: props.details,
              })
            }
          >
            Manter dados e renovar <Icon name="arrow-right" />
          </Button>
        </Conditional>
      </ContainerButton>
      <Conditional when={pressed}>
        <ModalSelectPayments details={props} {...pagesProps} />
      </Conditional>
    </Container>
  )
}
