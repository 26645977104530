import api from '..'

interface Customer {
  phone: string
  birthdate?: string
  name?: string
  companyName?: string
  foundation_date?: string
}

export async function getByDocument(document: string) {
  const response = await api.instance.v2.get<Customer>(`/document`, {
    params: { document },
  })
  return response.data
}
