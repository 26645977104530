import styled from 'styled-components'
import media from 'styled-media-query'

const Title = styled.h1`
  font-weight: 500;
  font-size: 56px;
  line-height: 120%;
  letter-spacing: -0.03em;

  ${media.lessThan('medium')`  
    font-size: 42px;
  `}
`

export default Title
