import { ReactNode } from 'react'
import Dropzone from 'react-dropzone'

import { SvgIcons } from '@/components/SvgIcons'

import { Button, Content, DropContainer, Icon, UploadMessage } from './styles'

interface IUpload {
  onUpload: Function
}

const Upload = ({ onUpload }: IUpload) => {
  function renderDragMessage(
    isDragActive: boolean,
    isDragRejest: boolean
  ): ReactNode {
    if (!isDragActive) {
      return (
        <Content>
          <Icon>{SvgIcons['upload']}</Icon>

          <UploadMessage>
            Arraste seu arquivo aqui ou clique em selecionar.
          </UploadMessage>

          <Button>Selecionar arquivo</Button>
        </Content>
      )
    }

    if (isDragRejest) {
      return (
        <Content>
          <Icon>{SvgIcons['uploadAlert']}</Icon>

          <UploadMessage type="error">Arquivo não suportado</UploadMessage>
        </Content>
      )
    }

    return (
      <Content>
        <Icon>{SvgIcons['uploadCheck']}</Icon>

        <UploadMessage type="success">Solte o arquivo aqui</UploadMessage>
      </Content>
    )
  }

  return (
    <Dropzone
      accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      onDropAccepted={(files) => onUpload(files)}
      maxFiles={1}
    >
      {({ getRootProps, getInputProps, isDragActive, isDragReject }): any => (
        <DropContainer
          {...getRootProps()}
          isDragActive={isDragActive}
          isDragReject={isDragReject}
        >
          <input {...getInputProps()} data-testid="upload" />
          {renderDragMessage(isDragActive, isDragReject)}
        </DropContainer>
      )}
    </Dropzone>
  )
}

export default Upload
