import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import media from 'styled-media-query'

const settings = {
  size: css`
    width: 12px;
    height: 12px;

    border-radius: 50%;

    ${media.greaterThan('huge')`
      width: 16px;
      height: 16px;
    `}
  `,

  color: {
    default: {
      base: rgba('#52676F', 0.2),
      highlight: '#52676F',
    },
    switch: {
      base: rgba('#fff', 0.2),
      highlight: '#fff',
    },
  },
}

export const Loader = styled.div<{
  size?: string
  switch?: boolean
}>`
  ${settings.size}

  position: relative;
  transform: translateZ(0);

  display: inline-block;

  border: 1px solid ${settings.color.default.base};
  border-left-color: ${settings.color.default.highlight};

  animation: rotate 1.1s infinite linear;

  &:after {
    ${settings.size}
  }

  ${media.greaterThan('huge')`
    border-width: 2px;
  `}

  ${(props) =>
    props.size &&
    css`
      width: ${props.size}px;
      height: ${props.size}px;

      ${media.greaterThan('huge')`
        width: ${props.size + 4}px;
        height: ${props.size + 4}px;
      `}
    `}

  ${(props) =>
    props.switch &&
    css`
      border: 1px solid ${settings.color.switch.base};
      border-left-color: ${settings.color.switch.highlight};
    `}

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export default Loader
