import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { rem, rgba } from 'polished'

import { Loader as LoaderComponent } from '@/components/Icons'
import { themes } from '@/styles'

import { FeatherIcons, Ionicons } from '../../Icons'
import { IMessageProps } from './interfaces'

export const Loader = styled(LoaderComponent).attrs(() => ({
  size: '20px',
}))`
  margin-right: 8px;
`

export const Icon = styled(FeatherIcons && Ionicons)`
  margin-top: 10px;
  margin-right: 8px;
  font-size: ${rem('20px')};
`

export const Action = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 36px;
`

export const IconAction = styled(FeatherIcons && Ionicons)`
  color: ${themes.palletes.primary};
  font-size: ${rem('26px')};

  cursor: pointer;
`

export const Title = styled.div`
  margin-bottom: 8px;
  margin-top: 12px;
  font-size: ${rem('16px')};
`

export const Content = styled.div`
  flex: 1;

  line-height: 20px;
  font-weight: 500;
  font-size: ${rem('14px')};
  color: ${themes.text.low};

  ${media.lessThan('medium')`
    max-width:257px ;
    height:100% ;
  `}
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const WrapperContent = styled(Wrapper)`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`

export const Container = styled.div<IMessageProps>`
  position: relative;

  padding: 8px 10px 16px 10px;

  display: flex;
  align-items: inherit;

  font-weight: 500;
  border-radius: 8px;

  ${media.lessThan('medium')`
    width: 100%;
    padding: 8px ;

    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

  `}

  &:before {
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);

    width: 3px;
    height: 80%;

    display: block;

    content: '';
    border-radius: 30px;

    ${media.greaterThan('huge')`
      width: 4px;
    `}
  }

  ${(props) =>
    props.kind &&
    {
      info: css`
        border: 0.5px solid ${themes.palletes.primary};

        ${Title} {
          color: ${themes.palletes.primary};
        }

        ${Icon} {
          color: ${themes.palletes.primary};
        }
      `,

      danger: css`
        background-color: ${(props: IMessageProps) =>
          props.background
            ? '#ffcdc64d'
            : `${rgba(themes.palletes.danger, 0.0)}`};

        border: ${(props) =>
          props.background ? '0' : `0.5px solid ${themes.palletes.danger}`};

        ${Title} {
          color: ${themes.palletes.danger};
        }

        ${Icon} {
          color: ${themes.palletes.danger};
        }
      `,

      warning: css`
        background-color: ${(props: IMessageProps) =>
          props.background
            ? '#f8f6c24d'
            : `${rgba(themes.palletes.warning, 0.0)}`};

        border: ${(props) =>
          props.background ? '0' : `0.5px solid ${themes.palletes.warning}`};

        ${Title} {
          color: ${themes.palletes.warning};
        }

        ${Icon} {
          color: ${themes.palletes.warning};
        }
      `,

      success: css`
        background-color: ${(props: IMessageProps) =>
          props.background
            ? '#c0f0d34d'
            : `${rgba(themes.palletes.success, 0.0)}`};

        border: ${(props) =>
          props.background ? '0' : `0.5px solid ${themes.palletes.success}`};

        ${Title} {
          color: ${themes.palletes.success};
        }

        ${Icon} {
          color: ${themes.palletes.success};
        }
      `,
    }[props.kind]}
`
