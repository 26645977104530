import styled from 'styled-components'
import media from 'styled-media-query'
import { themes } from '@/styles'

const Subtitle = styled.p`
  color: ${themes.text.high};
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  width: 85%;

  ${media.lessThan('medium')`  
    width: 100%;
  `}
`

export default Subtitle
