import styled from 'styled-components'
import { pxToRem } from '@/styles/sheets/helpers'

export const Header = styled.div`
  margin-top: 20px;

  & :first-child {
    width: ${pxToRem(40)};
    height: ${pxToRem(40)};
    padding: ${pxToRem(9)} ${pxToRem(10)};
    background: #f1ec85;
    border-radius: ${pxToRem(8)};
  }

  & :last-child {
    padding-left: ${pxToRem(12)};
  }
`

export const Content = styled.div`
  padding: ${pxToRem(32)} 0 ${pxToRem(8)};

  > div {
    display: inline;
  }
`
