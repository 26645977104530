import { css } from 'styled-components'
import theme from 'styled-theming'
import { lighten, rgba } from 'polished'

import text from './text'
import palletes from '../palletes'

export const kinds = theme.variants('mode', 'kind', {
  link: {
    light: css`
      color: ${text.medium};

      background-color: #e8eaf1;
    `,
    dark: css`
      color: #fff;

      background-color: #262a31;
    `,
  },

  smooth: {
    light: css<{
      variant?: string
    }>`
      color: ${props => palletes[props.variant]};

      background-color: ${props => rgba(palletes[props.variant], 0.1)};

      &:hover {
        color: ${props => lighten(1, palletes[props.variant])};

        background-color: ${props => palletes[props.variant]};
      }
    `,
    dark: css<{
      variant?: string
    }>`
      color: ${props => palletes[props.variant]};

      background-color: ${props => rgba(palletes[props.variant], 0.1)};

      &:hover {
        color: ${props => lighten(1, palletes[props.variant])};

        background-color: ${props => palletes[props.variant]};
      }
    `,
  },

  ghost: {
    light: css<{
      variant?: string
    }>`
      font-weight: 600;
      color: ${props => palletes[props.variant]};

      border-color: ${props => palletes[props.variant]};
      background-color: transparent;

      &:hover {
        background-color: ${props => rgba(palletes[props.variant], 0.05)};
      }

      ${({ variant }) =>
        variant === 'primary' &&
        `
        &:hover {
          background-color: ${rgba('#D7E0F5', 0.4)};
        }
      `}
    `,
    dark: css<{
      variant?: string
    }>`
      color: ${props => palletes[props.variant]};

      border-color: ${props => palletes[props.variant]};
      background-color: transparent;

      &:hover {
        background-color: ${props => rgba(palletes[props.variant], 0.05)};
      }

      ${({ variant }) =>
        variant === 'primary' &&
        `
        &:hover {
          background-color: ${lighten(0.5, '#D7E0F5')};
        }
      `}
    `,
  },

  simple: {
    light: css<{
      variant?: string
    }>`
      font-weight: 600;
      color: ${props => palletes[props.variant]};

      border: 0;
      background-color: transparent;

      &:hover {
        background-color: ${props => rgba(palletes[props.variant], 0.05)};
      }

      ${({ variant }) =>
        variant === 'primary' &&
        `
        &:hover {
          background-color: ${rgba('#D7E0F5', 0.4)};
        }
      `}
    `,
    dark: css<{
      variant?: string
    }>`
      color: ${props => palletes[props.variant]};

      border: 0;
      background-color: transparent;

      &:hover {
        background-color: ${props => rgba(palletes[props.variant], 0.05)};
      }

      ${({ variant }) =>
        variant === 'primary' &&
        `
        &:hover {
          background-color: ${lighten(0.5, '#D7E0F5')};
        }
      `}
    `,
  },
})
