import * as elements from './elements'
import palletes from './palletes'

export type ProductColors = 'aluguel' | 'imobiliaria' | 'essencial'

const themes = {
  ...elements,
  palletes,
}

export default themes
