import { generateRangeNumbers } from '@/tools'

import { commission, options } from '../commons'

export default {
  'porto_aluguel.university.enabled': {
    type: 'toggle',
    title: 'Plano universitário',
    description: 'Habilite ou desabilite o plano universitário',
  },
  'porto_aluguel.university.comission': commission(
    [10, ...generateRangeNumbers(15, 35)],
    ({ getValue }) => !getValue('porto_aluguel.university.enabled')
  ),
  'porto_aluguel.university.coverages': {
    type: 'checkbox',
    title: 'Coberturas',
    description: 'Selecione as coberturas disponíveis.',
    disabled: ({ getValue }) => !getValue('porto_aluguel.university.enabled'),
    options: options('porto_aluguel.university.coverages', [
      {
        value: 'rent',
        label: 'Aluguel',
        disabled: ({ user }) => !user.store.isAdmin,
      },
      {
        value: 'iptu',
        label: 'IPTU',
      },
      {
        value: 'condominium',
        label: 'Condomínio',
      },
      {
        value: 'water',
        label: 'Água',
      },
      {
        value: 'gas',
        label: 'Gás',
      },
      {
        value: 'electricity',
        label: 'Luz',
      },
      {
        value: 'property_damages',
        label: 'Danos ao imóvel',
      },
      {
        value: 'recision_fine',
        label: 'Multa por Rescisão',
      },
      {
        value: 'external_paint',
        label: 'Pintura interna',
      },
      {
        value: 'internal_paint',
        label: 'Pintura externa',
      },
    ]),
  },
}
