import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import {
  menuStore,
  personalizationStore,
  setIsMenuOpen,
  tourStore,
  useAuth,
  useCurrentOrganization,
  useOrganizations,
} from '@/modules'
import { Icon as PortoIcon } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { Icon as SystemIcon } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/system'
import { useHistory } from 'react-router'
import * as S from './styles'
import { Link } from 'react-router-dom'
import { isBroker } from '@/tools/partnership'
import { URLS } from '@/constants'
import { useEffect, useState } from 'react'
import { useUnit } from 'effector-react'

const getInitials = (name?: string) => {
  if (!name) {
    return ''
  }

  const [firstName, secondName] = name.trim().split(' ').filter(Boolean)

  if (!firstName) {
    return ''
  }

  const [firstLetter] = firstName.split('')

  if (!secondName) {
    return firstLetter
  }

  const [secondLetter] = secondName.split('')

  return `${firstLetter}${secondLetter}`
}

export const Header = () => {
  const organization = useCurrentOrganization()
  const organizations = useOrganizations()
  const auth = useAuth()
  const { push } = useHistory()
  const { url_logo, broker } = useUnit(personalizationStore)
  const [tour, menu, setIsMenuOpenFn] = useUnit([
    tourStore,
    menuStore,
    setIsMenuOpen,
  ])

  const isTourRunning = !tour.completed

  const [isOrganizationMenuOpen, setOrganizationMenuOpen] = useState(false)
  const [isAccountenuOpen, setAccountMenuOpen] = useState(false)

  useEffect(() => {
    if (!isTourRunning) {
      window.addEventListener('click', handleClose)
    }

    return () => {
      if (!isTourRunning) {
        window.removeEventListener('click', handleClose)
      }
    }
  }, [isTourRunning])

  useEffect(() => {
    setOrganizationMenuOpen(isTourRunning)
    setAccountMenuOpen(isTourRunning)
  }, [isTourRunning])

  const toggleAccountMenu = (e: React.MouseEvent) => {
    e.stopPropagation()
    setAccountMenuOpen((prev) => !prev)
    setOrganizationMenuOpen(false)
  }
  const toggleOrganizationMenu = (e: React.MouseEvent) => {
    e.stopPropagation()
    setOrganizationMenuOpen((prev) => !prev)
    setAccountMenuOpen(false)
  }

  const handleClose = () => {
    setOrganizationMenuOpen(false)
    setAccountMenuOpen(false)
  }

  return (
    <S.Container>
      <S.MenuIcon onClick={() => setIsMenuOpenFn(true)}>
        <SystemIcon
          color="system-primitive-neutral-800"
          size={20}
          name="menu"
        />
      </S.MenuIcon>
      <S.Brand>
        <img
          src={url_logo ? url_logo : '/logo-portobank-2023.svg'}
          alt="Porto Seguro Bank"
        />
        {!broker && (
          <>
            <S.Divider />
            <Typography
              color="system-primitive-neutral-500"
              variant="porto-title-6-medium"
              content="Portal das Imobiliárias"
            />
          </>
        )}
      </S.Brand>
      <S.Name onClick={toggleOrganizationMenu}>
        <S.Text
          color="system-primitive-neutral-800"
          variant="porto-text-body-2-regular"
          content={organization.name}
        />
        <S.IconContainer>
          <PortoIcon name="shortarrowdown" />
        </S.IconContainer>
        {isOrganizationMenuOpen && (
          <S.OrganizationMenu
            $isVisible={!isTourRunning || menu.isOrganizationMenuVisible}
          >
            <S.MenuTitle
              variant="porto-text-body-2-bold"
              content={organization.name}
            />
            <S.MenuButton
              onClick={() => push('/minha-organizacao/informacoes')}
            >
              <PortoIcon
                size={20}
                color="system-primitive-neutral-800"
                name="building"
              />
              <Typography
                color="system-primitive-neutral-800"
                variant="porto-text-body-2-regular"
                content="Configurações da Organização"
              />
            </S.MenuButton>
            <S.MenuButton
              onClick={() => organizations.unsetActiveOrganization()}
            >
              <PortoIcon
                size={20}
                color="system-primitive-neutral-800"
                name="circlesynchronize"
              />
              <Typography
                color="system-primitive-neutral-800"
                variant="porto-text-body-2-regular"
                content="Trocar Organização"
              />
            </S.MenuButton>
            <S.MenuDivider />
            <Link
              to={{
                pathname: isBroker(organization)
                  ? URLS.USE_TERMS_PDF_BROKER
                  : URLS.USE_TERMS_PDF_REAL_ESTATE,
              }}
              target="_blank"
            >
              <S.MenuButton>
                <PortoIcon
                  size={20}
                  color="system-primitive-neutral-800"
                  name="paper"
                />
                <Typography
                  color="system-primitive-neutral-800"
                  variant="porto-text-body-2-regular"
                  content="Termos de Uso"
                />
              </S.MenuButton>
            </Link>
          </S.OrganizationMenu>
        )}
      </S.Name>
      <S.Avatar onClick={toggleAccountMenu}>
        <Typography
          variant="porto-text-caption-bold"
          content={getInitials(organization.name)}
        />
        {isAccountenuOpen && (
          <S.AccountMenu
            $isVisible={!isTourRunning || menu.isAccountMenuVisible}
          >
            <S.MenuButton onClick={() => push('/minha-conta')}>
              <PortoIcon
                size={20}
                color="system-primitive-neutral-800"
                name="circleuser"
              />
              <Typography
                color="system-primitive-neutral-800"
                variant="porto-text-body-2-regular"
                content="Meu Perfil"
              />
            </S.MenuButton>
            <S.MenuDivider />
            <S.MenuButton onClick={() => auth.handleLogout()}>
              <SystemIcon
                size={20}
                color="system-primitive-neutral-800"
                name="logout"
              />
              <Typography
                color="system-primitive-neutral-800"
                variant="porto-text-body-2-regular"
                content="Sair"
              />
            </S.MenuButton>
          </S.AccountMenu>
        )}
      </S.Avatar>
    </S.Container>
  )
}
