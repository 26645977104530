export const secondStepForm = {
  estate: {
    type: "RESIDENCIAL",
    address: {
      zipcode: "",
      street: "",
      number: "",
      neighborhood: "",
      city: "",
      state: "",
      complement: ""
    }
  },
  landlord: {
    name: "",
    document: "",
    phone: "",
    email: "",
    birthdate: "",
    address_type: "RESIDENCIAL",
    address: {
      zipcode: "",
      number: ""
    }
  },
  payment: {
    type: "boleto"
  }
}