import { useState } from 'react'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Carousel, NewsCard } from '@/components'
import { useRemoteConfig } from '@/tools/hooks/firebase'
import { useCurrentOrganization } from '@/modules'
import Button from '@/components/Actions/Button'
import { ButtonContainer, CardsContainer, Container, Header } from './styles'

const News = () => {
  const { values } = useRemoteConfig()
  const profile = useCurrentOrganization()
  const isBroker = profile.type === 'BROKER'
  const newsCardData = values.newsCards
  const newsBanners = values.newsBanners
  const [cardsToShow, setCardsToShow] = useState(3)

  const handleClick = () => {
    if (cardsToShow < newsCardData.length) {
      setCardsToShow((prev) => prev + 3)
    }
  }

  const renderCards = () =>
    newsCardData
      .filter((data) => !isBroker || !data.isRealEstateOnly)
      .slice(0, cardsToShow)
      .map((data, index) => <NewsCard key={index} {...data} />)

  const renderBannerCarousel = () => {
    return (
      <Carousel
        settings={{
          dots: true,
          infinite: true,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                arrows: false,
              },
            },
          ],
        }}
      >
        {newsBanners
          .filter((data) => !isBroker || !data.isRealEstateOnly)
          .map((banner) => (
            <div key={banner.image}>
              <img
                style={{ margin: '0 auto', width: '100%', maxWidth: '900px' }}
                src={banner.image}
                alt="banner"
              />
            </div>
          ))}
      </Carousel>
    )
  }

  return (
    <Container>
      <Header>
        <Typography
          color="system-primitive-neutral-900"
          content="Novidades"
          variant="porto-title-4-bold"
        />
        <Typography
          color="system-primitive-neutral-900"
          content="Aqui você fica por dentro de tudo que é lançado no portal!"
          variant="porto-text-body-1-regular"
        />
      </Header>
      {renderBannerCarousel()}
      <CardsContainer>{renderCards()}</CardsContainer>
      {cardsToShow < newsCardData.length && (
        <ButtonContainer>
          <Button type="button" kind="ghost" onClick={handleClick}>
            Carregar mais
          </Button>
        </ButtonContainer>
      )}
    </Container>
  )
}

export default News
