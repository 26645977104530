import React from 'react'

import { ILoaderProps } from './interfaces'

import { Container, Left, Icon, Message } from './styles'

const Loader: React.FC<ILoaderProps> = ({ message, alignment, ...props }) => (
  <Container alignment={alignment} {...props}>
    <Left>
      <Icon />
    </Left>

    <Message>{message}</Message>
  </Container>
)

Loader.defaultProps = {
  message: 'Hint text',
  alignment: 'center',
}

export default Loader
