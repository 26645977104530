import {
  Anchors,
  BoxCards,
  CardBanner,
  Faq,
  Header,
} from '@/modules/static-page/components'

export const cartaoPortoContent = [
  {
    Component: Header,
    props: {
      title: 'Cartão de Crédito Porto Bank',
      shortcuts: [
        {
          label: 'Indicar clientes',
          color: 'seguros',
          icon: 'add',
          url: '/produtos/cartao-porto/indicar',
        },
      ],
      side: [
        {
          Component: Anchors,
          props: {
            title: 'Navegue pelos tópicos',
            values: [
              {
                label: 'Benefícios',
                anchor: '#benefits',
              },
              {
                label: 'Como funciona',
                anchor: '#contract_steps',
              },

              {
                label: 'Tire suas dúvidas',
                anchor: '#faq',
              },
            ],
          },
        },
      ],
    },
  },
  {
    Component: CardBanner,
  },
  {
    Component: BoxCards,
    props: {
      variant: 'secondary',
      title: 'Principais benefícios',
      anchor_id: 'benefits',
      values: [
        {
          columns: 4,
          cards: [
            {
              icon: 'circledollarsign',
              subtitle:
                '<strong>Até 12 meses de anuidade grátis</strong> para conhecer e aproveitar os benefícios e vantagens que ele oferece.',
            },
            {
              icon: 'cleancar',
              subtitle:
                '<strong>Tag Porto Bank grátis e sem mensalidade.</strong> Evite filas em estacionamentos e pedágios pelo Brasil, e apenas o que usar.',
            },
            {
              icon: 'gaspump',
              subtitle:
                '<strong>Desconto de até R$ 0,15 por litro de combustível</strong> pagando pelo App Porto em postos equipados com Shell Box.',
            },
            {
              icon: 'giftbox',
              subtitle:
                'Junte pontos no PortoPlus e troque por descontos em produtos Porto, viagens, milhas e muito mais. São mais de 200 parceiros com ofertas especiais. ',
            },
            {
              icon: 'car',
              subtitle:
                '<strong>5% de desconto ao contratar + 5% de cashback na fatura do cartão</strong> ao contratar o Porto Seguro Auto e Azul Seguro Auto e 10% de desconto na renovação, parcelamento em até 12x sem juros.',
            },
            {
              icon: 'portoseguro',
              subtitle:
                'Benefícios e descontos na Porto. São vantagens nos seguros de Vida, Residencial, Viagem e Celular, além dos serviços da Porto Faz e do Reppara!',
            },
            {
              icon: 'smartphone',
              subtitle:
                'Pague por aproximação com seu celular ou smartwatch utilizando as carteiras digitais Google Pay, Samsung Pay e Apple Pay.',
            },
          ],
        },
      ],
    },
  },
  {
    Component: BoxCards,
    props: {
      title: 'Como funciona',
      description: 'Entenda a jornada completa, da indicação ao contrato.',
      anchor_id: 'contract_steps',
      values: [
        {
          columns: 3,
          cards: [
            {
              icon: '1',
              title: 'Indicação do cliente',
              subtitle:
                'Selecione os clientes que deseja indicar na lista de pré aprovados.',
              link: {
                label: 'Indicar cliente',
                url: '/cartao-porto/indicar',
              },
            },
            {
              icon: '2',
              title: 'Envio da comunicação',
              subtitle:
                'Os clientes que você indicou receberão o e-mail  do convite com o link para contratar.',
            },
            {
              icon: '3',
              title: 'Contratação online',
              subtitle:
                'O cliente acessa a página e contrata online, em poucos passos.',
            },
          ],
        },
      ],
    },
  },
  {
    Component: Faq,
    props: {
      variant: 'primary',
      title: 'Tire suas dúvidas',
      anchor_id: 'faq',
      values: [
        {
          question: 'Como funciona a anuidade do cartão de crédito?',
          answer:
            'Ao solicitar o Cartão Porto Bank você conta com 1 ano de isenção de anuidade na 1ª bandeira e 6 meses de isenção na 2ª bandeira, para conhecer e aproveitar todos os benefícios e vantagens que ele oferece. Ao fim desse período, o valor do desconto será calculado de acordo com seus gastos mensais, ou seja, quanto mais você utilizar seu cartão nas compras do dia a dia, maior será sua economia.',
        },
        {
          question:
            'Quais os benefícios do programa de relacionamento Cartão Porto Bank?',
          answer:
            'Ao fazer suas compras com o Cartão Porto Bank, você acumula pontos que podem ser convertidos em diversos produtos, serviços e descontos. Alguns benefícios são: troca de pontos por milhas aéreas e pacotes de viagens, descontos em lojas parceiras de vestuário e eletroeletrônicos e descontos em serviços para seu carro nos Centros Automotivos Porto.',
        },
        {
          question: 'Qual é a validade dos pontos do Cartão Porto Bank?',
          answer:
            'Os pontos possuem validade de 24 (vinte e quatro) meses móveis. Caso os pontos não sejam utilizados neste prazo, ao serem concedidos os pontos adquiridos no 25º (vigésimo quinto) mês, prescreverão os pontos do 1º (primeiro) mês, e assim sucessivamente.',
        },
        {
          question:
            'Quais as formas de pagamento da fatura do meu cartão de crédito?',
          answer:
            'Você pode gerar um boleto ou código Pix pelo App Porto para pagar a fatura do seu Cartão Porto Bank. Também pode cadastrar sua fatura em débito automático com o banco de sua preferência.',
        },
      ],
    },
  },
]
