import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import media from 'styled-media-query'
import { themes } from '@/styles'

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background: #f8f6c2;
  border-radius: 30px;
  margin-bottom: 16px;

  ${media.lessThan('medium')`
    margin-bottom: 12px;
  `}
`

export const Content = styled.div`
  display: flex;
  padding: 19px 24px 19px;
  width: 85%;

  ${media.lessThan('medium')`
    padding: 11px 6px 0px;
  `}
`

export const Icon = styled.div<{ onlyMessage: boolean }>`
  color: ${themes.text.higher};
  margin: 4px 20px 4px 0px;
  display: flex;

  svg {
    width: 24px;
    color: #94621f;
  }

  ${media.lessThan('medium')`
    display: none ;
  `}

  ${({ onlyMessage }) =>
    onlyMessage &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `}
`

export const Header = styled.div`
  display: flex;
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.025em;
  width: 100%;

  color: #000000;

  ${media.lessThan('medium')`
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    letter-spacing: 0.025em;   
  `}
`

export const Wrapper = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.025em;

  color: #000000;

  ${media.lessThan('medium')`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.03em;
  `}
`

export const WrapperLink = styled.div`
  padding: 27px 0px 18px 0px;
  display: flex;
  align-items: center;

  div {
    display: flex;
    align-items: center;
    align-self: center;
  }

  a {
    display: inline-block;
    position: relative;
    color: ${themes.palletes.primary};
    font-weight: bold;
  }

  a:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: ${themes.palletes.primaryHover};
    transform-origin: bottom left;
    transition: transform 0.25s ease-out;
  }

  a:hover:after {
    transform: scaleX(1.1);
    transform-origin: bottom left;
  }

  svg {
    color: ${themes.palletes.primary};
  }
`
export const Links = styled(Link)`
  font-weight: 400;

  font-family: ${themes.fonts.text};
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.025em;
  text-decoration-line: none;

  color: ${themes.palletes.primary};

  ${media.lessThan('medium')`
    margin-left: 0px;
  `}
`

export const LinkRedirect = styled.div`
  font-weight: 400;

  font-family: ${themes.fonts.text};
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.025em;
  text-decoration-line: none;

  color: ${themes.palletes.primary};

  ${media.lessThan('medium')`
    margin-left: 0px;
  `}
`
