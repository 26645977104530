import styled, { css } from 'styled-components'
import { rem } from 'polished'
import media from 'styled-media-query'

import { themes } from '@/styles'

interface Props {
  backgroundNone?: boolean;
   disabled?: boolean;
   isActive?: boolean;
 }

export const Content = styled.div`
  line-height: 180%;
  font-size: ${rem('8px')};
  color: ${themes.text.higher};

  ${media.greaterThan('huge')`
    font-size: ${rem('12px')};
  `}
`

export const Header = styled.div`
  border-bottom: 1px solid rgba(179, 179, 179, 0.5);
  padding: 18px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Icon = styled.div``

export const Title = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.02em;
  margin-left: 35px;
  color: #2f2663;

  ${media.lessThan('medium')`
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;

    letter-spacing: 0.03em;

    color: #2F2663;
  `}
`

export const CloseButton = styled.div`
  cursor: pointer;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;

  &:hover {
    background: #f7f7f7;
    border-radius: 30px;
  }
`

export const Item = styled.div`
  padding: 16px 24px;
  margin: 0;
`

export const Subtitle = styled.h4`
  font-family: 'Porto Roobert';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 16px;

  letter-spacing: 0.02em;

  color: #2f2663;

  ${media.lessThan('medium')`
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.025em;
  `}
`

export const Options = styled.div<{ firstColumn?: boolean }>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 26px;

  ${({ firstColumn }) =>
    firstColumn &&
    css`
      grid-template-columns: 1fr;
    `}
`

export const Footer = styled.div`
  padding: 16px 24px;
  display: flex;

  border-top: 1px solid rgba(179, 179, 179, 0.5);
  gap: 16px;

  margin-top: 24px;
`

export const Button = styled.button<Props>`
  padding: 6px 12px;
  cursor: pointer;
  width: 185px;
  height: 32px;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  letter-spacing: 0.025em;

  color: #2c3da8;

  background: ${(props) => props.isActive ? '#eff4ff' : "#ffffff" } ;

  border: 1px solid #2c3da8;
  border-radius: 30px;

  &:focus {
    background-color: #eff4ff;
  }

  &:hover {
    background-color: #eff4ff;
  }
`
