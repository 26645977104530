import {
  DataGrid,
  GridColDef,
  ptBR,
} from '@mui/x-data-grid'
import { InstallmentsTableRowData } from './interfaces'

export const InstallmentsTable = ({
  installmentsData = [],
}: {
  installmentsData: InstallmentsTableRowData[]
}) => {
  const defaultColumnProps = {
    sortable: false,
    filterable: false,
    hideable: false,
    groupable: false,
    editable: false,
    resizable: false,
  }

  const columns: GridColDef[] = [
    {
      ...defaultColumnProps,
      field: 'installmentDate',
      headerName: 'Data',
      flex: 1,
    },
    {
      ...defaultColumnProps,
      field: 'installmentNumber',
      headerName: 'Parcela',
      flex: 1,
    },
    {
      ...defaultColumnProps,
      field: 'amortization',
      headerName: 'Amortização',
      flex: 1,
    },
    {
      ...defaultColumnProps,
      field: 'fees',
      headerName: 'Juros',
      flex: 1,
    },
    {
      ...defaultColumnProps,
      field: 'mip',
      headerName: 'MIP',
      flex: 1,
    },
    {
      ...defaultColumnProps,
      field: 'dfi',
      headerName: 'DFI',
      flex: 1,
    },
    {
      ...defaultColumnProps,
      field: 'installmentValue',
      headerName: 'Parcela',
      flex: 1,
    },
    {
      ...defaultColumnProps,
      field: 'balance',
      headerName: 'Saldo',
      flex: 1,
    },
  ]

  const rows = installmentsData

  return (
    <DataGrid
      {...{
        localeText: ptBR.components.MuiDataGrid.defaultProps.localeText,
        initialState: {
          pagination: { paginationModel: { pageSize: 10 } },
        },
        pageSizeOptions: [5, 10, 20, 100],
        checkboxSelection: false,
        hideFooter: false,
        disableColumnMenu: true,
        columns,
        rows,
        sx: {
          '.MuiDataGrid-cell': {
            fontSize: '.778rem',
          },
          '.MuiDataGrid-columnHeaders': {
            background: '#EBF1EC',
          },
          '.MuiDataGrid-columnHeaderTitle': {
            fontSize: '.778rem',
            fontWeight: 'bold',
          },
          '.MuiDataGrid-root': {
            borderRadius: 4,
          },
          '.MuiDataGrid-virtualScroller': {
            minHeight: 40,
          },
        },
      }}
    />
  )
}
