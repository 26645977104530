import React from 'react'

import { Grid, Forms } from '@/components'
import { banks, removeSpecialChars } from '@/tools'

import * as S from './styles'

const DebitAccount = () => {
  return (
    <S.Container>
      <Grid gap="1rem" columns="repeat(3, 1fr)">
        <Forms.InputGroup
          name="debit.document"
          label="CPF do titular"
          placeholder="Digite o CPF do titular"
          mask="document"
        />

        <Forms.Autocomplete
          name="debit.bank"
          label="Banco"
          emptyMessage="Informe o banco"
          onSearch={async (term) =>
            banks
              .filter(
                (bank) =>
                  removeSpecialChars(bank.name)
                    .toLowerCase()
                    .includes(removeSpecialChars(term).toLocaleLowerCase()) ||
                  bank.id.includes(term)
              )
              .map((bank) => ({
                label: bank.id + ' - ' + bank.name,
                value: bank.id,
              }))
          }
        />

        <Forms.InputGroup
          name="debit.agency"
          label="Agência sem o dígito"
          placeholder="Digite o número da agência"
        />

        <Forms.InputGroup
          name="debit.account"
          label="Conta corrente sem dígito"
          placeholder="Digite o número da conta"
        />

        <Forms.InputGroup
          name="debit.digit"
          label="Dígito da conta"
          placeholder="Digite o dígito da conta"
        />
      </Grid>
    </S.Container>
  )
}

export default DebitAccount
