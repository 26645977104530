import Tippy from '@tippyjs/react'
import { useSpring } from 'framer-motion'

import { TooltipProps } from './types'
import * as S from './styles'

const TooltipError = ({
  placement = 'top',
  content,
  children,
  maxWidth,
  maxHeight,
  status,
  interactive,
  hideOnClick,
  background,
  color,
  backgroundArrow,
  setClick,
}: TooltipProps) => {
  const opacity = useSpring(0)
  // const scale = useSpring(0.9)

  const onHide = () => {
    opacity.set(0)
  }

  const onMount = () => {
    // scale.set(1)
    opacity.set(1)
  }

  if (!content) return <S.Children>{children}</S.Children>

  return (
    <Tippy
      visible={hideOnClick}
      interactive={interactive}
      animation
      onHide={onHide}
      onMount={onMount}
      placement={placement}
      render={(attrs) => (
        <S.Tooltip
          style={{
            // scale,
            backgroundColor: background ? background : '',
            color: color ? color : '',
            opacity,
          }}
          {...attrs}
        >
          <S.Content
            style={{
              maxWidth: maxWidth ?? 'auto',
              maxHeight: maxHeight ?? 'auto',
            }}
          >
            {status && (
              <>
                <S.ContainerHead>
                  <S.TitleStatus>{status}</S.TitleStatus>
                </S.ContainerHead>
              </>
            )}
            {content.map((errors, index) => {
              return (
                <p key={index}>
                  {index + 1}. {errors}
                </p>
              )
            })}
          </S.Content>
          <S.Arrow backgroundArrow={backgroundArrow} data-popper-arrow />
        </S.Tooltip>
      )}
    >
      <S.Children>{children}</S.Children>
    </Tippy>
  )
}

export default TooltipError
