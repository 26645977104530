import { Flex, Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Icon } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'

import { Actions } from '@/components'

const Link = ({
  label,
  icon,
  to,
}: {
  label: string,
  icon?: string,
  to: string,
}) => {
  return (
    <Actions.Link
      to={to}
    >
      <Flex
        sx={{
          alignItems: "center",
          gap: ".25rem",
        }}
      >
        {icon && <Icon
          name={icon}
          color="system-text-highlight"
        />}
        <Typography
          variant="porto-text-link-body-2-bold"
          color="system-text-highlight"
          content={label}
        />
      </Flex>
    </Actions.Link>
  )
}

export default Link
