import { Fragment } from 'react'
import { IAutoCompleteLocal } from './interfaces'
import Autocomplete from '@mui/material/Autocomplete'
import { CircularProgress } from '@mui/material'
import { ValidationTextField } from './styles'

export function AutocompleteLocal<OptionType>({
  loading = false,
  sx = {},
  error = false,
  errorMessage = '',
  selectionCallback = () => {},
  options = [],
  value = null,
  disabled = false,
  getOptionLabel,
  isOptionEqualToValue,
  id,
  label,
}: IAutoCompleteLocal<OptionType>) {
  const handleChange = (event: any, newValue: OptionType | null) => {
    selectionCallback(newValue)
  }

  return (
    <Autocomplete
      disablePortal
      loading={loading}
      disabled={loading || disabled}
      id={id}
      options={options}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      value={value}
      sx={{
        width: 300,
        ...sx,
      }}
      renderInput={params => (
        <ValidationTextField
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
          error={error}
          helperText={errorMessage}
        />
      )}
      onChange={handleChange}
    />
  )
}
