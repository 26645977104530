export const isCapitalizacaoEnabled = (profile) => {
  if (profile.type === 'BROKER') return true
  return profile.partnerships.some(
    (partnership) => (
      partnership.settings['porto_capitalizacao.enabled']
    )
  )
}
export const isCapitalizacaoCreditCardEnabled = ({ profile, susep }) => {
  if (profile.type === 'BROKER') return true

  if (!susep) {
    return profile.partnerships.some(
      (partnership) => (
        partnership.settings['porto_capitalizacao.payments.creditCard.enabled']
      )
    )
  }

  const partnership = profile.partnerships.find(p => p.suseps.includes(susep))
  return partnership.settings['porto_capitalizacao.payments.creditCard.enabled']
}
