import { ICardsProps } from './interfaces'
import { Conditional, Icons } from '@/components'

import * as S from './styles'
import { SvgIcons } from '@/components/SvgIcons'

export const Cards = ({ children, title, icon, isSvgIcons }: ICardsProps) => {
  return (
    <S.Section>
      <S.Content>
        <div className="conten">
          <S.IconIons>
            <Conditional when={isSvgIcons}>{SvgIcons[icon]}</Conditional>
            <Conditional when={!isSvgIcons}>
              <Icons.FeatherIcons height={24} width={24} name={icon} />
            </Conditional>
          </S.IconIons>
          <S.Title>{title}</S.Title>
        </div>

        <S.Wrapper>
          <S.Text dangerouslySetInnerHTML={{ __html: children }} />
        </S.Wrapper>
      </S.Content>
    </S.Section>
  )
}
