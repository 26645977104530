import { themes } from '@/styles'
import styled from 'styled-components'
import media from 'styled-media-query'

export const Section = styled.section`
  background-color: #ffff;
  color: #444;
  padding: 24px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  border-radius: 8px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  width: 100%;

  .conten {
    width: 100%;
    display: flex;
    align-items: center;
  }

  ${media.lessThan('medium')`
    padding-top: 16px;
  `}
`

export const IconIons = styled.div`
  width: 48px;
  height: 48px;
  background: #bfccec;
  border-radius: 8px;

  justify-content: center;
  display: flex;
  align-items: center;

  margin-right: 24px;

  svg {
    color: ${themes.palletes.terciary};
  }

  ${media.lessThan('medium')`
    display: none;
  `}
`

export const Title = styled.p`
  ${media.lessThan('medium')`
    text-align: left;
    color: #2F2663;
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;

    font-family: ${themes.fonts.text};
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.02em;
  `}
  text-align: left;
  color: #2f2663;

  font-family: ${themes.fonts.text};
  font-weight: 650;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.02em;
`

export const Wrapper = styled.div`
  background-color: white;
  overflow: hidden;

  margin-top: 16px;

  ${media.lessThan('medium')`
    padding-top: 16px;
    
  `}
`

export const Text = styled.div`
  ${media.lessThan('medium')`
    margin-left: 0px;
      padding: 24px 24px 16px 0px

      height: 100%;
      
      font-family: ${themes.fonts.text};
      font-size: 14px;
      line-height: 20px;

      letter-spacing: 0.025em;

      color: #000000;
  `}

  ${media.lessThan('small')`
    margin-left: 0px;
      padding: 24px 24px 16px 0px
       height: 100%;
      width: 264px;

      font-family: ${themes.fonts.text};
      font-size: 14px;
      line-height: 20px;

      letter-spacing: 0.025em;

      color: #000000;
  `}
    font-weight: 400;
  /* padding: 18px; */
  margin-left: 72px;

  font-family: ${themes.fonts.text};
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.025em;

  color: #000000;

  a {
    display: inline-block;
    position: relative;
    color: ${themes.palletes.primaryHover};
    font-weight: bold;
  }

  a:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: ${themes.palletes.primaryHover};
    transform-origin: bottom left;
    transition: transform 0.25s ease-out;
  }

  a:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
`
