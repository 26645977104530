import { useState } from 'react'

import { Conditional, Icons, Loading, Modals, Tooltip } from '@/components'
import { plansParse } from '../../Budgets/utils'
import { formatMoneyBRL } from '@/tools'
import { useContract } from '@/modules'
import { api } from '@/services'
import dayjs from 'dayjs'

import * as S from './styles'
import { formatToCPFOrCNPJ, formatToPhone } from 'brazilian-values'

const Policy = () => {
  const contract = useContract()
  const [status, setStatus] = useState<'printing' | ''>('')

  if (!contract.policy) return null

  const isBoleto = ['11', '31', '55', '81'].includes(
    contract.payload.payment.method
  )

  const handlePrint = async (type: string, budget?: string) => {
    try {
      setStatus('printing')
      await api.contracts.print(contract.id, type, budget)
    } catch (error) {
      setStatus('')
      if (type === 'ticket') {
        handleInformationTicket()
      }
    } finally {
      setStatus('')
    }
  }

  const budget = contract.budgets.find((budget) => {
    return budget.id === contract.payload.payment.budget
  })

  const method = budget.payment_methods.find((method) => {
    return method.id === contract.payload.payment.method
  })

  const installment = method.installments.find((installment) => {
    return installment.id === contract.payload.payment.installment
  })

  function handleInformationTicket() {
    Modals.Information({
      icon: 'hourglass-outline',
      title: 'O boleto está sendo gerado.',
      content: `Por favor, tente novamente mais tarde.`,
    })
  }

  const numberPolicy =
    contract.policy.sucursal +
    '.' +
    contract.policy.branch +
    '.' +
    contract.policy.id

  return (
    <S.Container>
      <Conditional when={status === 'printing'}>
        <Loading message="Aguarde, gerando PDF." />
      </Conditional>

      <S.Title
        status={!!contract.policy.status ? contract.policy.status : '80'}
      >
        {!!contract.policy.description
          ? contract.policy.description
          : 'Aguardando Emissão'}
      </S.Title>

      <S.Divider>
        <S.Icon>
          <Icons.FeatherIcons name="file-text" />
        </S.Icon>
        Detalhes da apólice
      </S.Divider>

      <S.Items>
        <S.Item.Wrap>
          <S.Item.Header>
            <S.Item.Label>Nº do orçamento</S.Item.Label>

            <Tooltip content="Gerar PDF do orçamento">
              <S.Icon
                onClick={() => {
                  handlePrint('budget', contract.policy.budget.toString())
                }}
              >
                <Icons.FeatherIcons name="printer" />
              </S.Icon>
            </Tooltip>
          </S.Item.Header>
          <S.Item.Title>{contract.policy.budget}</S.Item.Title>
        </S.Item.Wrap>

        <S.Item.Wrap>
          <S.Item.Header>
            <S.Item.Label>Nº da proposta</S.Item.Label>

            <Tooltip content="Gerar PDF da proposta">
              <S.Icon
                onClick={() => {
                  handlePrint('proposal')
                }}
              >
                <Icons.FeatherIcons name="printer" />
              </S.Icon>
            </Tooltip>
          </S.Item.Header>
          <S.Item.Title>{contract.policy.proposal}</S.Item.Title>
        </S.Item.Wrap>

        <S.Item.Wrap>
          <S.Item.Header>
            <S.Item.Label>Nº da apólice</S.Item.Label>

            <Conditional when={contract.policy.id !== '0'}>
              <Tooltip content="Gerar PDF da apólice">
                <S.Icon
                  onClick={() => {
                    handlePrint('policy')
                  }}
                >
                  <Icons.FeatherIcons name="printer" />
                </S.Icon>
              </Tooltip>
            </Conditional>
          </S.Item.Header>
          <S.Item.Title>
            {contract.policy.id !== '0' && !!contract.policy.id
              ? numberPolicy
              : 'Aguardando emissão'}
          </S.Item.Title>
        </S.Item.Wrap>
      </S.Items>

      <S.Coverages.Section>
        <S.Coverages.List>
          <thead>
            <tr>
              <th>Forma de pagamento</th>
              <th>Valor da parcela</th>
              <Conditional when={isBoleto}>
                <th>Gerar boleto</th>
              </Conditional>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>{installment.description}</td>
              <td>{formatMoneyBRL(installment.first)}</td>
              <Conditional when={isBoleto}>
                <td>
                  <S.Icon
                    onClick={() => {
                      handlePrint('ticket')
                    }}
                  >
                    <Icons.FeatherIcons name="printer" />
                  </S.Icon>
                </td>
              </Conditional>
            </tr>
          </tbody>
        </S.Coverages.List>
      </S.Coverages.Section>

      <S.Divider>
        <S.Icon>
          <Icons.FeatherIcons name="file-text" />
        </S.Icon>
        Dados da apólice
      </S.Divider>

      <S.Items>
        <S.Item.Wrap>
          <S.Item.Label>Data da transmissão</S.Item.Label>
          <S.Item.Title>
            {dayjs(contract.policy.created_at).format('DD/MM/YYYY [às] HH:mm')}
          </S.Item.Title>
        </S.Item.Wrap>

        <S.Item.Wrap>
          <S.Item.Label>Início do contrato</S.Item.Label>
          <S.Item.Title>
            {dayjs(contract.payload.contract.period.start).format('DD/MM/YYYY')}
          </S.Item.Title>
        </S.Item.Wrap>

        <S.Item.Wrap>
          <S.Item.Label>Fim do contrato</S.Item.Label>
          <S.Item.Title>
            {dayjs(contract.payload.contract.period.end).format('DD/MM/YYYY')}
          </S.Item.Title>
        </S.Item.Wrap>

        <S.Item.Wrap>
          <S.Item.Label>Tipo de plano</S.Item.Label>
          <S.Item.Title>
            {plansParse[contract.payload.contract.plan.toLowerCase()]}
          </S.Item.Title>
        </S.Item.Wrap>
      </S.Items>

      <S.Divider>
        <S.Icon>
          <Icons.FeatherIcons name="user" />
        </S.Icon>
        Dados do pretendente principal
      </S.Divider>

      <S.Items>
        <S.Item.Wrap>
          <S.Item.Label>Nome</S.Item.Label>
          <S.Item.Title data-suppress="true">{contract.payload.customers[0].name}</S.Item.Title>
        </S.Item.Wrap>

        <S.Item.Wrap>
          <S.Item.Label>CPF/CNPJ</S.Item.Label>
          <S.Item.Title data-suppress="true">{contract.payload.customers[0].document}</S.Item.Title>
        </S.Item.Wrap>

        <S.Item.Wrap>
          <S.Item.Label>Data de nascimento</S.Item.Label>
          <S.Item.Title data-suppress="true">
            {dayjs(contract.payload.customers[0].birthdate).format(
              'DD/MM/YYYY'
            )}
          </S.Item.Title>
        </S.Item.Wrap>

        <S.Item.Wrap>
          <S.Item.Label>Telefone</S.Item.Label>
          <S.Item.Title data-suppress="true">
            {formatToPhone(contract.payload.customers[0].phone)}
          </S.Item.Title>
        </S.Item.Wrap>
      </S.Items>

      <S.Items>
        <S.Item.Wrap>
          <S.Item.Label>E-mail</S.Item.Label>
          <S.Item.Title data-suppress="true">{contract.payload.customers[0].email}</S.Item.Title>
        </S.Item.Wrap>
      </S.Items>
      <S.Divider>
        <S.Icon>
          <Icons.FeatherIcons name="user" />
        </S.Icon>
        Dados do locador
      </S.Divider>

      <S.Items>
        <S.Item.Wrap>
          <S.Item.Label>Nome</S.Item.Label>
          <S.Item.Title data-suppress="true">{contract.payload.landlord.name}</S.Item.Title>
        </S.Item.Wrap>

        <S.Item.Wrap>
          <S.Item.Label>CPF/CNPJ</S.Item.Label>
          <S.Item.Title data-suppress="true">
            {formatToCPFOrCNPJ(contract.payload.landlord.document)}
          </S.Item.Title>
        </S.Item.Wrap>

        <S.Item.Wrap>
          <S.Item.Label>Telefone</S.Item.Label>
          <S.Item.Title data-suppress="true">
            {formatToPhone(contract.payload.landlord.phone)}
          </S.Item.Title>
        </S.Item.Wrap>

        <S.Item.Wrap>
          <S.Item.Label>E-mail</S.Item.Label>
          <S.Item.Title data-suppress="true">{contract.payload.landlord.email}</S.Item.Title>
        </S.Item.Wrap>
      </S.Items>

      <S.Divider>
        <S.Icon>
          <Icons.FeatherIcons name="list" />
        </S.Icon>
        Coberturas
      </S.Divider>

      <S.Coverages.Section>
        <S.Coverages.List>
          <thead>
            <tr>
              <th>Cobertura</th>
              <th>Valor contratado</th>
              <th>Valor líquido</th>
            </tr>
          </thead>

          <tbody>
            {contract.budgets[0].coverages.map((item: any) => (
              <tr>
                <td>{item.description}</td>
                <td>{formatMoneyBRL(item.value)}</td>
                <td>{formatMoneyBRL(item.pricing)}</td>
              </tr>
            ))}
          </tbody>
        </S.Coverages.List>
      </S.Coverages.Section>

      <S.Divider>
        <S.Icon>
          <Icons.FeatherIcons name="dollar-sign" />
        </S.Icon>
        Valor do Seguro
      </S.Divider>

      <S.Items>
        <S.Item.Wrap>
          <S.Item.Label>Cláusulas de serviço</S.Item.Label>
          <S.Item.Title>
            {formatMoneyBRL(contract.budgets[0].quotation.prize.clauses)}
          </S.Item.Title>
        </S.Item.Wrap>

        <S.Item.Wrap>
          <S.Item.Label>IOF</S.Item.Label>
          <S.Item.Title>{formatMoneyBRL(installment.iof)}</S.Item.Title>
        </S.Item.Wrap>

        <S.Item.Wrap>
          <S.Item.Label>Valor total</S.Item.Label>
          <S.Item.Title>{formatMoneyBRL(installment.total)}</S.Item.Title>
        </S.Item.Wrap>
      </S.Items>

      <S.Divider>
        <S.Icon>
          <Icons.FeatherIcons name="tool" />
        </S.Icon>
        Cláusulas de serviço
      </S.Divider>
      <S.Items>
        <S.Badge type="sugestão">Assistência Bike</S.Badge>
        <S.Badge type="sugestão">Assistência em Antenas</S.Badge>
        <S.Badge type="sugestão">Chaveiro</S.Badge>
        <S.Badge type="sugestão">Eletricista</S.Badge>
        <S.Badge type="sugestão">Encanador</S.Badge>
      </S.Items>

      <S.Divider>
        <S.Icon>
          <Icons.FeatherIcons name="briefcase" />
        </S.Icon>
        Dados do corretor
      </S.Divider>

      <S.Items>
        <S.Item.Wrap>
          <S.Item.Label>Susep</S.Item.Label>
          <S.Item.Title>{contract.broker.id}</S.Item.Title>
        </S.Item.Wrap>

        <S.Item.Wrap>
          <S.Item.Label>Nome</S.Item.Label>
          <S.Item.Title>{contract.broker.name}</S.Item.Title>
        </S.Item.Wrap>

        <S.Item.Wrap>
          <S.Item.Label>E-mail</S.Item.Label>
          <S.Item.Title>{contract.broker.email}</S.Item.Title>
        </S.Item.Wrap>
      </S.Items>

      <S.Divider>
        <S.Icon>
          <Icons.FeatherIcons name="home" />
        </S.Icon>
        Dados da imobiliária
      </S.Divider>

      <S.Items>
        <S.Item.Wrap>
          <S.Item.Label>Código</S.Item.Label>
          <S.Item.Title>{contract.partner.id}</S.Item.Title>
        </S.Item.Wrap>

        <S.Item.Wrap>
          <S.Item.Label>Nome</S.Item.Label>
          <S.Item.Title>{contract.partner.name}</S.Item.Title>
        </S.Item.Wrap>

        <S.Item.Wrap>
          <S.Item.Label>E-mail</S.Item.Label>
          <S.Item.Title>{contract.partner.email}</S.Item.Title>
        </S.Item.Wrap>
      </S.Items>
    </S.Container>
  )
}

export default Policy
