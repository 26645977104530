export const contentLabels = {
  alert: {
    title:
      'A Porto encerrou o seu contrato com a Docusign. Para os parceiros que têm acesso à plataforma, veja instruções abaixo.',
    children:
      'A partir do dia <b>20/04/2022</b>, os parceiros só poderão acessar a plataforma para fazer consultas e baixar os documentos que já estiverem assinados.<br/><br/> Até o dia <b>10/07/2022</b>, indicamos que os parceiros realizem o download dos seus documentos. Após essa data não será mais permitido o acesso ao Docusign, pois a conta estará encerrada.',
    link: '<a href="https://account.docusign.com/logout#/username" target="_blank" rel="noopener noreferrer">Acessar Docusign</a>',
  },
  how: {
    title: 'Como faço para ter acesso a plataforma, Clicksign?',
    children:
      'É simples! Clique em <a href="https://lp.clicksign.com/clickpartner/porto-seguro-imob?plan_subscribed=custom&parcerias_indicado_individual=Porto+Seguro" target="_blank" rel="noopener noreferrer">Solicitar acesso</a>, preencha o nosso formulário e pronto! Em até 1 dia útil receberá um retorno com a liberação e os dados de acesso da sua conta Clicksign.',
    childrenIntegration:
      'É simples! Peça ao responsável pela imobiliária ou corretora para solicitar acesso ou caso já tenha sido feita a solicitação aguarde até 1 dia útil para a liberação.',
  },
  doubt: {
    title: 'Ainda com dúvida? ',
    children:
      'A Clicksign possui uma central de atendimento em que você poderá tirar todas as suas dúvidas.<br/><br/> <b>Telefone:</b> <a href="tel:113145-2570">(11) 3145-2570</a> (Ramal 7 - Atendimento de segunda a sexta-feira, exceto feriados, das 09h00 às 18h00)<br/><br/> <b>Email:</b> <a href=mailto:ajuda@clicksign.com>ajuda@clicksign.com</a><br/><br/><b>Site:</b> <a href="https://clicksign.com/suporte/" target="_blank" rel="noopener noreferrer">www.clicksign.com/suporte/</a><br/><br/>Também poderá contar com o suporte da PortoEduc. Na plataforma você encontra uma série de vídeos educativos, de curta duração, explicando o uso da ferramenta.',
  },
  footer: {
    children:
      'A Porto Seguro não se responsabiliza pelos assuntos relacionados à assinatura eletrônica, ficando responsável somente pelo pagamento do serviço à empresa fornecedora.',
  },

  clicksign: {
    title: '',
    children:
      'É um serviço de assinatura eletrônica que você poderá utilizar em seus documentos, como, por exemplo, contratos de locação. E em questão de minutos ou horas (e não dias) você garante a assinatura, agiliza o processo e conquista seu cliente! <b>Este é um serviço de benefício Porto seguro somente para parceiros e que não irá gerar custo (regras para manutenção de gratuidade <a href="https://www.corretoronlinenoticias.com.br/image/media/_05900/5940/original/regras_de_elegibilidade.pdf" target="_blank" rel="noopener noreferrer">clique aqui</a>).</b> ',
    link: '<a href="https://www.clicksign.com/" target="_blank" rel="noopener noreferrer">Conheça mais</a>',
  },
}

export const links = {
  whatIs: {
    link: 'https://www.clicksign.com/suporte/',
  },
  access: {
    link: 'https://lp.clicksign.com/clickpartner/porto-seguro-imob?plan_subscribed=custom&parcerias_indicado_individual=Porto+Seguro',
  },
  have: {
    link: 'https://app.clicksign.com/',
  },
}
