import { Box, Flex, Typography } from "@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base"
import { Dialog, Icon } from "@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto"
import { benefitsModalData } from '@/data/capitalizacao'
import { IModalProps } from "./interfaces"

const FOOTER_HEIGHT = "116px"
const CONTENT_HEIGHT = "80vh"

export const Modal = ({
  isModalOpen,
  setIsModalOpen
}: IModalProps) => (
  <Dialog
    isOpen={isModalOpen}
    onClose={() => setIsModalOpen(false)}
    size="large"
    primaryButton={{
      label: 'Ok, entendi',
      action: () => setIsModalOpen(false)
    }}
  >
    <Flex
      sx={{
        flexDirection: 'column',
        height: `calc(${CONTENT_HEIGHT} - ${FOOTER_HEIGHT})`,
        maxHeight: '550px',
        overflowY: 'auto',
        gap: ".5rem",
      }}
    >
      <Icon
        name='circlequestion'
        size="1.75rem"
      />
      <Typography
        content={benefitsModalData.title}
        variant="porto-title-5-semibold"
      />
      <Typography
        color="porto-primitive-black-75"
        variant="porto-text-body-2-regular"
        content={benefitsModalData.description}
      />
      <Flex
        sx={{
          flexDirection: 'column',
          gap: '1.5rem',
          marginY: '.75rem',
        }}
      >
        {benefitsModalData.benefits.map(({ service, benefits }) => (
          <div key={`modal_benefits_service_${service}`}>
            <Typography
              content={service}
              variant="porto-text-body-2-bold"
              color="porto-primitive-black-75"
            />
            <Box
              sx={{
                marginLeft: '1.5rem',
                color: 'porto-primitive-black-75'
              }}
            >
              <ul>
                {benefits.map((benefit, index) => (
                  <li key={`modal_benefits_benefit_${index}`}>
                    <Typography
                      content={benefit}
                      variant="porto-text-body-2-regular"
                    />
                  </li>
                ))}
              </ul>
            </Box>
          </div>
        ))}
      </Flex>
      <Typography
        content={benefitsModalData.footer}
        variant="porto-text-body-2-regular"
        color="porto-primitive-black-75"
      />
    </Flex>
  </Dialog>
)