import dayjs from 'dayjs'

export const canResend = ({ status, payload, metadata }): boolean => {
  const isFailure = (
    status === 'FAILURE' &&
    [
      "@imob/integration-failure",
      "@imob/digibee-internal",
    ].includes(metadata?.error?.code)
  )
  const isValidPeriod = dayjs().startOf('day').isSameOrBefore(dayjs(payload?.contract?.period?.start))
  return isFailure && isValidPeriod
}
