import { AxiosResponse } from 'axios'
import * as instances from '../instance'

type Products =
  | 'PORTO_ESSENCIAL'
  | 'PORTO_ALUGUEL'
  | 'PORTO_IMOBILIARIA'
  | 'PORTO_CAPITALIZACAO'
  | 'HOME_EQUITY'

interface ResponseData {
  avalibleToSend: boolean
  susep: string
  type: string
  phone: string
  email: string
  name: string
  id: string
  isElegibleCard: boolean
}

interface Persons {
  name: string
  email: string
  document: string
  phone: string
}

interface FromPersonPayload {
  persons: Persons[]
  partner: string
  susep: string
  product: Products
}

interface FromContractPayload {
  contractId: string
}

export async function verifyElegibilityFromContract(
  payload: FromContractPayload
): Promise<ResponseData[]> {
  const { data } = await instances.v2.post<
    FromContractPayload,
    AxiosResponse<ResponseData[]>
  >('/pis/eligibility/contracts', payload)

  return data
}

export async function verifyElegibilityFromPerson(
  payload: FromPersonPayload
): Promise<ResponseData[]> {
  const { data } = await instances.v2.post<
    FromPersonPayload,
    AxiosResponse<ResponseData[]>
  >('/pis/eligibility', payload)

  return data
}

export async function listCustomers(): Promise<ResponseData[]> {
  const { data } = await instances.v2.get<ResponseData[]>('/pis/eligibility')

  return data
}
