import { Box } from "@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base"

const Card = ({
  sx = {},
  children,
}) => {
  return (
    <Box
      sx={{
        padding: "2rem",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "porto-primitive-black-15",
        borderRadius: ".5rem",
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}

export default Card
