import { Step, Steps, Wizard } from 'react-albus'

import { Base } from '../components'
import { NewAccountProvider } from './context'
import Document from './steps/Document'
import ProfileTypeSelector from './steps/ProfileTypeSelector'
import BrokerNotAccreditated from './steps/BrokerNotAccreditated'
import PersonalData from './steps/PersonalData'
import EmailSent from './steps/EmailSent'
import Chat from '@/components/Chat'

const FirstAccess = () => {
  return (
    <Base>
      <NewAccountProvider>
        <Wizard
          render={(wizard: any) => (
            <Steps>
              <Step
                id="document"
                render={() => (
                  <Document
                    wizard={wizard}
                    hasAccreditationStep="personal-data"
                  />
                )}
              />
              <Step
                id="profile-type-selector"
                render={() => (
                  <ProfileTypeSelector
                    wizard={wizard}
                    partnerAccreditatedStep="personal-data"
                  />
                )}
              />
              <Step
                id="broker-not-accreditated"
                render={() => <BrokerNotAccreditated />}
              />
              <Step
                id="personal-data"
                render={() => <PersonalData wizard={wizard} />}
              />
              <Step id="email-sent" render={() => <EmailSent />} />
            </Steps>
          )}
        />
      </NewAccountProvider>
      <Chat />
    </Base>
  )
}

export default FirstAccess
