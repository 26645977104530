import * as yup from 'yup'

import { contractStore } from '@/modules'
import { isValidPhone } from '@brazilian-utils/brazilian-utils'
import { formatToCPF, isCNPJ, isCPF } from 'brazilian-values'
import dayjs from 'dayjs'

const documentValidation = (value: string) => {
  if (value.length <= 14) return isCPF(value)

  return isCNPJ(value)
}

export const address = yup.object().shape({
  zipcode: yup
    .string()
    .min(9, 'Por favor insira um CEP válido.')
    .required('Campo CEP é obrigatório.'),
  street: yup.string().required('Por favor confirme a rua.'),
  number: yup.string().required('Campo número é obrigatório.'),
  complement: yup.string().max(20),
  neighborhood: yup.string().required('Por favor confirme o bairro.'),
  city: yup.string().required('Por favor confirme a cidade.'),
  state: yup.string().required('Por favor confirme o estado.'),
})

export const user = {
  document: yup
    .string()
    .required('Campo CPF/CNPJ é obrigatório.')
    .test(
      'document-test',
      'Por favor insira um CPF/CNPJ válido.',
      (value) => !!value && documentValidation(value)
    ),
  name: yup.string().required('Campo nome é obrigatório.'),
  birthdate: yup.string().when('document', {
    is: (val: string) => isCPF(val),
    then: yup
      .string()
      .required('Campo data de nascimento é obrigatório.')
      .test(
        'invalid-date',
        'Favor informar data de nascimento válida.',
        function (value) {
          if (!value) return true
          const birthdate = dayjs(value.split('/').reverse().join(''))
          const birthdateYear = dayjs(
            value.split('/').reverse().join('')
          ).year()
          const limitDate = dayjs('1900-01-01')
          const limitYear = dayjs().year()

          return (
            dayjs(limitDate).isSameOrBefore(birthdate) &&
            limitYear >= birthdateYear
          )
        }
      ),
    otherwise: yup.string().strip(),
  }),
  email: yup
    .string()
    .email('Por favor insira um e-mail válido.')
    .required('Campo e-mail é obrigatório.'),
  phone: yup
    .string()
    .test((value, context) => {
      if (!isValidPhone(value))
        return context.createError({
          message: 'Por favor insira um número válido.',
        })

      return true
    })
    .required('Campo telefone/celular é obrigatório.'),
  pep: yup.string().when('document', {
    is: (val: string) => isCPF(val),
    then: yup
      .string()
      .default('1')
      .required('Campo pessoa politicamente exposta é obrigatório.'),
    otherwise: yup.string().strip(),
  }),
}

export const landlordSchema = yup.object().shape({
  ...user,
  document: user.document.test(
    'duplicated-documents',
    'Este CPF já está em uso por um dos inquilinos.',
    (document = '') => {
      const documents = contractStore
        .getState()
        .payload.customers.map(({ document }) => formatToCPF(document))

      return !documents.includes(document)
    }
  ),
  addresses: yup.object().shape({
    useSameAddress: yup.boolean(),
    main: address,
    billing: yup.object().when('useSameAddress', {
      is: false,
      then: address,
      otherwise: yup.object().strip(),
    }),
  }),
})

export const tenantsSchema = yup.object().shape({
  ...user,
})

export const customersSchema = yup.object().shape({
  customers: yup.array().of(yup.object().shape({ ...user })),
})

export const coveragesSchema = yup.object().shape({
  coverages_main: yup.object().shape({
    rent: yup.number(),
    iptu: yup
      .number()
      .max(yup.ref('rent'), 'Valor máximo referente à cobertura aluguel.'),
    condominium: yup
      .number()
      .max(yup.ref('rent'), 'Valor máximo referente à cobertura aluguel.'),
    water: yup
      .number()
      .max(yup.ref('rent'), 'Valor máximo referente à cobertura aluguel.'),
    gas: yup
      .number()
      .max(yup.ref('rent'), 'Valor máximo referente à cobertura aluguel.'),
    electricity: yup
      .number()
      .max(yup.ref('rent'), 'Valor máximo referente à cobertura aluguel.'),
  }),
})
