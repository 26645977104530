import api from '..'
import { HomeEquityOfferPayload, HomeEquityOfferPayloads } from './types';

export async function registerHomeEquityPayload(payload: HomeEquityOfferPayloads) {
  try {
    const response = await api.instance.v2.post(`/home-equity-proposal/offer`, payload)
    return response.data
  } catch (error) {
    throw error
  }
}

export async function processPayload(id: string) {
  try {
    const response = await api.instance.v2.post(`/home-equity-proposal`, {id})
    return response.data
  } catch (error) {
    throw error
  }
}