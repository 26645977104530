import * as Steps from '.'

const steps = [
  {
    id: 'budgets',
    icon: '',
    title: '',
    description: '',
    render: Steps.Budgets,
  },

  {
    id: 'biometry',
    icon: '',
    title: '',
    description: '',
    render: Steps.Biometry,
  },

  {
    id: 'landlord',
    icon: 'user',
    title: 'Locador',
    description: 'Cadastre as informações do dono do imóvel a ser locado.',
    render: Steps.Landlord,
  },
]

export default steps
