import { ReactNode } from 'react'
import { Container, Card } from './styles'

const Base = ({ children }: { children: ReactNode }) => (
  <Container>
    <Card>{children}</Card>
  </Container>
)

export default Base
