import { Flex, Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { ITextContentProps } from './interfaces'
import { dashText } from '@/tools'

export const TextContent = ({ title, description = '-' }: ITextContentProps) => (
  <Flex
    sx={{ flexDirection: 'column', gap: '.5rem', flex: 1, minWidth: '210px', maxWidth: '220px' }}
  >
    <Typography content={title} variant="porto-text-caption-bold" />
    <Typography
      content={description}
      data-test-id={dashText(title)}
      variant="porto-text-body-2-regular"
      sx={{ wordWrap: 'break-word' }}
    />
  </Flex>
)
