import { useRef, useState } from 'react'

import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'

import { useOutsideClick } from '@/tools'

import {
  Container,
  DropdownButton,
  OptionsContainer,
  Option,
  Label,
  Value,
  Icon,
} from './styles'

const QUANTITY_LIST = [
  10,
  20,
  50,
]

export default function Dropdown({
  value,
  onSelectOption,
}) {
  const [isOpen, setIsOpen] = useState(false)

  const open = () => setIsOpen(true)
  const close = () => setIsOpen(false)

  const menuRef = useRef(null)
  useOutsideClick(menuRef, close)

  function handleSelectOption(option) {
    onSelectOption(option)
    close()
  }

  return (
    <Container>
      <DropdownButton onClick={open} ref={menuRef}>
        <Value content={value} />
        <Icon name="chevron-down" />
        <Typography
          content="itens por página"
          variant="porto-text-caption-regular"
        />
      </DropdownButton>
      {isOpen && (
        <OptionsContainer>
          {QUANTITY_LIST.map((option) => (
            <Option onClick={() => handleSelectOption(option)} key={option}>
              <Label content={option.toString()} />
            </Option>
          ))}
        </OptionsContainer>
      )}
    </Container>
  );
}
