import styled from 'styled-components'
import media from 'styled-media-query'
import { rgba } from 'polished'

import { Actions } from '@/components'
import { themes } from '@/styles'

export const Container = styled.div`
  width: 350px;
  padding: 32px;

  border-radius: 20px;
  background-color: #fff;

  ${media.greaterThan('huge')`
    width: 450px;
  `}
`

export const Icon = styled.div`
  padding: 12px;
  margin-bottom: 16px;
  display: inline-block;

  font-size: 20px;
  text-align: center;
  border-radius: 8px;
  color: ${themes.palletes.primary};
  background-color: ${rgba(themes.palletes.primary, 0.15)};

  ${media.greaterThan('huge')`
    padding: 16px;

    font-size: 32px;
  `}
`

export const Title = styled.h4`
  margin-bottom: 16px;
`

export const Form = styled.form``

export const Action = styled(Actions.Button)`
  margin-top: 40px;
`
