import styled from 'styled-components'
import TextField from '@mui/material/TextField'

export const ValidationTextField = styled(TextField)({
  P: {
    color: '#842310 !important',
    fontSize: '0.6875rem',
    fontWeight: 'bold',
    fontFamily: '"Porto Roobert",sans-serif',
    margin: 0,
    padding: '7px 16px',
    backgroundColor: 'rgba(132,35,16,0.06)',
    borderRadius: '0 0 8px 8px',
  },

  '& .Mui-error > fieldset': {
    borderColor: 'rgba(132,35,16,0.5) !important',
    borderWidth: 1,
  },
  '& .Mui-error': {
    color: '#842310 !important',
  },
})
