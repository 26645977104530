import React, { useRef } from 'react'
import { isBrowser, isMobile } from 'react-device-detect'
import { AnimatePresence } from 'framer-motion'

import { dashText, useOutsideClick } from '@/tools'

import { Actions, Content } from '../../../index'

import Base from '../../Base'

import { IHelperModalBase } from './interfaces'

import { Container, Loader, Head, Icon, Title, Description, Close, Body, Footer } from './styles'

const Helpers: React.FC<IHelperModalBase> = props => {
  const containerRef = useRef()

  useOutsideClick(containerRef, !props.disableOutsideClick ? props.onDismiss?.handler : null)

  return (
    <Base centered={isBrowser} bottom={isMobile}>
      <AnimatePresence>
        <Container
          ref={containerRef}
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={{
            hidden: { y: 100 },
            visible: { y: 0, transition: { ease: [0.25, 1, 0.5, 1] } },
          }}
        >
          {props.loading?.status ? (
            <AnimatePresence>
              <Loader
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={{
                  hidden: { opacity: 0 },
                  visible: { opacity: 1 },
                }}
              >
                <Icon name={props.icon} />

                <Content.Loader message={props.loading?.message} />
              </Loader>
            </AnimatePresence>
          ) : (
            <>
              <Head>
                <Icon name={props.icon} />

                <Title>{props.title}</Title>

                <Description dangerouslySetInnerHTML={{ __html: props.description }} />

                {props.onDismiss && (
                  <Close.Action onClick={props.onDismiss.handler}>
                    <Close.Icon />
                  </Close.Action>
                )}
              </Head>

              <Body>{props.children}</Body>

              <Footer>
                {props.onSubmit && (
                  <Actions.Button
                    size="block"
                    type="button"
                    icon={props.icon}
                    onClick={props.onSubmit.handler}
                    isLoading={props.loading.status}
                    variant={props.onSubmit.variant}
                    data-gtm-type="click"
                    data-gtm-clicktype="button"
                    data-gtm-name={dashText(props.onSubmit.label)}
                  >
                    {props.onSubmit.label}
                  </Actions.Button>
                )}

                {props.onDismiss && (
                  <Actions.Button
                    kind="smooth"
                    onClick={props.onDismiss.handler}
                    data-gtm-type="click"
                    data-gtm-clicktype="button"
                    data-gtm-name={dashText(props.onDismiss.label)}
                  >
                    {props.onDismiss.label}
                  </Actions.Button>
                )}
              </Footer>
            </>
          )}
        </Container>
      </AnimatePresence>
    </Base>
  )
}

Helpers.defaultProps = {
  icon: 'Flag',
  title: 'Helper modal',
  description: 'Modal description',
}

export default Helpers
