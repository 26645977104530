import { api } from '@/services'
import qs from 'qs'

interface CampaignNumber {
  readonly id: string
  readonly policy_date: string
  readonly policy: string
  readonly broker: string
  readonly partner: string
}

export const getCampaignNumbers =
  (campaignId: string) =>
  async (props: { partner: string } | { broker: string[] }) =>
    await api.instance.v2.get<CampaignNumber[]>(
      `/campaigns/${campaignId}/raffle?${qs.stringify(props, {
        arrayFormat: 'comma',
      })}`
    )
