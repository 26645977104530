import React from 'react'
import { useFormikContext, FormikProps, FieldArray } from 'formik'

import { Grid, Forms, Actions } from '@/components'

import { Susep } from './styles'

const susepTip =
  'A SUSEP consiste em 5 dígitos com o final F para pessoa física ou J para jurídica.'

const Informations = () => {
  const {
    values,
  }: FormikProps<{
    type: string
    susep: {
      extras: []
    }
  }> = useFormikContext()

  return (
    <Forms.Card step="1" title="Organização" active>
      <Grid
        columns={['repeat(2, 1fr)', 'repeat(3, 1fr)']}
        gap="1rem"
        space="2rem 0"
      >
        <Forms.InputGroup
          name="document"
          label="CPF/CNPJ"
          placeholder="Digite o CPF ou CNPJ"
          mask="document"
        />

        <Forms.InputGroup
          name="name"
          label="Nome"
          placeholder="Digite o nome"
        />
      </Grid>

      <Grid
        columns={['repeat(2, 1fr)', 'repeat(3, 1fr)']}
        gap="1rem"
        space="0 0 2rem"
      >
        <Forms.SelectGroup
          name="type"
          label="Perfil da organização"
          options={[
            { value: '', label: 'Selecione o perfil da organização' },
            { value: 'REAL_ESTATE', label: 'Imobiliária' },
            { value: 'BROKER', label: 'Corretora' },
          ]}
        />
      </Grid>

      {values.type === 'REAL_ESTATE' && (
        <Grid columns={['repeat(2, 1fr)', 'repeat(3, 1fr)']} gap="1rem">
          <Forms.InputGroup
            name="realEstate.code"
            label="Código da imobiliária"
            placeholder="Digite o código da imobiliária"
          />
        </Grid>
      )}

      {values.type === 'BROKER' && (
        <>
          <Grid columns={['repeat(2, 1fr)', 'repeat(3, 1fr)']} gap="1rem">
            <Forms.InputGroup
              name="susep.porto"
              label="SUSEP Porto"
              placeholder="Digite a SUSEP Porto"
              mask="susep"
              tip={susepTip}
            />

            <Forms.InputGroup
              name="susep.official"
              label="SUSEP Oficial"
              placeholder="Digite a SUSEP Oficial"
            />
          </Grid>

          <FieldArray
            name="susep.extras"
            render={(arrayHelpers) => (
              <div>
                <Grid
                  columns={['repeat(2, 1fr)', 'repeat(3, 1fr)']}
                  space="2rem 0"
                  gap="1rem"
                >
                  {values.susep.extras.length > 0 &&
                    values.susep.extras.map((_, index) => (
                      <Susep key={index}>
                        <Forms.InputGroup
                          name={`susep.extras.${index}`}
                          label={`SUSEP Extra ${index + 1}`}
                          placeholder="Digite a SUSEP Extra"
                          tip={susepTip}
                          mask="susep"
                        />

                        <Actions.Button
                          type="button"
                          size="small"
                          variant="danger"
                          onClick={() => arrayHelpers.remove(index)}
                        >
                          Remover
                        </Actions.Button>
                      </Susep>
                    ))}
                </Grid>

                <div>
                  <Actions.Button
                    kind="ghost"
                    type="button"
                    onClick={() =>
                      arrayHelpers.insert(values.susep.extras.length, '')
                    }
                  >
                    Adicionar SUSEP Extra
                  </Actions.Button>
                </div>
              </div>
            )}
          />
        </>
      )}
    </Forms.Card>
  )
}

export default Informations
