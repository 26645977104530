import dayjs from 'dayjs'
import { useMemo } from 'react'

import { Conditional, Content } from '@/components'
import { useContract, useCurrentOrganization } from '@/modules'
import { isCapitalizacaoEnabled } from '@/tools/partnership'

import { Proposal, Recalculate } from '..'
import { LayoutEssencial } from '../../../../../layouts/LayoutEssencial'
import NewError from '../../shared/NewError'
import { Products } from '../../shared/entities'
import Payment from '../Payments'
import Policy from './Policy'
import { PACErrorOfferCapitalizacao } from './_PACErrorOfferCapitalizacao'

import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

const Details = () => {
  const contract = useContract<Products.ESSENCIAL>()
  const profile = useCurrentOrganization()

  const expiredTerm = useMemo(() => {
    const term = dayjs(contract?.payload?.periodContractTerm?.start)
      .utc()
      .format('YYYY-MM-DD')

    const today = dayjs(new Date()).utc().format('YYYY-MM-DD')
    const diff = dayjs(term).utc().diff(today, 'day')

    return diff
  }, [contract])

  const recalculate = useMemo(() => {
    const start = dayjs(contract?.payload?.periodContractTerm?.start).utc()
    const today = dayjs().utc()

    return start.isBefore(today, 'day')
  }, [contract?.payload?.periodContractTerm?.start])

  const released = useMemo(() => {
    return expiredTerm > -1
  }, [expiredTerm])

  const commonParseError = {
    title: 'Erro de integração.',
    subtitle: 'Lamentamos o inconveniente',
    message: (
      <>
        Clique no botão para realizar um novo orçamento. Se precisar, entre em
        contato com a nossa{' '}
        <a href="https://atendimento.solucoesparalocacao.com.br/hc/pt-br/requests/new">
          Central de Ajuda
        </a>
        .
      </>
    ),
  }

  const parseError = {
    '@essencial/integration-failure': commonParseError,
    '@essencial/unknown-integration-error': commonParseError,
    '@essencial/pac-refused': {
      title: 'Análise recusada.',
      subtitle: (
        <>
          Poxa! Analisamos os dados informados e verificamos que{' '}
          <strong>
            o Fiança Locatícia Essencial não está disponível para esse cliente
          </strong>
          .
        </>
      ),
      message: `
        Infelizmente, não é possível continuar com a contratação.
        Tente fazer um novo orçamento.
      `,
    },
    '@essencial/recalculation-error': {
      title: 'Não foi possível seguir para a próxima etapa.',
      message:
        'Lamentamos o inconveniente. Clique no botão para tentar novamente.',
    },
  }

  const status = useMemo(() => {
    if (contract.external_status === 'PENDING') return 'loading'

    if (contract.metadata?.error) return 'error'

    if (contract?.policy?.id) return 'policy'

    if (!contract.payload?.payment?.budget && released) return 'pending'

    if (contract.payload?.landlord?.document && released) return 'payment'

    if (released) return 'pending'

    if (recalculate) return 'recalculate'
  }, [
    contract.payload?.payment?.budget,
    contract.payload?.landlord?.document,
    contract.external_status,
    contract.metadata?.error,
    contract?.policy?.id,
    recalculate,
    released,
  ])

  const step = useMemo<number>(() => {
    if (status === 'payment') return 5

    if (
      !!contract.payload?.payment?.budget &&
      contract.metadata?.status === '@essencial/approved'
    )
      return 4

    if (
      [
        '@essencial/approved',
        '@essencial/biometry-review',
        '@essencial/biometry-expired',
        '@essencial/biometry-refused',
      ].includes(contract.metadata?.status) &&
      contract.payload?.payment?.budget
    )
      return 3

    if (contract.id) return 2

    return 2
  }, [
    contract.id,
    contract.metadata?.status,
    contract.payload?.payment?.budget,
    status,
  ])

  return (
    <LayoutEssencial currentStep={step} disableStepper={status === 'policy'}>
      <Conditional when={contract.external_status === 'PENDING'}>
        <Content.Loader message="Aguarde, estamos calculando o orçamento." />
      </Conditional>

      <Conditional when={status === 'pending'}>
        <Proposal />
      </Conditional>

      <Conditional when={status === 'payment'}>
        <Payment />
      </Conditional>

      <Conditional when={status === 'recalculate'}>
        <Recalculate recalculate={recalculate} />
      </Conditional>

      <Conditional when={status === 'policy'}>
        <Policy />
      </Conditional>

      <Conditional when={status === 'error'}>
        <Conditional
          when={
            contract.metadata.error?.code !== '@essencial/pac-refused' ||
            !isCapitalizacaoEnabled(profile)
          }
        >
          <NewError
            product="Fiança Locatícia Essencial"
            errorTitle={parseError[contract.metadata.error?.code]?.title}
            errorMessage={parseError[contract.metadata.error?.code]?.message}
          />
        </Conditional>

        <Conditional
          when={
            isCapitalizacaoEnabled(profile) &&
            contract.metadata.error?.code === '@essencial/pac-refused'
          }
        >
          <PACErrorOfferCapitalizacao />
        </Conditional>
      </Conditional>
    </LayoutEssencial>
  )
}

export default Details
