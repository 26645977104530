import { useEffect, useMemo, useState } from 'react'

import { Conditional, Loading, Modals } from '@/components'
import { api } from '@/services'

import { useQuery } from 'react-query'

import { SvgIcons } from '@/components/SvgIcons'
import { useContract } from '@/modules'
import { Products } from '@/screens/Contracts/Products/shared/entities'
import { formatMoneyBRL, useIsMobileMedium } from '@/tools'
import {
  AmountFormsTitle,
  Button,
  ButtonClear,
  ButtonClose,
  ButtonStyles,
  Content,
  ContentHead,
  FirstValue,
  FormCheck,
  FormWrapper,
  Icon,
  Input,
  Installment,
  Label,
  LabelInstallment,
  Title,
  TitleHead,
  TitleLabel,
  TitleSelectForms,
} from './styles'

export const paymentsParse = {
  '11': 'bankSlip',
  '41': 'invoiceWithoutDown',
  '81': 'invoiceWithDown',
}

const ModalSelectPayments = props => {
  const isMobile = useIsMobileMedium()
  const contract = useContract<Products.IMOBILIARIA>()

  const [status, setStatus] = useState<'printing' | 'loading' | ''>('')
  const [methods, setMethods] = useState([])

  const [isNotChecked, setIsNotChecked] = useState(true)

  const [eventClick, setEventClick] = useState(false)

  const [isSelectAll, setIsSelectAll] = useState(false)

  const details = props?.details?.state || props?.details

  const paymentMethods = details?.budgets?.map(payments => {
    return payments.payment_methods.filter(payment => !['62', '97'].includes(payment.id))
  })

  const budget = details?.budgets.find(budget => {
    return budget.id === budget.id
  })

  const arrayIdPaymentMethods = methods.map(installments => {
    return installments.installments
      .map(element => {
        if (element.isChecked === true) {
          return element.id
        }
      })
      .filter(function (el) {
        return el
      })
  })
  const reduceArrayIdPaymentMethods = arrayIdPaymentMethods.reduce((accumulator, currentValue) => {
    return [...accumulator, ...currentValue]
  }, [])

  const disregardDelected = () => {
    if (reduceArrayIdPaymentMethods.length - 1) {
      return setIsNotChecked(true)
    }
  }

  const handlePrint = async (id: string, budget?: string, idpaymentMethods?: string) => {
    try {
      let allPaymentMethods = ''
      if(isSelectAll){
        allPaymentMethods = paymentsEnable.map(paymentEnable => paymentEnable.id).join(',');
      }
      const paymentMethods = isSelectAll ? allPaymentMethods : String(reduceArrayIdPaymentMethods)
      
      setStatus('printing')
      props.setStatus('printing')
      await api.contracts.print(id, 'budget', budget, paymentMethods)
    } catch (error) {
      setStatus('')
      props.setStatus('')
    } finally {
      setStatus('')
      props.setStatus('')
    }
  }
  const handleChange = event => {
    const { name, checked } = event.target

    setMethods((prev: any) => {
      return prev.map(e => {
        e.installments.map(i => {
          if (i.description === name) {
            i.isChecked = checked
            return i
          }
        })
        return e
      })
    })
  }
  function AllSelect(event) {
    const { checked } = event.target
    setIsSelectAll(true)
    setMethods((prev: any) => {
      return prev.map(installments => {
        installments.installments.map(element => {
          element.isChecked = checked
          return element
        })
        return installments
      })
    })
  }

  function ClearSelect(event) {
    setMethods((prev: any) => {
      return prev.map((x, index) => {
        x.installments.map((b, index2) => {
          b.isChecked = false
          return b
        })
        return x
      })
    })
  }

  useEffect(() => {
    if (paymentMethods) setMethods(paymentMethods[0])
  }, [])

  const orgQuerySettings = useQuery(
    [
      '@org/id/part/id',
      typeof contract.broker === 'string' ? contract.broker : contract.broker.document,
      typeof contract.partner === 'string' ? contract.partner : contract.partner.id,
    ],
    async () => {
      const brokerId =
        typeof contract.broker === 'string' ? contract.broker : contract.broker.document
      const partnerId =
        typeof contract.partner === 'string' ? contract.partner : contract.partner.id

      return await api.instance.v1.get(`/organizations/${brokerId}/partners/${partnerId}`)
    },
  )

  const partnership = orgQuerySettings.data?.data

  const settings = useMemo(() => {
    if (!partnership) return

    return partnership.settings
  }, [partnership])

  const enableMethods = useMemo(() => {
    if (!settings) return

    const methods = budget?.payment_methods
      ?.map(m => {
        const enable = settings[`porto_imobiliaria.payments.${paymentsParse[m.id]}.enabled`]

        if (enable) return m

        return false
      })
      .filter(Boolean)

    return methods
  }, [budget?.payment_methods, settings])

  const paymentsEnable = useMemo(() => {
    const installments = enableMethods?.map(m => {
      const limit = settings[`porto_imobiliaria.payments.${paymentsParse[m.id]}.installments`]

      if (!limit) return m.installments

      return m.installments.filter(i => i.amount <= limit)
    })

    return installments?.flat() || []
  }, [enableMethods, settings])

  // const paymentEnable = usePaymentEnable('porto_imobiliaria', )

  return (
    <Modals.Content padding={true} colorIcon="primary" size="medium">
      <Conditional when={status === 'printing'}>
        <Loading message="Aguarde, gerando PDF." />
      </Conditional>
      <Content>
        <Title>
          <p />
          <TitleLabel>Formas de pagamento</TitleLabel>
          <ButtonClose onClick={() => props.setPressed(false)}>{SvgIcons.closeIcon}</ButtonClose>
        </Title>

        <FormWrapper>
          <ContentHead>
            <TitleHead>Selecione as formas de pagamento para gerar o PDF.</TitleHead>
            <AmountFormsTitle>Máximo de 30 formas de pagamento.</AmountFormsTitle>
            <TitleSelectForms>Formas de pagamento</TitleSelectForms>
          </ContentHead>
          <FormCheck>
            <div>
              <Label>
                <Conditional when={!isMobile}>
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    name="id"
                    {...(isNotChecked === true
                      ? { checked: false }
                      : eventClick === true
                        ? { checked: true }
                        : {})}
                    onChange={event => {
                      setIsNotChecked(!isNotChecked)
                      AllSelect(event)
                    }}
                    onClick={() => setEventClick(true)}
                  />
                </Conditional>
                Selecionar todos
                <Conditional when={isMobile}>
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    name="id"
                    {...(isNotChecked === true
                      ? { checked: false }
                      : eventClick === true
                        ? { checked: true }
                        : {})}
                    onChange={event => {
                      setIsNotChecked(!isNotChecked)
                      AllSelect(event)
                    }}
                    onClick={() => setEventClick(true)}
                  />
                </Conditional>
              </Label>
            </div>
          </FormCheck>
          {paymentsEnable.map(paymentEnable => (
            <FormCheck key={paymentEnable.id}>
              <div className="teste-div">
                <Label key={paymentEnable.id}>
                  <Conditional when={!isMobile}>
                    <Input
                      type="checkbox"
                      className="form-check-input"
                      name={paymentEnable.description}
                      checked={paymentEnable?.isChecked}
                      onChange={event => {
                        handleChange(event)
                        disregardDelected()
                      }}
                      onClick={() => setEventClick(false)}
                    />
                  </Conditional>

                  {paymentEnable.description === '1 x A VISTA NA CIA'
                    ? 'Boleto a vista'
                    : paymentEnable.description.toLowerCase()}

                  <Conditional when={isMobile}>
                    <Input
                      type="checkbox"
                      className="form-check-input"
                      name={paymentEnable.description}
                      checked={paymentEnable?.isChecked}
                      onChange={event => {
                        handleChange(event)
                      }}
                    />
                  </Conditional>
                </Label>
                <Conditional when={!isMobile}>
                  <Installment>
                    <LabelInstallment>1ª parcela</LabelInstallment>
                    <FirstValue>{formatMoneyBRL(paymentEnable.first)}</FirstValue>
                  </Installment>
                  <Conditional when={paymentEnable.description !== '1 x A VISTA NA CIA'}>
                    <Installment>
                      <LabelInstallment>Demais parcelas</LabelInstallment>
                      <FirstValue>{formatMoneyBRL(paymentEnable.price)}</FirstValue>
                    </Installment>
                  </Conditional>
                </Conditional>
              </div>
            </FormCheck>
          ))}
        </FormWrapper>
        <ButtonStyles>
          <Button
            onClick={() => {
              handlePrint(details?.id, budget.id, reduceArrayIdPaymentMethods)
              props.setPressed(false)
            }}
            size="block"
            {...(reduceArrayIdPaymentMethods?.length < 1
              ? { isDisabled: true }
              : { isDisabled: false })}
          >
            <Icon
              {...(reduceArrayIdPaymentMethods?.length < 1
                ? { disabled: true }
                : { disabled: false })}
            >
              {SvgIcons.iconFile}
            </Icon>
            Gerar PDF
          </Button>
          <ButtonClear
            onClick={event => {
              ClearSelect(event)
              setIsNotChecked(true)
            }}
          >
            Limpar
          </ButtonClear>
        </ButtonStyles>
      </Content>
    </Modals.Content>
  )
}

export default ModalSelectPayments
