import { pxToRem } from '@/styles/sheets/helpers'
import breakpoints from '@pol-npm/riscos-financeiros-dashboard-ui/dist/theme/breakpoints'
import styled from 'styled-components'

export const Container = styled.div`
  background-color: #d9deea;
  border-radius: ${pxToRem(16)};
  display: flex;
  margin-top: ${pxToRem(64)};
  overflow: hidden;

  @media (max-width: ${breakpoints.md}) {
    flex-direction: column;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding: ${pxToRem(24)};
`

export const DescriptionWrapper = styled.div`
  margin-top: ${pxToRem(10)};
`

export const Image = styled.img`
  flex: 1;
  max-height: 275px;
  max-width: 50%;
  object-fit: cover;

  @media (max-width: ${breakpoints.md}) {
    max-width: 100%;
  }
`
