import { Forms } from '@/components'
import { Button } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import breakpoints from '@pol-npm/riscos-financeiros-dashboard-ui/dist/theme/breakpoints'
import styled from 'styled-components'
import media from 'styled-media-query'

export const Header = styled.div`
  align-items: center;
  display: flex;

  ${media.lessThan('medium')`
    flex-wrap: wrap;
    row-gap: 8px;
  `}
`

export const BackButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  margin-right: 16px;
  outline: none;
  padding: 6px;
`

export const UpdatedAt = styled.div`
  margin: 0 8px 0 auto;
`

export const ReloadButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 8px;
  outline: none;
  padding: 12px;
`

export const NewButton = styled(Button)`
  ${media.lessThan('medium')`
    width: 100% !important;
  `}
`

export const Filter = styled.div`
  align-items: center;
  display: flex;
  margin-top: 8px;

  @media (max-width: ${breakpoints.sm}) {
    flex-wrap: wrap;
    flex-direction: row-reverse;

    > :nth-child(1) {
      order: 3;
    }

    > :nth-child(2) {
      order: 2;
    }

    > :nth-child(3) {
      order: 1;
    }
  }
`

export const DropdownWrapper = styled.div`
  margin-right: 8px;
`

export const Input = styled(Forms.Input)`
  margin-left: auto;
  max-width: 298px;

  @media (max-width: ${breakpoints.sm}) {
    max-width: unset;
  }
`

export const DropdownItem = styled.div`
  padding: 8px;
  white-space: pre;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`

export const DropdownItemWrapper = styled.div`
  cursor: pointer;
  margin: 8px 0;
`

export const Content = styled.div`
  text-align: center;
`

export const SetPaidButtonContainer = styled.button`
  align-items: self-end;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  gap: 6px;
  outline: none;
  padding-right: 22px;
  position: relative;
`

export const LoaderWrapper = styled.div`
  padding: 128px 0;
`

export const TableWrapper = styled.div`
  padding: 32px 0;
  display: flex;
  justify-content: center;
`
