import { useEffect, useState } from 'react'

import { Conditional, Loading, Modals } from '@/components'
import { api } from '@/services'

import {
  Content,
  Title,
  AmountFormsTitle,
  TitleSelectForms,
  Form,
  FormCheck,
  Label,
  Installment,
  LabelInstallment,
  FirstValue,
  FormWrapper,
  Button,
  ButtonStyles,
  TitleLabel,
  ContentHead,
  ButtonClear,
  Input,
  ButtonClose,
  TitleHead,
  Icon,
} from './styles'
import { formatMoneyBRL, useIsMobileMedium } from '@/tools'
import { SvgIcons } from '@/components/SvgIcons'

const ModalSelectPayments = (props) => {
  const isMobile = useIsMobileMedium()

  const [status, setStatus] = useState<'printing' | 'loading' | ''>('')
  const [selected, setSelected] = useState({
    method: {
      id: '',
      installments: [],
    },
    installment: '',
  })
  const [methods, setMethods] = useState([])

  const [isNotChecked, setIsNotChecked] = useState(true)

  const [isChecked, setIsChecked] = useState(false)
  const [eventClick, setEventClick] = useState(false)

  const details = props.details.details

  const paymentMethods = details.budgets?.map((payments) => {
    return payments.payment_methods.filter(
      (payment) => !['62', '97'].includes(payment.id)
    )
  })

  useEffect(() => {
    props.onChange &&
      props.onChange({
        method: selected.method.id,
        installment: selected.installment,
      })
  }, [selected])

  const budget = details.budgets.find((budget) => {
    return budget.id === budget.id
  })

  const arrayIdPaymentMethods = methods.map((installments) => {
    return installments.installments
      .map((element) => {
        if (element.isChecked === true) {
          return element.id
        }
      })
      .filter(function (el) {
        return el
      })
  })
  const reduceArrayIdPaymentMethods = arrayIdPaymentMethods.reduce(
    (accumulator, currentValue) => {
      return [...accumulator, ...currentValue]
    },
    []
  )

  const disregardDelected = () => {
    if (reduceArrayIdPaymentMethods.length - 1) {
      return setIsNotChecked(true)
    }
  }

  const handlePrint = async (
    id: string,
    budget?: string,
    idpaymentMethods?: string
  ) => {
    try {
      setStatus('printing')
      props.setStatus('printing')
      await api.contracts.print(
        id,
        'budget',
        budget,
        String(reduceArrayIdPaymentMethods)
      )
    } catch (error) {
      setStatus('')
      props.setStatus('')
    } finally {
      setStatus('')
      props.setStatus('')
    }
  }
  const handleChange = (event) => {
    const { name, checked } = event.target
    if (checked) {
      setIsChecked(true)
    }

    setMethods((prev: any) => {
      return prev.map((e) => {
        e.installments.map((i) => {
          if (i.description === name) {
            i.isChecked = checked
            return i
          }
        })
        return e
      })
    })
  }
  function AllSelect(event) {
    const { checked } = event.target
    if (checked) {
      setIsChecked(true)
    }
    setMethods((prev: any) => {
      return prev.map((installments) => {
        installments.installments.map((element) => {
          element.isChecked = checked
          return element
        })
        return installments
      })
    })
  }

  function ClearSelect(event) {
    setMethods((prev: any) => {
      return prev.map((x, index) => {
        x.installments.map((b, index2) => {
          b.isChecked = false
          return b
        })
        return x
      })
    })
  }

  useEffect(() => {
    setMethods(paymentMethods[0])
  }, [])

  useEffect(() => {}, [methods])

  return (
    <Modals.Content padding={true} colorIcon="primary" size="medium">
      <Conditional when={status === 'printing'}>
        <Loading message="Aguarde, gerando PDF." />
      </Conditional>
      <Content>
        <Title>
          <p />
          <TitleLabel>Formas de pagamento </TitleLabel>
          <ButtonClose onClick={() => props.setPressed(false)}>
            {SvgIcons.closeIcon}
          </ButtonClose>
        </Title>

        <FormWrapper>
          <ContentHead>
            <TitleHead>
              Selecione as formas de pagamento para gerar o PDF.
            </TitleHead>
            <AmountFormsTitle>
              Máximo de 30 formas de pagamento.
            </AmountFormsTitle>
            <TitleSelectForms>Formas de pagamento</TitleSelectForms>
          </ContentHead>
          <FormCheck>
            <div>
              <Label>
                <Conditional when={!isMobile}>
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    name="id"
                    {...(isNotChecked === true
                      ? { checked: false }
                      : eventClick === true
                      ? { checked: true }
                      : {})}
                    onChange={(event) => {
                      setIsNotChecked(!isNotChecked)
                      AllSelect(event)
                    }}
                    onClick={() => setEventClick(true)}
                  />
                </Conditional>
                Selecionar todos
                <Conditional when={isMobile}>
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    name="id"
                    {...(isNotChecked === true
                      ? { checked: false }
                      : eventClick === true
                      ? { checked: true }
                      : {})}
                    onChange={(event) => {
                      setIsNotChecked(!isNotChecked)
                      AllSelect(event)
                    }}
                    onClick={() => setEventClick(true)}
                  />
                </Conditional>
              </Label>
            </div>
          </FormCheck>
          {methods.map((installment, index) => {
            return (
              <>
                {installment.installments.map((e, key) => (
                  <FormCheck key={key}>
                    <div className="teste-div">
                      <Label key={key}>
                        <Conditional when={!isMobile}>
                          <Input
                            type="checkbox"
                            className="form-check-input"
                            name={e.description}
                            checked={e?.isChecked}
                            onChange={(event) => {
                              handleChange(event)
                              disregardDelected()
                            }}
                            onClick={() => setEventClick(false)}
                          />
                        </Conditional>

                        {e.description === '1 x A VISTA NA CIA'
                          ? 'Boleto a vista'
                          : e.description.toLowerCase()}

                        <Conditional when={isMobile}>
                          <Input
                            type="checkbox"
                            className="form-check-input"
                            name={e.description}
                            checked={e?.isChecked}
                            onChange={(event) => {
                              handleChange(event)
                            }}
                          />
                        </Conditional>
                      </Label>
                      <Conditional when={!isMobile}>
                        <Installment>
                          <LabelInstallment>1ª parcela</LabelInstallment>
                          <FirstValue>{formatMoneyBRL(e.first)}</FirstValue>
                        </Installment>
                        <Conditional
                          when={e.description !== '1 x A VISTA NA CIA'}
                        >
                          <Installment>
                            <LabelInstallment>Demais parcelas</LabelInstallment>
                            <FirstValue>{formatMoneyBRL(e.price)}</FirstValue>
                          </Installment>
                        </Conditional>
                      </Conditional>
                    </div>
                  </FormCheck>
                ))}
              </>
            )
          })}
        </FormWrapper>
        <ButtonStyles>
          <Button
            onClick={() => {
              handlePrint(details.id, budget.id, reduceArrayIdPaymentMethods)
              props.setPressed(false)
            }}
            size="block"
            {...(reduceArrayIdPaymentMethods?.length < 1
              ? { isDisabled: true }
              : { isDisabled: false })}
          >
            <Icon
              {...(reduceArrayIdPaymentMethods?.length < 1
                ? { disabled: true }
                : { disabled: false })}
            >
              {SvgIcons.iconFile}
            </Icon>
            Gerar PDF
          </Button>
          <ButtonClear
            onClick={(event) => {
              ClearSelect(event)
              setIsNotChecked(true)
              setIsChecked(false)
            }}
          >
            Limpar
          </ButtonClear>
        </ButtonStyles>
      </Content>
    </Modals.Content>
  )
}

export default ModalSelectPayments
