import { useEffect, useState } from 'react'

import { Box } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Notification } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'

import { useQuery } from '@/tools'
import { api } from '@/services'

import {
  SplitScreen,
  LoginContent,
  LoginPresentation,
  Link,
} from './../../shared/ui'

import Form from './Form'
import FormSent from './FormSent'

const Content = () => {

  const query = useQuery()

  const [formSent, SetFormSent] = useState(false)
  const [initialLoading, setInitialLoading] = useState(true)

  const [token, setToken] = useState('')
  const [email, setEmail] = useState('')
  const [error, setError] = useState({ title: undefined, message: undefined })

  const verifyToken = async () => {
    try {
      setInitialLoading(true)
      await api.users.auth.verifyToken(query.hash as string)
    } catch (e) {
      const errorMap = {
        [undefined]: {
          title: 'Este link é inválido.',
          message: `
            <a href="/esqueci-senha">Clique aqui</a> para gerar um link válido e criar uma nova senha de acesso.
          `
        },
        '@user/expired-action-code': {
          title: 'Este link expirou.',
          message: `
            <a href="/esqueci-senha">Clique aqui</a> para gerar um novo link e criar uma nova senha de acesso.
          `
        },
      }
      setError(errorMap[e.code] ? errorMap[e.code] : errorMap[undefined])
    } finally {
      setInitialLoading(false)
    }
  }

  useEffect(() => {
    if (query.hash && query.email) {
      setToken(query.hash as string)
      setEmail(query.email as string)
    }
    verifyToken()
  }, [])

  if (initialLoading) {
    return (
      <>
        <Link
          label="Voltar"
          icon="arrowleft"
          to="/"
        />
        <Box sx={{ marginTop: "1rem" }}>
          <Notification
            title="Aguarde"
            description="Estamos carregando os dados..."
            showCloseButton={false}
            icon="hourglass"
          />
        </Box>
      </>
    )
  }

  if (error?.title && error?.message) {
    return (
      <>
        <Link
          label="Voltar"
          icon="arrowleft"
          to="/"
        />
        <Box sx={{ marginTop: "1rem" }}>
          <Notification
            title={error.title}
            description={error.message}
            showCloseButton={false}
            variant="error"
            icon="circleclose"
          />
        </Box>
      </>
    )
  }

  if (!formSent) {
    return (
      <Form
        onComplete={() => SetFormSent(true)}
        email={email}
        token={token}
      />
    )
  }

  return <FormSent />
}

const NewReset = () => {
  return (
    <SplitScreen>
      <LoginPresentation />
      <LoginContent>
        <Content />
      </LoginContent>
    </SplitScreen>
  )
}

export default NewReset
