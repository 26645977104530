import React, { useMemo, useState } from 'react'

import { useFormikContext } from 'formik'

import { useCurrentOrganization } from '@/modules'

import { ContainerIcon } from '../../styles'

import { BudgetPayment } from '../Details'
import { PropsPayment } from './interface'

import { Container, ContainerWrapper, InputSelect } from './styles'

const Payment = ({ budgets, labelSelect, isDisabled }: PropsPayment) => {
  const form = useFormikContext<BudgetPayment>()
  const [setActive, setActiveState] = useState('')
  const [setRotate, setRotateState] = useState('custom-arrow')
  const organization = useCurrentOrganization()

  function toggle() {
    setActiveState(setActive === '' ? 'active' : '')

    setRotateState(
      setActive === 'active' ? 'custom-arrow' : 'custom-arrow rotate'
    )
  }

  const paymentMethods = useMemo(
    () =>
      budgets.length &&
      budgets[0].budgets[0].payment_methods.filter((pm) => {
        const brokerSettings = organization.partnerships.find(
          (p) => p.partner.id === budgets[0].partner
        )

        if (
          [
            '62', // cartão de crédito
            '97', // cartão porto seguro
          ].includes(pm.id)
        ) {
          return false
        }

        switch (pm.id) {
          /* boleto à vista */
          case '11': {
            return brokerSettings?.settings[
              'porto_imobiliaria.payments.bankSlip.enabled'
            ]
          }
          /* fatura mensal s/ entrada */
          case '41': {
            return brokerSettings?.settings[
              'porto_imobiliaria.payments.invoiceWithoutDown.enabled'
            ]
          }
          /* fatura mensal (1 parcela a vista no boleto) */
          case '81': {
            return brokerSettings?.settings[
              'porto_imobiliaria.payments.invoiceWithDown.enabled'
            ]
          }
        }

        return []
      }),
    [budgets, organization.partnerships]
  )

  const handleChange = ({ target: { value } }) => {
    const [filtered] = paymentMethods.filter(el => el.installments.find(installment => installment.id === value)) 

    const installment = value
    const method = filtered.id

    form.setFieldValue('method', method)
    form.setFieldValue('installment', installment)
  }

  return (
    <Container>
      <ContainerWrapper>
        <InputSelect
          name="installment"
          onChange={handleChange}
          onClick={toggle}
          disabled={!paymentMethods || isDisabled}
        >
          <option value="1">{labelSelect}</option>
          {!!paymentMethods &&
            paymentMethods.map((payment) =>
              payment.installments.map(({ id, description }, index) => (
                <option key={index + id} value={id}>{`${description}`}</option>
              ))
            )}
        </InputSelect>
        <ContainerIcon
          className={`${setRotate}`}
          name="chevron-up"
        ></ContainerIcon>
      </ContainerWrapper>
    </Container>
  )
}

export default Payment
