import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { rem, rgba } from 'polished'
import { motion } from 'framer-motion'

import { themes, layers, helpers } from '@/styles'

import { Ionicons } from '../../../Icons'
import { IToastedProps } from './interfaces'

export const Container = styled.div<IToastedProps>`
  position: fixed;
  z-index: ${layers.toast};
  left: 50%;
  transform: translateX(-50%);
  top: 10px;

  width: 90%;

  display: flex;
  align-items: center;
  flex-direction: column;

  ${(props) =>
    props.position &&
    {
      'center-bottom': css`
        ${media.greaterThan('large')`
          width: 250px;
        `}

        ${media.greaterThan('huge')`
          width: 350px;
        `}
      `,
    }[props.position]}
`

export const Item = styled(motion.div)<{
  type?: 'success' | 'error' | 'warning' | 'info'
}>`
  ${helpers.defaultBoxShadow}

  padding: 10px;

  display: inline-flex;
  align-items: flex-start;

  color: #fff;
  cursor: pointer;
  user-select: none;
  border-radius: 8px;
  font-size: ${rem('10px')};
  font-family: ${themes.fonts.title};

  ${media.greaterThan('huge')`
    padding: 16px;
    
    font-size: ${rem('14px')};
  `}

  ${media.lessThan('large')`
    width: 100%;
    padding: 16px;

    justify-content: space-between;
  `}

  ${(props) =>
    props.type &&
    {
      success: css`
        background-color: ${rgba(themes.palletes.success, 0.9)};
      `,

      error: css`
        background-color: ${rgba(themes.palletes.danger, 0.9)};
      `,

      warning: css`
        background-color: ${rgba(themes.palletes.warning, 0.9)};
      `,

      info: css`
        background-color: ${themes.text.higher};
      `,
    }[props.type]}

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }
`

export const Content = styled.div`
  padding: 0 8px;
`

export const Close = styled.button`
  all: unset;

  padding: 2px;
  margin-left: 8px;

  cursor: pointer;
  transition: 0.15s;
  border-radius: 100%;

  &:hover {
    transition: 0.15s;
    background-color: ${rgba('#FFF', 0.3)};
  }
`

export const Icon = styled(Ionicons)``
