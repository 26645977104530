import { useEffect, useState } from 'react'
import { FormikProvider, useFormik } from 'formik'

import { Actions, Forms, Modals } from '@/components'
import {
  Button,
  CloseButton,
  Icon,
  Footer,
  Item,
  Header,
  Subtitle,
  Title,
  Options,
} from './styles'
import { SvgIcons } from '@/components/SvgIcons'


import schema from './schema'

namespace FilterBudgets {
  export interface Values {
    status?: string[]
    order?: string
    plan?: string[]
  }

  export type Props = Modals.Promised.PromisedModalProps<FilterBudgets.Values> &
    FilterBudgets.Values

  const Component = (props: FilterBudgets.Props) => {
    const [selectFilter, setSelectFilter] = useState('')
    const [isChecked, setIsChecked] = useState(true)

    const form = useFormik<FilterBudgets.Values>({
      initialValues: {
        status: props.status || [],
        order: props.order || undefined,
        plan: props.plan || [],
      },
      validationSchema: schema,
      onSubmit: props.onSubmit,
    })

    const handleOrder = (event) => {
      form.setFieldValue(
        'order',
        !!event.target.name ? event.target.name : undefined
      )
      setSelectFilter(event.target.name)
    }
    const selectedCalculateds = () => {
      setIsChecked(!false)
      if (form.values.status.length > 0 && isChecked) {
        form.setFieldValue('status', [])
        form.setFieldValue('plan', [])
      }
    }
    useEffect(() => {
      setSelectFilter(form.values.order)
    }, [selectFilter])
    

    return (
        <Modals.Content padding={true} size="small" onDismiss={props.onDismiss}>
          <FormikProvider value={form}>
            <Header>
              <Icon />
              <Title>Filtros </Title>
              <CloseButton onClick={() => props.onDismiss()}>
                {SvgIcons.closeIcon}
              </CloseButton>
            </Header>
            <Item>
              <Subtitle>Status</Subtitle>
              <Options firstColumn={false}>
                <Forms.Choice
                  type="checkbox"
                  name="status"
                  value="processing"
                  label="Em Processamento"
                />
                <Forms.Choice
                  type="checkbox"
                  name="status"
                  value="done"
                  label="Concluído"
                />
                <Forms.Choice
                  type="checkbox"
                  name="status"
                  value="failure"
                  label="Falha na integração"
                />
                <Forms.Choice
                  type="checkbox"
                  name="status"
                  value="critical"
                  label="Crítica"
                />
                <Forms.Choice
                  type="checkbox"
                  name="status"
                  value="calculated"
                  label="Orçamento calculado"
                />
              </Options>
            </Item>
            <Item>
              <Subtitle>Ordenar por</Subtitle>
              <Options>
                <Button
                  isActive={selectFilter === ''}
                  name=""
                  onClick={handleOrder}
                >
                  Ordenacao padrao
                </Button>
                <Button
                  isActive={selectFilter === 'asc'}
                  name="asc"
                  onClick={handleOrder}
                >
                  Proponente A a Z
                </Button>
              </Options>
            </Item>
            <Item>
              <Subtitle>Tipo de planos</Subtitle>
              <Options firstColumn={true}>
                <Forms.Choice
                  type="checkbox"
                  name="plan"
                  value="COMMERCIAL"
                  label="Plano comercial"
                />
                <Forms.Choice
                  type="checkbox"
                  name="plan"
                  value="RESIDENTIAL"
                  label="Plano residencial"
                />
              </Options>
            </Item>

            <Footer>
              <Actions.Button
                onClick={() => {
                  form.handleSubmit()
                }}
              >
                Aplicar filtros
              </Actions.Button>
              <Actions.Button kind="simple" onClick={selectedCalculateds}>
                Remover Filtros
              </Actions.Button>
            </Footer>
          </FormikProvider>
        </Modals.Content>
    )
  }

  export const openModal =
    Modals.Promised.createModal<FilterBudgets.Props>(Component)
}

export default FilterBudgets
