import styled from 'styled-components'
import media from 'styled-media-query'
import { rem, size } from 'polished'

import { themes } from '@/styles'
import { firebaseMedia } from '@/tools'

export const Container = styled.header`
  width: 100%;
  height: 60px;

  background-color: ${themes.page.colors.background};

  ${media.greaterThan('huge')`
    height: 80px;
  `}
`

export const Content = styled.div`
  height: 100%;

  display: flex;
  align-items: center;

  ${media.greaterThan('large')`
    width: 1100px;
    margin: 0 auto;
  `}

  ${media.greaterThan('huge')`
    width: 1300px;
  `}

  ${media.lessThan('large')`
    padding: 0 20px;
  `}
`

export const Logo = styled.img.attrs(() => ({
  src: firebaseMedia('logos/Logos_Porto.svg'),
}))`
  margin-left: 24px;
  padding-left: 24px;
  width: 100px;
`

export const Title = styled.h6`
  margin-left: 8px;
  font-size: ${rem('8px')};
  color: ${themes.text.higher};

  ${media.greaterThan('large')`
    font-size: ${rem('10px')};
  `}

  ${media.greaterThan('huge')`
    font-size: ${rem('11px')};
  `}
`

export const Actions = styled.div`
  margin-left: auto;
`

export const Action = styled.button`
  all: unset;

  cursor: pointer;
  transition: 0.15s;
  color: ${themes.text.high};

  > svg {
    ${size('18px')}
  }

  &:hover {
    opacity: 0.6;
    transition: 0.15s;
  }
`
