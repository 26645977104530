import { useState } from "react"

import { Box, Flex, Typography } from "@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base"

import { Forms, toast } from "@/components"
import { useCurrentUser } from "@/modules"
import { api } from "@/services"

import { useOnboarding } from "./../context"
import { Card, OnboardingWrapper } from "./../ui"
import { personalDataSchema } from "./../schema"

const PersonalData = ({
  wizard,
  currentStep,
}) => {

  const [isLoading, setIsLoading] = useState(false)
  const onboarding = useOnboarding()
  const currentUser = useCurrentUser()

  const { attributes } = onboarding.state.form

  return (
    <OnboardingWrapper
      currentStep={currentStep}
      isLoading={isLoading}
      wizard={wizard}
      hidePreviousButton={true}
      formData={{
        initialValues: {
          policyholder: {
            ...attributes.policyholder,
            phone: attributes.policyholder.phone || currentUser?.store.phone,
            name: currentUser?.store.name,
            email: currentUser?.store.email,
            document: currentUser?.store.document,
          }
        },
        validationSchema: personalDataSchema,
        onSubmit: async ({ policyholder }) => {
          try {

            setIsLoading(true)

            onboarding.dispatch({
              type: 'UPDATE_FORM_ATTRIBUTES',
              payload: {
                policyholder: {
                  ...policyholder,
                  email: currentUser?.store.email,
                  lgpd: true,
                  termsOfUse: true,
                }
              }
            })

            const data = await api.users.newAccount.createOrUpdateLead(onboarding.state.form)

            onboarding.dispatch({
              type: 'UPDATE_FORM_FROM_API',
              payload: data
            })

            wizard.next()

          } catch (e) {
            console.log(e)
            toast(
              `
                Ocorreu um erro inesperado. Por favor, tente novamente...
              `,
              {
                type: 'error',
              }
            )
          } finally {
            setIsLoading(false)
          }
        },
      }}
    >
      <Card>
        <Typography
          variant="porto-title-4-semibold"
          content="Dados pessoais"
        />
        <Box
          sx={{
            marginTop: "2rem",
          }}
        >
          <Typography
            variant="porto-title-6-medium"
            content="Confirme seus dados"
          />
        </Box>
        <Flex
          sx={{
            marginTop: "1rem",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: {
                xs: "1fr",
                md: "repeat(2, 2fr)",
              },
              gap: "1rem",
            }}
          >
            <Forms.InputGroup
              name="policyholder.name"
              label="Nome"
              placeholder="Seu nome"
              readOnly={true}
            />
            <Forms.InputGroup
              name="policyholder.email"
              label="E-mail"
              placeholder="nome@email.com.br"
              readOnly={true}
            />
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: {
                xs: "1fr",
                md: "repeat(2, 2fr)",
              },
              gap: "1rem",
            }}
          >
            <Forms.InputGroup
              name="policyholder.document"
              label="CPF/CNPJ"
              placeholder="00.000.000/0000-00"
              mask="document"
              readOnly={true}
            />
            <Forms.InputGroup
              name="policyholder.phone"
              label="Telefone/celular"
              placeholder="(00) 90000-0000"
              type="phone"
              mask="phone"
            />
          </Box>
        </Flex>
      </Card>
    </OnboardingWrapper>
  )
}

export default PersonalData
