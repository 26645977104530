import { pxToRem } from '@/styles/sheets/helpers'
import breakpoints from '@pol-npm/riscos-financeiros-dashboard-ui/dist/theme/breakpoints'
import styled from 'styled-components'

export const Container = styled.div`
  background-color: #bfccec;
  border-radius: ${pxToRem(16)};
  display: flex;
  height: 296px;
  overflow: hidden;

  @media (max-width: calc(${breakpoints.sm})) {
    flex-flow: row wrap;
    height: 547px;
  }
`

export const DescriptionWrapper = styled.div`
  display: flex;
  margin: ${pxToRem(16)} 0 0;
`

export const Image = styled.img`
  height: auto;
  width: 588px;

  @media (max-width: calc(${breakpoints.sm})) {
    width: 100%;
  }
`

export const WrapperContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding: 0 ${pxToRem(32)};

  @media (max-width: calc(${breakpoints.sm})) {
    padding: ${pxToRem(24)} ${pxToRem(32)};
  }
`

export const WrapperImage = styled.div`
  display: flex;
  width: 50%;

  @media (max-width: calc(${breakpoints.sm})) {
    max-width: 100%;
    width: 100%;
  }
`
