import styled, { css } from 'styled-components'
import media from 'styled-media-query'

import { IGridProps } from './interfaces'

const generateCSSPropertyResponsively = (property, value) => {
  if (typeof value === 'string')
    return css`
      ${property}: ${value};
    `

  return css`
    ${property}: ${value[0]};

    ${media.greaterThan('medium')`
      ${property}: ${value[1]};
    `}

    ${media.greaterThan('large')`
      ${property}: ${value[2]};
    `}

    ${media.greaterThan('huge')`
      ${property}: ${value[3]};
    `}
  `
}

export const Container = styled.div<IGridProps>`
  display: grid;

  ${({ columns }) =>
    columns &&
    css`
      ${generateCSSPropertyResponsively('grid-template-columns', columns)}
    `}

  ${({ gap }) =>
    gap &&
    css`
      ${generateCSSPropertyResponsively('grid-gap', gap)}
    `}

  ${({ space }) =>
    space &&
    css`
      ${generateCSSPropertyResponsively('margin', space)}
    `}
`
