import styled, { css } from 'styled-components'
import { rem, size } from 'polished'
import media from 'styled-media-query'

import { themes } from '@/styles'

export const Container = styled.div``

export const Content = styled.div``

export const List = styled.div<{ hasMore: boolean }>`
  position: relative;

  &:before {
    position: absolute;
    top: 0;
    left: 1px;

    height: 96%;

    content: '';
    border-left: 2px solid ${themes.utilities.border};

    ${media.greaterThan('huge')`
      top: 4px;
      left: 2px;
    `}
  }

  ${({ hasMore }) =>
    hasMore &&
    css`
      &:after {
        position: absolute;
        bottom: 0;
        left: 0;

        width: 100%;
        height: 50px;

        content: '';
        background-image: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0) 0%,
          #fff 100%
        );
      }
    `}
`

export const Item = styled.div`
  position: relative;

  padding-left: 12px;

  ${media.greaterThan('huge')`
    padding-left: 16px;
  `}

  &:not(:last-of-type) {
    margin-bottom: 16px;

    ${media.greaterThan('huge')`
      margin-bottom: 32px;
    `}
  }

  &:before {
    ${size('4px')}

    position: absolute;
    top: 4px;
    left: 0;

    content: '';
    border-radius: 100%;
    background-color: ${themes.text.low};
    box-shadow: 0 0 0 3px ${themes.page.colors.background};

    ${media.greaterThan('huge')`
      ${size('6px')}

      top: 7px;
    `}
  }
`

export const Heading = styled.p`
  margin-bottom: 16px;

  font-size: ${rem('9px')};
  color: ${themes.text.high};

  ${media.greaterThan('huge')`
    font-size: ${rem('13px')};
  `}
`

export const Table = styled.table`
  width: 100%;

  text-align: left;
  border-radius: 4px;
  border: 1px solid ${themes.utilities.border};

  > thead {
    th {
      padding: 3px 6px;

      font-weight: normal;
      font-size: ${rem('7px')};
      color: ${themes.text.low};

      ${media.greaterThan('large')`
        font-size: ${rem('8px')};
      `}

      ${media.greaterThan('huge')`
        padding: 6px 8px;

        font-size: ${rem('11px')};
      `}
    }
  }

  > tbody {
    > tr {
      &:nth-child(odd) {
        background-color: ${themes.page.colors.content};
      }

      > td {
        padding: 4px 6px;

        font-size: ${rem('7px')};
        color: ${themes.text.high};

        ${media.greaterThan('large')`
          font-size: ${rem('8px')};
        `}

        ${media.greaterThan('huge')`
          padding: 6px 8px;

          font-size: ${rem('12px')};
        `}
      }
    }
  }
`

export const Footer = styled.div`
  margin-top: 16px;
`
