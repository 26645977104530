import { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'

import {
  setAnalyticsPage,
  setAnalyticsSite,
  setAnalyticsUser,
} from '@/store/ducks/analytics'

import {
  EAnalyticsBrand,
  EAnalyticsPortal,
  EAnalyticsProducts,
} from '@/store/ducks/analytics/interfaces'

import {
  FifthSection,
  FirstSection,
  FourthSection,
  PortoEducSection,
  SecondSection,
  SixthSection,
  ThirdSection,
} from './components'
import * as S from './styles'

const LandingPage = () => {
  const dispatch = useDispatch()
  const isMounted = useRef(false)

  const handleAnalytics = () => {
    dispatch(
      setAnalyticsPage({
        name: 'Portal Imobiliária - Landing Page',
        product: EAnalyticsProducts.aluguelFianca,
      })
    )

    dispatch(
      setAnalyticsSite({
        brand: EAnalyticsBrand.portoseguro,
        portal: EAnalyticsPortal.imobiliarias,
      })
    )

    dispatch(
      setAnalyticsUser({
        logado: false,
        protocolo: '',
        susep: '',
        user_id: '',
      })
    )
  }

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true
      handleAnalytics()
    }
  }, [])

  return (
    <>
      <S.SectionBackground>
        <S.SectionContainer>
          <FirstSection />
        </S.SectionContainer>
      </S.SectionBackground>

      <S.SectionContainer>
        <SecondSection />
      </S.SectionContainer>

      <S.SectionContainer>
        <ThirdSection />
      </S.SectionContainer>

      <S.SectionBackground backgroundColor="secondary">
        <S.SectionContainer>
          <FourthSection />
        </S.SectionContainer>
      </S.SectionBackground>

      <S.SectionContainer>
        <PortoEducSection />
      </S.SectionContainer>

      <S.SectionContainer>
        <FifthSection />
      </S.SectionContainer>

      <S.SectionContainer>
        <SixthSection />
      </S.SectionContainer>
    </>
  )
}

export default LandingPage
