import { useState } from 'react'

import {
  Box,
  Flex,
  Typography,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Button } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'

import { useNewAccount, ActionTypes } from './../context'
import { Link } from '../../components'

type ProfileType = 'BROKER' | 'REAL_ESTATE'

const ProfileTypeSelector = ({ wizard, partnerAccreditatedStep }) => {
  const [isLoading, setIsLoading] = useState(false)
  const newAccount = useNewAccount()

  const handleProfileTypeClick = async (profileType: ProfileType) => {
    setIsLoading(true)

    newAccount.dispatch({
      type: ActionTypes.UPDATE,
      payload: {
        profileType,
      },
    })

    if (profileType === 'BROKER') {
      wizard.next()
      return
    }

    wizard.push(partnerAccreditatedStep)
  }

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        gap: '1rem',
      }}
    >
      <Link label="Voltar" icon="arrowleft" to="/login" />
      <Typography variant="porto-title-3-semibold" content="Crie sua conta" />
      <Typography
        variant="porto-text-body-1-regular"
        content={`
          Por favor, informe seu perfil de usuário:
        `}
      />
      <Flex
        sx={{
          gap: '1rem',
        }}
      >
        <Flex
          sx={{
            gap: '1rem',
          }}
        >
          <Box
            sx={{
              marginTop: '1.5rem',
            }}
          >
            <Button
              type="submit"
              size="large"
              isLoading={isLoading}
              isDisabled={isLoading}
              onClick={() => handleProfileTypeClick('REAL_ESTATE')}
            >
              Sou Imobiliária
            </Button>
          </Box>
          <Box
            sx={{
              marginTop: '1.5rem',
            }}
          >
            <Button
              type="submit"
              size="large"
              isLoading={isLoading}
              isDisabled={isLoading}
              onClick={() => handleProfileTypeClick('BROKER')}
            >
              Sou Corretor
            </Button>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default ProfileTypeSelector
