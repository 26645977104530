import { api } from '@/services'
import { useQuery } from 'react-query'

export function usePlans() {
  const query = useQuery(['capitalizacao', 'data', 'plans'], api.capitalizacao.getPlans, {
    refetchOnWindowFocus: false,
  })

  return query
}
