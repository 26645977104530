import styled from 'styled-components'
import { rgba, rem } from 'polished'

import { Actions as ActionsComponent } from '@/components'
import { themes } from '@/styles'

export const Container = styled.div`
  padding: 16px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border-radius: 8px;
  border: 1px solid ${rgba(themes.palletes.primary, 0.3)};
  background-color: ${rgba(themes.palletes.primary, 0.02)};
`

export const Details = styled.div``

export const Title = styled.h5`
  font-weight: 500;
`

export const Description = styled.p`
  margin-top: 4px;

  font-size: ${rem('12px')};
  color: ${themes.text.high};
`

export const Actions = styled.div`
  margin-top: 16px;

  display: flex;
`

export const Action = styled(ActionsComponent.Button).attrs(() => ({
  size: 'small',
}))`
  flex: 1;

  &:not(:last-of-type) {
    margin-right: 6px;
  }
`
