import { createEffect, createEvent } from 'effector'

import { api } from '@/services'

import store from '../store'

export const refreshToken = createEffect('user/refresh-token', {
  handler: async () => {
    const { access_token, refresh_token } = await api.users.auth.refreshToken(
      store.getState().tokens.refresh
    )

    return {
      access: access_token,
      refresh: refresh_token,
    }
  },
})

export const handleLogin = createEffect('createEffect', {
  handler: async ({ email, password }) => {
    const { access, refresh } = await api.users.auth.signInWithEmailAndPassword(
      email,
      password
    )

    return {
      access,
      refresh,
      email,
    }
  },
})

export const handleLogout = createEvent('handleLogout')

store
  .on(refreshToken.doneData, (state, tokens) => ({
    ...state,
    tokens,
  }))
  .on(handleLogin.fail, (state) => {
    return state
  })
  .on(handleLogin.doneData, (state, { access, refresh, email }) => {
    return {
      ...state,
      tokens: {
        access,
        refresh,
      },
      user: {
        ...state.user,
        email,
      },
    }
  })
  .reset(handleLogout)
  .watch(({ tokens }) => {
    if (tokens.access) {
      api.instance.v1.defaults.headers.authorization = `Bearer ${tokens.access}`
      api.instance.v2.defaults.headers.authorization = `Bearer ${tokens.access}`
    }
  })

async function handleUnauthorizedError(error) {
  const originalRequest = error.config
  const { tokens } = store.getState()
  const tokenMessages = [
    'auth/access-token-expired',
    '@auth/expired-access-token',
  ]

  if (
    tokens.refresh &&
    !originalRequest?.retry &&
    error.response.status === 401 &&
    tokenMessages.includes(error.response.data.code)
  ) {
    try {
      originalRequest.retry = true

      const { access } = await refreshToken(null)

      originalRequest.headers.authorization = `Bearer ${access}`

      return api.instance.v2(originalRequest)
    } catch (error) {
      handleLogout(null)
      throw error
    }
  }

  return Promise.reject(error)
}

async function handlePartnershipAccessDenied(error) {
  const errorCode = '@auth/partnership-access-denied'

  if (error.response.data.code === errorCode) {
    window.location.replace('/')
  }

  return Promise.reject(error)
}

api.instance.v1.interceptors.response.use(
  (response) => response,
  handleUnauthorizedError
)

api.instance.v1.interceptors.response.use(
  (response) => response,
  handlePartnershipAccessDenied
)

api.instance.v2.interceptors.response.use(
  (response) => response,
  handleUnauthorizedError
)

api.instance.v2.interceptors.response.use(
  (response) => response,
  handlePartnershipAccessDenied
)
