
import Category from './Category'

import { Container, List } from './styles'

const categories = [
  {
    to: '/organizacoes',
    color: '#fb563b',
    icon: 'business-outline',
    title: 'Organizações',
    description: 'Gerencie todas as organizações do sistema.',
    onClick: console.log,
  },
  {
    to: '/usuarios',
    color: '#2C3DA8',
    icon: 'people-outline',
    title: 'Usuários',
    description: 'Gerencie as contas de usuários do sistema.',
    onClick: console.log,
  },
  // {
  //   to: '/portabilidades',
  //   color: '#25c5da',
  //   icon: 'document-attach-outline',
  //   title: 'Portabilidades',
  //   description: 'Parametrize as opções de portabilidade de carteira.',
  //   onClick: console.log,
  // },
  // {
  //   to: '/ajuda',
  //   color: '#2DD9CE',
  //   icon: 'help-circle-outline',
  //   title: 'Ajuda',
  //   description: 'Tutoriais de uso do sistema.',
  //   onClick: console.log,
  // },
]

const Categories = () => {
  return (
    <Container>
      <List>
        {categories.map((category, index) => (
          <Category
            key={index}
            to={category.to}
            color={category.color}
            icon={category.icon}
            title={category.title}
            description={category.description}
            onClick={category.onClick}
          />
        ))}
      </List>
    </Container>
  )
}

export default Categories
