import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { rem, rgba } from 'polished'

import { themes } from '@/styles'
import { Icons } from '@/components'

type ContainerProps = {
  viewOnly?: boolean
}

type HeadingProps = {
  removeMargin?: boolean
}

type TitleProps = {
  smaller?: boolean
}

type DescriptionProps = {
  smaller?: boolean
}

export const Container = styled.div<ContainerProps>`
  position: relative;

  width: 100%;
  padding: 16px;

  border-radius: 4px;
  border: 1px dotted ${themes.utilities.border};

  &:not(:last-of-type) {
    margin-bottom: 16px;
  }

  ${(props) =>
    props.viewOnly &&
    css`
      &:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        content: '';
        cursor: not-allowed;
        border-radius: 4px;
        border: 1px solid ${rgba(themes.palletes.primary, 0.5)};
        background-color: ${rgba(themes.palletes.primary, 0.03)};
      }
    `}
`

export const LockIcon = styled(Icons.Ionicons).attrs(() => ({
  name: 'lock-closed',
}))`
  position: absolute;
  top: 10px;
  right: 10px;

  font-size: ${rem('14px')};
  color: ${themes.palletes.primary};
`

export const Heading = styled.div<HeadingProps>`
  ${({ removeMargin }) =>
    !removeMargin &&
    css`
      margin-bottom: 16px;
    `}
`

export const UpperHeading = styled.h5`
  margin-bottom: ${rem('14px')};
  margin-top: ${rem('32px')};
  font-weight: bold;
`

export const Title = styled.h5<TitleProps>`
  margin-bottom: 8px;

  font-weight: 500;

  ${(props) =>
    props.smaller &&
    css`
      font-size: ${rem('8px')};
      color: ${themes.text.high};

      ${media.greaterThan('huge')`
        font-size: ${rem('12px')};
      `}
    `}
`

export const Description = styled.h6<DescriptionProps>`
  margin-top: 6px;

  line-height: 140%;
  font-weight: lighter;
  font-size: ${rem('10px')};
  color: ${themes.text.medium};

  ${media.greaterThan('huge')`
    font-size: ${rem('14px')};
  `}

  ${(props) =>
    props.smaller &&
    css`
      font-size: ${rem('8px')};

      ${media.greaterThan('huge')`
        font-size: ${rem('12px')};
      `}
    `}
`

export const Content = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(2, 1fr);

  ${media.greaterThan('medium')`
    grid-template-columns: 0.8fr 1fr;
  `}

  &:not(:last-of-type) {
    margin-bottom: 24px;
  }
`
