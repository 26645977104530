import { useMemo } from 'react'

export const useMaintenanceInterval = () => {
  const maintenanceInterval = useMemo(() => {
    if (
      !process.env.REACT_APP_MAINTENANCE_DATE_START ||
      !process.env.REACT_APP_MAINTENANCE_DATE_END
    )
      return

    const now = new Date()

    const startDate = new Date(process.env.REACT_APP_MAINTENANCE_DATE_START)
    const endDate = new Date(process.env.REACT_APP_MAINTENANCE_DATE_END)

    if (now > endDate) return

    const startString = startDate.toLocaleDateString('pt-BR', {
      timeZone: 'UTC',
    })
    const endString = endDate.toLocaleDateString('pt-BR', { timeZone: 'UTC' })

    return `${startString} e ${endString}`
  }, [])

  return maintenanceInterval
}
