import React from 'react'

import { IAddressProps, IAddressValuesProps } from './interfaces'

import { Container, Edit, Icon, Heading, Content, Action } from './styles'

const Address: React.FC<IAddressProps> = ({
  title = 'Endereço cadastrado',
  fetchAddress,
  ...props
}) => {
  if (!props.street) return null

  const edit = (props: IAddressValuesProps) => {
    fetchAddress(props)
  }

  return (
    <Container>
      <Icon />

      <Content>
        <Heading>{title}</Heading>
        {props.street}, {props.number} - {props.neighborhood}.
        <br />
        {props.city}, {props.state}. {props.zipcode}.
        <br />
        {props.complement}
        {props.complement && <br />}
        {props.action && (
          <Action kind="ghost" onClick={props.action.handler}>
            {props.action.text}
          </Action>
        )}
        <Edit.Action
          isDisabled={props.isDisabled}
          kind="ghost"
          onClick={() => edit(props)}
          data-gtm-type="click"
          data-gtm-clicktype="button"
          data-gtm-name="editar:endereco-cadastrado"
        >
          Editar
        </Edit.Action>
      </Content>
    </Container>
  )
}

export default Address
