import { Forms, Grid } from '@/components'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Card } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import React from 'react'

const Coverages: React.FC = () => {
  return (
    <Card>
      <Grid>
        <Typography id="coberturas-adicionais" as="span" variant="porto-title-4-semibold">
          Coberturas adicionais
        </Typography>
        <Typography as="span" variant="porto-text-body-1-regular" sx={{ marginBottom: '32px' }}>
          Defina os valores para coberturas adicionais ou deixe em branco para manter sem.
        </Typography>
        <Grid gap="16px">
          <Grid columns={['repeat(2, 1fr)', 'repeat(3, 1fr)', 'repeat(5, 1fr)']} gap="16px">
            <Forms.InputGroup
              mask="money"
              placeholder="R$"
              name="coverages.coverages_main.iptu"
              label="IPTU"
            />
            <Forms.InputGroup
              mask="money"
              placeholder="R$"
              name="coverages.coverages_main.condominium"
              label="Condomínio"
            />
            <Forms.InputGroup
              mask="money"
              placeholder="R$"
              name="coverages.coverages_main.water"
              label="Conta de água"
            />
            <Forms.InputGroup
              mask="money"
              placeholder="R$"
              name="coverages.coverages_main.electricity"
              label="Conta de luz"
            />
            <Forms.InputGroup
              mask="money"
              placeholder="R$"
              name="coverages.coverages_main.gas"
              label="Gás canalizado"
            />
          </Grid>
          <Typography as="span" variant="porto-text-body-1-regular" color="neutras-black-70">
            As coberturas marcadas abaixo terão a Importância Segurada (IS) igual ao valor da
            cobertura do aluguel.
          </Typography>
          <Grid columns={['repeat(2, 1fr)', 'repeat(4, 1fr)']} gap="16px">
            <Forms.Choice
              name="coverages.coverages_additionals.property_damages"
              type="checkbox"
              label="Danos ao imóvel"
            />
            <Forms.Choice
              name="coverages.coverages_additionals.internal_paint"
              type="checkbox"
              label="Pintura interna"
            />
            <Forms.Choice
              name="coverages.coverages_additionals.external_paint"
              type="checkbox"
              label="Pintura externa"
            />
            <Forms.Choice
              name="coverages.coverages_additionals.recision_fine"
              type="checkbox"
              label="Multa rescisória"
            />
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}

export default Coverages
