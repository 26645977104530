import { useContract } from '@/modules'
import { Card } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import React, { useId, useMemo } from 'react'

import {
  dashText,
  formatCustomerName,
  formatDocument,
  formatMoneyBRL,
} from '@/tools'

import { Icons } from '@/components'
import { Products } from '@/screens/Contracts/Products/shared/entities'
import { capitalize, formatCEP } from '@brazilian-utils/brazilian-utils'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import * as S from './styled'

export const Resume: React.FC = () => {
  const checkBoxId = useId()
  const contract = useContract<Products.ESSENCIAL>()

  const budget = useMemo(() => {
    const budgetId = contract.payload?.payment?.budget

    if (!budgetId) return

    const budget = contract.budgets?.find((e) => e.id === budgetId)

    return budget
  }, [contract.budgets, contract.payload?.payment?.budget])

  const method = useMemo(() => {
    if (!budget || !contract.payload?.payment?.method) return

    return budget.payment_methods.find((method) => {
      return method.id === contract.payload?.payment.method
    })
  }, [budget, contract.payload?.payment?.method])

  const installment = useMemo(() => {
    if (!method || !contract.payload?.payment?.installment) return

    return method.installments.find((installment) => {
      return installment.id === contract.payload?.payment?.installment
    })
  }, [contract.payload?.payment?.installment, method])

  const totalValue = useMemo(() => {
    if (!method) return

    const i = method.installments[0]

    return i.total
  }, [method])

  if (contract.product !== Products.ESSENCIAL) return null

  const customersCount = contract.payload?.customers?.length

  return (
    <Card sx={{ padding: 0, height: 'fit-content' }}>
      <S.ExpandController type="checkbox" id={checkBoxId} />

      <S.ExpandedLabel htmlFor={checkBoxId}>
        <Typography as="span" variant="porto-text-caption-bold">
          Ver resumo
        </Typography>

        <Icons.FeatherIcons name="ChevronUp" />
      </S.ExpandedLabel>

      <S.Content>
        <S.Section>
          <Typography
            variant="porto-text-caption-bold"
            color="system-text-secondary"
            content="Orçamento"
          />
          <S.SectionRow>
            <Typography
              variant="porto-text-body-2-bold"
              content="Orçamento nº: "
            />
            <Typography
              variant="porto-text-body-2-regular"
              content={
                !!contract.policy?.proposal
                  ? Number(contract.policy?.proposal).toString()
                  : 'Aguardando Emissão'
              }
            />
          </S.SectionRow>

          <S.SectionRow>
            <Typography
              variant="porto-text-body-2-bold"
              content="Criado em: "
            />
            <Typography
              variant="porto-text-body-2-regular"
              content={
                contract.created_at &&
                new Date(contract.created_at).toLocaleDateString()
              }
            />
          </S.SectionRow>
        </S.Section>

        <S.Section>
          <Typography
            variant="porto-text-caption-bold"
            color="system-text-secondary"
            content={`Inquilinos (${customersCount})`}
          />

          {customersCount &&
            contract.payload?.customers.map((value) => {
              const displayName = value.socialName || value.name

              return (
                <S.SectionRow key={dashText(displayName)}>
                  <Typography
                    variant="porto-text-body-2-bold"
                    content={formatCustomerName(displayName) + ' - '}
                  />
                  <Typography
                    variant="porto-text-body-2-regular"
                    content={formatDocument(value.document)}
                  />
                </S.SectionRow>
              )
            })}
        </S.Section>

        {!!budget && (
          <S.Section>
            <Typography
              variant="porto-text-caption-bold"
              color="system-text-secondary"
              content="Contrato"
            />

            <S.SectionRow>
              <Typography variant="porto-text-body-2-bold" content="Plano:" />
              <Typography
                variant="porto-text-body-2-regular"
                content={budget && capitalize(budget?.description || '-')}
              />
            </S.SectionRow>

            {installment && (
              <S.SectionRow>
                <Typography
                  variant="porto-text-body-2-bold"
                  content="Parcelas:"
                />
                <Typography variant="porto-text-body-2-regular">
                  {capitalize(installment?.description)}{' '}
                  {formatMoneyBRL(installment?.price)}
                </Typography>
              </S.SectionRow>
            )}

            {totalValue && (
              <S.SectionRow>
                <Typography
                  variant="porto-text-body-2-bold"
                  content="Valor total:"
                />
                <Typography
                  variant="porto-text-body-2-regular"
                  content={formatMoneyBRL(totalValue)}
                />
              </S.SectionRow>
            )}
          </S.Section>
        )}

        <S.Section>
          <Typography
            variant="porto-text-caption-bold"
            color="system-text-secondary"
            content="Imóvel"
          />

          <S.SectionRow>
            <Typography variant="porto-text-body-2-bold" content="CEP:" />
            <Typography
              variant="porto-text-body-2-regular"
              content={formatCEP(contract.payload?.address?.zipcode || '')}
            />
          </S.SectionRow>
        </S.Section>

        {contract.payload?.landlord && (
          <S.Section>
            <Typography
              variant="porto-text-caption-bold"
              color="system-text-secondary"
            >
              Proprietário
            </Typography>

            <S.SectionRow>
              <Typography variant="porto-text-body-2-regular">
                <strong>
                  {formatCustomerName(
                    contract.payload.landlord.socialName ||
                      contract.payload.landlord.name
                  )}
                </strong>{' '}
                - {formatDocument(contract.payload.landlord.document)}
              </Typography>
            </S.SectionRow>
          </S.Section>
        )}

        <S.Section>
          <Typography
            variant="porto-text-caption-bold"
            color="system-text-secondary"
            content="Corretora de Seguros"
          />

          <S.SectionRow>
            <Typography variant="porto-text-body-2-bold">
              {typeof contract.broker == 'object' &&
                `${contract.broker?.id} - `}
            </Typography>
            <Typography variant="porto-text-body-2-regular">
              {capitalize(
                (typeof contract.broker == 'object'
                  ? contract.broker?.name
                  : contract.broker) || '-'
              )}
            </Typography>
          </S.SectionRow>
        </S.Section>
      </S.Content>
    </Card>
  )
}
