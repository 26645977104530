import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { DescriptionWrapper } from './styles'

const Info = () => (
  <DescriptionWrapper>
    <Typography
      variant="porto-text-caption-regular"
      color="system-primitive-neutral-900"
      content="Solicitações que forem realizadas até 12h terão suas cobranças iniciadas no mesmo dia."
    />
    <Typography
      variant="porto-text-caption-regular"
      color="system-primitive-neutral-900"
      content="Os dados enviados não serão utilizados para quaisquer outras comunicações além da cobrança."
    />
  </DescriptionWrapper>
)

export default Info
