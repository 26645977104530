import { useMemo } from 'react'

import dot from 'dot-object'
import { useUnit } from 'effector-react'

import { Partnership } from '../@types/Partnership'

import * as actions from './actions'
import store from './store'

export function useOrganizations() {
  return {
    store: useUnit(store).organizations,
    ...actions.organizations,
  }
}

export function useCurrentOrganization() {
  const {
    organizations: { active: org },
  } = useUnit(store)

  return org
}

export function usePartnershipSettings(
  susep: string,
  partner: string
): Partnership.Settings {
  const profile = useCurrentOrganization()

  const { settings, suseps, prolabore_enabled } = useMemo(() => {
    return (
      profile.partnerships?.find(
        (partnership) =>
          partnership.suseps.includes(susep) &&
          partnership.partner.id === partner
      ) || {}
    )
  }, [partner, profile.partnerships, susep])

  const returnData = useMemo(() => {
    return dot.object(
      Object.assign(
        { config: { prolabore_enabled: prolabore_enabled || false }, suseps },
        settings
      )
    ) as Partnership.Settings
  }, [prolabore_enabled, settings, suseps])

  return returnData
}

export function useCurrentUser() {
  return {
    store: useUnit(store).user,
    ...actions.user,
  }
}

export function useAuth() {
  const { tokens, user } = useUnit(store)

  return {
    tokens,
    isAuthenticated: !!(tokens.access && user.email),
    ...actions.auth,
  }
}
