import cardValidator from 'card-validator'
import * as yup from 'yup'

export default yup.object().shape({
  method: yup.string().required(),
  installment: yup.string().required(),
  card: yup.object().when('method', {
    is: (value: string) => value === 'CC' || value === 'CP',
    then: yup
      .object()
      .shape({
        number: yup
          .string()
          .required('Campo obrigatório.')
          .test(
            'card_number-test',
            'Por favor insira um cartão válido.',
            (value = '') => cardValidator.number(value).isValid
          ),
        expiration_date: yup
          .string()
          .required('Campo obrigatório.')
          .min(7, 'Por favor a data completa.'),
      })
      .optional(),
    otherwise: yup.object().strip(),
  }),
})
