import { rem } from 'polished'
import styled from 'styled-components'
import media from 'styled-media-query'
import { FeatherIcons } from '../Icons'
import { Actions as ActionsComponent } from '@/components'

export const ContainerWrapperStyled = styled.section`
  width: 100%;
  border-bottom-left-radius: 11px;
  border-bottom-right-radius: 11px;

  max-height: 500px;
  overflow: auto;
  background: #ffff;

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #e0e0e0;
    border-radius: 30px;
  }

  ::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 30px;
  }
`

export const Header = styled.header`
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
  background: #ffff;
  display: flex;
  justify-content: space-between;

  padding: 16px 16px 16px 20px;

  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;

  letter-spacing: 0.02em;
  color: #2f2663;
`
export const Icon = styled(FeatherIcons)`
  margin-right: 8px;
  height: ${rem('20px')};

  ${media.greaterThan('huge')`
    height: ${rem('26px')};
  `}

  ${media.lessThan('medium')`
    height: ${rem('26px')};
  `}
`

export const Button = styled(ActionsComponent.Button).attrs(() => ({
  forwardedAs: 'a',
  kind: 'ghost',
}))`
  align-items: center;
  display: flex;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  letter-spacing: 0.03em;
  color: #2c3da8;

  &:first-child {
    margin-right: 24px;
  }
`

export const ContentHeader = styled.div`
  display: flex;
`

export const EmptyWrapperStyled = styled.div<{ containerHeight?: number }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  min-height: ${({ containerHeight }) => containerHeight || 400}px;

  .iconBox {
    margin-bottom: 10px;
  }

  strong {
    display: block;
    color: #000000;
    font-size: 16px;
    line-height: 19px;
  }

  span {
    display: block;
    color: #ffff;
    font-size: 12px;
    line-height: 16px;
  }
`

export const LoadingWrapperStyled = styled(EmptyWrapperStyled)``

export const TableWrapper = styled.table`
  position: relative;
  table-layout: fixed;
  min-width: 950px;

  @media (max-width: 1080px) {
    min-width: 950px;
  }

  @media (max-width: 720px) {
    min-width: 950px;
  }

  color: #000000;
  background: #ffffff;

  &.dark {
    //* em dev//*
    /* color: orange;
    background-color: red; */
  }

  thead {
    &.fixed {
      position: sticky;
      top: 0;
      box-shadow: 0px 4px 4px rgb(0 0 0 / 16%);
      z-index: 1;
    }

    tr {
      th {
        text-align: left;
        z-index: 1;
        background-color: #fff;

        &.dark {
          background-color: black;
        }

        &.fixed {
          position: sticky;
          top: 0;
        }

        padding: 0px 16px 0px 20px;
        font-weight: 500px;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  tbody {
    tr {
      &:nth-child(odd) {
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.16),
          0px -1px 1px rgba(0, 0, 0, 0.16);
      }
    }
  }
`

interface BodyTdProps {
  disabled?: boolean
}

export const BodyTdStyled = styled.td<BodyTdProps>`
  position: relative;
  padding: 30px 16px 30px 20px;
  font-size: 14px;
  line-height: 20px;
  text-overflow: ellipsis;
  overflow-x: hidden;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  white-space: nowrap;
`

export const TableBottomContainerStyled = styled.div`
  padding: 24px 0;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const DisabledOutlineContainerStyled = styled.div`
  position: absolute;
  display: flex;

  align-items: center;

  top: 0;
  height: 100%;
  width: 100%;

  &::after {
    content: '';
    width: 100%;
    height: 1px;
    background-color: blue;
  }
`
