import Excel from 'exceljs'
import { saveAs } from 'file-saver'

import { Colors, dataTable, brokerDataTable } from './dataTable'
import { occupations } from '@/screens/Contracts/Products/Imobiliaria/Budgets/Steps/occupations'

const addDataValidations = ({ worksheet }: { worksheet: Excel.Worksheet }) => {
  const civilStatusColumnLetter = worksheet.getColumn('customer_civil_status').letter
  const customerOccupation = worksheet.getColumn('customer_occupation').letter
  const customerIncomeColumnLetter = worksheet.getColumn('customer_income').letter
  const pepColumnLetter = worksheet.getColumn('customer_pep').letter
  const pepDocumentTypeColumnLetter = worksheet.getColumn('pep_document_type').letter
  const pepDocumentDateColumnLetter = worksheet.getColumn('pep_document_date').letter
  const pepNationalityColumnLetter = worksheet.getColumn('pep_nationality').letter
  const pepBrazilianResidentColumnLetter = worksheet.getColumn('pep_brazilian_resident').letter
  const pepRelationshipTypeColumnLetter = worksheet.getColumn('pep_relationship_type').letter
  const ownerColumnLetter = worksheet.getColumn('estate_owner').letter
  const estateTypeColumnLetter = worksheet.getColumn('estate_type').letter
  const contractServicesClausesColumnLetter = worksheet.getColumn(
    'contract_services_clauses',
  ).letter
  const beneficiaryEnabledColumnLetter = worksheet.getColumn('beneficiary_enabled').letter

  worksheet.dataValidations.add(
    `${beneficiaryEnabledColumnLetter}2:${beneficiaryEnabledColumnLetter}500`,
    {
      type: 'list',
      allowBlank: false,
      formulae: ['"Sim,Não"'],
    },
  )

  worksheet.dataValidations.add(
    `${contractServicesClausesColumnLetter}2:${contractServicesClausesColumnLetter}500`,
    {
      type: 'list',
      allowBlank: false,
      formulae: [
        '"479-PLANO PADRÃO-GRATUITO,480-PLANO TOTAL-REDE REFERENCIADA,485-PLANO INTERM. LIVRE ESCOLHA,547-PLANO TOTAL REDE REFERENCIADA CORRETOR,548-PLANO TOTAL LIVRE ESCOLHA,553-PLANO INTERMEDIÁRIO REDE REFERENCIADA,IMOV. DESOCUPADOS NÃO TEM CLAUSULA DE SERVIÇO"',
      ],
    },
  )

  worksheet.dataValidations.add(`${estateTypeColumnLetter}2:${estateTypeColumnLetter}500`, {
    type: 'list',
    allowBlank: false,
    formulae: [
      '"1 - CONSULTORIOS / ESCOLAS / ESCRITORIOS E HOSPITAIS,2 - COMERCIO E SERVICOS,3 - INDUSTRIAS,4 - IMOVEIS DESOCUPADOS NAO LOCADOS,5 - CASAS,6 - APARTAMENTOS,7 - IMOVEIS DESOCUPADOS - CASAS,8 - IMOVEIS DESOCUPADOS - APARTAMENTOS,9 - CASA DE MADEIRA"',
    ],
  })

  worksheet.dataValidations.add(`${ownerColumnLetter}2:${ownerColumnLetter}500`, {
    type: 'list',
    allowBlank: false,
    formulae: ['"Sim,Não"'],
  })

  worksheet.dataValidations.add(`${civilStatusColumnLetter}2:${civilStatusColumnLetter}500`, {
    type: 'list',
    allowBlank: false,
    formulae: [
      '"1 - Solteiro(a),2 - Casado(a),3 - Viúvo(a),4 - Separado(a) Judicialmente,5 - Divorciado(a)"',
    ],
  })

  worksheet.dataValidations.add(`${customerIncomeColumnLetter}2:${customerIncomeColumnLetter}500`, {
    type: 'list',
    allowBlank: false,
    formulae: [
      '"2 - Até R$ 2.500¸00,3 - De R$2.500¸01 a R$5.000¸00,4 - De R$5.000¸01 a R$10.000¸00,5 - Acima de R$10.000¸01"',
    ],
  })

  worksheet.dataValidations.add(`${customerOccupation}2:${customerOccupation}500`, {
    type: 'list',
    allowBlank: false,
    formulae: ['PROFISSÕES!$A$1:$A$401'],
  })

  worksheet.dataValidations.add(`${pepColumnLetter}2:${pepColumnLetter}500`, {
    type: 'list',
    allowBlank: false,
    formulae: [
      '"1 - Sim,2 - Não e não tem relacionamento próximo com PEP,3 - Não mas possui relacionamento próximo com PEP"',
    ],
  })

  worksheet.dataValidations.add(
    `${pepDocumentTypeColumnLetter}2:${pepDocumentTypeColumnLetter}500`,
    {
      type: 'list',
      allowBlank: false,
      formulae: ['"1 - RG,5 - RNE,40 - Classe,2 - CNH,39 - Passaporte"'],
    },
  )

  worksheet.dataValidations.add(
    `${pepDocumentDateColumnLetter}2:${pepDocumentDateColumnLetter}500`,
    {
      type: 'date',
      allowBlank: false,
      operator: 'greaterThan',
      formulae: [new Date(1900, 0, 1)],
      showErrorMessage: true,
    },
  )

  worksheet.dataValidations.add(`${pepNationalityColumnLetter}2:${pepNationalityColumnLetter}500`, {
    type: 'list',
    allowBlank: false,
    formulae: ['"1 - Brasileira,2 - Estrangeira"'],
  })

  worksheet.dataValidations.add(
    `${pepBrazilianResidentColumnLetter}2:${pepBrazilianResidentColumnLetter}500`,
    {
      type: 'list',
      allowBlank: false,
      formulae: ['"1 - Sim,2 - Não"'],
    },
  )

  worksheet.dataValidations.add(
    `${pepRelationshipTypeColumnLetter}2:${pepRelationshipTypeColumnLetter}500`,
    {
      type: 'list',
      allowBlank: false,
      formulae: [
        '"1 - Pai ou Mãe,2 - Cônjuge,3 - Companheiro(a),4 - Filho(a),5 - Enteado(a),11 - Outros"',
      ],
    },
  )
}

const downloadBatch = async ({ isBroker }) => {
  const workbook = new Excel.Workbook()
  const worksheet = workbook.addWorksheet('ORÇAMENTO')
  const worksheetOccupation = workbook.addWorksheet('PROFISSÕES')
  worksheetOccupation.addRows(
    Object.entries(occupations).map(([key, value]) => [`${key} - ${value}`]),
  )

  const columns = [...dataTable]

  if (isBroker) {
    columns.push(...brokerDataTable)
  }

  worksheet.columns = columns
  worksheet.columns.forEach(column => {
    // Pintando todas as colunas com borda
    column.border = {
      top: { style: 'thin', color: { argb: '00000000' } },
      left: { style: 'thin', color: { argb: '00000000' } },
      bottom: { style: 'thin', color: { argb: '00000000' } },
      right: { style: 'thin', color: { argb: '00000000 ' } },
    }

    // Definindo todas as colunas com cor transparente
    column.fill = {
      type: 'pattern',
      pattern: 'none',
    }

    // Definindo colunas amarelas e cinzas
    switch (columns[column.number - 1].config.color) {
      case 'GRAY':
        column.fill = { ...Colors.GRAY } as Excel.Fill
        break
      case 'YELLOW':
        column.fill = { ...Colors.YELLOW } as Excel.Fill
        break
    }
  })

  // Configurando cabeçalho da tabela (primeira linha)
  const header = worksheet.getRow(1)
  header.height = 56
  header.eachCell(cell => {
    cell.alignment = { vertical: 'middle', horizontal: 'center' }
    cell.fill = { ...Colors[columns[Number(cell.col) - 1].config.color] }
    cell.font = { bold: true }

    const note = columns.find(data => data.header === cell.value)?.note

    if (!!note) cell.note = note
  })

  addDataValidations({ worksheet })

  const buffer = await workbook.xlsx.writeBuffer()
  const blob = new Blob([buffer], { type: 'buffer' })

  saveAs(blob, 'PlanilhaModelo.xlsx')
}

export default downloadBatch
