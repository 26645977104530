import { useEffect, useMemo, useState } from 'react'

import { useField } from 'formik'
import { AnimatePresence } from 'framer-motion'

import { Forms } from '@/components'
import { SvgIcons } from '@/components/SvgIcons'
import { Conditional } from '@/components/Utilities'

import { PropsSearchBar } from './interface'

import {
  CointainerWrapper,
  ContainerAnimate,
  ContainerSearchRecent,
  IconLoader,
  Input,
  LabelRecentSearch,
  LoaderWrap,
  Search,
  SearchIcon,
  SearchInputs,
  TitleReacentSearch,
} from './styles'

function SearchBar(props: PropsSearchBar) {
  const [field, meta, helpers] = useField(props.name)
  const [toggle, setToggle] = useState(false)
  let arrayItemLocalStorage = []

  function parser(value: string) {
    if (value) return JSON.parse(value)
    return []
  }

  function saveValueInputLocalStorage(value: any) {
    arrayItemLocalStorage = parser(localStorage.getItem('recent-search'))
    arrayItemLocalStorage.push(value)
    localStorage.setItem('recent-search', JSON.stringify(arrayItemLocalStorage))
    setToggle(false)
  }

  const handleSelected = (value: string) => {
    helpers.setValue(value)
  }
  const searchRecents = useMemo<[]>(
    () => parser(localStorage.getItem('recent-search')),
    [localStorage.getItem('recent-search')]
  )
  const searchRecentsReverse = searchRecents.reverse().slice(0, 5)
  const resultssearchRecents = searchRecentsReverse.filter((element) => {
    return element !== ''
  })

  function handlePressInput(event) {
    if (event.key === 'Enter') {
      saveValueInputLocalStorage(field.value)
      props.action()
    }
  }

  function handleBlur() {
    setTimeout(() => {
      setToggle(!toggle)
    }, 100)
  }

  useEffect(() => {
    if (props.value !== '') helpers.setValue(props.value)
  }, [props.value])

  return (
    <Search>
      <SearchInputs>
        <Input
          autoComplete="off"
          onFocusCapture={() => setToggle(!toggle)}
          onBlurCapture={handleBlur}
          onKeyPress={(e) => handlePressInput(e)}
          {...props}
          {...field}
          disabled={props.loading || props.disabled}
          error={meta.touched && !!meta.error}
        />
        <SearchIcon
          onClick={() => {
            props.action()
            saveValueInputLocalStorage(field.value)
          }}
        >
          <Conditional when={props.loading}>
            <LoaderWrap>
              <IconLoader />
            </LoaderWrap>
          </Conditional>
          <Conditional when={!props.loading}>
            {SvgIcons.magnifyingGlass}
          </Conditional>
        </SearchIcon>
      </SearchInputs>
      <Conditional when={toggle}>
        <ContainerSearchRecent>
          <LabelRecentSearch>
            Busque por nome, número do código, CPF ou CNPJ.
          </LabelRecentSearch>
          <TitleReacentSearch>Buscas recentes</TitleReacentSearch>
          {resultssearchRecents?.map((e, key) => {
            return (
              <CointainerWrapper key={key}>
                {SvgIcons.magnifyingGlassBlack}
                <p
                  key={key}
                  onClick={() => {
                    handleSelected(e)
                    setToggle(false)
                  }}
                >
                  {e}
                </p>
              </CointainerWrapper>
            )
          })}
        </ContainerSearchRecent>
      </Conditional>
      <ContainerAnimate className="teste">
        <AnimatePresence>
          {meta.touched && meta.error && (
            <Forms.Message kind="danger">{meta.error}</Forms.Message>
          )}
        </AnimatePresence>
      </ContainerAnimate>
    </Search>
  )
}

export default SearchBar
