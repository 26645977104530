export abstract class ApplicationMask {
  /**
   * Responsável por fazer a aplicação da máscara. Realiza a limpeza do valor passado,
   * aplicando as regras do `this.convertToRaw`
   *
   * @param value Valor original do input
   * @returns Retorna o valor com sua máscara aplicada.
   */
  public applyMask(value: string): string {
    const rawValue = this.convertToRaw(value)

    return rawValue
  }

  /**
   * Responsável por limpar o valor da string apenas com os valores
   * aceitos para essa máscara. Limitando o tamanho da string e valores aceitos.
   *
   * @param value Valor original do input
   * @returns Retorna o valor limpo, apenas com os caracteres válidos e
   * aplicando limitação de caracteres quando necessário
   */
  public convertToRaw(value: string): string {
    return value
  }
}
