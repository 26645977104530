import { ApplicationMask } from './Model'

/**
 * A máscara de OnlyNumbers permite que sejam inseridos apenas números inteiros
 */
export class OnlyNumbersMask extends ApplicationMask {
  convertToRaw(value: string): string {
    const raw = value.replace(/\D/g, '')

    return raw
  }
}
