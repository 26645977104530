import styled from 'styled-components'

const Card = styled.div`
  background-color: #f6fdff;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  position: relative;
  min-height: 360px;
  max-width: 80rem;
`

export default Card
