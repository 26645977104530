import styled from 'styled-components'
import media from 'styled-media-query'

export const Plans = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 5px;

  ${media.lessThan('huge')`
    grid-template-columns: repeat(2, 1fr);
  `}

  @media (min-width: 1440px) and (max-width: 1700px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1700px) {
    grid-template-columns: repeat(3, 1fr);
  }
`
