import theme from 'styled-theming'

export const colors = {
  background: theme('mode', {
    light: '#FFF',
    dark: '#1D2027',
  }),

  content: theme('mode', {
    light: '#F6F7FB',
    dark: '#17181D',
  }),

  exit: theme('mode', {
    light: '#0046c0',
    dark: '#0046c0',
  })
}
