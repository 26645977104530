import styled from 'styled-components'
import { themes } from '@/styles'

export const Container = styled.div`
  position: relative;
`

export const Content = styled.div``

export const Section = styled.div`
  margin-left: 16px;
  padding: 32px;

  flex: 1;

  border-radius: 8px;
  background-color: ${themes.page.colors.background};
`
