import { useState } from 'react'
import { Base, Logo, NeedHelp } from '../components'
import Form from './Form'
import FormSent from './FormSent'
import { Divider } from './styles'

const ForgotPassword = () => {
  const [emailSent, setEmailSent] = useState<string>('')

  return (
    <Base>
      <Logo />
      {!emailSent ? (
        <Form onComplete={({ email }) => setEmailSent(email)} />
      ) : (
        <FormSent email={emailSent} />
      )}
      <Divider />
      <NeedHelp />
    </Base>
  )
}

export default ForgotPassword
