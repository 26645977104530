import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { Container } from '@/components/Forms/InputGroup/styles'
import { centeredContainer } from '@/styles/mixins'

type GridAreas = 'name' | 'cpf' | 'email' | 'phone' | 'image-picker' | 'empty'

const FormGridDesktop = css`
  grid-template-areas:
    'image-picker empty'
    'name cpf'
    'email phone';
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 2fr 1fr;
`

const FormGridMobile = css`
  grid-template-areas:
    'image-picker image-picker'
    'name name'
    'cpf cpf'
    'email email'
    'phone phone';
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-gap: 18px;
`

export const ScreenContainer = styled.div`
  ${centeredContainer}

  padding: 80px 32px;
`

export const FormGrid = styled.div`
  display: grid;
  grid-gap: 24px;

  ${media.greaterThan('medium')`${FormGridDesktop}`}
  ${media.lessThan('medium')`${FormGridMobile}`}
`

export const FormArea = styled.div<{ area: GridAreas }>`
  grid-area: ${({ area }) => area};

  ${Container} {
    display: block;
  }
`

export const SubmitButtonContainer = styled.div`
  grid-area: submit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
