import styled from 'styled-components'
import { themes } from '@/styles'

export const Container = styled.div``

export const PeriodsInfo = styled.p`
  font-family: ${themes.fonts.text};
  margin-top: 1.5rem;

  font-size: 14px;
  color: #5f5f5f;
`

export const FileInfo = styled.p`
  font-family: ${themes.fonts.text};
  font-size: 16px;
  font-weight: 500;
  width: 90%;
  margin-bottom: 1rem;
  margin-left: -4px;

  a {
    font-weight: bold;
    color: ${themes.palletes.primary};
  }
`
