import styled from 'styled-components'
import media from 'styled-media-query'
import { size, rem } from 'polished'
import { motion } from 'framer-motion'

import { themes } from '@/styles'

import { FeatherIcons } from '../../../Icons'

export const Container = styled(motion.div)`
  width: 100%;
  padding: 32px;

  border-radius: 30px 30px 0 0;
  background-color: ${themes.page.colors.background};

  ${media.greaterThan('large')`
    width: 450px;
    padding: 48px 32px;

    border-radius: 20px;
  `}

  ${media.greaterThan('huge')`
    width: 600px;
    padding: 56px 48px;
  `}

  ${media.lessThan('large')`
    bottom: 0;
  `}
`

export const Loader = styled(motion.div)`
  text-align: center;
`

export const Head = styled.header`
  position: relative;

  margin-bottom: 24px;

  text-align: center;

  ${media.greaterThan('huge')`
    margin-bottom: 32px;
  `}
`

export const Icon = styled(FeatherIcons)`
  ${size('48px')}

  margin-bottom: 24px;

  stroke-width: 1.2;
  color: ${themes.text.medium};

  ${media.greaterThan('huge')`
    ${size('64px')}
  `}
`

export const Title = styled.h3`
  margin-bottom: 8px;
`

export const Description = styled.p`
  font-size: ${rem('9px')};
  color: ${themes.text.medium};

  ${media.greaterThan('huge')`
    font-size: ${rem('12px')};
  `}
`

export const Close = {
  Action: styled.button`
    all: unset;

    position: absolute;
    top: -10px;
    right: -10px;

    cursor: pointer;
    transition: 0.3s;
    color: ${themes.text.low};

    &:hover {
      transition: 0.3s;
      color: ${themes.text.medium};
    }

    ${media.greaterThan('huge')`
      top: -20px;
      right: -20px;
    `}
  `,

  Icon: styled(FeatherIcons).attrs(() => ({ name: 'x' }))`
    ${size('14px')}

    ${media.greaterThan('huge')`
      ${size('20px')}
    `}
  `,
}

export const Body = styled.div``

export const Footer = styled.footer`
  margin-top: 32px;

  padding: 0 32px;

  text-align: center;

  ${media.greaterThan('huge')`
    margin-top: 40px;
    padding: 0 64px;
  `}

  > button {
    &:not(:last-of-type) {
      margin-bottom: 8px;
    }
  }
`
