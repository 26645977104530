import React from 'react'

import { Conditional, Modals } from '@/components'

import { IBudgetDetailsProps } from '../Budget/interfaces'
import { Content, Coverages } from './styles'
import { formatMoneyBRL } from '@/tools'

const Details: React.FC<IBudgetDetailsProps> = (props) => {
  return (
    <Modals.Content
      title="Mais Informações"
      icon="information-circle-outline"
      colorIcon="primary"
      onSubmit={{ text: 'Fechar', onClick: props.onCancel }}
    >
      <Content>
        <Coverages.Section>
          <Coverages.List>
            <thead>
              <tr>
                <th>Coberturas</th>
                <th>Valor da cobertura</th>
                <th>Prêmio Líquido</th>
              </tr>
            </thead>

            <tbody>
              {props.details.coverages.map((item: any) => (
                <tr>
                  <td>{item.description}</td>
                  <td>{formatMoneyBRL(item.value)}</td>
                  <td>{formatMoneyBRL(item.pricing)}</td>
                </tr>
              ))}
            </tbody>
          </Coverages.List>
        </Coverages.Section>

        <Coverages.Section>
          <Coverages.List>
            <thead>
              <tr>
                <th>Demonstrativo do prêmio</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>Prêmio Líquido Coberturas</td>
                <td>{formatMoneyBRL(props.details.quotation.prize.net)}</td>
              </tr>
              <tr>
                <td>Prêmio Líquido Cláusulas</td>
                <td>{formatMoneyBRL(props.details.quotation.prize.clauses)}</td>
              </tr>
              <tr>
                <td>Prêmio Líquido Total</td>
                <td>{formatMoneyBRL(props.details.quotation.prize.net)}</td>
              </tr>
              <tr>
                <td>Custo de apólice</td>
                <td>R$ 0,00</td>
              </tr>
              <tr>
                <td>IOF</td>
                <td>{formatMoneyBRL(props.details.quotation.prize.iof)}</td>
              </tr>
              <tr>
                <td>Prêmio Total à Vista</td>
                <td>{formatMoneyBRL(props.details.quotation.prize.total)}</td>
              </tr>
            </tbody>
          </Coverages.List>
        </Coverages.Section>

        <Coverages.Section>
          <Coverages.List>
            <thead>
              <tr>
                <th>Cláusula de Serviço</th>
              </tr>
            </thead>
            <tbody>
              <Conditional when={props.details.title === 'Plano Básico'}>
                <tr>
                  <td>Não possui</td>
                </tr>
              </Conditional>
              <Conditional when={props.details.title !== 'Plano Básico'}>
                <tr>
                  <td>Assistência Bike</td>
                </tr>
                <tr>
                  <td>Assistência em Antenas</td>
                </tr>
                <tr>
                  <td>Chaveiro</td>
                </tr>
                <tr>
                  <td>Eletricista</td>
                </tr>
                <tr>
                  <td>Encanador</td>
                </tr>
              </Conditional>
            </tbody>
          </Coverages.List>
        </Coverages.Section>
      </Content>
    </Modals.Content>
  )
}

export default Details
