import { Wizard, Steps, Step } from 'react-albus'
import { Heading } from '../Details/styles'
import { Conditional } from '@/components'
import steps from './Steps/steps'
import * as S from './styles'

const Proposal = () => {
  return (
    <S.Container>
      <S.Content>
        <Wizard
          render={(wizard: any) => (
            <>
              <Conditional when={wizard.step.id !== 'budgets'}>
                <Heading.Wrapper>
                  <Heading.Title>{wizard.step.title}</Heading.Title>
                  <Heading.Description>{wizard.step.description}</Heading.Description>
                </Heading.Wrapper>
              </Conditional>

              <Steps>
                {steps.map(({ render, ...step }) => (
                  <Step {...step} render={wizard => render({ wizard })} />
                ))}
              </Steps>
            </>
          )}
        />
      </S.Content>
    </S.Container>
  )
}

export default Proposal
