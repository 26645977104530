import { ReactNode } from "react"
import {
  FormikConfig,
  FormikProvider,
  FormikValues,
  useFormik,
} from "formik"

import { Box, Flex, Typography } from "@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base"
import { Button, Stepper } from "@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto"

import FocusError from '@/tools/utils/focusError'

import Card from "./Card"

interface OnboardingWrapperProps {
  children: ReactNode
  beforeStepperChildren?: ReactNode
  currentStep: number
  isLoading?: boolean
  wizard: {
    previous: () => void
  }
  hidePreviousButton?: boolean
  hideNextButton?: boolean
  formData?: FormikConfig<FormikValues>
}

const FormikWrapper = ({ formData, children }) => {
  const form = useFormik(formData)
  return (
    <FormikProvider value={form}>
      <form onSubmit={form.handleSubmit}>
        {children({ form })}
        <FocusError />
      </form>
    </FormikProvider>
  )
}

const WrapperSelector = ({ children, formData }) => {
  if (!formData) { return children({}) }
  return (
    <FormikWrapper
      formData={formData}
      children={children}
    />
  )
}

const OnboardingWrapper = ({
  children,
  beforeStepperChildren,
  currentStep,
  isLoading,
  wizard,
  hidePreviousButton,
  hideNextButton,
  formData,
}: OnboardingWrapperProps) => {
  return (
    <WrapperSelector
      formData={formData}
      children={({ form }) => (
        <>
          {beforeStepperChildren && <Box
            sx={{
              marginBottom: "1.5rem",
            }}
          >
            {beforeStepperChildren}
          </Box>}
          <Card
            sx={{
              paddingY: "1rem",
            }}
          >
            <Stepper
              currentStep={currentStep}
              showStepsNumber={true}
              steps={[
                { label: 'Dados pessoais' },
                { label: 'Imobiliária' },
                { label: 'Corretora de seguros' },
                { label: 'Dados bancários' },
                { label: 'Assinatura do contrato' },
              ]}
            />
          </Card>
          <Box
            sx={{
              marginTop: "1.5rem",
            }}
          >
            {typeof children === 'function' ? children({ form }) : children}
          </Box>
          <Flex
            sx={{
              marginTop: "1.5rem",
              gap: "1rem",
            }}
          >
            {!hidePreviousButton && <Button
              size="large"
              onClick={() => wizard.previous()}
            >
              Voltar
            </Button>}
            {!hideNextButton && <Button
              size="large"
              type="submit"
            >
              Continuar
            </Button>}
          </Flex>
        </>
      )}
    />
  )
}

export default OnboardingWrapper
