export const occupations = {
  135: 'AÇOUGUEIRO',
  136: 'ACUPUNTURISTA',
  137: 'ADESTRADOR DE ANIMAIS',
  139: 'ADMINISTRADOR DE EMPRESAS',
  140: 'ADMINISTRADOR HOSPITALAR',
  141: 'ADMINISTRADOR OU FUNCIONÁRIO DE COOPERATIVAS',
  142: 'ADVOGADO',
  143: 'AEROMOÇA, COMISSÁRIO DE BORDO, TRIPULANTES DE AERONAVES',
  144: 'AGENCIADOR DE PROPAGANDA, CONTATO PUBLICITÁRIO',
  145: 'AGENCIADOR, VENDEDOR, PRODUTOR SEGUROS E ASSEMELHADOS',
  146: 'AGENTE ADMINISTRATIVO',
  147: 'AGENTE DE SERVICOS FUNERARIOS E EMBALSAMADOR',
  148: 'AGENTE DE VIAGEM E GUIA DE TURISMO',
  149: 'AGENTE TRIBUTOS, ARRECADADOR',
  150: 'AGENTE DE VETOR/SANITAR, GUARDA ENDEMIAS, FISCAL DE HIGIENE',
  151: 'AGENTE, VISTORIADOR DE CARGAS OU MERCADORIAS',
  152: 'AGRICOLAS, PECUARIA, FLORESTAIS, PESCA',
  153: 'AGRIMENSOR',
  155: 'AJUDANTE DE MOTORISTA, AJUDANTE DE CAMINHÃO',
  156: 'AJUDANTE GERAL , AJUD/AUX. DE SERVIÇOS GERAIS',
  157: 'ALFAIATE, COSTUREIRA, ESTILISTA, CONFECCIONISTA',
  158: 'ALMOXARIFE, ESTOQUISTAS E ATIVIDADES RELACIONADAS',
  159: 'ANALISTA DE PRODUÇÃO, ANALISTA DE MATERIAIS',
  160: 'ANALISTA DE SISTEMAS',
  161: 'ANALISTA DE TREINAMENTO',
  162: 'ANALISTA SUPORTE TÉCNICO INFORMÁTICA, TÉCNICO REDE',
  163: 'ANALISTA/ ASSESSOR/ TÉCNICO DO JUDICIÁRIO',
  164: 'ANALISTA/ ASSISTENTE FINANCEIRO, DE INVESTIMENTO, CRÉDITO',
  165: 'ANALISTA/ ASSISTENTE/ ASSESSOR/ TÉCNICO ADMINISTRATIVO',
  168: 'APOSENTADO (POR TEMPO DE SERVIÇO)',
  169: 'ARBITRO ESPORTIVO',
  170: 'ARQUEÓLOGO',
  171: 'ARQUITETO',
  172: 'ARQUIVISTA',
  173: 'ARTESÃO',
  174: 'ASCENSORISTA',
  175: 'ASSESSOR/ASSISTENTE PARLAMENTAR OU DE GABINETE',
  176: 'ASSISTENTE SOCIAL',
  177: 'ASSISTENTE/ SECRETÁRIO ESCOLAR',
  178: 'ASTROLOGO',
  179: 'ASTRONOMO E METEOROLOGISTA',
  180: 'ATENDENTE DE GUICHÊ, BILHETEIRO, EMISSOR DE PASSAGEM',
  181: 'ATENDENTE EM HOSPITAIS, CONSULTORIOS, LABORATORIOS',
  182: 'ATENDENTE/AUXILIAR ESCOLAR, DE CRECHE/BERÇARIO',
  183: 'ATIV. REMUNERADA PELA PRATICA ESPORTE AMADOR',
  184: 'ATIV. REMUNERADA PELA PRATICA ESPORTE PROFISSIO',
  185: 'ATLETA PROFISSIONAL E TECNICO EM DESPOR',
  186: 'ATOR E DIRETOR DE ESPETACULOS PUBLICOS',
  188: 'ATUARIO, MATEMÁTICO, ESTATÍSTICO',
  189: 'AUDITOR (EMPRESAS)',
  190: 'AUDITOR/FISCAL FEDERAL, ESTADUAL, MUNICIPAL',
  192: 'AUXILIAR DE ESCRITORIO',
  193: 'AUXILIAR/TÉCNICO NA AREA SAÚDE (EXCT ENFERMAGEM)',
  195: 'BANCARIO',
  196: 'BIBLIOTECARIO, MUSEÓLOGO E ATVS CORRESPONDENTES',
  197: 'BIOLOGO, GENETICISTA, FISIOLOGISTA, BIOLOGISTA',
  198: 'BIOMÉDICO',
  199: 'BIOQUIMICO',
  201: 'BOMBEIRO',
  202: 'BORRACHEIRO, BICICLETEIRO',
  203: 'CABELEREIRO, BARBEIRO, MANICURE, PEDICURE, ASSEMELHADOS',
  204: 'CANTOR, COMPOSITOR',
  205: 'CAPITALISTA, RECEBENDO RENDIMENTO DE AP',
  206: 'CARCEREIRO, AGENTE PENITENCIÁRIO',
  207: 'CARREGADORES E EMBALADORES EM GERAL',
  208: 'CARTEIRO',
  209: 'CARTÓGRAFO',
  210: 'CERAMISTA, TELHADISTA (FABRICADOR DE TELHAS), OLEIRO',
  211: 'CHAVEIRO',
  212: 'CINEASTA',
  213: 'COBRADOR DE ONIBUS E DEMAIS TRANSPORTES COLETIVOS',
  214: 'COLOCADOR DE GESSO, MARMORISTA E ATVS RELACIONADAS',
  215: 'COLOCADOR DE LUMINOSOS, OUTDOORS, LETREIROS',
  216: 'COMANDANTE DE EMBARCACOES',
  217: 'COMANDANTE/ PILOTO DE AERONAVES, INSTRUTOR DE VÔO',
  218: 'COMERCIÁRIO-BALCONISTA,CAIXA,VENDED ESTB COMERC',
  220: 'COMPRADOR - ANIMAIS/PROD AGRÍCOLAS',
  221: 'COMPRADOR - INDÚSTRIA, SERVIÇOS E COMÉRCIO',
  222: 'COMUNICOLOGO',
  223: 'CONFERENTE (PORTUARIO)',
  224: 'CONSTRUTOR',
  225: 'CONSULTOR',
  226: 'CONSULTOR ADMINISTRATIVO, CONTÁBIL, FINANCEIRO',
  227: 'CONSULTOR DE INFORMÁTICA/SISTEMAS',
  228: 'CONSULTOR JURÍDICO',
  229: 'CONSULTOR(A), VENDEDOR(A) DE COSMÉTICOS',
  231: 'CONTADOR, ANALISTA CONTÁBIL (EXCT TÉCNICOS)',
  232: 'CONTRAMESTRE DE EMBARCACOES',
  233: 'COORDENADOR DE VÔO, TECNICO TRAFEGO EM AEROPORTOS',
  234: 'COORDENADOR/ CHEFE/ SUPERVISOR ADMINISTRATIVO',
  235: 'COORDENADOR/ SUPERVISOR EM EMPRESAS DE TRANSPORTES',
  236: 'COREOGRAFO, BAILARINO, DANÇARINO',
  237: 'CORRETOR BOLSA DE MERCADORIAS',
  238: 'CORRETOR DE IMOVEIS, TITULOS E VALORES',
  239: 'CORRETOR DE SEGUROS',
  240: 'COVEIRO',
  241: 'COZINHEIRA, MERENDEIRA, DOCEIRA, CONFEITEIRA',
  242: 'DATILOSCOPISTA, PAPILOSCOPISTA POLICIAIS',
  243: 'DECORADOR, PAISAGISTA, VITRINISTA',
  244: 'DEGUSTADOR, ENOLOGO, SOMMELIER',
  245: 'DELEGADO DE POLICIA',
  246: 'DEMONSTRADOR, DIVULGADOR, PROMOTOR DE VENDAS',
  247: 'DENTISTA',
  248: 'DESENHISTA ARTÍSTICO, DESENHISTA PUBLICITÁRIO',
  249: 'DESENHISTA COMERCIAL',
  250: 'DESENHISTA TECNICO',
  251: 'DESENHISTA, PROJETISTA, DESIGNER IND OU GRÁFICO',
  252: 'DESPACHANTE (INCLUSIVE O ADUANEIRO)',
  253: 'DETETIVE, INVESTIGADOR PARTICULAR',
  254: 'DIGITADOR',
  255: 'DIRETOR DE EMPRESAS',
  256: 'DIRETOR DO ESTABELECIMENTO DE ENSINO',
  257: 'DIRETOR, CONTROLLER, SUPERINTENDENTE ADMINISTRA',
  258: 'DIRIGENTE PARTIDÁRIO, SINDICALISTAS EM GERAL',
  259: 'DJ, SONOPLASTA, OPERADOR DE SOM',
  260: 'DO LAR',
  261: 'ECONOMISTA',
  262: 'EDITOR DE LIVROS',
  263: 'ELETRICISTA DE MANUTENçãO DE REDES PúBL',
  264: 'ELETRICISTA DE MANUTENCAO DE VEICULOS A',
  265: 'ELETRICISTAS E ASSEMELHADOS',
  266: 'ELETRICISTAS EM GERAL',
  267: 'ELETRICITÁRIO (TRAB.EMPRESA ENERGIA ELÉTRICA)',
  268: 'EMBAIXADOR, DIPLOMATA, CONSUL, ASS CHANCELARIA',
  269: 'EMPREG DOMESTICO(DIARISTA,BABA,CASEIRO,GOVERNA)',
  271: 'EMPRESARIO E PRODUTOR DE ESPETACULOS PUBLICOS',
  272: 'EMPRESÁRIO ESPORTIVO',
  274: 'EMPRESÁRIO/PROPRIET ASSESSORIA EMPRESARIAL',
  275: 'EMPRESÁRIO/PROPRIET COM AUTOPEÇAS,SERV AUTOMOTIVOS',
  276: 'EMPRESARIO/PROPRIET BANCA JORNAIS,REVISTAS',
  277: 'EMPRESÁRIO/PROPRIET CLIN.MÉDICA, LABORATÓRIO,HOSPITAIS',
  278: 'EMPRESÁRIO/PROPRIET DE GRÁFICA',
  279: 'EMPRESARIO/PROPRIET EDITORA, LIVRARIA',
  280: 'EMPRESARIO/PROPRIET EMPR IMPORTAÇÃO, EXPORTAÇÃO',
  281: 'EMPRESÁRIO/PROPRIET EMPRESAS COMUNICAÇÃO,RÁDIO,TV',
  282: 'EMPRESÁRIO/PROPRIET EMPRESA DE INFORMÁTICA/TELECOM',
  283: 'EMPRESÁRIO/PROPRIET EMPRESA DE TURISMO',
  284: 'EMPRESÁRIO/PROPRIET EMPRESA MINERAÇÃO,PEDREIRA',
  285: 'EMPRESARIO/PROPRIET ESTAB AGRICOL, DA PECUAR E SIM',
  286: 'EMPRESARIO/PROPRIET ESTAB COMERCIAL, LOJISTA',
  287: 'EMPRESARIO/PROPRIET ESTAB ESCOLAR, EDUCACIONAL',
  288: 'EMPRESARIO/PROPRIET ESTAB INDUSTRIAL',
  289: 'EMPRESÁRIO/PROPRIET ESTACIONAMENTOS',
  290: 'EMPRESARIO/PROPRIET HOTEL, MOTEL, POUSADA, RESORT',
  291: 'EMPRESÁRIO/PROPRIET IMOBILIÁRIA, ADM CONDOMÍNIOS',
  292: 'EMPRESARIO/PROPRIET DE LOJAS VEÍCULOS, CONCESSIONÁR',
  293: 'EMPRESARIO/PROPRIET PADARIA, BAR, RESTAURANTE',
  294: 'EMPRESÁRIO/PROPRIET PESQUEIRO,SERV.MARÍTIMOS',
  295: 'EMPRESARIO/PROPRIET POSTO DE GASOLINA',
  296: 'EMPRESARIO/PROPRIET TRANSPORTADORA/ DISTRIBUIDORA',
  297: 'EMPRESÁRIO/PROPRIET ACADEMIA, GINÁSTICA,ESPOR',
  298: 'EMPRESARIO/TECNICO EMPRES DE SEGURANCA,',
  299: 'ENCANADOR OU INSTALADOR DE TUBULAÇÕES',
  300: 'ENFERMEIRO',
  301: 'ENGENHEIRO',
  302: 'ENGENHEIRO AGRONOMO',
  303: 'ENGENHEIRO DE MINAS',
  304: 'ENGENHEIRO FLORESTAL',
  305: 'ENGENHEIRO NUCLEAR',
  306: 'ENTREGADOR, MOTOBOY E ASSEMELHADOS',
  307: 'ESCRITOR, AUTOR DE LIVROS',
  308: 'ESCRIVÃO POLICIAL/JUDICIAL',
  309: 'ESCULTOR, PINTOR ARTIST, ARTISTA PLÁSTICO, ARTESÃO',
  310: 'ESPOLIO',
  311: 'BOLSISTA, ESTAGIARIO, TRAINEE',
  312: 'ESTENOGRAFO, DATILOGRAFO, TAQUÍGRAFO E ASSEMELHADOS',
  313: 'ESTETICISTA / MASSAGISTA / MASSOTERAPEUTA E ASSEMEL',
  314: 'ESTUDANTE',
  315: 'ESTUDANTE EM INTERCAMBIO',
  316: 'FARMACEUTICO E ATVS AUXILIARES RELACIONADAS',
  317: 'FAXINEIRA, ARRUMADEIRA, CAMAREIRA, COPEIRA',
  318: 'FEIRANTE',
  319: 'FERRAMENTEIRO',
  320: 'FILOSOFO, SOCIOLOGO, HISTORIADOR E ASSEMELHADOS',
  321: 'FISCAL',
  322: 'FISCAL/ SUPERVISOR/ MESTRE EM OBRAS CONSTR. CIVIL',
  323: 'FISICO',
  324: 'FISICO NUCLEAR',
  325: 'FISIOTERAPEUTA E TERAPEUTA OCUPACIONAL',
  326: 'FONOAUDIOLOGO',
  327: 'FOTOGRAFO',
  328: 'FRENTISTA E DEMAIS TRABALHADORES DE POSTOS DE GASOLINA',
  329: 'FUNCIONARIO PUBLICO CIVIL APOSENTADO',
  330: 'FUNCIONÁRIO/SERVIDOR PÚBLICO (FUNÇÕES BUROCRÁTICAS)',
  331: 'FUNILEIRO',
  332: 'GARAGISTA, MANOBRISTA',
  333: 'GARÇON, MAITRE, BAR MAN E ASSEMELHADOS',
  334: 'GARI, TRABALHADOR CONSERVAÇÃO ÁREAS PÚBLICAS',
  335: 'GARIMPEIRO',
  336: 'GEOGRAFO',
  337: 'GEOLOGO',
  338: 'GERENTE ADMINISTRATIVO',
  339: 'GERENTE DE VENDAS, GERENTE COMERCIAL',
  340: 'GERENTE/ADMIN ESTAB COMERCIAL(EXCT BARES E SIM)',
  341: 'GERENTE/ADMINISTRAD ESTAB AGRICOL, PECUAR E SIM',
  342: 'GERENTE/ADMINISTRAD HOTEL,MOTEL,POUSADA,RESORT',
  343: 'GERENTE/ADMINISTRAD PADARIAS,BARES,RESTAURANTES',
  344: 'GOVERNANTA DE HOTEL, CAMAREIRO, PORTEIR',
  345: 'GUARDA CIVIL E MUNICIPAL',
  346: 'GUARDA NOTURNO',
  348: 'INSPETOR DE DUTOS',
  349: 'INSPETOR/ VISITADOR NAVAL',
  350: 'INSTALADOR DE GÁS, AGUA E ESGOTO E ASSEMELHADOS',
  351: 'INSTALADOR DE PISOS, AZULEGISTA, PASTILHEIRO',
  352: 'INSTALADOR DE TV A CABO, CABISTA',
  353: 'INSTALADOR/MANTENEDOR ANTENAS, TELHADOS E ASSEM',
  354: 'INSTALADOR/ MANTENEDOR ELEVADORES',
  355: 'INSTALADOR//TÉCNICO EM EQUIPAMENTOS ELETRÔNICOS',
  356: 'INSTRUMENTADOR CIRURGICO',
  357: 'INSTRUTOR DE AUTO ESCOLA',
  358: 'INSTRUTOR, MONITOR CURSOS TÉCNICOS, DE INFORMÁTICA',
  359: 'JARDINEIRO, AUXILIAR DE JARDINEIRO',
  360: 'JOALHEIRO, OURIVES, DESIGNER DE JÓIAS',
  362: 'JORNALEIRO',
  363: 'JORNALISTA, REPORTER, ASSESSOR DE IMPRENSA',
  364: 'JUIZ DE DIREITO',
  365: 'LANTERNEIRO E PINTOR DE VEICULOS',
  366: 'LAVADOR DE AUTOS',
  368: 'LAVRADOR, TRABALHADOR RURAL ( AGRICULTURA E PECUARIA)',
  369: 'LEILOEIRO, AVALIADOR, INSPETOR DE AVARIAS',
  370: 'LEITURISTA DE AGUA, LUZ E GÁS',
  371: 'LOCUTOR, RADIALISTA, COMENTARISTA DE RADIO E TV',
  372: 'MAGISTRADO',
  374: 'MAQUINISTA E FOGUISTA DE EMBARCACOES, L',
  375: 'MAQUINISTA FERROVIÁRIO, FOGUISTA',
  376: 'MARCENEIRO, SERRALHEIRO, CARPINTEIRO E ASSEMELHADOS',
  377: 'MARCHAND, ANTIQUÁRIO, NEGOCIADOR DE ARTE',
  378: 'MARINHEIRO E ASSEMELHADO',
  381: 'MECÂNICO MANUTENÇÃO VEÍC AUTOMOTORES, AERONAVES',
  382: 'MECANICO MANUTENCAO,MONTADOR,PREPAR,OPERAD MAQUINAS',
  383: 'MEDICO',
  384: 'MERGULHADOR',
  385: 'MESTRE E CONTRAMESTRE',
  386: 'MILITAR EM GERAL',
  387: 'MILITAR REFORMADO POR INVALIDEZ',
  388: 'MILITAR REFORMADO POR TEMPO DE SERVIÇO',
  389: 'MINISTRO DO STF, DESEMBARGADOR',
  390: 'MINISTRO E SECRETÁRIO MUNICIPAL, ESTADUAL E FEDERAL',
  391: 'MODELO (MODA, FOTOGRÁFICO)',
  393: 'MONITOR, INSTRUTOR DE MENORES (FEBEM E SIMILARES)',
  394: 'MOTOQUEIRO',
  395: 'MOTORISTA BOIADEIRO',
  396: 'MOTORISTA DE GUINCHO, REBOQUE',
  397: 'MOTORISTA-TRANSP DE CARGA',
  398: 'MOTORISTA-TRANSP DE PASSAGEIROS (EXCT TAXISTAS)',
  399: 'MUSICO, MAESTRO, PROF MÚSICA, REGENTE CORAIS',
  403: 'NUTRICIONISTA E ASSISTENTE DE NUTRIÇÃO',
  404: 'OCEANÓGRAFO',
  407: 'ODONTOLOGO',
  408: 'OFFICE-BOY / CONTÍNUO',
  409: 'OFICIAIS DO EXÉRCITO, MARINHA E AERONÁUTICA',
  410: 'OFICIAL DE JUSTICA',
  411: 'OPERADOR DE COMPUTADOR/CPD, SCHEDULLER',
  413: 'OPERADOR DE CAMERAS CINEMA E TV, CINEGRAFISTA',
  414: 'OPERADOR DE IMAGEM E DE VÍDEO',
  415: 'OPERADOR DE TELEMARKETING',
  417: 'OPERADOR DE MÁQUINAS NA CONSTRUÇÃO CIVIL',
  418: 'OPERADOR MERCADO FINANCEIRO, FACTORING, CAMBIO',
  419: 'OPERADOR OFF SETT',
  420: 'OPERADOR DE PREGAO DA BOLSA DE VALORES',
  421: 'OPERADOR, INSPETOR DE COBRANÇA',
  422: 'OPERADORES DE MAQUINAS INDUSTRIAIS',
  423: 'OTICO, CONTACTOLOGO',
  435: 'OUTROS - MEDICOS, ODONTOLOGOS, ENFERMEI',
  446: 'OUTROS - TECNICOS',
  449: 'PADEIRO, PANIFICADOR',
  450: 'PARAMEDICO',
  451: 'PARLAMENTARES: SENADORES, DEPUTADOS, VEREADORES',
  452: 'PAVIMENTADOR, CALCETEIRO',
  453: 'PEAO DE RODEIO',
  454: 'PEDAGOGO, ORIENTADOR/COORDENADOR PEDAGOGICO',
  455: 'PEDREIRO E DEMAIS TRABS BRAÇAIS NA CONSTR CIVIL',
  456: 'PENSIONISTA',
  457: 'PERITO CRIMINAL/DO JUDICIARIO E AUXS CORRESPOND',
  458: 'PERITO, VISTORIADOR EM SEGUROS',
  459: 'PILOTO AUTOMOBILÍSTICO',
  460: 'PINTOR ARTISTICO',
  461: 'PINTOR (COM EQUIPAMENTOS DE SEGURANCA ADEQUADOS)',
  462: 'PINTOR (SEM EQUIPAMENTOS DE SEGURANCA ADEQUADOS)',
  463: 'POLICIAL CIVIL, MILITAR E INVESTIGADOR POLICIAL',
  464: 'POLICIAL,AGENTE FLORESTAL, AMBIENTAL',
  466: 'PORTEIRO',
  468: 'PRESIDENTE DA REPUBLICA, GOVERNADORES, PREFEITOS',
  469: 'PRESIDENTE/VICE PRESIDENTE ADMINISTRATIVO',
  471: 'PRO. E EMP',
  473: 'PROCURADOR PÚBLICO',
  474: 'PROF. E COMERC.',
  477: 'PROF/COMERC.',
  478: 'PROFESSOR',
  479: 'PROFESSOR DE ENSINO SUPERIOR',
  480: 'PROFESSOR EDUC FISICA/ESPORTES,PERSONAL TRAINER',
  481: 'PROFESSOR ENSINO FUNDAM, MÉDIO, SUPERIOR, LÍNGUAS',
  484: 'PROGRAMADOR VISUAL',
  485: 'PROGRAMADOR, TÉCNICO, TECNÓLOGO EM INFORMÁTICA',
  486: 'PROMOTOR DE EVENTOS, PROMOTER, HOSTESS',
  487: 'PROMOTOR DE JUSTIÇA, DEFENSOR PÚBLICO',
  488: 'PROPRIETARIO DE IMOVEL, RECEBENDO RENDI',
  490: 'PROTéTICO',
  491: 'PROTETICO, TÉCNICOS E AUXILIARES EM PROTESE',
  492: 'PSICOLOGO , PSICOTERAPEUTA, PSICANALISTA',
  493: 'PUBLICITARIO, PROPAGANDISTA',
  494: 'QUIMICO',
  495: 'RADIOTERAPEUTA',
  496: 'RECENSEADOR, PESQUISADOR',
  497: 'RECEPCIONISTA',
  498: 'REITOR, DIRETOR, ADMINISTRADOR ESCOLAR',
  499: 'RELACOES PUBLICAS',
  500: 'RODOVIáRIO',
  501: 'SACERDOTE, PASTOR, MEMBRO ORDENS, SEITAS RELIGIO',
  502: 'SALVA VIDAS',
  503: 'SAPATEIRO',
  504: 'SECRETÁRIA (O), TÉCNICA(O) EM SECRETARIADO',
  505: 'SECURITARIO',
  506: 'SERVENTUARIO DE JUSTICA',
  507: 'SERVIDOR PúBLICO MUNIICPAL - AGENTE DE',
  508: 'SINDICANTE/INVESTIGADOR EM SEGUROS',
  510: 'SOCIOLOGO',
  511: 'SOLDADOR,CHAPEADOR,CALDEREIRO,MONTADOR ESTR MET',
  512: 'SUPERVISOR DE PRODUÇÃO E MANUTENÇÃO INDUSTRIAL',
  513: 'SUPERVISOR/ INSPETOR DE ALUNOS',
  514: 'SUPERVISORES, INSPETOR E AGENTE DE COMP',
  515: 'TABELIAO, OFICIAIS DE REGISTRO/CARTORIO',
  516: 'TAPECEIRO, TECELÃO (MÓVEIS E DECORAÇÃO)',
  517: 'TAXISTA',
  518: 'TEC. TURISMO',
  521: 'TÉCNICO AGRÍCOLA, EM AGRONOMIA/AGRIMENSURA',
  522: 'TECNICO DE BIOLOGIA',
  523: 'TECNICO DE INSTRUMENTACAO INDUSTRIAL',
  524: 'TéCNICO EDIF.',
  525: 'TÉCNICO EM ELETRODOMÉSTICOS, REFRIGERAÇÃO E MAQS COSTUR',
  526: 'TECNICO ELETRONICO',
  527: 'TECNICO EM AGRONOMIA E AGRIMENSURA',
  528: 'TECNICO EM CONTABILIDADE OU ESTATÍSTICA',
  529: 'TECNICO EM ELETRONICA, ELETROTÉCNICO',
  530: 'TÉCNICO EM SEGURANÇA DO TRABALHO',
  531: 'TÉCNICO EQUIPAMENTOS HOSPITALARES, ODONTOLOGICOS',
  532: 'TÉCNICO EM MAQUINAS COPIADORAS (XEROX E SIMILARES)',
  533: 'TÉCNICO MINERAÇÃO (PETRÓLEO E GÁS NATURAL)',
  534: 'TECNICO, AUXILIAR LABORATORIO/PATOLOGIA CLINICA',
  535: 'TÉCNICO, TECNOLOGO EM MECANICA/ENGENHARIA',
  536: 'TÉCNICO, TECNOLOGO EM QUIMICA',
  537: 'TÉCNICO/AGENTE EM TELECOMUNICAÇÕES TELEFONIA',
  538: 'TÉCNICO/AUX MINERAÇÃO, MINERADOR (CARVÃO E METAIS)',
  539: 'TÉCNICO/AUXILIAR EM ENFERMAGEM',
  540: 'TÉCNICO/AUXILIAR EM RADIOLOGIA, RAIO X',
  541: 'TÉCNICO/ INSTALADOR DE AR CONDICIONADO',
  542: 'TÉCNICO E/OU INSTALADOR DE SOM',
  543: 'TÉCNICO/OPERARIO OBRAS ESTRADAS OU RODOVIAS',
  544: 'TÉCNICO/TECNOLOGO EM EDIFICAÇÕES',
  545: 'TECNOLOGO',
  546: 'TELEFONISTA, TELEGRAFISTA E ATVS RELACIONADAS',
  547: 'TESOUREIRO',
  548: 'TINTUREIRO, LAVADEIRA E, AUXS CORRESPONDENTES',
  550: 'TOPÓGRAFO E AUXILIAR DE TOPOGRAFIA',
  551: 'TORNEIRO MECÂNICO',
  552: 'TOSADOR DE ANIMAIS',
  553: 'TRABALHADOR EM SERVS RODOVIÁRIOS - CONTROLE DE TRÁFEGO',
  558: 'TRABALHADOR DE FABRICACAO DE CALCADOS E',
  559: 'TRABALHADOR DE FABRICACAO DE ROUPAS',
  561: 'TRABALHADOR DE USINAGEM DE METAIS',
  562: 'TRABALHADOR EM PLATAFORMAS PETROLÍFERAS',
  563: 'TRABALHADOR EM SERVIÇOS POSTAIS (EXCT CARTEIRO)',
  564: 'TRABALHADOR FABR PRODTS BORRACHA E PLASTICO',
  565: 'TRABALHADOR FABR PRODTS TEXTEIS,CALCADOS,CONFEC',
  566: 'TRABALHADOR FABRIC VEICS AUTOMOTORES, AERONAVES',
  567: 'TRABALHADOR NA FABRICAÇÃO DE ALIMENTOS E BEBIDAS',
  568: 'TRABALHADOR IND TRATAMENTO DE FUMO E FAB DE CIGARR',
  569: 'TRABALHADOR DA INDÚSTRIA GRAFICA',
  570: 'TRABALHADOR METALURGICO OU SIDERURGICO',
  571: 'TRABALHADOR NA FABRICACAO DE PAPEL E PAPELAO',
  572: 'TRABALHADOR PORTUÁRIO, ESTIVADOR - EXCT CONFERENTE',
  573: 'TRABALHADOR EM SERVS RODOVIÁRIOS - PEDÁGIOS',
  574: 'TRABALHADOR/TÉCNICO EM SANEAMENTO',
  576: 'TRABALHO RURAL',
  577: 'TRADUTOR E INTÉRPRETE',
  578: 'TRATORISTA, OPERADORES DE MÁQUINAS AGRÍCOLAS',
  579: 'TRIPULANTES DE EMBARCAÇOES',
  580: 'VAQUEIRO',
  582: 'VENDEDOR',
  583: 'VENDEDOR AMBULANTE, CAMELÔ E ASSEMELHADOS',
  584: 'VENDEDOR DE COMERCIO VAREJISTA E ATACAD',
  585: 'VENDEDOR, REPRESENTANTE COMERCIAL',
  586: 'VETERINARIO E ZOOTECNISTA',
  587: 'VIDRACEIRO',
  588: 'VIGILANTE, VIGIA, GUARDA NOTURNO, SEGURANÇA',
  589: 'WEB DESIGNER, ANALISTA/PROGRAM COMPUTAC GRAFICA',
  590: 'ZELADOR',
  591: 'EMPRESARIO DA CONSTRUCAO CIVIL, EMPREITEIRO',
  592: 'ENGENHEIRO-EXCT.MINAS,NUCLEAR,FLORES,CI',
  593: 'ESTILISTA E MODELISTA',
  594: 'MONITOR DE ALARMES',
  595: 'CIENT.POLITICO,FILOSO,SOCIOL,HISTORIADO',
  596: 'PSIQUIATRA',
  597: 'CHEFE DE COZINHA',
  598: 'POLICIAL FEDERAL E RODOVIARIO',
  599: 'NAO DESEJO INFORMAR',
  600: 'NAO IDENTIFICADO - BPN',
  602: 'AUTO ELÉTRICO',
  603: 'ESTACIONAMENTO PÚBLICO',
  604: 'PODÓLOGO',
  605: 'PROFESSOR DE INFORMÁTICA',
}
