import media from 'styled-media-query'
import styled from 'styled-components'
import { rem } from 'polished'

import { FeatherIcons } from '@/components/Icons'
import { Actions } from '@/components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const ContainerList = styled.div`
  background: white;
  border-radius: 8px;
`

export const ContentHeader = styled.div`
  display: flex;
  gap: 16px;
  padding: 16px 16px;
`
export const ContainerHeader = styled.div`
  position: relative;

  .custom-arrow::before,
  .custom-arrow::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;

    left: 50%;
    top: 50%;

    transform: translate(-50%, -50%);
  }
  .custom-arrow {
    transform: rotate(180deg);
  }

  .rotate {
    transform: rotate(0deg);
  }
`
export const ContainerIcon = styled(FeatherIcons)`
  position: absolute;
  top: 0;
  right: 7px;
  display: block;
  height: 100%;
  width: 1.2rem;
  pointer-events: none;
  color: #5f5f5f;
`
export const Search = styled.select`
  display: flex;
  appearance: none;
  border-radius: 4px;
  height: 100%;
  width: 304px;
  padding: 1em 4em 1em 1.5em;
  border: 1px solid #b3b3b3;
  cursor: pointer;
  font-family: 'Porto Roobert';
  font-style: normal;
  font-weight: 500;

  &:focus {
    outline: none;
    border: 1px solid #2c3da8;
  }
`

export const Button = styled(Actions.Button)<{ kind?: any }>``

export const Icon = styled(FeatherIcons)`
  margin-right: 8px;
  height: ${rem('20px')};

  ${media.greaterThan('huge')`
    height: ${rem('26px')};
  `}

  ${media.lessThan('medium')`
    height: ${rem('26px')};
  `}
`
