import styled from 'styled-components'
import { Form } from 'formik'
import Button from '@/components/Actions/Button'

export const Header = styled.div`
  align-items: center;
  display: flex;
`

export const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  margin-right: 16px;
  outline: none;
  padding: 6px;
`

export const Content = styled.div`
  padding: 16px;
`

export const SheetDownload = styled.button`
  background: var(--system-primitive-off-white);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  gap: 12px;
  padding: 8px;
  max-width: fit-content;
`

export const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;
  max-width: 380px;
`

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`

export const Action = styled(Button)`
  margin-top: 16px;
`

export const SheetContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const NotificationWrapper = styled.div`
  margin-top: 24px;
  max-width: 584px;
`

export const AddFieldButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  outline: none;
`

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-left: 16px;
`

export const ListItem = styled.li`
  white-space: pre;
`
