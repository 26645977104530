import { Actions, Content } from '@/components'
import { Cards } from '../../components'

import { contentLabels, links } from '../../utils'

import {
  Container,
  ContainerButton,
  ContainerLink,
  ContainerRedirect,
} from './styles'

const rentInstallment = () => {
  return (
    <Container>
      <Content.Heading
        title="Parcelamento de aluguel"
        subtitle="Parcele o aluguel dos Seguros Fiança e Fiança Essencial."
      />
      <Cards
        icon="wallet"
        isSvgIcons
        title={contentLabels.rentInstallment.title}
        children={contentLabels.rentInstallment.children}
      />
      <ContainerButton>
        <ContainerLink to="/sinistro">
          <Actions.Button size="large" type="button" kind="ghost">
            Voltar
          </Actions.Button>
        </ContainerLink>
        <ContainerRedirect
          href={links().rentInstallment.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Actions.Button size="large" type="button" icon="arrow-up-right">
            Parcelar aluguel
          </Actions.Button>
        </ContainerRedirect>
      </ContainerButton>
    </Container>
  )
}
export default rentInstallment
