import {
  Flex,
  Typography,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Icon } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'

const PortoBankTypoLogo = () => {
  return (
    <Flex
      sx={{
        alignItems: 'center',
        gap: '.25rem',
      }}
    >
      <Icon name="portoseguro" color="porto-banking-primary" size="md" />
      <Flex
        sx={{
          alignItems: 'center',
        }}
      >
        <Typography
          variant="system-title"
          sx={{
            fontFamily: 'Porto Roobert',
            color: 'porto-banking-primary',
          }}
          content="Porto"
        />
        <Typography
          variant="system-title"
          sx={{
            fontFamily: 'Porto Roobert',
          }}
          content="Bank"
        />
      </Flex>
    </Flex>
  )
}

export default PortoBankTypoLogo
