import styled from 'styled-components'
import { Link } from 'react-router-dom'
import media from 'styled-media-query'
import { rem } from 'polished'

import { themes } from '@/styles'

import { FeatherIcons } from '../../Icons'

export const Container = styled(Link)`
  all: unset;

  display: inline-flex;
  align-items: center;

  cursor: pointer;
  transition: 0.3s;

  font-size: ${rem('13px')};
  color: ${themes.text.low};
  font-family: ${themes.fonts.title};

  &:hover {
    transition: 0.3s;
    color: ${themes.palletes.primary};
  }

  ${media.greaterThan('huge')`
    font-size: ${rem('14px')};
  `}
`

export const Icon = styled(FeatherIcons)`
  height: 14px;

  margin-right: 4px;

  color: #d4d7e4;

  ${media.greaterThan('huge')`
    height: 16px;
  `}
`
