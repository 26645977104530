import styled from 'styled-components'
import media from 'styled-media-query'
import theme from 'styled-theming'
import { rem, rgba } from 'polished'
import { motion } from 'framer-motion'

import { themes, helpers, layers } from '@/styles'

import { Ionicons } from '../Icons'

export const Container = styled.div`
  position: relative;
`

export const Overlay = styled(motion.div)`
  ${helpers.overlayBackdrop}
`

export const List = styled(motion.div)`
  z-index: ${layers.popover};
  min-width: max-content;

  border-radius: 8px;
  background-color: ${theme('mode', {
    light: '#f3f5fb',
    dark: '#14171d',
  })};

  ${media.greaterThan('large')`
    position: absolute;
    top: 0;
    right: 0;

    ${helpers.defaultBoxShadow}
  `}

  ${media.lessThan('large')`
    position: fixed;
    bottom: 0;
    left: 0;

    width: 100%;
    padding: 16px 0;

    border-radius: 20px 20px 0 0;
  `}
`

export const Title = styled.p`
  padding: 16px 24px 0;

  text-align: left;
  font-size: ${rem('12px')};
  color: ${themes.text.low};

  ${media.lessThan('large')`
    padding: 16px 24px;
  `}
`

export const Icon = styled(Ionicons)`
  margin-top: 2px;
  margin-right: 8px;

  font-size: ${rem('12px')};
  color: ${themes.text.low};

  ${media.greaterThan('huge')`
    margin-right: 16px;

    font-size: ${rem('18px')};
  `}
`

export const Item = styled.button`
  all: unset;

  width: 100%;
  padding: 12px 16px;

  display: flex;
  align-items: center;
  text-wrap: nowrap;

  line-height: 1;
  cursor: pointer;
  box-sizing: border-box;
  font-size: ${rem('10px')};
  color: ${themes.text.higher};

  ${media.greaterThan('huge')`
    padding: 16px 24px;

    font-size: ${rem('15px')};
  `}

  &:first-of-type {
    border-radius: 8px 8px 0 0;
  }

  &:last-of-type {
    border-radius: 0 0 8px 8px;
  }

  &:hover {
    color: ${themes.text.medium};

    background-color: ${theme('mode', {
      light: rgba('#000', 0.02),
      dark: rgba('#FFF', 0.02),
    })};
  }
`
