import { useFormikContext } from 'formik'
import { Forms } from '@/components'
import { Batch } from '../../'
import { PartnershipSusepCombo } from '@/components/PartnershipSusepCombo'
import { PartnershipSusep } from '@/services/api/partnership/types'
import { useEffect, useState } from 'react'

const Organization = () => {
  const form = useFormikContext<Batch>()
  const [selectedSusep, setSelectedSusep] = useState<PartnershipSusep | null>(null)
  const shouldShowError = Object.keys(form.touched).length > 0

  useEffect(() => {
    const formUpdates: {
      susep?: string
      partner?: string
      broker?: string
    } = {
      susep: selectedSusep?.susep ?? '',
      broker: selectedSusep?.susep
        ? JSON.stringify({
            id: selectedSusep?.broker.id ?? '',
            susep: selectedSusep?.susep ?? '',
          })
        : '',
      partner: selectedSusep?.partner.id ?? '',
    }

    /** @ts-ignore */
    form.setValues({
      ...form.values,
      ...formUpdates,
    })
  }, [selectedSusep])

  return (
    <Forms.Card active step="1" title="Organização">
      <PartnershipSusepCombo
        selectionCallback={(value: PartnershipSusep) => {
          setSelectedSusep(() => value)
        }}
        error={{
          susep: shouldShowError ? form?.errors['broker'] : null,
          partnership: shouldShowError ? form?.errors['partner'] : null,
        }}
        sx={{
          partnership: {
            width: '100%',
          },
          susep: {
            width: '100%',
          },
          box: {},
        }}
        product="porto_imobiliaria"
      ></PartnershipSusepCombo>
    </Forms.Card>
  )
}

export default Organization
