import { Conditional, Icons } from '@/components'

import { IBoxtProps } from './interfaces'
import {
  Container,
  Icon,
  Initials,
  Title,
  Description,
  ContentLink,
} from './styles'

const Box = ({
  color,
  description,
  icon,
  title,
  to,
  initialsColor,
  link,
  iconLink,
  actions,
}: IBoxtProps) => {
  return (
    <Container to={to} color={color} onClick={actions}>
      <Initials color={initialsColor}>
        <Icon color={color}>{icon}</Icon>
      </Initials>

      <Title>{title}</Title>

      <Description>{description}</Description>
      <Conditional when={!!link}>
        <ContentLink>
          {link}
          <Icons.FeatherIcons height={10} width={18} name={iconLink} />
        </ContentLink>
      </Conditional>
    </Container>
  )
}
export default Box

Box.defaultProps = {
  to: '',
  title: 'Product title',
  description: 'Short product description here.',
}
