import { useContract } from '@/modules'
import { Products } from '@/screens/Contracts/Products/shared/entities'
import { formatToCEP, formatToCPF, formatToPhone } from 'brazilian-values'
import dayjs from 'dayjs'
import { TextContentGroup } from '../TextContentGroup'

export const Owner = () => {
  const contract = useContract<Products.CAPITALIZACAO>()

  return (
    <TextContentGroup
      title="Proprietário"
      values={[
        [
          {
            title: 'CPF',
            description: formatToCPF(contract.payload.landlord.document),
          },
          {
            title: 'Nome completo',
            description: contract.payload.landlord.name,
          },
          contract.payload.landlord.socialName && {
            title: 'Nome social',
            description: contract.payload.landlord.socialName,
          },
          { title: 'E-mail', description: contract.payload.landlord.email },
        ],
        [
          {
            title: 'Telefone/celular',
            description: formatToPhone(contract.payload.landlord.phone) || '-',
          },
          {
            title: 'Data de nascimento',
            description: dayjs(contract?.payload?.landlord.birthdate).format('DD/MM/YYYY') || '-',
          },
        ],
        [
          {
            title: 'CEP',
            description: formatToCEP(contract?.payload?.landlord.address.zipcode),
          },
          {
            title: 'Número',
            description: contract?.payload?.landlord.address.number,
          },
        ],
      ]}
    />
  )
}
