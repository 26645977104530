import { useState, useMemo, useEffect } from 'react'

import { useContract, useCurrentOrganization } from '@/modules'
import { Conditional } from '@/components'
import { dashText, formatMoneyBRL } from '@/tools'

import * as S from './styles'

const Payments = props => {
  const contract = useContract()
  const profile = useCurrentOrganization()

  const settings = profile.partnerships?.find(partnership =>
    partnership.broker.suseps.includes(contract.broker.id),
  )?.settings

  const [selected, setSelected] = useState({
    method: {
      id: '',
      installments: [],
    },
    installment: '',
  })

  const selectedInstallment = useMemo(
    () =>
      selected.installment &&
      selected.method.installments.find(({ id }) => id === selected.installment),
    [selected.installment],
  )

  const handleSelected = (key, value) => {
    setSelected(state => ({ ...state, [key]: value }))

    if (key === 'method') {
      setSelected(state => ({
        ...state,
        installment: '',
      }))
    }
  }

  useEffect(() => {
    props.onChange &&
      props.onChange({
        method: selected.method.id,
        installment: selected.installment,
      })
  }, [selected])

  const isCreditCardInstallment = ['70', '98'].includes(selected.installment.substring(0, 2))

  const isCreditCardMethod = ['62', '97'].includes(selected.method.id)

  const isDebitAccount = ['52', '55'].includes(selected.method.id)

  const paymentMethodIdToSettings = {
    '11': 'bankSlip',
    '41': 'invoiceWithoutDown',
    '81': 'invoiceWithDown',
    '62': 'creditCard',
    '97': 'portoSeguroCard',
  }

  const availableInstallments = useMemo(
    () =>
      selected.method.installments.filter(installment => {
        if (profile.type === 'BROKER') return true
        if (selected.method.installments.length <= 1) return true

        const prefix = `porto_imobiliaria.payments.${paymentMethodIdToSettings[selected.method.id]}`

        if (installment.amount <= settings[`${prefix}.installments`]) {
          return true
        }

        return false
      }),
    [selected],
  )

  return (
    <S.Container>
      <S.Content>
        <S.List
          data-gtm-type="select"
          data-gtm-name={dashText(
            props.payments.find(p => p.id === selected.method.id)?.description,
          )}
          data-gtm-subname="formas-de-pagamento"
        >
          {props.payments.map(method => {
            const active = selected.method.id === method.id

            return (
              <S.Item key={method.id} active={active}>
                <S.Heading onClick={() => handleSelected('method', method)}>
                  <S.Pin />

                  <S.Title>{method.description}</S.Title>
                </S.Heading>
              </S.Item>
            )
          })}
        </S.List>
        <Conditional when={!!selected.method.id}>
          <S.Resume>
            <S.Select
              onChange={event => handleSelected('installment', event.target.value)}
              value={selected.installment}
              data-gtm-type="select"
              data-gtm-name={selected.installment}
              data-gtm-subname="selecione-as-parcelas"
            >
              <option value="">Selecione as parcelas</option>

              {availableInstallments.map(installment => (
                <option value={installment.id}>{installment.description}</option>
              ))}
            </S.Select>
            <Conditional when={selectedInstallment}>
              <S.Note.List>
                {Object.entries(selectedInstallment).map(([key, value]) => {
                  const items = ['first', 'others', 'iof']

                  return (
                    items.includes(key) && (
                      <S.Note.Item>
                        <S.Note.Label>
                          {
                            {
                              first: 'Primeira parcela',
                              others: 'Demais parcelas',
                              iof: 'IOF',
                            }[key]
                          }
                        </S.Note.Label>
                        <S.Note.Value>{formatMoneyBRL(value)}</S.Note.Value>
                      </S.Note.Item>
                    )
                  )
                })}
              </S.Note.List>

              <Conditional when={isDebitAccount}>
                <S.Message>
                  O CPF/CNPJ informado deve pertencer obrigatoriamente ao titular da conta corrente.{' '}
                  <br />O Banco poderá solicitar ao cliente correntista a autorização prévia do
                  débito. Essa autorização deverá ser efetuada por meio dos canais de atendimento do
                  próprio Banco para cada um dos contratos e/ou apólices.
                </S.Message>
              </Conditional>

              <Conditional when={isCreditCardInstallment && isCreditCardMethod}>
                <S.Message>
                  Informamos que o lançamento das parcelas será realizado mensalmente no cartão de
                  crédito informado abaixo. Caso o lançamento da parcela não seja autorizado, um
                  boleto será gerado e enviado para o e-mail do inquilino informado no PAC, com
                  objetivo de regularização da parcela.
                </S.Message>
              </Conditional>
            </Conditional>
          </S.Resume>
        </Conditional>
      </S.Content>
    </S.Container>
  )
}

export default Payments
