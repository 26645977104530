import React from 'react'

import { IIoniconsProps } from './interfaces'

import { Container } from './styles'

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'ion-icon': any
    }
  }
}

const Ionicons: React.FC<IIoniconsProps> = (props) => (
  <Container {...props}>
    <ion-icon name={props.name}></ion-icon>
  </Container>
)

export default Ionicons
