import { pxToRem } from '@/styles/sheets/helpers'
import breakpoints from '@pol-npm/riscos-financeiros-dashboard-ui/dist/theme/breakpoints'
import styled from 'styled-components'

export const Container = styled.div`
  background-color: #474075;
  border-radius: ${pxToRem(16)};
  display: flex;

  @media (max-width: calc(${breakpoints.md})) {
    flex-direction: column;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(8)};
  justify-content: space-around;
  padding: ${pxToRem(24)};
`

export const Button = styled.a`
  align-self: start;
  background-color: #fff;
  border-radius: ${pxToRem(4)};
  margin-top: ${pxToRem(10)};
  padding: ${pxToRem(12)};

  @media (max-width: calc(${breakpoints.md})) {
    text-align: center;
    width: 100%;
  }
`

export const Image = styled.img`
  padding: ${pxToRem(8)};
`
