import api from '../index'

export async function getByZipcode(zipcode: string) {
  try {
    const response: any = await api.instance.v2.get(`/address?cep=${zipcode}`)

    if (!response) throw Error('CEP não encontrado.')

    const { cep, logradouro, bairro, localidade, uf } = response.data

    return {
      state: uf,
      zipcode: cep,
      city: localidade,
      street: logradouro,
      neighborhood: bairro,
    }
  } catch (error) {
    throw error
  }
}
