import { useEffect } from 'react'

import { Conditional, Content } from '@/components'
import { useContract } from '@/modules'
import { useGTM, dashText } from '@/tools'
import { CustomDataProps, DataLayerProps } from '@/tools/hooks/GTM/types'

import Proposal from './../Proposal'

import Policy from './Policy'
import Resume from './Resume'

import * as S from './styles'
import NewError from '../../shared/NewError'

type ContractType = ReturnType<typeof useContract>

const getBudget = ({ contract }: { contract: ContractType }) =>
  contract?.budgets.find(
    (budget) => budget.id === contract.payload?.payment?.budget
  )

const getCommonCustomData = ({
  contract,
  suffix = '',
}: {
  contract: ContractType
  suffix?: string
}): CustomDataProps => {
  const budget = getBudget({ contract })

  return {
    page: {
      name: `Portal Imobiliária - Orcamentos - Seguro Incendio Resumo ${
        suffix && `- ${suffix}`
      }`,
      product: 'aluguel-fianca',
    },
    site: {
      brand: 'portoseguro',
      portal: 'imobiliarias',
    },
    orcamento: {
      protocolo: contract.policy?.proposal ?? '',
      tipo: contract.product,
      parceiro_id: contract.partner?.id,
      susep: contract.broker?.id,
      plano: budget?.description ?? '',
    },
  }
}

const getResultadoSimulacaoDataLayer = ({
  contract,
  retorno = 'sucesso',
}: {
  contract: ContractType
  retorno?: 'sucesso' | 'erro'
}): DataLayerProps => {
  const budget = getBudget({ contract })
  return {
    event: 'resultado_simulacao',
    tipo_orcamento: `consulta-status:seguro-incendio:${dashText(
      budget?.description || 'sem-plano-selecionado'
    )}`,
    status: contract.status,
    retorno,
  }
}

const PolicyWrapper = ({ contract }: { contract: ContractType }) => {
  const { setCustomData, setDataLayer } = useGTM()

  useEffect(() => {
    setCustomData(getCommonCustomData({ contract }))
    setDataLayer({
      event: 'step_change',
      titulo: 'Portal Imobiliária - orcamentos - seguro incendio',
      etapa: '/area-logada/orcamentos/seguro-incendio/resumo',
    })
    setDataLayer(getResultadoSimulacaoDataLayer({ contract }))
  }, [])

  return <Policy />
}

const ErrorWrapper = ({ contract }: { contract: ContractType }) => {
  const { setCustomData, setDataLayer } = useGTM()

  const parseError = {
    '@imob/integration-failure': {
      title: 'Erro de integração.',
    },
    '500': {
      title: 'Erro de integração.',
    },
    '@imob/unmapped-error': {
      title: 'Algo deu errado.',
    },
    '@imob/discount': {
      title:
        'Desconto técnico não permitido. Por gentileza, entrar em contato com o suporte do portal das imobiliárias',
    },
    '@imob/coverages': {
      title:
        'Contratação de conteúdo, perda de aluguel e resp. civil não permitida para imóveis desocupados.',
    },
    '@imob/periods': {
      title: 'Vigência superior a 180 dias.',
    },
    '@imob/zip-code-not-found': {
      title: 'CEP não entrado na nossa base.',
    },
    '@imob/partnership-not-found': {
      title: 'Parceiro Negócio não encontrado.',
    },
  }

  useEffect(() => {
    setCustomData(getCommonCustomData({ contract, suffix: 'Erro' }))
    setDataLayer({
      event: 'step_change',
      titulo: 'Portal Imobiliária - orcamentos - seguro incendio',
      etapa: '/area-logada/orcamentos/seguro-incendio/',
    })
    setDataLayer({
      ...getResultadoSimulacaoDataLayer({
        contract,
        retorno: 'erro',
      }),
      descricao: parseError[contract.metadata.error?.code ?? '500']?.title,
      erro: {
        codigo: contract.metadata.error?.code ?? '500',
        servico: 'seguro-incendio',
        mensagem: parseError[contract.metadata.error?.code ?? '500']?.title,
      },
    })
  }, [])

  return (
    <NewError
      product="Seguro Incêndio"
      errorTitle={parseError[contract.metadata.error?.code]?.title}
      errorMessage={parseError[contract.metadata.error?.code]?.message}
    />
  )
}

const Details = () => {
  const contract = useContract()

  if (
    (!!contract.metadata?.error && contract.external_status !== 'PENDING') ||
    contract.external_status === 'FAILURE'
  ) {
    return <ErrorWrapper contract={contract} />
  }

  return (
    <S.Wrapper>
      <S.Aside>
        <Resume />
      </S.Aside>

      <S.Content>
        <Conditional when={contract.external_status === 'PENDING'}>
          <Content.Loader message="Aguarde, estamos calculando o orçamento." />
        </Conditional>

        <Conditional
          when={
            !!contract.budgets.length &&
            !Object.keys(contract?.policy ?? {}).length &&
            !contract.metadata.error &&
            contract.external_status !== 'PENDING'
          }
        >
          <Proposal />
        </Conditional>

        <Conditional
          when={
            !!Object.keys(contract?.policy ?? {}).length &&
            !contract.metadata.error &&
            contract.external_status !== 'PENDING'
          }
        >
          <PolicyWrapper contract={contract} />
        </Conditional>
      </S.Content>
    </S.Wrapper>
  )
}

export default Details
