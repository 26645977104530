import {
  Flex,
  Typography,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'

interface ILastUpdate {
  lastSynced: Date;
}

export const LastUpdate: React.FC<ILastUpdate> = ({ lastSynced }: { lastSynced: Date }) => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'center',
      }}
    >
      <Typography content="Atualizado" variant="porto-text-caption-regular" />
      <Typography
        content={(lastSynced.toLocaleString('pt-br')) || '-'}
        variant="porto-text-caption-regular"
      />
    </Flex>
  )
}
