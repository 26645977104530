import { useEnums, usePlans } from '@/tools/hooks/capitalizacao'
import { useCountries } from '@/tools/hooks/capitalizacao/useCountries'
import NewError from '../../shared/NewError'
import { Loading } from '../components'
import { DetailsScreen } from './Details'

const PARENT_PADDING = '168px'

export const Details = () => {
  const enumsQuery = useEnums()
  const plansQuery = usePlans()
  const countries = useCountries()

  if (enumsQuery.isError || plansQuery.isError || countries.isError) {
    return (
      <NewError
        product="Capitalização"
        errorTitle={
          enumsQuery.error?.message || plansQuery.error?.message || countries.error?.message
        }
      />
    )
  }

  return (
    <Loading
      isLoading={enumsQuery.isLoading || plansQuery.isLoading || countries.isLoading}
      sx={{ height: `calc(100vh - ${PARENT_PADDING})` }}
    >
      <DetailsScreen enums={enumsQuery.data} plans={plansQuery.data} />
    </Loading>
  )
}
