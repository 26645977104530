import { Box } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'

const Container = ({
  children,
  addPaddingTop = false,
  sx = {},
}) => {
  return (
    <Box
      sx={{
        paddingX: { xs: '1rem', md: '2rem', lg: '4rem' },
        paddingBottom: '1.5rem',
        paddingTop: addPaddingTop && '1.5rem',
        backgroundColor: "porto-primitive-white",
        ...sx
      }}
    >{children}</Box>
  )
}

export default Container
