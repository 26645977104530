import { differenceInYears, parse } from 'date-fns'
import dayjs from 'dayjs'

const DATE_REGEX = /(\d{4}-\d{2}-\d{2})|\d{2}\/\d{2}\/\d{4}/

export const birthdateValidator = (value?: string) => {
  if (!value) return true

  const birthdate = dayjs(value.split('/').reverse().join(''))
  const birthdateYear = dayjs(value.split('/').reverse().join('')).year()
  const limitDate = dayjs('1900-01-01')
  const limitYear = dayjs().year()

  return limitDate.isSameOrBefore(birthdate) && limitYear >= birthdateYear && DATE_REGEX.test(value)
}

export const over18Validator = (value?: string) => {
  if (!value) return true

  const date = parse(
    value.substring(0, 10).split('-').reverse().join('/'),
    'dd/MM/yyyy',
    new Date(),
  )

  return differenceInYears(new Date(), date) >= 18
}
