import styled, { css } from 'styled-components'
import { rem, rgba, size } from 'polished'
import media from 'styled-media-query'
import theme from 'styled-theming'

import { Icons } from '@/components'
import { themes } from '@/styles'

const badgeColorGenerate = (color) => css`
  color: ${color};
  background-color: ${rgba(color, 0.1)};
`

export const Badge = styled.span<{ type: string }>`
  padding: 4px 8px;

  color: #000;
  border-radius: 6px;
  font-size: ${rem('6px')};
  background-color: ${rgba('#000', 0.1)};

  ${media.greaterThan('large')`
    font-size: ${rem('9px')};
  `}

  ${media.greaterThan('huge')`
    font-size: ${rem('11px')};
  `}

  ${(props) =>
    props.type &&
    {
      Customizado: badgeColorGenerate('#1400FF'),
      sugestão: badgeColorGenerate('#00D1FF'),
    }[props.type]}
`

export const Prices = styled.div``

export const Price = {
  Highlight: styled.p`
    font-size: ${rem('12px')};
    color: ${themes.text.higher};

    ${media.greaterThan('large')`
        display: flex;
        align-items: center;

        font-size: ${rem('19px')};
      `}

    ${media.greaterThan('huge')`
        font-size: ${rem('24px')};
      `}
  `,

  Small: styled.span`
    font-size: ${rem('8px')};
    text-transform: uppercase;
    margin-left: 2px;

    ${media.greaterThan('large')`
        font-size: ${rem('11px')};
      `}

    ${media.greaterThan('huge')`
        font-size: ${rem('14px')};
      `}

      ${media.lessThan('large')`
        margin-top: 2px;

        display: block;
      `}

      &:after {
      transform: rotate(15deg);

      width: 1px;
      height: 10px;
      margin: 0 2px;

      display: none;

      content: '';
      background-color: ${themes.text.high};

      ${media.greaterThan('large')`
          height: 13px;
          margin: 0 11px -6px;
          display: inline-block;
        `}

      ${media.greaterThan('huge')`
          height: 19px;
          margin: 0 13px -8px;
        `}
    }
  `,

  SmallFess: styled.span`
    font-size: ${rem('4px')};
    text-transform: uppercase;
    flex: 1;

    ${media.greaterThan('large')`
        font-size: ${rem('8px')};
      `}

    ${media.greaterThan('huge')`
      font-size: ${rem('10px')};
    `}
  `,

  Content: styled.div`
    display: flex;
    flex-direction: column;
  `,

  Muted: styled.p`
    margin-top: 4px;
    margin-bottom: 8px;

    font-size: ${rem('9px')};
    color: ${themes.text.low};

    ${media.greaterThan('large')`
        font-size: ${rem('12px')};
      `}

    ${media.greaterThan('huge')`
        font-size: ${rem('14px')};
      `}
  `,
}

export const Actions = styled.div`
  margin-left: 160px;
`

export const Check = {
  Box: styled.div`
    ${size('12px')}

    position: absolute;

    margin-left: ${rem('25px')};

    border-radius: 8px;
    border: 1px solid ${themes.utilities.border};

    ${media.greaterThan('large')`
      ${size('16px')}

      margin-right: 16px;
      margin-left: ${rem('25px')};
    `}

    ${media.greaterThan('huge')`
      ${size('18px')}

      margin-left: ${rem('70px')};
    `}
  `,

  Icon: styled(Icons.FeatherIcons).attrs(() => ({ name: 'check' }))`
    ${size('7px')}

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    color: #c6dbe4;
    stroke-width: 3;

    ${media.greaterThan('large')`
      ${size('9px')}
    `}

    ${media.greaterThan('huge')`
      ${size('14px')}
    `}
  `,
}

export const Container = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: column;

  cursor: pointer;
  user-select: none;

  font-family: ${themes.fonts.title};

  border: 1px solid #dcf0f8;
  border-radius: 0.5rem;

  margin: 10px;
  width: 220px;

  ${media.greaterThan('large')`
    width: 230px;
  `}

  ${media.greaterThan('huge')`
    width: 290px;
  `}

  &:active {
    -webkit-tap-highlight-color: transparent;
  }

  ${(props) =>
    props.active &&
    css`
      background-image: ${theme('mode', {
        light: `linear-gradient(to right, ${rgba('#00abef', 0)} 0%, ${rgba(
          '#00abef',
          0.05
        )} 100%)`,
        dark: `linear-gradient(to right, ${rgba('#000', 0)} 0%, ${rgba(
          '#000',
          0.03
        )} 100%)`,
      })};

      border: 1.5px solid ${themes.palletes.primary};

      ${Check.Box} {
        border-color: transparent;
        background-color: ${themes.palletes.primary};
      }

      ${Check.Icon} {
        color: #fff;
      }
    `}

  :hover {
    box-shadow: 0 0 1.5px ${themes.palletes.primary};
  }
`

export const Content = styled.div`
  padding: 14px 14px 0 14px;

  display: flex;
  flex-direction: column;

  height: 100%;

  ${media.greaterThan('large')`
    padding: 0px 24px 0 16px;
  `}

  ${media.greaterThan('huge')`
    padding: 0px 24px 0 16px;
  `}
`

export const Header = styled.header`
  padding-top: 16px;
`

export const Title = styled.h1`
  margin-bottom: 4px;

  font-weight: 500;
  font-size: ${rem('14px')};
  color: ${themes.text.high};

  ${media.greaterThan('large')`
    margin-bottom: 8px;

    font-size: ${rem('15px')};
  `}

  ${media.greaterThan('huge')`
    font-size: ${rem('18px')};
  `}
`

export const Description = styled.p`
  max-width: 100px;
  margin-top: 14px;

  font-size: ${rem('7px')};
  color: ${themes.text.low};

  ${media.greaterThan('large')`
    max-width: 160px;
    margin-top: 18px;

    font-size: ${rem('10px')};
  `}

  ${media.greaterThan('huge')`
    max-width: 200px;
    margin-top: 22px;

    font-size: ${rem('12px')};
  `}
`

export const Body = styled.div`
  flex: 1;
  margin-top: ${rem('16px')};
`

export const Coverages = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: -4px;

  margin-bottom: ${rem('30px')};
`

export const CoverageItem = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;
  margin-top: ${rem('8px')};

  &:first-child {
    margin-top: 0;
  }
`

export const Icon = styled.div`
  color: ${theme('mode', {
    light: themes.palletes.primary,
    dark: '#0087ba',
  })};
  font-size: 16px;

  ${media.greaterThan('huge')`
    font-size: 22px;
  `}
`

export const Text = styled.p`
  font-size: ${rem('8px')};
  color: ${themes.text.medium};
  margin-left: ${rem('3px')};

  ${media.greaterThan('large')`

    font-size: ${rem('10px')};
  `}

  ${media.greaterThan('huge')`
    font-size: ${rem('12px')};
  `}
`

export const Link = styled.a`
  font-size: ${rem('13px')};
  color: #fff;
  cursor: pointer;
  padding: 18px;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 30px;
  border-radius: 0px 30px 0px 7px;
  background-color: ${themes.palletes.primary};

  ${media.greaterThan('large')`
    font-size: ${rem('12px')};
  `}

  ${media.greaterThan('huge')`
    font-size: ${rem('15px')};
  `}
`

export const Print = styled.a`
  font-size: ${rem('15px')};
  color: ${themes.palletes.primary};
  cursor: pointer;

  padding: 18px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 20px 0px 7px;

  height: 30px;

  background-color: #e5faff;

  ${media.greaterThan('large')`
    font-size: ${rem('14px')};
  `}

  ${media.greaterThan('huge')`
    font-size: ${rem('16px')};
  `}
`

export const Footer = styled.div`
  width: 100%;
  display: flex;

  justify-content: space-between;

  margin-top: 15px;
`
