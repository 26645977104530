const UF = [
  { name: 'Acre', acronym: 'AC', ddd: [68] },
  { name: 'Alagoas', acronym: 'AL', ddd: [82] },
  { name: 'Amapá', acronym: 'AP', ddd: [96] },
  { name: 'Amazonas', acronym: 'AM', ddd: [92, 97] },
  { name: 'Bahia', acronym: 'BA', ddd: [71, 73, 74, 75, 77] },
  { name: 'Ceará', acronym: 'CE', ddd: [85, 88] },
  { name: 'Distrito Federal', acronym: 'DF', ddd: [61] },
  { name: 'Espírito Santo', acronym: 'ES', ddd: [27, 28] },
  { name: 'Goiás', acronym: 'GO', ddd: [62, 64] },
  { name: 'Maranhão', acronym: 'MA', ddd: [98, 99] },
  { name: 'Mato Grosso', acronym: 'MT', ddd: [65, 66] },
  { name: 'Mato Grosso do Sul', acronym: 'MS', ddd: [67] },
  { name: 'Minas Gerais', acronym: 'MG', ddd: [31, 32, 33, 34, 35, 37, 38] },
  { name: 'Pará', acronym: 'PA', ddd: [91, 93, 94] },
  { name: 'Paraíba', acronym: 'PB', ddd: [83] },
  { name: 'Paraná', acronym: 'PR', ddd: [41, 42, 43, 44, 45, 46] },
  { name: 'Pernambuco', acronym: 'PE', ddd: [81, 87] },
  { name: 'Piauí', acronym: 'PI', ddd: [86, 89] },
  { name: 'Rio de Janeiro', acronym: 'RJ', ddd: [21, 22, 24] },
  { name: 'Rio Grande do Norte', acronym: 'RN', ddd: [84] },
  { name: 'Rio Grande do Sul', acronym: 'RS', ddd: [51, 53, 54, 55] },
  { name: 'Rondônia', acronym: 'RO', ddd: [69] },
  { name: 'Roraima', acronym: 'RR', ddd: [95] },
  { name: 'Santa Catarina', acronym: 'SC', ddd: [47, 48, 49] },
  {
    name: 'São Paulo',
    acronym: 'SP',
    ddd: [11, 12, 13, 14, 15, 16, 17, 18, 19],
  },
  { name: 'Sergipe', acronym: 'SE', ddd: [79] },
  { name: 'Tocantins', acronym: 'TO', ddd: [63] },
]

export default UF
