import routes from '@/router/routes'
import * as Layouts from '@/layouts'
import { Route, Switch } from 'react-router-dom'
import Errors from '@/screens/Errors/Route'
import { useCurrentOrganization } from '@/modules'

interface RouteRendererProps {
  type: keyof typeof routes
  layout: keyof typeof Layouts
}

const RouteTypeRenderer = ({ type, layout }: RouteRendererProps) => {
  const Layout = Layouts[layout]
  const profile = useCurrentOrganization()

  return (
    <Layout>
      <Switch>
        {routes[type].map(({ conditional, ...route }, index) => {
          if (!conditional) return <Route key={index} exact={route.exact} {...route} />

          if (conditional(profile)) return <Route key={index} exact={route.exact} {...route} />

          return null
        })}
        {type !== 'public' && <Route component={Errors} />}
      </Switch>
    </Layout>
  )
}

export default RouteTypeRenderer
