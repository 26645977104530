export default {
  'porto_essencial.claim': [
    {
      upperHeading: 'Sinistro',
      heading: 'Comunicado de Sinistro',
      fields: {
        'porto_essencial.claim.handout': {
          type: 'toggle',
          title: 'Habilitar',
        },
      },
    },
  ],
}
