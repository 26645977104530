import React from 'react'
import { isBrowser } from 'react-device-detect'

import Actions from '../../Actions'

import { IHeadingProps } from './interfaces'

import { Container, Head, Title, Subtitle, Right, Action } from './styles'

const Heading: React.FC<IHeadingProps> = (props) => {
  return (
    <Container>
      <Head>
        <Title>{props.title}</Title>

        {props.subtitle && <Subtitle>{props.subtitle}</Subtitle>}
      </Head>

      {props.actions &&
        (isBrowser ? (
          <Right>
            {props.actions.map((action, index) => (
              <Action
                key={index}
                icon={action.icon}
                onClick={action.onClick}
                {...action}
              >
                {action.label}
              </Action>
            ))}
          </Right>
        ) : (
          <Actions.Floating
            fixed
            actions={props.actions.map((action) => ({
              icon: action.icon,
              onClick: action.onClick,
            }))}
          />
        ))}
    </Container>
  )
}

Heading.defaultProps = {
  title: 'Heading',
}

export default Heading
