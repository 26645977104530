import { Flex } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { IWrapperProps } from './interfaces'

export const Wrapper = ({
  children,
  id,
  sx
}: IWrapperProps) => {
  return (
    <Flex sx={sx}>
      <Flex
        sx={{
          flexDirection: "column",
          gap: '1rem',
          padding: { xs: "1rem", md: "2rem", xl: "4rem" },
          width: "100%",
        }}
        id={id}
      >
      {children}
      </Flex>
    </Flex>
  )
}