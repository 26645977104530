import styled from 'styled-components'
import { rem } from 'polished'
import media from 'styled-media-query'

import { Actions } from '@/components'
import { themes } from '@/styles'

export const Content = styled.div`
  line-height: 180%;
  font-size: ${rem('8px')};
  color: ${themes.text.higher};

  ${media.greaterThan('huge')`
    font-size: ${rem('12px')};
  `}
`

export const Feature = {
  Section: styled.div`
    margin-bottom: 32px;
  `,

  List: styled.table`
    width: 100%;
    margin-bottom: 10px;

    text-align: center;

    > thead {
      th {
        :first-of-type {
          border-top-left-radius: 20px;
        }

        :last-of-type {
          border-top-right-radius: 20px;
        }
        background-color: rgba(102, 206, 245, 0.1);
        padding: 8px 8px 8px 8px;

        font-weight: 700;
        font-size: ${rem('8px')};

        ${media.greaterThan('large')`
            font-size: ${rem('11px')};
          `}

        ${media.greaterThan('huge')` 
            font-size: ${rem('14px')};
        `}
      }
    }

    > tbody {
      > tr {
        &:nth-child(odd) {
          background-color: ${themes.page.colors.content};
        }

        td:first-of-type {
          font-weight: 550;
        }

        > td {
          padding: 8px;

          font-size: ${rem('7px')};
          color: ${themes.text.high};

          ${media.greaterThan('large')`
              font-size: ${rem('11px')};
            `}

          ${media.greaterThan('huge')`
              padding: 8px 16px;
  
              font-size: ${rem('14px')};
            `}
        }
      }
    }
  `,
}

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Action = styled.a`
  color: ${themes.text.low};
  font-weight: 600;
  font-size: ${rem('14px')};
  cursor: pointer;

  :hover {
    color: #000;
  }

  ${media.lessThan('huge')`
    font-size: ${rem('12px')};
  `}
`
