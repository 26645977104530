export const data = [
  {
    image: '/images/Porto-ic-handshake.svg',
    backgroundColor: 'var(--porto-primitive-off-white)',
    title: 'Parceria estreita e transparente',
    text: 'O acesso ao Portal das Imobiliárias e a emissão de produtos estarão vinculados ao corretor da sua preferência',
  },
  {
    image: '/images/Porto-ic-geek.svg',
    backgroundColor: 'var(--porto-bank-porto-bank-15,#DFE2F2)',
    title: 'Agilidade para o cliente final',
    text: 'Contratação e gerenciamento dos produtos diretamente pelo portal, dando mais autonomia no processo e otimizando a jornada do cliente.',
  },
  {
    image: '/images/Porto-ic-laptop.svg',
    backgroundColor: 'var(--porto-primitive-yellow-30)',
    title: 'Ferramentas facilitadoras',
    text: 'Conte com ferramentas gratuitas que vão trazer agilidade para o seu dia a dia, como a assinatura eletrônica e assessoria de cobrança.',
  },
  {
    image: '/images/Porto-ic-coin.svg',
    backgroundColor: 'var(--porto-seguro-holding-porto-seguro-15, #D9F1FF)',
    title: 'Ganho de escala',
    text: 'Com a contratação na ponta, corretor e imobiliárias ganham capilaridade, aumentando a produção de contratos e a rentabilidade do seu negócio.',
  },
]
