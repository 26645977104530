import { rgba, size } from 'polished'
import styled, { css } from 'styled-components'
import media from 'styled-media-query'

import { FeatherIcons, Loader } from '@/components/Icons'
import { themes } from '@/styles'

interface Props {
  error?: string | boolean
}

export const Search = styled.div`
  position: relative;
`

export const ContainerAnimate = styled.div`
  width: 100%;
`
export const SearchInputs = styled.div`
  display: flex;
`
export const Input = styled.input<Props>`
  height: 48px;
  width: 100%;
  padding: 12px;
  border: 1px solid #eeeef7;
  border-radius: 4px 0 0 4px;

  font-family: 'Porto Roobert';
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 20px;

  letter-spacing: 0.03em;

  :focus-visible {
    outline: 0.5px solid ${themes.palletes.primary};
  }

  ${(props) =>
    props.error &&
    css`
      border-radius: 4px 4px 0 0;
      border-color: ${rgba(themes.palletes.danger, 0.5)};
      background-color: ${rgba(themes.palletes.danger, 0.02)};

      &:focus {
        border-color: ${themes.palletes.danger};
        background-color: ${rgba(themes.palletes.danger, 0.03)};
        box-shadow: 0 0 0 3px ${(props) => rgba(themes.palletes.danger, 0.1)};

        ${media.greaterThan('huge')`
          box-shadow: 0 0 0 4px ${(props) => rgba(themes.palletes.danger, 0.1)};
        `}
      }
    `}
`
export const SearchIcon = styled.div`
  display: flex;
  width: 40px;
  justify-content: center;
  align-items: center;
  position: static;
  float: none;
  background: ${themes.palletes.primary};
  margin-left: -5px;

  border-radius: 0 4px 4px 0;

  cursor: pointer;
`
export const Icon = styled(FeatherIcons)`
  ${size('13px')}

  color: #f6f7fb;
  transition: 0.3s;
  pointer-events: none;

  ${media.greaterThan('huge')`
    ${size('13px')}
  `}
`

export const ContainerSearchRecent = styled.div`
  margin-top: 4px;
  height: 180px;
  overflow: auto;
  z-index: 1;

  background: #ffffff;
  border: 1px solid ${themes.palletes.primary};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  position: absolute;

  ::-webkit-scrollbar {
    width: 6px;
    border-radius: 30px;
  }

  ::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 30px;
    padding: 4px;
  }

  div {
    display: flex;
  }

  p {
    font-family: 'Porto Roobert';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    letter-spacing: 0.03em;

    color: #1f1f1f;
  }
`

export const LabelRecentSearch = styled.div`
  font-family: 'Porto Roobert';
  font-style: normal;
  font-weight: 500;
  font-size: 11.8px;
  line-height: 16px;
  padding: 8px 0px 8px 8px;
  letter-spacing: 0.04em;
  padding-right: 35px;

  color: #5f5f5f;
`

export const TitleReacentSearch = styled.div`
  font-family: 'Porto Roobert';
  font-style: normal;
  padding: 0 8px 0 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  letter-spacing: 0.03em;

  color: ${themes.palletes.primary};
`

export const CointainerWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  height: 48px;
  gap: 4px;

  &:hover {
    background-color: #f7f7f7;
    cursor: pointer;
  }

  p {
    width: 100%;
  }
`

export const LoaderWrap = styled.div`
  display: flex;
  margin-top: 12px;
  transform: translateY(-40%);
`

export const IconLoader = styled(Loader)`
  border-left-color: #ffff;
`
