import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import theme from 'styled-theming'
import { rem, rgba, size } from 'polished'
import { NavLink } from 'react-router-dom'

import { themes } from '@/styles'

import { Ionicons } from '../../Icons'

export const Container = styled.div`
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
`

export const List = styled.div<{numberOfItems: number}>`
  overflow: hidden;

  width: 100%;

  display: grid;
  grid-gap: 10px;
  grid-template-columns: ${({ numberOfItems }) => `repeat(${numberOfItems}, 1fr);`};

  border-radius: 8px;
  background-color: ${theme('mode', {
    light: '#edeff7',
    dark: '#222329',
  })};
`

export const Icon = styled(Ionicons)`
  margin-right: 4px;

  font-size: ${rem('8px')};
  color: ${themes.text.low};

  ${media.greaterThan('large')`
    margin-top: 2px;

    font-size: ${rem('12px')};
  `}

  ${media.greaterThan('huge')`
    margin-right: 8px;

    font-size: ${rem('16px')};
  `}
`

export const Title = styled.span`
  color: ${themes.text.medium};
  font: 500 ${rem('8px')} ${themes.fonts.title};

  ${media.greaterThan('large')`
    font-size: ${rem('10px')};
  `}

  ${media.greaterThan('huge')`
    font-size: ${rem('14px')};
  `}
`

export const Item = styled(NavLink)`
  all: unset;

  margin: 4px;
  padding: 10px 0px;

  display: flex;
  align-items: center;
  justify-content: center;

  line-height: 1;
  cursor: pointer;
  border-radius: 8px;

  &:hover {
    background-color: ${theme('mode', {
      light: '#FFF',
      dark: '#17181d',
    })};

    ${Title} {
      color: ${themes.text.high};
    }
  }

  ${media.greaterThan('large')`
    padding: 12px 0px;
  `}

  ${media.greaterThan('huge')`
    margin: 6px;
    padding: 16px 0px;
  `}

  &.active {
    background-color: ${theme('mode', {
      light: '#FFF',
      dark: '#17181d',
    })};

    ${Icon} {
      color: ${themes.palletes.primary};
    }

    ${Title} {
      color: ${themes.palletes.primary};
    }
  }
`
