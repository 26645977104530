import React, { useState, useRef, useEffect } from 'react'
import { FormikProvider, useFormik } from 'formik'
import { isBrowser, isMobile } from 'react-device-detect'

import { Modals, Forms, Icons, Content } from '@/components'
import { useOutsideClick } from '@/tools'

import { useAuth, useCurrentUser } from '../hooks'

import validationSchema from './validations'

import * as S from './styles'

const Sudo = (props) => {
  const auth = useAuth()
  const user = useCurrentUser()

  const [status, setStatus] = useState<'loading' | 'validated' | ''>('')

  const ref = useRef()

  const onSubmit = async ({ password }) => {
    try {
      setStatus('loading')

      await auth.handleLogin({
        email: user.store.email,
        password,
      })

      setStatus('validated')
    } catch (error) {
      setStatus('')
    }
  }

  const form = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema,
    onSubmit,
  })

  useEffect(() => {
    if (status === 'validated') {
      setTimeout(() => {
        props.onSubmit()
      }, 1500)
    }
  }, [status])

  useOutsideClick(ref, props.onDismiss)

  return (
    <Modals.Base centered={isBrowser} bottom={isMobile}>
      <S.Container ref={ref}>
        <S.Icon>
          <Icons.Ionicons
            name={
              status === 'validated'
                ? 'lock-open-outline'
                : 'lock-closed-outline'
            }
          />
        </S.Icon>

        <S.Title>Confirme sua senha para continuar</S.Title>

        {status === 'validated' && (
          <Content.Message title="Sucesso" kind="success">
            Senha validada com sucesso.
          </Content.Message>
        )}

        {status === 'loading' && (
          <Content.Loader message="Aguarde, validando senha..." />
        )}

        {!status && (
          <FormikProvider value={form}>
            <S.Form onSubmit={form.handleSubmit}>
              <Forms.InputGroup
                size="block"
                label="Senha"
                name="password"
                type="password"
                placeholder="Digite sua senha"
                autoFocus
              />

              <S.Action icon="Key" size="block">
                Confirmar senha
              </S.Action>
            </S.Form>
          </FormikProvider>
        )}
      </S.Container>
    </Modals.Base>
  )
}

export default Modals.Promised.createModal(Sudo)
