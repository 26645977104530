import { useEffect, useState, useCallback, useMemo } from 'react'

import { getCampaignNumbers } from '@/services/api/campaigns'
import type {
  CampaignNumber,
  FetchCampaignNumbersResult,
  UseCampaignNumbersResult,
} from './types'
import { useOrganizations } from '@/modules'

const CHRISTMAS_CAMPAIGN_ID = 'natal-premiado-2021'

const getChristmasCampaignNumbers = getCampaignNumbers(CHRISTMAS_CAMPAIGN_ID)

const fetchCampaignNumbers = async (
  params:
    | {
        partner: string
      }
    | {
        broker: string[]
      }
): Promise<FetchCampaignNumbersResult> => {
  try {
    const response = await getChristmasCampaignNumbers(params)

    return { data: response.data }
  } catch (error: unknown) {
    return { error: new Error('Erro ao buscar numeros da campanha') }
  }
}

export const useCampaignNumbers = (): UseCampaignNumbersResult => {
  const currentOrganization = useOrganizations().store.active

  const [status, setStatus] = useState<
    'idle' | 'loading' | 'error' | 'completed'
  >('idle')

  const [result, setResult] = useState<CampaignNumber[]>()
  const [error, setError] = useState<Error>()

  const queryArgs = useMemo(() => {
    if (currentOrganization.type.toLocaleLowerCase() === 'real_estate') {
      return { partner: currentOrganization.id }
    }

    // TODO Melhorar a tipagem do currentOrganization pra tirar esse hack
    return { broker: (currentOrganization as any)?.metadata?.susep?.porto }
  }, [currentOrganization])

  const fetchData = useCallback(async () => {
    setStatus('loading')

    const res = await fetchCampaignNumbers(queryArgs)

    if (res.error) {
      setError(res.error)
      setStatus('error')
      return
    }

    if (res.data) {
      setResult(res.data)
      setStatus('completed')
    }
  }, [queryArgs])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return { status, result, error } as UseCampaignNumbersResult
}
