import { Content as ContentComponent } from '@/components'

import Category from '../Homepage/Category'
import { List } from '../Homepage/styles'
import EmailChangeModal from './EmailChange'
import PasswordChangeModal from './PasswordChange'
import * as S from './styles'

const Users = () => {
  const { Heading } = ContentComponent

  return (
    <S.Container>
      <Heading
        title="Usuários"
        subtitle="Gerencie as contas de usuários do sistema."
      />

      <List>
        <Category
          icon='mail-outline'
          title='Alterar e-mail'
          description='Use essa opção caso precise alterar um e-mail de uma conta de usuário para outro.'
          onClick={() => EmailChangeModal.open()} />
        <Category
          icon='key-outline'
          title='Trocar senha'
          onClick={() => PasswordChangeModal.open()}
          description='Precisa cadastrar uma senha manualmente para alguma conta? Use essa opção.' />
      </List>
    </S.Container>
  )
}

export default Users
