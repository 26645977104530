import styled from 'styled-components'

export const Container = styled.div`
  &:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    background: white;
    bottom: 0;
    right: 0;
    z-index: -1;
  }
`
