import styled from 'styled-components'
import { rem } from 'polished'
import breakpoints from '@pol-npm/riscos-financeiros-dashboard-ui/dist/theme/breakpoints'

export const RefusedTextContainer = styled.div`
  margin-top: ${rem('-8px')};
  @media (max-width: calc(${breakpoints.sm} - 1px)) {
    margin-top: ${rem('3px')};
  }
`

export const ProposalContainer = styled.div`
  display: grid;
  flex-direction: grid;
  grid-template-columns: 1fr ${rem('216px')};
  gap: 1rem;
  background-color: var(--porto-saude-05);
  padding: ${rem('34px')} ${rem('28px')} ${rem('8px')} ${rem('28px')};
  border-radius: 8px;

  & > button {
    height: ${rem('32px')};
  }

  @media (max-width: calc(${breakpoints.sm} - 1px)) {
    display: flex;
    flex-direction: column;
    grid-template-columns: 1fr ${rem('216px')};
    padding-bottom: ${rem('24px')};
  }
  @media (min-width: calc(${breakpoints.md})) {
    & > button {
      width: ${rem('152px')};
    }
    padding-bottom: ${rem('24px')};
  }
  @media (min-width: calc(${breakpoints.lg})) {
    & > button {
      width: ${rem('152px')};
      margin-top: ${rem('-60px')};
    }
    padding-bottom: ${rem('0px')};
  }
`

export const KeyImageContainer = styled.div`
  text-align: center;
`

export const KeyImage = styled.img`
  width: ${rem('216px')};
  height: ${rem('216px')};
  @media (max-width: calc(${breakpoints.sm} - 1px)) {
    width: ${rem('169px')};
    height: ${rem('169px')};
  }
`
