import styled from 'styled-components'
import { css } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/_shared'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'

export const Container = styled.div`
  height: 100%;
  padding: 0 12px;
`

export const Wrapper = styled.div`
  border-radius: 12px;
  border: 1px solid var(--system-primitive-neutral-200);
  height: 100%;
  padding: 20px 16px;
`

export const Image = styled.img`
  border-radius: 12px;
  width: 100%;
`

export const Label = styled(Typography)(
  css({ sx: { marginTop: '32px', textTransform: 'uppercase', width: '100%' } })
)

export const Title = styled(Typography)(
  css({ sx: { marginTop: '4px', width: '100%' } })
)

export const Text = styled(Typography)(css({ sx: { marginTop: '32px' } }))
