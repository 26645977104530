import { pxToRem } from '@/styles/sheets/helpers'
import breakpoints from '@pol-npm/riscos-financeiros-dashboard-ui/dist/theme/breakpoints'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Container = styled.div`
  background-color: #0a0047;
  border-radius: ${pxToRem(16)};
  display: flex;
  overflow: hidden;

  @media (max-width: calc(${breakpoints.md})) {
    flex-direction: column;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  padding: ${pxToRem(24)};
`

export const DescriptionWrapper = styled.div`
  > div {
    display: inline;
  }
`

export const Image = styled.img`
  flex: 1;
  max-height: 275px;
  max-width: 50%;
  object-fit: cover;

  @media (max-width: calc(${breakpoints.md})) {
    max-width: 100%;
  }
`

export const Button = styled(Link)`
  align-self: start;
  background-color: #fff;
  border-radius: ${pxToRem(4)};
  padding: ${pxToRem(12)};

  @media (max-width: calc(${breakpoints.md})) {
    text-align: center;
    width: 100%;
  }
`
