import api from '../index'

export async function get(id: string) {
  const response = await api.instance.v2.get(`/partners/${id}`)

  return response.data
}

export async function getPartnerList(
  name?: string,
  isBroker?: string,
  page?: number,
  limit?: number,
  document?: string,
  isDocument?: boolean,
  partner?: string
) {
  const response = await api.instance.v2.get(`/partners`, {
    params: isDocument ? {
      document,
      broker: isBroker ? isBroker : '',
      page,
      limit,
      partner
    } : {
      name,
      broker: isBroker ? isBroker : '',
      page,
      limit,
      partner
    }
  })

  return response.data
}

export async function getPartnerListCode(
  document: string,
) {
  const response = await api.instance.v2.get(`/partners/${document}`)

  return response.data
}

export async function getPartnerBond(
  id: string,
  broker?: string
) {
  const response = await api.instance.v2.get(`/partners/${id}/settings`, {
    params: {
      broker
    }
  })

  return response.data
}
