import { useContract } from '@/modules'
import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { Conditional, Content } from '@/components'
import BiometryCard from '@/components/BiometryCard'
import { Products } from '@/screens/Contracts/Products/shared/entities'
import { WizardContext } from 'react-albus'
import { Action, Actions } from '../../../Details/styles'
import { Container } from './styles'

const Biometry: React.FC<WizardContext> = ({ next }) => {
  const history = useHistory()
  const contract = useContract<Products.ESSENCIAL>()

  const goToSupport = () => {
    const link: string =
      'https://atendimento.solucoesparalocacao.com.br/hc/pt-br/requests/new'
    window.open(link, '_blank')
  }

  const goToPanel = () => {
    history.push('/')
  }

  const biometryState: Parameters<typeof BiometryCard>[0]['status'] =
    useMemo(() => {
      // TODO: Esse código pode ser melhorado
      switch (contract?.metadata?.status) {
        case '@essencial/approved':
          return 'done'
        case '@essencial/biometry-expired':
          return 'expired'
        case '@essencial/biometry-refused':
          return 'refused'
        default:
          return 'pending'
      }
    }, [contract])

  const customer = useMemo(() => {
    return contract.payload.customers.find((customer) => !!customer.responsible)
  }, [contract.payload.customers])

  return (
    <Container>
      <Conditional when={!contract?.metadata?.status}>
        <Content.Message title="Orçamento pré-aprovado." kind="success">
          Para aprovar o orçamento, precisamos confirmar a biometria facial do
          inquilino.
        </Content.Message>
      </Conditional>

      <BiometryCard responsible={customer} status={biometryState} />

      <Actions>
        <Conditional
          when={biometryState === 'refused' || biometryState === 'expired'}
        >
          <Action kind="ghost" variant="primary" onClick={goToSupport}>
            Suporte
          </Action>

          <Action icon="RefreshCcw" onClick={goToPanel}>
            Voltar para Painel
          </Action>
        </Conditional>

        <Action onClick={next} isDisabled={biometryState !== 'done'}>
          Continuar
        </Action>
      </Actions>
    </Container>
  )
}

export default Biometry
