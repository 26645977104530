const initialValues = {
  partner: '',
  susep: '',
  address: {
    city: '',
    state: '',
    number: '',
    street: '',
    zipcode: '',
    complement: '',
    neighborhood: '',
  },
  customers: [
    {
      document: '',
      email: '',
      phone: '',
      name: '',
      birthdate: '',
    },
  ],
  rent_price: '',
  rent_due_date: '5',
  contract_period_start: '',
  contract_period_end: '',
  validity_period_start: '',
  validity_period_end: '',
  estate_readjustment: '',
  coverages: {
    coverages_main: {
      rent: '',
      iptu: '',
      condominium: '',
      water: '',
      gas: '',
      electricity: '',
    },
    coverages_additionals: {
      property_damages: false,
      internal_paint: false,
      external_paint: false,
      recision_fine: false,
    },
  },
}

export default initialValues
