import { Carousel, Grid } from '@/components'
import { useCurrentOrganization } from '@/modules'
import {
  Flex,
  Typography,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import {
  Button,
  Card,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { useMemo } from 'react'
import Card1Image from '@/assets/images/porto-educ-logo.png'
import Card2Image from '@/assets/images/card-quero-treinamento-img.jpg'

import * as S from './styles'
import breakpoints from '@pol-npm/riscos-financeiros-dashboard-ui/dist/theme/breakpoints'
import { onlyNumbers } from '@/tools'

export const PortoEducSection: React.FC = () => {
  const currentOrganization = useCurrentOrganization()

  const portoEducLink = useMemo(() => {
    if (currentOrganization.type === 'REAL_ESTATE')
      return 'https://parceiros.portoseguroeducacao.com.br'

    if (currentOrganization.type === 'BROKER')
      return 'https://www.portoseguroeducacao.com.br'

    return '#'
  }, [currentOrganization.type])

  return (
    <S.Container>
      <Carousel
        settings={{
          slidesToShow: 2,
          responsive: [
            {
              breakpoint: Number(onlyNumbers(breakpoints.sm)),
              settings: {
                arrows: false,
                slidesToShow: 1,
                dots: true,
              },
            },
          ],
        }}
      >
        <Card
          sx={{
            background: 'var(--porto-primitive-off-white)',
            height: '100%',
            marginRight: {
              sm: '8px',
            },
          }}
        >
          <S.Grid columns={['1fr', '1fr 1fr']} gap="16px">
            <Flex
              sx={{ flexDirection: 'column', alignItems: 'start', gap: '8px' }}
            >
              <Typography variant="porto-title-6-bold">
                Quer vender mais com o Portal das Imobiliárias?
              </Typography>
              <Typography variant="porto-text-body-2-regular">
                Acesse materiais de suporte e trilhas de conhecimento na Porto
                Educ.
              </Typography>
              <Button kind="simple" as="a" href={portoEducLink} target="_blank">
                Acessar
              </Button>
            </Flex>
            <S.Image
              src={Card1Image}
              objectFit="contain"
              alt="Logo Porto Educ"
            />
          </S.Grid>
        </Card>
        <Card
          sx={{
            background: 'var(--porto-banking-05)',
            height: '100%',
            marginLeft: {
              sm: '8px',
            },
          }}
        >
          <S.Grid columns={['1fr', '1fr 1fr']} gap="16px">
            <Flex
              sx={{ flexDirection: 'column', alignItems: 'start', gap: '8px' }}
            >
              <Typography variant="porto-title-6-bold">
                Solicitação de treinamentos
              </Typography>
              <Typography variant="porto-text-body-2-regular">
                Você pode solicitar treinamentos sobre produtos e
                funcionalidades para aumentar sua produção.
              </Typography>
              <Button
                kind="simple"
                as="a"
                href="https://docs.google.com/forms/d/e/1FAIpQLSdTg_QO9je00CFKlU7Y1J4MsTxlK-TvUXyZuN6gwwdj5yNpXg/viewform"
                target="_blank"
              >
                Solicitar
              </Button>
            </Flex>
            <S.Image src={Card2Image} alt="pessoas trabalhando" />
          </S.Grid>
        </Card>
      </Carousel>
    </S.Container>
  )
}
