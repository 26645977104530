import { Step, Steps, Wizard, WizardContext } from "react-albus"

import PartnerRegistration from "./PartnerRegistration"
import AwaitingRegistrationComplete from "./AwaitingRegistrationComplete"
import PartnerRegistrationError from "./PartnerRegistrationError"

const Registration = () => {

  return (
    <Wizard
      render={(wizard: WizardContext) => (
        <Steps>
          <Step
            id="partner-registration"
            render={() => (
              <PartnerRegistration
                wizard={wizard}
                errorStep="partner-registration-error"
              />
            )}
          />
          <Step
            id="awaiting-registration-complete"
            render={() => (
              <AwaitingRegistrationComplete />
            )}
          />
          <Step
            id="partner-registration-error"
            render={() => (
              <PartnerRegistrationError />
            )}
          />
        </Steps>
      )}
    />
  )
}

export default Registration
