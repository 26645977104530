import { css } from 'styled-components'
import media from 'styled-media-query'
import { rem } from 'polished'

import themes from '../themes'

export default css`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 120%;
    color: ${themes.text.higher};
    font-family: ${themes.fonts.title};
  }

  h1 {
    letter-spacing: -0.8px;
    font-size: ${rem('24px')};

    ${media.greaterThan('large')`
      font-size: ${rem('36px')};
    `}

    ${media.greaterThan('huge')`
      font-size: ${rem('40px')};
    `}
  }

  h2 {
    font-size: ${rem('18px')};

    ${media.greaterThan('large')`
      font-size: ${rem('22px')};
    `}

    ${media.greaterThan('huge')`
      font-size: ${rem('30px')};
    `}
  }

  h3 {
    font-size: ${rem('20px')};

    ${media.greaterThan('huge')`
      font-size: ${rem('24px')};
    `}
  }

  h4 {
    font-size: ${rem('11px')};

    ${media.greaterThan('huge')`
      font-size: ${rem('16px')};
    `}
  }

  h5 {
    font-size: ${rem('8px')};

    ${media.greaterThan('large')`
      font-size: ${rem('10px')};
    `}

    ${media.greaterThan('huge')`
      font-size: ${rem('14px')};
    `}
  }

  h6 {
    font-size: ${rem('12px')};
  }
`
