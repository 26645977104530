import styled from 'styled-components'
import { css } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/_shared'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'

export const SWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
`

export const SSpan = styled(Typography)(css({ sx: {} }))
