import { Carousel, ParceriaPremiadaBanner } from '@/components'
import { SecondSectionCard } from '..'
import { data } from './data'
import { Container, Description, SecondSectionContainer, Title } from './styles'

const SecondSection = () => {
  const renderCarousel = () => (
    <Carousel
      settings={{
        dots: true,
        slidesToShow: 4,
        infinite: false,
        arrows: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 475,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      }}
    >
      {data.map((cardData, index) => (
        <SecondSectionCard
          key={index}
          image={cardData.image}
          backgroundColor={cardData.backgroundColor}
          title={cardData.title}
          text={cardData.text}
        />
      ))}
    </Carousel>
  )

  return (
    <SecondSectionContainer>
      {!!process.env.REACT_APP_ENABLE_PARCERIA_PREMIADA && <ParceriaPremiadaBanner />}
      <Title variant="porto-title-4-medium">Tudo o que sua imobiliária precisa está aqui!</Title>
      <Description variant="porto-text-body-1-regular">
        O Portal das Imobiliárias é um canal exclusivo de contratação das Soluções para Alugar,
        garantias locatícias que substituem o fiador e o caução e facilitam a jornada de locação de
        um imóvel.
      </Description>
      <Container>{renderCarousel()}</Container>
    </SecondSectionContainer>
  )
}

export default SecondSection
