import * as yup from 'yup'
import {
  isValidMobilePhone,
  isValidPhone,
  isValidCEP,
} from '@brazilian-utils/brazilian-utils'
import { ENUMS } from './context'
import { isCNPJ, isCPF } from 'brazilian-values'

const removeSpecialChars = (str) => (str || '').replace(/\(|\)|\s|-|\.|\//g, '')

const addressValidationSchema = yup.object({
  zipcode: yup
    .string()
    .required('Campo CEP obrigatório')
    .test('cep-test', 'Por favor, insira um CEP válido.', (str) =>
      str ? isValidCEP(str) : false
    ),
  street: yup.string().required('Campo endereco obrigatório'),
  number: yup
    .string()
    .required('Campo número obrigatório')
    .min(1, 'Campo número deve conter pelo menos 1 caractere')
    .max(6, 'Campo número deve conter no máximo 6 caracteres'),
  neighborhood: yup.string().required('Campo bairro obrigatório'),
  city: yup.string().required('Campo cidade obrigatório'),
  state: yup.string().required('Campo UF obrigatório'),
  complement: yup.string().optional(),
})

const representativeSchema = yup.object().shape({
  cpf: yup
    .string()
    .required('Campo CPF obrigatório')
    .test('cpf-test', 'Por favor, insira um CPF válido.', (v) =>
      isCPF(removeSpecialChars(v))
    ),
  name: yup
    .string()
    .required('Campo nome obrigatório')
    .min(5, 'O nome deve conter pelo menos 5 caracteres')
    .max(65, 'O nome deve conter no máximo 65 caracteres'),
  email: yup
    .string()
    .email('E-mail inválido')
    .required('Campo email obrigatório'),
  foreign: yup.boolean().optional(),
  address: addressValidationSchema.required(),
})

export const bankDataSchema = yup.object().shape({
  bankData: yup.object().shape({
    bank: yup.string().required('Campo banco obrigatório'),
    email: yup
      .string()
      .email('E-mail inválido')
      .required('Campo e-mail operacional obrigatório'),
    account: yup.string().required('Campo número da conta obrigatório'),
    accountDigit: yup
      .string()
      .max(1, 'Dígito deve conter apenas 1 caractere')
      .required('Campo dígito da conta obrigatório'),
    agency: yup.string().required('Campo agência obrigatório'),
  }),
})

export const juridicalPersonSchema = yup.object().shape({
  policyholder: yup.object().shape({
    address: addressValidationSchema.required(),
    creci: yup.string().required('Campo CRECI é obrigatório'),
    companyName: yup.string().required('Campo razão social obrigatório'),
    cityRegistration: yup
      .string()
      .required('Campo inscrição municipal é obrigatório')
      .max(15, 'Campo inscrição municipal deve conter no máximo 15 caracteres'),
    companyType: yup.string().required('Campo tipo de empresa obrigatório'),
    patrimony: yup
      .mixed()
      .oneOf(Object.keys(ENUMS.PATRIMONY), 'Tipo de patrimônio inválido')
      .required('Campo patrimônio obrigatório'),
    optingSimplesNacional: yup
      .number()
      .required('Campo Optante pelo Simples Nacional obrigatório'),
    representative: representativeSchema.required(),
  }),
})

export const legalPersonSchema = yup.object().shape({
  policyholder: yup.object().shape({
    address: addressValidationSchema.required(),
    creci: yup.string().required('Campo CRECI é obrigatório'),
  }),
})

export const personalDataSchema = yup.object().shape({
  policyholder: yup.object().shape({
    name: yup.string().required('Campo Nome obrigatório'),
    email: yup
      .string()
      .email('E-mail inválido')
      .required('Campo E-mail obrigatório'),
    phone: yup
      .string()
      .required('Telefone/celular é obrigatório.')
      .test(
        'phone-test',
        'Favor informar um telefone válido',
        (v) =>
          isValidMobilePhone(removeSpecialChars(v)) ||
          isValidPhone(removeSpecialChars(v))
      ),
    document: yup
      .string()
      .required('Documento é obrigatório.')
      .test(
        'document-test',
        'Por favor insira um documento válido.',
        (value = '') => isCPF(value) || isCNPJ(value)
      ),
    termsOfUse: yup
      .boolean()
      .oneOf(
        [true],
        'Você precisa aceitar a política de privacidade para continuar'
      ),
  }),
})
