import * as Steps from '.'

export default [
  {
    id: 'plans',
    icon: 'list',
    title: 'Orçamentos',
    description: 'Selecione a Cotação desejada.',
    render: Steps.Plans,
  },

  {
    id: 'pdf',
    icon: 'file-text',
    title: 'Envio do PDF da apólice',
    description:
      'Selecione para quem o PDF da apólice deve ser enviado, por email.',
    render: Steps.Pdf,
  },

  {
    id: 'addresses',
    icon: 'map-pin',
    title: 'Endereços adicionais',
    description: 'Endereços adicionais de cobrança e correspondência.',
    render: Steps.Addresses,
  },

  {
    id: 'customers',
    icon: 'users',
    title: 'Beneficiários',
    description: 'Adicione beneficiários ao seguro.',
    render: Steps.Customers,
  },

  {
    id: 'payments',
    icon: 'dollar-sign',
    title: 'Formas de pagamento',
    description: 'Selecione a forma de pagamento para finalizar.',
    render: Steps.Payments,
  },
]
