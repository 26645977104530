import { Step, Steps, Wizard } from 'react-albus'

import steps from './Steps/steps'

const Proposal = () => {
  return (
    <Wizard>
      <Steps>
        {steps.map(({ render, ...step }) => (
          <Step key={step.id} {...step} render={render} />
        ))}
      </Steps>
    </Wizard>
  )
}

export default Proposal
