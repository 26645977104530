import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import {
  capitalizacaoContent,
  fiancaLocaticiaContent,
  homeEquityContent,
  seguroIncendioContent,
  cartaoPortoContent,
} from './data'
import StaticPage from '@/modules/static-page'
import { useCurrentOrganization } from '@/modules'

const productsEnums = {
  'seguro-incendio': seguroIncendioContent,
  'fianca-locaticia': fiancaLocaticiaContent,
  'titulo-de-capitalizacao': capitalizacaoContent,
  'cartao-porto': cartaoPortoContent,
  'home-equity': homeEquityContent,
}

const AboutProducts = () => {
  const [page, setPage] = useState(null)
  const { id } = useParams<{ id: string }>()
  const profile = useCurrentOrganization()

  const history = useHistory()

  useEffect(() => {
    const _page = productsEnums[id]

    if (!_page) {
      history.push('/404')
      return
    }

    if (id === 'cartao-porto' && profile.type === 'BROKER') {
      history.push('/404')
      return
    }

    setPage(_page)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [id])

  if (!page) {
    return null
  }

  return <StaticPage page={page} />
}

export default AboutProducts
