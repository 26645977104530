const patterns = {
  '#': /\d/,
  x: /\w/,
  '9': 9,
  '+': /[1-9]/,
}

export default function theMask(value: string, mask: string | string[]) {
  if (!mask) return false

  let currentMask = mask

  if (Array.isArray(currentMask)) {
    const masks = currentMask.sort((a, b) => a.length - b.length)

    currentMask =
      masks.find(currentMask => value.length <= currentMask.length) || masks[masks.length - 1]
  }

  return Array.from(currentMask).map(char => patterns[char] || char)
}
