import styled from 'styled-components'

// necessário para ocultar a interface do componente Organization
export const OrganizationWrapper = styled.div`
  & header, & aside {
    display: none;
  }

  & input {
    width: 100%;
  }

  & > div > div {
    padding: 0 !important;

    & > div {
      padding: 0 !important;

      & > div > div > div {
        width: auto;
      }
    }
  }
`
export const Container = styled.div`
  &:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    background: white;
    bottom: 0;
    right: 0;
    z-index: -1;
  }
`
