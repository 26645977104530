import { Box, Flex, Typography } from "@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base"

import { Forms } from "@/components"

import { AddressForm, Card } from "./../../ui"

const LegalPersonForm = ({
  form,
}) => {
  return (
    <>
      <Card>

        <Typography
          variant="porto-title-4-semibold"
          content="Imobiliária"
        />

        <Box
          sx={{
            marginTop: "2rem",
          }}
        >
          <Typography
            variant="porto-title-6-medium"
            content="Dados básicos"
          />
        </Box>

        <Flex
          sx={{
            marginTop: "1rem",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: {
                xs: "1fr",
                md: "repeat(3, 1fr)",
              },
              gap: "1rem",
            }}
          >
            <Forms.InputGroup
              name="policyholder.creci"
              label="Número de inscrição do CRECI"
              placeholder="Digite"
            />
          </Box>
        </Flex>

        <Box
          sx={{
            marginTop: "2rem",
          }}
        >
          <Typography
            variant="porto-title-6-medium"
            content="Endereço"
          />
        </Box>

        <Box
          sx={{
            marginTop: "1rem",
          }}
        >
          <AddressForm
            formField="policyholder.address"
          />
        </Box>

      </Card>
    </>
  )
}

export default LegalPersonForm
