import { statusValues } from '@/data/capitalizacao'
import { useContract } from '@/modules'
import { Flex, Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Card, Tag } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { Contract, Info, InsuranceBroker, Owner, Property, Tenant } from './components'
import { TStatusProps } from './interfaces'

export const Third = ({ tenantProps }) => {
  const contract = useContract()
  const statusEnums = statusValues[contract.metadata.status] as TStatusProps

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '3rem',
      }}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          gap: '1.5rem',
        }}
      >
        {statusEnums?.label && (
          <Tag data-test-id="contract-status" color={statusEnums.label.color} type={statusEnums.label.type}>
            {statusEnums.label.text}
          </Tag>
        )}
        <Typography
          data-test-id="contract-proposal"
          content={`Proposta nº ${contract?.policy?.proposal || '---------'}`}
          variant="porto-title-5-bold"
        />
        {statusEnums?.info && <Info />}
      </Flex>
      <Contract />
      <InsuranceBroker />
      <Tenant {...tenantProps} />
      <Property />
      <Owner />
    </Card>
  )
}
