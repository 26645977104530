import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div``

export const List = styled.div`
  display: grid;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-columns: repeat(2, 1fr);

  ${media.greaterThan('large')`
    grid-column-gap: 24px;
    grid-template-columns: repeat(3, 1fr);
  `}
`
