import styled from 'styled-components'
import { css } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/_shared'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Button } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'

export const Action = styled(Button)(
  css({
    sx: {
      width: { sm: '100% !important', md: 'unset !important' },
    },
  })
)

export const Title = styled(Typography)(
  css({ sx: { marginTop: '40px', width: '100%' } })
)

export const Divider = styled.hr`
  border-color: var(--system-border-neutral);
  margin: 2rem 0;
`

export const LinkLabel = styled(Typography)``
