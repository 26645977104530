import React from 'react'
import { useRouteMatch, useLocation } from 'react-router-dom'

import { ITabsHeadProps } from './interfaces'

import { Container, List, Item, Icon, Title } from './styles'

const Head: React.FC<ITabsHeadProps> = (props) => {
  const match = useRouteMatch()

  return (
    <Container>
      <List numberOfItems={props.items.length}>
        {props.items.map((item) => (
          <Item
            key={item.path}
            to={match.url + (item.path === '/' ? '' : item.path)}
          >
            {item.icon && <Icon name={item.icon} />}

            <Title>{item.title}</Title>
          </Item>
        ))}
      </List>
    </Container>
  )
}

export default Head
