import api from '../index'

export async function get() {
  try {
    const response = await api.instance.v1.get('/invites')

    return response.data
  } catch (error) {
    throw error
  }
}

export async function refuse(id: string) {
  try {
    const response = await api.instance.v1.delete(`/invites/${id}`)

    return response.data
  } catch (error) {
    throw error
  }
}

export async function accept(id: string) {
  try {
    const response = await api.instance.v1.put(`/invites/${id}/accept`)

    return response.data
  } catch (error) {
    throw error
  }
}
