import { BRLMoneyToNumber } from '@/tools'
import dayjs from 'dayjs'

const formatDate = (date: string) => {
  const value = date?.split('/').reverse().join('-')

  if (!value) return

  return dayjs(value).format('YYYY-MM-DD')
}

const onlyDigits = value => String(value).replace(/\D/g, '')

export const formatFirstStepContract = ({ values, hideBrokerAndPartner = false }) => ({
  ...(!hideBrokerAndPartner && {
    susep: values.susep,
    partner: values.partner,
  }),
  customer: {
    ...values.customer,
    birthdate: formatDate(values.customer.birthdate),
    document: onlyDigits(values.customer.document),
    complement_document_emission: formatDate(values.customer.complement_document_emission),
    phone: onlyDigits(values.customer.phone),
    pep: {
      ...values.customer.pep,
      ...(values.customer.pep.document && {
        document: onlyDigits(values.customer.pep.document),
      }),
    },
  },
  contract: {
    ...values.contract,
    monthly_value: BRLMoneyToNumber(values.contract.monthly_value),
    total_value: BRLMoneyToNumber(values.contract.total_value),
  },
})

export const formatSecondStepContract = ({ values }) => ({
  estate: {
    ...values.estate,
    address: {
      ...values.estate.address,
      zipcode: onlyDigits(values.estate.address.zipcode),
    },
  },
  landlord: {
    ...values.landlord,
    phone: onlyDigits(values.landlord.phone),
    birthdate: formatDate(values.landlord.birthdate),
    document: onlyDigits(values.landlord.document),
    address: {
      ...values.landlord.address,
      zipcode: onlyDigits(values.landlord.address.zipcode),
    },
  },
})
