import { themes } from '@/styles'
import { pxToRem } from '@/styles/sheets/helpers'
import styled from 'styled-components'

export const Content = styled.div`
  color: #0a0047;
  font-family: ${themes.fonts.text};
  font-size: ${pxToRem('13px')};
  font-style: normal;
  line-height: ${pxToRem('24px')};
  letter-spacing: ${pxToRem('0.26px')};

  > ul {
    margin-left: ${pxToRem('24px')};
  }
`
