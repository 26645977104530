import * as AesJs from 'aes-js'
import { createHash } from 'crypto-browserify'
import { Buffer } from 'buffer'

const cryptoKey = 'solucoesparalocacao'

const getEncryptedKeyBuffer = (key: string) => {
  const key256 = createHash('sha256').update(key).digest('hex')

  const buffer = Buffer.from(key256.toString(), 'hex')
  return new Uint8Array(buffer).slice(0, 16)
}

export function encrypt(text: string) {
  if (!text || !cryptoKey) return ''

  const cleanedText = text
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/([^\w]+|\s+)/g, '')
    .replace(/\-\-+/g, '')
    .replace(/(^-+|-+$)/, '')
    .replace('-', '')
  const textAsBytes = AesJs.utils.utf8.toBytes(cleanedText)
  const aes = new AesJs.AES(getEncryptedKeyBuffer(cryptoKey))
  const encryptedBytes = aes.encrypt(AesJs.padding.pkcs7.pad(textAsBytes))

  return AesJs.utils.hex.fromBytes(encryptedBytes)
}
