import * as yup from 'yup'

const applyIsRequired = (schema: yup.ArraySchema<yup.StringSchema>) =>
  schema
    .min(1, 'Selecione ao menos uma SUSEP para o Seguro Incêndio')
    .required('Selecione ao menos uma SUSEP para o Seguro Incêndio')

export const validationSchema = yup.object({
  porto_aluguel: yup.object({
    basic: yup.object({
      enabled: yup.boolean(),
    }),
    suseps: yup
      .array()
      .of(yup.string())
      .when('$porto_aluguel.basic.enabled', {
        is: true,
        then: (schema) =>
          schema
            .min(1, 'Selecione ao menos uma SUSEP para o Fiança Locatícia')
            .required('Selecione ao menos uma SUSEP para o Fiança Locatícia'),
      }),
  }),
  porto_capitalizacao: yup.object({
    enabled: yup.boolean(),
    suseps: yup
      .array()
      .of(yup.string())
      .when('$porto_capitalizacao.enabled', {
        is: true,
        then: (schema) =>
          schema
            .min(
              1,
              'Selecione ao menos uma SUSEP para o Título de Capitalizacao'
            )
            .required(
              'Selecione ao menos uma SUSEP para o Título de Capitalizacao'
            ),
      }),
  }),
  porto_imobiliaria: yup.object({
    residential: yup.object({
      enabled: yup.boolean(),
    }),
    commercial: yup.object({
      enabled: yup.boolean(),
    }),
    suseps: yup
      .array()
      .of(yup.string())
      .when('$porto_imobiliaria.residential.enabled', {
        is: true,
        then: applyIsRequired,
      })
      .when('$porto_imobiliaria.commercial.enabled', {
        is: true,
        then: applyIsRequired,
      }),
  }),
})
