import styled from 'styled-components'
import media from 'styled-media-query'

import { themes } from '@/styles'

export const Container = styled.div`
  background-color: ${themes.page.colors.content};
`

export const Content = styled.main`
  margin: 20px;
  min-height: calc(100vh - 60px);

  ${media.greaterThan('large')`
    width: 900px;
    margin: 0 auto;
    padding: 30px 0 100px;
  `}

  ${media.greaterThan('huge')`
    width: 1100px;
    padding: 40px 0 200px;
    min-height: calc(100vh - 80px);
  `}
`
