import cardImage1 from '@/assets/images/lp-card1.png'
import cardImage2 from '@/assets/images/lp-card2.png'
import cardImage3 from '@/assets/images/lp-card3.png'
import cardImage4 from '@/assets/images/lp-card4.png'

export const data = [
  {
    image: cardImage1,
    label: 'Garantia de aluguel',
    title: 'Fiança Locatícia',
    text: 'O Fiança Locatícia é um seguro completo que substitui o fiador ou caução e ainda tem uma série de coberturas adicionais para o imóvel comercial ou residencial alugado. São vantagens para todos os lados!',
  },
  {
    image: cardImage2,
    label: 'Garantia de aluguel',
    title: 'Título de Capitalização',
    text: 'Garantia locatícia que substitui o fiador e permite o resgate do valor ao final do contrato. O inquilino ainda concorre a prêmios em dinheiro todos os meses.',
  },
  {
    image: cardImage3,
    label: 'Seguro Obrigatório',
    title: 'Seguro Incêndio',
    text: 'Seguro obrigatório, complementar às garantias. Garante proteção contra incêndio e outros danos materiais.',
  },
  {
    image: cardImage4,
    label: 'Liberação de Crédito',
    title: 'Empréstimo com Garantia de Imóvel',
    text: 'Seus clientes contam com ótimas taxas e condições de pagamento e você é comissionado a cada novo contrato.',
  },
]
