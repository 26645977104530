import React from 'react'

import { useLockBodyScroll } from '@/tools'

import { Icons, Conditional } from '../..'

import * as S from './styles'

export type LoaderProps = {
  message?: string
}

const Loader = ({ message }: LoaderProps) => {
  useLockBodyScroll()

  return (
    <S.Container>
      <S.Content>
        <S.Icon>
          <Icons.Loader />
        </S.Icon>

        <Conditional when={!!message}>
          <S.Message>{message}</S.Message>
        </Conditional>
      </S.Content>
    </S.Container>
  )
}

export default Loader
