import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import * as S from './styles'
import parceriaPremiada from '@/assets/images/parceria-premiada.png'
import { useCurrentOrganization } from '@/modules'

export const Banner = () => {
  const { type } = useCurrentOrganization()
  const isBroker = type === 'BROKER'
  const brokerText =
    'Se 50% da produção total da sua imobiliária parceira for pelo Portal das Imobiliárias, você ganhará um bônus de 10 números da sorte.'
  const realEstateText =
    'Ao somar 10 contratos de Fiança Locatícia e Título de Capitalização no mês, seus números da sorte serão dobrados. E mais! A partir daí, cada novo contrato valerá dobrado também: você receberá dois números da sorte por contrato.'

  return (
    <S.Container>
      <S.Wrapper>
        <Typography
          content="Dobre seus números da sorte!"
          variant="porto-title-3-bold"
          color="#0A0047"
        />
        <S.DescriptionWrapper>
          <Typography
            content={isBroker ? brokerText : realEstateText}
            variant="porto-text-body-2-regular"
            color="#0A0047"
          />
        </S.DescriptionWrapper>
      </S.Wrapper>
      <S.Image src={parceriaPremiada} />
    </S.Container>
  )
}
