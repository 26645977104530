import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Loader } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { SetPaidButtonContainer } from './styles'

export const SetPaidButton = ({
  isLoading,
  onClick,
}: {
  isLoading: boolean
  onClick: () => void
}) => {
  return (
    <SetPaidButtonContainer onClick={!isLoading ? onClick : null}>
      <Typography
        variant="porto-text-link-body-2-regular"
        color="system-text-highlight"
        content="Marcar como pago"
      />
      {isLoading && (
        <Loader
          style={{ position: 'absolute', right: 0 }}
          color="system-text-highlight"
        />
      )}
    </SetPaidButtonContainer>
  )
}
