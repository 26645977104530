export const icons = {
    '0001': 'flame-outline',
    '0222': 'bed-outline',
    '0002': 'flash-outline',
    '0024': 'car-outline',
    '0064': 'thunderstorm-outline',
    '0010': 'medkit-outline',
    '0004': 'home-outline',
    '0005': 'megaphone-outline',
}
  