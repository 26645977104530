import { useEffect } from 'react'
import { useFormikContext } from 'formik'

const ErrorFocus = () => {
  const { isSubmitting, isValidating, errors } = useFormikContext()

  const keyify = (obj, prefix = '') =>
    Object.keys(obj).reduce((res, el) => {
      if (Array.isArray(obj[el])) {
        return res
      } else if (typeof obj[el] === 'object' && obj[el] !== null) {
        return [...res, ...keyify(obj[el], prefix + el + '.')]
      } else {
        return [...res, prefix + el]
      }
    }, [])

  useEffect(() => {
    const keys = keyify(errors)

    if (keys.length > 0 && isSubmitting && !isValidating) {
      const errorElement = document.querySelector(`[name="${keys[0]}"]`)
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
        // setTimeout(() => {
        //   document.getElementsByName(keys[0])[0].focus()
        // }, 1000)
      }
    }
  }, [isSubmitting, isValidating, errors])

  return null
}

export default ErrorFocus
