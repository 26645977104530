import styled from 'styled-components'

export const Suggestion = styled.button`
  cursor: pointer;
  width: 100%;
  border-radius: .25rem;
  padding: .5rem;
  border: none;
  background-color: var(--system-surface-secondary);
  text-align: left;

  &:hover {
    background-color: var(--system-surface-action-tertiary-pressed);
  }

  &:disabled {
    background-color: var(--system-surface-secondary);
    cursor: inherit;
  }
`
