import styled from 'styled-components'
import { css } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/_shared'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import breakpoints from '@pol-npm/riscos-financeiros-dashboard-ui/dist/theme/breakpoints'

export const ThirdSectionContainer = styled.div`
  padding: 0px 108px 136px;

  @media (max-width: calc(${breakpoints.sm} - 1px)) {
    padding: 32px 12px;
  }
`

export const Title = styled(Typography)(
  css({
    sx: {},
  })
)

export const BannersGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  margin-top: 32px;

  @media (max-width: ${breakpoints.sm}) {
    grid-template-columns: 1fr;
  }
`
