import { Card, CardImage, CardText, CardTitle } from './styles'

const SecondSectionCard = ({
  image,
  title,
  text,
  backgroundColor,
}: {
  image: string
  backgroundColor?: string
  title: string
  text: string
}) => {
  return (
    <Card>
      <CardImage src={image} bgColor={backgroundColor} />
      <CardTitle variant="porto-text-body-1-bold" content={title} />
      <CardText variant="porto-text-body-2-regular" content={text} />
    </Card>
  )
}

export default SecondSectionCard
