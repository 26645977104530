import { Shimmer } from '@/components'
import { DetailedHTMLProps, ImgHTMLAttributes, useState } from 'react'
import { ShimmerProps } from '../Shimmer'

type ImageProps = DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>

interface LazyImageProps extends Omit<ImageProps, 'width' | 'height'> {
  fallbackHeight?: ShimmerProps['$height']
  fallbackWidth?: ShimmerProps['$width']
  alt: string
}

export default function LazyImage({
  fallbackHeight,
  fallbackWidth,
  alt,
  ...defaultProps
}: LazyImageProps) {
  const [loaded, setLoaded] = useState(false)

  const imageLoader = new Image()

  imageLoader.src = defaultProps.src

  imageLoader.onload = () => {
    setLoaded(true)
  }

  if (!loaded) {
    return <Shimmer $width={fallbackWidth} $height={fallbackHeight} />
  }

  return <img {...defaultProps} alt={alt} />
}
