import product from './product'
import suseps from './suseps'
import payments from './payments'
import claim from './claim'

export default {
  ...product,
  ...suseps,
  ...payments,
  ...claim,
}
