import { birthdateValidator } from '@/tools/validators'
import * as yup from 'yup'
import { BRLMoneyToNumber } from '@/tools'
import { isCPF } from 'brazilian-values'

export default yup.object().shape({
  cpf: yup
    .string()
    .required('Este campo precisa ser preenchido.')
    .test('cpf-validation', 'CPF inválido', value => !!value && isCPF(value)),
  name: yup
    .string()
    .required('Este campo precisa ser preenchido.')
    .test('name_and_lastname', 'Nome e sobrenome requeridos.', (val = '') => {
      const isThereTwoWordWithAnSpaceBetween = (fullName: string) => {
        return /^[A-zÀ-ÿ]{2,}\s{1,}[A-zÀ-ÿ]{1,}[A-zÀ-ÿ\s]{1,}$/gm.test(fullName)
      }

      return isThereTwoWordWithAnSpaceBetween(val)
    }),
  birthdate: yup
    .string()
    .required('Data de nascimento inválida.')
    .test('birthdate-validation', 'Data de nascimento inválida.', birthdateValidator),
  marital_status: yup.string().required('Informe o estado civil'),
  occupation: yup.string().required('Informe a ocupação'),
  income: yup
    .string()
    .required('Este campo precisa ser preenchido.')
    .test(
      'min_value',
      'Valor mínimo de R$ 100,00',
      val => typeof val !== 'undefined' && BRLMoneyToNumber(val) >= 100,
    ),
  email: yup.string().required('Este campo precisa ser preenchido.').email('E-mail válido.'),
  cell_phone: yup
    .string()
    .required('Este campo precisa ser preenchido.')
    .matches(/\(\d{2,}\) \d{4,}\-\d{4}/g, 'Telefone inválido.'),
})
