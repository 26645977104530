import { api } from '@/services'

interface Personalization {
  id: string
  url_logo: string
  broker: string
}

export async function getPersonalization(prefix: string) {
  const { data } = await api.instance.v2.get<Personalization>(
    `/white-label/url-prefix/${prefix}`
  )

  return data
}
