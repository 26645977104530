import styled from 'styled-components'
import media from 'styled-media-query'
import { rem } from 'polished'

import { themes } from '@/styles'

export const Container = styled.div``

export const Body = styled.div`
  margin-top: 16px;
`

export const Message = styled.div`
  color: ${themes.text.high};
  font-size: ${rem('10px')};
  font-family: ${themes.fonts.title};

  ${media.greaterThan('huge')`
    font-size: ${rem('12px')};
  `}
`
