import {
  Action,
  Container,
  Icon,
  Content,
  Loader,
  Title,
  Wrapper,
  WrapperContent,
  IconAction,
} from './styles'
import { IMessageProps } from './interfaces'
import { Conditional, Tooltip } from '@/components/Utilities'

const Message = (props: IMessageProps) => {
  return (
    <Container kind={props.kind} {...props}>
      {props.isLoading ? <Loader /> : props.icon && <Icon name={props.icon} />}

      <WrapperContent>
        <Wrapper>
          <Title>{props.title}</Title>
          <Content>{props.children}</Content>
        </Wrapper>
        <Conditional when={!!props.iconAction}>
          <Action onClick={props.action}>
            <Tooltip placement="bottom"  content={"Baixar"} >
            <IconAction name={props.iconAction} />
            </Tooltip>
          </Action>
        </Conditional>
      </WrapperContent>
    </Container>
  )
}

Message.defaultProps = {
  kind: 'info',
  icon: 'information-circle-outline',
}

export default Message
