import { Container, Image, Label, Text, Title, Wrapper } from './styles'

const FifthSectionCard = ({
  image,
  label,
  title,
  text,
}: {
  image: string
  label: string
  title: string
  text: string
}) => (
  <Container>
    <Wrapper>
      <Image src={image} />
      <Label
        color="system-primitive-neutral-700"
        variant="porto-text-overline"
        content={label}
      />
      <Title variant="porto-title-6-bold" content={title} />
      <Text variant="porto-text-body-1-regular" content={text} />
    </Wrapper>
  </Container>
)

export default FifthSectionCard
