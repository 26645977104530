import { createEvent } from 'effector'

import { SplashStore } from '../store'

export const toggle = createEvent()

SplashStore.on(toggle, (state) => {
  return {
    ...state,
    isVisible: !state.isVisible,
  }
})
