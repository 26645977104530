import * as yup from 'yup'

const address = yup.object().shape({
  zipcode: yup.string().min(9, 'Por favor insira um CEP válido.'),
  street: yup.string(),
  number: yup.string(),
  complement: yup.string(),
  neighborhood: yup.string(),
  city: yup.string(),
  state: yup.string(),
})

export const addresses = yup.object().shape({
  billing: address,
  mailing: address,
})
