import breakpoints from '@pol-npm/riscos-financeiros-dashboard-ui/dist/theme/breakpoints'
import styled from 'styled-components'

export const Container = styled.div`
  &:before {
    content: '';
    background: white;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }
`

export const List = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  margin-top: 28px;

  @media (max-width: ${breakpoints.sm}) {
    flex-direction: column;
  }
`
