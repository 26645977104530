import { useFormik, FormikProvider } from 'formik'

import { Conditional, Content, Grid, Modals } from '@/components'

import { Actions, Action } from '../../../Details/styles'
import { useContract } from '@/modules'

import * as S from './styles'
import Register from './Register'
import { formatToCPF, isCNPJ, isCPF } from 'brazilian-values'

interface ICustomers {
  initialValues: {
    name: string
    document: string
    address: {
      zipcode: string
      street: string
      number: string
      complement: string
      neighborhood: string
      city: string
      state: string
    }
  }
}

const Customers = ({ wizard }) => {
  const contract = useContract()
  const form = useFormik({
    initialValues: {
      customers: contract.payload.contract.beneficiaries || [],
    },
    onSubmit: (beneficiaries) => {
      contract.update({
        contract: {
          ...contract.payload.contract,
          beneficiaries: beneficiaries.customers,
        },
      })
      wizard.next()
    },
  })

  const add = async () => {
    const customer: any = await Register.openModal()

    if (!customer) return

    form.setFieldValue('customers', [
      ...form.values.customers,
      {
        ...customer,
        id: Math.random(),
      },
    ])
  }

  const edit = async (customer: ICustomers) => {
    const edited: any = await Register.openModal({
      initialValues: customer,
    })

    if (!edited) return

    const target = form.values.customers.find(
      (customer) => customer.id === edited.id
    )
    const merge = Object.assign({}, target, edited)

    form.setFieldValue('customers', [
      ...form.values.customers.filter((customer) => customer.id !== merge.id),
      merge,
    ])
  }

  const remove = async (customer: any) => {
    const confirm = await Modals.Confirmation({
      icon: 'alert-triangle',
      title: 'Remover pretendente',
      content: `Deseja realmente remover o cadastro de <b>${customer.name}</b>?`,
      submitText: 'Remover pretendente',
    })

    if (!confirm) return

    form.setFieldValue(
      'customers',
      form.values.customers.filter(({ id }) => id !== customer.id)
    )
  }

  return (
    <FormikProvider value={form}>
      <form onSubmit={form.handleSubmit}>
        <Content.Message title="Atenção" kind="warning">
          Não é obrigatório o cadastro de beneficiários.
        </Content.Message>

        <S.List>
          <Grid
            gap="1rem"
            space={['1rem 0', '2rem 0']}
            columns={['repeat(2, 1fr)', 'repeat(3, 1fr)']}
          >
            {form.values.customers.map((customer) => (
              <S.Card.Item>
                <S.Card.Detail>
                  <S.Card.Title>
                    {customer.socialName || customer.name}
                  </S.Card.Title>
                  <Conditional when={isCPF(customer.document)}>
                    <S.Card.Description>
                      CPF: {formatToCPF(customer.document)}
                    </S.Card.Description>
                  </Conditional>
                  <Conditional when={isCNPJ(customer.document)}>
                    <S.Card.Description>
                      CNPJ: {customer.document}
                    </S.Card.Description>
                  </Conditional>
                </S.Card.Detail>

                <S.Card.Actions>
                  <S.Card.Action kind="ghost" onClick={() => edit(customer)}>
                    Editar
                  </S.Card.Action>

                  <S.Card.Action
                    kind="ghost"
                    variant="danger"
                    onClick={() => remove(customer)}
                  >
                    Remover
                  </S.Card.Action>
                </S.Card.Actions>
              </S.Card.Item>
            ))}

            <S.Card.Item add onClick={add}>
              <S.Card.Detail>
                <S.Card.Title>Cadastrar beneficiário</S.Card.Title>
              </S.Card.Detail>
            </S.Card.Item>
          </Grid>
        </S.List>

        <Actions>
          <Action icon="ArrowLeft" onClick={wizard.previous} kind="smooth">
            Voltar
          </Action>

          <Action icon="ArrowRight" onClick={() => form.handleSubmit()}>
            Próxima etapa
          </Action>
        </Actions>
      </form>
    </FormikProvider>
  )
}

export default Customers
