import { createEvent, Event } from 'effector'

import store from './store'

import { IToastItem } from './types'

export const addToast: Event<IToastItem> = createEvent('addToast')

export const removeToast: Event<{ id: string }> = createEvent('removeToast')

store
  .on(addToast, (state, toast) => [...state, toast])
  .on(removeToast, (state, payload) =>
    state.filter((toast) => toast.id !== payload.id)
  )
