import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import Datepicker, { registerLocale } from 'react-datepicker'
import { rem, size, rgba } from 'polished'
import theme from 'styled-theming'
import { isMobile } from 'react-device-detect'
import { themes, layers, helpers } from '@/styles'
import ptBR from 'date-fns/locale/pt-BR'

import 'react-datepicker/dist/react-datepicker.css'

import { FeatherIcons } from '../../Icons'

registerLocale('pt-BR', ptBR)

const overrideDefaultStyle = css`
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 100%;
    height: 100%;
  }

  .react-datepicker-popper {
    z-index: ${layers.popover};

    .react-datepicker {
      ${helpers.defaultBoxShadow}

      font-family: ${themes.fonts.title};
      border-color: ${themes.utilities.border};
      background-color: ${theme('mode', {
        light: '#fbfeff',
        dark: '#1c1f25',
      })};

      .react-datepicker__triangle {
        border-top-color: ${themes.utilities.border};

        &:before {
          border-top-color: ${themes.utilities.border};
        }
      }

      .react-datepicker__navigation--next {
        color: #fff;
        border-left-color: ${themes.text.medium};
      }

      .react-datepicker__navigation--previous {
        color: #fff;
        border-right-color: ${themes.text.medium};
      }

      .react-datepicker__month-container {
        .react-datepicker__header {
          background-color: ${theme('mode', {
            light: '#fbfeff',
            dark: '#1c1f25',
          })};
          border-color: ${themes.utilities.border};

          .react-datepicker__year-select {
            all: unset;

            margin-top: 4px;

            appearance: menulist;
            color: ${themes.text.high};
          }
        }

        .react-datepicker__week {
          &:not(:last-of-type) {
            border-bottom: 1px solid ${themes.utilities.border};
          }
        }

        .react-datepicker__current-month,
        .react-datepicker__day-name,
        .react-datepicker__day {
          color: ${themes.text.high};
        }

        .react-datepicker__day {
          &:hover {
            background-color: ${themes.page.colors.content};
          }
        }

        .react-datepicker__day--disabled {
          opacity: 0.5;
        }

        .react-datepicker__day--in-range,
        .react-datepicker__day--in-selecting-range {
          background-color: ${theme('mode', {
            light: '#fbfeff',
            dark: '#1c1f25',
          })};
          color: ${themes.text.high};

          &:hover {
            background-color: ${theme('mode', {
              light: '#fbfeff',
              dark: '#1c1f25',
            })};
          }
        }

        .react-datepicker__day--keyboard-selected {
          color: #fff;
          background-color: ${themes.text.medium};
        }

        .react-datepicker__month {
          .react-datepicker__day--today {
            color: #fff;
            border-radius: 6px;
            background-color: ${themes.palletes.success};
          }

          .react-datepicker__day--selected {
            color: #fff;
            background-color: ${themes.palletes.primary};
          }
        }
      }
    }
  }
`

export const Field = styled.div`
  width: 100%;
  height: 40px;

  display: flex;
  align-items: center;

  border-radius: 8px;
  background-color: ${theme('mode', {
    light: '#fbfeff',
    dark: '#1c1f25',
  })};
  border: 1px solid ${themes.utilities.border};

  ${media.greaterThan('huge')`
    height: 55px;
  `}

  ${overrideDefaultStyle}
`

export const Prefix = styled.div`
  width: 40px;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  border-right: 1px solid ${themes.utilities.border};

  ${media.greaterThan('huge')`
    width: 55px;
  `}
`

export const Icon = styled(FeatherIcons).attrs(() => ({
  name: 'calendar',
}))`
  ${size('12px')}

  color: ${themes.palletes.primary};

  ${media.greaterThan('huge')`
    ${size('18px')}
  `}
`

export const Value = styled(Datepicker).attrs(() => ({
  locale: 'pt-BR',
  dateFormat: 'dd/MM/yyyy',
  withPortal: isMobile,
}))`
  all: unset;

  width: 100%;
  height: 100%;
  padding: 0 16px;

  box-sizing: border-box;

  font-weight: 600;
  font-size: ${rem('10px')};
  font-family: ${themes.fonts.text};

  color: ${themes.text.high};
  -webkit-text-fill-color: ${themes.text.high};

  &::placeholder {
    color: ${themes.text.low};
    -webkit-text-fill-color: ${themes.text.low};
  }

  ${media.greaterThan('huge')`
    font-size: ${rem('13px')};
  `}
`

export const Container = styled.div<any>`
  display: inline-block;

  ${(props) =>
    props.readOnly &&
    css`
      cursor: not-allowed;
    `}

  ${(props) =>
    props.error &&
    css`
      ${Field} {
        border-radius: 8px 8px 0 0;
        border-color: ${rgba(themes.palletes.danger, 0.5)};
        background-color: ${rgba(themes.palletes.danger, 0.02)};
      }

      ${Prefix} {
        border-right-color: ${rgba(themes.palletes.danger, 0.5)};
        background-color: ${rgba(themes.palletes.danger, 0.02)};
      }

      ${Icon} {
        color: ${themes.palletes.danger};
      }
    `}
`
