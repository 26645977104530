import { useState } from 'react'
import { isBrowser, isMobile } from 'react-device-detect'

import { useContract } from '@/modules'

import { Container, Group, Head, Icon, Item, Label, Panel, Title, Toggle, Value } from './styles'

import { Tooltip } from '@/components'
import { formatDate, formatMoneyBRL } from '@/tools'
import { formatToCPF } from 'brazilian-values'
import { Products } from '../../../shared/entities'
import { plansParse } from '../../Budgets/utils'

const Resume = () => {
  const contract = useContract<Products.ALUGUEL>()

  const [expanded, setExpanded] = useState(isBrowser ? true : false)

  const toggleExpandable = () => {
    setExpanded(state => !state)
  }

  const customers = contract.payload.customers
  const estate = contract.payload.estate
  const address = estate.address

  return (
    <Container>
      {isMobile && (
        <Toggle.Box expanded={expanded} onClick={toggleExpandable}>
          <Toggle.Action>
            {expanded ? 'Esconder resumo' : 'Clique aqui para visualizar resumo do orçamento'}

            <Icon name={expanded ? 'chevron-up' : 'chevron-down'} />
          </Toggle.Action>
        </Toggle.Box>
      )}

      {expanded && (
        <Panel>
          <Head>
            Resumo <Icon name="message-square" />
          </Head>

          <Group>
            <Title>
              <Icon name="hash" /> Documentos
            </Title>

            <Item>
              <Label>PAC</Label>
              <Value>{!!contract.payload.pac ? contract.payload.pac : 'Aguardando cálculo'}</Value>
            </Item>
          </Group>

          <Group>
            <Title>
              <Icon name="users" /> Pretendentes
            </Title>

            {customers.map(({ document, name }, index) => (
              <Item key={index}>
                <Value>
                  <b>{name}</b> - {formatToCPF(document)}
                </Value>
              </Item>
            ))}
          </Group>

          <Group>
            <Title>
              <Icon name="map-pin" /> Endereço de locação
            </Title>

            <Item>
              <Value>
                {address.street}, nº {address.number} {address.complement}
                <br />
                {address.neighborhood}, {address.city} - {address.state}
                <br />
                {address.zipcode}
              </Value>
            </Item>
          </Group>

          <Group>
            <Title>
              <Icon name="file-text" /> Dados do contrato
            </Title>

            <Item>
              <Label>Plano</Label>
              <Value>{plansParse[contract.payload.contract.plan.toLowerCase()]}</Value>
            </Item>

            <Item>
              <Label>Tipo do imóvel</Label>
              <Value>{contract.payload.estate.type.toLowerCase()}</Value>
            </Item>

            <Item>
              <Label>Valor do aluguel</Label>
              <Value>{formatMoneyBRL(contract.payload.coverages.rent)}</Value>
            </Item>
          </Group>

          <Group>
            <Title>
              <Tooltip
                maxWidth={300}
                content="O início de vigência deverá ser a data do protocolo na Cia. ou início do Contrato de Locação, se esta for posterior à data do protocolo. O término da vigência para seguro anual deverá ser a data do reajuste do aluguel ou o 365º dia da referia transmissão, dessa forma a cobrança do prêmio será anual, essa regra todavia, somente será aplicada quando a transmissão ou protocolo da proposta ocorrer em até 30 dias, a contas do início do contrato de locação e para seguro Plurianual não deverá ultrapassar a data final do prazo do Contrato de Locação."
              >
                <Icon name="calendar" />
              </Tooltip>
              Vigência do seguro{' '}
            </Title>

            <Item>
              <Label>Início em</Label>
              <Value>
                {formatDate(contract.payload.contract.periodContractTerm.start, {
                  timeZone: 'UTC',
                })}
              </Value>
            </Item>

            <Item>
              <Label>Fim em</Label>
              <Value>
                {formatDate(contract.payload.contract.periodContractTerm.end, {
                  timeZone: 'UTC',
                })}
              </Value>
            </Item>
          </Group>
        </Panel>
      )}
    </Container>
  )
}

export default Resume
