import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Badge } from '@/components'

import { IListProps } from './interfaces'
import { Container, Detail, Middle, Right } from './styles'

const List = (props: IListProps) => {
  const history = useHistory()

  const [pressed, setPressed] = useState(false)

  return (
    <Container
      role="button"
      aria-pressed={pressed}
      aria-haspopup={true}
      tabIndex={0}
      onKeyDown={(event) => {
        if (event.key === 'Enter' || event.key === ' ') {
          setPressed(true)
        }
      }}
      onClick={() => {
        history.push(`/orcamento/${props.batch.id}`, {
          state: props.batch.id,
          status: props.status,
        })
      }}
    >
      <Middle>
        {props.details.map((detail, index) => (
          <Detail.Item key={index}>
            <Detail.Info>
              <Detail.Label>{detail.label}</Detail.Label>
              <Detail.Value>{detail.value}</Detail.Value>
            </Detail.Info>
          </Detail.Item>
        ))}
      </Middle>
      <Right>
        <Badge
          icon={
            {
              budget: 'alert-circle',
              policy: 'alert-circle',
              budget_partial_calculated: 'alert-circle',
              budget_calculated: 'check',
              policy_partial_emitted: 'alert-circle',
              policy_emitted: 'check',
              expired_period_term: 'alert-circle',
              critical: 'alert-circle',
              failure: 'alert-circle',
              created: 'alert-circle',
            }[props.status]
          }
          removeDot
          kind={
            {
              budget: 'warning',
              policy: 'warning',
              budget_partial_calculated: 'warning',
              budget_calculated: 'success',
              policy_partial_emitted: 'success-light',
              policy_emitted: 'success',
              expired_period_term: 'danger',
              critical: 'danger',
              failure: 'danger',
              created: 'warning',
            }[props.status]
          }
        >
          {
            {
              budget: 'Em processamento',
              policy: 'Em processamento',
              budget_partial_calculated: 'Calculado parcialmente',
              budget_calculated: 'Calculado',
              policy_partial_emitted: 'Concluído parcialmente',
              policy_emitted: 'Concluído',
              expired_period_term: 'Vigência expirada',
              critical: 'Crítica',
              failure: 'Falha na Integração',
              created: 'Em processamento',
            }[props.status]
          }
        </Badge>
      </Right>
    </Container>
  )
}

export default List
