import { Grid } from '@/components'
import { useCurrentOrganization } from '@/modules'
import { api } from '@/services'
import { capitalize } from '@brazilian-utils/brazilian-utils'
import {
  DataGrid,
  GridColDef,
  GridRowSelectionModel,
  ptBR,
} from '@mui/x-data-grid'
import {
  Flex,
  Typography,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import {
  Button,
  Tag,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { formatToPhone } from 'brazilian-values'

import { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { LeadSuccess } from '../LeadSuccess'

interface ElegibilityData {
  id: string
  name: string
  email: string
  phone: string
  susep: string
  isElegibleCard: boolean
  avalibleToSend: boolean
}

const defaultColumnProps: Readonly<Partial<GridColDef>> = {
  sortable: true,
  filterable: false,
  hideable: false,
  groupable: false,
  editable: false,
  resizable: false,
}

const columns: GridColDef[] = [
  {
    ...defaultColumnProps,
    field: 'name',
    renderCell(params) {
      return capitalize(params.value)
    },
    headerName: 'Nome',
    flex: 1,
  },
  {
    ...defaultColumnProps,
    field: 'phone',
    renderCell(params) {
      return formatToPhone(params.value)
    },
    headerName: 'Telefone',
    flex: 1,
  },
  {
    ...defaultColumnProps,
    field: 'type',
    renderCell(params) {
      if (!params.value) return '-'

      return params.value
    },
    headerName: 'Tipo',
    flex: 1,
  },
  {
    ...defaultColumnProps,
    field: 'susep',
    headerName: 'Susep',
    flex: 1,
  },
  {
    ...defaultColumnProps,
    field: 'avalibleToSend',
    renderCell(params) {
      if (params.value)
        return (
          <Tag size="small" color="gray">
            Convite disponível
          </Tag>
        )

      return (
        <Tag size="small" color="green">
          Convite enviado
        </Tag>
      )
    },
    headerName: 'Status',
    flex: 1,
  },
]

export const LeadTable: React.FC = () => {
  const [selecteds, setSelecteds] = useState<GridRowSelectionModel>([])

  const currentOrganization = useCurrentOrganization()
  const elegibilityQuery = useQuery<ElegibilityData[]>(
    ['api.elegibility.listCustomers', currentOrganization.id],
    api.elegibility.listCustomers,
    {
      enabled: !!currentOrganization.id && currentOrganization.type != 'BROKER',
    }
  )

  const sendSelectedsMutation = useMutation(
    async (ids: GridRowSelectionModel) => {
      const toSend = elegibilityQuery.data.filter(
        (value) => value.avalibleToSend && ids.includes(value.id)
      )

      await api.sms.addQueueApproveCard({
        documents: toSend.map((e) => e.id),
        from: 'LEAD_TABLE',
      })

      setSelecteds([])
    }
  )

  const sendAllAvailable = useMutation(async () => {
    const toSend = elegibilityQuery.data.filter((value) => value.avalibleToSend)

    await api.sms.addQueueApproveCard({
      documents: toSend.map((e) => e.id),
      from: 'LEAD_TABLE',
    })
  })

  if (sendSelectedsMutation.isSuccess || sendAllAvailable.isSuccess)
    return <LeadSuccess />

  return (
    <>
      <Typography
        as="h1"
        variant="porto-title-3-bold"
        sx={{ display: 'block' }}
      >
        Cartão de Crédito
      </Typography>
      <Typography
        as="h2"
        variant="porto-text-body-1-regular"
        sx={{ display: 'block', marginBottom: '44px' }}
      >
        Nova indicação
      </Typography>
      <Typography
        as="p"
        variant="porto-text-body-1-regular"
        sx={{ display: 'block', marginBottom: '24px' }}
      >
        Confira a lista dos seus clientes que são pré-aprovados para solicitar o
        Cartão de Crédito Porto Bank.
      </Typography>

      <DataGrid
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        autoHeight
        loading={elegibilityQuery.isLoading}
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        columns={columns}
        checkboxSelection
        disableRowSelectionOnClick
        disableColumnMenu
        rows={elegibilityQuery.data || []}
        columnHeaderHeight={40}
        rowHeight={40}
        onRowSelectionModelChange={setSelecteds}
        rowSelectionModel={selecteds}
        sx={{
          '.MuiCircularProgress-root': {
            color: 'var(--system-surface-action-primary)',
          },
          '.MuiDataGrid-cell': {
            fontSize: '.778rem',
          },
          '.MuiDataGrid-columnHeaders': {
            background: 'var(--system-primitive-neutral-100)',
          },
          '.MuiDataGrid-columnHeaderTitle': {
            fontSize: '.778rem',
            fontWeight: 'bold',
          },
          '.MuiDataGrid-root': {
            borderRadius: 4,
          },
          '.MuiDataGrid-virtualScroller': {
            minHeight: 40,
          },
        }}
      />

      <Flex sx={{ gap: '16px', marginTop: '16px' }}>
        <Button
          kind="ghost"
          isDisabled={
            !selecteds.length ||
            sendSelectedsMutation.isLoading ||
            sendAllAvailable.isLoading
          }
          isLoading={sendSelectedsMutation.isLoading}
          onClick={() => sendSelectedsMutation.mutateAsync(selecteds)}
        >
          Indicar selecionados
        </Button>

        <Button
          isDisabled={
            sendSelectedsMutation.isLoading || sendAllAvailable.isLoading
          }
          isLoading={sendAllAvailable.isLoading}
          onClick={() => sendAllAvailable.mutateAsync()}
        >
          Indicar todos disponíveis
        </Button>
      </Flex>
    </>
  )
}
