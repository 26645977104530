import styled from 'styled-components'
import media from 'styled-media-query'
import { rem } from 'polished'
import { themes } from '@/styles'

export const Budgets = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 5px;

  ${media.lessThan('huge')`
    grid-template-columns: repeat(2, 1fr);
  `}

  @media (min-width: 1440px) and (max-width: 1700px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1700px) {
    grid-template-columns: repeat(3, 1fr);
  }
`

export const Description = styled.p`
  margin: ${rem('15px')} 0 ${rem('8px')} ${rem('24px')};

  font-weight: 600;
  font-size: ${rem('14px')};

  color: ${themes.text.high};

  ${media.lessThan('huge')`
    font-size: ${rem('12px')};
  `}
`

export const Link = styled.a`
  padding: 4px;
  color: ${themes.palletes.primary};

  cursor: pointer;

  &:hover {
    color: #0092c9;
  }
`
