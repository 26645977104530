import { Box } from "@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base"
import { Card } from './Card'
import { BaseCard } from "../BaseCard"
import { IAlsoOfferProps } from "./interfaces"
import { CardSac } from "@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto"

export const AlsoOffer = ({
  title,
  values,
  variant = "primary",
  columns,
}: IAlsoOfferProps) => (
  <BaseCard
    title={title}
    variant={variant}
  >
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: { xs: "1fr", lg: `repeat(${columns || values.length}, 1fr)` },
        gap: "1rem",
      }}
    >
      {values.map((card, i) => (
        <CardSac
          key={i}
          {...card}
        />
      ))}
    </Box>
  </BaseCard>
)
