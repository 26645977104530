import { RentItem } from '@/services/api/rentCollection'
import { parseCurrency } from '@brazilian-utils/brazilian-utils'
import { dataTable } from './data-table'

export function sheetToRentItemMapper(jsonSheetObject: object): RentItem {
  const jsonSheetObjectEntries = Object.entries(jsonSheetObject)

  const [, document] = jsonSheetObjectEntries.find(
    ([key]) => key === dataTable.find((item) => item.key === 'document').header
  )
  const [, tenant] = jsonSheetObjectEntries.find(
    ([key]) => key === dataTable.find((item) => item.key === 'tenant').header
  )
  const [, tenantSocialName] = jsonSheetObjectEntries.find(
    ([key]) =>
      key === dataTable.find((item) => item.key === 'tenantSocialName').header
  )
  const [, phones] = jsonSheetObjectEntries.find(
    ([key]) => key === dataTable.find((item) => item.key === 'phones').header
  )
  const [, address] = jsonSheetObjectEntries.find(
    ([key]) => key === dataTable.find((item) => item.key === 'address').header
  )
  const [, number] = jsonSheetObjectEntries.find(
    ([key]) => key === dataTable.find((item) => item.key === 'number').header
  )
  const [, complement] = jsonSheetObjectEntries.find(
    ([key]) =>
      key === dataTable.find((item) => item.key === 'complement').header
  )
  const [, city] = jsonSheetObjectEntries.find(
    ([key]) => key === dataTable.find((item) => item.key === 'city').header
  )
  const [, uf] = jsonSheetObjectEntries.find(
    ([key]) => key === dataTable.find((item) => item.key === 'uf').header
  )
  const [, zipcode] = jsonSheetObjectEntries.find(
    ([key]) => key === dataTable.find((item) => item.key === 'zipcode').header
  )
  const [, dueDate] = jsonSheetObjectEntries.find(
    ([key]) => key === dataTable.find((item) => item.key === 'dueDate').header
  )
  const [, debits] = jsonSheetObjectEntries.find(
    ([key]) => key === dataTable.find((item) => item.key === 'debits').header
  )

  return {
    address,
    city,
    complement,
    debits: typeof debits === 'string' ? parseCurrency(debits) : debits,
    document: String(document),
    dueDate,
    number,
    phones: String(phones).split(','),
    tenant,
    tenantSocialName,
    uf,
    zipcode: String(zipcode),
  }
}
