import React, { useMemo } from 'react'
import { useField } from 'formik'

import Message from '../Message'

import { IDatePickerProps } from './interfaces'

import Label from '../Label'

import { Container, Field, Prefix, Icon, Value } from './styles'
import MaskedTextInput from 'react-text-mask'

const Datepicker: React.FC<IDatePickerProps> = (props) => {
  const [field, meta, helpers] = useField(props.name || '_')

  const hasError = useMemo(
    () => meta.touched && !!meta.error,
    [meta.touched, meta.error]
  )

  return (
    <Container error={hasError} readOnly={props.readOnly}>
      <Label>{props.label}</Label>

      <Field>
        <Prefix>
          <Icon />
        </Prefix>

        <Value
          {...field}
          selected={field.value}
          {...props}
          autoComplete="off"
          dropdownMode="select"
          onChange={(date) => helpers.setValue(date)}
          customInput={
            <MaskedTextInput
              {...(props.inputProps || {})}
              type="text"
              mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
            />
          }
        />
      </Field>

      {hasError && <Message kind="danger">{meta.error}</Message>}
    </Container>
  )
}

Datepicker.defaultProps = {
  label: 'Label',
  placeholderText: new Date().toLocaleDateString('pt-BR'),
}

export default Datepicker
