import { AxiosResponse } from 'axios'
import * as instances from '../instance'

type From = 'PORTO_ESSENCIAL' | 'HOME_EQUITY' | 'LEAD_TABLE'

type SendUniquePayload = {
  template: string
  from: From
  document: string
}

type AddToQueuePayload = {
  from: From
  template: string
  documents: string[]
}

type SendSmsResponse = {
  from: From
  link: string
  id: string
  date: Date
  partner: string
  susep: string
  userId: string
  template: string
}

export async function sendSmsApproveCard(
  payload: Omit<SendUniquePayload, 'template'>
) {
  const { data } = await instances.v2.post<
    SendUniquePayload,
    AxiosResponse<SendSmsResponse[]>
  >('/send/sms', {
    ...payload,
    template: 'APPROVE_CARD',
  } as SendUniquePayload)

  return data
}

export async function addQueueApproveCard(
  payload: Omit<AddToQueuePayload, 'template'>
) {
  const { data } = await instances.v2.post('/send/sms/add-queue', {
    ...payload,
    template: 'APPROVE_CARD',
  })

  return data
}
