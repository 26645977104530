import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { size, rem, rgba } from 'polished'
import { motion } from 'framer-motion'

import { Ionicons, FeatherIcons } from '../../Icons'
import { IContentProps } from './interfaces'

import { themes } from '@/styles'

export const Container = styled(motion.div)<IContentProps>`
  margin: 20px;

  border-radius: 8px;
  background-color: #fff;

  ${(props) =>
    props.size &&
    {
      small: css`
        width: 100%;

        ${media.greaterThan('large')`
          width: 488px;
          margin: 40px auto;
        `}

        ${media.greaterThan('huge')`
          width: 488px;
        `}
      `,

      medium: css`
        width: 100%;

        ${media.greaterThan('large')`
          width: 700px;
          margin: 40px auto;
        `}

        ${media.greaterThan('huge')`
          width: 700px;
        `}
      `,

      large: css`
        width: 100%;

        ${media.greaterThan('large')`
          width: 800px;
          margin: 40px auto;
        `}

        ${media.greaterThan('huge')`
          width: 1000px;
        `}
      `,
    }[props.size]}
`

export const Head = styled.div`
  padding: 32px 32px 16px;

  display: flex;
  align-items: center;

  ${media.greaterThan('huge')`
    padding: 32px 32px 40px;
  `}
`

export const CloseIconButton= styled.button`
  all: unset;
  margin-left: auto;
  cursor: pointer;
`

export const Icon = styled(Ionicons)<{ color: string }>`
  ${size('30px')}

  margin-right: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 8px;
  font-size: ${rem('14px')};
  color: ${themes.palletes.success};
  background-color: ${rgba(themes.palletes.success, 0.15)};

  ${(props) =>
    props.color &&
    {
      primary: css`
        color: ${themes.palletes.primary};
        background-color: ${rgba(themes.palletes.primary, 0.25)};
      `,
      warning: css`
        color: ${themes.palletes.warning};
        background-color: ${rgba(themes.palletes.warning, 0.25)};
      `,
    }[props.color]}

  ${media.greaterThan('huge')`
    ${size('40px')}

    font-size: ${rem('20px')};
  `}
`

export const Title = styled.p`
  font-weight: 600;
  font-size: ${rem('12px')};
  color: ${themes.text.high};

  ${media.greaterThan('huge')`
    font-size: ${rem('16px')};
  `}

  ${(props) =>
    props.color &&
    {
      primary: css`
        color: ${themes.palletes.primary};
      `,
    }[props.color]}
`

export const Loader = styled.div`
  padding-bottom: 40px;

  ${media.greaterThan('huge')`
    padding-bottom: 56px;
  `}
`

export const Body = styled.div<IContentProps>`
  padding: ${(props) => (props.padding ? '0' : '0 24px 24px')};
`

export const Footer = styled.footer`
  padding: 16px 24px;
  border-top: 1px solid rgba(179, 179, 179, 0.5);

  display: flex;
  align-items: center;
  gap: 16px;

  border-radius: 0 0 8px 8px;
`
