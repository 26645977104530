import styled from 'styled-components'

export const TopButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 24px;
`

export const EnviarPropostaButtonContainer = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;
`

export const LeftButton = styled.div`
  margin-right: auto;
`

export const ResultBaseCardFlex = styled.div`
  display: flex;
`

export const ResultLeftBox = styled.div`
  width: 40%;
`
export const ResultRightBox = styled.div`
  width: 60%;
`
export const ResultRightBoxLine = styled.div`
  margin-bottom: 15px;
`

export const ErrorButtonContainer = styled.div`
  width: 40%;
  margin-top: 24px;
`
