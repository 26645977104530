import React from 'react'

import { Portal } from 'react-portal'
import { AnimatePresence } from 'framer-motion'

import { useLockBodyScroll } from '@/tools'

import { IModalBaseProps } from './interfaces'

import { Overlay, Dialog, Modal } from './styles'

const variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
}

const Base: React.FC<IModalBaseProps> = (props) => {
  useLockBodyScroll()


  return (
    <Portal>
      <AnimatePresence>
        <Modal
          className="modal"
          id="modal"
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={variants}
          {...props}
        >
            <Overlay onClick={props.onDismiss} />

            <Dialog>{props.children}</Dialog>
        </Modal>
      </AnimatePresence>
    </Portal>
  )
}

export default Base
