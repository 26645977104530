import { FormikProvider, useFormik } from 'formik'
import { useState } from 'react'

import { Conditional, Forms, Grid, Loading, Modals } from '@/components'
import { useOrganizations } from '@/modules'
import { api } from '@/services'
import { saveAs } from 'file-saver'
import { Content } from './styles'

import schema from './schema'

const Export = ({ product, renewal_available, onDismiss }) => {
  const [status, setStatus] = useState<'loading' | 'validated' | ''>('')
  const {
    store: { active: currentProfile },
  } = useOrganizations()

  const isBroker = currentProfile.type === 'BROKER'
  const isRenewalAvailable = !renewal_available ? {} : { renewal_available }

  const onSubmit = async ({ start_date, end_date }) => {
    try {
      setStatus('loading')
      const { data: file } = await api.instance.v2.get(`/contracts`, {
        responseType: 'arraybuffer',
        params: {
          start: start_date,
          end: end_date,
          broker: isBroker,
          product: product,
          ...isRenewalAvailable,
        },
        headers: {
          Accept: 'text/csv',
        },
      })

      const blob = new Blob([file], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
      })
      saveAs(blob, 'export.xls')

      setStatus('validated')
    } catch (error) {
      setStatus('')
    }
  }

  const form = useFormik({
    initialValues: {
      start_date: '',
      end_date: '',
    },
    validationSchema: schema,
    onSubmit,
  })

  return (
    <Modals.Content
      title="Exportar"
      icon="information-circle-outline"
      size="small"
      colorIcon="primary"
      onSubmit={{ text: 'Exportar', onClick: () => form.handleSubmit() }}
      onDismiss={onDismiss}
      onCancel={{ text: 'Cancelar', onClick: onDismiss }}
    >
      <Conditional when={status === 'loading'}>
        <Loading message="Aguarde, exportando dados." />
      </Conditional>
      <Content>
        <FormikProvider value={form}>
          <Grid columns="repeat(1, 1fr)" gap="1rem">
            <Forms.Datepicker
              name="start_date"
              label="Início de vigência da renovação (Data Inicial)"
              placeholderText="Digite a data inicial"
            />
            <Forms.Datepicker
              name="end_date"
              label="Início de vigência da renovação (Data Final)"
              placeholderText="Digite a data final"
            />
          </Grid>
        </FormikProvider>
      </Content>
    </Modals.Content>
  )
}

export default Modals.Promised.createModal<{
  product
  renewal_available
  onDismiss
}>(Export)
