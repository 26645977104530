import { ReactNode } from 'react'

import { Container } from './styles'

type Props = {
  children: ReactNode
}

export default function Blank(props: Props) {
  return <Container>{props.children}</Container>
}
