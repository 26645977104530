import styled, { css } from 'styled-components'
import { size } from 'polished'

import { themes, layers, helpers } from '@/styles'

import { FeatherIcons } from '../../Icons'

import { IFloatingProps } from './interfaces'

export const Icon = styled(FeatherIcons)`
  color: #fff;
`

export const Toggle = styled.button`
  all: unset;

  ${size('56px')}
  ${helpers.defaultBoxShadow}

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  border-radius: 20px;
  background-color: ${themes.palletes.primary};

  ${Icon} {
    height: 22px;
  }
`

export const Actions = styled.div`
  position: absolute;
`

export const Action = styled.button`
  all: unset;

  ${size('48px')}
  ${helpers.defaultBoxShadow}

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 14px;

  cursor: pointer;
  background-color: #00688f;

  ${Icon} {
    height: 16px;
  }
`

export const Container = styled.div<
  Pick<IFloatingProps, 'fixed' | 'direction'>
>`
  position: relative;
  z-index: ${layers.floatActions.button};

  ${({ fixed }) =>
    fixed &&
    css`
      position: fixed;
      bottom: 24px;
      right: 24px;
    `}

  ${({ direction }) =>
    direction &&
    {
      up: css`
        ${Actions} {
          left: 0;
          bottom: 0;

          width: 100%;
          margin-bottom: 72px;
        }

        ${Action} {
          margin: 0 auto;

          &:not(:last-of-type) {
            margin-bottom: 16px;
          }
        }
      `,

      left: css`
        ${Actions} {
          top: 4px;
          right: 0;

          margin-right: 72px;

          display: inline-flex;
        }

        ${Action} {
          &:not(:last-of-type) {
            margin-right: 16px;
          }
        }
      `,
    }[direction]}
`
