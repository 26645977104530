import { useRef, useState } from 'react'
import { Flex, Box, Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Icon } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { ICollapseProps } from './interfaces'

export const Collapse = ({
  title,
  children
}: ICollapseProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const content = useRef(null)

  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  const height = isOpen ? `${content?.current?.scrollHeight}px` : '0px'

  return (
    <button
      style={{
        backgroundColor: "transparent",
        border: "none",
        borderBottom: "1px solid var(--porto-primitive-black-15)",
        cursor: "pointer",
        padding: "14px 0",
      }}
      onClick={handleToggle}
    >
      <Flex
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          textAlign: "initial",
          gap: "1rem",
        }}
      >
        <Typography
          content={title}
          variant="porto-text-body-1-bold"
          color="system-text-highlight"
        />
        <Box
          sx={{
            transform: isOpen
              ? "rotate(180deg)"
              : "rotate(0deg)",
            transition: "transform 0.3s ease-in-out",
          }}
        >
          <Icon
            name="shortarrowdown"
            color="system-text-highlight"
          />
        </Box>
      </Flex>
      <Box
        sx={{
          height,
          overflow: "hidden",
          transition: "height 0.4s ease-in-out",
        }}
        ref={content}
      >
        <Flex
          sx={{
            padding: "24px 0 14px",
            flexDirection: "column",
            textAlign: "left",
          }}
        >
          {children}
        </Flex>
      </Box>
    </button>
  )
}