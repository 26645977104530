import { Box, Flex, Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { IBaseCardProps } from './interfaces'

export const BaseCard = ({ title, subtitle, children }: IBaseCardProps) => {
  return (
    <Box
      sx={{
        backgroundColor: "var(--porto-primitive-white)",
        border: '1px solid var(--porto-primitive-black-15)',
        width: '100%',
        maxWidth: '880px',
        padding: "1.75rem",
        borderRadius: ".75rem",
      }}
    >
      <Flex
        sx={{
          flexDirection: "column",
          gap: ".5rem",
          marginBottom: "1rem",
        }}
      >
        <Typography
          content={title}
          variant="porto-title-4-medium"
          color="porto-primitive-black-85"
        />
        {subtitle && (
          <Typography
            content={subtitle}
            variant="porto-text-body-1-regular"
            color="porto-primitive-black-65"
          />
        )}
      </Flex>
      {children}
    </Box>
  )
}
