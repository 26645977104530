import React, { useState } from 'react'
import { BottomScrollListener } from 'react-bottom-scroll-listener'

import { parseExportValidate } from '@/screens/BatchBudget/utils'
import Filter from '@/screens/BatchBudget/components/Filter'
import { Conditional, Icons, Loading } from '@/components'
import Export from '@/tools/utils/export'

import {
  BodyTdStyled,
  ContainerWrapperStyled,
  EmptyWrapperStyled,
  LoadingWrapperStyled,
  TableBottomContainerStyled,
  TableWrapper,
  Header,
  ContentHeader,
  Button,
  Icon,
} from './styles'

import { IColumn, ITable } from './interface'

const TableBatch = ({
  isEmpty,
  isLoading,
  emptyData = {
    height: 400,
    title: '',
    subtitle: '',
  },
  titleHeader,
  columns,
  rows,
  batch,
  darkMode,
  fixedHeader = true,
  renderBottomElement,
  className,
  onClickRow,
  onBottomReached = () => {
    return
  },
}: ITable) => {
  const [filters, setFilters] = useState<Filter.Values>({
    order: '',
  })

  const [rowsOrder, setRowsOrder] = useState(rows)

  function sortByStatus(status: string) {
    return (a) => {
      if (a.status.props.children === status) return -1
      return 1
    }
  }

  const handleFilter = async () => {
    const newFilters = await Filter.openModal(filters)

    if (newFilters?.order) {
      setRowsOrder(rows.slice().sort(sortByStatus(newFilters?.order)))
    } else {
      setRowsOrder(rows)
    }

    setFilters(newFilters)
  }

  const handleExport = async () => {
    const budgets = await parseExportValidate(batch)

    Export(budgets)
  }

  return (
    <BottomScrollListener
      onBottom={
        isEmpty || isLoading
          ? () => {
              return
            }
          : onBottomReached
      }
    >
      <>
        <Conditional when={isLoading}>
          <LoadingWrapperStyled>
            <Loading message="Aguarde, estamos procurando os dados" />
          </LoadingWrapperStyled>
        </Conditional>

        <Conditional when={isEmpty}>
          <EmptyWrapperStyled containerHeight={emptyData.height}>
            <div className="iconBox">
              <Icons.FeatherIcons name="activity" width={50} color="#E5E5E5" />
            </div>
            <strong>{emptyData.title}</strong>
            <span>{emptyData.subtitle}</span>
          </EmptyWrapperStyled>
        </Conditional>

        <Conditional when={!isLoading && !isEmpty}>
          <>
            <Header>
              {titleHeader}
              <ContentHeader>
                <Button onClick={handleFilter}>
                  <Icon name="filter" />
                  Filtro
                </Button>
                <Button onClick={handleExport}>
                  <Icon name="file" />
                  Exportar
                </Button>
              </ContentHeader>
            </Header>
            <ContainerWrapperStyled className={`${className}`}>
              <TableWrapper
                className={`tableWrapper ${darkMode && 'dark'}`}
                cellSpacing={0}
              >
                <thead className={`${fixedHeader && 'fixed'}`}>
                  <tr>
                    {columns.map((item: IColumn) => (
                      <th
                        style={{ minWidth: `${item.width}px` }}
                        key={`header-column-${item.key}-${item.dataIndex}`}
                        className={`${fixedHeader && 'fixed'} ${
                          darkMode && 'dark'
                        }`}
                      >
                        {item.title}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="tableBody">
                  {rowsOrder.map((item: any) => (
                    <tr
                      key={`header-row-${item.key}`}
                      onClick={() => onClickRow && onClickRow(item)}
                    >
                      {columns.map((column, index) => (
                        <BodyTdStyled
                          key={`row-${index}-${item.key}`}
                          disabled={item.disabled}
                          style={{
                            color: item[column.dataIndex]?.color,
                          }}
                        >
                          {item[column.dataIndex]?.text ||
                            item[column.dataIndex]}
                        </BodyTdStyled>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </TableWrapper>
              <Conditional when={!!renderBottomElement}>
                <TableBottomContainerStyled>
                  {renderBottomElement}
                </TableBottomContainerStyled>
              </Conditional>
            </ContainerWrapperStyled>
          </>
        </Conditional>
      </>
    </BottomScrollListener>
  )
}

export default React.memo(TableBatch)
