import React from 'react'
import { useUnit } from 'effector-react'
import { AnimatePresence } from 'framer-motion'

import store from '../store'
import { removeToast } from '../actions'

import { IToastedProps } from './interfaces'

import { Container, Item, Content, Close, Icon } from './styles'

const variants = {
  hidden: { opacity: 0, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: { ease: [0.16, 1, 0.3, 1] },
  },
}

const Toasted: React.FC<IToastedProps> = (props) => {
  const toasts = useUnit(store)

  const handleCloseToast = (id) => {
    removeToast({ id })
  }

  if (toasts.length < 1) return null

  return (
    <Container position={props.position}>
      <AnimatePresence>
        {toasts.map((toast) => (
          <Item
            key={toast.id}
            onClick={() => handleCloseToast(toast.id)}
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={variants}
            {...toast.options}
          >
            <Content>{toast.message}</Content>

            <Close onClick={() => handleCloseToast(toast.id)}>
              <Icon name="close" />
            </Close>
          </Item>
        ))}
      </AnimatePresence>
    </Container>
  )
}

Toasted.defaultProps = {
  position: 'center-bottom',
}

export default Toasted
