import imobiliaria from './imobiliaria'
import aluguel from './aluguel'
import essencial from './essencial'
import capitalizacao from './capitalizacao'

export default {
  ...imobiliaria,
  ...aluguel,
  ...essencial,
  ...capitalizacao,
}
