import { Icon } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { Flex, Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import selection from '@pol-npm/riscos-financeiros-porto-icons/src/selection.json'
import { ICardProps } from './interfaces'

const nameOptions = selection.icons.map(i => i.properties.ligatures).sort()

export const Card = ({
  icon,
  iconColor = "porto-primitive-black-85",
  legend,
  link,
  subtitle,
  title,
}: ICardProps) => {
  const renderIcon = nameOptions.includes(icon)
    ? (
        <Icon
          name={icon}
          color={iconColor}
          size="32px"
        />
      )
    : (
        <Typography
          variant="porto-title-4-semibold"
          content={icon}
        />
      )

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        gap: '.5rem',
        padding: '0 1rem',
      }}
    >
      {!!icon && renderIcon}
      <Flex
        sx={{
          flexDirection: 'column',
          gap: '.25rem',
        }}
      >
        <Flex
          sx={{
            flexDirection: 'column',
          }}
        >
          {legend && (
            <Typography
              content={legend}
              variant="porto-text-body-2-regular"
              color="porto-primitive-black-70"
            />
          )}
          <Typography
            content={title}
            variant="porto-text-body-1-bold"
          />
        </Flex>
        <Flex
          sx={{
            flexDirection: 'column',
            gap: '.5rem',
          }}
        >
          <Typography
            content={subtitle}
            variant="porto-text-body-1-regular"
            color="porto-primitive-black-70"
          />
          {link && (
            <Typography
              variant="porto-text-body-1-regular"
              content={`<a href="${link.url}" target="_blank">${link.label}</a>`}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}