import { Icon } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { SSpan, SWrapper } from './styles'

const NeedHelp = ({ callback }: { callback: () => void }) => {
  return (
    <SWrapper onClick={() => callback()} >
      <Icon color="system-text-link" name="circlequestion" />
      <SSpan
        color="system-text-link"
        variant="porto-text-link-body-1-bold"
        content="Precisa de ajuda?"
      />
    </SWrapper>
  )
}

export default NeedHelp
