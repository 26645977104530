import React from 'react'

// import { Tooltip } from 'components'

import { ICardProps } from './interfaces'

import Helper from './Helper'

import {
  Aside,
  Body,
  Container,
  Content,
  Head,
  Step,
  Title,
  Validated,
} from './styles'

const Card: React.FC<ICardProps> = (props) => (
  <Container active={props.active} validated={props.validated}>
    <Content>
      <Head>
        {props.step && <Step>{props.step}</Step>}

        <Title>{props.title}</Title>

        {props.validated && (
          // <Tooltip content={`Etapa: <b>${props.title}</b> conclúida.`}></Tooltip>
          <Validated />
        )}
      </Head>

      {props.children && <Body>{props.children}</Body>}
    </Content>

    <Aside>
      {props.active && props.helper && <Helper>{props.helper}</Helper>}
    </Aside>
  </Container>
)

Card.defaultProps = {
  title: 'Card title',
}

export default Card
