
import styled from 'styled-components'
import { size } from 'polished'

import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Icons } from '@/components'

export const Container = styled.div`
  width: 158px;
`

export const DropdownButton = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
`

export const OptionsContainer = styled.div`
  position: absolute;
  align-self: flex-start;
  width: 158px;
  transform: translate(-12px, 12px);
  border: 1px solid var(--system-border-input-neutral);
  border-radius: 4px;
  background-color: var(--system-primitive-neutral-0);
  overflow: hidden;
`

export const Option = styled.div`
  width: 100%;
  padding: 14px 12px;
  background-color: var(--system-primitive-neutral-0);

  &:hover {
    background-color: var(--system-surface-action-secondary-hover);
    cursor: pointer;
  }
`

const Text = styled(Typography).attrs(() => ({ variant: 'porto-text-body-2-regular' }))

export const Value = Text`
  color: var(--system-text-link);
`

export const Label = Text``

export const Icon = styled(Icons.FeatherIcons)`
  color: var(--system-text-link);
  ${size('20px')}
`