import styled from 'styled-components'
import media from 'styled-media-query'
import { rem } from 'polished'

import { themes } from '@/styles'
import { pxToRem } from '@/styles/sheets/helpers'

export const Content = styled.div`
  line-height: 180%;
  font-size: ${rem('8px')};
  color: ${themes.text.higher};

  ${media.greaterThan('huge')`
    font-size: ${rem('12px')};
  `}
`

export const CloseButton = styled.div`
  position: absolute;
  top: ${pxToRem('54px')};
  right: ${pxToRem('18px')};
  cursor: pointer;
`
