import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { size, rgba, rem } from 'polished'

import { Actions as ActionsComponent, Icons } from '@/components'
import { themes } from '@/styles'
import theme from 'styled-theming'

const badgeColorGenerate = (color) => css`
  color: ${color};
  background-color: ${rgba(color, 0.1)};
`

const statusColorGenerate = (color) => css`
  background-color: ${color};
  box-shadow: 0 0 0 4px ${rgba(color, 0.3)};
`

export const RowResponsive = styled.div`
  display: flex;
  justify-content: stretch;
  gap: 16px;

  ${media.lessThan('medium')`
    flex-direction: column;
    justify-content: unset;
  `}
`

export const Container = styled.div``

export const Title = styled.p<{ status: string }>`
  margin-bottom: 32px;

  font-weight: bold;
  font-size: ${rem('18px')};
  color: ${themes.text.higher};

  &:before {
    ${size('7px')}

    margin-right: 14px;

    display: inline-block;

    content: '';
    border-radius: 100%;

    ${(props) =>
      props.status &&
      {
        '0': statusColorGenerate(themes.palletes.success),
        '1': statusColorGenerate(themes.palletes.danger),
      }[props.status]}

    ${media.greaterThan('huge')`
      ${size('13px')}
    `}
  }
`

export const Divider = styled.p`
  margin-bottom: 24px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 600;
  font-size: ${rem('15px')};
  color: ${themes.palletes.primary};

  &:after {
    height: 1px;
    display: block;
    margin-left: 8px;
    flex: 1;
    content: '';
    background-color: ${themes.utilities.border};
  }
`

export const Icon = styled.div`
  color: ${theme('mode', {
    light: themes.palletes.primary,
    dark: '#0087ba',
  })};

  svg {
    ${size('15px')}

    cursor: pointer;
    margin-right: 8px;
    margin-top: 4px;

    ${media.greaterThan('huge')`
      ${size('18px')}
    `}
  }
`

export const Items = styled.div`
  margin-bottom: 32px;
  display: flex;
`

export const Item = {
  Wrap: styled.div`
    white-space: nowrap;
    &:not(:last-of-type) {
      margin-right: ${rem('60px')};
    }
  `,

  Content: styled.div`
    display: flex;
    align-items: center;

    p {
      margin-right: 15px;
    }
  `,

  Label: styled.p`
    font-weight: bold;
    font-size: ${rem('14px')};
    color: ${themes.text.high};
  `,

  Title: styled.p`
    font-size: ${rem('14px')};
    color: ${themes.text.low};
  `,
}

export const Coverages = {
  Section: styled.div`
    margin-bottom: 32px;
  `,

  List: styled.table`
    width: 100%;

    text-align: left;

    > thead {
      th {
        padding: 0 8px 8px;

        font-weight: 700;
        font-size: ${rem('13px')};
        color: ${themes.text.high};

        ${media.greaterThan('huge')`
          padding: 0 16px 8px;
          font-size: ${rem('14px')};
        `}
      }
    }

    > tbody {
      > tr {
        &:nth-child(odd) {
          background-color: ${themes.page.colors.content};
        }

        > td {
          padding: 8px;

          font-size: ${rem('13px')};
          color: ${themes.text.high};

          ${media.greaterThan('huge')`
            padding: 8px 16px;
          font-size: ${rem('14px')};
          `}
        }
      }
    }
  `,
}

export const Description = styled.p`
  margin: ${rem('30px')} 0 ${rem('8px')} ${rem('8px')};

  font-weight: 500;
  font-size: ${rem('14px')};

  color: ${themes.text.medium};

  ${media.lessThan('huge')`
    font-size: ${rem('14px')};
  `}

  &:last-child {
    margin-top: ${rem('15px')};
  }
`

export const Link = styled.a`
  font-weight: 700;
  color: ${themes.palletes.primary};

  cursor: pointer;

  &:hover {
    color: #0092c9;
  }
`

export const Actions = styled.div`
  margin-top: 32px;
`

export const Action = styled(ActionsComponent.Floating).attrs({
  type: 'button',
})`
  font-size: ${rem('15px')};
  &:first-of-type {
    margin-right: ${rem('24px')};
  }
`

export const Badge = styled.span<{ type: string }>`
  padding: 4px 8px;
  margin-left: ${rem('14px')};

  color: #000;
  border-radius: 6px;
  font-size: ${rem('6px')};
  background-color: ${rgba('#000', 0.1)};

  ${media.greaterThan('large')`
    font-size: ${rem('9px')};
  `}

  ${media.greaterThan('huge')`
    font-size: ${rem('11px')};
  `}

  ${(props) =>
    props.type &&
    {
      Customizado: badgeColorGenerate('#1400FF'),
      sugestão: badgeColorGenerate('#00D1FF'),
    }[props.type]}
`

export const Options = {
  Toggle: styled.button`
    all: unset;

    margin-left: 24px;

    display: flex;
    align-items: center;

    cursor: pointer;
    line-height: 1;
    color: ${themes.text.low};

    &:hover {
      color: ${themes.text.higher};
    }
  `,

  Icon: styled(Icons.FeatherIcons).attrs(() => ({ name: 'printer' }))`
    color: ${theme('mode', {
      light: themes.palletes.primary,
      dark: '#0087ba',
    })};
    ${size('12px')}

    ${media.greaterThan('large')`
      ${size('16px')}
    `}

    ${media.greaterThan('huge')`
      ${size('24px')}
    `}
  `,

  Label: styled.span`
    display: none;

    ${media.lessThan('large')`
      display: block;
      margin-left: 8px;

      font-size: ${rem('10px')};
    `}
  `,
}
