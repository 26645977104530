import { pxToRem } from '@/styles/sheets/helpers'
import breakpoints from '@pol-npm/riscos-financeiros-dashboard-ui/dist/theme/breakpoints'
import styled from 'styled-components'

export const Header = styled.div`
  margin-bottom: ${pxToRem('32px')};
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${pxToRem('12px')};
`

export const ActionContainer = styled.div`
  display: flex;
  gap: ${pxToRem('24px')};

  @media (max-width: calc(${breakpoints.sm})) {
    flex-direction: column;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem('16px')};
`

export const MessageContainer = styled(Container)`
  gap: 0;
`

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: ${pxToRem('1px')} solid var(--neutras-black-15, #e0e0e0);
  border-radius: ${pxToRem('12px')};
  margin-top: ${pxToRem('40px')};
  padding: ${pxToRem('32px')};
  max-width: ${pxToRem('846px')};
`

export const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${pxToRem('24px')};
  gap: ${pxToRem('8px')};
`

export const Divider = styled.hr`
  border-top: ${pxToRem('1px')} solid var(--neutras-black-15, #e0e0e0);
  margin: ${pxToRem('24px')} 0;
  width: 100%;
`
