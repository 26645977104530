import styled from 'styled-components'
import { pxToRem } from '@/styles/sheets/helpers'

export const Content = styled.div``

export const Header = styled.div`
  margin-top: 20px;

  & :first-child {
    width: ${pxToRem(40)};
    height: ${pxToRem(40)};
    padding: ${pxToRem(9)} ${pxToRem(10)};
    background: '#F1EC85';
    border-radius: 8px;
  }

  & :last-child {
    padding-left: ${pxToRem(12)};
  }
`

export const Body = styled.div`
  margin-top: ${pxToRem(24)};
  font-size: ${pxToRem(14)};
  color: var(--neutras-black-75, #404040);

  & a {
    display: inline-flex;
    margin-top: ${pxToRem(24)};
    color: revert;
    text-decoration: none !important;

    & div {
      font-size: ${pxToRem(32)};
      margin-top: ${pxToRem(-8)};
    }
  }
`