import * as yup from 'yup'
import dayjs from 'dayjs'

export default yup.object().shape({
  start_date: yup
    .string()
    .nullable()
    .required('Campo data inicio é obrigatório.'),
  end_date: yup
    .string()
    .nullable()
    .when('start_date', {
      is: (value) => !!value,
      then: yup
        .string()
        .nullable()
        .test(
          'end-validation',
          'Período não pode ser maior que seis meses.',
          function (value) {
            const { start_date } = this.parent
            const max = dayjs(start_date).add(6, 'months')
            return dayjs(value).isSameOrBefore(max)
          }
        ),
    })
    .required('Campo data final é obrigatório.'),
})
