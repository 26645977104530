import styled from 'styled-components'
import media from 'styled-media-query'
import { Link } from 'react-router-dom'
import { rem } from 'polished'

import { Icons } from '@/components'
import { themes } from '@/styles'

export const Container = styled(Link).attrs(() => ({ to: '/' }))`
  all: unset;

  margin-bottom: 24px;

  display: inline-flex;
  align-items: center;

  cursor: pointer;
  transition: 0.15s;
  font-size: ${rem('10px')};
  color: ${themes.text.high};

  ${media.greaterThan('huge')`
    font-size: ${rem('14px')};
  `}

  &:hover {
    transition: 0.15s;
    color: ${themes.text.low};
  }
`

export const Icon = styled(Icons.Ionicons).attrs(() => ({ name: 'apps' }))`
  margin-right: 8px;
`
