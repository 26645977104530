import styled from 'styled-components'
import { pxToRem } from '@/styles/sheets/helpers'
import media from 'styled-media-query'

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 5;
`

export const ComboContainer = styled.div`
  position: relative;
  display: flex;
  padding: ${pxToRem('9px')} ${pxToRem('0px')} ${pxToRem('8px')} ${pxToRem('0px')};
  flex-direction: column;
  align-items: flex-start;
  border-radius: ${pxToRem('12px')};
  background: var(--surface-surface-secondary, #f7f7f7);
  overflow: visible;
  width: 100%;
`

export const ComboTextContainer = styled.div`
  display: flex;
  padding: ${pxToRem('0px')} ${pxToRem('8px')};
  align-items: center;
  gap: ${pxToRem('4px')};
  width: 100%;
`

export const ComboText = styled.span`
  color: var(--text-text-secondary, #646464);
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;

  & > div {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }
`

export const ErrorContainer = styled.div`
  margin: ${pxToRem('0px')};
  padding: ${pxToRem('7px')} ${pxToRem('16px')} ${pxToRem('7px')};
  background-color: rgba(132, 35, 16, 0.06);
  border-radius: 0 0 ${pxToRem('8px')} ${pxToRem('8px')};
  border-color: rgba(132, 35, 16, 0.5);
`

export const FloatingContainer = styled.div`
  position: absolute;
  ${media.greaterThan('medium')`
    width: ${pxToRem('480px')};
  `}
  align-items: flex-start;
  border-radius: ${pxToRem('12px')};
  background: #fff;
  box-shadow: 0 ${pxToRem('8px')} ${pxToRem('16px')} 0 rgba(0, 0, 0, 0.24);
  left: 0;
  bottom: 0;
  transform: translateY(101%);
  z-index: 7;
`

export const OptionsContentContainer = styled.div`
  display: flex;
  padding: ${pxToRem('24px')};
  flex-direction: column;
  align-items: flex-start;
  gap: ${pxToRem('16px')};
`

export const OptionsContainer = styled.div`
  display: flex;
  gap: ${pxToRem('12px')};
  max-width: ${pxToRem('432px')};
  flex-wrap: wrap;
`

export const OptionContainer = styled.div`
  display: flex;
  padding: ${pxToRem('12px')} ${pxToRem('24px')};
  justify-content: center;
  align-items: center;
  gap: ${pxToRem('8px')};
  border-radius: ${pxToRem('12px')};
  border: ${pxToRem('1px')} solid var(--neutras-black-15, #e0e0e0);
  cursor: pointer;
`

export const ChildrenContainer = styled.div`
  display: block;
`

export const ActionsContainer = styled.div`
  display: flex;
  padding: ${pxToRem('16px')} ${pxToRem('24px')};
  flex-direction: column;
  align-items: flex-start;
  gap: ${pxToRem('8px')};
  border-top: ${pxToRem('1px')} solid var(--border-border-neutral, #e0e0e0);
  border-bottom-left-radius: ${pxToRem('12px')};
  border-bottom-right-radius: ${pxToRem('12px')};
  background: var(--surface-surface-primary, #fff);
`

export const ActionContainer = styled.div`
  display: flex;
  max-width: ${pxToRem('432px')};
  align-items: flex-start;
  gap: ${pxToRem('12px')};
`
