import { Content, Conditional } from '@/components'
import { useContract } from '@/modules'

import Proposal from './../Proposal'

import Resume from './Resume'
import Policy from './Policy'

import * as S from './styles'
import NewError from '../../shared/NewError'

const Details = () => {
  const contract = useContract()

  const parseError = {
    '@aluguel/integration-failure': {
      title: 'Erro de integração.',
    },
    '500': {
      title: 'Erro de integração.',
    },
    '@aluguel/pac-analysis-error': {
      title: 'Falha ao processar resposta do servidor.',
    },
    '@aluguel/cpf-spouse-already-informed': {
      title: 'CPF conjuge já informado em outro pretendente.',
    },
    '@aluguel/missing-documents': {
      message:
        'É preciso enviar documentação adicional para concluir a análise. Entre em contato com seu corretor.',
      title: 'Documentação pendente',
    },
    '@aluguel/pac-already-analyzed': {
      title: 'Este PAC já foi analisado.',
    },
    '@aluguel/pac-status': {
      title: 'Erro ao consultar status do PAC.',
    },
    '@aluguel/pac-review': {
      title: 'PAC em análise.',
      message:
        'Nosso sistema processou todas as informações do cadastro e, neste caso, o PAC está em análise. Para mais informações, consulte seu Corretor.',
    },
    '@aluguel/pac-refused': {
      title: 'Análise recusada.',
      message:
        'A partir dos dados informados, não será possível dar prosseguimento à contratação do seguro.',
    },
  }

  if (
    (!!contract.metadata?.error && contract.external_status !== 'PENDING') ||
    contract.external_status === 'FAILURE'
  ) {
    return (
      <NewError
        product="Fiança Locatícia"
        errorTitle={parseError[contract.metadata.error?.code]?.title}
        errorMessage={parseError[contract.metadata.error?.code]?.message}
      />
    )
  }

  return (
    <S.Wrapper>
      <S.Aside>
        <Resume />
      </S.Aside>

      <S.Content>
        <Conditional when={contract.external_status === 'PENDING'}>
          <Content.Loader message="Aguarde, estamos calculando o orçamento." />
        </Conditional>

        <Conditional
          when={
            !!contract.budgets.length &&
            !Object.keys(contract?.policy ?? {}).length &&
            !contract.metadata?.error
          }
        >
          <Proposal />
        </Conditional>

        <Conditional
          when={!!Object.keys(contract?.policy ?? {}).length && !contract.metadata?.error}
        >
          <Policy />
        </Conditional>
      </S.Content>
    </S.Wrapper>
  )
}

export default Details
