import { IRealEstatePartnershipSupepCombo } from './interfaces'
import { useEffect, useState } from 'react'
import { loadBrokerPartnershipSuseps } from '@/services/api/partnership'
import { useCurrentOrganization } from '@/modules'
import {
  PartnershipSusep,
  PartnershipSuseps,
} from '@/services/api/partnership/types'
import { AutocompleteLocal } from '../AutocompleteLocal'
import { useField } from 'formik'
import { useMutation } from 'react-query'

export const RealEstatePartnershipSupepCombo = ({
  partnershipId,
  sx,
  selectionCallback = () => {},
  error = false,
  errorMessage = '',
  disabled = false,
}: IRealEstatePartnershipSupepCombo) => {
  const [options, setOptions] = useState<PartnershipSuseps>([])
  const [value, setValue] = useState(null)
  const profile = useCurrentOrganization()
  const requiredProfile = 'BROKER'

  const [field, meta, helpers] = useField('susep')

  const handleChange = (newValue: PartnershipSusep | null) => {
    setValue(newValue)
    selectionCallback(newValue)
    helpers.setValue(newValue.susep)
  }

  const loadOptionsMutation = useMutation(async (id: string) => {
    const susepOptions = await loadBrokerPartnershipSuseps(id)

    setOptions(() => susepOptions)
    if (field.value) {
      const selected = susepOptions.find((e) => e.susep === field.value)
      handleChange(selected)
    }
  })

  useEffect(() => {
    if (profile.type !== requiredProfile || !partnershipId) return

    loadOptionsMutation.mutate(partnershipId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnershipId, profile.type])

  return (
    <AutocompleteLocal<PartnershipSusep>
      label="SUSEP"
      id="real-estate-partnership-susep-combo"
      getOptionLabel={(option: PartnershipSusep) => `${option.susep}`}
      isOptionEqualToValue={(
        option: PartnershipSusep,
        value: PartnershipSusep | null
      ) => option.susep === value?.susep}
      disabled={disabled}
      loading={loadOptionsMutation.isLoading}
      error={error}
      errorMessage={errorMessage}
      options={options}
      value={value}
      selectionCallback={handleChange}
      sx={sx}
    ></AutocompleteLocal>
  )
}
