import { createStore } from 'effector'

const menuStore = createStore({
  isMenuOpen: false,
  isOrganizationMenuVisible: false,
  isAccountMenuVisible: false,
  step: 0,
})

export { menuStore }
