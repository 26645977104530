import styled, { css } from 'styled-components'
import media from 'styled-media-query'

import { firebaseMedia } from '@/tools'
import { themes } from '@/styles'

import button from '../../Actions/Button'

export const EffectMobile = styled.div`
  display: none;
  top: -34vw;
  position: relative;

  ${media.lessThan('medium')`
    display: block;
  `};
`

export const Message = styled.div`
  a {
    color: ${themes.palletes.primary};
  }
`

const LayoutGridDesktop = css`
  display: grid;
  grid-template-columns: 300px 1fr;
  min-height: 100vh;
`

export const LayoutGrid = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  background-color: white;

  ${media.greaterThan('medium')`${LayoutGridDesktop}`}
`

export const Logo = styled.img.attrs(() => ({
  src: firebaseMedia('logos/Logos_Porto.svg'),
  alt: 'Logotipo Porto Seguro',
}))`
  height: 28px;

  ${media.greaterThan('medium')`
    display: none;
  `}
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 632px;
  width: 100%;

  padding: 60px 16px;
  margin: 2rem 0 0 15rem;
  gap: 1.3rem;

  ${media.lessThan('medium')`  
    margin: 0;
    padding: 30px 16px;
  `}
`

export const Footer = styled.div`
  margin-top: 2rem;

  ${media.lessThan('medium')`
    display: flex;
    flex-direction: column-reverse;
  `}
`

export const Button = styled(button)`
  width: 184px;
  height: 48px;
  padding: 0;

  &:first-of-type {
    margin-right: 24px;
  }

  ${media.lessThan('medium')`
    margin-right: 0px;
    width: 100%;

    &:last-of-type {
      margin-bottom: 24px;
    }
  `}
`
