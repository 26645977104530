import React, { useEffect, useMemo, useState } from 'react'

import { FormikProvider, useFormik } from 'formik'

import { Conditional, Grid, Icons } from '@/components'
import { Action } from '../../../Details/styles'

import { useContract } from '@/modules'
import Budget from './Budget'
import validationSchema from './schema'

import { dashText, useGTM } from '@/tools'
import * as S from './styles'

import { api } from '@/services'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { WizardContext } from 'react-albus'
import { Card } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { CardCartaoPorto } from '@/components/CardCartaoPorto'
import { Products } from '@/screens/Contracts/Products/shared/entities'
import { useQuery } from 'react-query'

const Budgets: React.FC<WizardContext> = ({ next }) => {
  const contract = useContract<Products.ESSENCIAL>()
  const { setDataLayer, setCustomData } = useGTM()

  useEffect(() => {
    if (contract?.payload?.payment?.budget) {
      next()
    }
  }, [])

  useEffect(() => {
    setCustomData({
      page: {
        name: 'Portal Imobiliária - Orçamento - Seguro Fianca Essencial Selecionar Cotacao',
        product: 'aluguel-fianca',
      },
      site: {
        brand: 'portoseguro',
        portal: 'imobiliarias',
      },
      orcamento: {
        protocolo: `${contract?.policy?.proposal}`,
        tipo: contract?.product,
        plano: '',
        parceiro_id:
          typeof contract?.partner === 'string'
            ? contract?.partner
            : contract?.partner?.id,
        susep:
          typeof contract?.broker === 'string'
            ? contract?.broker
            : contract?.broker?.id,
      },
    })
  }, [contract, setCustomData])

  useEffect(() => {
    setDataLayer({
      event: 'step_change',
      titulo: 'Portal Imobiliária - Seguro Fianca Essencial Status da Cotacao',
      etapa:
        '/area-logada/orcamentos/seguro-fianca-essencial/resumo-selecionar-cotacao',
    })
  }, [setDataLayer])

  const { data, isLoading: isLoadingElegibility } = useQuery(
    ['elegibility', 'verifyElegibility', contract.id],
    () =>
      api.elegibility.verifyElegibilityFromContract({
        contractId: contract.id,
      }),
    {
      enabled: !!contract.id,
      refetchOnWindowFocus: false,
    }
  )

  const personsApproved = useMemo(
    () => data?.filter((persons) => persons.isElegibleCard) || [],
    [data]
  )

  const form = useFormik({
    initialValues: {
      comission: contract?.payload?.payment?.comission,
      budget: contract.payload?.payment?.budget || '',
      send_sms_cartao_porto: false,
    },
    validationSchema,
    onSubmit: async (plan) => {
      contract.update({ payment: { budget: plan.budget } })

      if (plan.send_sms_cartao_porto) {
        api.sms
          .addQueueApproveCard({
            documents: personsApproved.map((e) => e.id),
            from: 'PORTO_ESSENCIAL',
          })
          .catch((error) => {
            console.error(error)
          })
      }

      await api.contracts.payment(contract.id, { budget: plan.budget })

      const selectedPlan = contract.budgets.find((p) => p.id === plan.budget)

      setDataLayer({
        event: 'solicitacao-servico',
        nome_servico: 'seguro-fianca-essencial',
        tipo_servico: `selecionar:orcamento:${dashText(
          selectedPlan?.description
        )}`,
        retorno: 'sucesso',
        descricao: 'orçamento selecionado com sucesso',
      })
      next()
    },
  })

  return (
    <FormikProvider value={form}>
      <S.Form>
        <S.ActionGrid gap="16px" columns={['1fr', 'repeat(2, max-content)']}>
          <Typography as="h3" variant="porto-title-4-semibold">
            Ofertas pré-aprovadas
          </Typography>
        </S.ActionGrid>

        <S.Budgets>
          {contract.budgets.map((b) => (
            <Budget id={b.id} />
          ))}
        </S.Budgets>

        <Typography as="span" variant="porto-text-body-2-regular">
          *O Limite Máximo de Indenização (LMI) das coberturas adicionais é
          limitado ao Limite Máximo de Garantia (LMG).
        </Typography>

        <Conditional when={!isLoadingElegibility && !!personsApproved.length}>
          <FormikProvider value={form}>
            <CardCartaoPorto />
          </FormikProvider>
        </Conditional>

        {form.touched?.budget && !!form.errors?.budget && (
          <Card
            sx={{
              width: '100%',
              border: 0,
              backgroundColor: 'var(--porto-primitive-red-30)',
              color: 'var(--system-text-primary)',
            }}
          >
            <Grid columns="max-content 1fr">
              <Icons.FeatherIcons name="circleclose" />
              <Grid gap="8px" columns="1fr">
                <Typography as="span" variant="porto-text-body-1-bold">
                  Atenção
                </Typography>
                <Typography as="span" variant="porto-text-body-2-regular">
                  {form.errors.budget?.toString()}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        )}

        <S.FormActions>
          <Action
            icon="ArrowRight"
            type="submit"
            isLoading={form.isSubmitting}
            isDisabled={form.isSubmitting}
            data-gtm-type="click"
            data-gtm-clicktype="button"
            data-gtm-name="proxima-etapa"
          >
            Continuar
          </Action>
        </S.FormActions>
      </S.Form>
    </FormikProvider>
  )
}

export default Budgets
