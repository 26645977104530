import { Modals } from '@/components'
import { Content, IconContainer, Paragraph, Title } from './styles'
import { Icon } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/system'
import { TCreateModal } from '@/components/Modals/promised'

interface ResultErrorModalInterface extends TCreateModal {
  blockRetry?: boolean
}

const ResultErrorModal: React.FC<ResultErrorModalInterface> = (props) => {
  return (
    <Modals.Content
      icon=""
      title=""
      onSubmit={{
        text: 'Tentar novamente',
        disabled: props.blockRetry,
        onClick: (e) => {
          return props.onSubmit(e)
        },
      }}
      onCancel={{
        text: 'Fechar',
        onClick: (e) => {
          return props.onDismiss(e)
        },
      }}
    >
      <Content>
        <IconContainer>
          <Icon {...{ name: 'alert', color: 'porto-primitive-red-80' }} />
        </IconContainer>

        <Title>Algo deu errado!</Title>

        <Paragraph>
          Não foi possível enviar a pré-proposta.
          {!props.blockRetry && ' Por favor, tente novamente.'}
        </Paragraph>
      </Content>
    </Modals.Content>
  )
}

export default Modals.Promised.createModal<ResultErrorModalInterface>(
  ResultErrorModal
)
