import { BiometryStatus } from '.'

type StatusText = {
  iconName: string
  title: Readonly<string>
  body: Readonly<string>
  actionLabel?: string
}

export const biometryAlias: Readonly<Record<BiometryStatus, Readonly<StatusText>>> = {
  pending: {
    iconName: 'circleinfo',
    title: 'Biometria em andamento',
    body: 'Avise o cliente para realizar a biometria enviada por SMS e aguarde a conclusão do processo.',
  },
  done: {
    iconName: 'circlecheck',
    title: 'Análise cadastral aprovada',
    body: 'Você pode baixar a resposta da análise',
    actionLabel: 'Baixar análise',
  },
  expired: {
    iconName: 'circleclose',
    title: 'Biometria não realizada',
    body: 'O link para realizar a biometria expirou, tente enviar novamente.',
  },
  refused: {
    iconName: 'circleclose',
    title: 'Análise cadastral recusada',
    body: 'Verifique se informou os dados pessoais do inquilino principal corretamente, ou troque o inquilino principal, editando o orçamento.',
    actionLabel: 'Baixar análise',
  },
}
