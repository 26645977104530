import React from 'react'

import { Grid, Forms } from '@/components'

import * as S from './styles'

const CreditCard = () => {
  return (
    <S.Container>
      <Grid gap="1rem" columns={['auto', '1fr 1fr 1fr']}>
        <Forms.InputGroup
          name="card.number"
          label="Número do cartão"
          placeholder="Digite o número do cartão"
          mask="creditCard"
        />

        <Forms.InputGroup
          name="card.expiration_date"
          label="Validade"
          placeholder="00/0000"
          mask="shortDate"
        />
      </Grid>
    </S.Container>
  )
}

export default CreditCard
