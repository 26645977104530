import styled from 'styled-components'
import { css } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/_shared'

export const Container = styled.div``

export const List = styled.div(
  css({
    sx: {
      display: "flex",
      justifyContent: { xs: "center", md: "inherit" },
      gap: "1rem",
      flexWrap: "wrap",
      maxWidth: "1100px",
      "& > a": {
        width: "220px"
      }
    }
  })
)
