import { Grid, Info } from '@/components/Utilities'

import { AnimateVerticalShow } from '@/components/Utilities/Animations/AnimateVerticalShow'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Card, Icon } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { useFormikContext } from 'formik'
import React, { useEffect } from 'react'
import * as S from './styles'
import { SocialNameFieldProps } from './types'

export const SocialNameField: React.FC<SocialNameFieldProps> = ({
  textFieldName = 'customer.socialName',
  toggleName = 'customer.socialNameToggle',
  textFieldGrid = ['1fr', '1fr 1fr', '2fr 1fr'],
  readonly,
}) => {
  const formikProvider = useFormikContext()
  const toggleField = formikProvider.getFieldProps<boolean>(toggleName)

  useEffect(() => {
    if (!toggleField.value) {
      formikProvider.setFieldValue(textFieldName, '')
    }
  }, [toggleField.value])

  return (
    <Grid>
      <Grid gap="16px" columns="max-content max-content">
        <S.Toggle
          type="checkbox"
          label="Cliente possui nome social"
          name={toggleName}
          disabled={readonly}
        />
        <Info
          iconName="circlequestion"
          content="Nome social é o nome pelo qual a pessoa travesti ou transgênero se identifica e possui a mesma proteção concedida ao nome de registro, assegurada pelo Decreto nº 8.727/16."
        />
      </Grid>

      <AnimateVerticalShow isOpen={toggleField.value}>
        <S.StyledGrid gap="16px">
          <Grid columns={textFieldGrid}>
            <S.TextField
              label="Nome social completo"
              placeholder="Digite"
              name={textFieldName}
              readOnly={readonly}
              data-test-id="social-name"
            />
          </Grid>

          <Card
            sx={{
              background: 'var(--system-primitive-off-white)',
              border: 0,
            }}
          >
            <Grid gap="12px" columns="max-content 1fr">
              <Icon name="circleinfo" color="system-text-highlight" />
              <Grid>
                <Typography
                  as="span"
                  variant="porto-text-body-2-regular"
                  color="system-text-secondary"
                >
                  O nome social será utilizado para identificar o(a) cliente Porto. É minha
                  responsabilidade, enquanto Corretor(a), incluir as alterações necessárias em
                  apólices, documentos e contratos vigentes e equivalentes.
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </S.StyledGrid>
      </AnimateVerticalShow>
    </Grid>
  )
}
