import React, { useState } from 'react'
import { useFormik, FormikProvider } from 'formik'

import { useOrganizations } from '@/modules'
import { api } from '@/services'
import { Modals, Grid, Forms, toast } from '@/components'

import validationSchema from './validations'

const Register = ({ onSubmit, onDismiss }) => {
  const [loading, setLoading] = useState(false)
  const organization = useOrganizations()
  const organizationId = organization.store.active.id

  const parseError = {
    'User already invited': {
      title: 'Este usuário já recebeu um convite.',
    },
    'Integration Error': {
      title: 'Ocorreu um erro! Este usuário já está cadastrado na organização.',
    },
    Error: {
      title:
        'Não foi possível enviar um convite no momento, tente novamente mais tarde.',
    },
  }

  const inviteMember = async ({ email }) => {
    try {
      setLoading(true)

      const response = await api.organizations.members.invite(organizationId, {
        email,
      })

      onSubmit({
        id: response.id,
        user: {
          email,
        },
        status: response.status,
        created_at: response.created_at,
      })

      toast('Integrante adicionado com sucesso!', {
        type: 'success',
      })
    } catch (error) {
      const errorMessage = error.response.data.errorMessage

      toast(
        parseError[!!errorMessage ? errorMessage : 'Integration Error'].title,
        {
          type: 'error',
        }
      )
    } finally {
      setLoading(false)
    }
  }
 
  const handleSubmit = ({ email }) => {
    inviteMember({ email })
  }

  const handleDismiss = () => {
    onDismiss()
    form.resetForm()
  }

  const form = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  })

  return (
    <Modals.Helper
      title="Convidar novo integrante"
      description="Convide aqui um novo integrante para a organização."
      icon="UserPlus"
      onSubmit={{
        label: 'Convidar novo integrante',
        handler: () => form.handleSubmit(),
      }}
      onDismiss={{ 
        label: 'Cancelar convite',
        handler: handleDismiss,
      }}
      loading={{
        status: loading,
        message:
          'Aguarde, estamos validando os dados do novo integrante da organização.',
      }}
      disableOutsideClick
    >
      <FormikProvider value={form}>
        <Grid gap="2rem">
          <Forms.InputGroup
            name="email"
            label="E-mail"
            placeholder="Digite o e-mail"
            autoFocus
          />
        </Grid>
      </FormikProvider>
    </Modals.Helper>
  )
}

export default Modals.Promised.createModal<{ id }>(Register)
