import { Actions, Content } from '@/components'
import { useCurrentOrganization } from '@/modules'
import store from '@/modules/auth/store'
import { useUnit } from 'effector-react'
import { Cards } from '../../components'
import { contentLabels, links } from '../../utils'

import {
  Container,
  ContainerButton,
  ContainerLink,
  ContainerRedirect,
} from './styles'

const OminousStatement = () => {
  const profile = useCurrentOrganization()
  const isBroker = profile.type === 'BROKER'
  const { tokens } = useUnit(store)

  return (
    <Container>
      <Content.Heading
        title="Comunicado de sinistro"
        subtitle="Comunique o sinistro do Fiança Locatícia Essencial."
      />
      <Cards
        icon="teacher"
        isSvgIcons
        title={contentLabels.ominousStatement.title}
        children={
          isBroker
            ? contentLabels.ominousStatementBroker.children
            : contentLabels.ominousStatement.children
        }
      />
      <ContainerButton>
        <ContainerLink to="/sinistro">
          <Actions.Button size="large" type="button" kind="ghost">
            Voltar
          </Actions.Button>
        </ContainerLink>
        <ContainerRedirect
          href={
            isBroker
              ? links().corretorOnline.link
              : links(tokens.access, profile.id).ominousStatement.link
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <Actions.Button size="large" type="button" icon="arrow-up-right">
            {!isBroker ? 'Comunicar sinistro' : 'Acessar o COL'}
          </Actions.Button>
        </ContainerRedirect>
      </ContainerButton>
    </Container>
  )
}
export default OminousStatement
