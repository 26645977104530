import {
  Flex,
  Typography,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import {
  Dialog,
  Icon,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'

import restrictions from './restrictions.json'
import * as S from './styles'

const FOOTER_HEIGHT = '116px'
const CONTENT_HEIGHT = '80vh'

export interface IModalProps {
  isModalOpen: boolean
  setIsModalOpen: (_arg: boolean) => void
}

export const EstablishmentRestrictionsModal = ({
  isModalOpen,
  setIsModalOpen,
}: IModalProps) => (
  <Dialog
    isOpen={isModalOpen}
    onClose={() => setIsModalOpen(false)}
    size="large"
    primaryButton={{
      label: 'Ok, entendi',
      action: () => setIsModalOpen(false),
    }}
  >
    <Flex
      sx={{
        flexDirection: 'column',
        height: `calc(${CONTENT_HEIGHT} - ${FOOTER_HEIGHT})`,
        maxHeight: '550px',
        overflowY: 'auto',
        gap: '1rem',
      }}
    >
      <Icon name="circleinfo" size="1.75rem" />
      <Typography
        content="Tipos de estabelecimento que não são aceitos pelo Seguro Incêndio"
        variant="porto-title-5-semibold"
      />
      <Flex
        sx={{
          flexDirection: 'column',
          gap: '1.5rem',
          marginY: '.75rem',
        }}
      >
        <Typography
          variant="porto-text-body-2-regular"
          content={`
            Importante: Mercados, Minimercados, Quitandas, Hortifruti, Sacolão e Similares
            terão aceitação normal desde que em seu estoque não contenha mais do que
            50% dos produtos cujo o risco seja excluído conforme listagem abaixo:
          `}
        />

        <S.List>
          {restrictions.map((r) => (
            <S.ListItem key={r}>
              <Typography variant="porto-text-body-2-regular" content={r} />
            </S.ListItem>
          ))}
        </S.List>

        <Typography
          variant="porto-text-body-2-regular"
          content={`
            Para seguros envolvendo um ou mais bens tombados pelo patrimônio histórico,
            artístico ou cultural e cujo o valor em risco do local segurado declarado
            pelo segurado na especificação da apólice, não exceda a R$ 2.000.000,00, a
            indenização será estipulada baseada no valor de reconstrução de um imóvel
            convencional. Se mesmo depois de restaurado, houver depreciação artística,
            redução do valor do imóvel ou do conjunto de que faça parte, não estarão
            garantidos por este seguro os prejuízos daí resultante.
          `}
        />
      </Flex>
    </Flex>
  </Dialog>
)
