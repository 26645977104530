import styled, { css } from 'styled-components'
import { themes } from '@/styles'
import palletes from '@/styles/themes/palletes'

export const ModalSectionTitle = styled.h6<{ color?: string }>`
  font-style: normal;
  font-weight: bold;
  color: ${themes.palletes.terciary};

  font-size: 20px;
  line-height: 130%;
  margin-bottom: 16px;
  letter-spacing: 0.02em;

  ${(props) =>
    props.color &&
    {
      alert: css`
        color: #842310;
      `,
    }[props.color]}
`

export const ModalSectionDescription = styled.p`
  color: #1f1f1f;

  font-size: 14px;
  line-height: 140%;
  text-align: center;
  padding: 5px 24px 0;

  &:last-of-type {
    padding-bottom: 32px;
  }

  a {
    font-weight: bold;
    color: ${palletes.primary};
  }
`
