import { createEffect } from 'effector'
import { personalizationStore } from '../store'
import { api } from '@/services'

export const handlePersonalization = createEffect('handlePersonalization', {
  handler: async () => {
    const [currentPrefix = ''] = window.location.pathname
      .split('/')
      .filter(Boolean)

    if (currentPrefix) {
      return await api.whiteLabel.getPersonalization(currentPrefix)
    }

    return {}
  },
})

personalizationStore
  .on(handlePersonalization.doneData, (state, payload) => ({
    ...state,
    ...payload,
    loading: false,
  }))
  .on(handlePersonalization.fail, (state) => ({ ...state, loading: false }))
  .watch(({ broker }) => {
    api.instance.v1.defaults.headers['x-broker'] = broker
    api.instance.v2.defaults.headers['x-broker'] = broker
  })
