import React, { useState, useEffect } from 'react'
import { useFormik, FormikProvider } from 'formik'

import { api } from '@/services'
import { useDebounce, useGTM } from '@/tools'

import Forms from '../../../Forms'
import { Grid, toast, Conditional } from '../../../Utilities'

import { createModal } from '../../promised'
import Base from '../Base'

import { IAddressModalProps } from './interfaces'

import validationSchema from './validations'

const Address: React.FC<IAddressModalProps> = (props) => {
  const [loading, setLoading] = useState(false)
  const [disabledFields, setDisabledFields] = useState(['city', 'state'])
  const { setDataLayer } = useGTM()

  useEffect(() => {
    setDataLayer({
      event: 'modal',
      acao: 'abrir',
      nome: 'confirmacao-de-endereco',
    })

    return () => {
        setDataLayer({
        event: 'modal',
        acao: 'fechar',
        nome: 'confirmacao-de-endereco',
      })
    }
  }, [])

  const onSubmit = (values) => {
    form.resetForm()
    props.onSubmit(values)
  }

  const handleBlur = (event) => {
    form.setFieldValue('complement', event.target.value)
  }

  const form = useFormik({
    initialValues: {
      zipcode: props.zipcode || '',
      street: '',
      number: '',
      complement: '',
      neighborhood: '',
      city: '',
      state: '',
    },
    validationSchema,
    onSubmit,
  })

  const zipcode = useDebounce(form.values.zipcode)

  const onDismiss = () => {
    form.resetForm()
    props.onDismiss()
  }

  const fetchAddress = async () => {
    try {
      setLoading(true)

      const address = await api.addresses.getByZipcode(zipcode)

      Object.entries(address).map((entry) => {
        setDisabledFields((state) => [...state, !!entry[1] && entry[0]])
      })

      form.setValues({
        zipcode,
        street: address.street,
        neighborhood: address.neighborhood,
        city: address.city,
        state: address.state,
        complement: '',
        number: '',
      })
    } catch (error) {
      toast('Por favor insira um CEP válido.', {
        type: 'error',
      })

      props.onDismiss()
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (props.initialValues) {
      form.setValues(props.initialValues)
    }
  }, [])

  useEffect(() => {
    if (zipcode && !form.errors?.zipcode && !props.initialValues) fetchAddress()
  }, [zipcode])

  // useEffect(() => {
  //   !!props.zipcode && fetchAddress()
  // }, [])

  return (
    <Base
      icon="Map"
      title="Complete o endereço"
      description={`Por favor, confirme os dados vinculados ao CEP ${props.zipcode} e insira o número e o complemento do endereço.`}
      onSubmit={{
        label: 'Confirmar endereço',
        handler: () => form.handleSubmit(),
      }}
      onDismiss={{
        label: 'Cancelar',
        handler: onDismiss,
      }}
      loading={{
        status: loading,
        message: 'Aguarde, buscando endereço.',
      }}
      disableOutsideClick={true}
    >
      <FormikProvider value={form}>
        <Conditional when={!props.zipcode}>
          <Grid columns="1fr 2fr" space="0 0 1rem">
            <Forms.InputGroup
              name="zipcode"
              label="CEP"
              placeholder="Digite o CEP"
              size="block"
              mask="zipcode"
              data-gtm-form="input"
              data-gtm-name="cep"
              data-gtm-subname="endereco"
            />
          </Grid>
        </Conditional>

        <Forms.InputGroup
          name="street"
          label="Rua"
          placeholder="Confirme a rua"
          size="block"
          readOnly={disabledFields.includes('street')}
          data-gtm-form="input"
          data-gtm-name="rua"
          data-gtm-subname="endereco"
        />

        <Grid columns="1fr 2fr" gap="1rem" space="1rem 0">
          <Forms.InputGroup
            autoFocus
            name="number"
            label="Número"
            placeholder="Ex: 130"
            data-gtm-form="input"
            data-gtm-name="numero"
            data-gtm-subname="endereco"
          />

          <Forms.InputGroup
            name="complement"
            label="Complemento"
            placeholder="Ex: Bloco 10/Ap 172"
            maxLength={20}
            onBlur={handleBlur}
            data-gtm-form="input"
            data-gtm-name="complemento"
            data-gtm-subname="endereco"
          />
        </Grid>

        <Grid columns="1fr 1fr .5fr" gap="1rem">
          <Forms.InputGroup
            name="neighborhood"
            label="Bairro"
            placeholder="Confirme o bairro"
            readOnly={disabledFields.includes('neighborhood')}
            data-gtm-form="input"
            data-gtm-name="bairro"
            data-gtm-subname="endereco"
          />

          <Forms.InputGroup
            name="city"
            label="Cidade"
            placeholder="Confirme a cidade"
            readOnly={disabledFields.includes('city')}
            data-gtm-form="input"
            data-gtm-name="cidade"
            data-gtm-subname="endereco"
          />

          <Forms.InputGroup
            name="state"
            label="Estado"
            placeholder="Confirme o estado"
            readOnly={disabledFields.includes('state')}
            data-gtm-form="input"
            data-gtm-name="estado"
            data-gtm-subname="endereco"
          />
        </Grid>
      </FormikProvider>
    </Base>
  )
}

export default createModal<IAddressModalProps>(Address)
