import dayjs from 'dayjs'
import { Flex, Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { useContract } from '@/modules'
import { formatMoneyBRL } from '@/tools'
import { Table } from '../Table'
import { TextContentGroup } from '../TextContentGroup'

const paymentEnum = {
  '11': 'Boleto',
  'paymentLink': 'Cartão de crédito'
}

export const Contract = () => {
  const contract = useContract()
  const selectedPlan = contract.payload.contract
  const active = !!(selectedPlan?.period?.start && selectedPlan?.period?.end)

  return (
    <Flex
      sx={{
        gap: '24px',
        flexDirection: 'column',
      }}
    >
      <TextContentGroup
        title="Contrato"
        values={[
          [
            { title: "Plano", description: `${selectedPlan.plan_validity} meses (resgate de ${selectedPlan?.plan_rescue}%)` || "-" },
            { title: "Valor do aluguel", description: formatMoneyBRL(contract?.payload?.contract?.monthly_value) || "-" },
            { title: "Valor da proposta", description: formatMoneyBRL(contract?.payload?.contract?.total_value) || "-" },
          ],
          [
            { title: "Início da vigência", description: active ? dayjs(contract?.payload?.contract?.period?.start).format("DD/MM/YYYY") : "-" },
            { title: "Fim da vigência", description: active ? dayjs(contract?.payload?.contract?.period?.end).format("DD/MM/YYYY") : "-" },
          ],
         
        ]}
      />
      {!active && <Typography variant='porto-text-caption-regular' content='O título será emitido e ficará vigente após a confirmação do pagamento no sistema. A vigência será de acordo com o plano contratado.' />}
      {contract?.metadata?.bond_number && (
        <Table
          headColumns={['Número do título', 'Valor do título', 'Número da Sorte']}
          columns={[[contract?.metadata?.bond_number, formatMoneyBRL(contract?.metadata?.bond_value) || "-", contract?.metadata?.lucky_number || "-"]]}
        />
      )}
      <Table
        headColumns={['Forma de pagamento', 'Valor']}
        columns={[[paymentEnum[contract.payment.method] || '-', formatMoneyBRL(contract?.payload?.contract?.total_value) || "-"]]}
      />
      <Table
        headColumns={['Benefícios', 'Descrições']}
        columns={[
          ['Número da sorte', 'Porto Seguro Aluguel - Número da sorte para concorrer até 200 mil reais.'],
          ['Serviços emergenciais', 'Mão de obra gratuita para chaveiro, eletricista e encanador (3 utilizações por proposta).'],
          [`Resgate de ${selectedPlan.plan_rescue}%`, `Resgate de ${selectedPlan.plan_rescue}% do valor total ao final do contrato.`],
        ]}
      />
    </Flex>
  )
}
