import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { Link } from 'react-router-dom'
import { size, rem } from 'polished'

import { themes } from '@/styles'

export const TagContent = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`

export const Icon = styled.div<{ color: string }>`
  color: ${props => props.color};

  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  padding: 0.5rem;

  & svg {
    width: clamp(30px, 50px, 75px);
  }
`

export const Initials = styled.div<{ color: string }>`
  ${size('56px')}
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 8px;

  color: #ccc;
  -webkit-text-fill-color: #ccc;

  font: bold ${rem('10px')} ${themes.fonts.title};

  background-color: ${props => props.color};

  ${media.greaterThan('large')`
    font-size: ${rem('20px')};
  `}

  ${media.greaterThan('huge')`
    font-size: ${rem('24px')};
  `}
`

export const Container = styled(Link)`
  all: unset;

  position: relative;

  padding: 24px 12px;
  gap: 0.5rem;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

  cursor: pointer;
  transition: 0.3s;
  border-radius: 8px;
  text-align: center;
  box-sizing: border-box;
  background-color: ${themes.page.colors.background};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);

  &:before {
    position: absolute;
    top: 0;
    right: 0;

    width: 0;
    height: 2px;

    content: '';
    pointer-events: none;
    transition: 0.6s cubic-bezier(0.87, 0, 0.13, 1);
  }

  &:hover {
    transition: 0.3s;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.05);

    &:before {
      left: 0;

      width: 100%;

      transition: 0.6s cubic-bezier(0.87, 0, 0.13, 1);
    }
  }

  ${props =>
    props.color &&
    css`
      &:before {
        background-color: ${props => props.color};
      }
    `}
`
