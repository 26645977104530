import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { rem } from 'polished'

import { themes } from '@/styles'

export const Content = styled.div`
  line-height: 180%;
  font-size: ${rem('8px')};
  color: ${themes.text.higher};

  ${media.greaterThan('huge')`
    font-size: ${rem('12px')};
  `}
`

const ModalContentDesktop = css`
  margin: 32px 0;
  padding: 32px;
  border-radius: 8px;
  height: auto;
  width: auto;
`

export const ModalContent = styled.div`
  position: relative;
  z-index: 20;
  padding: 16px 16px 64px;
  height: 100%;
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;

  ${media.greaterThan('medium')`${ModalContentDesktop}`}
`

export const ModalCloseButton = styled.button`
  position: absolute;
  border: 0;
  cursor: pointer;
  background-color: transparent;

  top: 14px;
  right: 14px;
  width: 48px;
  height: 48px;

  &:hover {
    background: #f7f7f7;
    border-radius: 30px;
    position: absolute;
    width: 48px;
    height: 48px;
  }
`

export const ModalTitle = styled.p`
  font-style: normal;
  font-weight: bold;
  color: ${themes.palletes.terciary};

  font-size: 20px;
  line-height: 130%;
  margin-block: 8px;
`

export const Icon = styled.div`
  color: ${themes.palletes.primary};
  font-size: 95px;

  ${media.lessThan('medium')`
    margin-top: 50px;
  `}
`

export const ButtonClose = styled.div`
  cursor: pointer;
  font-size: 16px;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;

  &:hover {
    height: 48px;
    background: #f7f7f7;
    border-radius: 30px;
  }
`
