import styled from 'styled-components'
import media from 'styled-media-query'
import { rgba, rem } from 'polished'
import { motion } from 'framer-motion'

import { layers, themes, helpers } from '@/styles'

export const Container = styled(motion.div).attrs({
  variants: {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
    },
  },
  initial: 'initial',
  animate: 'animate',
})`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${layers.modals};

  display: grid;
  place-items: center;

  cursor: progress;
  backdrop-filter: blur(2px);
  background-color: ${rgba('#000', 0.3)};
`

export const Content = styled(motion.div).attrs({
  variants: {
    initial: {
      y: 30,
    },
    animate: {
      y: 0,
      transition: {
        ease: [0.16, 1, 0.3, 1],
      },
    },
  },
  initial: 'initial',
  animate: 'animate',
})`
  margin: 20px;
  padding: 24px 32px;

  text-align: center;
  border-radius: 12px;
  background-color: #fff;
  font-size: ${rem('14px')};
  color: ${themes.text.higher};
  font-family: ${themes.fonts.title};

  ${media.greaterThan('huge')`
    padding: 32px 40px;

    font-size: ${rem('14px')};
  `}

  ${helpers.defaultBoxShadow}
`

export const Icon = styled.div``

export const Message = styled.div`
  margin-top: 12px;
`
