import Button from '@/components/Actions/Button'
import {
  Box,
  Flex,
  Typography,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { FormikProvider, useFormik } from 'formik'
import { BaseCard } from '../../components'
import { Forms } from '@/components'
import { RadioGroup } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { HomeEquityStepComponent } from '../..'
import { useEffect, useState } from 'react'
import validationSchema from './validations'
import { api } from '@/services'
import { RadioButtonsContainer } from './styles'

export const HomeEquityFirstStep = ({
  formData = {},
  nextStep = () => null,
  previousStep = () => null,
}: HomeEquityStepComponent) => {
  const [isPropertyPaidOff, setPropertyPaidOff] = useState(
    formData.property_has_debt || '0'
  )
  const [zipcodeLoading, setZipcodeLoading] = useState(false)
  const [zipcodeLoaded, setZipcodeLoaded] = useState(null)
  const [addressLoaded, setAddressLoaded] = useState(false)

  const form = useFormik({
    initialValues: {
      property_type: formData.property_type || '',
      property_value: formData.property_value || '',
      zipcode: formData.zipcode || '',
      address: formData.address || '',
      property_has_debt: isPropertyPaidOff,
      property_debt: formData.property_debt || '',
    },
    validationSchema,
    onSubmit: (values) => {
      nextStep(values)
    },
  })

  const fetchAddress = async (zipcode) => {
    try {
      setZipcodeLoaded(() => zipcode)
      setZipcodeLoading(() => true)
      setAddressLoaded(() => false)

      const address = await api.addresses.getByZipcode(zipcode)

      form.setFieldValue(
        'address',
        `${address.street} - ${address.city}/${address.state}`
      )

      setAddressLoaded(() => true)
    } catch (error) {
      setAddressLoaded(() => false)
    } finally {
      setZipcodeLoading(() => false)
    }
  }

  const handleGoBack = () => {
    previousStep(form.values)
  }

  const handlePropertyPaidOffRadioChange = (value) => {
    const propertyPaidOff = Boolean(Number(value))
    setPropertyPaidOff(() => propertyPaidOff)

    form.setFieldValue('property_has_debt', value)
  }

  useEffect(() => {
    if (form.initialValues) {
      form.setValues(form.initialValues)
    }
  }, [])

  useEffect(() => {
    const zipcode = form.values.zipcode

    if (zipcode && zipcode.length === 9 && zipcode !== zipcodeLoaded) {
      fetchAddress(zipcode)
    }
  }, [form.values])

  return (
    <FormikProvider value={form}>
      <Flex
        sx={{
          flexDirection: 'column',
          gap: '1rem',
          width: '100%',
        }}
      >
        <BaseCard title="Dados do imóvel">
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: { xs: '1fr', lg: '250px 1fr' },
              gap: '1rem',
            }}
          >
            <Forms.SelectGroup
              name="property_type"
              options={[
                { label: 'Tipo de imóvel', value: '' },
                { label: 'Casa residencial', value: 'Casa residencial' },
                {
                  label: 'Apartamento residencial',
                  value: 'Apartamento residencial',
                },
                { label: 'Sala comercial', value: 'Comercial' },
                { label: 'Terreno', value: 'Terreno' },
              ]}
              size="block"
            />
            <Forms.InputGroup
              name="property_value"
              label=""
              mask="money"
              placeholder="Valor do imóvel"
              size="block"
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              gridTemplateColumns: { xs: '1fr', lg: '1fr' },
              marginTop: '32px',
            }}
          >
            <Typography
              content="Onde está localizado o imóvel?"
              variant="porto-text-body-1-regular"
              sx={{
                color: 'var(--neutras-black-75, #404040)',
              }}
            />
          </Box>

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: { xs: '1fr', lg: '150px 1fr' },
              gap: '1rem',
              marginTop: '16px',
            }}
          >
            <Forms.InputGroup
              name="zipcode"
              placeholder="CEP do imóvel"
              size="block"
              mask="zipcode"
              data-gtm-form="input"
              data-gtm-name="cep"
              data-gtm-subname="endereco"
              readOnly={zipcodeLoading}
            />

            <Forms.InputGroup
              name="address"
              label=""
              placeholder="Endereço"
              readOnly={zipcodeLoading || addressLoaded}
            />
          </Box>

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: { xs: '1fr', lg: '180px 1fr' },
              gridTemplateRows: { xs: '1fr 1fr', lg: '24px 1fr' },
              gap: '1rem',
              rowGap: '10px',
              marginTop: '40px',
            }}
          >
            <Typography
              content="O imóvel está quitado?"
              variant="porto-text-body-1-regular"
              sx={{
                color: 'var(--neutras-black-75, #404040)',
              }}
            />
            {!isPropertyPaidOff && (
              <Typography
                content="Qual o valor da dívida?"
                variant="porto-text-body-1-regular"
                sx={{
                  color: 'var(--neutras-black-75, #404040)',
                }}
              />
            )}
            {isPropertyPaidOff && <div></div>}

            <RadioButtonsContainer>
              <RadioGroup
                onChange={handlePropertyPaidOffRadioChange}
                initialChecked={String(Number(isPropertyPaidOff))}
                options={[
                  { text: 'Sim', value: '1' },
                  { text: 'Não', value: '0' },
                ]}
              />
            </RadioButtonsContainer>
            <div style={{ display: 'none' }}>
              <Forms.InputGroup name="property_has_debt" type="hidden" />
            </div>
            <div style={{ display: isPropertyPaidOff ? 'none' : 'grid' }}>
              <Forms.InputGroup
                name="property_debt"
                mask="money"
                placeholder="Digite o valor da dívida"
              />
            </div>
            {isPropertyPaidOff && <div></div>}
          </Box>
        </BaseCard>
      </Flex>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', lg: 'repeat(2, 1fr)' },
          gap: '1rem',
        }}
      >
        <Button kind="ghost" onClick={handleGoBack}>
          Voltar
        </Button>

        <Button
          type="button"
          onClick={() => form.handleSubmit()}
          isDisabled={Object.keys(form.errors).length > 0}
        >
          Continuar
        </Button>
      </Box>
    </FormikProvider>
  )
}
