import { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useFormik, FormikProvider } from 'formik'

import { Icons, Forms, Modals, Content, toast, Conditional } from '@/components'
import { api } from '@/services'

import * as S from './styles'

const Settings = () => {
  const params = useParams<{ id: string; partner: string }>()
  const history = useHistory()

  const [status, setStatus] = useState<'fetching' | 'saving' | ''>('')
  const [details, setDetais] = useState({
    prolabore_enabled: false,
  })

  const fetchOrganization = async () => {
    try {
      setStatus('fetching')
      const response = await api.organizations.partnerships.get(
        params.id,
        params.partner
      )
      form.setFieldValue('prolabore_enabled', response.prolabore_enabled)
      setDetais(response)
    } catch (error) {
      history.push('/organizacoes')

      toast(
        'Está organização que tentou acessar não existe e/ou está fora do ar.',
        {
          type: 'warning',
        }
      )
    } finally {
      setStatus('')
    }
  }

  const onSubmit = async (values) => {
    const reset = await Modals.Confirmation({
      icon: 'check-circle',
      title: 'Aplicar alterações',
      content: `Deseja realmente aplicar as novas configurações?`,
      submitText: 'Aplicar',
    })

    if (!reset) return

    try {
      setStatus('saving')

      await api.organizations.partnerships.updateProlabore(
        params.id,
        params.partner,
        {
          prolabore_enabled: values.prolabore_enabled,
        }
      )

      fetchOrganization()

      toast('Configurações salvas com sucesso!', {
        type: 'success',
      })
    } catch (error) {
      toast('Ocorreu um erro ao salvar as configurações!', {
        type: 'error',
      })
    } finally {
      setStatus('')
    }
  }

  const form = useFormik({
    initialValues: {
      prolabore_enabled: details.prolabore_enabled,
    },
    onSubmit,
  })

  useEffect(() => {
    fetchOrganization()
  }, [])

  if (status === 'fetching')
    return (
      <Content.Loader message="Aguarde, estamos buscando suas configurações." />
    )

  return (
    <FormikProvider value={form}>
      <S.Form onSubmit={form.handleSubmit}>
        <S.Container>
          <S.Title>
            <Icons.FeatherIcons name="Settings" /> Configurações
          </S.Title>

          <S.Item.Content>
            <S.Item.Heading>
              <S.Item.Title>Pró-Labore</S.Item.Title>
              <S.Item.Description>
                Habilitar ou desabilitar Pró-labore
              </S.Item.Description>
            </S.Item.Heading>

            <S.Item.Input>
              <Forms.Toggle name="prolabore_enabled" />
            </S.Item.Input>
          </S.Item.Content>

          <S.Footer>
            <Conditional
              when={form.values.prolabore_enabled !== details.prolabore_enabled}
            >
              <S.Action kind="link" onClick={() => form.resetForm()}>
                Cancelar
              </S.Action>

              <S.Action
                isLoading={status === 'saving'}
                onClick={() => form.handleSubmit()}
              >
                Salvar
              </S.Action>
            </Conditional>
          </S.Footer>
        </S.Container>
      </S.Form>
    </FormikProvider>
  )
}

export default Settings
