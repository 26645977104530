import styled from 'styled-components'
import media from 'styled-media-query'
import { rgba, size } from 'polished'

import { Grid as GridComponent } from '@/components'
import { themes } from '@/styles'

import {
  Aside,
  Body,
  Container,
  Content,
  Head,
} from '@/components/Forms/Card/styles'

export const Grid = styled(GridComponent).attrs({
  columns: ['auto', 'repeat(3, 1fr)'],
  gap: ['.5rem', '1rem'],
})``

export const Container2 = styled.div``

export const Contentw = styled.div``

export const Add = styled.div`
  padding-top: 24px;
`

export const Trash = styled.div`
  padding-top: 30px;
`

export const OrganizationWrapper = styled.div`
  ${Aside} {
    display: none;
  }

  ${Head} {
    display: none;
  }

  ${Container} {
    all: unset;
  }

  ${Content} {
    all: unset;
  }

  ${Body} {
    all: unset;
  }
`

export const Action = styled.button`
  all: unset;
  justify-content: center;
  margin-top: 25px;
  display: flex;
  align-items: center;

  cursor: pointer;
  transition: 0.15s;
  color: ${themes.palletes.primary};

  > svg {
    ${size('20px')}
  }

  &:hover {
    color: ${rgba(themes.palletes.primary, 0.6)};
    transition: 0.15s;
  }

  ${media.greaterThan('huge')`
    > svg {
      ${size('25px')}
    }
  `}
`
