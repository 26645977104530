import React from 'react'

import { Modals } from '@/components'

import { IPlanDetailsProps } from '../Plan/interfaces'
import { Content, Coverages } from './styles'
import { formatMoneyBRL } from '@/tools'

const Details: React.FC<IPlanDetailsProps> = (props) => {
  return (
    <Modals.Content
      title="Mais Informações"
      icon="information-circle-outline"
      colorIcon="primary"
      onSubmit={{ text: 'Fechar', onClick: props.onCancel }}
    >
      <Content>
        <Coverages.Section>
          <Coverages.List>
            <thead>
              <tr>
                <th>Coberturas</th>
                <th>Limite máximo de indenização (R$)</th>
                <th>Valor do seguro (R$)</th>
              </tr>
            </thead>

            <tbody>
              {props.details.coverages.map((item: any) => (
                <tr>
                  <td>{item.description}</td>
                  <td>{formatMoneyBRL(item.value)}</td>
                  <td>{formatMoneyBRL(item.prize)}</td>
                </tr>
              ))}
            </tbody>
          </Coverages.List>
        </Coverages.Section>

        <Coverages.Section>
          <Coverages.List>
            <thead>
              <tr>
                <th>Valores de seguro (R$)</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>Prêmio Líquido</td>
                <td>
                  {formatMoneyBRL(props.details.quotation.prize.coverages)}
                </td>
              </tr>
              <tr>
                <td>Desconto</td>
                <td>
                  {formatMoneyBRL(props.details.quotation.prize.discount)}
                </td>
              </tr>
              <tr>
                <td>Serviços Emergenciais</td>
                <td>{formatMoneyBRL(props.details.quotation.prize.clauses)}</td>
              </tr>
              <tr>
                <td>IOF</td>
                <td>{formatMoneyBRL(props.details.quotation.prize.iof)}</td>
              </tr>
              <tr>
                <td>Prêmio Líquido Total</td>
                <td>{formatMoneyBRL(props.details.quotation.prize.net)}</td>
              </tr>
              <tr>
                <td>Valor total do seguro</td>
                <td>{formatMoneyBRL(props.details.quotation.prize.total)}</td>
              </tr>
            </tbody>
          </Coverages.List>
        </Coverages.Section>

        <Coverages.Section>
          <Coverages.List>
            <thead>
              <tr>
                <th>Participação Obrigatória do Segurado</th>
              </tr>
            </thead>

            <tbody>
              {props.details.benefits.map((item: any) => (
                <tr>
                  <td>{item.title}</td>
                  <td>{item.description}</td>
                </tr>
              ))}
            </tbody>
          </Coverages.List>
        </Coverages.Section>

        <Coverages.Section>
          <Coverages.List>
            <thead>
              <tr>
                <th>Serviços para sua região</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>HELP DESK ATENDIMENTO MICRO</td>
              </tr>
              <tr>
                <td>CHAVEIRO COMUM</td>
              </tr>
              <tr>
                <td>ENCANADOR</td>
              </tr>
            </tbody>
          </Coverages.List>
        </Coverages.Section>
      </Content>
    </Modals.Content>
  )
}

export default Details
