import { mergeDeep } from '@/tools/utils/deepMerge'
import { useCallback } from 'react'
import TagManager from 'react-gtm-module'
import { GTM_ID } from './config'
import { CustomDataProps, DataLayerProps } from './types'

if (GTM_ID) {
  TagManager.initialize({
    gtmId: GTM_ID,
  })
}

export function useGTM() {
  const setCustomData = useCallback((values: CustomDataProps) => {
    window.customData = mergeDeep(window.customData || {}, values)
  }, [])

  const setDataLayer = useCallback((values: DataLayerProps) => {
    if (window.dataLayer) window.dataLayer.push(values)
  }, [])

  return {
    setCustomData,
    setDataLayer,
  }
}
