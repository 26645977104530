import styled from 'styled-components'
import media from 'styled-media-query'
import { rem, size } from 'polished'

import { Icons } from '@/components'
import { themes } from '@/styles'
import theme from 'styled-theming'

interface Props {
  failure?: boolean
}

const borderColor = theme('mode', {
  light: '#eeeef7',
  dark: '#26272f',
})

export const Container = styled.div<Props>`
  padding: 12px 24px;

  cursor: ${(props) => props.failure ? '' : "pointer"};

  &:hover {
    background-color: ${(props) => props.failure ? '' : "#f7f7f7"}
  }

  &:not(:last-of-type) {
    border-bottom: 2px solid ${borderColor};
  }

  ${media.greaterThan('large')`
    padding: 10px 32px;

    display: flex;
    align-items: center;
    justify-content: space-between;
  `}

  ${media.greaterThan('huge')`
    padding: 16px 16px;
  `}
`

export const Left = styled.div`
  ${media.greaterThan('large')`
    flex: 1;
  `}
`

export const Title = styled.h4`
  margin-bottom: 4px;

  font-weight: 500;
  color: ${themes.text.high};

  ${media.greaterThan('huge')`
    margin-bottom: 8px;
  `}
`

export const CreatedAt = styled.p`
  margin-bottom: 8px;

  color: ${themes.text.low};
  font-size: ${rem('10px')};

  ${media.greaterThan('huge')`
    margin-bottom: 16px;

    font-size: ${rem('14px')};
  `}
`

export const Tag = {
  Item: styled.div`
    padding: 6px 10px;

    display: inline-flex;
    align-items: center;

    line-height: 1;
    border-radius: 6px;
    background-color: ${themes.page.colors.content};

    &:not(:last-of-type) {
      margin-right: 8px;
    }

    ${media.greaterThan('huge')`
      padding: 8px 14px;
    `}
  `,

  Icon: styled(Icons.Ionicons)`
    margin-right: 4px;

    font-size: ${rem('8px')};
    color: ${themes.text.low};

    ${media.greaterThan('large')`
      font-size: ${rem('10px')};
    `}

    ${media.greaterThan('huge')`
      font-size: ${rem('13px')};
    `}
  `,

  Value: styled.div`
    font-weight: 600;
    text-transform: uppercase;
    font-size: ${rem('6px')};
    color: ${themes.text.higher};

    ${media.greaterThan('large')`
      font-size: ${rem('8px')};
    `}

    ${media.greaterThan('huge')`
      font-size: ${rem('11px')};
    `}
  `,
}

export const Middle = styled.div`
  display: flex;
  align-items: center;
  
`

export const Detail = {
  Item: styled.div`
    display: flex;
    width: 5rem;

    &:not(:last-of-type) {
      /* margin-right: 24px; */
    }

    ${media.greaterThan('large')`
      width: 5rem;

      &:first-child {
      width: 5rem;

      }
      &:last-child {
        margin-left: 10px;
        width: 8rem ;
      }
    `}

    ${media.greaterThan('huge')`
      width: 8rem;

      &:first-child {
        width: 5rem;

      }
      &:last-child {
        width: 12rem ;
      }
      &:nth-child(2) {
        width: 13.5rem;
}
    `};
  `,

  Icon: styled(Icons.Ionicons)`
    margin-right: 8px;

    font-size: ${rem('10px')};
    color: ${themes.palletes.info};

    ${media.greaterThan('large')`
      font-size: ${rem('12px')};
    `}

    ${media.greaterThan('huge')`
      font-size: ${rem('16px')};
    `}
  `,

  Info: styled.p``,

  Label: styled.span`
    display: block;

    font-size: ${rem('8px')};
    color: ${themes.text.low};

    ${media.greaterThan('large')`
      font-size: ${rem('10px')};
    `}

    ${media.greaterThan('huge')`
      font-size: ${rem('12px')};
    `}
  `,

  Value: styled.span`
    display: block;

    font-size: ${rem('8px')};
    color: ${themes.text.high};

    ${media.greaterThan('large')`
      font-size: ${rem('10px')};
    `}

    ${media.greaterThan('huge')`
      font-size: ${rem('14px')};
    `}
  `,
}

export const Right = styled.div`
  display: flex;
  align-items: center;

  ${media.greaterThan('large')`
    flex: 1;
    justify-content: flex-end;
  `}
`

export const Options = {
  Toggle: styled.button`
    all: unset;

    margin-left: 24px;

    display: flex;
    align-items: center;

    cursor: pointer;
    line-height: 1;
    color: ${themes.text.low};

    &:hover {
      color: ${themes.text.higher};
    }
  `,

  Icon: styled(Icons.FeatherIcons).attrs(() => ({ name: 'more-vertical' }))`
    ${size('12px')}

    ${media.greaterThan('large')`
      ${size('16px')}
    `}

    ${media.greaterThan('huge')`
      ${size('24px')}
    `}
  `,

  Label: styled.span`
    display: none;

    ${media.lessThan('large')`
      display: block;
      margin-left: 8px;

      font-size: ${rem('10px')};
    `}
  `,
}
