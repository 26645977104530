import { createGlobalStyle } from 'styled-components'

import themes from '../themes'

import heading from './heading'
import reset from './reset'

export default createGlobalStyle`
  ${reset}
  ${heading}

  html {
    font-size: 16px;
    scroll-behavior: smooth;
  }

  body {
    font-family: ${themes.fonts.text};
  }

  ::selection {
    color: #000;
    background-color: #D7E0F5;
  }

  pre {
    height: 100px;

    padding: 20px;
    overflow: hidden;
    margin-bottom: 20px;

    color: #EEE;
    white-space: pre;
    border-radius: 15px;
    background-color: #222;
    text-shadow: 0 1px 0 #000;
    transition: max-height 1s;
    border-bottom: 1px solid #555;
    box-shadow: 0 1px 5px rgba(0,0,0,0.4) inset, 0 0 20px rgba(0,0,0,0.2) inset;
    font: 16px/24px 'Courier New', Courier, 'Lucida Sans Typewriter', 'Lucida Typewriter', monospace;

    &:hover {
      height: auto;
    }
  }
`
