import React, { FC, ReactElement } from 'react'
import { Icon, ModalCloseButton, ModalContent } from './styles'
import { Modals } from '@/components'
import { SvgIcons } from '@/components/SvgIcons'

interface Props {
  onDismiss?: React.MouseEventHandler<HTMLButtonElement>
  icon?: JSX.Element
  centered?: boolean,
}

const ModalBase: FC<Props> = ({
  icon,
  centered,
  children,
  onDismiss,
}): ReactElement => {
  return (
    <Modals.Base centered={centered} onDismiss={onDismiss}>
      <ModalContent>
        <ModalCloseButton onClick={onDismiss}>
          {SvgIcons.closeIcon}
        </ModalCloseButton>
        <Icon>{icon}</Icon>
        {children}
      </ModalContent>
    </Modals.Base>
  )
}

export default ModalBase
