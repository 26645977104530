import { FC, ReactElement } from 'react'

import { Gradient, LogoPorto, LogoGradient, Subtitle, Title } from './styles'

const GradientAside: FC = (): ReactElement => {
  return (
    <Gradient>
      <LogoPorto width="92" />
      <Title>Portal das Imobiliárias</Title>
      <Subtitle>
        Uma série de facilidades e benefícios para imobiliárias e corretoras?
        Quem tem Porto, tem!
      </Subtitle>
      <LogoGradient />
    </Gradient>
  )
}

export default GradientAside
