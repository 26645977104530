import styled, { css } from 'styled-components'
import media from 'styled-media-query'

import GradientLogo from '@/screens/FirstAccess/components/GradientLogo'
import Logo from '@/screens/FirstAccess/components/Logo'
import { themes } from '@/styles'

const GradientBackground = styled.aside`
  position: relative;
  background: linear-gradient(233.94deg, #2c3da8 11.46%, #809ad9 100%);
  height: 100%;

  ${media.lessThan('medium')`    
    background: linear-gradient(144.06deg, #2c3da8 11.46%, #809ad9 100%);
    height: 10rem;
  `}
`

export const LogoPorto = styled(Logo)`
  ${media.lessThan('medium')`    
    display: none;
  `}
`

const GradientMobile = css`
  display: block;
  padding: 0;
`

export const Gradient = styled(GradientBackground)`
  display: block;
  padding: 40px 24px 40px 30px;

  ${media.lessThan('medium')`
    ${GradientMobile}
    clip-path: ellipse(110% 58% at 65% 41%);
  `}
`

export const Title = styled.h1`
  color: #ffffff;
  font-family: ${themes.fonts.text};

  font-style: normal;
  font-weight: normal;

  font-size: 2.25rem;
  line-height: 120%;

  letter-spacing: -0.03em;
  margin-top: 3rem;

  ${media.lessThan('medium')`
    font-weight: 700;
    text-align: center;  
    margin-top: 2rem;
  `}
`

export const Subtitle = styled.p`
  font-family: ${themes.fonts.text};
  color: #ffffff;
  opacity: 0.8;

  font-style: normal;
  font-weight: normal;

  font-size: 1rem;
  line-height: 24px;

  margin-top: 1.5rem;

  ${media.lessThan('medium')`    
    display: none;
  `}
`

export const LogoGradient = styled(GradientLogo)`
  margin-top: 6rem;
  width: 320px;

  ${media.lessThan('medium')`    
    display: none;
  `}
`
