import React, { useRef, useEffect } from 'react'
import Ink from 'react-ink'
import { useField } from 'formik'

import { Ionicons } from '../../Icons'

import { ToggleProps } from './types'
import * as S from './styles'

const Toggle = (props: ToggleProps) => {
  const [field, meta, helpers] = useField(props.name)
  const { resetValueOnUnmount = true } = props

  const initialValue = useRef(field.value)

  useEffect(() => {
    return () => {
      if (resetValueOnUnmount) helpers.setValue(initialValue.current)
    }
  }, [])

  return (
    <S.Container>
      <S.Field>
        <S.Input
          {...field}
          {...{
            ...(props['data-gtm-form'] && {
              'data-gtm-form': props['data-gtm-form'],
            }),
            ...(props['data-gtm-type'] && {
              'data-gtm-type': props['data-gtm-type'],
            }),
            ...(props['data-gtm-name'] && {
              'data-gtm-name': props['data-gtm-name'],
            }),
            ...(props['data-gtm-subname'] && {
              'data-gtm-subname': props['data-gtm-subname'],
            }),
          }}
          checked={field.value}
          disabled={props.disabled}
        />

        <S.Thumb>
          <Ink />

          <S.Pin>
            <Ionicons
              name={field.value ? 'checkmark-outline' : 'close-outline'}
            />
          </S.Pin>
        </S.Thumb>

        {props.label && <S.Label>{props.label}</S.Label>}
      </S.Field>

      {meta.touched && !!meta.error && (
        <S.Message kind="danger">{meta.error}</S.Message>
      )}
    </S.Container>
  )
}

export default Toggle
