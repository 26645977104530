import { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import {
  Icon,
  Tabs,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { PageContainer } from '../../components'
import Form from './Form'
import Sheet from './Sheet'
import { Header, CloseButton, Content } from './styles'

const NewRentCollection = () => {
  const { replace } = useHistory()
  const { state } = useLocation<{ isUpdating: boolean }>()
  const [currentTab, setCurrentTab] = useState(0)

  const tabs = [
    { label: state?.isUpdating ? 'Criação individual' : 'Individual' },
    { label: state?.isUpdating ? 'Atualização por planilha' : 'Por planilha' },
  ]

  const renderContent = () => {
    if (currentTab === 0) {
      return <Form />
    } else if (currentTab === 1) {
      return <Sheet isUpdating={state?.isUpdating} />
    }
  }

  return (
    <PageContainer>
      <Header>
        <CloseButton
          onClick={() => {
            replace('/sinistro/assessoria-de-cobranca')
          }}
        >
          <Icon size={16} name="close" />
        </CloseButton>
        <Typography
          variant="porto-title-4-bold"
          color="system-primitive-neutral-900"
          content={
            state?.isUpdating ? 'Atualização de cobrança' : 'Nova cobrança'
          }
        />
      </Header>
      <Tabs tabs={tabs} onChange={(index) => setCurrentTab(Number(index))} />
      <Content>{renderContent()}</Content>
    </PageContainer>
  )
}

export default NewRentCollection
