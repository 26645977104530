import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { rgba, rem, size } from 'polished'
import { Link } from 'react-router-dom'

import { themes, layers, helpers } from '@/styles'

import { FeatherIcons } from '../../Icons'

import Button from '../Button'

export const Container = styled.footer`
  ${helpers.defaultBoxShadow}

  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: ${layers.floatActions.submit};

  width: 80%;
  height: 40px;

  padding-left: 8px;

  display: flex;
  align-items: center;

  border-radius: 10px;

  background-color: ${themes.forms.action};

  ${media.greaterThan('large')`
    width: 350px;
  `}

  ${media.greaterThan('huge')`

    width: 450px;
    height: 50px;

    border-radius: 12px;
  `}
`

export const Anchor = {
  Button: styled.button.attrs({
    type: 'button',
  })`
    all: unset;

    ${size('28px')}

    margin-right: 8px;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    transition: 0.15s;
    border-radius: 8px;
    background-color: ${rgba('#FFF', 0.05)};

    &:hover {
      transition: 0.15s;
      background-color: ${rgba('#FFF', 0.08)};
    }

    ${media.greaterThan('huge')`
      ${size('34px')}

      margin-right: 16px;
    `}
  `,

  Icon: styled(FeatherIcons)`
    height: 12px;

    color: #FFF;

    ${media.greaterThan('huge')`
      height: 16px;
    `}
  `,
}

export const Secondary = styled(Link)`
  all: unset;

  cursor: pointer;
  transition: 0.15s;
  font: 500 ${rem('8px')} ${themes.fonts.title};

  color: ${themes.palletes.primary};
  -webkit-text-fill-color: ${themes.palletes.primary};

  ${media.greaterThan('large')`
    font-size: ${rem('9px')};
  `}

  ${media.greaterThan('huge')`
    font-size: ${rem('11px')};
  `}

  &:hover {
    opacity: 0.6;
    transition: 0.15s;
  }
`

export const Primary = styled(Button)`
  margin-top: -14px;
  margin-right: 6px;
  margin-left: auto;

  ${media.greaterThan('huge')`
    margin-top: -20px;
  `}
`
