import * as addresses from './addresses'
import * as awardedPartnership from './awarded-partnership'
import * as batchs from './batchs'
import * as brokers from './brokers'
import * as capitalizacao from './capitalizacao'
import * as contracts from './contracts'
import * as customers from './customers'
import * as elegibility from './elegibility'
import * as instance from './instance'
import * as invites from './invites'
import * as news from './news'
import * as organizations from './organizations'
import * as partners from './partners'
import * as rentCollection from './rentCollection'
import * as sms from './sms'
import * as users from './users'
import * as whiteLabel from './white-label'

const exports = {
  instance,
  users,
  invites,
  organizations,
  addresses,
  contracts,
  brokers,
  partners,
  customers,
  news,
  batchs,
  rentCollection,
  elegibility,
  sms,
  whiteLabel,
  capitalizacao,
  awardedPartnership,
}

export default exports
