import styled from 'styled-components'
import media from 'styled-media-query'
import { size } from 'polished'

import { themes } from '@/styles'

import { FeatherIcons } from '../../Icons'

interface Props {
  hidden?: boolean
}

export const Container = styled.div<Props>`
  display: inline-block;
  visibility: ${(props) => props.hidden && 'hidden'};
`

export const Field = styled.div`
  position: relative;

  > select {
    padding-right: 40px;
  }
`

export const Group = styled.optgroup``

export const Option = styled.option``

export const LoaderWrap = styled.div`
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-40%);
`

export const Arrow = {
  Box: styled.div`
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);

    display: flex;
    flex-direction: column;

    pointer-events: none;
    color: ${themes.text.low};
  `,

  Icon: styled(FeatherIcons)`
    ${size('10px')}

    &:last-of-type {
      margin-top: -4px;

      ${media.greaterThan('huge')`
        margin-top: -5px;
      `}
    }

    ${media.greaterThan('huge')`
      ${size('13px')}
    `}
  `,
}
