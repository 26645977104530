import { useState } from "react"

import { Box, Flex, Typography } from "@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base"

import { Autocomplete, Forms, toast } from "@/components"
import { api } from "@/services"
import { onboardingBanks } from "@/tools"

import { useOnboarding } from "./../context"
import { bankDataSchema } from "./../schema"
import { Card, OnboardingWrapper } from "./../ui"

const BankAutocomplete = (props: {
  onSearch,
  name: string,
  initialInnerValue?: string,
}) => {
  return (
    <Autocomplete
      initialInnerValue={props.initialInnerValue}
      name={props.name}
      label="Banco"
      placeholder="Busque por nome ou código"
      emptySearchMessage="Nenhum banco encontrado"
      onSearch={props.onSearch}
    />
  )
}

const BankingData = ({
  wizard,
  currentStep,
}) => {

  const [isLoading, setIsLoading] = useState(false)
  const onboarding = useOnboarding()

  const { attributes } = onboarding.state.form

  const handleAutocompleteSearch = async term => {
    const found = onboardingBanks.filter(
      b => (
        b.id.includes(term) ||
        b.name.toLowerCase().includes(term.toLowerCase())
      )
    )

    return found.map(b => ({
      label: `${b.id} - ${b.name}`,
      value: b.id,
      payload: b
    }))
  }

  return (
    <OnboardingWrapper
      currentStep={currentStep}
      isLoading={isLoading}
      wizard={wizard}
      formData={{
        initialValues: attributes,
        validationSchema: bankDataSchema,
        onSubmit: async (values) => {
          try {

            setIsLoading(true)

            onboarding.dispatch({
              type: 'UPDATE_FORM_ATTRIBUTES',
              payload: values
            })

            const data = await api.users.newAccount.createOrUpdateLead(onboarding.state.form)

            onboarding.dispatch({
              type: 'UPDATE_FORM_FROM_API',
              payload: data
            })

          } catch (e) {
            toast(
              `
                Ocorreu um erro inesperado. Por favor, tente novamente...
              `,
              {
                type: 'error',
              }
            )
          } finally {
            setIsLoading(false)
          }

          wizard.next()
        },
      }}
    >
      <Card>
        <Typography
          variant="porto-title-4-semibold"
          content="Dados bancários"
        />
        <Flex
          sx={{
            marginTop: "1rem",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: {
                xs: "1fr",
                md: "repeat(2, 1fr)",
              },
              gap: "1rem",
            }}
          >
            <BankAutocomplete
              onSearch={handleAutocompleteSearch}
              name="bankData.bank"
              initialInnerValue={`${attributes.bankData.bank || ''}`}
            />
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: {
                xs: "1fr",
                md: "repeat(3, 1fr)",
              },
              gap: "1rem",
            }}
          >
            <Forms.InputGroup
              name="bankData.agency"
              label="Agência sem o dígito"
              placeholder="Digite"
            />
            <Forms.InputGroup
              name="bankData.account"
              label="Número da conta sem o dígito"
              placeholder="Digite"
            />
            <Forms.InputGroup
              name="bankData.accountDigit"
              label="Dígito da conta"
              placeholder="Digite"
            />
          </Box>
        </Flex>
        <Box
          sx={{
            marginTop: "2rem",
          }}
        >
          <Typography
            variant="porto-title-6-medium"
            content="E-mail para recebimento de faturas e boletos"
          />
        </Box>
        <Box
          sx={{
            marginTop: "1rem",
            display: "grid",
            gridTemplateColumns: {
              xs: "1fr",
              md: "repeat(2, 1fr)",
            },
            gap: "1rem",
          }}
        >
          <Forms.InputGroup
            name="bankData.email"
            label="E-mail operacional"
            placeholder="nome@email.com.br"
          />
        </Box>
      </Card>
    </OnboardingWrapper>
  )
}

export default BankingData
