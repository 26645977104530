import { Box, Flex, Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { BaseCard } from '../BaseCard'
import { Card } from './Card'
import { Button } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { IBoxCardsProps } from './interfaces'

export const BoxCards = ({
  actions,
  anchor_id,
  description,
  image,
  title,
  values,
  variant = 'primary',
}: IBoxCardsProps) => (
  <BaseCard
    title={title}
    description={description}
    variant={variant}
    image={image}
    id={anchor_id}
  >
    <Flex
      sx={{
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      {values.map(({ title: itemTitle, columns = 6, cards }, index) => (
        <Box
          key={`card_BaseBox_${title}_${index}`}
        >
          {itemTitle && (
            <Typography
              content={itemTitle}
              variant="porto-title-5-medium"
            />
          )}
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: {
                xs: "1fr",
                lg: "repeat(3, 1fr)",
                xl: "repeat(4, 1fr)",
                xxl: `repeat(${columns}, 1fr)`,
              },
              gap: "1rem",
              padding: "1.5rem 0",
            }}
          >
            {cards.map((card) => (
              <Card
                key={JSON.stringify(card)}
                {...card}
              />
            ))}
          </Box>
        </Box>
      ))}
      {actions && (
        <Flex
          sx={{
            gap: "1rem",
          }}
        >
          {actions?.map(({ label, url, ...props }) => (
            <Button
              onClick={() => {
                window.open(url, '_blank')
              }}
              {...props}
            >
              {label}
            </Button>
          ))}
        </Flex>
      )}
    </Flex>
  </BaseCard>
)