import { FormikProvider, useFormik } from 'formik'
import { HomeEquityStepComponent } from '../..'
import {
  Box,
  Flex,
  Typography,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { BaseCard } from '../../components'
import { Forms } from '@/components'
import Button from '@/components/Actions/Button'
import { useEffect } from 'react'
import validationSchema from './validations'

export const HomeEquityThirdtStep = ({
  formData = {},
  nextStep = () => null,
  previousStep = () => null,
}: HomeEquityStepComponent) => {
  const form = useFormik({
    initialValues: {
      cpf: formData.cpf || '',
      name: formData.name || '',
      birthdate: formData.birthdate || '',
      marital_status: formData.marital_status || '',
      occupation: formData.occupation || '',
      income: formData.income || '',
      email: formData.email || '',
      cell_phone: formData.cell_phone || '',
    },
    validationSchema,
    onSubmit: (values) => {
      nextStep(values)
    },
  })

  const handleGoBack = () => {
    previousStep(form.values)
  }

  useEffect(() => {
    if (form.initialValues) {
      form.setValues(form.initialValues)
    }
  }, [])

  return (
    <FormikProvider value={form}>
      <Flex
        sx={{
          flexDirection: 'column',
          gap: '1rem',
          width: '100%',
        }}
      >
        <BaseCard title="Dados Pessoais">
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: { xs: '1fr', lg: '375px' },
              gridTemplateRows: { xs: '1fr 1fr', lg: '1fr' },
              gap: '1rem',
              rowGap: '4px',
              marginTop: '16px',
            }}
          >
            <Forms.InputGroup
              name="cpf"
              label=""
              mask="cpf"
              placeholder="CPF"
            />
            <Typography
              content="Em caso de pessoa jurídica, incluir CPF do sócio principal."
              variant="porto-text-caption-regular"
              sx={{
                color: 'var(--neutras-black-70, #5F5F5F)',
                marginLeft: '12px',
              }}
            />
          </Box>

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: { xs: '1fr', lg: '375px repeat(2, 1fr)' },
              gap: '1rem',
              rowGap: '4px',
              marginTop: '32px',
            }}
          >
            <Forms.InputGroup
              name="name"
              label=""
              placeholder="Nome completo"
            />

            <Forms.InputGroup
              name="birthdate"
              label=""
              placeholder="Data de nascimento"
              mask="date"
            />

            <Forms.SelectGroup
              name="marital_status"
              options={[
                { label: 'Estado civil', value: '' },
                { label: 'Solteiro', value: 'Solteiro' },
                { label: 'Casado', value: 'Casado' },
                { label: 'Divorciado(a)', value: 'Divorciado(a)' },
                { label: 'União Estável', value: 'União Estável' },
                { label: 'Viúvo(a)', value: 'Viúvo(a)' },
              ]}
            />
          </Box>

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: { xs: '1fr', lg: '375px 1fr' },
              gap: '1rem',
              rowGap: '4px',
              marginTop: '32px',
            }}
          >
            <Forms.SelectGroup
              name="occupation"
              options={[
                { label: 'Ocupação', value: '' },
                {
                  label: 'Empregado Setor Publico',
                  value: 'Empregado Setor Publico',
                },
                {
                  label: 'Empregado Setor Privado',
                  value: 'Empregado Setor Privado',
                },
                {
                  label: 'Profissional Liberal',
                  value: 'Profissional Liberal',
                },
                { label: 'Autônomo', value: 'Autônomo' },
                { label: 'Empresário', value: 'Empresário' },
                { label: 'Estudante bolsista', value: 'Estudante bolsista' },
                { label: 'Em recolocação', value: 'Em recolocação' },
                {
                  label: 'Aposentado Pensionista',
                  value: 'Aposentado Pensionista',
                },
                { label: 'Investidor', value: 'Investidor' },
              ]}
            />

            <Forms.InputGroup
              name="income"
              label=""
              mask="money"
              placeholder="Renda familiar"
            />
          </Box>
        </BaseCard>

        <BaseCard title="Dados de contato">
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: { xs: '1fr', lg: 'repeat(2, 1fr)' },
              gap: '1rem',
            }}
          >
            <Forms.InputGroup name="email" label="" placeholder="E-mail" />
            <Forms.InputGroup
              name="cell_phone"
              label=""
              mask="phone"
              placeholder="Telefone"
            />
          </Box>
        </BaseCard>
      </Flex>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', lg: 'repeat(2, 1fr)' },
          gap: '1rem',
        }}
      >
        <Button kind="ghost" onClick={handleGoBack}>
          Voltar
        </Button>

        <Button
          type="button"
          onClick={() => form.handleSubmit()}
          isDisabled={Object.keys(form.errors).length > 0}
        >
          Continuar
        </Button>
      </Box>
    </FormikProvider>
  )
}
