import styled from 'styled-components'
import media from 'styled-media-query'
import { rem } from 'polished'

import { themes } from '@/styles'
import { Icons } from '@/components'

export const Container = styled.div`
  margin-top: 1rem;
  border-radius: 8px;
  background-color: ${themes.page.colors.background};
`

export const Icon = styled(Icons.FeatherIcons)`
  margin-right: 8px;

  font-size: ${rem('10px')};
  color: ${themes.palletes.info};

  ${media.greaterThan('large')`
      font-size: ${rem('12px')};
    `}

  ${media.greaterThan('huge')`
      font-size: ${rem('16px')};
    `}
`

export const Content = styled.div`
  padding: 20px;
  display: flex;
  justify-content: flex-end;
`

export const Section = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 40px;
  }
`

export const PaginationButton = styled.button`
  background-color: transparent;
  cursor: pointer;
  border: none;
  color: ${themes.palletes.primary};

  display: flex;
  align-items: center;
  font-size: ${rem('16px')};
  font-weight: 600;
  line-height: 20px;

  & ${Icon} {
    color: currentColor;
    margin: 0 8px;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:first-of-type {
    margin-right: 2rem;
  }
`
