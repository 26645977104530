import bannerImage1 from '@/assets/images/banner-image.png'
import bannerImage2 from '@/assets/images/banner-image2.png'
import { ThirdSectionBanner } from '..'
import { BannersGrid, ThirdSectionContainer, Title } from './styles'

const ThirdSection = () => {
  return (
    <ThirdSectionContainer>
      <Title
        variant="porto-title-4-medium"
        content="Com o Portal das Imobiliárias, todo mundo ganha"
      />
      <BannersGrid>
        <ThirdSectionBanner
          backgroundColor="#EBF1EC"
          title="Vantagens para corretores"
          image={bannerImage1}
          text="Redução do trabalho operacional;<br><br>Gestão e parametrização da sua carteira de parceiros;<br><br>Possibilidade de aumento de carteira;<br><br>Agilidade na gestão de apólices."
        />
        <ThirdSectionBanner
          backgroundColor="#DFE2F2"
          title="Vantagens para a imobiliária"
          image={bannerImage2}
          text="Contratação em poucos cliques;<br><br>Agilidade na assinatura de contratos de aluguel;<br><br>Autonomia e relacionamento digital com o corretor."
        />
      </BannersGrid>
    </ThirdSectionContainer>
  )
}

export default ThirdSection
