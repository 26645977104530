import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'

import { Forms } from '@/components'
import { useCurrentOrganization } from '@/modules'

import { ImobiliariaBudget } from '../../Imobiliaria/Budgets'
import { ISuseps } from './types'
import { PartnershipSusep } from '@/services/api/partnership/types'
import { PartnershipSusepCombo } from '@/components/PartnershipSusepCombo'

interface IProducts {
  product:
    | 'porto_imobiliaria'
    | 'porto_essencial'
    | 'porto_aluguel'
    | 'porto_capitalizacao'
    | 'home_equity'
  valuesPartner?: (partner: { id?: string }) => void
  suseps?: ISuseps
  setStatus?: boolean
  gtmSubname?: string
  disabled?: boolean
}

export const parseError = {
  '@partners/invalid-document': {
    title: 'Documento ou corretor deve ser um CPF ou CNPJ válido.',
  },
  '@partners/invalid-name': {
    title: 'O campo de nome deve ter pelo menos 5 caracteres',
  },
  '@partners/partner-not-found': {
    title: 'Parceiro não encontrado',
  },
  '@partners/not-allowed': {
    title: 'O usuário não tem permissão para executar essa ação',
  },
  'Integration Error': {
    title: 'Ocorreu um erro!',
  },
  '@partners/partnership-not-found': {
    title: 'Não há vínculo com o parceiro selecionado, não é possível prosseguir',
  },
}

const Organization = ({ product, valuesPartner }: IProducts) => {
  const profile = useCurrentOrganization()
  const profileId = profile.id
  const isBroker = profile.type === 'BROKER'
  const form = useFormikContext<ImobiliariaBudget>()
  const getProduct = () => {
    return product !== 'home_equity' ? product : 'any'
  }
  const shouldShowError = Object.keys(form.touched).length > 0

  const [selectedSusep, setSelectedSusep] = useState<PartnershipSusep | null>(null)

  useEffect(() => {
    const formUpdates: { susep?: string; partner?: string } = {
      susep: selectedSusep?.susep ?? '',
    }

    if (!isBroker) {
      formUpdates.partner = profileId
    }

    valuesPartner({ id: selectedSusep?.partner.id })

    form.setValues({
      ...form.values,
      ...formUpdates,
    })
  }, [selectedSusep])

  return (
    <Forms.Card
      active
      step="1"
      title={isBroker ? 'Imobiliária e Corretora de Seguros' : 'Corretora de Seguros'}
      helper={
        isBroker
          ? 'Comece selecionando qual <b>parceiro de negócio</b> e <b>SUSEP</b> será relacionada neste orçamento.'
          : 'Selecione a <b>SUSEP</b> da <b>corretora de seguros</b> parceira.'
      }
    >
      <PartnershipSusepCombo
        selectionCallback={(value: PartnershipSusep) => {
          setSelectedSusep(() => value)
        }}
        error={{
          susep: shouldShowError ? form?.errors['susep'] : null,
          partnership: shouldShowError ? form?.errors['partner'] : null,
        }}
        sx={{
          partnership: {
            width: '100%',
          },
          susep: {
            width: '100%',
          },
          box: {},
        }}
        product={getProduct()}
      ></PartnershipSusepCombo>
    </Forms.Card>
  )
}

export default Organization
