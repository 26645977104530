import {
  First
} from "../Steps"
import { Wrapper } from '../components'

export const BudgetsScreen = ({
  enums,
  plans
}) => {
  const handleHeaderButtonClick = () => {
    window.open('https://www.portoseguro.com.br/titulo-de-capitalizacao-para-aluguel#condicoes-gerais', '_blank')
  }

  return (
    <Wrapper
      headerProps={{
        title: "Novo Título de Capitalização",
        description: "Informe os dados para gerar o orçamento.",
        action: {
          icon: "externalLink",
          kind: "ghost",
          onClick: handleHeaderButtonClick,
          label: "Condições Gerais"
        }
      }}
      stepperProps={{ currentStep: 1 }}
    >
      <First
        tenantProps={{ enums }}
        contractProps={{ plans }}
      />
    </Wrapper>
  )
}