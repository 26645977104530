import { steps } from '@/data/capitalizacao'
import {
  Box,
  Flex,
  Typography,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import {
  Button,
  Card,
  Stepper,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { Resume } from '../Resume'

export const Wrapper = ({ headerProps, children, showResume = false, stepperProps }) => {
  return (
    <>
      <Flex
        sx={{
          marginBottom: '1.5rem',
          gap: '1rem',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <Box>
          <Flex sx={{ gap: '1rem' }}>
            <Flex
              sx={{
                flexDirection: 'column',
              }}
            >
              <Typography content={headerProps.title} variant="porto-title-4-bold" />
              {headerProps.subtitle && (
                <Typography content={headerProps.subtitle} variant="porto-title-6-semibold" />
              )}
              {headerProps.description && (
                <Typography content={headerProps.description} variant="porto-text-body-1-regular" />
              )}
            </Flex>
          </Flex>
        </Box>
        {headerProps?.action && (
          <Button
            icon={headerProps.action?.icon}
            kind={headerProps.action?.kind}
            onClick={headerProps.action.onClick}
          >
            {headerProps.action.label}
          </Button>
        )}
      </Flex>
      <Flex
        sx={{
          gap: '1rem',
          justifyContent: { xs: 'center', lg: 'inherit' },
        }}
      >
        <Flex
          sx={{
            maxWidth: '760px',
            width: '100%',
            flexDirection: 'column',
            gap: '1rem',
          }}
        >
          {stepperProps && (
            <Card>
              <Stepper currentStep={stepperProps.currentStep} steps={steps} />
            </Card>
          )}
          {children}
        </Flex>

        {showResume && <Resume />}
      </Flex>
    </>
  )
}
