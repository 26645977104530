import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { Contract } from '@/modules/contract/interface'
import { listContracts } from '@/services/api/contracts'
import { Products } from '@/screens/Contracts/Products/shared/entities'
import { FilterOption } from '@/screens/Budgets/List/types'
import { toast } from '@/components'

type ProviderProps = {
  totalPages: number
  children: React.ReactNode
  product: Products
  filters: FilterOption
}

const INITIAL_PAGE = 1
const DEFAULT_QUANTITY = 10

const Context = createContext({
  contracts: [] as Contract<any>[],
  page: 0,
  quantity: 0,
  isPreviousDisabled: true,
  isNextDisabled: true,
  updating: [] as string[],
  isLoading: false,

  onNextPage: () => {},
  onPreviousPage: () => {},
  onChangeQuantity: (newQuantity: number) => {},
  updateContractData: (contract: Contract<any>) => {},

  pushUpdate: (...ids: string[]) => {},
  finishUpdate: (id: string) => {},
})

export const PaginationProvider = ({ children, totalPages, product, filters }: ProviderProps) => {
  const [page, setPage] = useState(INITIAL_PAGE)
  const [quantity, setQuantity] = useState(DEFAULT_QUANTITY)
  const [updatingContracts, setUpdatingContracts] = useState<string[]>([])
  const [contracts, setContracts] = useState<Contract<any>[]>([])

  const { isFetching: isLoading } = useQuery(
    ['contracts', { quantity, page, product, ...filters }],
    () => listContracts({ page, limit: quantity, product, ...filters }),
    {
      refetchOnWindowFocus: false,
      onSuccess: setContracts,
      onError: () => {
        toast('Ocorreu um erro ao buscar os contratos.', {
          type: 'error',
        })
        setContracts([])
      },
    },
  )

  useEffect(() => {
    if (product || filters) setPage(INITIAL_PAGE)
  }, [product, filters])

  const onPreviousPage = useCallback(() => {
    if (page === INITIAL_PAGE) return

    setPage(currentPage => currentPage - 1)
  }, [page])

  const onNextPage = useCallback(() => {
    if (page === totalPages) return

    setPage(currentPage => currentPage + 1)
  }, [page, totalPages])

  const onChangeQuantity = useCallback(
    (newQuantity: number) => {
      if (newQuantity === quantity) return

      setPage(INITIAL_PAGE)
      setQuantity(newQuantity)
    },
    [quantity],
  )

  const pushUpdate = (...ids: string[]) => {
    setUpdatingContracts(value => [...value, ...ids])
  }

  const updateContractData = (contract: Contract<any>) => {
    const index = contracts.findIndex(({ id }) => id === contract.id)

    if (index !== -1) {
      contracts[index] = contract
      contracts.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
      setContracts([...contracts])
    }
  }

  const finishUpdate = (id: string) => {
    setUpdatingContracts(value => value.filter(e => e !== id))
  }

  return (
    <Context.Provider
      children={children}
      value={{
        contracts,
        page,
        quantity,
        isPreviousDisabled: page === INITIAL_PAGE,
        isNextDisabled: contracts.length < quantity,
        updating: updatingContracts,
        isLoading,

        updateContractData,

        onNextPage,
        onPreviousPage,
        onChangeQuantity,

        pushUpdate,

        finishUpdate,
      }}
    />
  )
}

export function usePagination() {
  return useContext(Context)
}
