import { Flex, Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'

import { Loader } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import * as S from './styles'

const Splash = () => {
  return (
    <S.Container>
      <Flex
        sx={{
          flexDirection: 'column',
          gap: '48px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            gap: '16px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="porto-title-4-bold">Carregando os dados da organização</Typography>
          <Typography variant="porto-text-body-1-regular">Por favor, aguarde...</Typography>
        </Flex>

        <Loader size="48px" />
      </Flex>
    </S.Container>
  )
}

export default Splash
