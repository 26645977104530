import React from 'react'
import { useHistory } from 'react-router-dom'

import { Conditional, Tooltip, Icons } from '@/components'
import { firebaseMedia } from '@/tools'

import { useOrganizations, useCurrentUser } from '../..'

import * as S from './styles'

const ProfileBar = () => {
  const history = useHistory()

  const user = useCurrentUser()
  const organizations = useOrganizations()

  return (
    <S.Container>
      <S.Organizations>
        <S.Back
          onClick={() => organizations.unsetActiveOrganization()}
          data-gtm-type="click"
          data-gtm-clicktype="button"
          data-gtm-name="voltar-a-selecao-do-perfil"
        >
          <Tooltip content="Voltar à seleção de perfil" placement="right">
            <Icons.FeatherIcons name="grid" height={20} />
          </Tooltip>
        </S.Back>
        {organizations.store.current.slice(0, 4).map((organization) => {
          const active = organizations.store.active.id
          if (user.store.isAdmin && organization.id !== active) return null

          return (
            <S.Organization
              key={organization.id}
              active={organization.id === organizations.store.active?.id}
              onClick={async () => {
                try {
                  await organizations.setActiveOrganization(organization.id)
                  history.push(`/`)
                } catch (e) {}
              }}
            >
              <Tooltip
                content={
                  <S.Tip>
                    <small>
                      {
                        {
                          BROKER: 'Corretora',
                          REAL_ESTATE: 'Imobiliária',
                        }[organization.type]
                      }
                    </small>
                    <span>{organization.name}</span>
                  </S.Tip>
                }
                placement="right"
              >
                <S.OrganizationContent>
                  {organization.photo ? (
                    <S.Logo src={organization.photo} />
                  ) : (
                    <S.Initials>{organization.name.substr(0, 3)}</S.Initials>
                  )}
                </S.OrganizationContent>
              </Tooltip>
            </S.Organization>
          )
        })}
        <Conditional when={organizations.store.current.length > 4}>
          <S.Back onClick={() => organizations.unsetActiveOrganization()}>
            <Tooltip content="Ver todos" placement="right">
              <S.OrganizationContent>
                <S.Initials>
                  +{organizations.store.current.length - 4}
                </S.Initials>
              </S.OrganizationContent>
            </Tooltip>
          </S.Back>
        </Conditional>
      </S.Organizations>
      <S.Divider />

      <S.Footer>
        <S.User.Box
          to="/minha-conta"
          data-gtm-type="click"
          data-gtm-clicktype="button"
          data-gtm-name="minha-conta"
        >
          <Tooltip
            content={
              <S.Tip>
                <small>Minha conta</small>
                <span>{user.store.name}</span>
              </S.Tip>
            }
            placement="right"
          >
            <S.User.Avatar
              src={
                user.store.avatar || firebaseMedia('images/user-default.jpg')
              }
            />
          </Tooltip>
        </S.User.Box>

        <Conditional when={user.store.isAdmin}>
          <S.Back
            onClick={() => {
              history.replace('/')
              organizations.resetActiveOrganization()
            }}
          >
            <Tooltip content="Voltar ao painel de controle" placement="right">
              <Icons.Ionicons name="settings-outline" />
            </Tooltip>
          </S.Back>
        </Conditional>
      </S.Footer>
    </S.Container>
  )
}

export default ProfileBar
