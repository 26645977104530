import { AnimatePresence } from 'framer-motion'
import React, { useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'

import { useOutsideClick } from '@/tools'

import { IPopoverProps } from './interfaces'

import { Container, Icon, Item, List, Overlay, Title } from './styles'

const overlay = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { ease: [0.16, 1, 0.3, 1] },
  },
}

const list = {
  hidden: { height: 0, opacity: 0 },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.2,
    },
  },
  visible: {
    height: 'auto',
    opacity: 1,
    transition: { ease: [0.16, 1, 0.3, 1] },
  },
}

const Popover: React.FC<IPopoverProps> = (props) => {
  const ref = useRef()

  const [visible, setVisible] = useState(false)

  useOutsideClick(ref, () => {
    setVisible(false)
  })

  const toggle = (event) => {
    event.stopPropagation()

    setVisible((state) => !state)
  }

  return (
    <Container onClick={toggle}>
      <AnimatePresence>
        {visible && (
          <>
            {isMobile && (
              <Overlay
                exit="hidden"
                initial="hidden"
                animate="visible"
                variants={overlay}
              />
            )}

            <List
              ref={ref}
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={list}
            >
              {isMobile && props.title && <Title>{props.title}</Title>}

              {props.items.map((item, index) => (
                <Item key={index} onClick={item.onClick}>
                  {item.icon && <Icon name={item.icon} />}

                  {item.label}
                </Item>
              ))}
            </List>
          </>
        )}
      </AnimatePresence>

      {props.children}
    </Container>
  )
}

export default Popover
