import React from 'react'

import { Content, Tabs } from '@/components'

import { Container, Main } from './styles'

const tabs = {
  Contract: React.lazy(() => import('./components/Contract')),
  Budgets: React.lazy(() => import('./components/Budgets')),
  History: React.lazy(() => import('./components/History')),
}

const Details = () => {
  const { Heading } = Content

  return (
    <Container>
      <Heading
        title="Consultas"
        subtitle="Analise todo o processo de contrato."
        actions={[
          {
            icon: 'copy',
            kind: 'smooth',
            label: 'Duplicar orçamento',
            onClick: console.log,
          },
          {
            icon: 'printer',
            label: 'Imprimir consulta',
            onClick: console.log,
          },
        ]}
      />

      <Main>
        <Tabs
          enableAnchor
          items={[
            {
              key: 'contrato',
              icon: 'document',
              title: 'Contrato',
              content: () => <tabs.Contract />,
            },
            {
              key: 'orcamentos',
              icon: 'pricetags',
              title: 'Orçamentos',
              content: () => <tabs.Budgets />,
            },
            {
              key: 'historico',
              icon: 'time',
              title: 'Histórico',
              content: () => <tabs.History />,
            },
          ]}
        />
      </Main>
    </Container>
  )
}

export default Details
