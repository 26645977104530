import { useField } from 'formik'
import {
  Typography,
  ReadMore,
  Flex,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { RadioGroup } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { BaseCard } from '../../../../components/BaseCard'
import { benefitsCardData } from '@/data/capitalizacao'
import { Benefits } from './_Benefits'
import { useEffect } from 'react'

const formatPlansOptions = plans =>
  plans.map(({ id, validity, rescue }) => ({
    text: `${validity} meses (resgate de ${rescue}% do valor).`,
    value: id,
  }))

export const Contract = ({ plans }) => {
  const { setValue: setPlanValue } = useField('contract.plan_id')[2]
  const [{ value: rescueValue }, rescueMeta, { setValue: setPlanRescue }] =
    useField('contract.plan_rescue')
  const { setValue: setPlanValidity } = useField('contract.plan_validity')[2]

  const handleValues = data => {
    setPlanValue(data?.id)
    setPlanRescue(data?.rescue)
    setPlanValidity(data?.validity)
  }

  const formattedPlansOptions = formatPlansOptions(plans)

  useEffect(() => {
    setTimeout(() => {
      handleValues(plans[0])
    }, 250)
  }, [])

  return (
    <BaseCard
      title="Contrato"
      subtitle="Selecione o plano conforme o período do contrato de aluguel."
    >
      <Flex
        sx={{
          gap: '1rem',
          flexDirection: { xs: 'column', lg: 'row' },
        }}
      >
        <Flex
          sx={{
            flexDirection: 'column',
          }}
        >
          <ReadMore data-test-id="contract-see-more" closedLabel="Ver mais" hideLabelAfterOpened height="222" hideFade>
            <RadioGroup
              options={formattedPlansOptions}
              initialChecked={plans[0]?.id}
              onChange={value => {
                const selectedPlan = plans.find(({ id }) => id === parseInt(value))
                if (selectedPlan) {
                  handleValues(selectedPlan)
                }
              }}
              titleProps={{ content: 'Plano' }}
              data-test-id="contract-plan"
            />
          </ReadMore>
          <Flex
            sx={{
              flexDirection: 'column',
              gap: '1rem',
              marginTop: '2rem',
              width: { lg: '340px' },
            }}
          >
            <Typography content="Vigência do título" variant="porto-title-6-medium" />
            <Typography
              content="O título será emitido e ficará vigente após a confirmação do pagamento no sistema. A vigência será de acordo com o plano contratado."
              variant="porto-text-caption-regular"
              sx={{ color: 'var(--system-primitive-neutral-700)' }}
            />
          </Flex>
        </Flex>
        <Benefits benefits={benefitsCardData} rescuePercentage={rescueValue} />
      </Flex>
    </BaseCard>
  )
}
