import { useFormik, FormikProvider, Form } from 'formik'
import { Modals, Grid, Forms } from '@/components'
import { tenantsSchema } from '../../schema'

import dayjs from 'dayjs'
import { useEffect } from 'react'
import { useGTM } from '@/tools'
import { formatToCPF, formatToPhone } from 'brazilian-values'
namespace TenantRegister {
  export interface Values {
    initialValues: {
      phone: string
      name: string
      document: string
      birthdate: string
      email: string
      pep: string
    }
  }

  export type Props =
    Modals.Promised.PromisedModalProps<TenantRegister.Values> &
      TenantRegister.Values

  const Component = (props: TenantRegister.Props) => {
    const isEditing = !!props.initialValues

    const { setDataLayer } = useGTM()

    useEffect(() => {
      setDataLayer({
        event: 'modal',
        acao: 'abrir',
        nome: isEditing ? 'editar-inquilino' : 'adicionar-inquilino',
      })
    }, [])

    const onSubmit = (values) => {
      setDataLayer({
        event: 'modal',
        acao: 'fechar',
        nome: isEditing ? 'editar-inquilino' : 'adicionar-inquilino',
      })
      props.onSubmit(values)
    }

    const form = useFormik({
      initialValues: {
        phone: !!props.initialValues.phone
          ? formatToPhone(props.initialValues.phone)
          : '',
        name: props.initialValues.name,
        document: formatToCPF(props.initialValues.document),
        birthdate: dayjs(props.initialValues.birthdate).format('DD/MM/YYYY'),
        email: props.initialValues.email,
        pep: '1',
      },
      validationSchema: tenantsSchema,
      onSubmit,
    })

    return (
      <Modals.Content
        icon={isEditing ? 'person' : 'person-add'}
        title={form.values.name || 'Adicionar novo inquilino'}
        size="large"
        onSubmit={{
          text: isEditing ? 'Salvar alterações' : 'Adicionar inquilino',
          onClick: () => form.handleSubmit(),
        }}
        onCancel={{
          text: 'Cancelar',
          onClick: () => {
            setDataLayer({
              event: 'modal',
              acao: 'fechar',
              nome: isEditing ? 'editar-inquilino' : 'adicionar-inquilino',
            })
            props.onDismiss()
          },
        }}
      >
        <FormikProvider value={form}>
          <Form>
            <Grid columns="repeat(3, 1fr)" gap="1rem">
              <Forms.InputGroup
                name="document"
                label="CPF"
                placeholder="Digite o CPF"
                mask="cpf"
                readOnly
                data-gtm-form="input"
                data-gtm-name="cpf"
              />

              <Forms.InputGroup
                name="name"
                label="Nome"
                placeholder="Digite o nome"
                data-gtm-form="input"
                data-gtm-name="nome"
              />

              <Forms.InputGroup
                name="birthdate"
                label="Data de nascimento"
                placeholder="Digite a data de nascimento"
                mask="date"
                data-gtm-form="input"
                data-gtm-name="data-de-nascimento"
              />
            </Grid>

            <Grid columns="repeat(3, 1fr)" gap="1rem" space="1rem 0">
              <Forms.InputGroup
                name="phone"
                label="Telefone/celular"
                placeholder="Digite o telefone/celular"
                mask="phone"
                readOnly
                data-gtm-form="input"
                data-gtm-name="telefone"
              />

              <Forms.InputGroup
                name="email"
                label="E-mail"
                placeholder="Digite o e-mail"
                readOnly
                data-gtm-form="input"
                data-gtm-name="email"
              />

              <Forms.SelectGroup
                name="pep"
                label="Pessoa Politicamente Exposta"
                defaultValue="1"
                data-gtm-type="select"
                data-gtm-name="pessoa-publicamente-exposta"
                data-gtm-subname="seguro-fianca-essencial-inquilino"
                options={[
                  {
                    label: 'Não',
                    value: '1',
                  },
                  {
                    label: 'Sim',
                    value: '2',
                  },
                ]}
              />
            </Grid>
          </Form>
        </FormikProvider>
      </Modals.Content>
    )
  }

  export const openModal =
    Modals.Promised.createModal<TenantRegister.Props>(Component)
}

export default TenantRegister
