import { useUnit } from 'effector-react'
import { AnimatePresence } from 'framer-motion'

import { SplashStore } from '../store'

import Splash from './ui/Splash'

export const SplashProvider = (props) => {
  const { isVisible } = useUnit(SplashStore)

  return (
    <>
      <AnimatePresence>{isVisible && <Splash />}</AnimatePresence>
      {props.children}
    </>
  )
}
