import styled from 'styled-components'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { css } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/_shared'
import breakpoints from '@pol-npm/riscos-financeiros-dashboard-ui/dist/theme/breakpoints'

export const Container = styled.div<{ $backgroundColor: string }>`
  background: ${(props) => props.$backgroundColor};
  border-radius: 12px;
  border: 1px solid var(--system-primitive-neutral-200);
  display: grid;
  grid-template-columns: 16px 1fr 32px 1fr;
  grid-template-rows: 16px;
  padding: 16px;

  @media (max-width: ${breakpoints.md}) {
    grid-template-columns: 8px 1fr 8px;
    grid-template-rows: 8px auto auto minmax(40px, 1fr);
  }
`

export const Title = styled(Typography)(
  css({
    sx: {
      gridColumnStart: 2,
      gridRowStart: 2,
    },
  })
)

export const Text = styled(Typography)(
  css({
    sx: { gridColumnStart: 2, marginTop: '8px', gridRowStart: 3 },
  })
)

export const Image = styled.img`
  border-radius: 12px;
  grid-column-start: 4;
  grid-row: 1 / span 4;
  max-height: 300px;
  object-fit: cover;
  width: 100%;

  @media (max-width: ${breakpoints.md}) {
    grid-column: 1 / -1;
    grid-row: 5 / -1;
  }
`
