import Excel from 'exceljs'
import { colors, dataTable } from './data-table'
import { saveAs } from 'file-saver'

export const downloadSheet = async () => {
  const workbook = new Excel.Workbook()
  const worksheet = workbook.addWorksheet('Planilha1')

  worksheet.columns = dataTable
  const header = worksheet.getRow(1)

  worksheet.columns.forEach((column) => {
    column.alignment = { vertical: 'middle', horizontal: 'center' }
  })

  const dueDateColumnLetter = worksheet.getColumn('dueDate').letter

  worksheet.dataValidations.add(
    `${dueDateColumnLetter}2:${dueDateColumnLetter}99`,
    {
      type: 'date',
      allowBlank: false,
      formulae: [new Date()],
      operator: 'lessThanOrEqual',
      showErrorMessage: true,
    }
  )

  header.eachCell((cell) => {
    const cellIndex = Number(cell.col) - 1

    cell.fill = { ...colors[dataTable[cellIndex].config.color] }
    cell.font = { bold: true, color: { argb: 'FFFFFFFF' } }

    if (!dataTable[cellIndex].cellNote) return
    cell.note = dataTable[cellIndex].cellNote
  })

  const buffer = await workbook.xlsx.writeBuffer()
  const blob = new Blob([buffer], { type: 'buffer' })

  saveAs(blob, 'PlanilhaModeloAssessoriaCobranca.xlsx')
}
