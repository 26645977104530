import {
  Box,
  Flex,
  Typography,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { HomeEquityResultComponent } from '../index'
import Button from '@/components/Actions/Button'
import {
  TopButtonsContainer,
  ErrorButtonContainer,
  EnviarPropostaButtonContainer,
} from './styles'
import {
  Loader,
  Tabs,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { ResultCard } from './components/ResultCard'
import { useEffect, useMemo, useState } from 'react'
import {
  processPayload,
  registerHomeEquityPayload,
} from '../../../../services/api/homeEquity'
import { BRLMoneyToNumber, formatMoneyBRL } from '@/tools'
import {
  HomeEquityOfferPayload,
  HomeEquityOfferPayloads,
} from '@/services/api/homeEquity/types'
import { InstallmentsTable } from './components/InstallmentsTable'
import { useCurrentUser, useCurrentOrganization } from '@/modules'
import ResultModal from './components/ResultModal'
import ResultErrorModal from './components/ResultErrorModal'
import { InstallmentsTableSummary } from './components/InstallmentsTableSummary'
import { api } from '@/services'
import { useQuery } from 'react-query'
import { CardCartaoPorto } from '@/components/CardCartaoPorto'
import { Conditional } from '@/components'
import { FormikProvider, useFormik } from 'formik'
import { AccordionCard } from './components/AccordionCard'

export const HomeEquityResult = ({
  formData = {},
  restart = () => null,
}: HomeEquityResultComponent) => {
  const depreciationSystems: {
    label: string
    value: string
    data: any
    payload_id?: string
  }[] = [
    { label: 'Tabela SAC', value: 'SAC', data: {} },
    { label: 'Tabela Price', value: 'PRICE', data: {} },
  ]

  const formatStringDateToSend = (date: string): string => {
    return date.split('/').reverse().join('-')
  }

  const removeCpfMask = (cpfMasked: string): string => {
    return cpfMasked.replaceAll(/[^0-9]/gm, '')
  }

  const formatNumberToStringPercent = (percent: number): string => {
    return `${(percent * 10).toLocaleString()}%`
  }

  const { data, isLoading: isLoadingElegibility } = useQuery(
    ['elegibility', 'verifyElegibility', formData?.cpf],
    () =>
      api.elegibility.verifyElegibilityFromPerson({
        persons: [
          {
            document: formData?.cpf || '',
            email: formData.email || '',
            name: formData.name || '',
            phone: formData.phone || '',
          },
        ],
        partner: formData?.partner_id || '',
        susep: formData.susep || '',
        product: 'HOME_EQUITY',
      }),
    { enabled: !!formData?.cpf, refetchOnWindowFocus: false }
  )

  const personsApproved = useMemo(
    () => data?.filter((persons) => persons.isElegibleCard) || [],
    [data]
  )

  const form = useFormik({
    onSubmit: () => undefined,
    initialValues: {
      send_sms_cartao_porto: false,
    },
  })

  const [isProposalLoading, setProposalLoading] = useState(false)
  const [proposalSendAttempts, setProposalAttempts] = useState(0)
  const [isProposalSended, setProposalSended] = useState(false)
  const [selectedDepreciationSystem, setSelectedDepreciationSystem] = useState(
    depreciationSystems[0].value
  )
  const [depreciationSystemOfferData, setDepreciationSystemOfferData] =
    useState({})
  const [selectedDepreciationSystemData, setSelectedDepreciationSystemData] =
    useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [proposalError, setProposalError] = useState(false)
  const profile = useCurrentOrganization()
  const isBroker = profile.type === 'BROKER'
  const { store: userStore } = useCurrentUser()
  let loading: boolean = false

  const setLoading = (loadingStatus: boolean): void => {
    loading = loadingStatus
    setIsLoading(() => loading)
  }

  const handleRestart = () => {
    restart()
  }

  const handleSendProposal = async () => {
    if (form.values.send_sms_cartao_porto) {
      api.sms
        .sendSmsApproveCard({
          document: formData.cpf,
          from: 'HOME_EQUITY',
        })
        .catch((error) => {
          console.error(error)
        })
    }

    const { id } = selectedDepreciationSystemData

    if (!id || proposalSendAttemptsReachedLimit()) {
      return
    }

    setProposalAttempts((prevProposalAttempts) => {
      return prevProposalAttempts + 1
    })

    setProposalLoading(() => true)
  }

  const sendProposal = async () => {
    try {
      const priceProposalId = depreciationSystemOfferData['PRICE']?.id

      await processPayload(priceProposalId)

      setProposalLoading(() => false)
      setProposalSended(() => true)

      const voltar = await ResultModal()

      if (!voltar) {
        handleRestart()
      }
    } catch (e) {
      setProposalLoading(() => false)

      const tryAgain = await ResultErrorModal({
        blockRetry: proposalSendAttemptsReachedLimit(),
      })

      if (tryAgain) {
        handleSendProposal()
      }
    }
  }

  const proposalSendAttemptsReachedLimit = (): boolean => {
    return proposalSendAttempts > 2 || false
  }

  useEffect(() => {
    const { id } = selectedDepreciationSystemData || {}

    if (!id || !isProposalLoading) {
      return
    }

    sendProposal()
  }, [isProposalLoading])

  const isProposalUnsendable = () => {
    return (
      isProposalLoading ||
      isProposalSended ||
      proposalSendAttemptsReachedLimit()
    )
  }

  const isProposalSending = () => {
    return isProposalLoading
  }

  const handleDepreciationSystemChange = (key) => {
    setSelectedDepreciationSystem(
      () => depreciationSystems[key].value || depreciationSystems[0].value
    )
  }

  useEffect(() => {
    updateSelectedDepreciationSystemData()
  }, [selectedDepreciationSystem])

  const getUserNameParts = (): { firstName: string; lastName: string } => {
    const userFullname = userStore?.name || ''

    const nameParts = userFullname.split(' ')

    const firstName = nameParts.shift()
    const lastName = nameParts.join(' ')

    return {
      firstName,
      lastName,
    }
  }

  const buildAgentPayload = () => {
    const userName = getUserNameParts()

    return {
      login: formData?.susep,
      first_name: userName.firstName,
      last_name: userName.lastName,
      agent_id: formData?.partner_id,
    }
  }

  const sendOfferPayload = async () => {
    if (loading) {
      return
    }

    setLoading(true)
    setProposalError(false)

    const payloads: HomeEquityOfferPayloads = {
      payloads: [],
    }

    for (const depreciationSystem of depreciationSystems) {
      const depreciationSystemPayload: HomeEquityOfferPayload = {
        consumer: {
          birth_date: formatStringDateToSend(formData.birthdate),
          address_zip_code: formData.zipcode,
          cell_phone: formData.cell_phone,
          cpf: removeCpfMask(formData.cpf),
          email: formData.email,
          income: BRLMoneyToNumber(formData.income),
          marital_status: formData.marital_status,
          name: formData.name,
          occupation: formData.occupation,
        },
        simulation_parameters: {
          contract_type: 'Pessoa Física',
          depreciation_system: depreciationSystem.value,
          ensurance_co: 'ZURICH',
          ensurance_flag: true,
          expenses_flag: true,
          grace_period_days: Number(formData.grace_period_days),
          guarantee_fee_flag: true,
          has_property_guarantee: true,
          installments: Number(formData.deadline_months),
          IOF_flag: true,
          noraty_flag: true,
          usage: formData.usage,
          operation_type: 'REFINANCIAMENTO',
          property_type: formData.property_type,
          property_value: BRLMoneyToNumber(formData.property_value),
          property_zip_code: formData.zipcode,
          rate: 0.0124,
          registration_fee_flag: true,
          value: BRLMoneyToNumber(formData.loan_value),
          ITB_aliquot: 0.04,
        },
        agent: buildAgentPayload(),
      }

      payloads.payloads.push(depreciationSystemPayload)
    }

    try {
      const offerResults = await registerHomeEquityPayload(payloads)

      for (const offerResult of offerResults?.proposals) {
        populateOfferResult(offerResult)
      }
    } catch (e) {
      setProposalError(true)
    }

    setLoading(false)
  }

  const updateSelectedDepreciationSystemData = () => {
    setSelectedDepreciationSystemData(
      () => depreciationSystemOfferData[selectedDepreciationSystem]
    )
  }

  const formatInstallmentsDate = (installmentDate: string): string => {
    return installmentDate.split('-').reverse().join('/')
  }

  const formatInstallmentsList = (resultData: any): [] => {
    const installmentsList = resultData.datas.map((installmentDate, index) => {
      return {
        id: index,
        installmentDate: formatInstallmentsDate(installmentDate),
        installmentNumber:
          resultData.numeroParcelas[index] !== ''
            ? resultData.numeroParcelas[index]
            : '-',
        amortization: formatMoneyBRL(resultData.amortizacao[index]),
        fees: formatMoneyBRL(resultData.juros[index]),
        mip: formatMoneyBRL(resultData.MPI[index]),
        dfi: formatMoneyBRL(resultData.DFI[index]),
        installmentValue: formatMoneyBRL(resultData.valorParcelas[index]),
        balance: formatMoneyBRL(resultData.saldo[index]),
      }
    })

    return installmentsList
  }

  const populateOfferResult = (offerResult: any) => {
    const offerResultDepreciationSystem =
      offerResult?.payload?.simulation_parameters?.depreciation_system
    if (!offerResultDepreciationSystem) {
      return
    }

    const offerData = {}
    offerData[offerResultDepreciationSystem] = offerResult?.offer?.success
    offerData[offerResultDepreciationSystem].id = offerResult?.id
    offerData[offerResultDepreciationSystem].depreciationSystem =
      offerResultDepreciationSystem
    offerData[offerResultDepreciationSystem].installmentsList =
      formatInstallmentsList(offerResult?.offer?.success?.result_data)

    setDepreciationSystemOfferData((prevDepreciationSystemOfferData) => {
      return {
        ...prevDepreciationSystemOfferData,
        ...offerData,
      }
    })
  }

  useEffect(() => {
    updateSelectedDepreciationSystemData()
  }, [depreciationSystemOfferData])

  useEffect(() => {
    sendOfferPayload()

    return () => {}
  }, [])

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        gap: '1rem',
        width: '100%',
      }}
    >
      {proposalError && (
        <Flex
          sx={{
            alignItems: 'left',
            justifyContent: 'left',
          }}
        >
          <Box
            sx={{
              backgroundColor: 'var(--porto-primitive-white)',
              border: '1px solid var(--porto-primitive-black-15)',
              width: '60%',
              padding: '1.75rem',
              borderRadius: '.75rem',
            }}
          >
            <Flex
              sx={{
                flexDirection: 'column',
                gap: '.5rem',
                marginBottom: '1.75rem',
              }}
            >
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: { xs: '1fr', lg: '40px 1fr' },
                  gap: '1rem',
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="36"
                  viewBox="0 0 40 36"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18.6082 3.09246C18.6082 3.09245 18.6082 3.09247 18.6082 3.09246L2.36502 31.2879C1.7407 32.3716 2.52483 33.7145 3.75689 33.7145H36.2433C37.4753 33.7145 38.2594 32.3716 37.6352 31.2879L21.392 3.09246C20.7724 2.01709 19.2277 2.0171 18.6082 3.09246ZM16.7514 2.02275C18.1953 -0.483494 21.8049 -0.483493 23.2487 2.02275L39.4919 30.2182C40.9363 32.7255 39.1299 35.8574 36.2433 35.8574H3.75689C0.870344 35.8574 -0.936156 32.7255 0.508246 30.2182L16.7514 2.02275Z"
                    fill="#FF5A40"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M19.7843 10.8574C20.3854 10.8574 20.9865 11.3584 20.9865 11.9595V23.3808C20.9865 23.9819 20.4856 24.4828 19.7843 24.4828C19.1832 24.4828 18.582 23.9819 18.582 23.3808V11.9595C18.582 11.3584 19.083 10.8574 19.7843 10.8574Z"
                    fill="#FF5A40"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M19.7855 28.6901C20.4868 28.6901 20.9877 28.089 20.9877 27.4879C20.9877 26.7866 20.3866 26.2856 19.7855 26.2856C19.0842 26.2856 18.5832 26.8868 18.5832 27.4879C18.4831 28.089 19.0842 28.6901 19.7855 28.6901Z"
                    fill="#FF5A40"
                  />
                </svg>
                <Typography
                  content="Algo deu errado"
                  variant="porto-title-4-medium"
                  color="var(--neutras-black-75, #404040)"
                />
              </Box>
              <Typography
                content="Não foi possível concluir a sua solicitação. Por favor, tente novamente."
                variant="porto-text-body-1-regular"
                color="porto-primitive-black-65"
                sx={{
                  marginTop: '24px',
                }}
              />

              <ErrorButtonContainer>
                <Button onClick={handleRestart}>Refazer simulação</Button>
              </ErrorButtonContainer>
            </Flex>
          </Box>
        </Flex>
      )}

      {isLoading && (
        <Flex
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '64px',
            }}
          >
            <Loader size="4rem" color="porto-primitive-black-65" />

            <Typography
              content="Processando sua solicitação. Isso pode levar alguns segundos."
              variant="porto-text-body-1-regular"
              color="porto-primitive-black-65"
              sx={{
                marginTop: '32px',
              }}
            />

            <Typography
              content="Por favor, aguarde."
              variant="porto-text-body-1-regular"
              color="porto-primitive-black-65"
              sx={{
                marginTop: '8px',
              }}
            />
          </Box>
        </Flex>
      )}

      {!isLoading && !proposalError && (
        <Flex
          sx={{
            flexDirection: 'column',
            gap: '1rem',
            width: '100%',
          }}
        >
          <Typography
            content="Resultado"
            variant="porto-title-4-semibold"
            color="porto-primitive-black-85"
          />

          <Typography
            content="Sua simulação foi concluída. Caso queira, você pode compartilhar com seu cliente."
            variant="porto-text-body-1-regular"
            color="porto-primitive-black-65"
          />

          <TopButtonsContainer>
            <Button kind="ghost" onClick={handleRestart}>
              Refazer simulação
            </Button>
          </TopButtonsContainer>

          <EnviarPropostaButtonContainer>
            <Button
              onClick={handleSendProposal}
              isDisabled={isProposalUnsendable()}
              isLoading={isProposalSending()}
            >
              Enviar pré-proposta
            </Button>
          </EnviarPropostaButtonContainer>

          <InstallmentsTableSummary
            installmentsData={depreciationSystemOfferData}
          ></InstallmentsTableSummary>

          <Tabs
            tabs={depreciationSystems}
            onChange={handleDepreciationSystemChange}
          ></Tabs>

          <ResultCard
            title="Parceria"
            leftInfos={
              isBroker
                ? {
                    label: '',
                    value: `${formData.partner_name}`,
                  }
                : {
                    label: '',
                    value: `${formData.susep} - ${formData.susep_name}`,
                  }
            }
            rightInfos={{
              leftItens: isBroker
                ? [{ label: 'Susep selecionada', value: formData.susep }]
                : [],
              rightItens: [],
            }}
          />

          <ResultCard
            title="Dados gerais do cliente"
            leftInfos={{
              label: 'Pessoa física',
              value: formData.name,
            }}
            rightInfos={{
              leftItens: [
                { label: 'CPF', value: formData.cpf },
                { label: 'Telefone', value: formData.cell_phone },
                { label: 'Renda familiar', value: formData.income },
              ],
              rightItens: [
                { label: 'Nascimento', value: formData.birthdate },
                { label: 'E-mail', value: formData.email },
              ],
            }}
          >
            <Conditional
              when={!isLoadingElegibility && !!personsApproved.length}
            >
              <FormikProvider value={form}>
                <CardCartaoPorto />
              </FormikProvider>
            </Conditional>
          </ResultCard>

          <ResultCard
            title="Dados do imóvel"
            leftInfos={{
              label: formData.zipcode,
              value: formData.address,
            }}
            rightInfos={{
              leftItens: [
                { label: 'Tipo de imóvel', value: formData.property_type },
              ],
              rightItens: [
                { label: 'Valor do imóvel', value: formData.property_value },
              ],
            }}
          />

          <ResultCard
            expanded={true}
            title="Valores disponíveis"
            leftInfos={{
              label: '',
              value: 'Crédito com Garantia de Imóvel',
            }}
            rightInfos={{
              leftItens: [
                {
                  label: 'Total do empréstimo',
                  value:
                    formatMoneyBRL(
                      selectedDepreciationSystemData?.result_data
                        .valorTotalFinanciado
                    ) || '',
                },
                {
                  label: 'IOF',
                  value:
                    formatMoneyBRL(
                      selectedDepreciationSystemData?.result_data.IOF
                    ) || '',
                },
                {
                  label: 'Juros mensais',
                  value:
                    formatNumberToStringPercent(
                      selectedDepreciationSystemData?.result_data
                        .tax_rate_percent_monthly
                    ) || '',
                },
                {
                  label: 'Carência',
                  value: `${formData.grace_period_days} dias`,
                },
              ],
              rightItens: [
                {
                  label: 'Valor primeira prestação',
                  value:
                    formatMoneyBRL(
                      selectedDepreciationSystemData?.result_data
                        .valorDaPrestacao
                    ) || '',
                },
                {
                  label: 'Tabela de juros',
                  value:
                    selectedDepreciationSystemData?.depreciationSystem || '',
                },
                {
                  label: 'CET Anual',
                  value: selectedDepreciationSystemData?.result_data.cetAnual
                    ? `${(selectedDepreciationSystemData?.result_data.cetAnual).toLocaleString()}%`
                    : '',
                },
                {
                  label: 'Renda necessária',
                  value:
                    formatMoneyBRL(
                      selectedDepreciationSystemData?.result_data
                        .rendaMinimaNecessaria
                    ) || '',
                },
              ],
            }}
          />

          <AccordionCard title="Detalhamento das parcelas" expanded={true}>
            <InstallmentsTable
              installmentsData={
                selectedDepreciationSystemData?.installmentsList || []
              }
            />
          </AccordionCard>
        </Flex>
      )}
    </Flex>
  )
}
