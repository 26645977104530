export enum BudgetStatus {
  PENDING = 'PENDING',
  FAILURE = 'FAILURE',

  BUDGET = 'budget',
  REFUSED = 'refused',
  CANCELED = 'canceled',
  MISSING_DOCUMENT = 'missingDocuments',
  POLICY = 'policy',
  POLICY_REFUSED = 'policyRefused',
  PROPOSAL = 'proposal',
  BIOMETRY_REVIEW = 'biometryReview',
  BIOMETRY_APPROVED = 'biometryApproved',
  BIOMETRY_EXPIRED = 'biometryExpired',
  BIOMETRY_REFUSED = 'biometryRefused',
  PAC_APPROVED = 'pacApproved',
  PAC_REFUSED = 'pacRefused',
  PAC_REVIEW = 'pacReview',
  ERROR = 'error',

  CAP_DRAFT = '@cap/draft',
  CAP_REGISTERING = '@cap/registering',
  CAP_REGISTERING_ERROR = '@cap/registering-error',
  CAP_AWAITING_PAYMENT = '@cap/awaiting-payment',
  CAP_AWAITING_PAYMENT_LINK = '@cap/awaiting-payment-link',
  CAP_CANCELED = '@cap/cancelled',
  CAP_ISSUED = '@cap/issued',
  CAP_ERROR = '@cap/integration-failure',
  CAP_RENEWAL = '@cap/renewal',
  CAP_REDEMPTION_PERFORMED = '@cap/redemption-performed',
  CAP_CLOSED = '@cap/closed',
}
