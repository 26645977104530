const StaticPage = ({ page }) => {
  return (
    <>
      {page.map(({ Component, props }, index) => {
        return <Component {...props} key={index} />
      })}
    </>
  )
}

export default StaticPage
