import { createEvent } from 'effector'
import { ContractStore } from '../store'

export const update = createEvent<any>('')

ContractStore.on(update, (state: any, payload: any) => ({
  ...state,
  payload: {
    ...state.payload,
    ...payload,
  },
}))
