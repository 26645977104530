import { rem } from 'polished'
import styled from 'styled-components'
import media from 'styled-media-query'

import { themes } from '@/styles'

export const Container = styled.div`
  background-color: var(--system-surface-primary);
  border: 1px solid var(--system-border-neutral);
  border-radius: 4px;
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, minmax(max-content, 1fr)) max-content;
  overflow-x: auto;
`

export const Item = styled.div<{ bgColor?: string }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: left;
  white-space: break-spaces;
  word-break: break-word;
  padding: 0.5rem;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  background-color: ${({ bgColor }) => `var(--${bgColor})`};
`

export const ActionContainer = styled(Item)`
  align-items: center;
  justify-content: center;
`

export const Head = styled(Item)`
  cursor: unset;
  background-color: var(--system-surface-tertiary);
`

export const Options = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px;
`

export const Message = styled.p`
  padding: 16px 0;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 8px;
  background-color: ${themes.page.colors.background};

  font-weight: 600;
  font-size: ${rem('11px')};
  color: ${themes.text.medium};

  ${media.greaterThan('huge')`
    font-size: ${rem('13px')};
  `}
`
