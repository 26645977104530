import React, { useState, useMemo, useEffect } from 'react'
import { useFormik, FormikProvider } from 'formik'

import { Modals, Grid, Forms, Conditional, Content, toast } from '@/components'
import { underAgeValidation } from '../../../../utils'
import { countries, useDebounce, useGTM } from '@/tools'
import * as S from '../../../../styles'
import { api } from '@/services'

import validationSchema from './schema'
import dayjs from 'dayjs'
import { isCPF } from 'brazilian-values'

const initialValues = {
  financial: false,
  document: '',
  name: '',
  email: '',
  phone: '',
  birthdate: '',
  emancipated: false,
  civil_status: '',
  spouse_document: '',
  employment: {
    bond: '',
    company: '',
    salary: '',
    incomes: '',
    phone: '',
  },
  nacionality: '1',
  emigrant: false,
  residence_country: '',
  address: {
    zipcode: '',
    street: '',
    number: '',
    complement: '',
    neighborhood: '',
    city: '',
    state: '',
  },
}

namespace SuitorRegister {
  export type SuitorRegisterValues = typeof initialValues
  export interface Values {
    initialValues: SuitorRegisterValues
  }

  export type Props = Modals.Promised.PromisedModalProps<SuitorRegister.Values> &
    SuitorRegister.Values

  const Component = (props: SuitorRegister.Props) => {
    const [status, setStatus] = useState<'address' | ''>('')
    const [disabledFields, setDisabledFields] = useState([])
    const { setDataLayer } = useGTM()

    const [statusCustomer, setStatusCustomer] = useState<'fetchingCustomer' | ''>('')

    const isEditing = props.initialValues

    const onSubmit = values => {
      const suitor = validationSchema.cast(values)
      setDataLayer({
        event: 'modal',
        acao: 'fechar',
        nome: isEditing ? 'editar-pretendente' : 'adicionar-pretendente',
      })
      props.onSubmit(suitor)
    }

    const form = useFormik<SuitorRegisterValues>({
      initialValues: props.initialValues || initialValues,
      validationSchema,
      onSubmit,
    })

    const values = useMemo(() => form.values, [form.values])
    const zipcode = useDebounce(values.address?.zipcode)

    const document = useDebounce(values.document)

    const isUnderage = useMemo(() => underAgeValidation(values.birthdate), [values.birthdate])

    const fetchAddress = async () => {
      try {
        setStatus('address')

        const address = await api.addresses.getByZipcode(zipcode)

        if (!address) {
          form.setFieldValue('address.street', '')
          form.setFieldValue('address.neighborhood', '')
          form.setFieldValue('address.city', '')
          form.setFieldValue('address.state', '')

          return
        }

        Object.entries(address).map(entry => {
          setDisabledFields(state => [...state, !!entry[1] && entry[0]])
        })

        form.setFieldValue('address.street', address.street)
        form.setFieldValue('address.neighborhood', address.neighborhood)
        form.setFieldValue('address.city', address.city)
        form.setFieldValue('address.state', address.state)
      } catch (error) {
        toast('Por favor insira um CEP válido.', {
          type: 'warning',
        })
      } finally {
        setStatus('')
      }
    }

    const fetchCustomer = async () => {
      try {
        setStatusCustomer('fetchingCustomer')

        const customer = await api.customers.getByDocument(document)

        form.setFieldValue('name', customer.name)
        form.setFieldValue('birthdate', dayjs(customer.birthdate).format('DD/MM/YYYY'))
      } finally {
        setStatusCustomer('')
      }
    }

    const handleBlurDocument = () => {
      if (document !== '') {
        if (isCPF(document)) fetchCustomer()
      }
    }

    useEffect(() => {
      setDisabledFields([])
      if (!props.initialValues?.address?.zipcode && zipcode && !form.errors?.address?.zipcode)
        fetchAddress()
    }, [zipcode])

    useEffect(() => {
      setDataLayer({
        event: 'modal',
        acao: 'abrir',
        nome: isEditing ? 'editar-pretendente' : 'adicionar-pretendente',
      })
    }, [])

    return (
      <Modals.Content
        icon={isEditing ? 'person' : 'person-add'}
        title={form.values.name || 'Novo pretendente'}
        size="large"
        onSubmit={{
          text: isEditing ? 'Salvar alterações' : 'Adicionar novo pretendente',
          onClick: () => form.handleSubmit(),
        }}
        onCancel={{
          text: 'Cancelar',
          onClick: () => {
            props.onDismiss()
            setDataLayer({
              event: 'modal',
              acao: 'fechar',
              nome: isEditing ? 'editar-pretendente' : 'adicionar-pretendente',
            })
          },
        }}
      >
        <FormikProvider value={form}>
          <S.Grid space={['1rem 0', '1rem 0']}>
            <Forms.InputGroup
              name="document"
              label="CPF"
              placeholder="Digite o CPF"
              mask="cpf"
              readOnly={statusCustomer === 'fetchingCustomer'}
              onBlur={handleBlurDocument}
              data-gtm-form="input"
              data-gtm-name="cpf"
            />

            <S.Choices>
              <Forms.Toggle
                label="Pretendente financeiro"
                name="financial"
                data-gtm-type="input"
                data-gtm-name="pretendente-financeiro"
              />
            </S.Choices>
          </S.Grid>

          <Conditional when={statusCustomer === 'fetchingCustomer'}>
            <Content.Loader
              alignment="left"
              message="Aguarde, estamos buscando os dados do cliente."
            />
          </Conditional>

          <S.Grid space={['1rem 0', '1rem 0']}>
            <Conditional when={!statusCustomer}>
              <Forms.InputGroup
                name="name"
                label="Nome completo"
                placeholder="Digite o nome completo"
                data-gtm-form="input"
                data-gtm-name="nome-completo"
              />

              <Forms.InputGroup
                name="birthdate"
                label="Data de nascimento"
                placeholder="Digite a data de nascimento"
                mask="date"
                data-gtm-form="input"
                data-gtm-name="data-de-nascimento"
              />

              <Forms.InputGroup
                name="phone"
                label="Telefone/celular"
                placeholder="Digite o telefone/celular"
                mask="phone"
                data-gtm-form="input"
                data-gtm-name="telelfone-celular"
              />
            </Conditional>

            <Conditional when={isUnderage}>
              <S.ToggleWrapper>
                <Forms.Toggle
                  name="emancipated"
                  label="Pretendente emancipado"
                  data-gtm-type="input"
                  data-gtm-name="pretendente-emancipado"
                />
              </S.ToggleWrapper>
            </Conditional>
          </S.Grid>

          <S.Grid space={['1rem 0', '1rem 0']}>
            <Forms.SelectGroup
              name="nacionality"
              label="Nacionalidade"
              data-gtm-type="select"
              data-gtm-name="nacionalidade"
              options={[
                {
                  label: 'Brasileira',
                  value: '1',
                },
                {
                  label: 'Estrangeira',
                  value: '2',
                },
              ]}
            />

            <Conditional when={values.emigrant}>
              <Forms.SelectGroup
                name="residence_country"
                label="País residente"
                data-gtm-type="select"
                data-gtm-name="pais-residente"
                options={[
                  {
                    label: 'Selecione o país residente',
                    value: '',
                  },
                  ...countries.map(({ code, name }) => ({
                    label: name,
                    value: code,
                  })),
                ]}
              />
            </Conditional>
            <S.Choices>
              <Forms.Toggle
                name="emigrant"
                label="Não reside no brasil"
                data-gtm-type="input"
                data-gtm-name="nao-reside-no-brasil"
              />
            </S.Choices>
          </S.Grid>

          <S.Grid space={['1rem 0', '1rem 0']}>
            <Forms.InputGroup
              name="email"
              label="E-mail"
              placeholder="Digite o e-mail"
              data-gtm-form="input"
              data-gtm-name="email"
            />
            <Forms.SelectGroup
              name="civil_status"
              label="Estado civil"
              data-gtm-type="select"
              data-gtm-name="estado-civil"
              options={[
                {
                  label: 'Selecione o estado civil',
                  value: '',
                },
                {
                  label: 'Amasiado',
                  value: 'AMASIADO',
                },
                {
                  label: 'Casado',
                  value: 'CASADO',
                },
                {
                  label: 'Desquitado',
                  value: 'DESQUITADO',
                },
                {
                  label: 'Divorciado',
                  value: 'DIVORCIADO',
                },
                {
                  label: 'Separado',
                  value: 'SEPARADO',
                },
                {
                  label: 'Solteiro',
                  value: 'SOLTEIRO',
                },
                {
                  label: 'Viúvo',
                  value: 'VIUVO',
                },
              ]}
            />

            <Conditional when={['AMASIADO', 'CASADO'].includes(values.civil_status)}>
              <Forms.InputGroup
                name="spouse_document"
                label="CPF do cônjuge"
                placeholder="Digite o CPF do cônjuge"
                mask="cpf"
                data-gtm-form="input"
                data-gtm-name="cpf-do-conjuge"
              />
            </Conditional>
          </S.Grid>

          <S.Grid space={['1rem 0', '1rem 0']}>
            <Forms.SelectGroup
              name="employment.bond"
              label="Vínculo empregatício"
              data-gtm-type="select"
              data-gtm-name="vinculo-empregaticio"
              options={[
                {
                  label: 'Selecione o vínculo empregatício',
                  value: '',
                },
                {
                  label: 'Aposentado / Pensionista',
                  value: '3',
                },
                {
                  label: 'Autônomo',
                  value: '12',
                },
                {
                  label: 'Empresário',
                  value: '2',
                },
                {
                  label: 'Estudante',
                  value: '4',
                },
                {
                  label: 'Funcionário Público',
                  value: '13',
                },
                {
                  label: 'Funcionário com registro CLT',
                  value: '1',
                },
                {
                  label: 'Profissional Liberal',
                  value: '8',
                },
                {
                  label: 'Renda proveniente de aluguéis',
                  value: '7',
                },
              ]}
            />
            <Forms.InputGroup
              name="employment.salary"
              label="Salário"
              placeholder="Digite o salário"
              mask="money"
              data-gtm-form="input"
              data-gtm-name="salario"
            />

            <Forms.InputGroup
              name="employment.incomes"
              label="Outros rendimentos"
              placeholder="Digite outros rendimentos"
              mask="money"
              data-gtm-form="input"
              data-gtm-name="outros-rendimentos"
            />

            <Conditional when={['2', '13', '1', '8'].includes(form.values.employment?.bond)}>
              <Forms.InputGroup
                name="employment.company"
                label="Nome da empresa"
                placeholder="Digite o nome da empresa"
                data-gtm-form="input"
                data-gtm-name="nome-da-empresa"
              />

              <Forms.InputGroup
                name="employment.phone"
                label="Telefone comercial"
                placeholder="Digite o telefone comercial"
                mask="phone"
                data-gtm-form="input"
                data-gtm-name="telefone-comercial"
              />
            </Conditional>
          </S.Grid>

          <S.Grid space={['1rem 0', '1rem 0']}>
            <Forms.InputGroup
              name="address.zipcode"
              label="CEP"
              placeholder="Digite o CEP"
              mask="zipcode"
              data-gtm-form="input"
              data-gtm-name="cep"
            />
          </S.Grid>

          <Conditional when={status === 'address'}>
            <Content.Loader alignment="left" message="Buscando endereço, aguarde..." />
          </Conditional>

          <Conditional when={!!values.address?.city}>
            <Grid columns={['auto', 'auto .2fr .5fr']} gap="1rem" space={['1rem 0', '2rem 0']}>
              <Forms.InputGroup
                name="address.street"
                label="Rua"
                placeholder="Confirme a rua"
                readOnly={disabledFields.includes('street')}
                data-gtm-form="input"
                data-gtm-name="rua"
              />

              <Forms.InputGroup
                name="address.number"
                label="Número"
                placeholder="Ex: 130"
                data-gtm-form="input"
                data-gtm-name="numero"
              />

              <Forms.InputGroup
                name="address.complement"
                label="Complemento"
                placeholder="Ex.: Bloco 10; Apto 172"
                data-gtm-form="input"
                data-gtm-name="complemento"
              />
            </Grid>

            <Grid columns={['repeat(2, 1fr)', '1fr 1fr .5fr']} gap="1rem">
              <Forms.InputGroup
                name="address.neighborhood"
                label="Bairro"
                placeholder="Confirme o bairro"
                readOnly={disabledFields.includes('neighborhood')}
                data-gtm-form="input"
                data-gtm-name="bairro"
              />

              <Forms.InputGroup
                name="address.city"
                label="Cidade"
                placeholder="Confirme a cidade"
                readOnly={disabledFields.includes('city')}
                data-gtm-form="input"
                data-gtm-name="cidade"
              />

              <Forms.InputGroup
                name="address.state"
                label="Estado"
                placeholder="Confirme o estado"
                readOnly={disabledFields.includes('state')}
                data-gtm-form="input"
                data-gtm-name="estado"
              />
            </Grid>
          </Conditional>
        </FormikProvider>
      </Modals.Content>
    )
  }

  export const openModal = Modals.Promised.createModal<SuitorRegister.Props>(Component)
}

export default SuitorRegister
