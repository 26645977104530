import styled from 'styled-components'
import media from 'styled-media-query'
import { rem, size } from 'polished'

import { themes } from '@/styles'

export const Container = styled.div`
  margin-bottom: 32px;

  display: flex;
  align-items: center;
`

export const Content = styled.div`
  flex: 1;
`

export const Title = styled.h4`
  margin-bottom: 6px;

  display: inline-flex;

  font-weight: 600;
  font-size: 20px;
  line-height: 28px;

  color: ${themes.palletes.terciary};
`

export const Description = styled.p`
  font-size: ${rem('14px')};
  font-weight: 500;
  line-height: 20px;
  color: ${themes.text.medium};

  > b {
    font-weight: 600;
  }
`

export const Back = styled.button`
  all: unset;

  margin-right: 8px;

  display: inline-flex;

  cursor: pointer;
  color: ${themes.text.low};

  &:hover {
    color: ${themes.text.medium};
  }

  > svg {
    ${size('16px')}

    transform: translateY(2px);
  }
`

export const Controls = styled.div`
  padding-left: 16px;
`
