import { css } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/_shared'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import breakpoints from '@pol-npm/riscos-financeiros-dashboard-ui/dist/theme/breakpoints'
import styled from 'styled-components'

export const FourthSectionContainer = styled.div`
  padding: 40px 108px 88px;

  @media (max-width: calc(${breakpoints.sm} - 1px)) {
    padding: 40px 12px;
  }
`

export const Title = styled(Typography)(css({ sx: {} }))

export const GridContainer = styled.div`
  display: grid;
  margin-top: 32px;
  grid-template: 1fr 1fr / 1fr 1fr 1fr;
  gap: 16px 24px;

  @media (max-width: calc(${breakpoints.sm} - 1px)) {
    grid-template: auto / 1fr;
  }
`

export const GridItem = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
`

export const GridItemTitle = styled(Typography)(
  css({ sx: { marginTop: '8px' } })
)

export const GridItemText = styled(Typography)(
  css({ sx: { marginTop: '4px' } })
)
