import { createStore } from 'effector'
import { persist } from 'effector-storage/local'

const tourStore = createStore(
  {
    completed: false,
  },
  { name: 'tour' }
)

persist({ store: tourStore })

export { tourStore }
