import themes from '@/styles/themes'
import { rem, rgba } from 'polished'
import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import theme from 'styled-theming'

export const Input = styled.input<{ error?: unknown }>`
  all: unset;

  width: 100%;
  height: 48px;
  padding: 0 16px;

  transition: 0.3s;
  border-radius: 4px;
  box-sizing: border-box;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 8px 0px;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  border: 1px solid ${themes.utilities.border};

  background-color: ${theme('mode', {
    light: '#fff',
    dark: '#1c1f25',
  })};

  font-weight: 600;
  font-size: ${rem('11px')};
  font-family: ${themes.fonts.text};

  color: ${themes.text.high};
  -webkit-text-fill-color: ${themes.text.high};

  &::placeholder {
    font-weight: 500;
    color: ${themes.text.low};
    -webkit-text-fill-color: ${themes.text.low};
  }

  &:focus {
    transition: 0.3s;
    border-color: ${themes.palletes.primary};
    box-shadow: 0 0 0 3px ${rgba(themes.palletes.primary, 0.12)};

    ${media.greaterThan('huge')`
      box-shadow: 0 0 0 4px ${rgba(themes.palletes.primary, 0.12)};
    `}
  }

  ${media.greaterThan('huge')`
    height: 55px;

    font-size: ${rem('13px')};
  `}

  ${props =>
    props.readOnly &&
    css`
      cursor: not-allowed;
      border-color: ${themes.utilities.border};
      background-color: ${themes.utilities.border};
    `}

  ${props =>
    props.error &&
    css`
      border-radius: 4px;
      border-color: ${rgba(themes.palletes.danger, 0.5)};
      background-color: ${rgba(themes.palletes.danger, 0.02)};

      &:focus {
        border-color: ${themes.palletes.danger};
        background-color: ${rgba(themes.palletes.danger, 0.03)};
        box-shadow: 0 0 0 3px ${rgba(themes.palletes.danger, 0.1)};

        ${media.greaterThan('huge')`
          box-shadow: 0 0 0 4px ${rgba(themes.palletes.danger, 0.1)};
        `}
      }
    `}
`
