import { useFormikContext } from 'formik'
import { useContext, useMemo } from 'react'

import { Conditional, Content, Forms, Grid } from '@/components'
import { generateRangeNumbers } from '@/tools'

import { useOrganizations } from '@/modules'
import { pxToRem } from '@/styles/sheets/helpers'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Notification } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { BudgetsContext, ImobiliariaBudget } from '..'
import * as S from '../styles'
import { clausesParse, includedServicesByClause, plansParse } from '../utils'

const Contract = () => {
  const context = useContext(BudgetsContext)
  const formik = useFormikContext<ImobiliariaBudget>()
  const {
    store: { active: organization },
  } = useOrganizations()

  const prolabore = useMemo(() => context.settings.prolabore, [context.settings.prolabore])

  return (
    <Forms.Card
      step={formik.values.renewal_number ? '1' : '2'}
      title="Contrato"
      helper={
        formik.values.renewal_number
          ? 'Atualize o <b>plano</b>, <b>cláusula</b> e <b>dados técnicos</b> do contrato.'
          : 'Selecione o <b>plano</b>, <b>cláusula</b> e <b>dados técnicos</b> do contrato.'
      }
      active
    >
      <Typography
        sx={{ marginBottom: pxToRem('24px') }}
        variant="porto-text-caption-regular"
        color="#FF5A40"
        content="* preenchimento obrigatório."
      />
      <S.Grid>
        <Forms.SelectGroup
          name="contract_plan"
          label="Plano"
          showRequired
          resetValueOnUnmount={false}
          data-gtm-type="select"
          data-gtm-name="plano"
          data-gtm-subname="contrato"
          options={[
            {
              label: 'Selecione o Plano',
              value: '',
            },
            ...context.settings.plans.map(plan => ({
              label: plansParse[plan],
              value: plan,
            })),
          ]}
        />

        <Forms.SelectGroup
          name="contract_clause"
          label="Cláusula de serviço"
          showRequired
          resetValueOnUnmount={false}
          disabled={context.settings.clauses.length < 1}
          data-gtm-type="select"
          data-gtm-name="clausula-de-servico"
          data-gtm-subname="contrato"
          options={[
            {
              label: 'Selecione a cláusula',
              value: '',
            },
            ...context.settings.clauses.map(clause => ({
              label: clausesParse[clause],
              value: clause,
            })),
          ]}
        />
      </S.Grid>

      <S.Grid space={['.5rem 0 0', '2rem 0 0']}>
        <Conditional when={['BROKER'].includes(organization.type)}>
          <Forms.SelectGroup
            name="contract_commission"
            label="Comissão"
            resetValueOnUnmount={false}
            data-gtm-type="select"
            data-gtm-name="comissao"
            data-gtm-subname="contrato"
            options={[
              {
                label: 'Selecione a comissão',
                value: '',
              },
              ...generateRangeNumbers(10, 70).map(value => ({
                label: value + '%',
                value,
              })),
            ]}
          />

          <Conditional when={context.settings.config.prolabore_enabled}>
            <Forms.SelectGroup
              name="contract_prolabore"
              label="Pró-labore (%)"
              resetValueOnUnmount={false}
              data-gtm-type="select"
              data-gtm-name="pro-labore"
              data-gtm-subname="contrato"
              options={[
                {
                  label: 'Selecione o pró-labore (%)',
                  value: '',
                },
                ...generateRangeNumbers(1, 70 - Number(formik.values.contract_commission)).map(
                  value => ({
                    label: value + '%',
                    value,
                  }),
                ),
              ]}
            />
          </Conditional>
        </Conditional>

        <Conditional
          when={
            ['REAL_ESTATE'].includes(organization.type) &&
            prolabore.enabled &&
            !!prolabore.percentage
          }
        >
          <Forms.SelectGroup
            name="contract_prolabore"
            label="Pró-labore (%)"
            resetValueOnUnmount={false}
            disabled={!prolabore.variable}
            data-gtm-type="select"
            data-gtm-name="pro-labore"
            data-gtm-subname="contrato"
            options={
              !prolabore.variable
                ? [
                    {
                      label: prolabore.percentage + '%',
                      value: prolabore.percentage,
                    },
                  ]
                : [
                    {
                      label: 'Selecione o pró-labore (%)',
                      value: '',
                    },
                    ...generateRangeNumbers(1, prolabore.percentage).map(value => ({
                      label: value + '%',
                      value,
                    })),
                  ]
            }
          />
        </Conditional>

        <Forms.InputGroup
          name="contract_discount"
          label="Desconto técnico (opcional)"
          placeholder="Digite o desconto técnico"
          mask="percentage"
          data-gtm-form="input"
          data-gtm-name="desconto-tecnico"
          data-gtm-subname="contrato"
        />
      </S.Grid>
      <S.NotificationWrapper>
        <Notification
          icon="circleinfo"
          variant="filled"
          description="O valor do desconto técnico deve estar conforme o acordo vigente."
        />
      </S.NotificationWrapper>

      <Conditional
        when={
          ['REAL_ESTATE'].includes(organization.type) &&
          formik.values.contract_plan &&
          prolabore.enabled &&
          !prolabore.variable
        }
      >
        <Grid space="2rem 0 0">
          <Content.Message title="Atenção" kind="warning">
            Pró labore parametrizado fixo em {prolabore.percentage}%, não sendo possível alterar.
          </Content.Message>
        </Grid>
      </Conditional>

      <Conditional when={!!formik.values.contract_clause}>
        <S.Services.Wrap>
          <S.Services.Title>Serviços inclusos:</S.Services.Title>

          <S.Services.List>
            {includedServicesByClause(formik.values.contract_clause).map((service, index) => (
              <S.Services.Item key={index}>{service}</S.Services.Item>
            ))}
          </S.Services.List>
        </S.Services.Wrap>
      </Conditional>
    </Forms.Card>
  )
}

export default Contract
