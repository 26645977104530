import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { rem } from 'polished'

import { themes } from '@/styles'

import { Loader } from '../../Icons'

import { ILoaderProps } from './interfaces'

export const Container = styled.div<Pick<ILoaderProps, 'alignment'>>`
  padding: 16px 0;

  display: flex;
  align-items: center;

  border-radius: 8px;
  background-color: ${themes.page.colors.background};

  ${media.greaterThan('huge')`
    padding: 24px 0;
  `}

  ${(props) =>
    props.alignment &&
    {
      left: css`
        justify-content: flex-start;
      `,

      center: css`
        justify-content: center;
      `,

      right: css`
        justify-content: flex-flex-end;
      `,
    }[props.alignment]}
`

export const Left = styled.div`
  min-width: 16px;

  line-height: 1;

  ${media.greaterThan('huge')`
    margin-top: 3px;
  `}
`

export const Icon = styled(Loader)``

export const Message = styled.p`
  margin-left: 8px;

  display: inline-block;

  font-weight: 600;
  font-size: ${rem('11px')};
  color: ${themes.text.medium};

  ${media.greaterThan('huge')`
    margin-left: 16px;

    font-size: ${rem('13px')};
  `}
`
