import { isCNPJ, isCPF } from 'brazilian-values'
import * as yup from 'yup'

export const initialValues = {
  document: '',
}

export type FormSchema = typeof initialValues

const documentValidation = (value: string) => {
  if (value.length <= 14) return isCPF(value)

  return isCNPJ(value)
}

export const firstAccessFormSchema: yup.SchemaOf<FormSchema> = yup
  .object()
  .shape({
    document: yup
      .string()
      .required('Campo CPF/CNPJ é obrigatório.')
      .test(
        'document-test',
        'Por favor insira um CPF/CNPJ válido.',
        (value) => !!value && documentValidation(value)
      ),
  })
