import React, { useState, useMemo, useContext, memo } from 'react'

import { Actions, Content, Conditional } from '@/components'
import { formatDate } from '@/tools'

import { SettingsContext } from '../../ui'
import Heading from '../Heading'

import * as schema from './schema'
import * as S from './styles'

const RANGE = 3

const Activity = () => {
  const {
    details: { created_at, activities },
  } = useContext(SettingsContext)

  const [range, setRange] = useState(RANGE)

  const hasMore = useMemo(
    () =>
      range <
      activities.sort((a, b) => +new Date(b.timestamp) - +new Date(a.timestamp))
        .length,
    [activities, range]
  )

  const handleItems = () => {
    setRange((state) => {
      if (!hasMore) return RANGE
      return state + RANGE
    })
  }

  return (
    <S.Container>
      <Heading
        removeBackButton
        title="Histórico de alterações"
        description="Acompanhe todas as alterações dos produtos."
      />

      <S.Content>
        <Conditional when={activities.length < 1}>
          <Content.Message title="Atenção" kind="warning">
            Ainda não possui nenhuma alteração.
          </Content.Message>
        </Conditional>

        <Conditional when={activities.length > 0}>
          <S.List hasMore={hasMore}>
            {activities?.slice(0, range).map((activity, index) => {
              const changes = Object.entries(activity.changes)

              return (
                <S.Item key={index}>
                  <S.Heading>
                    Mudanças em{' '}
                    {formatDate(activity.timestamp, {
                      timeStyle: 'short',
                    })}{' '}
                    por <b>{activity.author.name}</b>
                  </S.Heading>

                  <S.Table>
                    <thead>
                      <tr>
                        <th>Produto</th>
                        <th>Categoria</th>
                        <th>Campo</th>
                        <th>Novo valor</th>
                      </tr>
                    </thead>

                    <tbody>
                      {changes?.map(([key, value]) => {
                        const [product, category, field, nested] =
                          key.split('.')
                        const valueRenderer = () => {
                          if (typeof value === 'boolean')
                            return value ? 'Ativo' : 'Inativo'

                          if (Array.isArray(value)) {
                            return value
                              .map((value) => schema.values[value] || value)
                              .join(', ')
                          }

                          return value
                        }

                        return (
                          <tr>
                            <td>{schema.products[product]}</td>
                            <td>{schema.categories[category]}</td>
                            <td>
                              {schema.fields[field] || 'Valores'}
                              <span> {schema.fields[nested]}</span>
                            </td>
                            <td>{valueRenderer()}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </S.Table>
                </S.Item>
              )
            })}

            <S.Item>
              <S.Heading>
                Vínculo criado no dia{' '}
                {formatDate(created_at, {
                  timeStyle: 'short',
                })}
              </S.Heading>
            </S.Item>
          </S.List>

          <Conditional when={RANGE < activities.length}>
            <S.Footer>
              <Actions.Button
                type="button"
                size="small"
                kind={hasMore ? 'ghost' : 'smooth'}
                onClick={handleItems}
              >
                {hasMore ? 'Mostrar mais' : 'Esconder itens'}
              </Actions.Button>
            </S.Footer>
          </Conditional>
        </Conditional>
      </S.Content>
    </S.Container>
  )
}

export default memo(Activity)
