import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { Button, ButtonContainer, Iframe, IframeContainer, Overlay } from './styles'

export enum Status {
  Off,
  Visible,
  NotVisible,
}

const Chat = ({ open }: { open?: boolean }) => {
  const [status, setStatus] = useState<Status>(Status.Off)

  const handleClick = () => {
    setStatus(prevState => (prevState !== Status.Visible ? Status.Visible : Status.NotVisible))
  }

  useEffect(() => {
    if (!open) return
    handleClick()
  }, [open])

  return createPortal(
    <>
      <IframeContainer status={status}>
        {status === Status.Visible && <Overlay onClick={handleClick} />}
        {status !== Status.Off && (
          <Iframe
            id="porto-assistencia-iframe"
            title="porto-assistencia"
            src="https://prime.altubots.com/chats/portogarantia/b44a75b83e3540b298d3f027/index.html"
          ></Iframe>
        )}
      </IframeContainer>
      <ButtonContainer>
        <Button onClick={handleClick}>
          <svg
            width="60%"
            height="60%"
            viewBox="0 0 24 24"
            fill="#fff"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10,18 L6,22 L6,18 L10,18 Z M17,6 C19.7614237,6 22,8.23857625 22,11 C22,13.7614237 19.7614237,16 17,16 L17,16 L7,16 C4.23857625,16 2,13.7614237 2,11 C2,8.23857625 4.23857625,6 7,6 L7,6 Z"
              transform="translate(12.000000, 14.000000) scale(-1, 1) translate(-12.000000, -14.000000) "
            ></path>
          </svg>
        </Button>
      </ButtonContainer>
    </>,
    document.body,
  )
}

export default Chat
