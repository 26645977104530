import { api } from '@/services'
import {
  BudgetPayments,
  BatchBudgets,
  FilterBatchesList,
  FilterBudgetsBatch,
} from './types'

export async function order(payment: BudgetPayments) {
  try {
    const response = await api.instance.v2.post(
      `/seguro-incendio/contracts/orders`,
      payment
    )

    return response.data
  } catch (error) {
    throw error
  }
}

const parseBody = (body: BatchBudgets, id: string, susep: string) => {
  const { budgets, filename, partner, period_start, product, organizationType } = body

  const parseCode = (code) => {
    if (Number.isInteger(code)) {
      return code
    } else {
      const num = code * 100
      return num
    }
  }

  const validationDocument = (document: string) => {
    return document.trim().replace(/\D/g,'')
  }

  const parseBudgets = (budgets) =>
    budgets.map((item) => {
      return {
        ...item,
        customer_document: validationDocument(
          item.customer_document.toString()
        ),
        beneficiary_complement: !!item.beneficiary_complement
          ? item.beneficiary_complement.toString()
          : '',
        address_complement: !!item.address_complement
          ? item.address_complement.toString()
          : '',
        beneficiary_document: validationDocument(
          item.beneficiary_document.toString()
        ),
        customer_phone_number: !!item.customer_phone_number
          ? item.customer_phone_number.toString().padStart(9, '0')
          : '',
        customer_phone_code: !!item.customer_phone_code
          ? item.customer_phone_code.toString().padStart(2, '0')
          : '',
        beneficiary_zipcode: String(item?.beneficiary_zipcode),
        address_zipcode: String(item?.address_zipcode),
        contract_operation_code: parseCode(item.contract_operation_code),
        contract_discount: parseCode(item.contract_discount),
        contract_prolabore: parseCode(item.contract_prolabore),
        estate_owner: item.estate_owner.toUpperCase(),
        beneficiary_enabled: item.beneficiary_enabled.toUpperCase(),
      }
    })

  return {
    filename,
    partner,
    period_start,
    product,
    budgets: parseBudgets(budgets),
    broker: id,
    susep,
    organizationType,
  }
}

export async function validate(params) {
  try {
    const { broker, ...body } = params
    const { id, susep } = JSON.parse(broker)

    const response = await api.instance.v2.post<BatchBudgets>(
      `/batches`,
      await parseBody(body, id, susep)
    )

    return response.data
  } catch (error) {
    throw error
  }
}

export async function calculate(idBatch: string, budgets?: []) {
  try {
    const response = await api.instance.v2.post<BatchBudgets>(
      `/batches/${idBatch}/calculate`,
      { contracts: budgets }
    )

    return response.data
  } catch (error) {
    throw error
  }
}

export async function getBatchesList(
  filters: FilterBatchesList,
  page: number,
  isBroker: boolean,
  search: string,
  profileId: string
) {
  const response = await api.instance.v2.get(`/batches`, {
    params: isBroker
      ? {
          broker: profileId,
          partner: search,
          ...filters,
          page: page,
          limit: 10,
        }
      : {
          partner: profileId,
          susep: search,
          ...filters,
          page: page,
          limit: 10,
        },
  })

  return response.data
}

export async function getBudgetsBatch(filters: FilterBudgetsBatch, id: string) {
  const response = await api.instance.v2.get(`/batches/${id}/contracts`, {
    params: {
      ...filters,
    },
  })

  return response.data
}
