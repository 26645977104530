import { DataGrid, GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid'

import { EReferralsBrokersStatus, IReferralsBrokersSolicitations } from '@/interfaces'

import { actionTypes, useOnboarding } from '../../context'
import { ShapeStatus, TableWrapper } from './style'

const SHAPE_STATUS = {
  APPROVED: {
    bgColor: '#C0F0D3',
    fontColor: '#034D2E',
    label: 'Acesso liberado',
  },
  DECLINED: {
    bgColor: '#FFCDC6',
    fontColor: '#842310',
    label: 'Acesso recusado',
  },
  PENDING: {
    bgColor: '#F8F6C2',
    fontColor: '#94621F',
    label: 'Acesso pendente'
  },
}

const columns: GridColDef[] = [
  { field: 'broker', headerName: 'Corretor', width: 480 },
  { field: 'tel', headerName: 'Telefone', width: 240 },
  {
    field: 'status',
    headerName: 'Acesso ao portal das Imobiliárias',
    renderCell: (props: GridRenderCellParams<String>) => {
      const status = handleStatus(props.value)
      return (<ShapeStatus {...{ bgColor: status.bgColor, fontColor: status.fontColor }}> {status.label}</ShapeStatus>)
    },
    width: 320
  }
]

const handleStatus = (status: string) => {
  return SHAPE_STATUS[status]
}

const phoneMask = (phone) => {
  if (!phone) return ''
  phone = phone.replace(/\+55/gim, '')
  phone = phone.replace(/\D/g, '')
  phone = phone.replace(/^(\d{2})(\d)/g,"($1) $2")
  phone = phone.replace(/(\d)(\d{4})$/,"$1-$2")
  return phone
}

export default function BrokerTable({
  referralSolicitations,
  selectedBrokers,
  setSelectedBrokers
}: {
  referralSolicitations: IReferralsBrokersSolicitations[];
  selectedBrokers: string[];
  setSelectedBrokers: (param) => void;
}) {
  const onboarding = useOnboarding()
  const rows = referralSolicitations.length
    ? referralSolicitations.map(({ broker: { name, phone, susep }, status }: IReferralsBrokersSolicitations) => ({
      id: susep,
      broker: name,
      status,
      tel: phoneMask(phone)
      }))
    : []

  const handleSelectedBrokers = (brokers) => {
    setSelectedBrokers(brokers)

    const data = brokers.map(el => ({
      name: el.broker,
      susep: el.id
    }))

    onboarding.dispatch({
      type: actionTypes.UPDATE_BEHAVIOR,
      payload: {
        selectedBrokers: data
      }
    })
  }

  return (
    <TableWrapper>
      <DataGrid {...{
        checkboxSelection: true,
        columns,
        disableColumnFilter: true,
        disableColumnMenu: true,
        hideFooter: true,
        isRowSelectable: (params: GridRowParams) => params.row.status === EReferralsBrokersStatus.PENDING,
        onRowSelectionModelChange: (ids) => {
          const selectedIDs = new Set(ids)
          const selectedRows = rows.filter((row) =>
            selectedIDs.has(row.id)
          )
          handleSelectedBrokers(selectedRows)
        },
        rows,
        sx: {
          '.MuiDataGrid-cell': {
            fontSize: '.778rem'
          },
          '.MuiDataGrid-columnHeaders': {
            background: '#EBF1EC'
          },
          '.MuiDataGrid-columnHeaderTitle': {
            fontSize: '.778rem',
            fontWeight: 'bold'
          },
          '.MuiDataGrid-root': {
            borderRadius: 4
          },
          '.MuiDataGrid-virtualScroller': {
            minHeight: 40
          }
        }
      }} />
    </TableWrapper>
  )
}
