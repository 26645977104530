import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

dayjs.extend(isSameOrBefore)

export interface ICustomers {
  document: string
  email: string
  phone: string
  name: string
  birthdate: string
}

export const plans = ['basic', 'traditional', 'university']

export const plansParse = {
  basic: 'Básico',
  traditional: 'Tradicional',
  university: 'Universitário',
}

export const budgetsParse = {
  '0001': 'Essencial 12',
  '0003': 'Essencial 18',
  '0004': 'Essencial 20',
  '0005': 'Essencial 30',
}

export const coverages = [
  'rent',
  'iptu',
  'condominium',
  'water',
  'gas',
  'electricity',
]

export const coveragesAdditionals = [
  'property_damages',
  'recision_fine',
  'external_paint',
  'internal_paint',
]

export const coveragesParse = {
  rent: 'Aluguel',
  iptu: 'IPTU',
  condominium: 'Condomínio',
  water: 'Água',
  gas: 'Gás',
  electricity: 'Luz',
  property_damages: 'Danos ao imóvel',
  recision_fine: 'Rescisão de contrato',
  external_paint: 'Pintura externa',
  internal_paint: 'Pintura interna',
}

export const paymentsParse = {
  CC: 'creditCard',
  FAT: 'invoiceWithoutDown',
  CP: 'portoSeguroCard',
}

export const underAgeValidation = (date) => {
  const birthdate =
    date?.length === 10 && dayjs(date.split('/').reverse().join(''))
  const limit = dayjs().subtract(18, 'year')

  return dayjs(limit).isSameOrBefore(birthdate)
}
