import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'

export const TagContainer = styled.div`
  margin-top: 24px;
  min-height: 28px;
`

export const CardTitle = styled(Typography)`
  margin-top: 8px;
`

export const CardDescription = styled(Typography)`
  margin-top: 8px;
`

export const ButtonContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
`
