import * as yup from 'yup'

const organization = {
  partner: yup
    .string()
    .notOneOf([''], 'Selecione o parceiro.')
    .required('Selecione o parceiro.'),
  broker: yup
    .string()
    .notOneOf([''], 'Selecione a SUSEP.')
    .required('Selecione a SUSEP.'),
}

export default yup.object().shape({
  ...organization,
})
