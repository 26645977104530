import { css } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/_shared'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import breakpoints from '@pol-npm/riscos-financeiros-dashboard-ui/dist/theme/breakpoints'
import styled from 'styled-components'

export const BannerContainer = styled.a`
  border-radius: 16px;
  cursor: pointer;
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  overflow: hidden;

  @media (max-width: calc(${breakpoints.md} - 1px)) {
    grid-template: 1fr 1fr / 1fr;
  }
`

export const Wrapper = styled.div`
  background: #2c3da8;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  padding: 32px 40px;

  @media (max-width: calc(${breakpoints.md} - 1px)) {
    grid-row: 2 / -1;
  }

  @media (max-width: calc(${breakpoints.sm})) {
    padding: 24px;
  }
`

export const Title = styled(Typography)(css({ sx: {} }))

export const Subtitle = styled(Typography)(
  css({
    sx: {},
  })
)

export const Action = styled.div<{ $outlined: boolean }>`
  background: ${(props) => (props.$outlined ? 'transparent' : '#fff')};
  border: ${(props) => (props.$outlined ? '1px solid #fff' : 'none')};
  border-radius: 4px;
  padding: 12px 16px;
  width: fit-content;
`

export const Label = styled(Typography)(css({ sx: {} }))

export const Image = styled.img`
  height: 100%;
  object-fit: cover;
  width: 100%;

  @media (max-width: calc(${breakpoints.md} - 1px)) {
    grid-row: 1 / 2;
  }
`
