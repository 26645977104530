import styled from 'styled-components'
import { Grid } from '@/components'
import media from 'styled-media-query'
import { RadioButton as RadioRawComponent } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'

export const StrechGrid = styled(Grid)`
  justify-content: stretch;
`

export const RadioButton = styled(RadioRawComponent)`
  width: fit-content;
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  ${media.lessThan('medium')`
    justify-content: unset;
    flex-direction: column;
  `}
`
