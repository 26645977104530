import { Flex, Typography } from "@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base"
import { Dialog, Icon } from "@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto"

const CityRegistrationModal = ({
  onAccept,
  onDecline,
  isOpen,
}) => (
  <Dialog
    isOpen={isOpen}
    size="medium"
    primaryButton={{
      label: 'Informar agora',
      action: onAccept
    }}
    secondaryButton={{
      label: 'Continuar sem informar',
      action: onDecline
    }}
  >
    <Flex
      sx={{
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <Icon
        name="circlequestion"
        size="md"
      />
      <Typography
        variant="porto-title-5-semibold"
        content="A imobiliária não recebe o pró-labore sem informar a inscrição municipal"
      />
      <Typography
        variant="porto-text-body-2-regular"
        content={`
          Essa informação é essencial para receber os pagamentos
          de pró-labore. Gostaria de informar agora?
        `}
      />
    </Flex>
  </Dialog>
)

export default CityRegistrationModal
