import dayjs from 'dayjs'
import * as yup from 'yup'

import { document, address } from '../../../../schema'
import { underAgeValidation } from '../../../../utils'

export default yup.object().shape({
  document,
  name: yup.string().required('Campo nome é obrigatório.'),
  birthdate: yup
    .string()
    .test(
      'invalid-date',
      'Favor informar data de nascimento válida.',
      function (value) {
        if (!value) return true
        const birthdate = dayjs(value.split('/').reverse().join(''))
        const birthdateYear = dayjs(value.split('/').reverse().join('')).year()
        const limitDate = dayjs('1900-01-01')
        const limitYear = dayjs().year()

        return (
          dayjs(limitDate).isSameOrBefore(birthdate) &&
          limitYear >= birthdateYear
        )
      }
    )
    .required('Campo data de nascimento é obrigatório.'),
  financial: yup.boolean(),
  emancipated: yup.boolean().when('birthdate', {
    is: (value) => underAgeValidation(value),
    then: yup
      .boolean()
      .oneOf(
        [true],
        'O pretendente é menor de idade e precisa ser emancipado para continuar.'
      ),
  }),
  email: yup
    .string()
    .email('Por favor insira um e-mail válido.')
    .required('Campo e-mail é obrigatório.'),
  phone: yup
    .string()
    .matches(/\(\d{2,}\) \d{4,}\-\d{4}/g, 'Por favor insira um número válido.')
    .required('Campo telefone/celular é obrigatório.'),
  civil_status: yup
    .string()
    .notOneOf([''], 'Selecione o estado civil.')
    .required('Selecione o estado civil.'),
  spouse_document: yup.mixed().when('civil_status', {
    is: (value) => value === 'AMASIADO' || value === 'CASADO',
    then: document,
  }),
  employment: yup.object().shape({
    bond: yup
      .string()
      .notOneOf([''], 'Selecione o vínculo empregatício.')
      .required('Selecione o vínculo empregatício.'),
    company: yup.string().when('bond', {
      is: (value) => ['2', '13', '1', '8'].includes(value),
      then: yup.string().required('Campo nome da empresa é obrigatório.'),
    }),
    phone: yup.string(),
    salary: yup.string().required('Campo salário é obrigatório.'),
    incomes: yup.string(),
  }),
  nacionality: yup
    .string()
    .required('Campo nacionalidade é obrigatório.')
    .oneOf(['1', '2'], 'Campo nacionalidade é obrigatório'),
  emigrant: yup.bool(),
  residence_country: yup.string().when('emigrant', {
    is: true,
    then: yup
      .string()
      .notOneOf([''], 'Campo país residente é obrigatório.')
      .required('Campo país residente é obrigatório.'),
  }),
  address: address,
})
