import React from 'react'

import { dashText, useSmoothAnchor } from '@/tools'

import { IFormProps } from './interfaces'

import { Container, Anchor, Primary, Secondary } from './styles'

const Form: React.FC<IFormProps> = ({
  onSubmit,
  onCancel,
  isLoading,
  ...props
}) => {
  const anchor = useSmoothAnchor()

  return (
    <Container {...props}>
      <Anchor.Button onClick={anchor.handler}>
        <Anchor.Icon name="arrow-up" />
      </Anchor.Button>

      {onCancel && (
        <Secondary to={onCancel.to} onClick={onCancel.onClick}>
          {onCancel.label}
        </Secondary>
      )}

      <Primary
        icon={onSubmit.icon}
        onClick={onSubmit.onClick}
        isLoading={isLoading}
        type="submit"
        data-gtm-type="click"
        data-gtm-clicktype="button"
        data-gtm-name={dashText(onSubmit.label)}
      >
        {onSubmit.label}
      </Primary>
    </Container>
  )
}

export default Form
