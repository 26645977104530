import {
  Flex,
  Typography,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Link } from '../../components'

const BrokerNotAccreditated = () => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        gap: '1rem',
      }}
    >
      <Link label="Voltar" icon="arrowleft" to="/login" />
      <Typography
        variant="porto-title-3-semibold"
        content="Identificamos que você ainda não possui um cadastro na Porto"
      />
      <Typography
        variant="porto-text-body-1-regular"
        content={`
          Procure a sucursal Porto Seguro mais próxima e solicite seu cadastro.
        `}
      />
    </Flex>
  )
}

export default BrokerNotAccreditated
