import styled, { keyframes } from 'styled-components'

const ShimmerAnimation = keyframes`
  from {
    background-position: top right;
  }

  to {
    background-position: top left;
  }
`

const FadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

export interface ShimmerProps {
  $width?: string | number
  $height?: string | number
}

const Shimmer = styled.div<ShimmerProps>`
  --default-bg-color: #f6f7f8;
  --default-bg-moving-gradient: linear-gradient(
    to right,
    rgb(238, 238, 238) 8%,
    rgb(222, 222, 222) 18%,
    rgb(238, 238, 238) 33%
  );

  background: var(--default-bg-color);
  background-size: 1000% 1000%;
  background-image: var(--default-bg-moving-gradient);
  background-repeat: no-repeat;

  animation: ${ShimmerAnimation} forwards infinite ease-in-out,
    ${FadeIn} 0.02s forwards;

  animation-duration: 1.2s;

  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
`

Shimmer.displayName = 'Shimmer'

Shimmer.defaultProps = {
  $width: '100%',
  $height: '100%',
}

export default Shimmer
