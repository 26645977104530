export enum EReferralsBrokersStatus {
  DECLINED = 'DECLINED',
  DONE = 'DONE',
  PENDING = 'PENDING'
}

export interface IReferralsBrokersSolicitations {
  broker: {
    address: string;
    email: string;
    id: string;
    name: string;
    phone: string;
    susep: string;
  };
  created_at: Date;
  id: Document;
  status: EReferralsBrokersStatus;
  updated_at: Date;
  partner: {
    document: string;
    email: string;
    name: string;
  }
}
