import { useLayoutEffect, useMemo } from 'react'
import qs from 'qs'
import { useMedia } from 'react-use'

import { defaultBreakpoints } from 'styled-media-query'

export const useSmoothAnchor = (top = 0, left = 0) => {
  const handler = () =>
    window.scrollTo({
      top,
      left,
      behavior: 'smooth',
    })

  return {
    handler,
  }
}

export const useOutsideClick = (ref, callback) => {
  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target) && !!callback) {
      callback()
    }
  }

  useLayoutEffect(() => {
    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  })
}

export const useLockBodyScroll = (disable?: boolean) => {
  useLayoutEffect(() => {
    if (disable) {
      document.body.style.overflowY = ''
      return
    }

    document.body.style.overflowY = 'hidden'

    return () => {
      document.body.style.overflowY = ''
    }
  }, [disable])
}

export function useQuery(options = { ignoreQueryPrefix: true }) {
  const { search } = window.location

  return useMemo(() => qs.parse(search, options), [search, options])
}

export const useIsMobile = () => {
  const isMobile = useMedia(`(max-width: ${defaultBreakpoints.large})`)

  return isMobile
}

export const useIsMobileMedium = () => {
  const isMobile = useMedia(`(max-width: ${defaultBreakpoints.medium})`)

  return isMobile
}
