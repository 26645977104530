import React, { useRef, useState, useEffect, InputHTMLAttributes } from 'react'

import { firebaseMedia, useGTM } from '@/tools'

import Crop from './Crop'

import { IAvatarProps } from './interfaces'

import {
  Container,
  Picture,
  Image,
  Actions,
  Upload,
  Input,
  Button,
} from './styles'

const Avatar: React.FC<IAvatarProps> = (props) => {
  const ref = useRef<InputHTMLAttributes<HTMLInputElement>>()
  const { setDataLayer } = useGTM()

  const [file, setFile] = useState<File>(null)
  const [cropped, setCropped] = useState(null)

  const handleFile = (event) => {
    setFile(event.target.files[0])

    if (event.target.files[0])
      setDataLayer({
        event: 'resultado_consulta',
        nome_servico: 'adicionar',
        tipo_busca: 'nova-foto',
        retorno: 'sucesso',
        descricao: 'Foto de usuário selecionada',
      })
  }

  const removePicture = (event) => {
    event.preventDefault()

    ref.current.value = ''
    setFile(null)
    setCropped(null)
  }

  const onCrop = (blob) => {
    setCropped(new File([blob], file.name))
    setFile(null)
  }

  const onCancel = () => {
    setFile(null)
  }

  useEffect(() => {
    props.onChange && props.onChange(cropped)
  }, [file])

  return (
    <>
      {file && (
        <Crop
          src={window.URL.createObjectURL(file)}
          onCrop={onCrop}
          onCancel={onCancel}
        />
      )}

      <Container>
        <Picture>
          <Image
            src={
              (cropped && window.URL.createObjectURL(cropped)) ||
              props.src ||
              firebaseMedia('images/user-default.jpg')
            }
          />
        </Picture>

        <Actions>
          <Upload>
            <Input ref={ref} type="file" onChange={handleFile} />

            <Button
              kind="ghost"
              data-gtm-type="click"
              data-gtm-clicktype="button"
              data-gtm-name="adicionar-uma-foto"
            >
              Adicionar nova foto
            </Button>
          </Upload>

          {cropped && (
            <Button
              onClick={removePicture}
              data-gtm-type="click"
              data-gtm-clicktype="button"
              data-gtm-name="remover-foto"
            >
              Remover
            </Button>
          )}
        </Actions>
      </Container>
    </>
  )
}

export default Avatar
