import { rem } from 'polished'
import styled from 'styled-components'
import media from 'styled-media-query'

import { Actions as ActionsComponent, Icons } from '@/components'
import { themes } from '@/styles'

export const Container = styled.div``

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  ${media.greaterThan('large')`
    flex-direction: row;
    align-items: flex-start;
  `}
`

export const Aside = styled.aside`
  flex: 0.3;

  border-radius: 8px;
  background-color: ${themes.page.colors.background};
`

export const Content = styled.div`
  padding: 32px;

  flex: 1;

  border-radius: 8px;
  background-color: ${themes.page.colors.background};
`

export const Heading = {
  Wrapper: styled.div`
    margin-bottom: 24px;
  `,

  Icon: styled(Icons.FeatherIcons)`
    transform: translateY(3px);

    margin-right: 8px;
    color: ${themes.text.medium};
  `,

  Title: styled.h3``,

  Description: styled.p`
    margin-top: 8px;

    font-size: ${rem('14px')};
    color: ${themes.text.medium};
  `,
}

export const Actions = styled.div`
  display: flex;
  margin-top: 16px;
`

export const Action = styled(ActionsComponent.Button)`
  &:not(:last-of-type) {
    margin-right: 1rem;
  }
`
