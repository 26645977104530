import { RouteType } from '@/router/routes/types'
import { Admin } from '@/screens'

const routes: readonly RouteType[] = [
  {
    path: '/',
    exact: true,
    component: Admin.Homepage,
  },

  {
    exact: true,
    path: '/organizacoes',
    component: Admin.Organizations.Listing,
  },

  {
    path: '/organizacoes/cadastro',
    component: Admin.Organizations.Register,
  },

  {
    path: '/organizacoes/:id',
    component: Admin.Organizations.Details,
  },

  {
    exact: true,
    path: '/usuarios',
    component: Admin.Users,
  },
] as const

export default routes
