import api from '../../index'

export async function list(params: { broker?: string; partner?: string }) {
  const response = await api.instance.v2.get(`/partnerships`, {
    params,
    headers: {
      'x-organization': params.partner || params.broker || '',
    },
  })

  return response.data
}

export async function updateProlabore(
  id: string,
  partner: string,
  payload: {
    prolabore_enabled: boolean
  }
) {
  try {
    const response = await api.instance.v2.patch(
      `/partnerships`,
      {
        ...payload,
      },
      {
        params: { broker: id, partner: partner },
      }
    )

    return response.data
  } catch (error) {
    throw error
  }
}

export async function get(id: string, partner: string = '') {
  try {
    const response = await api.instance.v1.get(
      `/organizations/${id}/partners/${partner}`
    )

    return response.data
  } catch (error) {
    throw error
  }
}

export async function create(id: string, partner: string) {
  try {
    const response = await api.instance.v1.post(
      `/organizations/${id}/partners`,
      {
        partner,
      }
    )

    return response.data
  } catch (error) {
    throw error
  }
}

export async function remove(id: string, partner: string) {
  try {
    const response = await api.instance.v1.delete(
      `/organizations/${id}/partners/${partner}`
    )

    return response.data
  } catch (error) {
    throw error
  }
}

export async function update(
  organizationId: string,
  partnerId: string,
  payload: {
    settings: {
      [key: string]: string | number | string[]
    }
  }
) {
  try {
    const response = await api.instance.v1.put(
      `/organizations/${organizationId}/partners/${partnerId}`,
      {
        ...payload,
      },
      {
        validateStatus: (status) => status < 400,
      }
    )

    return response.data
  } catch (error) {
    throw error
  }
}

export async function restoreSettings(
  organizationId: string,
  partnerId: string
) {
  try {
    const response = await api.instance.v1.delete(
      `/organizations/${organizationId}/partners/${partnerId}/settings`
    )

    return response.data
  } catch (error) {
    throw error
  }
}

export async function applyDefaultSettings(
  organizationId: string,
  partnersId: string[]
) {
  try {
    const response = await api.instance.v1.put(
      `/organizations/${organizationId}/partners/default/apply`,
      {
        partners: partnersId,
      },
      {
        validateStatus: (status) => status < 400,
      }
    )

    return response.data
  } catch (error) {
    throw error
  }
}
