import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Tag } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import React from 'react'

import { IBoxtProps } from './interfaces'
import { Container, Icon, Initials, TagContent } from './styles'

const Box = ({
  color,
  description,
  icon,
  title,
  to,
  initialsColor,
  tag,
  ...props
}: IBoxtProps) => {
  return (
    <Container {...props} to={to} color={color}>
      {tag && (
        <TagContent>
          <Tag color={tag.color}>{tag.label}</Tag>
        </TagContent>
      )}
      <Initials color={initialsColor}>
        <Icon color={color}>{icon}</Icon>
      </Initials>

      <Typography content={title} variant="porto-text-body-1-bold" />
      <Typography content={description} variant="porto-text-body-2-regular" sx={{ color: "var(--system-primitive-neutral-700)" }} />
    </Container>
  )
}
export default Box

Box.defaultProps = {
  to: '',
  title: 'Product title',
  description: '',
}
