import { useEffect, useState } from 'react'

import { Actions, Conditional, Content } from '@/components'
import { useCurrentUser, useOrganizations } from '@/modules'
import { api } from '@/services'

import { contentLabels, links } from './utils'
import { Container, ContainerButton, Link } from './styles'

import Card from './components/Card'
import CardWhatIs from './components/CardWhatIs'
import { Button } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'

const EletronicSignature = () => {
  const user = useCurrentUser()
  const organization = useOrganizations()
  const organizationId = organization.store.active.id
  const [isOwner, setIsOwner] = useState<boolean>(false)
  const [status, setStatus] = useState<boolean>(true)

  const Owner = async () => {
    try {
      const [organizations] = await Promise.all([
        api.organizations.get(organizationId),
      ])

      const { members } = organizations

      const { role } = members.find(
        (member) => member.email === user.store.email
      )

      return setIsOwner(user.store.isAdmin || role === 'owner')
    } finally {
      setStatus(false)
    }
  }

  useEffect(() => {
    Owner()
  }, [])

  return (
    <Container>
      <Conditional when={status}>
        <Content.Loader message="Aguarde, estamos carregando suas informações de Assinatura eletrônica!" />
      </Conditional>
      <Conditional when={!status}>
        <Content.Heading
          title="Assinatura eletrônica"
          subtitle="Assine os seus documentos de maneira rápida, fácil e digital."
        />
        <CardWhatIs
          title="Agora a plataforma parceira da Porto é a Clicksign. Você conhece?"
          children={contentLabels.clicksign.children}
          link={contentLabels.clicksign.link}
          iconLink="arrow-up-right"
        />
        <Card
          icon="doubleGear"
          title={contentLabels.how.title}
          children={
            isOwner
              ? contentLabels.how.children
              : contentLabels.how.childrenIntegration
          }
          background="#FFFFFF"
          colorLink="#2C3DA8"
        />
        <Card
          icon="interogationCircle"
          title={contentLabels.doubt.title}
          children={contentLabels.doubt.children}
          background="#FFFFFF"
        />
        <Card
          children={contentLabels.footer.children}
          background="#EFF4FF"
          border="30px"
        />
        <ContainerButton>
          <Conditional when={isOwner}>
            <Button
              as="a"
              href={links.access.link}
              target="_blank"
              rel="noreferrer"
              size="large"
              type="button"
              icon="arrow-up-right"
            >
              Solicitar acesso
            </Button>
          </Conditional>

          <Button
            as="a"
            href={links.have.link}
            target="_blank"
            rel="noreferrer"
            icon="arrow-up-right"
            size="large"
            type="button"
            kind={isOwner ? 'ghost' :'smooth'}
          >
            Acessar Clicksign
          </Button>
        </ContainerButton>
      </Conditional>
    </Container>
  )
}

export default EletronicSignature
