import { useCurrentOrganization } from '@/modules'
import { Box } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { SupepCombo } from '../SusepCombo'
import {
  BrokerRealEstatePartnership,
  PartnershipSusep,
} from '@/services/api/partnership/types'
import { IPartnershipSusepCombo } from './interface'
import { RealEstatePartnershipCombo } from '../RealEstatePartnershipCombo'
import { RealEstatePartnershipSupepCombo } from '../RealEstatePartnershipSupepCombo'
import { useEffect, useState } from 'react'

export function PartnershipSusepCombo({
  selectionCallback = () => {},
  error = {
    susep: null,
    partnership: null,
  },
  sx = {
    susep: null,
    partnership: null,
    box: null,
  },
  product = 'any'
}: IPartnershipSusepCombo) {
  const brokerType = 'BROKER'
  const realEstateType = 'REAL_ESTATE'
  const profile = useCurrentOrganization()
  const profileType = profile.type

  const [realEstatePartnership, setRealEstatePartnership] =
    useState<BrokerRealEstatePartnership | null>(null)
  const [susep, setSusep] = useState<PartnershipSusep | null>(null)

  useEffect(() => {
    const susepData: Partial<PartnershipSusep> = susep || {
      susep: null,
      partner: {
        id: realEstatePartnership?.id,
        name: realEstatePartnership?.name,
      },
      broker: {
        id: null,
        name: null,
      },
    }

    selectionCallback(susepData)
  }, [susep, realEstatePartnership])

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: { xs: '1fr 1fr', lg: '1fr 1fr' },
        gap: '1rem',
        marginTop: '16px',
        ...sx.box ?? {}
      }}
    >
      {profileType === realEstateType && (
        <SupepCombo
          selectionCallback={selectionCallback}
          error={!!error.susep}
          errorMessage={error.susep ?? ''}
          sx={sx?.susep ?? {}}
          product={product}
        ></SupepCombo>
      )}

      {profileType === brokerType && (
        <>
          <RealEstatePartnershipCombo
            selectionCallback={(value: BrokerRealEstatePartnership | null) => {
              setRealEstatePartnership(() => value)
            }}
            error={!!error?.partnership}
            errorMessage={error?.partnership ?? ''}
            sx={sx?.partnership ?? {}}
          ></RealEstatePartnershipCombo>

          <RealEstatePartnershipSupepCombo
            selectionCallback={(value: PartnershipSusep | null) => {
              setSusep(() => value)
            }}
            partnershipId={realEstatePartnership?.partnershipId}
            disabled={!realEstatePartnership?.partnershipId}
            error={!!error.susep}
            errorMessage={error.susep ?? ''}
            sx={sx?.susep ?? {}}
          ></RealEstatePartnershipSupepCombo>
        </>
      )}
    </Box>
  )
}
