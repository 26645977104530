import { createEffect } from 'effector'

import { api } from '@/services'
import { toast } from '@/components'

import { ContractStore } from '../store'

export const order = createEffect('orderContract', {
  handler: async (payload) => {
    const contract = await api.contracts.order(
      ContractStore.getState().id,
      payload
    )
    return contract
  },
})

ContractStore.on(order.pending, (state, pending) => ({
  ...state,
  _status: pending ? 'ordering' : 'fulfilled',
}))
  .on(order.fail, (state) => {
    toast('Ocorreu um erro ao tentar gerar a proposta.', {
      type: 'error',
    })
    return {
      ...state,
      _status: 'fulfilled',
    }
  })
  .on(order.doneData, (_, contract) => {
    toast('Proposta gerada com sucesso!', {
      type: 'success',
    })
    return contract
  })
