import { createStore } from 'effector'
import { persist } from 'effector-storage/local'

const authStore = createStore(
  {
    tokens: {
      access: '',
      refresh: '',
    },
    user: {
      name: '',
      email: '',
      avatar: '',
      phone: '',
      document: '',
      missing_user: undefined,
      isAdmin: false,
      email_verified: false,
      pending_accreditation: false,
      pending_registration: false,
    },
    organizations: {
      current: [] as any[],
      invites: [],
      active: {
        id: '',
        type: '',
        name: '',
        document: '',
        metadata: {
          additional_commission: {},
          settings: {},
          susep: {
            official: '',
            porto: [],
          },
        },
        partnerships: [],
      },
    },
  },
  {
    name: 'auth',
  }
)

persist({ store: authStore })

export default authStore
