import { useEffect, useState } from 'react'
import dayjs from 'dayjs'

import { Conditional, Content, Paginator } from '@/components'
import { useBatchesList } from '@/screens/BatchBudget/hooks'
import { limitCaracter } from '@/tools'

import FilterBatchs from './Components/FilterBatchs'
import List from './Components/List'

import {
  Container,
  ContainerList,
  ContentHeader,
  Button,
  Icon,
  Search,
  ContainerHeader,
  ContainerIcon,
} from './styles'
import CardNotice from './Components/CardNotice'
import { labelDescription } from './Components/CardNotice/utils'

const Listing = () => {
  const [search, setSearch] = useState(undefined)
  const [setActive, setActiveState] = useState('')
  const [setRotate, setRotateState] = useState('custom-arrow')
  const [alert, setAlert] = useState(false)

  const { batches, filters, page, isBroker, profile, isFetching } =
    useBatchesList(search)

  const handleFilter = async () => {
    const newFilters = await FilterBatchs.openModal(filters.get())

    if (newFilters?.status.includes('budget')) {
      newFilters.status.push('policy', 'created')
    }

    filters.apply(newFilters)
    setAlert(false)
  }

  const hasFilters =
    !!filters.get() &&
    Object.values(filters.get())?.some((filter) => {
      if (Array.isArray(filter) && !!filter.length) return true
      if (Boolean(filter) && !Array.isArray(filter)) return true
      return false
    })

  function toggle() {
    setActiveState(setActive === '' ? 'active' : '')

    setRotateState(
      setActive === 'active' ? 'custom-arrow' : 'custom-arrow rotate'
    )
  }

  useEffect(() => {
    if (batches.length === 0 && !isFetching) {
      filters.apply({})
      setSearch(undefined)
      setAlert(true)
    }
  }, [batches])

  return (
    <Container>
      <Content.Heading
        title="Consultar status do orçamento em lote"
        subtitle="Acompanhe o status de todas as suas solicitações de orçamentos em lote do seguro incêndio."
      />
      <Conditional when={isFetching}>
        <Content.Loader message="Aguarde, buscando contratos de Seguro Incêndio." />
      </Conditional>
      <Conditional when={alert && !isFetching}>
        <CardNotice icon="alertIcon" description={labelDescription} />
      </Conditional>

      <Conditional when={!isFetching}>
        <ContainerList>
          <ContentHeader>
            <ContainerHeader>
              <Search
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value || undefined)
                  setAlert(false)
                }}
                onClick={toggle}
              >
                <option value="">
                  Selecione {isBroker ? 'o parceiro' : 'a susep'}
                </option>
                <Conditional when={isBroker}>
                  {profile.partnerships.map(({ partner }) => (
                    <option value={partner.id}>
                      {`${limitCaracter(partner.name, 20, '...')} - ${
                        partner.id
                      }`}
                    </option>
                  ))}
                </Conditional>

                <Conditional when={!isBroker}>
                  {profile.partnerships
                    .map((partnership) =>
                      partnership.suseps
                        .filter((susep) => {
                          const productSuseps =
                            partnership.settings[`porto_imobiliaria.suseps`]
                          if (!productSuseps) return true

                          const residentialEnabled = profile.partnerships.find(
                            (setting) =>
                              setting.broker.id === partnership.broker.id
                          )?.settings[`porto_imobiliaria.residential.enabled`]

                          const commercialEnabled = profile.partnerships.find(
                            (setting) =>
                              setting.broker.id === partnership.broker.id
                          )?.settings[`porto_imobiliaria.commercial.enabled`]
                          if (!commercialEnabled && !residentialEnabled)
                            return false

                          return productSuseps.includes(susep)
                        })
                        .map((susep) => (
                          <option value={susep}>{`${susep} - ${limitCaracter(
                            partnership.broker.name,
                            20,
                            '...'
                          )}`}</option>
                        ))
                    )
                    .flat()}
                </Conditional>
              </Search>
              <ContainerIcon
                className={`${setRotate}`}
                name="chevron-up"
              ></ContainerIcon>
            </ContainerHeader>

            <Button
              kind={!hasFilters && 'ghost'}
              onClick={() => {
                handleFilter()
              }}
            >
              <Icon name="filter" />
              {hasFilters ? 'Editar filtros' : 'Filtros'}
            </Button>
          </ContentHeader>
          {batches.map((batch) => {
            return (
              <List
                details={[
                  {
                    label: 'Susep',
                    value: batch.susep,
                  },
                  {
                    label: 'Parceiro',
                    value: limitCaracter(batch?.partner?.name, 20, '...'),
                  },

                  {
                    label: 'Data',
                    value: dayjs(batch.created_at).format('DD/MM/YYYY'),
                  },

                  {
                    label: 'Início de vigência',
                    value: dayjs(batch.period_start).format('DD/MM/YYYY'),
                  },

                  {
                    label: 'Arquivo',
                    value: limitCaracter(batch.filename, 20, '...xls'),
                  },
                ].filter((item) => !!item?.value)}
                status={batch.status}
                batch={batch}
              />
            )
          })}
        </ContainerList>

        <Paginator
          onNext={page.next}
          onPrevious={page.prev}
          numberPage={page.get()}
          limitPage={batches.length}
        />
      </Conditional>
    </Container>
  )
}

export default Listing
