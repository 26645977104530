import { useEffect, useState } from 'react'
import { Step, Steps, Wizard, WizardContext } from 'react-albus'

import { useCurrentUser } from '@/modules'
import { api } from '@/services'
import { Container, Header } from '@/modules/static-page/components'

import {
  BankingData,
  BrokerData,
  DigitalSignature,
  PersonalData,
  RealEstateData,
  PartnerIntegration,
} from './Steps'

import { OnboardingProvider, useOnboarding } from './context'
import * as S from './styles'

const Onboarding = () => {
  const currentUser = useCurrentUser()
  const onboarding = useOnboarding()

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!currentUser.store?.email) {
      return
    }

    const fetchLead = async () => {
      try {
        setIsLoading(true)

        const data = await api.users.newAccount.getLead(currentUser.store.email)

        onboarding.dispatch({
          type: 'UPDATE_FORM_FROM_API',
          payload: data,
        })
      } catch (e) {
        console.log(e)
      } finally {
        setIsLoading(false)
      }
    }

    fetchLead()
  }, [currentUser.store?.email])

  if (isLoading) {
    return <>'loading...'</>
  }

  return (
    <OnboardingProvider>
      <S.Container>
        <Header
          title="Credenciamento Porto"
          description={`
              Os dados do credenciamento serão usados para criar a conta da sua imobiliária.
            `}
        />
        <Container>
          <Wizard
            render={(wizard: WizardContext) => (
              <Steps>
                <Step
                  id="personal-data"
                  render={() => (
                    <PersonalData wizard={wizard} currentStep={1} />
                  )}
                />
                <Step
                  id="real-estate-data"
                  render={() => (
                    <RealEstateData wizard={wizard} currentStep={2} />
                  )}
                />
                <Step
                  id="broker-data"
                  render={() => <BrokerData wizard={wizard} currentStep={3} />}
                />
                <Step
                  id="banking-data"
                  render={() => <BankingData wizard={wizard} currentStep={4} />}
                />
                <Step
                  id="partner-integration"
                  render={() => <PartnerIntegration wizard={wizard} />}
                />
                <Step
                  id="digital-signature"
                  render={() => (
                    <DigitalSignature wizard={wizard} currentStep={5} />
                  )}
                />
              </Steps>
            )}
          />
        </Container>
      </S.Container>
    </OnboardingProvider>
  )
}

const ProvidedComponent = () => {
  return (
    <OnboardingProvider>
      <Onboarding />
    </OnboardingProvider>
  )
}

export default ProvidedComponent
