import { api } from '@/services'

export enum NumberStatus {
  CONCORRENDO = 'CONCORRENDO',
  EXPIRADO = 'EXPIRADO',
  SORTEADO = 'SORTEADO',
}

export interface AwardedNumber {
  number: string
  policy: string
  partner: string
  status: NumberStatus
  bonus: boolean
}

export interface Points {
  drawDate: {
    _seconds: number
  }
  drawNumber: string
  month: string
  numbers: AwardedNumber[]
}

export async function getBrokerPoints(brokerId: string): Promise<Points[]> {
  const { data } = await api.instance.v2.get(
    `/campaigns/awarded-partnerships/brokers/${brokerId}/points`,
  )

  return data
}

export async function getPartnerPoints(partnerId: string): Promise<Points[]> {
  const { data } = await api.instance.v2.get(
    `/campaigns/awarded-partnerships/partners/${partnerId}/points`,
  )

  return data
}
