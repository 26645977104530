import { useState } from 'react'
import { useFormik, FormikProvider } from 'formik'
import * as yup from 'yup'

import { Box, Flex, Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Button } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'

import { api } from '@/services'
import { Forms, toast } from '@/components'

import { Link } from './../../shared/ui'

const Form = (props: {
  email: string,
  token: string,
  onComplete: Function,
}) => {

  const [isLoading, setIsLoading] = useState(false)

  const form = useFormik({
    initialValues: {
      password: '',
      passwordConfirm: '',
    },
    validationSchema: yup.object().shape({
      password: yup
        .string()
        .min(6, 'Mínimo de 6 caracteres.')
        .required('Campo nova senha é obrigatório.'),
      passwordConfirm: yup
        .string()
        .min(6, 'Mínimo de 6 caracteres.')
        .oneOf([yup.ref('password'), null], 'As senhas não coincidem.')
        .required('Confirme sua nova senha.'),
    }),
    onSubmit: async ({ password }) => {
      try {
        setIsLoading(true)
        await api.users.auth.updatePassword(
          props.email,
          { new: password },
          props.token
        )
        props.onComplete && props.onComplete()
      } catch (error) {
        toast(
          'Ocorreu um erro ao tentar atualizar a senha.',
          { type: 'error' }
        )
      } finally {
        setIsLoading(false)
      }
    },
  })

  return (
    <Flex
      sx={{
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <Link
        label="Voltar"
        icon="arrowleft"
        to="/"
      />
      <Typography
        variant="porto-title-3-semibold"
        content="Escolha uma nova senha"
      />
      <Typography
        variant="porto-text-body-1-regular"
        content={`
          A senha deve conter no mínimo 6 caracteres.
        `}
      />
      <Box
        sx={{
          marginTop: "1rem",
        }}
      >
        <FormikProvider value={form}>
          <form onSubmit={form.handleSubmit}>
            <Flex
              sx={{
                flexDirection: "column",
                gap: "1rem"
              }}
            >
              <Forms.InputGroup
                name="password"
                label="Nova senha"
                placeholder="Digite sua nova senha"
                type="password"
              />
              <Forms.InputGroup
                name="passwordConfirm"
                label="Confirmar nova senha"
                placeholder="Confirme sua nova senha"
                type="password"
              />
            </Flex>
            <Box
              sx={{
                marginTop: "1.5rem",
              }}
            >
              <Button
                type="submit"
                size="large"
                isLoading={isLoading}
                isDisabled={isLoading}
              >
                Continuar
              </Button>
            </Box>
          </form>
        </FormikProvider>
      </Box>
    </Flex>
  )
}

export default Form
