import theme from 'styled-theming'

export default {
  higher: theme('mode', {
    light: '#0A0047',
    dark: '#EEE',
  }),

  high: theme('mode', {
    light: '#1F1F1F',
    dark: '#AAA',
  }),

  medium: theme('mode', {
    light: '#52676F',
    dark: '#999',
  }),

  low: theme('mode', {
    light: '#5F5F5F',
    dark: '#666',
  }),
}
