import { useContract, useCurrentOrganization } from '@/modules'
import { ImobiliariaBudget } from '@/screens/Contracts/Products/Imobiliaria/Budgets'
import { Organization } from '@/screens/Contracts/Products/shared'
import { useField, useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import { BaseCard } from '../../../../components/BaseCard'
import * as S from './styles'

export const InsuranceBroker = ({ isInDetailsScreen }: { isInDetailsScreen: boolean }) => {
  const contract = useContract()
  const profile = useCurrentOrganization()
  const { setValue } = useField('partner_id')[2]
  const form = useFormikContext<ImobiliariaBudget>()
  const isBroker = profile.type === 'BROKER'
  const [partner, setPartner] = useState<string>()

  useEffect(() => {
    if (!form.touched.partner) return

    form.setValues({
      ...form.values,
      ...{
        partner,
      },
    })
  }, [partner])

  return (
    <BaseCard
      title={isBroker ? 'Imobiliária' : 'Corretora de Seguros'}
      subtitle={!isInDetailsScreen && !isBroker && 'Selecione a SUSEP do corretor de seguros.'}
    >
      <S.OrganizationWrapper>
        <Organization
          product="porto_capitalizacao"
          valuesPartner={partnerValue => {
            setValue(partnerValue?.id)
            setPartner(() => partnerValue?.id)
          }}
          data-test-id="susep"
        />
      </S.OrganizationWrapper>
    </BaseCard>
  )
}
