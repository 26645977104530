import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import * as S from './styles'
import { Banner } from './components'
import { Faq } from '@/modules/static-page/components'
import parceriaPremiada3 from '@/assets/images/parceria-premiada-3.png'
import regulamentoCorretor from '@/assets/pdfs/regulamento-parceria-premiada-corretor.pdf'
import regulamentoImobiliaria from '@/assets/pdfs/regulamento-parceria-premiada-imobiliaria.pdf'
import { useCurrentOrganization } from '@/modules'
import { DataTable } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/system'
import { api } from '@/services'
import { useQuery } from 'react-query'
import { Loader } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { pxToRem } from '@/styles/sheets/helpers'
import { AwardedNumber, NumberStatus } from '@/services/api/awarded-partnership'
import { capitalize } from '@brazilian-utils/brazilian-utils'
import { format, isValid } from 'date-fns'
import { ptBR } from 'date-fns/locale'

const numbersToTableDataMapper = (number: AwardedNumber) => ({
  ...number,
  bonus: number.bonus ? 'Bônus' : '',
  status: number.status === NumberStatus.SORTEADO ? 'Sorteado! 🎉' : capitalize(number.status),
})

const fetchBrokerPoints = id => api.awardedPartnership.getBrokerPoints(id)
const fetchPartnerPoints = id => api.awardedPartnership.getPartnerPoints(id)

const ParceriaPremiada = () => {
  const org = useCurrentOrganization()
  const isBroker = org.type === 'BROKER'

  const query = useQuery(
    ['fetchPoints'],
    () => (isBroker ? fetchBrokerPoints(org.id) : fetchPartnerPoints(org.id)),
    { refetchOnWindowFocus: false },
  )
  const hasNumbers = query.data?.length > 0

  const formatDate = (seconds: number) => {
    const date = new Date(seconds * 1000)

    return isValid(date) ? format(date, 'dd/MM', { locale: ptBR }) : ''
  }

  const renderNumbers = () => {
    return query.data?.map(points => (
      <S.MonthContainer
        key={points.month}
        bonusRows={points.numbers
          .filter(number => number.bonus)
          .map(number => points.numbers.indexOf(number) + 1)}
        expiredRows={points.numbers
          .filter(number => number.status === NumberStatus.EXPIRADO)
          .map(number => points.numbers.indexOf(number) + 1)}
        awardedRow={points.numbers.findIndex(number => number.status === NumberStatus.SORTEADO) + 1}
      >
        <Typography color="#1F1F1F" variant="porto-title-6-semibold">
          {points.month}
        </Typography>
        <Typography
          sx={{ marginTop: pxToRem(32) }}
          color="#1F1F1F"
          variant="porto-text-body-1-regular"
        >
          Data do sorteio: {formatDate(points.drawDate._seconds)}
        </Typography>
        <Typography
          sx={{ marginBottom: pxToRem(32) }}
          color="#1F1F1F"
          variant="porto-text-body-1-regular"
        >
          Número sorteado: <b>{points.drawNumber}</b>
        </Typography>
        <S.ScrollableWrapper>
          <DataTable
            actions={[]}
            columns={[
              { label: 'Números da sorte', prop: 'number' },
              isBroker
                ? { label: 'Imobiliária', prop: 'partner' }
                : { label: 'Apólice', prop: 'policy' },
              {
                label: isBroker ? 'Condição' : `Sorteio em ${formatDate(points.drawDate._seconds)}`,
                prop: 'status',
              },
              { label: '', prop: 'bonus' },
            ]}
            data={points.numbers.map(numbersToTableDataMapper)}
          />
        </S.ScrollableWrapper>
      </S.MonthContainer>
    ))
  }

  const renderNumbersTitle = () => {
    const text = hasNumbers
      ? 'Confira seus números da sorte'
      : 'Seus números da sorte estarão disponíveis aqui.'

    return (
      <>
        <Typography
          sx={{ marginTop: pxToRem(96) }}
          color="#000"
          content={text}
          variant="porto-title-4-medium"
        />
        {hasNumbers && (
          <Typography
            sx={{ marginTop: pxToRem(16) }}
            color="#1F1F1F"
            content="Os números que você recebeu em determinado mês valem apenas para o sorteio do mesmo mês."
            variant="porto-text-body-1-regular"
          />
        )}
      </>
    )
  }

  return (
    <S.Container>
      <S.FirstSection>
        <S.Wrapper>
          <Typography color="#BFCCEC" content="ParceriaPremiada" variant="porto-title-3-bold" />
          <Typography
            color="#fff"
            content="Ganhe números da sorte para concorrer sorteios mensais de R$ 5 mil."
            variant="porto-title-5-medium"
          />
          <Typography
            color="#fff"
            content="Durante o período da campanha, toda produção de Fiança Locatícia e Título de Capitalização pelo Portal das Imobiliárias geram números da sorte."
            variant="porto-text-body-1-regular"
          />
        </S.Wrapper>
        <S.Image src={parceriaPremiada3} />
      </S.FirstSection>
      <Banner />
      {query.isLoading ? (
        <S.LoaderContainer>
          <Loader size="2rem" />
        </S.LoaderContainer>
      ) : (
        <>
          {renderNumbersTitle()}
          {renderNumbers()}
        </>
      )}
      <S.Regulation>
        <Typography content="Regulamento" variant="porto-title-4-medium" />
        <Typography
          color="#404040"
          content="Acesse o regulamento completo da campanha."
          variant="porto-text-body-1-regular"
        />
        <S.Button
          icon="externalLink"
          forwardedAs="a"
          href={isBroker ? regulamentoCorretor : regulamentoImobiliaria}
          target="_blank"
          rel="noopener noreferrer"
        >
          Ver regulamento
        </S.Button>
      </S.Regulation>
      <S.Questions>
        <Faq
          anchor_id="faq"
          title="Tire suas dúvidas"
          values={[
            {
              question: 'Como funciona a campanha?',
              answer:
                'A campanha consiste na parceria entre corretores(as) e imobiliárias onde ambos deverão usar o Portal para adquirir números da sorte e concorrer a sorteios. Serão 2 sorteios mensais de R$ 5 mil reais.',
            },
            {
              question: 'Qual será o período da campanha?',
              answer:
                'A campanha irá iniciar no dia 01/05/2024 e terá a duração de 3 meses. Os sorteios acontecerão no último sábado de cada mês.',
            },
            {
              question: 'Quais produtos participarão da campanha?',
              answer:
                'Serão elegíveis emissões dos produtos Fiança Locatícia, Fiança Locatícia Essencial e o Título de Capitalização Aluguel.',
            },
            {
              question: 'Como corretores e imobiliárias irão adquirir os números da sorte?',
              answer: `Os corretores irão adquirir 10 números da sorte por cada imobiliária parceira ativa no Portal (que esteja produzindo) no período da campanha. Além disso, irão adquirir um bônus de 10 números da sorte se ao menos 50% da produção mensal da imobiliária parceira for pelo Portal das Imobiliárias.

                As imobiliárias, irão adquirir 1 número da sorte para cada emissão realizada pelo Portal no período da campanha. Além disso, irão adquirir um bônus de 10 números da sorte quando atingirem o total de 10 itens emitidos pelo Portal. E mais, a partir do 10º item vendido, a imobiliária passará a ganhar 2 números da sorte por cada nova emissão.`,
            },
            {
              question: 'Em qual momento recebo os números da Sorte?',
              answer: `Para o Fiança Locatícia, o número da sorte é recebido assim que a apólice for emitida.

              Agora, para o Título de Capitalização Aluguel, o número da sorte é recebido quando a proposta é emitida. Independente da quantidade de títulos vinculados à proposta.`,
            },
            {
              question: 'As emissões precisam ser de um mesmo produto?',
              answer: `Não. Serão consideradas emissões dos produtos Fiança Locatícia, Fiança Locatícia Essencial e o Título de Capitalização Aluguel. 
              Ex: 3 emissões do Fiança Locatícia e 5 emissões do Título de capitalização aluguel = 8 emissões.`,
            },
          ]}
        />
      </S.Questions>
    </S.Container>
  )
}

export default ParceriaPremiada
