import { BRLMoneyToNumber } from '@/tools'
import * as yup from 'yup'

export default yup.object().shape({
  property_type: yup.string().required('Informe o tipo do imóvel.'),
  property_value: yup
    .string()
    .required('Este campo precisa ser preenchido.')
    .test(
      'min_value',
      'O valor deve ser de R$ 150.000 a R$ 100.000.000.',
      (val) => {
        return typeof val !== 'undefined' && BRLMoneyToNumber(val) >= 150000
      }
    )
    .test(
      'max_value',
      'O valor deve ser de R$ 150.000 a R$ 100.000.000.',
      (val) => {
        return typeof val !== 'undefined' && BRLMoneyToNumber(val) <= 100000000
      }
    ),
  zipcode: yup.string().required('CEP é inválido.').min(9, 'CEP é inválido.'),
  property_has_debt: yup.string(),
  property_debt: yup
    .string()
    .test(
      'required_when_property_not_payed_off',
      'Esse campo precisa ser preenchido',
      (val, schema) => {
        const { property_has_debt: propertyHasDebt } = schema.parent

        return (
          (typeof val !== 'undefined' && propertyHasDebt === '0') ||
          propertyHasDebt === '1'
        )
      }
    )
    .test('must_be_less_than_property_value', '', (val, schema) => {
      const {
        property_value: propertyValue,
        property_has_debt: propertyHasDebt,
      } = schema.parent

      if (
        typeof propertyValue !== 'undefined' &&
        propertyHasDebt === '0' &&
        BRLMoneyToNumber(val) >= BRLMoneyToNumber(propertyValue)
      ) {
        return schema.createError({
          message: `O Valor tem que ser menor que ${propertyValue} (Valor do imóvel)`,
        })
      }

      return true
    }),
})
