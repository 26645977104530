import { isCNPJ, isCPF } from 'brazilian-values'
import * as yup from 'yup'

const documentValidation = (value: string) => {
  if (value.length <= 14) return isCPF(value)

  return isCNPJ(value)
}

export const validationSchema = yup.object().shape({
  document: yup
    .string()
    .required('Campo CPF/CNPJ é obrigatório.')
    .test(
      'document-test',
      'Por favor insira um CPF/CNPJ válido.',
      (value) => !!value && documentValidation(value)
    ),
  name: yup.string().required('Campo nome é obrigatório.'),
  address: yup.object().shape({
    zipcode: yup
      .string()
      .min(9, 'Por favor insira um CEP válido.')
      .required('Campo CEP é obrigatório.'),
    street: yup.string().required('Por favor confirme a rua.'),
    number: yup
      .string()
      .matches(
        /^[0-9]*$/g,
        'Utilize o campo de complemento para inserir letras.'
      )
      .required('Por favor insira o número.'),
    complement: yup.string(),
    neighborhood: yup.string().required('Por favor confirme o bairro.'),
    city: yup.string().required('Por favor confirme a cidade.'),
    state: yup.string().required('Por favor confirme o estado.'),
  }),
})
