import styled from 'styled-components'
import media from 'styled-media-query'

export const PaymentGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;

  ${media.lessThan('medium')`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${media.lessThan('small')`
    grid-template-columns:  1fr;
  `}
`

export const PaymentContainer = styled.div`
  border: 1px solid var(--border);

  > :first-child {
    margin-bottom: 1rem;
  }

  padding: 1rem;
`

export const PaymentList = styled.div`
  > :nth-child(even) {
    background: var(--border-border-neutral);
  }
`

export const PaymentListItem = styled.div`
  padding: 0.25rem;

  display: flex;
  justify-content: space-between;
`
