import api from '../index'

export async function checkPartnerDocument(props: {
  document: string
}) {
  try {
    const response = await api.instance.v2.post(
      `/partners/${props?.document}/check`
    )
    return response.data
  } catch (error) {
    throw error?.response
  }
}

export async function preRegisterPartner({
  document,
  ...props
}: {
  document: string,
  phone: string,
  name: string,
  email?: string,
  experienceMetadata: object,
}) {
  try {
    const response = await api.instance.v2.post(
      `/partners/${document}/register`,
      props,
    )
    return response.data
  } catch (error) {
    throw error?.response
  }
}

export async function getLead(email: string) {
  try {
    const response = await api.instance.v2.get(`/leads/${email}`)
    return response.data
  } catch (error) {
    throw error
  }
}

export async function createOrUpdateLead({ id, ...payload }: any) {

  const apiProps = (
    !id ?
      {
        method: 'post',
        url: `/leads`,
        body: payload,
      } : {
        method: 'patch',
        url: `/leads/${id}`,
        body: payload?.attributes,
      }
  )

  try {
    const response = await api.instance.v2[apiProps.method](
      apiProps.url,
      apiProps.body
    )
    return response.data
  } catch (error) {
    throw error?.response
  }
}

export async function createPartner(payload) {
  try {
    const response = await api.instance.v2.post(
      `/partners`,
      payload,
    )
    return response.data
  } catch (error) {
    throw error?.response
  }
}

export async function registerPartner(email) {
  try {
    const response = await api.instance.v2.post(
      `/partners/${email}/registration`
    )
    return response.data
  } catch (error) {
    throw error?.response
  }
}
