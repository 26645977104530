export const CIVIL_STATUS_OPTIONS = [
  { label: 'Casado(a)', value: 2 },
  { label: 'Solteiro(a)', value: 1 },
  { label: 'Viúvo(a)', value: 3 },
  { label: 'Divorciado(a)', value: 5 },
  { label: 'Separado(a) Judicialmente', value: 4 },
]
export const INCOME_OPTIONS = [
  { label: 'Até R$ 2.500,00', value: 2 },
  { label: 'De R$2.500,01 a R$5.000,00', value: 3 },
  { label: 'De R$5.000,01 a R$10.000,00', value: 4 },
  { label: 'Acima de R$10.000,01', value: 5 },
]
export const PEP_OPTIONS = [
  { label: 'Sim', value: 1 },
  { label: 'Não e não tem relacionamento próximo com PEP', value: 2 },
  { label: 'Não, mas possui relacionamento próximo com PEP', value: 3 },
]
export const DOCUMENT_TYPE_OPTIONS = [
  { label: 'RG', value: 1 },
  { label: 'RNE', value: 5 },
  { label: 'Classe', value: 40 },
  { label: 'CNH', value: 2 },
  { label: 'Passaporte', value: 39 },
]
export const NATIONALITY_OPTIONS = [
  { label: 'Brasileira', value: 1 },
  { label: 'Estrangeira', value: 2 },
]
export const BRAZILIAN_RESIDENT_OPTIONS = [
  { label: 'Sim', value: 1 },
  { label: 'Não', value: 2 },
]
export const RELATIONSHIP_OPTIONS = [
  { label: 'Pai ou Mãe', value: 1 },
  { label: 'Cônjuge', value: 2 },
  { label: 'Companheiro(a)', value: 3 },
  { label: 'Filho(a)', value: 4 },
  { label: 'Enteado(a)', value: 5 },
  { label: 'Outros', value: 6 },
]
