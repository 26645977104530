import styled from 'styled-components'
import {Grid as GridComponent} from '@/components'
import breakpoints from '@pol-npm/riscos-financeiros-dashboard-ui/dist/theme/breakpoints'

export const Container = styled.div`
  padding: 40px 108px;

  @media (max-width: calc(${breakpoints.sm} - 1px)) {
    padding: 40px 12px;
  }
`

export const Image = styled.img`
  width: 100%;
`

export const Grid = styled(GridComponent)`
    align-items: center;
`
