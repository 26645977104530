import { useCurrentOrganization, useOrganizations } from '@/modules'
import {
  getAcceptanceTerms,
  registerAcceptanceTerms,
} from '@/services/api/organizations/members'
import { useEffect } from 'react'
import { Modals } from '@/components'
import * as S from './styles'
import Icon from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/system/Icon'
import { Icon as PortoIcon } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { TCreateModal } from '@/components/Modals/promised'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { isBroker } from '@/tools/partnership'
import { URLS } from '@/constants'

export const AcceptanceTerms = () => {
  const organizations = useOrganizations()

  useEffect(() => {
    const verifyAcceptanceTerms = async () => {
      const acceptanceTerms = await getAcceptanceTerms()

      if (!acceptanceTerms.accepted) {
        showAcceptanceTerms()
      }
    }

    const showAcceptanceTerms = async () => {
      const accepted = await acceptanceModal()

      if (accepted) {
        registerAcceptanceTerms()
      } else {
        organizations.unsetActiveOrganization()
      }
    }

    verifyAcceptanceTerms()
  }, [organizations])

  return <></>
}

const Modal: React.FC<TCreateModal> = (props) => {
  const organization = useCurrentOrganization()

  return (
    <Modals.Content
      icon=""
      title=""
      onCancel={{
        text: 'Sair',
        onClick: (e) => {
          return props.onDismiss(e)
        },
      }}
      onSubmit={{
        text: 'Li e aceito',
        onClick: (e) => {
          return props.onSubmit(e)
        },
      }}
    >
      <S.Content>
        <S.Header>
          <PortoIcon size={20} color="porto-primitive-yellow-80" name="paper" />

          <Typography
            variant="porto-title-6-semibold"
            color="porto-primitive-black-100"
          >
            Atualização dos Termos de Uso
          </Typography>
        </S.Header>

        <S.Body>
          <Typography
            variant="porto-text-body-1-regular"
            color="porto-banking-100"
          >
            Para continuar acessando os serviços do Portal das Imobiliárias, é
            necessário ler e aceitar os novos Termos.
          </Typography>

          <a
            href={
              isBroker(organization)
                ? URLS.USE_TERMS_PDF_BROKER
                : URLS.USE_TERMS_PDF_REAL_ESTATE
            }
            target="_blank"
            rel="noreferrer"
          >
            Ler os Termos de Uso
            <Icon name="chevron_right" />
          </a>
        </S.Body>
      </S.Content>
    </Modals.Content>
  )
}

const acceptanceModal = Modals.Promised.createModal(Modal)
