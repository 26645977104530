import { createEffect, createEvent } from 'effector'

import { api } from '@/services'

import store from '../store'

export const getCurrentUser = createEffect({
  handler: async () => {
    const [user, organizations, invites] = await Promise.all([
      api.users.auth.currentUser(store.getState().user.email),
      api.organizations.get(),
      api.invites.get(),
    ])

    return {
      user,
      organizations,
      invites,
    }
  },
})

export const updateProfile = createEffect('updateUserDetails', {
  handler: async (payload) => {
    // TODO (pending) quando atualizar me retornar os dados persistidos para eu salvar na store (nome e avatar url)
    const user = await api.users.auth.updateProfile(
      store.getState().user.email,
      payload
    )

    return {
      name: user.name,
      avatar: user.avatar,
    }
  },
})

export const updateUserEmail = createEvent<string>('updateUserEmail')

store
  .on(getCurrentUser.doneData, (state, { user, organizations, invites }) => {
    return {
      ...state,
      user: {
        ...user,
        document: user.document ?? '',
        phone: user.phone ?? '',
        isAdmin: user.isAdmin || false,
      },
      organizations: {
        ...state.organizations,
        current: organizations,
        invites,
      },
    }
  })
  .on(updateProfile.doneData, (state, { name, avatar }) => {
    return {
      ...state,
      user: {
        ...state.user,
        name,
        avatar,
      },
    }
  })
  .on(updateUserEmail, (state, email) => ({
    ...state,
    user: {
      ...state.user,
      email,
    },
  }))
