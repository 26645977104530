import { useField } from 'formik'
import React, { useEffect } from 'react'

import { Loader } from '../../Icons'

import Forms from '../index'

import { ISelectGroupProps } from './interfaces'

import { Conditional } from '@/components/Utilities'
import { Arrow, Container, Field, Group, LoaderWrap, Option } from './styles'

const SelectGroup: React.FC<ISelectGroupProps> = props => {
  const [field, meta, helpers] = useField(props.name ?? '')
  const { resetValueOnUnmount = true } = props

  useEffect(() => {
    return () => {
      if (resetValueOnUnmount) helpers.setValue(props.defaultValue || '')
    }
  }, [])

  return (
    <Container hidden={props.hidden}>
      {props.label && <Forms.Label showRequired={props.showRequired}>{props.label}</Forms.Label>}
      <Field>
        <Forms.Select
          {...props}
          {...field}
          disabled={props.loading || props.disabled}
          error={meta.touched && !!meta.error}
        >
          <Conditional when={props.loading}>
            <Option value="">Carregando...</Option>
          </Conditional>
          <Conditional when={!props.loading}>
            {props.options.map(option => (
              <React.Fragment key={`select_group_option${JSON.stringify(option)}`}>
                <Conditional when={!!option.group}>
                  <Group label={option.label} key={option.label}>
                    {option.options?.map(e => (
                      <Option key={e.value} value={e.value}>
                        {e.label}
                      </Option>
                    ))}
                  </Group>
                </Conditional>
                <Conditional when={!option.group}>
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                </Conditional>
              </React.Fragment>
            ))}
          </Conditional>
        </Forms.Select>

        {props.loading && (
          <LoaderWrap>
            <Loader />
          </LoaderWrap>
        )}

        {!props.loading && (
          <Arrow.Box>
            <Arrow.Icon name="chevron-up" />
            <Arrow.Icon name="chevron-down" />
          </Arrow.Box>
        )}
      </Field>

      {meta.touched && meta.error && <Forms.Message kind="danger">{meta.error}</Forms.Message>}
    </Container>
  )
}

SelectGroup.defaultProps = {
  options: [],
}

export default SelectGroup
