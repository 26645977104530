import styled from 'styled-components'

import { Actions, Content as ContentComponent } from '@/components'
import media from 'styled-media-query'
import { rem, size } from 'polished'
import { themes } from '@/styles'

export const Container = styled.div`
  padding: 24px;

  flex: 1;

  ${media.greaterThan('huge')`
    padding: 32px;
  `}

  ${media.lessThan('medium')`
    margin-top: 10px;

    border-radius: 8px;
    background-color: #FFF;
  `}
`

export const Title = styled.div`
  margin-bottom: 24px;
  display: inline-flex;
  align-items: center;

  font-weight: 500;
  font-size: ${rem('12px')};
  color: ${themes.text.higher};
  font-family: ${themes.fonts.title};

  ${media.greaterThan('huge')`
      font-size: ${rem('16px')};
    `}

  &:hover {
    color: ${themes.text.medium};
  }

  > svg {
    ${size('16px')}

    margin-right: 8px;
    color: ${themes.palletes.primary};
  }
`

export const Message = styled(ContentComponent.Message)`
  margin-bottom: 8px;

  background-color: #fff;
`

export const Form = styled.form`
  position: relative;

  ${media.greaterThan('medium')`
    display: flex;
    
    border-radius: 8px;
    background-color: #fff;
  `}
`

export const Item = {
  Content: styled.div`
    display: grid;
    grid-column-gap: 16px;
    grid-template-columns: repeat(2, 1fr);

    width: 100%;
    padding: 16px;

    border-radius: 4px;
    border: 1px dotted ${themes.utilities.border};

    &:not(:last-of-type) {
      margin-bottom: 16px;
    }

    ${media.greaterThan('medium')`
      grid-template-columns: 0.8fr 1fr;
    `}
  `,

  Heading: styled.div``,

  Title: styled.h5`
    margin-bottom: 8px;

    font-weight: 500;
  `,

  Description: styled.h6`
    margin-top: 6px;

    line-height: 140%;
    font-weight: lighter;
    font-size: ${rem('10px')};
    color: ${themes.text.medium};

    ${media.greaterThan('huge')`
      font-size: ${rem('14px')};
    `}
  `,

  Input: styled.div`
    margin-top: 10px;
  `,
}

export const Footer = styled.div`
  margin-top: 24px;

  display: flex;
  justify-content: flex-end;
`

export const Action = styled(Actions.Button)`
  &:not(:last-of-type) {
    margin-right: 16px;
  }

  &:first-of-type {
    margin-right: auto;
  }
`
