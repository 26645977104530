import { Box, Flex, Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Illustration } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'

import PortoBankTypoLogo from './PortoBankTypoLogo'

const LoginPresentationArt = () => {
  return (
    <Flex
      sx={{
        gap: '.75rem',
        flexDirection: { xs: "column", xl: "row" }
      }}
    >
      <Box
        sx={{
          backgroundImage: `url(/images/login-picture.jpg)`,
          height: '235px',
          width: '215px',
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
        }}
      />
      <Box
        sx={{
          height: "175px",
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            height: "60%",
            width: "215px",
            top: "0",
            backgroundColor: "porto-banking-45",
            borderRadius: ".5rem",
          }}
        />
        <Illustration
          name="Keychain"
          sx={{
            position: "relative",
            height: "100%",
            zIndex: "99",
          }}
        />
      </Box>
    </Flex>
  )
}

const LoginPresentation = () => {
  return (
    <Flex
      sx={{
        flexDirection: "column",
        padding: { xs: "2rem", lg: "6rem" },
        gap: { xs: "1rem", lg: "2.5rem" },
        height: "100%",
        justifyContent: "flex-start",
        backgroundColor: "porto-banking-05",
      }}
    >
      <PortoBankTypoLogo />
      <Typography
        variant="porto-title-2-bold"
        content="Portal das Imobiliárias"
      />
      <Typography
        variant="porto-title-6-medium"
        content={`
          O Portal está de <b>cara nova!</b> Mais benefícios,
          ferramentas e soluções personalizadas
          pra cada momento.
        `}
      />
      <Box
        sx={{
          display: { xs: "none", "lg": "block" }
        }}
      >
        <LoginPresentationArt />
      </Box>
    </Flex>
  )
}

export default LoginPresentation
