import React, { useMemo, useEffect } from 'react'
import { AnimatePresence } from 'framer-motion'
import Lottie from 'react-lottie'

import { useAuth, useCurrentUser, useOrganizations } from '@/modules'
import { api } from '@/services'
import { useGTM } from '@/tools'
import {
  Content,
  Icons,
  Conditional,
  Loading,
  Actions,
  Tooltip,
  toast,
} from '@/components'

import animation from './animations/database.json'
import * as S from './styles'
import Chat from '@/components/Chat'
import { HTTP_STATUS_CODE } from '@/services/api/instance'

const ProfileSelector = () => {
  const auth = useAuth()
  const user = useCurrentUser()
  const organizations = useOrganizations()
  const { setCustomData, setDataLayer } = useGTM()

  const isOrphan = useMemo(() => {
    if (user.store.pending_accreditation) {
      return false
    }
    if (user.store.pending_registration) {
      return false
    }

    return (
      !organizations.store.current.length && !organizations.store.invites.length
    )
  }, [organizations.store, user.store])

  const invites = useMemo(
    () => organizations.store.invites,
    [organizations.store.invites]
  )

  const shouldAutoAccept =
    !organizations.store.current.length && !!invites.length

  useEffect(() => {
    if (shouldAutoAccept) organizations.acceptInvitation(invites[0].id)
  }, [shouldAutoAccept, organizations, invites])

  useEffect(() => {
    async function importOrganizations() {
      try {
        await api.users.auth.importOrganizations(user.store.email)
        await user.getCurrentUser()
      } catch (error) {
        toast(
          'Vínculo com a organização selecionada não encontrado. Para informações, entre em contato com seu consultor.',
          {
            type: 'error',
          },
        )
      }
    }

    if (isOrphan) {
      importOrganizations()
    }
  }, [isOrphan, user])

  useEffect(() => {
    setCustomData({
      site: {
        brand: 'portoseguro',
        portal: 'imobiliarias',
      },
      page: {
        name: 'Portal Imobiliária - Selecionar Perfil',
        product: 'aluguel-fianca',
      },
    })

    setDataLayer({
      event: 'step_change',
      titulo: 'Portal Imobiliária - Selecionar Perfil',
      etapa: '/area-logada/selecionar-perfil',
    })
  }, [setCustomData, setDataLayer])

  const handleSelectProfile = async (organization: any) => {
    try {
      if (organization?.blocked) {
        return;
      }

      await organizations.setActiveOrganization(organization.id)

      setDataLayer({
        event: 'solicitacao_servico',
        nome_servico: 'selecionar-perfil',
        tipo_servico: `selecionar:${
          organization.type === 'BROKER'
            ? organization.metadata.susep?.porto[0]
            : organization.id
        }`,
        ...{
          BROKER: {
            susep: organization.metadata.susep?.porto[0],
          },
          REAL_ESTATE: {
            parceiro_id: organization.id,
          },
        }[organization.type],
        retorno: 'sucesso',
        descricao: 'Perfil selecionado',
      })

      setCustomData({
        user: {
          ...{
            BROKER: {
              susep: organization.metadata.susep?.porto[0],
              parceiro_id: undefined,
            },
            REAL_ESTATE: {
              parceiro_id: organization.id,
              susep: undefined,
            },
          }[organization.type],
        },
      })
    } catch (error) {
      if (error?.response?.status !== HTTP_STATUS_CODE.NOT_FOUND) {
        window.location.reload()
      }

      setDataLayer({
        event: 'solicitacao_servico',
        nome_servico: 'selecionar-perfil',
        tipo_servico: `selecionar:${
          organization.type === 'BROKER'
            ? organization.metadata.susep?.porto[0]
            : organization.id
        }`,
        ...{
          BROKER: {
            susep: organization.metadata.susep?.porto[0],
          },
          REAL_ESTATE: {
            parceiro_id: organization.id,
          },
        }[organization.type],
        retorno: 'erro',
        descricao: 'Ocorreu um erro ao selecionar perfil',
        erro: {
          codigo: error.response?.status,
          servico: 'selecionar perfil',
          mensagem: error.response?.data?.message,
        },
      })
    }
  }

  return (
    <AnimatePresence>
      <S.Container>
        <S.TopHeader>
          <S.LogoPortoBank />

          <Tooltip content="Sair">
            <S.WrapperExit data-test-id="exit" onClick={() => auth.handleLogout()}>
              <S.ContentExit>
                <S.SpanHeader>Sair</S.SpanHeader>
              </S.ContentExit>
              <S.Close>
                <Icons.FeatherIcons name="LogOut" />
              </S.Close>
            </S.WrapperExit>
          </Tooltip>
        </S.TopHeader>

        <Conditional when={!isOrphan}>
          <S.Header>
            <S.SpanHeader>SELECIONE UM PERFIL</S.SpanHeader>
          </S.Header>
        </Conditional>

        <Conditional when={shouldAutoAccept}>
          <Loading message="Estamos buscando suas informações..." />
        </Conditional>

        <S.Content>
          <Conditional when={isOrphan}>
            <S.Denied.Wrap>
              <S.Denied.Title>Seja bem vindo ao novo portal!</S.Denied.Title>

              <S.Denied.Description>
                Identificamos que você já possuia um cadastro na versão anterior
                do Portal, estamos migrando os seus dados para que você obtenha
                uma melhor experiência!
              </S.Denied.Description>

              <React.Suspense fallback={null}>
                <Lottie
                  isClickToPauseDisabled
                  height={400}
                  options={{
                    animationData: animation,
                  }}
                />
              </React.Suspense>

              <S.Denied.Footer>
                Por favor aguarde, esse processo pode demorar alguns minutos.
              </S.Denied.Footer>
            </S.Denied.Wrap>
          </Conditional>

          <S.List>
            {organizations.store.current.map((organization) => (
              <S.Item
                key={organization.id}
                onClick={() => handleSelectProfile(organization)}
                data-gtm-type="click"
                data-gtm-clicktype="card"
                data-gtm-name={
                  {
                    BROKER: organization.metadata.susep?.porto[0],
                    REAL_ESTATE: organization.id,
                  }[organization.type]
                }
                disabled={organization?.blocked || false}
                data-test-id={`profile-${organization.id}`}
              >
                <span 
                  data-test-id="organization-name" >{organization.name}</span>
                <span
                  data-test-id="organization-id"
                >
                  {
                    {
                      BROKER: 'CNPJ',
                      REAL_ESTATE: 'Código',
                    }[organization.type]
                  }{' '}
                  {organization.id}
                </span>
                {organization?.blocked && (
                  <div>Acesso indisponível. Verifique com seu consultor.</div>
                )}
              </S.Item>
            ))}
          </S.List>

          <Conditional when={!!invites.length}>
            <S.Invites.Wrap>
              <Content.Message title="Atenção" kind="warning">
                Você possui novos convites para ingressar em organizações.
              </Content.Message>

              <S.Invites.List>
                {invites.map(({ id, organization }) => {
                  return (
                    <S.Item key={id}>
                      <span
                        data-test-id="invite-organization-name"
                      >{organization.name}</span>
                      <span
                        data-test-id="invite-organization-id"
                      >
                        {
                          {
                            BROKER: 'Susep',
                            REAL_ESTATE: 'Código',
                          }[organization.type]
                        }{' '}
                        {organization.id}
                      </span>

                      <S.Invites.Actions>
                        <Actions.Button
                          size="small"
                          variant="danger"
                          onClick={() => {
                            organizations.refuseInvitation(id)
                          }}
                          data-test-id="decline-invite"
                        >
                          Recusar
                        </Actions.Button>
                        <Actions.Button
                          size="small"
                          onClick={() => {
                            organizations.acceptInvitation(id)
                          }}
                          data-test-id="accept-invite"
                        >
                          Aceitar convite
                        </Actions.Button>
                      </S.Invites.Actions>
                    </S.Item>
                  )
                })}
              </S.Invites.List>
            </S.Invites.Wrap>
          </Conditional>
        </S.Content>
      </S.Container>
      <Chat />
    </AnimatePresence>
  )
}

export default ProfileSelector
