import styled from 'styled-components'

export const Container = styled.div`
  align-items: center;
  background: #f4f5fb;
  display: flex;
  height: 100vh;
  justify-content: center;
  padding: 0 12px;
`
export const Card = styled.div`
  background: #fff;
  border-radius: 12px;
  border: 1px solid #c8c8c8;
  max-width: 432px;
  padding: 28px 24px;
  width: 100%;
`
