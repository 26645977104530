import React, { memo, useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import { Icons, Conditional, Tooltip, Loading } from '@/components'
import { IPlanProps } from './interfaces'
import { formatMoneyBRL, handleInstallments } from '@/tools'
import { useContract } from '@/modules'
import * as schema from './schema'
import Details from '../Details'

import { api } from '@/services'

import {
  Container,
  Content,
  Header,
  Title,
  Body,
  Coverages,
  CoverageItem,
  Link,
  Badge,
  Text,
  Icon,
  Prices,
  Price,
  Actions,
  Check,
  Print,
  Footer,
} from './styles'
import ModalSelectPayments from './ModalPlan'

import { useLocation } from 'react-router-dom'

interface PaymentsPagesProps {
  setPressed?: React.Dispatch<React.SetStateAction<boolean>>
  pressed?: boolean
  status?: string
  setStatus?: React.Dispatch<React.SetStateAction<String>>
}

const Plan: React.FC<IPlanProps> = (props) => {
  const contract = useContract()
  const location = useLocation<any>()
  const propsLocation = location.state

  const [detailsVisibility, setDetailsVisibility] = useState(false)
  const [installments, setInstallments] = useState<number>()

  const [status, setStatus] = useState<'printing' | ''>('')
  const [pressed, setPressed] = useState(false)

  const orgQuerySettings = useQuery(
    ['@org/id/part/id', contract.broker.document, contract.partner.id],
    () => api.instance.v1.get(`/organizations/${contract.broker.document}/partners/${contract.partner.id}`)
  )

  const pagesProps: PaymentsPagesProps = {
    pressed,
    setPressed,
    status,
    setStatus,
  }

  useEffect(() => {
    if(!orgQuerySettings) return 
    const { settings } = orgQuerySettings?.data?.data || false
    if (!settings) return
    setInstallments(handleInstallments(settings))
  }, [orgQuerySettings])

  return (
    <>
      <Conditional when={detailsVisibility}>
        <Details
          details={props}
          onCancel={() => {
            setDetailsVisibility(false)
          }}
        />
      </Conditional>

      <Conditional when={status === 'printing'}>
        <Loading message="Aguarde, gerando PDF." />
      </Conditional>

      <Container
        onClick={props.onClick && props.onClick}
        active={props.active}
        title="Clique para selecionar"
      >
        <Content>
          <Header>
            <Actions>
              <Check.Box>
                <Check.Icon />
              </Check.Box>
            </Actions>
            <Title>{props.title}</Title>
            <Conditional when={!!props.type}>
              <Badge type={props.type}>{props.type}</Badge>
            </Conditional>
          </Header>

          <Body>
            <Coverages>
              {props.coverages.map((item: any) => (
                <CoverageItem>
                  <Icon>
                    <Icons.Ionicons name={schema.icons[item.id]} />
                  </Icon>
                  <Text>
                    {item.description} {formatMoneyBRL(item.value)}
                  </Text>
                </CoverageItem>
              ))}
            </Coverages>
          </Body>

          {
            installments ?
              <Prices>
                <Price.Highlight>
                  <Price.Content>
                    <Price.Small>{installments}x de </Price.Small>
                    <Price.SmallFess> Sem juros</Price.SmallFess>
                  </Price.Content>
                  {formatMoneyBRL(props.insurance / installments)}
                </Price.Highlight>

                <Price.Muted>
                  Valor total de {formatMoneyBRL(props.insurance)}
                </Price.Muted>
              </Prices>
            : ''
          }
        </Content>

        <Footer>
          <Link
            data-gtm-type="click"
            data-gtm-clicktype="button"
            data-gtm-name="mais-informacoes"
            data-gtm-subname={props.title}
            onClick={() => {
              setDetailsVisibility((state) => !state)
            }}
          >
            Mais informações
          </Link>

          <Print
            onClick={() => {
              setPressed(true)
            }}
          >
            <Tooltip content="Gerar PDF do orçamentos">
              <Icons.Ionicons name="print-outline" />
            </Tooltip>
          </Print>
        </Footer>
      </Container>
      <Conditional when={pressed}>
        <ModalSelectPayments details={propsLocation?.state ? propsLocation : contract} {...pagesProps} />
      </Conditional>
    </>
  )
}

export default memo(Plan)
