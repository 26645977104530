import { useEffect, useState } from 'react'

export const useBodyScrollLock = () => {
  const [isLocked, setIsLocked] = useState(false)

  useEffect(() => {
    document.body.style.overflow = isLocked ? 'hidden' : 'initial'
  }, [isLocked])

  return { setIsLocked }
}
