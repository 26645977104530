export default {
  'porto_capitalizacao.payments': [
    {
      heading: 'Boleto Bancário',
      fields: {
        'porto_capitalizacao.payments.bankSlip.enabled': {
          type: 'toggle',
          title: 'Habilitar',
          disabled: true
        },
      },
    },
    {
      heading: 'Cartão de crédito',
      fields: {
        'porto_capitalizacao.payments.creditCard.enabled': {
          type: 'toggle',
          title: 'Habilitar',
        },
      },
    },
  ],
}
