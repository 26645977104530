import styled from 'styled-components'
import media from 'styled-media-query'

export const Grid = styled.div`
  display: grid;
  gap: 32px;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 'a b';
  align-items: center;
  
  ${media.lessThan('medium')`
    grid-template-columns: 1fr;
    grid-template-areas:
        'b'
        'a';
  `}
`

export const TextContainer = styled.div`
  grid-area: a;
`

export const Image = styled.img`
  grid-area: b;
  width: 100%;
`
