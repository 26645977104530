import { AnimatePresence } from 'framer-motion'
import { useMemo } from 'react'

import { useAuth, useCurrentUser } from '../index'

import { personalizationStore } from '@/modules/personalization'
import { useUnit } from 'effector-react'
import { useQuery } from 'react-query'
import LoginSplash from './ui/LoginSplash'

const AuthProvider = props => {
  const user = useCurrentUser()
  const { isAuthenticated } = useAuth()
  const { broker } = useUnit(personalizationStore)

  const { isFetching } = useQuery(
    ['current-user', isAuthenticated, broker],
    async () => {
      await Promise.all([
        user.getCurrentUser(),
        new Promise(resolve => {
          setTimeout(resolve, 2000) // Define um tempo mínimo de 2s para exibir o loading
        }),
      ])
    },
    {
      enabled: isAuthenticated,
      refetchOnWindowFocus: false,
    },
  )

  const firstName = useMemo(() => user.store.name?.split(' ')[0] || '', [user.store.name])

  return (
    <>
      <AnimatePresence initial={false}>
        {isFetching && <LoginSplash firstName={firstName} />}
      </AnimatePresence>

      {!isFetching && props.children}
    </>
  )
}

export default AuthProvider
