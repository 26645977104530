import styled from 'styled-components'
import media from 'styled-media-query'

import { Actions } from '@/components'
import { pxToRem } from '@/styles/sheets/helpers'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Form } from 'formik'

export const Container = styled.div`
  padding: 0 54px;
`

export const Message = styled(Typography)`
  display: block;
  margin: 40px 0;
`

export const FiltersRow = styled(Form)`
  margin-top: 16px;
`

export const ProductsRow = styled.div`
  grid-column: 1 / 5;

  display: grid;
  width: 100%;
  overflow-x: auto;
  gap: 0.5rem;
  grid-template-columns: repeat(4, minmax(max-content, 1fr));

  ${media.lessThan('large')`
    grid-column: unset;
  `}
`

export const FilterButtom = styled(Actions.Button)`
  grid-column: 4;
  grid-row: 1;

  ${media.lessThan('large')`
    grid-column: unset;
    grid-row: unset;
  `}
`

export const ShowFiltersContainer = styled.div`
  grid-row-start: 1;
  grid-row: 1;
  grid-column: 1/4;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: ${pxToRem('85px')};
  height: ${pxToRem('38px')};
  padding: ${pxToRem('9px')} ${pxToRem('0px')} ${pxToRem('9px')} ${pxToRem('0px')};
  border: ${pxToRem('1px')} solid var(--border-border-input-neutral, #b3b3b3);
  border-radius: ${pxToRem('12px')};
  background: var(--surface-surface-secondary, #f7f7f7);
  color: var(--text-text-secondary, #646464);
  cursor: pointer;

  & :first-child {
    margin-left: ${pxToRem('10px')};
    margin-right: ${pxToRem('5px')};
  }
`

export const HideFiltersContainer = styled(ShowFiltersContainer)`
  ${ShowFiltersContainer};
  width: ${pxToRem('140px')};
  border: ${pxToRem('1px')} solid var(--seguros-porto-seguros-100, #0046c0);
  background: var(--seguros-porto-seguros-100, #0046c0);
  color: var(--neutras-white, #fff);
`

export const TableWrapper = styled.div`
  position: relative;
  text-align: center;
  min-height: 50vh;
`

export const CustomDateWrapper = styled.div`
  display: flex;
  gap: ${pxToRem('12px')};
  flex-direction: row;
  & > div {
    max-width: 50%;
  }
  ${media.lessThan('large')`
    flex-direction: column;
    & > div {
      max-width: 100%
    }
  `}
`

export const NewProposalContainer = styled.div`
  position: relative;
`

export const SearchFieldWrapper = styled.div`
  width: 100%;
  padding-right: ${pxToRem('16px')};
`

export const NewProposalOptionsContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translateY(101%);
  display: flex;
  max-width: ${pxToRem('273px')};
  white-space: nowrap;
  padding: ${pxToRem('8px')} ${pxToRem('0px')};
  flex-direction: column;
  align-items: flex-start;
  gap: ${pxToRem('8px')};
  border-radius: ${pxToRem('4px')};
  background: var(--neutras-white, #fff);
  box-shadow: 0 0 ${pxToRem('8px')} 0 #e0e0e0;
  z-index: 10;

  & > a {
    display: flex;
    padding: ${pxToRem('12px')} ${pxToRem('16px')};
    align-items: center;
    gap: ${pxToRem('16px')};
    align-self: stretch;
  }
`
