import { rem } from 'polished'
import styled from 'styled-components'
import media from 'styled-media-query'

import themes from '@/styles/themes'

export const Container = styled.label`
  display: inline-block;

  padding-left: 8px;
  margin-bottom: 4px;

  font-weight: 600;
  font-size: ${rem('10px')};
  color: ${themes.text.low};
  font-family: ${themes.fonts.text};

  ${media.greaterThan('huge')`
    margin-bottom: 6px;

    font-size: ${rem('12px')};
  `}
`

export const Asterisk = styled.span`
  color: #ff5a40;
`
