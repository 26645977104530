import { MouseEvent, ReactNode, useEffect } from 'react'

import { Actions, Conditional, Modals } from '@/components'

import ModalBase from '../ModalBase'
import ModalSection from '../ModalSection'

export interface IUploadProps {
  icon?: JSX.Element
  title?: string
  color?: string
  description?: string | ReactNode
  labelSubmit?: string
  statusDismiss?: string
  onDismiss?: (event: MouseEvent<HTMLButtonElement>) => void
  onSubmit?: (event: MouseEvent<HTMLButtonElement>) => void
}

const Upload = ({
  icon,
  title,
  color,
  description,
  labelSubmit,
  onSubmit,
  onDismiss,
  statusDismiss,
}: IUploadProps) => {
  useEffect(() => {
    if (statusDismiss === '') onDismiss({} as MouseEvent<HTMLButtonElement>)
  }, [statusDismiss])
  return (
    <ModalBase centered={true} icon={icon} onDismiss={onDismiss}>
      <ModalSection color={color} title={title}>
        {typeof description === 'string'
          ? (<div dangerouslySetInnerHTML={{ __html: description }} />)
          : description
        }
      </ModalSection>
      <Conditional when={!!labelSubmit}>
        <Actions.Button
          onClick={(event) => {
            onSubmit(event)
            onDismiss(event)
          }}
        >
          {labelSubmit}
        </Actions.Button>
      </Conditional>
    </ModalBase>
  )
}

const UploadModal = Modals.Promised.createModal<IUploadProps>(Upload)

export default UploadModal
