import styled from 'styled-components'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { css } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/_shared'
import Button from '@/components/Actions/Button'
import breakpoints from '@pol-npm/riscos-financeiros-dashboard-ui/dist/theme/breakpoints'

export const FirstSectionContainer = styled.div`
  padding: 64px 108px;

  @media (max-width: calc(${breakpoints.sm} - 1px)) {
    padding: 32px 12px 40px;
  }
`

export const Title = styled(Typography)(
  css({
    sx: {
      marginTop: '32px',
    },
  })
)

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 40px;
  row-gap: 16px;

  @media (max-width: ${breakpoints.sm}) {
    grid-template-columns: unset;
    row-gap: 48px;
  }
`

export const ImagesWrapper = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr;
  grid-row: 1 / span 3;

  @media (max-width: ${breakpoints.sm}) {
    grid-row: unset;
  }
`

export const Image = styled.img`
  border-radius: 16px;
  width: 100%;
`

export const KeyChainContainer = styled.div`
  align-items: end;
  border-radius: 16px;
  display: flex;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    bottom: 24px;
    right: 32px;
    left: 0;
    background: #809ad9;
    border-radius: 16px;
  }
`

export const KeyChainWrapper = styled.div`
  width: 100%;
  z-index: 0;
`

export const Text = styled(Typography)(
  css({
    sx: {
      maxWidth: { md: 'unset', lg: '393px' },
      justifySelf: 'center',
      gridRowStart: { sm: 1 },
    },
  })
)

export const Action = styled(Button)(
  css({
    sx: {
      maxWidth: { md: 'unset', lg: '393px' },
      justifySelf: 'center',
      width: '100%',
    },
  })
)
