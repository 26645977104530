import { createEvent } from 'effector'

import { ContractStore } from '../store'

export const updateAll = createEvent<any>('')

ContractStore.on(updateAll, (state, contract) => ({
  ...state,
  ...contract,
  external_status: contract.status,
}))
