export enum EAnalyticsPortal {
  imobiliarias = 'imobiliarias',
}

export enum EAnalyticsProducts {
  aluguelFianca = 'aluguel-fianca',
  fiancaEssencial = 'fianca-essencial',
  seguroIncendio = 'seguro-incendio',
  portalImobiliarias = 'portal-imobiliarias'
}

export enum EAnalyticsBrand {
  portoseguro = 'porto'
}

export interface IPAGE {
  name: string;
  product: EAnalyticsProducts;
}

export interface ISITE {
  brand: string;
  portal: string;
}

export interface IUSER {
  logado: boolean;
  protocolo: string;
  susep: string;
  user_id: string;
}

export interface ICUSTOM_DATA {
  page: IPAGE;
  site: ISITE;
  user: IUSER;
}

export interface IINITIAL_STATE {
  customData: ICUSTOM_DATA
}
