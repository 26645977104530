import {
  Container,
  Title,
  Wrapper,
  Image,
  Content,
  Link,
  WrapperLink,
  ContainerImage,
} from './styles'

import clicksign from '../../../../assets/Clicksign/clicksign.png'
import { Conditional, Icons } from '@/components'

interface CardTitleProps {
  title?: string
  children?: string
  link?: string
  iconLink?: string
}

const CardWhatIs = ({ title, children, link, iconLink }: CardTitleProps) => {
  return (
    <Container>
      <ContainerImage>
        <Image src={clicksign} />
        <Content>
          <Title>{title}</Title>
          <Wrapper dangerouslySetInnerHTML={{ __html: children }} />
          <Conditional when={!!link}>
            <WrapperLink>
              <div>
                <Link dangerouslySetInnerHTML={{ __html: link }} />
                <Icons.FeatherIcons name={iconLink || ''} height={16} />
              </div>
            </WrapperLink>
          </Conditional>
        </Content>
      </ContainerImage>
    </Container>
  )
}

export default CardWhatIs
