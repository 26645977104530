import React, { lazy, useState, useEffect } from 'react'
import { Route, useParams, useHistory } from 'react-router-dom'

import { Content, Tabs, toast } from '@/components'
import { api } from '@/services'

import Settings from './components/Settings'
import { Container } from './styles'

const tabs = {
  Partners: lazy(() => import('./components/Partners')),
}

const Details = () => {
  const params = useParams<{ id: string }>()
  const history = useHistory()

  const [loading, setLoading] = useState<boolean>(false)

  const [details, setDetais] = useState({
    name: '',
    type: '',
  })

  const fetchOrganization = async () => {
    try {
      setLoading(true)

      const response = await api.organizations.get(params.id)
      setDetais(response)
    } catch (error) {
      history.push('/organizacoes')

      toast(
        'Está organização que tentou acessar não existe e/ou está fora do ar.',
        {
          type: 'warning',
        }
      )
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchOrganization()
  }, [])

  return (
    <Container>
      {loading ? (
        <Content.Loader message="Aguarde, buscando informações da organização" />
      ) : (
        <>
          <Content.Heading
            title={details.name}
            subtitle="Gerencie os dados cadastrais da organização."
          />

          <Tabs
            items={[
              {
                icon: 'people',
                path: '/parceiros',
                title: 'Parceiros',
                content: () => (
                  <tabs.Partners
                    type={
                      {
                        REAL_ESTATE: 'REAL_ESTATE',
                        BROKER: 'BROKER',
                      }[details.type]
                    }
                  />
                ),
              },
            ]}
          />

          <Route
            path="/organizacoes/:id/parceiros/configuracoes/:partner"
            component={Settings}
          />
        </>
      )}
    </Container>
  )
}

export default Details
