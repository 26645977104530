import { Flex, Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Wrapper } from '../Wrapper'
import { IBaseCardProps } from './interfaces'

const variants = {
  primary: 'porto-primitive-white',
  secondary: 'porto-primitive-off-white',
  tertiary: 'porto-primitive-black-05'
}

export const BaseCard = ({
  children,
  description,
  id,
  image,
  title,
  variant = 'primary',
}: IBaseCardProps) => (
  <Wrapper
    id={id}
    sx={{
      backgroundColor: variants[variant],
    }}
  >
    {image && (
      <img
        src={image.src}
        alt={image.alt}
        width="100px"
      />
    )}
    <Flex
      sx={{
        flexDirection: 'column',
        gap: '.5rem'
      }}
    >
      <Typography
        content={title}
        variant="porto-title-4-medium"
      />
      {description && (
        <Typography
          content={description}
          variant="porto-body-1-regular"
        />
      )}
    </Flex>
    {children}
  </Wrapper>
)
