import api from '../index'

export * as members from './members'
export * as partnerships from './partnerships'

export async function get(id: string = '', type?: 'REAL_ESTATE' | 'BROKER') {
  const params = {
    type: {
      realEstate: 'REAL_ESTATE',
      broker: 'BROKER',
    },
  }

  try {
    const response = await api.instance.v1.get(`/organizations/${id}`, {
      params: {
        type: params.type[type] || undefined,
      },
    })

    return response.data
  } catch (error) {
    throw error
  }
}

export async function remove(id: string) {
  try {
    const response = await api.instance.v1.delete(`/organizations/${id}`)

    return response.data
  } catch (error) {
    throw error
  }
}

export async function invites(id: string) {
  try {
    const response = await api.instance.v1.get(`/organizations/${id}/invites`)

    return response.data
  } catch (error) {
    throw error
  }
}

export async function create({ type, realEstate, susep, ...payload }) {
  const METADATAS = {
    REAL_ESTATE: {
      code: realEstate.code,
    },
    BROKER: {
      susep: {
        porto: [
          susep.porto.toUpperCase(),
          ...susep.extras.map((item) => item.toUpperCase()),
        ],
        official: susep.official.toUpperCase(),
      },
    },
  }

  const parsedPayload = {
    type,
    metadata: { ...METADATAS[type] },
    ...payload,
  }

  try {
    const response = await api.instance.v1.post('/organizations', parsedPayload)

    return response.data
  } catch (error) {
    throw error
  }
}
