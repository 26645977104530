import styled from 'styled-components'
import media from 'styled-media-query'
import theme from 'styled-theming'
import { rem } from 'polished'
import { motion } from 'framer-motion'

import { themes } from '@/styles'

export const Container = styled.div`
  padding: 4px 0;

  display: flex;
  align-items: center;

  border-radius: 8px;
  border: 1px solid ${themes.utilities.border};
  background-color: ${theme('mode', {
    light: '#fbfeff',
    dark: '#1c1f25',
  })};

  ${media.greaterThan('huge')`
    padding: 8px 0;
  `}
`

export const List = styled.div`
  margin-left: 8px;

  display: flex;
  flex-wrap: wrap;
  align-items: center;

  ${media.greaterThan('huge')`
    margin-left: 16px;
  `}
`

export const Item = styled(motion.div)`
  padding: 6px 8px;

  display: flex;
  align-items: center;

  font-size: ${rem('10px')};
  border-radius: 6px;
  font-weight: 600;
  background-color: #ebedf4;
  color: ${themes.text.high};

  ${media.greaterThan('huge')`
    padding: 8px 12px;

    font-size: ${rem('12px')};
  `}

  &:not(:last-of-type) {
    margin-right: 6px;
  }
`

export const Remove = styled.button`
  all: unset;

  margin-left: 10px;

  cursor: pointer;
  color: ${themes.text.low};

  &:hover {
    color: ${themes.text.high};
  }
`

export const Search = styled.div`
  min-width: 150px;
  margin: 0 8px;

  flex: 1;

  cursor: pointer;

  ${media.greaterThan('huge')`
    min-width: 200px;
    margin: 0 16px;
  `}
`

export const Options = styled.select`
  all: unset;

  width: 100%;
  padding: 8px 0;

  font-size: 16px;
  text-align: left;
  box-sizing: border-box;
  appearance: searchfield;
  font-weight: 600;
  font-size: ${rem('10px')};
  font-family: ${themes.fonts.text};

  color: ${themes.text.high};
  -webkit-text-fill-color: ${themes.text.high};

  ${media.greaterThan('huge')`
    font-size: ${rem('13px')};
  `}
`

export const Option = styled.option`
  all: unset;

  width: 100%;

  text-align: left;
  box-sizing: border-box;
`
