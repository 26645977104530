import { formatMoneyBRL } from '@/tools'
import { firstStepForm } from './steps'

export const fullfillfirstStepContract = ({ values }) => ({
  susep: values?.broker?.id || '',
  partner: values?.partner?.id || '',
  partner_id: values?.partner?.id || '',
  customer: {
    ...firstStepForm.customer,
    ...values.payload.customer,
    socialNameToggle: !!values?.payload?.customer?.socialName,
  },
  contract: {
    ...firstStepForm.contract,
    ...values.payload.contract,
    monthly_value: values.payload?.contract?.monthly_value
      ? formatMoneyBRL(values.payload?.contract?.monthly_value)
      : '',
    total_value: values.payload?.contract?.monthly_value
      ? formatMoneyBRL(values.payload?.contract?.total_value)
      : '',
  },
})
