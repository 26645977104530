import styled from 'styled-components'

const sectionBackgroundColors = {
  primary: '#bfccec',
  secondary: '#2f2663',
}

type Colors = keyof typeof sectionBackgroundColors

export const SectionBackground = styled.div<{ backgroundColor?: Colors }>`
  width: 100%;
  background: ${({ backgroundColor }) =>
    sectionBackgroundColors[backgroundColor]};
`

SectionBackground.defaultProps = {
  backgroundColor: 'primary',
}

export const SectionContainer = styled.section`
  margin: 0 auto;
  max-width: 1440px;
`
