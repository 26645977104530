import styled from 'styled-components'

export const Susep = styled.div`
  width: 100%;

  display: flex;
  align-items: flex-start;

  > div {
    margin-right: 8px;

    flex: 1;
  }

  > button {
    margin-top: 35px;
  }
`
