import styled from 'styled-components'
import media from 'styled-media-query'
import { size, rem } from 'polished'

import ActionsComponent from '../../Actions'
import { FeatherIcons } from '../../Icons'
import { themes } from '@/styles'

export const Container = styled.div`
  padding: 16px;

  display: flex;
  align-items: flex-start;

  border-radius: 8px;
  background-color: ${themes.page.colors.content};

  ${media.greaterThan('huge')`
    padding: 24px;
  `}
`

export const Icon = styled(FeatherIcons).attrs(() => ({ name: 'map' }))`
  ${size('16px')}

  margin-right: 16px;

  stroke-width: 1.2px;
  color: ${themes.palletes.primary};

  ${media.greaterThan('huge')`
    ${size('24px')}

    margin-right: 24px;
  `}
`

export const Edit = {
  Action: styled(ActionsComponent.Button).attrs(() => ({
    forwardedAs: 'a',
    size: 'small',
    icon: 'edit',
  }))`
    height: 30px;
    padding: 0.3rem;
    margin-top: 0.5rem;

    ${media.lessThan('huge')`
      height: 25px;
    `}
  `,
}

export const Content = styled.div`
  line-height: 180%;
  font-size: ${rem('8px')};
  color: ${themes.text.high};

  ${media.greaterThan('huge')`
    font-size: ${rem('12px')};
  `}
`

export const Heading = styled.p`
  margin-bottom: 4px;

  font-weight: 500;
  font-size: ${rem('8px')};
  color: ${themes.text.low};

  ${media.greaterThan('huge')`  
    margin-bottom: 8px;

    font-size: ${rem('12px')};
  `}
`

export const Action = styled(ActionsComponent.Button).attrs(() => ({
  forwardedAs: 'a',
  size: 'small',
  icon: 'x',
}))`
  height: 30px;
  padding: 0.3rem;
  margin-top: 0.5rem;
  margin-right: 0.7rem;

  ${media.lessThan('huge')`
    height: 25px;
  `}
`
