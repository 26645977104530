export const data = [
  {
    title: 'Análise cadastral ágil e sem custo',
    text: 'A análise cadastral do seu inquilino é feita pela Porto e você imobiliária não tem nenhum custo com este processo.',
  },
  {
    title: 'Garantia de recebimento',
    text: 'Com a Porto, sua imobiliária tem garantia do recebimento das taxas administrativas.',
  },
  {
    title: 'Assinatura eletrônica grátis',
    text: 'Seja uma imobiliária ativa no Portal e agilize seus processos, reduza seus custos operacionais e feche contratos com segurança.',
  },
  {
    title: 'Apoio de equipe especializada',
    text: 'Atendimento humanizado para suporte durante todo o processo de uso da plataforma, com a qualidade Porto Bank.',
  },
  {
    title: 'Assessoria de cobrança',
    text: 'Suporte profissional, sem custos, para auxiliar sua imobiliária com a regularização de aluguéis atrasados.',
  },
]
