import {
  Box,
  Flex,
  Typography,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Button, Modal, Overlay } from './style'
import {
  Icon,
  Notification,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { Autocomplete } from '@/components'
import { useEffect, useState } from 'react'
import { api } from '@/services'
import { useOnboarding } from '../../context'
import { isCNPJ, isCPF } from 'brazilian-values'

interface IBrokerModal {
  callback: () => void
  isOpen: boolean
}

const BrokerAutocomplete = (props: { onSearch; onSelect }) => {
  return (
    <Autocomplete
      name="term"
      onSearch={props.onSearch}
      label="Corretor de seguros"
      onSelect={props.onSelect}
      placeholder="Busque por CNPJ/CPF ou Susep"
      emptySearchMessage="Nenhum corretor encontrado"
    />
  )
}

const BrokerModal = ({ callback, isOpen }: IBrokerModal) => {
  const [selected, setSelected] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const onboarding = useOnboarding()

  const handleAutocompleteSearch = async (term: string = '') => {
    if (!term) return setSelected(null)
    setIsLoading(true)

    try {
      const brokers = isDocument(term)
        ? await api.brokers.searchByDocument(term)
        : await api.brokers.searchBySusep(term)

      return brokers.reduce((arr, b) => {
        const items = b.suseps.map((s) => ({
          label: `${s} - ${b.name}`,
          value: s,
          payload: {
            ...b,
            susep: s,
          },
        }))
        arr = arr.concat(items)
        return arr
      }, [])
    } catch (e) {
      return []
    } finally {
      setIsLoading(false)
    }
  }

  const handleAutocompleteSelect = (option) => {
    if (!option) return setSelected(null)
    setSelected(option)
  }

  const handleBack = () => {
    setSelected(null)
    callback()
  }

  const handleSolicitation = async () => {
    const { address, email, id, name, phone, susep } = selected.payload

    const payload = {
      broker: {
        address,
        email,
        id,
        name,
        phone: `+55${phone?.replace(/\s/gim, '')}`,
        susep,
      },
      partner: {
        creci: onboarding.state.form.attributes.policyholder.creci,
        document:
          onboarding.state.form.attributes.policyholder.document.replace(
            /\D/g,
            ''
          ),
        email: onboarding.state.form.attributes.policyholder.email,
        name: onboarding.state.form.attributes.policyholder.name,
      },
    }

    await api.brokers.createReferralsBrokersSolicitations(payload)

    setSelected(null)
    return callback()
  }

  const isDocument = (term) => isCPF(term) || isCNPJ(term)

  useEffect(() => {
    setSelected(null)
  }, [])

  if (!isOpen) return <></>

  return (
    <Overlay>
      <Modal>
        <Box>
          <Typography
            {...{
              content: 'Nova corretora',
              variant: 'porto-title-3-semibold',
            }}
          />
        </Box>
        <Box
          {...{
            sx: {
              margin: '16px 0 0',
            },
          }}
        >
          <Typography
            {...{
              content: 'Digite o CPF/CNPJ ou Susep da corretora',
              variant: 'porto-text-body-1-regular',
            }}
          />
        </Box>
        <Box
          {...{
            sx: {
              marginTop: '1.5rem',
              display: 'grid',
              gridTemplateColumns: {
                xs: '1fr',
                md: 'repeat(3, 1fr)',
              },
              gap: '1rem',
            },
          }}
        >
          <BrokerAutocomplete
            onSearch={handleAutocompleteSearch}
            onSelect={handleAutocompleteSelect}
          />
        </Box>
        {selected && (
          <Box
            {...{
              sx: {
                margin: '16px 0 0',
              },
            }}
          >
            <Notification
              {...{
                description:
                  'Confirme os dados do corretor que vai ajudar sua imobiliária a contratar produtos Porto e clique em "Continuar".',
                icon: 'circlecheck',
                title: 'Corretor parceiro Porto!',
                variant: 'success',
              }}
            />
            <Flex
              {...{
                sx: {
                  background: '#EBF1EC',
                  borderRadius: 8,
                  flexFlow: 'column nowrap',
                  margin: '16px 0 0',
                  padding: 24,
                },
              }}
            >
              <Flex
                {...{
                  sx: {
                    flex: '0 0 100%',
                    flexFlow: 'row nowrap',
                  },
                }}
              >
                <Flex
                  {...{
                    sx: {
                      height: 20,
                      margin: '0 16px 0 0',
                      width: 20,
                    },
                  }}
                >
                  <Icon {...{ fontSize: 20, name: 'receipt' }} />
                </Flex>
                <Flex
                  {...{
                    sx: {
                      flexFlow: 'column nowrap',
                    },
                  }}
                >
                  <Typography
                    {...{
                      content: 'Susep',
                      variant: 'porto-text-body-2-bold',
                    }}
                  />
                  <Typography
                    {...{
                      content: selected.payload.susep,
                      variant: 'porto-text-body-2-regular',
                    }}
                  />
                </Flex>
              </Flex>
              <Flex
                {...{
                  sx: {
                    flex: '0 0 100%',
                    flexFlow: 'row nowrap',
                    margin: '16px 0 0',
                  },
                }}
              >
                <Flex
                  {...{
                    sx: {
                      height: 20,
                      margin: '0 16px 0 0',
                      width: 20,
                    },
                  }}
                >
                  <Icon {...{ fontSize: 20, name: 'receipt' }} />
                </Flex>
                <Flex
                  {...{
                    sx: {
                      flexFlow: 'column nowrap',
                    },
                  }}
                >
                  <Typography
                    {...{
                      content: 'Nome/Razão social',
                      variant: 'porto-text-body-2-bold',
                    }}
                  />
                  <Typography
                    {...{
                      content: selected.payload.name,
                      variant: 'porto-text-body-2-regular',
                    }}
                  />
                </Flex>
              </Flex>
              <Flex
                {...{
                  sx: {
                    flex: '0 0 100%',
                    flexFlow: 'row nowrap',
                    margin: '16px 0 0',
                  },
                }}
              >
                <Flex
                  {...{
                    sx: {
                      height: 20,
                      margin: '0 16px 0 0',
                      width: 20,
                    },
                  }}
                >
                  <Icon {...{ fontSize: 20, name: 'receipt' }} />
                </Flex>
                <Flex
                  {...{
                    sx: {
                      flexFlow: 'column nowrap',
                    },
                  }}
                >
                  <Typography
                    {...{
                      content: 'E-mail',
                      variant: 'porto-text-body-2-bold',
                    }}
                  />
                  <Typography
                    {...{
                      content: selected.payload.email,
                      variant: 'porto-text-body-2-regular',
                    }}
                  />
                </Flex>
              </Flex>
              <Flex
                {...{
                  sx: {
                    flex: '0 0 100%',
                    flexFlow: 'row nowrap',
                    margin: '16px 0 0',
                  },
                }}
              >
                <Flex
                  {...{
                    sx: {
                      height: 20,
                      margin: '0 16px 0 0',
                      width: 20,
                    },
                  }}
                >
                  <Icon {...{ fontSize: 20, name: 'receipt' }} />
                </Flex>
                <Flex
                  {...{
                    sx: {
                      flexFlow: 'column nowrap',
                    },
                  }}
                >
                  <Typography
                    {...{
                      content: 'Endereço',
                      variant: 'porto-text-body-2-bold',
                    }}
                  />
                  <Typography
                    {...{
                      content: `${selected.payload.address.street}, ${selected.payload.address.number}, ${selected.payload.address.neighborhood}, ${selected.payload.address.city}, ${selected.payload.address.state}`,
                      variant: 'porto-text-body-2-regular',
                    }}
                  />
                </Flex>
              </Flex>
            </Flex>
          </Box>
        )}
        <Flex
          {...{
            sx: {
              margin: '16px 0 0',
            },
          }}
        >
          <Box
            {...{
              sx: {
                margin: '0 16px 0 0',
                width: 82,
              },
            }}
          >
            <Button
              {...{
                disabled: isLoading,
                onClick: handleBack,
                type: 'button',
                title: 'Voltar',
              }}
            >
              Voltar
            </Button>
          </Box>
          {selected && (
            <Box>
              <Button
                {...{
                  disabled: isLoading,
                  onClick: handleSolicitation,
                  type: 'button',
                  title: 'Solicitar acesso',
                  variant: 'filled',
                  width: 155,
                }}
              >
                Solicitar vínculo
              </Button>
            </Box>
          )}
        </Flex>
      </Modal>
    </Overlay>
  )
}

export default BrokerModal
