import React from 'react'

import { Conditional, Icons, Modals } from '@/components'

import { Action, Content, Feature, Footer } from './styles'

import { plansParse } from '../../../../Budgets/utils'

const Features = (props) => {
  return (
    <Modals.Content
      title={'Coberturas do Plano ' + plansParse[props.plan.toLowerCase()]}
      icon="information-circle-outline"
      colorIcon="primary"
    >
      <Content>
        <Feature.Section>
          <Feature.List>
            <tbody>
              <tr>
                <td>Aluguel</td>
                <td>Até 30x o valor do aluguel</td>
              </tr>
              <tr>
                <td>IPTU</td>
                <td>Até 30x o valor contratado</td>
              </tr>
              <tr>
                <td>Condomínio</td>
                <td>Até 30x o valor contratado</td>
              </tr>
              <tr>
                <td>Água</td>
                <Conditional when={props.plan === 'TRADITIONAL'}>
                  <td>Até 6x o valor contratado</td>
                </Conditional>
                <Conditional when={props.plan !== 'TRADITIONAL'}>
                  <td>
                    <Icons.Ionicons name="close" />
                  </td>
                </Conditional>
              </tr>
              <tr>
                <td>Gás</td>
                <Conditional when={props.plan === 'TRADITIONAL'}>
                  <td>Até 6x o valor contratado</td>
                </Conditional>
                <Conditional when={props.plan !== 'TRADITIONAL'}>
                  <td>
                    <Icons.Ionicons name="close" />
                  </td>
                </Conditional>
              </tr>
              <tr>
                <td>Luz</td>
                <Conditional when={props.plan === 'TRADITIONAL'}>
                  <td>Até 6x o valor contratado</td>
                </Conditional>
                <Conditional when={props.plan !== 'TRADITIONAL'}>
                  <td>
                    <Icons.Ionicons name="close" />
                  </td>
                </Conditional>
              </tr>
              <tr>
                <td>Danos ao imóvel</td>
                <Conditional when={props.plan === 'TRADITIONAL'}>
                  <td>Até 6 aluguéis</td>
                </Conditional>
                <Conditional when={props.plan !== 'TRADITIONAL'}>
                  <td>
                    <Icons.Ionicons name="close" />
                  </td>
                </Conditional>
              </tr>
              <tr>
                <td>Multa por rescisão</td>
                <Conditional when={props.plan === 'TRADITIONAL'}>
                  <td>Até 3 aluguéis</td>
                </Conditional>
                <Conditional when={props.plan !== 'TRADITIONAL'}>
                  <td>
                    <Icons.Ionicons name="close" />
                  </td>
                </Conditional>
              </tr>
              <tr>
                <td>Pintura interna</td>
                <Conditional when={props.plan === 'TRADITIONAL'}>
                  <td>Até 3 aluguéis</td>
                </Conditional>
                <Conditional when={props.plan !== 'TRADITIONAL'}>
                  <td>
                    <Icons.Ionicons name="close" />
                  </td>
                </Conditional>
              </tr>
              <tr>
                <td>Pintura externa</td>
                <Conditional when={props.plan === 'TRADITIONAL'}>
                  <td>Até 3 aluguéis</td>
                </Conditional>
                <Conditional when={props.plan !== 'TRADITIONAL'}>
                  <td>
                    <Icons.Ionicons name="close" />
                  </td>
                </Conditional>
              </tr>
              <tr>
                <td>Plano de assistência</td>
                <Conditional when={props.plan === 'TRADITIONAL'}>
                  <td>Gratuito</td>
                </Conditional>
                <Conditional when={props.plan !== 'TRADITIONAL'}>
                  <td>
                    <Icons.Ionicons name="close" />
                  </td>
                </Conditional>
              </tr>
            </tbody>

            <tbody></tbody>
          </Feature.List>
        </Feature.Section>
        <Footer>
          <Action onClick={props.onCancel}>Fechar</Action>
        </Footer>
      </Content>
    </Modals.Content>
  )
}

export default Features
