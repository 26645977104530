import { EventCallable, createEvent } from 'effector'
import { menuStore } from '../store'

export const setIsMenuOpen: EventCallable<boolean> = createEvent()
export const setStep: EventCallable<number> = createEvent()

const stepsTourMenu = [
  {
    isMenuOpen: true,
    isOrganizationMenuVisible: false,
    isAccountMenuVisible: false,
  },
  {
    isMenuOpen: true,
    isOrganizationMenuVisible: false,
    isAccountMenuVisible: false,
  },
  {
    isMenuOpen: true,
    isOrganizationMenuVisible: false,
    isAccountMenuVisible: false,
  },
  {
    isMenuOpen: false,
    isOrganizationMenuVisible: true,
    isAccountMenuVisible: false,
  },
  {
    isMenuOpen: false,
    isOrganizationMenuVisible: false,
    isAccountMenuVisible: true,
  },
]

menuStore
  .on(setStep, (state, step) => ({ ...state, step, ...stepsTourMenu[step] }))
  .on(setIsMenuOpen, (state, isOpen) => ({ ...state, isMenuOpen: isOpen }))
