import { size } from 'polished'
import styled, { css } from 'styled-components'

import { Icons } from '@/components'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import media from 'styled-media-query'

type PaginationItemProps = {
  active: boolean
  page: number
}

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 8px 12px;
  background-color: var(--system-surface-primary);

  ${media.lessThan('large')`
    flex-direction: column;
    align-items: unset;
  `}
`

export const PaginationItem = styled(Typography).attrs<PaginationItemProps>(
  ({ page }) => ({ content: page, variant: 'system-small-action-label' })
)`
  padding: 0 12px;
  color: var(--system-text-action-tertiary);
  cursor: pointer;

  ${(props) =>
    props.active &&
    css`
      color: var(--system-text-action-secondary);
      border: 1px solid var(--system-text-action-secondary);
      border-radius: 16px;
    `}
`

export const DotsItem = styled(Typography).attrs(() => ({
  content: '...',
  variant: 'system-small-action-label',
}))`
  color: var(--system-text-disabled);
  cursor: default;
`

export const Icon = styled(Icons.FeatherIcons)`
  padding: 6px;
  color: var(--system-primitive-neutral-500);
  ${size('24px')}
  cursor: pointer;
`

export const Count = styled(Typography).attrs(() => ({
  variant: 'system-caption',
}))`
  color: var(--system-text-action-tertiary);
  flex: 1;
  text-align: right;
`
