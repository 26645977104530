import { IResultCardProps } from './interfaces'
import { ResultBaseCardFlex } from '../../styles'
import { ResultCardRightInfo } from './ResultCardRightInfo'
import { ResultCardLeftInfo } from './ResultCardLeftInfo'
import { AccordionCard } from '../AccordionCard'

export const ResultCard = ({
  title,
  leftInfos,
  rightInfos,
  children,
  expanded = false,
}: IResultCardProps) => {
  return (
    <AccordionCard title={title} expanded={expanded}>
      <ResultBaseCardFlex>
        <ResultCardLeftInfo value={leftInfos.value} label={leftInfos.label} />

        <ResultCardRightInfo
          leftItens={rightInfos.leftItens}
          rightItens={rightInfos.rightItens}
        />
      </ResultBaseCardFlex>
      {children}
    </AccordionCard>
  )
}
