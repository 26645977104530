import { Badge } from '@/components'
import TooltipError from '@/components/Utilities/TooltipError'

export const vailidationTooltip = (status) => {
  const parseError = {
    '@imob/integration-failure': {
      title:
        'Nosso servidor demorou para responder, clique em reenviar para calcularmos o orçamento deste proponente.',
    },
    '500': {
      title: 'Erro de integração.',
    },
    '@imob/discount': {
      title: 'Desconto técnico promocional não permitido.',
    },
  }

  if (
    status.external_status === 'failure' ||
    status.external_status === 'critical'
  ) {
    return (
      <TooltipError
        background="white"
        status="Dados incorretos"
        content={[parseError[status?.metadata?.error?.code || '500']?.title]}
        backgroundArrow="white"
      >
        <Badge
          icon={
            {
              failure: 'alert-circle',
              critical: 'alert-circle',
            }[status.external_status]
          }
          removeDot
          kind={
            {
              failure: 'danger',
              critical: 'danger',
            }[status.external_status]
          }
        >
          {
            {
              failure: 'Falha na integração',
              critical: 'Crítica',
            }[status.external_status]
          }
        </Badge>
      </TooltipError>
    )
  } else {
    return (
      <Badge
        icon={
          {
            processing: 'alert-circle',
            calculated: 'check',
            done: 'check',
          }[status.external_status]
        }
        removeDot
        kind={
          {
            processing: 'warning',
            calculated: 'success',
            done: 'success',
          }[status.external_status]
        }
      >
        {
          {
            processing: 'Em processamento',
            calculated: 'Orçamento calculado',
            done: 'Concluído',
          }[status.external_status]
        }
      </Badge>
    )
  }
}
