import { css } from 'styled-components'
import { linearGradient, rgba } from 'polished'
import theme from 'styled-theming'

export const border = theme('mode', {
  light: '#eeeef7',
  dark: '#17181D',
})

export const dissolveGradient = theme('mode', {
  light: css`
    ${linearGradient({
      colorStops: [`${rgba('#FFF', 0)}`, '#fff 100%'],
      toDirection: 'to bottom',
      fallback: 'transparent',
    })}
  `,

  dark: css`
    ${linearGradient({
      colorStops: [`${rgba('#1D2027', 0)}`, '#1D2027 100%'],
      toDirection: 'to bottom',
      fallback: 'transparent',
    })}
  `,
})
