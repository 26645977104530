import { createStore } from 'effector'

import { Products } from '@/screens/Contracts/Products/shared/entities'
import { Contract } from './interface'

const initialState = {
  id: '',
  budgets: [],
  customers: [],

  metadata: {},

  created_at: new Date(),
  updated_at: new Date(),
}

export const ContractStore = createStore<
  Partial<Contract<Products>> | typeof initialState
>(initialState)
