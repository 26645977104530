import { Form, FormikProvider, useFormik } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import { Conditional, Content, Forms, Grid, toast } from '@/components'
import validationSchema from './schema'

import { useContract } from '@/modules'
import { api } from '@/services'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Card } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import dayjs from 'dayjs'
import { Products } from '../../shared/entities'
import { Action, Actions } from '../Details/styles'

const Recalculate: React.FC<{ recalculate: boolean }> = ({ recalculate }) => {
  const contract = useContract<Products.ESSENCIAL>()
  const [isLoading, setLoading] = useState(false)

  const form = useFormik({
    initialValues: {
      contract_id: contract.id,
      period: { start: '', end: '' },
      periodContractTerm: {
        start: '',
        end: '',
      },
      rent_due_date: `${contract.payload?.rent_due_date || 5}`,
    },
    validationSchema,
    onSubmit: async ({ contract_id, ...payload }) => {
      try {
        setLoading(true)
        await api.contracts.recalculate(contract_id, payload)
        await contract.fetch(contract)
      } catch (error) {
        toast(
          'Ocorreu um erro ao atualizar orçamento. Tente novamente mais tarde!',
          {
            type: 'error',
          }
        )
      } finally {
        setLoading(false)
      }
    },
  })

  const { setFieldValue, values } = form

  const startDateContract = values.period.start

  const endDateContract = values.period.end

  const validateDate = (period: String, type: string | Date) => {
    return new Date(
      dayjs(type)
        .add(
          period === 'minEnd' ? 6 : 5,
          period === 'minEnd' ? 'month' : 'year'
        )
        .format()
    )
  }

  useEffect(() => {
    if (dayjs(startDateContract).isValid()) {
      const end = new Date(dayjs(startDateContract).add(12, 'month').format())

      setFieldValue('period.end', end)

      if (dayjs(startDateContract).isBefore(dayjs())) {
        setFieldValue('periodContractTerm.start', new Date())
        return
      }

      setFieldValue('periodContractTerm.start', new Date(startDateContract))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateContract])

  useEffect(() => {
    setFieldValue('periodContractTerm.end', endDateContract)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDateContract])

  return (
    <Grid gap="16px">
      <Conditional when={!recalculate}>
        <Content.Message title="Vigência Expirada" kind="danger">
          Para dar continuidade é necessário criar um <b>novo orçamento</b>!
        </Content.Message>
        <Actions>
          <Link to="/contratos/novo/essencial" rel="noopener noreferrer">
            <Action icon="plus">Novo Orçamento</Action>
          </Link>
        </Actions>
      </Conditional>
      <Conditional when={recalculate}>
        <Content.Message title="Vigência Expirada" kind="warning">
          A vigência do orçamento foi <b>expirada</b>! Atualize o período
          abaixo.
        </Content.Message>

        <FormikProvider value={form}>
          <Form>
            <Card>
              <Grid gap="16px">
                <Typography variant="porto-title-4-medium">
                  Recalcular
                </Typography>
                <Grid columns="repeat(2, 1fr)" gap="16px">
                  <Forms.Datepicker
                    name="period.start"
                    label="Início de contrato"
                    placeholderText="Digite o início de contrato"
                    showYearDropdown={true}
                    inputProps={{
                      'data-gtm-type': 'select',
                      'data-gtm-name': 'inicio-de-contrato',
                      'data-gtm-subname': 'datas-de-contrato-e-vigencia',
                    }}
                  />

                  <Forms.Datepicker
                    name="period.end"
                    label="Fim de contrato"
                    placeholderText="Digite o fim de contrato"
                    minDate={validateDate('minEnd', startDateContract)}
                    maxDate={validateDate('maxEnd', startDateContract)}
                    showYearDropdown={true}
                    inputProps={{
                      'data-gtm-type': 'select',
                      'data-gtm-name': 'final-de-contrato',
                      'data-gtm-subname': 'datas-de-contrato-e-vigencia',
                    }}
                  />

                  <Forms.Datepicker
                    name="periodContractTerm.start"
                    label="Início de vigência"
                    placeholderText="Digite o início de vigência"
                    minDate={new Date()}
                    showYearDropdown={true}
                    readOnly
                    inputProps={{
                      'data-gtm-type': 'select',
                      'data-gtm-name': 'inicio-de-vigencia',
                      'data-gtm-subname': 'datas-de-contrato-e-vigencia',
                    }}
                  />

                  <Forms.Datepicker
                    name="periodContractTerm.end"
                    label="Fim de vigência"
                    placeholderText="Digite o fim de vigência"
                    showYearDropdown={true}
                    readOnly
                    inputProps={{
                      'data-gtm-type': 'select',
                      'data-gtm-name': 'final-de-vigencia',
                      'data-gtm-subname': 'datas-de-contrato-e-vigencia',
                    }}
                  />
                </Grid>
                <Actions>
                  <Action
                    icon="refresh-ccw"
                    type="submit"
                    isLoading={isLoading}
                  >
                    Recalcular Orçamento
                  </Action>
                </Actions>
              </Grid>
            </Card>
          </Form>
        </FormikProvider>
      </Conditional>
    </Grid>
  )
}

export default Recalculate
