import { useFormikContext } from 'formik'
import { Fragment, useEffect, useMemo } from 'react'

import { Forms, Grid, Icons } from '@/components'

import { api } from '@/services'
import { Flex, Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Card } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { isCEP } from 'brazilian-values'
import dayjs from 'dayjs'
import { useMutation } from 'react-query'
import { FormSchemaType, useInitialStateForm } from '../form'

const Reajustment = {
  1: 'MAIOR ÍNDICE',
  2: 'IGP-M (FGV)',
  3: 'IGP-DI (FGV)',
  4: 'IPC (FIPE)',
  5: 'IPCA (IBGE)',
  6: 'INPC (FGV)',
  7: 'ICV (DIEESE)',
  8: 'INCC',
  9: 'IPC (FGV)',
}

const validateDate = (period: String, type: string | Date) => {
  return new Date(
    dayjs(type)
      .add(period === 'minEnd' ? 6 : 5, period === 'minEnd' ? 'month' : 'year')
      .format(),
  )
}

const keyValueToLabelValue = ([value, label]: string[]) => ({
  label,
  value,
})

const Estate = () => {
  const form = useFormikContext<FormSchemaType>()

  const { resend } = useInitialStateForm()

  const startDateContract = form.values.contract_period_start
  const endDateContract = form.values.contract_period_end

  const addressMutate = useMutation(async () => {
    if (form.errors?.address?.zipcode || !isCEP(form.values?.address?.zipcode))
      throw new Error('CEP inválido')

    const address = await api.addresses
      .getByZipcode(form.values.address.zipcode)
      .catch(() => undefined)

    await Promise.all([
      form.setFieldValue('address.street', address?.street || '', false),
      form.setFieldValue('address.neighborhood', address?.neighborhood || '', false),
      form.setFieldValue('address.city', address?.city || '', false),
      form.setFieldValue('address.state', address?.state || '', false),
    ])

    return address
  })

  useEffect(() => {
    if (dayjs(startDateContract).isValid()) {
      const end = new Date(dayjs(startDateContract).add(12, 'month').format())

      form.setFieldValue('contract_period_end', end)

      // Contrato de aluguel com vigência ativa?
      if (dayjs(startDateContract).isBefore(dayjs())) {
        // Contrato de seguro se inicia hoje
        form.setFieldValue('validity_period_start', new Date())
        return
      } else {
        // Contrato de seguro se inicia junto ao contrato de aluguel
        form.setFieldValue('validity_period_start', new Date(startDateContract))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateContract])

  useEffect(() => {
    form.setFieldValue('validity_period_end', endDateContract)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDateContract])

  const showAddres = useMemo(() => {
    if (form.errors?.address?.zipcode) return false

    if (addressMutate.isLoading) return false

    if (addressMutate.isError) return false

    if (!!addressMutate.data && addressMutate.data?.zipcode !== form.values?.address?.zipcode)
      return false

    return true
  }, [
    addressMutate.data,
    addressMutate.isError,
    addressMutate.isLoading,
    form.errors?.address?.zipcode,
    form.values?.address?.zipcode,
  ])

  return (
    <Card>
      <Grid gap="16px">
        <Typography as="span" variant="porto-title-4-semibold">
          Imóvel e locação
        </Typography>

        <Typography as="span" variant="porto-title-6-medium" sx={{ marginTop: '16px' }}>
          Endereço do imóvel que será alugado
        </Typography>

        <Grid columns={['1fr', '1fr', '1fr 2fr']} gap="16px">
          <Forms.InputGroup
            name="address.zipcode"
            label="CEP"
            placeholder="Digite o CEP"
            mask="zipcode"
            onBlur={() => addressMutate.mutate()}
            readOnly={addressMutate.isLoading}
            loading={addressMutate.isLoading}
          />
        </Grid>

        {showAddres && (
          <Fragment>
            <Grid columns={['1fr', 'repeat(3, 1fr)', '3fr 1fr 1fr']} gap="16px">
              <Forms.InputGroup
                name="address.street"
                label="Endereço"
                placeholder="Digite"
                type="text"
                readOnly={!!addressMutate.data?.street}
              />
              <Forms.InputGroup
                name="address.number"
                label="Número"
                placeholder="Digite"
                mask="integer"
                type="text"
              />
              <Forms.InputGroup
                name="address.complement"
                label="Complemento"
                maxLength={20}
                placeholder="ex: ap 23"
              />
            </Grid>
            <Grid columns={['1fr', '2fr 1fr 1fr', 'repeat(3, 1fr)']} gap="16px">
              <Forms.InputGroup
                name="address.neighborhood"
                label="Bairro"
                placeholder="Digite"
                readOnly={!!addressMutate.data?.neighborhood}
              />
              <Forms.InputGroup
                name="address.city"
                label="Cidade"
                placeholder="Digite"
                readOnly={addressMutate.isSuccess}
              />
              <Forms.InputGroup
                name="address.state"
                label="Estado"
                placeholder="Digite"
                readOnly={addressMutate.isSuccess}
              />
            </Grid>
          </Fragment>
        )}

        <Grid columns={['1fr', 'repeat(3, 1fr)']} gap="16px">
          <Forms.InputGroup
            mask="money"
            name="coverages.coverages_main.rent"
            placeholder="R$"
            label="Valor do aluguel"
          />

          <Forms.InputGroup
            label="Dia do vencimento aluguel"
            name="rent_due_date"
            type="number"
            min="1"
            max="28"
          />

          <Forms.SelectGroup
            name="estate_readjustment"
            resetValueOnUnmount={false}
            label="Índice de reajuste de contrato"
            options={[
              {
                label: 'Selecione o índice de contrato',
                value: '',
              },
              ...Object.entries(Reajustment).map(keyValueToLabelValue),
            ]}
          />
        </Grid>

        {!!form.errors?.coverages?.coverages_main?.rent &&
          form.errors.coverages.coverages_main.rent.includes('12.000') && (
            <Card
              sx={{
                background: 'var(--system-surface-warning)',
                border: 0,
                color: 'var(--system-text-primary)',
              }}
            >
              <Grid columns={'max-content 1fr'} gap="8px">
                <Icons.FeatherIcons color="var(--system-text-primary)" name="info" />

                <Typography as="span" variant="porto-text-body-2-regular">
                  Contratos com IS de aluguel acima de R$ 12.000 devem ser efetuados no Corretor
                  Online.
                </Typography>
              </Grid>
            </Card>
          )}

        <Typography as="span" variant="porto-title-6-medium" sx={{ marginTop: '16px' }}>
          Datas de contrato de aluguel e vigência da proposta
        </Typography>

        <Grid columns={['repeat(2, 1fr)']} gap="16px">
          <Forms.Datepicker
            name="contract_period_start"
            label="Início de contrato"
            placeholderText="Digite o início de contrato"
            showYearDropdown={true}
            inputProps={{
              'data-gtm-type': 'select',
              'data-gtm-name': 'inicio-de-contrato',
              'data-gtm-subname': 'datas-de-contrato-e-vigencia',
            }}
          />

          <Forms.Datepicker
            name="contract_period_end"
            label="Fim de contrato"
            placeholderText="Digite o fim de contrato"
            minDate={validateDate('minEnd', startDateContract)}
            maxDate={validateDate('maxEnd', startDateContract)}
            showYearDropdown={true}
            inputProps={{
              'data-gtm-type': 'select',
              'data-gtm-name': 'final-de-contrato',
              'data-gtm-subname': 'datas-de-contrato-e-vigencia',
            }}
          />
          <Forms.Datepicker
            name="validity_period_start"
            label="Início de vigência"
            placeholderText="Digite o início de vigência"
            minDate={new Date()}
            showYearDropdown={true}
            readOnly
            inputProps={{
              'data-gtm-type': 'select',
              'data-gtm-name': 'inicio-de-vigencia',
              'data-gtm-subname': 'datas-de-contrato-e-vigencia',
            }}
          />
          <Forms.Datepicker
            name="validity_period_end"
            label="Fim de vigência"
            placeholderText="Digite o fim de vigência"
            showYearDropdown={true}
            readOnly
            inputProps={{
              'data-gtm-type': 'select',
              'data-gtm-name': 'final-de-vigencia',
              'data-gtm-subname': 'datas-de-contrato-e-vigencia',
            }}
          />
        </Grid>

        <Flex>
          <Typography as="span" variant="porto-text-caption-regular" sx={{ marginRight: '.25rem' }}>
            A vigência da proposta do seguro fiança locatícia deve ser igual a vigência do contrato
            de locação, conforme{' '}
            <a
              href="https://www2.susep.gov.br/safe/bnportal/internet/pt-BR/search/51354?exp=%22%7B2019-2019%7D%22%2Fanodoc%20%22CIRCULAR%20SUSEP%22%2Fdis"
              target="_blank"
              rel="noreferrer"
            >
              Susep nº 671/2022
            </a>
          </Typography>
        </Flex>
      </Grid>
    </Card>
  )
}

export default Estate
