import { Flex, Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Collapse } from './Collapse'
import { BaseCard } from '../BaseCard'
import { IFaqProps } from './interfaces'

export const Faq = ({
  anchor_id,
  title,
  values,
  variant = 'primary',
}: IFaqProps) => (
  <BaseCard
    title={title}
    variant={variant}
    id={anchor_id}
  >
    <Flex
      sx={{
        flexDirection: 'column',
      }}
    >
      {values?.map(({ question, answer }) => (
        <Collapse
          key={`${question}_${answer}`}
          title={question}
        >
          <Typography
            content={answer}
            variant="porto-text-body-2-regular"
          />
        </Collapse>
      ))}
    </Flex>
  </BaseCard>
)