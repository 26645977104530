import { useEffect, useMemo, useState } from 'react'

import { Conditional, Grid, Icons, Modals, SemanticTable } from '@/components'

import { useContract } from '@/modules'
import { api } from '@/services'
import { dashText, formatCustomerName, formatMoneyBRL, useGTM } from '@/tools'
import { statusParse } from '../../Proposal/utils'
import contentData from './content.data.json'

import { capitalize } from '@brazilian-utils/brazilian-utils'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import {
  Button,
  Card,
  Tag,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { formatToCPFOrCNPJ, formatToPhone } from 'brazilian-values'
import { Products } from '../../../shared/entities'
import * as S from './styles'

const gtmLabelByDocumentType = {
  proposal: 'Proposta',
  policy: 'Apólice',
  budget: 'Carta',
  ticket: 'Boleto',
}

const Policy = () => {
  const { setDataLayer, setCustomData } = useGTM()
  const contract = useContract<Products.ESSENCIAL>()
  const [downloading, setDownloading] = useState<string[]>([])

  useEffect(() => {
    setCustomData({
      site: {
        brand: 'portoseguro',
        portal: 'imobiliarias',
      },
      page: {
        name: `Portal Imobiliária - Orcamentos - Fiança Locatícia Essencial - ${
          statusParse[contract.policy.status]
        }`,
        product: 'aluguel-fianca',
      },
      orcamento: {
        protocolo: contract.policy?.proposal,
        tipo: contract.product,
        parceiro_id: contract.partner?.id,
        susep: contract.broker?.id,
        plano: budget?.description,
      },
    })

    setDataLayer({
      event: 'step_change',
      titulo: 'Portal Imobiliária - orcamentos - fiança essencial',
      etapa: `/area-logada/orcamentos/fianca-essencial/${dashText(
        statusParse[contract.policy.status]
      )}`,
    })
  }, [setCustomData, setDataLayer])

  const isBoleto = ['11', '31', '55', '81'].includes(
    contract.payload?.payment?.method
  )

  const handlePrint = async (type: string, budget?: string) => {
    try {
      setDownloading((value) => [...value, type])
      await api.contracts.print(contract.id, type, budget)
      setDataLayer({
        event: 'solicitacao_servico',
        nome_servico: dashText(gtmLabelByDocumentType[type]),
        tipo_servico: 'download',
        retorno: 'sucesso',
        descricao: 'Documento gerado com sucesso.',
      })
    } catch (error) {
      setDataLayer({
        event: 'solicitacao_servico',
        nome_servico: dashText(gtmLabelByDocumentType[type]),
        tipo_servico: 'download',
        retorno: 'erro',
        descricao: 'Documento não foi gerado.',
        erro: {
          mensagem: error.toString(),
        },
      })
      if (budget === 'ticket') {
        handleInformationTicket()
      }
    } finally {
      setDownloading((value) => value.filter((v) => type !== v))
    }
  }

  const budget = contract.budgets.find((budget) => {
    return budget.id === contract.payload.payment.budget
  })

  const method = budget.payment_methods.find((method) => {
    return method.id === contract.payload.payment.method
  })

  const installment = method.installments.find((installment) => {
    return installment.id === contract.payload.payment.installment
  })

  function handleInformationTicket() {
    Modals.Information({
      icon: 'hourglass-outline',
      title: 'O boleto está sendo gerado.',
      content: `Por favor, tente novamente mais tarde.`,
    })
  }

  const customer = useMemo(
    () => contract.payload.customers[0],
    [contract.payload.customers]
  )

  const customerName = useMemo(() => {
    return formatCustomerName(customer.socialName || customer.name)
  }, [customer.name, customer.socialName])

  const subtitle = useMemo(() => {
    const proposal =
      contract.policy?.proposal && Number(contract.policy?.proposal)

    if (!proposal) return customerName

    return `Proposta ${proposal} - ${customerName}`
  }, [contract.policy?.proposal, customerName])

  const status = useMemo<keyof typeof contentData | undefined>(() => {
    if (contract.policy?.id) return 'policy'

    if (contract.payment?.method === 'ADC') return 'ticket'
  }, [contract.payment?.method, contract.policy?.id])

  const cardContent = useMemo(() => {
    if (status === 'policy') return contentData.policy

    if (status === 'ticket') return contentData.ticket

    return { title: '', content: '' }
  }, [status])

  return (
    <Card>
      <Grid gap="48px">
        <Grid gap="24px">
          <Tag color="green">{statusParse[contract.policy?.status]}</Tag>
          <Grid>
            <Typography as="span" variant="porto-title-4-semibold">
              {subtitle}
            </Typography>
            <Conditional when={!!contract.policy.id}>
              <Typography as="span" variant="porto-title-6-semibold">
                Apólice: {Number(contract.policy.id)}
              </Typography>
            </Conditional>
          </Grid>

          <Card
            sx={{
              border: 0,
              background: 'var(--system-surface-quaternary)',
            }}
          >
            <Grid gap="16px">
              <Icons.FeatherIcons name="mail" />

              <Grid>
                <Typography
                  as="span"
                  variant="porto-text-body-1-bold"
                  content={cardContent.title}
                />
                <Typography
                  as="span"
                  variant="porto-text-body-1-regular"
                  content={cardContent.content}
                />
              </Grid>
              <Grid
                columns={['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)']}
                gap="16px"
              >
                <Grid>
                  <Typography variant="porto-text-caption-bold">
                    Cliente
                  </Typography>
                  <Typography as="span" variant="porto-text-body-2-regular">
                    {customerName}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography variant="porto-text-caption-bold">
                    Telefone/celular
                  </Typography>
                  <Typography as="span" variant="porto-text-body-2-regular">
                    {formatToPhone(customer.phone)}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography variant="porto-text-caption-bold">
                    E-mail
                  </Typography>
                  <Typography as="span" variant="porto-text-body-2-regular">
                    {customer.email}
                  </Typography>
                </Grid>
              </Grid>

              <S.RowResponsive>
                <Conditional when={isBoleto}>
                  <Button
                    icon="download"
                    isLoading={downloading.includes('TICKET')}
                    onClick={() => handlePrint('TICKET')}
                  >
                    Baixar Boleto
                  </Button>
                </Conditional>

                <Conditional when={!!contract.policy.id}>
                  <Button
                    icon="download"
                    isLoading={downloading.includes('POLICY')}
                    onClick={() => handlePrint('POLICY')}
                  >
                    Baixar Apólice
                  </Button>
                </Conditional>

                <Button
                  as="a"
                  icon="whatsapp"
                  target="_blank"
                  href={`https://wa.me/55${customer.phone}`}
                >
                  Abrir WhatsApp
                </Button>
              </S.RowResponsive>
            </Grid>
          </Card>
        </Grid>

        <Grid gap="16px">
          <Typography variant="porto-title-5-bold">Inquilino</Typography>

          <Grid
            columns={['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)']}
            gap="16px"
          >
            <Grid>
              <Typography variant="porto-text-caption-bold">CPF</Typography>
              <Typography as="span" variant="porto-text-body-2-regular">
                {formatToCPFOrCNPJ(customer.document)}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="porto-text-caption-bold">
                Nome Completo
              </Typography>
              <Typography as="span" variant="porto-text-body-2-regular">
                {capitalize(customer.name)}
              </Typography>
            </Grid>
            {!!customer.socialName && (
              <Grid>
                <Typography variant="porto-text-caption-bold">
                  Nome Social
                </Typography>
                <Typography as="span" variant="porto-text-body-2-regular">
                  {capitalize(customer.socialName)}
                </Typography>
              </Grid>
            )}

            <Grid>
              <Typography variant="porto-text-caption-bold">E-mail</Typography>
              <Typography as="span" variant="porto-text-body-2-regular">
                {customer.email}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="porto-text-caption-bold">
                Telefone/celular
              </Typography>
              <Typography as="span" variant="porto-text-body-2-regular">
                {formatToPhone(customer.phone)}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="porto-text-caption-bold">
                Data de nascimento
              </Typography>
              <Typography as="span" variant="porto-text-body-2-regular">
                {new Date(customer.birthdate).toLocaleDateString(undefined, {
                  timeZone: 'UTC',
                })}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="porto-text-caption-bold">PEP</Typography>
              <Typography as="span" variant="porto-text-body-2-regular">
                Dado pendente
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid gap="16px">
          <Typography variant="porto-title-5-bold">Contrato</Typography>

          <Grid
            columns={['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)']}
            gap="16px"
          >
            <Grid>
              <Typography variant="porto-text-caption-bold">Plano</Typography>
              <Typography as="span" variant="porto-text-body-2-regular">
                {capitalize(budget.description)}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="porto-text-caption-bold">
                Valor do aluguel
              </Typography>
              <Typography as="span" variant="porto-text-body-2-regular">
                {formatMoneyBRL(contract.payload.coverages.rent)}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="porto-text-caption-bold">
                Valor total
              </Typography>
              <Typography as="span" variant="porto-text-body-2-regular">
                {formatMoneyBRL(installment.total)}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="porto-text-caption-bold">
                Início da vigência
              </Typography>
              <Typography as="span" variant="porto-text-body-2-regular">
                {new Date(
                  contract.payload.periodContractTerm.start
                ).toLocaleDateString(undefined, { timeZone: 'UTC' })}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="porto-text-caption-bold">
                Fim da vigência
              </Typography>
              <Typography as="span" variant="porto-text-body-2-regular">
                {new Date(contract.payload.period.end).toLocaleDateString(
                  undefined,
                  { timeZone: 'UTC' }
                )}
              </Typography>
            </Grid>
          </Grid>

          <SemanticTable.Table>
            <SemanticTable.Header>
              <SemanticTable.Row>
                <SemanticTable.Cell>
                  <Typography as="span" variant="porto-text-caption-bold">
                    Forma de pagamento
                  </Typography>
                </SemanticTable.Cell>
                <SemanticTable.Cell>
                  <Typography as="span" variant="porto-text-caption-bold">
                    Valor
                  </Typography>
                </SemanticTable.Cell>
              </SemanticTable.Row>
            </SemanticTable.Header>
            <SemanticTable.Body>
              <SemanticTable.Row>
                <SemanticTable.Cell>
                  <Typography as="span" variant="porto-text-body-2-regular">
                    {method.description}
                  </Typography>
                </SemanticTable.Cell>
                <SemanticTable.Cell>
                  <Typography as="span" variant="porto-text-body-2-regular">
                    {formatMoneyBRL(installment.total)}
                  </Typography>
                </SemanticTable.Cell>
              </SemanticTable.Row>
            </SemanticTable.Body>
          </SemanticTable.Table>

          <SemanticTable.Table>
            <SemanticTable.Header>
              <SemanticTable.Row>
                <SemanticTable.Cell>
                  <Typography as="span" variant="porto-text-caption-bold">
                    Benefícios
                  </Typography>
                </SemanticTable.Cell>
                <SemanticTable.Cell>
                  <Typography as="span" variant="porto-text-caption-bold">
                    Descrições
                  </Typography>
                </SemanticTable.Cell>
              </SemanticTable.Row>
            </SemanticTable.Header>
            <SemanticTable.Body>
              {budget.benefits.map((b) => (
                <SemanticTable.Row>
                  <SemanticTable.Cell>
                    <Typography as="span" variant="porto-text-body-2-regular">
                      {b.title}
                    </Typography>
                  </SemanticTable.Cell>
                  <SemanticTable.Cell>
                    <Typography as="span" variant="porto-text-body-2-regular">
                      {b.description}
                    </Typography>
                  </SemanticTable.Cell>
                </SemanticTable.Row>
              ))}
            </SemanticTable.Body>
          </SemanticTable.Table>
        </Grid>

        <Grid gap="16px">
          <Typography variant="porto-title-5-bold">
            Corretora de Seguros
          </Typography>

          {typeof contract.broker == 'object' && (
            <Grid
              columns={['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)']}
              gap="16px"
            >
              <Grid>
                <Typography variant="porto-text-caption-bold">Susep</Typography>
                <Typography as="span" variant="porto-text-body-2-regular">
                  {contract.broker.id}
                </Typography>
              </Grid>
              <Grid>
                <Typography variant="porto-text-caption-bold">Nome</Typography>
                <Typography as="span" variant="porto-text-body-2-regular">
                  {capitalize(contract.broker.name)}
                </Typography>
              </Grid>
              <Grid>
                <Typography variant="porto-text-caption-bold">
                  E-mail
                </Typography>
                <Typography as="span" variant="porto-text-body-2-regular">
                  {contract.broker.email.toLocaleLowerCase()}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>

        <Grid gap="16px">
          <Typography variant="porto-title-5-bold">Imóvel</Typography>

          <Grid
            columns={['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)']}
            gap="16px"
          >
            <Grid>
              <Typography variant="porto-text-caption-bold">CEP</Typography>
              <Typography as="span" variant="porto-text-body-2-regular">
                {contract.payload.address.zipcode}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            columns={['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)']}
            gap="16px"
          >
            <Grid>
              <Typography variant="porto-text-caption-bold">
                Endereço
              </Typography>
              <Typography as="span" variant="porto-text-body-2-regular">
                {contract.payload.address.street}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="porto-text-caption-bold">Número</Typography>
              <Typography as="span" variant="porto-text-body-2-regular">
                {contract.payload.address.number}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="porto-text-caption-bold">
                Complemento
              </Typography>
              <Typography as="span" variant="porto-text-body-2-regular">
                {contract.payload.address.complement || '-'}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="porto-text-caption-bold">Bairro</Typography>
              <Typography as="span" variant="porto-text-body-2-regular">
                {contract.payload.address.neighborhood}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="porto-text-caption-bold">Cidade</Typography>
              <Typography as="span" variant="porto-text-body-2-regular">
                {contract.payload.address.city}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="porto-text-caption-bold">Estado</Typography>
              <Typography as="span" variant="porto-text-body-2-regular">
                {contract.payload.address.state}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid gap="16px">
          <Typography variant="porto-title-5-bold">Proprietário</Typography>
          <Grid
            columns={['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)']}
            gap="16px"
          >
            <Grid>
              <Typography variant="porto-text-caption-bold">CPF</Typography>
              <Typography as="span" variant="porto-text-body-2-regular">
                {formatToCPFOrCNPJ(contract.payload.landlord.document)}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="porto-text-caption-bold">
                Nome completo
              </Typography>
              <Typography as="span" variant="porto-text-body-2-regular">
                {capitalize(contract.payload.landlord.name)}
              </Typography>
            </Grid>
            {!!contract.payload.landlord.socialName && (
              <Grid>
                <Typography variant="porto-text-caption-bold">
                  Nome social
                </Typography>
                <Typography as="span" variant="porto-text-body-2-regular">
                  {capitalize(contract.payload.landlord.socialName)}
                </Typography>
              </Grid>
            )}

            <Grid>
              <Typography variant="porto-text-caption-bold">E-mail</Typography>
              <Typography as="span" variant="porto-text-body-2-regular">
                {contract.payload.landlord.email}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="porto-text-caption-bold">
                Telefone/celular
              </Typography>
              <Typography as="span" variant="porto-text-body-2-regular">
                {formatToPhone(contract.payload.landlord.phone)}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="porto-text-caption-bold">
                Data de nascimento
              </Typography>
              <Typography as="span" variant="porto-text-body-2-regular">
                {contract.payload?.landlord?.birthdate
                  ? new Date(
                      contract.payload.landlord.birthdate
                    ).toLocaleDateString(undefined, { timeZone: 'UTC' })
                  : ' - '}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="porto-text-caption-bold">PEP</Typography>
              <Typography as="span" variant="porto-text-body-2-regular">
                {contract.payload?.landlord?.pep === '1' ? 'SIM' : 'NÃO'}
              </Typography>
            </Grid>
          </Grid>

          <Typography variant="porto-text-body-1-bold">Endereço</Typography>

          <Grid>
            <Typography variant="porto-text-caption-bold">CEP</Typography>
            <Typography as="span" variant="porto-text-body-2-regular">
              {contract.payload?.landlord?.addresses?.main.zipcode}
            </Typography>
          </Grid>

          <Grid
            columns={['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)']}
            gap="16px"
          >
            <Grid>
              <Typography variant="porto-text-caption-bold">
                Endereço
              </Typography>
              <Typography as="span" variant="porto-text-body-2-regular">
                {contract.payload.landlord.addresses.main.street}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="porto-text-caption-bold">Número</Typography>
              <Typography as="span" variant="porto-text-body-2-regular">
                {contract.payload.landlord.addresses.main.number}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="porto-text-caption-bold">
                Complemento
              </Typography>
              <Typography as="span" variant="porto-text-body-2-regular">
                {contract.payload.landlord.addresses.main.complement || '-'}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="porto-text-caption-bold">Bairro</Typography>
              <Typography as="span" variant="porto-text-body-2-regular">
                {contract.payload.landlord.addresses.main.neighborhood}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="porto-text-caption-bold">Cidade</Typography>
              <Typography as="span" variant="porto-text-body-2-regular">
                {contract.payload.landlord.addresses.main.city}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="porto-text-caption-bold">Estado</Typography>
              <Typography as="span" variant="porto-text-body-2-regular">
                {contract.payload.landlord.addresses.main.state}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}

export default Policy
