import * as yup from 'yup'

export default {
  partner: yup
    .string()
    .notOneOf([''], 'Selecione o parceiro.')
    .required('Selecione o parceiro.'),
  susep: yup
    .string()
    .notOneOf([''], 'Selecione a SUSEP.')
    .required('Selecione a SUSEP.'),
}
