import { useField } from 'formik'

import { Forms } from '../../index'
import Message from '../Message'

import { IFieldToggler } from './interfaces'

import { Unstable_NumberInput as MuiInputNumber } from '@mui/base/Unstable_NumberInput'

import * as S from './styles'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { helpers } from '@/styles'

const FieldToggler2: React.FC<IFieldToggler> = (props) => {
  const [field, meta, helpers] = useField<number>(props.name)

  return (
    <S.Container>
      <Forms.Label showRequired={props.showRequired}>{props.label}</Forms.Label>
      <MuiInputNumber
        slots={{
          root: S.MuiInputRoot,
          input: S.MuiInputElement,
          decrementButton: S.MuiButtons,
          incrementButton: S.MuiButtons,
        }}
        startAdornment={
          <Typography variant="porto-text-body-1-regular" as="span">
            R$
          </Typography>
        }
        slotProps={{
          root: {
            // @ts-ignore
            error: meta.touched && meta.error,
            readOnly: props.readOnly,
          },
          input: { ...field, step: 0.01 },
        }}
        min={0}
        step={1}
        shiftMultiplier={5}
        {...props}
      />
      {/* <Value
              {...field}
              {...{
                ...(props['data-gtm-form'] && {
                  'data-gtm-form': props['data-gtm-form'],
                }),
                ...(props['data-gtm-name'] && {
                  'data-gtm-name': props['data-gtm-name'],
                }),
              }}
              placeholder="Digite aqui"
              disabled={props.readOnly}
            /> */}

      {meta.touched && !!meta.error && (
        <Message kind="danger">{meta.error}</Message>
      )}
    </S.Container>
  )
}

FieldToggler2.defaultProps = {
  label: 'Label',
}

export default FieldToggler2
