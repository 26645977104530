import React from 'react'

import { render, reset } from './actions'

import { queryClient } from '@/index'
import { QueryClientProvider } from 'react-query'
import { PromisedModalProps, TCreateModal } from './types'

export default function createModal<TModalComponentProps extends PromisedModalProps>(
  Modal: React.ElementType<TCreateModal>,
) {
  return (props?: TModalComponentProps) => {
    const handler = new Promise<Parameters<TModalComponentProps['onSubmit']>[0]>(resolve => {
      const onSubmit = value => resolve(value)
      const onDismiss = () => resolve(undefined)

      render(
        <QueryClientProvider client={queryClient}>
          <Modal onSubmit={onSubmit} onDismiss={onDismiss} {...props} />
        </QueryClientProvider>,
      )
    })

    handler.finally(() => {
      reset()
    })

    return handler
  }
}
