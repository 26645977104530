import styled from 'styled-components'
import ReactCodeInput from 'react-verification-code-input'
import { rem } from 'polished'

import { themes } from '@/styles'
import media from 'styled-media-query'

export const Container = styled.div``

export const Field = styled(ReactCodeInput)`
  input {
    font-size: ${rem('14px')};
    color: ${themes.text.high};
    font-family: ${themes.fonts.title};
    border-color: ${themes.utilities.border};

    ${media.greaterThan('large')`
      font-size: ${rem('18px')};
    `}

    ${media.greaterThan('huge')`
      font-size: ${rem('22px')};
    `}

    &:focus {
      border-color: ${themes.palletes.primary};
    }

    &:last-child {
      border-right-color: ${themes.utilities.border};

      &:focus {
        border-right-color: ${themes.palletes.primary};
      }
    }
  }
`
