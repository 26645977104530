import { useCallback, useEffect, useState } from "react"
import * as yup from 'yup'

import { Box, Flex, Typography } from "@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base"
import { Icon } from "@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto"

import { toast } from "@/components"
import { api } from "@/services"

import { actionTypes, useOnboarding } from "./../../context"
import { Card, OnboardingWrapper } from "./../../ui"

import { Button } from './style'
import BrokerModal from './BrokerModal'
import BrokerTable from "./BrokerTable"

const BrokerDetails = (props: {
  items: Array<{
    address?: boolean;
    icon: string,
    title: string,
    description: string
  }>
}) => {
  return (
    <Flex {...{
      sx: {
        background: '#EBF1EC',
        borderColor: 'transparent',
        borderRadius: 8,
        flexFlow: 'row wrap',
        height: 200,
        padding: 24
      }
    }}>
      {props.items.filter(el => !el.address).map((el, i) => (
        <Flex key={i} {...{
          sx: {
            flex: '0 0 50%',
            flexFlow: 'row nowrap'
          }
        }}>
          <Flex {...{
            sx: {
              height: 20,
              margin: '0 16px 0 0',
              width: 20
            }
          }}>
            <Icon {...{ fontSize: 20, name: el.icon }} />
          </Flex>
          <Flex {...{
            sx: {
              flexFlow: 'column nowrap'
            }
          }}>
            <Typography {...{
              content: el.title,
              variant: 'porto-text-body-2-bold'
            }} />
            <Typography {...{
              content: el.description,
              variant: 'porto-text-body-2-regular'
            }} />
          </Flex>
        </Flex>
      ))}
      {props.items.filter(el => el.address).map((el, i) => (
        <Flex key={`1-${i}`} {...{
          sx: {
            flex: '0 0 100%',
            flexFlow: 'row nowrap'
          }
        }}>
          <Flex {...{
            sx: {
              height: 20,
              margin: '0 16px 0 0',
              width: 20
            }
          }}>
            <Icon {...{ fontSize: 20, name: el.icon }} />
          </Flex>
          <Flex {...{
            sx: {
              flexFlow: 'column nowrap'
            }
          }}>
            <Typography {...{
              content: el.title,
              variant: 'porto-text-body-2-bold'
            }} />
            <Typography {...{
              content: el.description,
              variant: 'porto-text-body-2-regular'
            }} />
          </Flex>
        </Flex>
      ))}
    </Flex>
  )
}

const BrokerData = ({
  wizard,
  currentStep,
}) => {
  const onboarding = useOnboarding()

  const [defaultBroker, setDefaultBroker] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [openModalSearchBroker, setOpenModalSearchBroker] = useState(false)
  const [referralSolicitations, setReferralSolicitations] = useState([])
  const [selectedBrokers, setSelectedBrokers] = useState([])

  const fetchRecommendedBroker = useCallback(async () => {
    try {
      setIsLoading(true)
      const broker = await api.brokers.getRecommendedBroker()
      setDefaultBroker({
        ...broker,
        susep: broker?.suseps[0]
      })

      if (!selectedBrokers.length) onboarding.dispatch({
        type: actionTypes.UPDATE_BEHAVIOR,
        payload: {
          selectedBrokers: [{
            ...broker,
            susep: broker?.suseps[0]
          }]
        }
      })

    } catch (e) {
      toast('Ocorreu um erro inesperado. Por favor, tente novamente...', {
        type: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }, [onboarding, selectedBrokers.length])

  const handleOpenModalSearchBroker = () => {
    if (!openModalSearchBroker) {
      window.scrollTo(0, 0)
      document.body.style.overflowY = 'hidden'
    } else {
      document.body.style.overflowY = 'initial'
    }

    if (openModalSearchBroker) handleReferralSolicitations()
    setOpenModalSearchBroker(!openModalSearchBroker)
  }

  const handleReferralSolicitations = useCallback(async () => {
    const data = await api.brokers.getReferralsBrokersSolicitations({ creci: onboarding.state.form.attributes.policyholder.creci })
    if (!data.length) return
    setReferralSolicitations(data)
  }, [onboarding.state.form.attributes.policyholder.creci])

  useEffect(() => {
    handleReferralSolicitations()
  }, [handleReferralSolicitations])

  useEffect(() => {
    fetchRecommendedBroker()
  }, [fetchRecommendedBroker])

  return (
    <OnboardingWrapper
      currentStep={currentStep}
      isLoading={isLoading}
      wizard={wizard}
      formData={{
        initialValues: {
        },
        validationSchema: yup.object().shape({
        }),
        onSubmit: async () => {
          try {
            setIsLoading(true)

            onboarding.dispatch({
              type: 'UPDATE_FORM_ATTRIBUTES',
              payload: {
                insuranceAgent: {
                  "id": defaultBroker.id,
                  "name": defaultBroker.name,
                  "email": defaultBroker.email,
                  "susep": defaultBroker.susep,
                  "telephone": defaultBroker.phone,
                  "address": defaultBroker.address
                }
              }
            })

            const data = await api.users.newAccount.createOrUpdateLead(onboarding.state.form)

            onboarding.dispatch({
              type: 'UPDATE_FORM_FROM_API',
              payload: data
            })

            wizard.next()

          } catch (e) {
            toast(
              `
                Ocorreu um erro inesperado. Por favor, tente novamente...
              `,
              {
                type: 'error',
              }
            )
          } finally {
            setIsLoading(false)
          }
        },
      }}
      hideNextButton={referralSolicitations.length && !selectedBrokers.length}
    >
      <Card>
        <Typography
          variant="porto-title-4-semibold"
          content="Corretora de seguros"
        />

        <Box
          sx={{
            margin: ".444rem 0 .889rem",
          }}
        >
          {
            referralSolicitations.length 
            ? <Typography {...{ color: '#5F5F5F', content: 'Identificamos abaixo suas corretoras parceiras. Selecione com quais deseja atuar', variant: 'porto-text-body-1-regular' }}/>
            : <Typography {...{ color: '#5F5F5F', content: 'Vimos que sua imobiliária não possui uma Corretora de Seguros vinculada.', variant: 'porto-text-body-1-regular' }} />
          }

        </Box>

        {
          referralSolicitations.length
            ? <Box>
              <Box {...{ sx: { margin: '0 0 16px' } }}>
                <BrokerTable {...{ referralSolicitations, selectedBrokers, setSelectedBrokers }} />
              </Box>

              <Box {...{ sx: { width: 238 } }}>
                <Button {...{
                  onClick: handleOpenModalSearchBroker,
                  type: 'button',
                  title: 'Adicionar nova corretora'
                }}>Adicionar outra corretora</Button>
              </Box>
            </Box>
            : defaultBroker && (
              <Box>
                <Box {...{
                  sx: {
                    marginTop: 32
                  }
                }}>
                  <Typography {...{
                    content: 'O seguinte corretor de seguros irá te dar suporte nas contratações:',
                    variant: 'porto-title-6-medium'
                  }} />
                </Box>
                <Box {...{
                  sx: {
                    marginTop: 16
                  }
                }}
                >
                  <BrokerDetails
                    items={[
                      {
                        icon: 'receipt',
                        title: 'Susep',
                        description: defaultBroker.susep
                      },
                      {
                        icon: 'circleuser',
                        title: 'Nome/Razão social',
                        description: defaultBroker.name
                      },
                      {
                        icon: 'phone',
                        title: 'Telefone',
                        description: defaultBroker.phone
                      },
                      {
                        icon: 'openmail',
                        title: 'E-mail',
                        description: defaultBroker.email
                      },
                      {
                        address: true,
                        icon: 'mappin',
                        title: 'Endereço',
                        description: `
                            ${defaultBroker.address?.street},
                            ${defaultBroker.address?.number},
                            ${defaultBroker.address?.neighborhood},
                            ${defaultBroker.address?.city},
                            ${defaultBroker.address?.state},
                          `
                      },
                    ]}
                  />
                </Box>
                <Box {...{
                  sx: {
                    marginTop: 32
                  }
                }}>
                  <Typography {...{
                    content: 'Se tiver uma corretora parceira, solicite o vinculo:',
                    variant: 'porto-text-body-2-regular'
                  }} />
                </Box>
                <Box {...{
                  sx: {
                    margin: '16px 0 0',
                    width: 232
                  }
                }}>
                  <Button {...{
                    onClick: handleOpenModalSearchBroker,
                    type: 'button',
                    title: 'Adicionar nova corretora',
                    width: 232
                  }}>
                    Adicionar nova corretora
                  </Button>
                </Box>
              </Box>
            )
        }
      </Card>
      {
        referralSolicitations.length
          ? <Box {...{ sx: { fontSize: '.889rem', lineHeight: '1.333rem', margin: '16px 0 0' } }}>
            <Typography {...{ content: 'Um e-mail de solicitação de acesso será enviado aos corretores selecionados.', variant: 'porto-text-body-2-regular' }} />
            <br />
            <Typography {...{ content: 'Pelo menos um corretor precisa liberar o seu acesso.', sx: { fontWeight: 'bold' },  variant: 'porto-text-body-2-regular' }} />
          </Box>
          : ''
      }
      <BrokerModal {...{ callback: handleOpenModalSearchBroker, isOpen: openModalSearchBroker }} />
    </OnboardingWrapper>
  )
}

export default BrokerData
