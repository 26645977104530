import styled, { css } from 'styled-components'
import { size } from 'polished'
import { motion } from 'framer-motion'

import { helpers, layers } from '@/styles'

import { IModalBaseProps } from './interfaces'

export const Overlay = styled.div`
  ${helpers.overlayBackdrop}
`

export const Dialog = styled.div`
  position: relative;

  max-height: calc(100vh - 32px);

  overflow-y: auto;

  display: flex;
  align-items: flex-start;
  justify-content: center;
`

export const Modal = styled(motion.div)<IModalBaseProps>`
  ${size('100%')}
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${layers.modals};
  display: ${(props) => (props.hidden ? 'none' : '')};

  ${(props) =>
    props.centered &&
    css`
      ${Dialog} {
        align-items: center;
      }
    `}

  ${(props) =>
    props.bottom &&
    css`
      ${Dialog} {
        align-items: flex-end;
      }
    `}
`
