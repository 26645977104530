import {
  Flex,
  Typography,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Card } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { IBaseCardProps } from './interfaces'

export const BaseCard = ({ title, subtitle, children }: IBaseCardProps) => {
  return (
    <Card>
      <Flex
        sx={{
          flexDirection: 'column',
          gap: '.5rem',
          marginBottom: '1.75rem',
        }}
      >
        <Typography
          content={title}
          variant="porto-title-4-semibold"
          color="porto-primitive-black-85"
        />
        {subtitle && (
          <Typography
            content={subtitle}
            variant="porto-text-body-1-regular"
            color="porto-primitive-black-65"
          />
        )}
      </Flex>
      {children}
    </Card>
  )
}
