import { Flex } from "@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base"
import { Loader } from "@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto"

export const Loading = ({ isLoading, children, sx = {} }) => (
  isLoading
  ? (
    <Flex
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        ...sx,
      }}
    >
      <Loader
        size="4rem"
        color='porto-primitive-black-65'
      />
    </Flex>
  )
  : children
)