import React from 'react'
import { addToast, removeToast } from './actions'

import { TToastOptions } from './types'

class Toast {
  private id: string = Math.random().toString()

  private options: TToastOptions = {
    delay: 5000,
    type: 'info',
  }

  public close(id: string) {
    setTimeout(() => {
      removeToast({ id })
    }, this.options.delay)
  }

  public open(message) {
    addToast({
      id: this.id,
      message,
      options: this.options,
    })

    this.close(this.id)
  }

  constructor(message: string|React.ReactNode, options: TToastOptions) {
    if (options) Object.assign(this.options, options)

    this.open(message)
  }
}

export default (message: string|React.ReactNode, options?: TToastOptions) =>
  new Toast(message, options)
