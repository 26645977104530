import { Content } from '@/components'
import styled from 'styled-components'

import { themes } from '@/styles'

export const CardContainer = styled.div`
  display: flex;
  padding: 32px 32px 32px 64px;
  margin: 0;
  align-items: flex-start;
  justify-content: space-between;

  & > svg {
    margin-top: 6rem;
  }
`

export const CardContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
`

export const CardDescription = styled.p`
  margin-top: 16px;
  margin-bottom: 16px;
  color: ${themes.text.high};
  font-size: 14px;
`
export const ErrorMessage = styled.span`
  color: red;
`

export const Loader = styled(Content.Loader)`
  background-color: transparent;
`
