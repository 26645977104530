import styled from 'styled-components'
import { Status } from '.'

const zIndex = 9999999
const iframeHeight = '700px'
const iframeWidth = '380px'
const overlayWidth = '58px'
const overlayHeight = '64px'
const padding = '16px'
const buttonSize = '64px'
const gap = '8px'

export const IframeContainer = styled.div<{ status: Status }>`
  border-radius: 20px;
  box-shadow: rgba(23, 73, 77, 0.15) 0px 20px 30px;
  display: ${props => (props.status === Status.Visible ? 'block' : 'none')};
  height: ${iframeHeight};
  inset: auto ${padding} calc(${buttonSize} + ${padding} + ${gap}) auto;
  max-height: calc(100vh - 104px);
  position: fixed;
  transform-origin: center bottom;
  width: ${iframeWidth};
  z-index: ${zIndex};

  @media (max-width: 600px) {
    bottom: 0;
    height: 100%;
    left: 0;
    max-height: none;
    right: 0;
    top: 0;
    width: 100%;
  }
`

export const Iframe = styled.iframe`
  background-color: white;
  border-radius: 20px;
  border: 0px;
  height: 100%;
  position: absolute;
  width: 100%;
`

export const Overlay = styled.div`
  cursor: pointer;
  width: ${overlayWidth};
  height: ${overlayHeight};
  position: absolute;
  z-index: 1;
`

export const ButtonContainer = styled.div`
  background: rgb(44, 61, 168);
  border-radius: 50%;
  bottom: ${padding};
  display: flex;
  height: ${buttonSize};
  position: fixed;
  right: ${padding};
  width: ${buttonSize};
  z-index: calc(${zIndex} - 1);
`

export const Button = styled.div`
  align-items: center;
  background: none;
  border: none;
  display: flex;
  flex: 1;
  justify-content: center;
`
