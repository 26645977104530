import { formatMoneyBRL } from '@/tools'
import { formatToCPFOrCNPJ } from 'brazilian-values'
import dayjs from 'dayjs'

export const formatResume = ({ contract }) => [
  {
    title: 'Proposta',
    values: [
      {
        title: 'Criado em:',
        description: dayjs(contract.created_at).format('DD/MM/YYYY') ?? '-',
      },
    ],
  },
  {
    title: 'Contrato',
    values: [
      {
        title: 'Valor:',
        description: formatMoneyBRL(contract.payload.contract.total_value) ?? '-',
      },
      {
        title: 'Plano:',
        description: `${contract?.payload?.contract?.plan_validity} meses (${contract?.payload.contract?.plan_rescue}%)`,
      },
    ],
  },
  {
    title: 'Corretora de Seguros',
    values: [
      {
        title: `${contract?.broker?.id} -`,
        description: contract?.broker?.name ?? '-',
      },
    ],
  },
  {
    title: 'Inquilino',
    values: [
      {
        title: `${contract?.payload?.customer?.socialName || contract?.payload?.customer?.name} -`,
        description: formatToCPFOrCNPJ(contract?.payload?.customer?.document) ?? '',
      },
    ],
  },
]
