export const icons = {
    '0': 'key-outline',
    '1': 'business-outline',
    '2': 'trail-sign-outline',
    '3': 'bulb-outline',
    '4': 'water-outline',
    '5': 'flame-outline',
    '6': 'cash-outline',
    '7': 'build-outline',
    '8': 'color-palette-outline',
    '9': 'color-palette-outline',
}