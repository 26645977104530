import React, { HTMLAttributes } from 'react'

import Tooltip from '../Tooltip'

import { Icon } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import * as S from './styles'

const Info: React.FC<Info.Props> = ({ content, iconName, ...props }) => (
  <S.Container {...props}>
    <Tooltip maxWidth={400} content={content}>
      <Icon name={iconName || 'circleinfo'} />
    </Tooltip>
  </S.Container>
)

export namespace Info {
  export type Props = {
    content: string
    iconName?: string
  } & HTMLAttributes<HTMLDivElement>
}

export default Info
