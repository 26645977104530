import styled from 'styled-components'
import { rem } from 'polished'
import media from 'styled-media-query'
import { themes } from '@/styles'
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 180%;
  font-size: ${rem('8px')};
  color: ${themes.text.higher};
  ${media.greaterThan('huge')`
    font-size: ${rem('12px')};
  `}
`