import styled from 'styled-components'
import { rem, size } from 'polished'
import media from 'styled-media-query'
import { Actions } from '@/components'


import { themes } from '@/styles'
import { FeatherIcons } from '@/components/Icons'


interface Props {
 backgroundNone?: boolean;
  disabled?: boolean;


}

export const Content = styled.div`
  line-height: 180%;
  font-size: ${rem('8px')};
  color: ${themes.text.higher};

  ${media.greaterThan('huge')`
    font-size: ${rem('12px')};
  `}
`

export const ContentHead = styled.div`

`

export const TitleHead = styled.h4`

  font-family: 'Porto Roobert';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin-top: 12px;

  letter-spacing: 0.02em;


  color: #2F2663;
  ${media.lessThan('medium')`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;


  letter-spacing: 0.025em;


  `}
`

export const Title = styled.div`

border-bottom: 1px solid rgba(179, 179, 179, 0.5);
padding: 0 18px ;


display: flex;
align-items: center;

justify-content: space-between;
p {
  width: 48px;
      height: 48px;
}

div {
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
}

    ${media.lessThan('medium')`
    padding: 0 8px ;
  
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    letter-spacing: 0.025em;


    color: #2F2663;

    p {
      display: none;
    }
              `}


`

export const TitleLabel = styled.h1`
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 28px;

text-align: center;
letter-spacing: 0.02em;


color: #2F2663;

padding: 18px 12px;
display: 2;

${media.lessThan('medium')`
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 28px;

letter-spacing: 0.03em;


color: #2F2663;
          `}
`

export const Link = styled.a`
  cursor: pointer;
  color: ${themes.palletes.primary};

  &:hover {
    color: ${themes.palletes.primaryHover};
  }
`

export const Coverages = {
  Section: styled.div`
    margin-bottom: 32px;
  `,

  List: styled.table`
    width: 100%;
    margin-bottom: 10px;

    text-align: left;

    > thead {
      th {
        padding: 0 8px 8px;

        font-weight: 800;
        font-size: ${rem('8px')};
        color: #000;

        ${media.greaterThan('large')`
            font-size: ${rem('10px')};
          `}

        ${media.greaterThan('huge')` 
            font-size: ${rem('14px')};
        `}
      }
    }

    > tbody {
      > tr {
        &:nth-child(odd) {
          background-color: ${themes.page.colors.content};
        }

        > td {
          padding: 8px;

          font-size: ${rem('7px')};
          color: ${themes.text.high};

          ${media.greaterThan('large')`
              font-size: ${rem('9px')};
            `}

          ${media.greaterThan('huge')`
              padding: 8px 16px;
  
              font-size: ${rem('14px')};
            `}
        }
      }
    }
  `,
}

export const AmountFormsTitle = styled.div`
    width: 304px;
    height: 52px;
    background: #EFF4FF;
    border-radius: 30px;

    display: flex;
    justify-content: center;
    align-items:center;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    letter-spacing: 0.03em;


    color: #000000;

    margin: 24px 0 24px;

${media.lessThan('medium')`
    width: 100%;
    margin: 16px 0px 0px 0px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    letter-spacing: 0.03em;

`}

`

export const TitleSelectForms = styled.div`

display: flex;
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 28px;

letter-spacing: 0.02em;


color: #2F2663;

margin: 24px 0;


`

export const Form = styled.form`

`

export const FormWrapper = styled.div`
height: 600px;

overflow-y: scroll;
padding: 0 24px;
::-webkit-scrollbar {
  /* width: 20px; */
}

@media only screen and (min-width: 1000px) and (max-width: 1675px ) {
height: 425px;
overflow-y: scroll;
  
}
${media.lessThan('medium')`
    
height: 489px;
     `}

`

export const FormCheck = styled.div`
display: flex;
padding: 0 0 12px;
margin: 0 0 9px;
border-bottom: 1px solid rgba(179, 179, 179, 0.5);

&:hover {
  .form-check-input{
    border: 1px solid #2C3DA8;
  }
  }

div {
  display: flex;
  align-items: center;

}
&:last-child {
  border-bottom: hidden;
  margin: 0;
}

 .form-check-input {
  cursor: pointer;
  appearance: none;
  width: 16px;
  height: 16px;
  border: 0.5px solid #5F5F5F;
  border-radius: 4px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  outline: none;
  background: white;

  &:hover {
   border: 1px solid #2C3DA8;
  }

}

.form-check-input {
  border-radius: 4px;
}

 .form-check-input:before {
  content: '';
  width: 16px;
  height: 16px;
  background: white;
  border-radius: 4px;
  opacity: 0;
  transition: all 350ms ease-in-out;
  position: absolute;
}

.form-check-input:before {
  border-radius: 0;
  background: transparent;
  border: 2px solid white;
  border-left: 0;
  border-top: 0;
  width: 3px;
  height: 7px;
  transform: rotate(45deg);
  top: 1px;

}
.form-check-input:checked {
  background: #2C3DA8;
  transition: all 350ms ease-in-out;
}

 .form-check-input:checked:before {
  opacity: 1;
}


`

export const Input = styled.input`
 margin-right: 16px ;
 
`

export const Label = styled.label`
    display: flex ;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    letter-spacing: 0.025em;

    color: #000000;

    width: 334px;
    
    &:hover {
  cursor: pointer;
  }

    ${media.lessThan('medium')`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    letter-spacing: 0.03em;

    color: #000000;
    display: flex;
    justify-content: space-between;
    width: 304px;



     `}

`

export const Installment = styled.div`
  display: flex;
  flex-direction: column;
  align-items: initial !important;
  width: 130px;


`

export const LabelInstallment = styled.span`

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  letter-spacing: 0.03em;


  color: #5F5F5F;

`

export const FirstValue = styled.div`
  display: flex;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.025em;

  color: #000000;

`
export const Button = styled(Actions.Button)<Props>`
  width: 143px;
  height: 48px;
  background:${(props) => props.backgroundNone ? 'none' : ''};
  margin-top:15px;
  margin-right:24px;
  color:${(props) => props.backgroundNone ? '#2C3DA8' : ''};

  padding: 0 20px 0px 12px;

  font-size: 14px;

 

  svg {
    width: 16px;
    color:${(props) => props.disabled && "silver"};
  }
  ${media.lessThan('medium')`
      width: 143px;
      margin-top:8px;
      font-size: 16px;
  `}
`

export const ButtonStyles = styled.div`
  display: flex;
  padding: 0 24px ;
  border-top: 1px solid rgba(179, 179, 179, 0.5);

  margin: 0 0 24px 0;


a {
  margin-top: 15px;
    width: 141px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    letter-spacing: 0.025em;


    color: #2C3DA8;
    ${media.lessThan('medium')`
      margin-top:8px;
  `}
}
`

export const ButtonClear = styled.button`
    padding: 0 20px;
    margin-top: 15px;
    margin-left: -8px;
    width: 141px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent ;
    border: transparent ;

    cursor: pointer;

    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    letter-spacing: 0.025em;


    color: #2C3DA8;

    ${media.lessThan('medium')`
      margin-top:8px;
  `}

  &:hover {
    background: #EFF4FF;
    border-radius: 4px;
      
  }

`

export const ButtonClose = styled.div`

    cursor: pointer;
    font-size: 16px;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
 
    &:hover {
      height: 48px;
      background: #F7F7F7;
      border-radius: 30px;
      
  }
`

export const Icon = styled.div<Props>`
  ${size('16px')}

  svg {
    fill: ${(props) => props.disabled ? 'silver' : '#f6f7fb'} ;
    ${size('16px')}

  }
  transition: 0.3s;
  pointer-events: none;
  margin-right: 8px;


`