import { useHistory } from 'react-router'
import {
  Box,
  Flex,
  Typography,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { HomeEquityStepper } from './components'
import { useState } from 'react'
import { HomeEquityFirstStep } from './Steps/First'
import { HomeEquitySecondStep } from './Steps/Second'
import { Icon } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { HomeEquityThirdtStep } from './Steps/Third'
import { HomeEquityResult } from './Result'
import { Container } from './styles'
import { HomeEquityPartnerDefinition } from './PartnerDefinition'

export interface HomeEquityNextStep {
  (stepData: any): void
}
export interface HomeEquityPreviousStep {
  (stepData: any): void
}
export interface HomeEquityRestart {
  (): void
}

export type HomeEquityStepComponent = {
  nextStep: HomeEquityNextStep
  previousStep: HomeEquityNextStep
  formData: any
}

export type HomeEquityResultComponent = {
  restart: HomeEquityRestart
  formData: any
}

export const HomeEquity = () => {
  const initialStepNumber = 0
  const [currentStep, setCurrentStep] = useState(initialStepNumber)
  const [formData, setFormData] = useState({})
  const nextStep: HomeEquityNextStep = (stepData) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      ...stepData,
    }))

    setCurrentStep((prevCurrentStep) => prevCurrentStep + 1)
  }
  const previousStep = (stepData) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      ...stepData,
    }))

    setCurrentStep((prevCurrentStep) =>
      prevCurrentStep > initialStepNumber ? prevCurrentStep - 1 : initialStepNumber
    )
  }
  const restart = () => {
    setFormData((prevFormData) => {
      return {}
    })
    setCurrentStep((prevCurrentStep) => initialStepNumber)
  }
  const history = useHistory()

  return (
    <Container>
      <Flex
        sx={{
          flexDirection: 'column',
          gap: '2rem',
          alignItems: 'flex-start',
        }}
      >
        <Flex
          sx={{
            gap: '1rem',
            alignItems: 'center',
          }}
        >
          <button
            style={{
              all: 'unset',
              cursor: 'pointer',
            }}
            onClick={() => history.push('/')}
          >
            <Icon
              name="close"
              size="xs"
              color="porto-primitive-black-70"
              role="button"
            />
          </button>
          <Flex
            sx={{
              flexDirection: 'column',
            }}
          >
            <Typography
              content="Crédito com Garantia Imobiliária"
              variant="porto-title-4-bold"
            />
          </Flex>
        </Flex>

        {currentStep < 4 && currentStep > initialStepNumber && (
          <Box
            sx={{
              backgroundColor: 'var(--porto-primitive-white)',
              border: '1px solid var(--porto-primitive-black-15)',
              width: '100%',
              maxWidth: '880px',
              padding: '.5rem',
              borderRadius: '.75rem',
            }}
          >
            <HomeEquityStepper
              currentStep={currentStep}
              steps={[
                { label: 'Dados do imóvel' },
                { label: 'Empréstimo' },
                { label: 'Dados pessoais' },
              ]}
            />
          </Box>
        )}

        {currentStep === initialStepNumber && (
          <HomeEquityPartnerDefinition
            nextStep={nextStep}
            previousStep={previousStep}
            formData={formData}
          />
        )}

        {currentStep === 1 && (
          <HomeEquityFirstStep
            nextStep={nextStep}
            previousStep={previousStep}
            formData={formData}
          />
        )}

        {currentStep === 2 && (
          <HomeEquitySecondStep
            nextStep={nextStep}
            previousStep={previousStep}
            formData={formData}
          />
        )}

        {currentStep === 3 && (
          <HomeEquityThirdtStep
            nextStep={nextStep}
            previousStep={previousStep}
            formData={formData}
          />
        )}

        {currentStep === 4 && (
          <HomeEquityResult
            restart={restart}
            formData={formData}
          />
        )}
      </Flex>
    </Container>
  )
}
