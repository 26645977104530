import { EAnalyticsProducts, IINITIAL_STATE } from './interfaces'

export const INITIAL_STATE: IINITIAL_STATE = {
  customData: {
    page: {
      name: '',
      product: EAnalyticsProducts['portal-imobiliarias']
    },
    site: {
      brand: '',
      portal: ''
    },
    user: {
      logado: false,
      protocolo: '',
      susep: '',
      user_id: ''
    }
  }
}

export const Types = {
  SET_ANALYTICS_PAGE: 'SET_ANALYTICS_PAGE',
  SET_ANALYTICS_SITE: 'SET_ANALYTICS_SITE',
  SET_ANALYTICS_USER: 'SET_ANALYTICS_USER'
}

export const setAnalyticsPage = ({
  name,
  product
}) => {
  return {
    payload: {
      name,
      product
    },
    type: Types.SET_ANALYTICS_PAGE
  }
}

export const setAnalyticsSite = ({
  brand,
  portal
}) => ({
  payload: {
    brand,
    portal
  },
  type: Types.SET_ANALYTICS_SITE
})

export const setAnalyticsUser = ({
  logado,
  protocolo,
  susep,
  user_id
}) => ({
  payload: {
    logado,
    protocolo,
    susep,
    user_id
  },
  type: Types.SET_ANALYTICS_USER
})

export const analytics = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case Types.SET_ANALYTICS_PAGE:
      return {
        ...state,
        customData: {
          ...state.customData,
          page: {
            ...payload
          }
        }
      }

    case Types.SET_ANALYTICS_SITE:
      return {
        ...state,
        customData: {
          ...state.customData,
          site: {
            ...payload
          }
        }
      }

    case Types.SET_ANALYTICS_USER:
      return {
        ...state,
        customData: {
          ...state.customData,
          user: {
            ...payload
          }
        }
      }

    default:
      return state
  }
}
