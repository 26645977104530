import Button from '@/components/Actions/Button'
import {
  Box,
  Flex,
  Typography,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { HomeEquityStepComponent } from '../..'
import { FormikProvider, useFormik } from 'formik'
import { BaseCard } from '../../components'
import { Forms } from '@/components'
import { useEffect, useState } from 'react'
import { BRLMoneyToNumber, convertPercentToDecimal, formatMoneyBRL } from '@/tools'
import validationSchema from './validations'
import { PropertyTypeLoanPercent } from './property-type-loan-percent.enum'

const calculateLoanValueFromPropertyValue = (
  propertyValue: number,
  propertyType: string
): number => {
  const propertyTypeLoadPercent = convertPercentToDecimal( getPropertyTypeLoadPercent(propertyType) )

  return propertyValue * propertyTypeLoadPercent
}

const getPropertyTypeLoadPercent = (propertyType: string): number => {
  return PropertyTypeLoanPercent[propertyType]
}

export const HomeEquitySecondStep = ({
  formData = {},
  nextStep = () => null,
  previousStep = () => null,
}: HomeEquityStepComponent) => {
  const [maxLoanValue, setMaxLoanValue] = useState(0)

  const form = useFormik({
    initialValues: {
      loan_value: formData.loan_value || '',
      usage: formData.usage || '',
      grace_period_days: formData.grace_period_days || '',
      deadline_months: formData.deadline_months || '',
      max_loan_value:
        calculateLoanValueFromPropertyValue(
          BRLMoneyToNumber(formData.property_value),
          formData.property_type
        ) || '',
      property_loan_percent: getPropertyTypeLoadPercent(formData.property_type) || 0,
      property_type_selected: formData.property_type || '',
    },
    validationSchema,
    onSubmit: (values) => {
      nextStep(values)
    },
  })

  const handleGoBack = () => {
    previousStep(form.values)
  }

  useEffect(() => {
    setMaxLoanValue((prevMaxLoanValue) => {
      const propertyValueNumber = BRLMoneyToNumber(
        formData.property_value ?? prevMaxLoanValue
      )
      return calculateLoanValueFromPropertyValue(
        propertyValueNumber,
        formData.property_type
      )
    })
  }, [formData])

  useEffect(() => {
    form.setValues({
      ...form.initialValues,
    })
  }, [maxLoanValue])

  return (
    <FormikProvider value={form}>
      <Flex
        sx={{
          flexDirection: 'column',
          gap: '1rem',
          width: '100%',
        }}
      >
        <BaseCard title="Empréstimo">
          <Box
            sx={{
              display: 'block',
              gridTemplateColumns: { xs: '1fr', lg: '1fr' },
              gap: '1rem',
              marginTop: '32px',
            }}
          >
            <Typography
              content="Qual o valor desejado do empréstimo?"
              variant="porto-text-body-1-regular"
              sx={{
                color: 'var(--neutras-black-75, #404040)',
              }}
            />
          </Box>

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: { xs: '1fr', lg: '60%' },
              gridTemplateRows: { xs: '1fr 1fr', lg: '1fr' },
              gap: '1rem',
              rowGap: '4px',
              marginTop: '16px',
            }}
          >
            <Forms.InputGroup
              name="loan_value"
              label=""
              mask="money"
              placeholder="Digite o valor"
            />
            <Typography
              content={`Empréstimo disponível até ${formatMoneyBRL(
                maxLoanValue
              )} (${getPropertyTypeLoadPercent(
                formData.property_type
              )}% do valor do imóvel).`}
              variant="porto-text-caption-regular"
              sx={{
                color: 'var(--neutras-black-70, #5F5F5F)',
                marginLeft: '5px',
              }}
            />
          </Box>

          <Box
            sx={{
              display: 'block',
              gridTemplateColumns: { xs: '1fr', lg: '1fr' },
              gap: '1rem',
              marginTop: '32px',
            }}
          >
            <Typography
              content="Qual o motivo do empréstimo?"
              variant="porto-text-body-1-regular"
              sx={{
                color: 'var(--neutras-black-75, #404040)',
              }}
            />
          </Box>

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: { xs: '1fr', lg: '60%' },
              marginTop: '16px',
            }}
          >
            <Forms.SelectGroup
              name="usage"
              options={[
                { label: 'Escolha uma opção', value: '' },
                { label: 'Quitar Dívidas', value: 'Quitar Dívidas' },
                { label: 'Portabilidade', value: 'Portabilidade' },
                { label: 'Viajar/Lazer', value: 'Viajar/Lazer' },
                {
                  label: 'Construção e Reforma',
                  value: 'Construção e Reforma',
                },
                { label: 'Investimento', value: 'Investimento' },
                { label: 'Aquisição de Bens', value: 'Aquisição de Bens' },
                {
                  label: 'Abrir minha Empresa/Franquia',
                  value: 'Abrir minha Empresa/Franquia',
                },
                {
                  label: 'Capital de Giro para minha empresa',
                  value: 'Capital de Giro para minha empresa',
                },
                { label: 'Outros', value: 'Outros' },
              ]}
            />
          </Box>

          <Box
            sx={{
              display: 'block',
              gridTemplateColumns: { xs: '1fr', lg: '1fr' },
              gap: '1rem',
              marginTop: '32px',
            }}
          >
            <Typography
              content="Escolha o período de carência e o prazo de pagamento."
              variant="porto-text-body-1-regular"
              sx={{
                color: 'var(--neutras-black-75, #404040)',
              }}
            />
          </Box>

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: { xs: '1fr', lg: '60%' },
              marginTop: '16px',
            }}
          >
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: { xs: '1fr', lg: '150px 1fr' },
                gap: '1rem',
              }}
            >
              <Forms.SelectGroup
                name="grace_period_days"
                options={[
                  { label: 'Carência', value: '' },
                  { label: '30 dias', value: '30' },
                  { label: '60 dias', value: '60' },
                  { label: '90 dias', value: '90' },
                  { label: '120 dias', value: '120' },
                  { label: '150 dias', value: '150' },
                  { label: '180 dias', value: '180' },
                ]}
              />

              <Forms.InputGroup
                name="deadline_months"
                label=""
                mask="integer"
                placeholder="Prazo de pagamento (em meses)"
                maxLength={3}
              />
            </Box>
          </Box>
        </BaseCard>
      </Flex>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', lg: 'repeat(2, 1fr)' },
          gap: '1rem',
        }}
      >
        <Button kind="ghost" onClick={handleGoBack}>
          Voltar
        </Button>

        <Button
          onClick={() => form.handleSubmit()}
          isDisabled={Object.keys(form.errors).length > 0}
        >
          Continuar
        </Button>
      </Box>
    </FormikProvider>
  )
}
