import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { HTMLAttributes, useState } from 'react'
import * as S from './styles'

type Props = {
  label: string
  children: React.ReactNode
} & Pick<HTMLAttributes<HTMLElement>, 'onMouseEnter' | 'onMouseLeave'>

export const Accordion: React.FC<Props> = ({ children, label, ...props }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <S.Container {...props}>
      <S.Button type="button" onClick={() => setIsOpen(!isOpen)}>
        <Typography variant="porto-text-body-2-regular">{label}</Typography>{' '}
        <S.Icon rotateIcon={isOpen} name="chevron-down" />
      </S.Button>
      <S.Content isOpen={isOpen}>{children}</S.Content>
    </S.Container>
  )
}
