import { formatResume } from '@/data/capitalizacao'
import { useContract } from '@/modules'
import {
  Box,
  Flex,
  Typography,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Card } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'

export const Resume = () => {
  const contract = useContract()
  const data = formatResume({ contract })

  return (
    <Card
      sx={{
        display: { xs: 'none', xl: 'block' },
        width: 'fit-content',
        height: 'fit-content',
        backgroundColor: 'var(--system-surface-primary)',
        borderRadius: '8px',
        padding: 0,
      }}
    >
      {data.map(({ title, values }) => (
        <Box
          key={`resume_cap_${JSON.stringify(values)}`}
          sx={{
            padding: '1rem',
            ':not(:last-of-type)': {
              borderBottom: '1px solid var(--porto-primitive-black-15)',
            },
          }}
        >
          <Typography
            variant="porto-text-caption-bold"
            color="porto-primitive-black-70"
            content={title}
          />
          <Flex
            sx={{
              flexDirection: 'column',
              gap: '.25rem',
              marginTop: '.75rem',
            }}
          >
            {values.map(value => (
              <Typography
                key={JSON.stringify(value)}
                variant="porto-text-body-2-regular"
                color="porto-primitive-black-85"
                content={`<strong>${value.title}</strong> ${value.description}`}
                data-test-id={`resume-${title}-${value.title.toLowerCase()}`}
              />
            ))}
          </Flex>
        </Box>
      ))}
    </Card>
  )
}
