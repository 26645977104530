import React, { useState, useEffect } from 'react'
import { useField } from 'formik'
import { AnimatePresence } from 'framer-motion'

import { Ionicons } from '../../Icons'

import * as S from './styles'
import { MultiselectProps, Options, Option } from './types'

const variants = {
  item: {
    hidden: {
      x: -10,
      opacity: 0,
    },
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        ease: [0.16, 1, 0.3, 1],
      },
    },
  },
}

export default function Multiselect({
  name,
  options,
  defaultValue = [],
  placeholder = 'Selecione',
  emptyMessage = 'Nenhum item encontrado',
}: MultiselectProps) {
  const [field, _, helpers] = useField(name)

  const [items, setItems] = useState<Options>(options)

  const [selected, setSelected] = useState<Options>([])

  const addItem = (item: Option) => {
    setSelected((state) => [...state, item])
    setItems((state) => state.filter(({ value }) => value !== item.value))
  }

  const addItems = (values: Options) => {
    setSelected((state) => [...state, ...values])

    setItems((state) =>
      state.filter(({ value }) => !defaultValue.includes(value))
    )
  }

  const removeItem = (item: Option) => {
    setItems((state) => [...state, item])
    setSelected((state) => state.filter(({ value }) => value !== item.value))
  }

  const handleSelected = (event) => {
    const value = event.target.value
    const item = items.find((item) => item.value === value)

    addItem(item)
  }

  const handleRemove = (item: Option) => {
    removeItem(item)
  }

  const handleDefaultValue = () => {
    addItems(items.filter((item) => defaultValue.includes(item.value)))
  }

  useEffect(() => {
    if (defaultValue && items.length > 0) handleDefaultValue()
  }, [])

  useEffect(() => {
    if (selected) {
      helpers.setValue(selected.map((item) => item.value))
    }
  }, [selected])

  return (
    <S.Container>
      {selected.length > 0 && (
        <S.List>
          <AnimatePresence>
            {selected.map((item) => {
              return (
                <S.Item
                  key={item.value}
                  variants={variants.item}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                >
                  {item.label}
                  <S.Remove onClick={() => handleRemove(item)}>
                    <Ionicons name="close" />
                  </S.Remove>
                </S.Item>
              )
            })}
          </AnimatePresence>
        </S.List>
      )}

      <S.Search>
        <S.Options onChange={handleSelected} name={field.name}>
          <S.Option>{placeholder}</S.Option>

          {items.length < 1 && <S.Option disabled>{emptyMessage}</S.Option>}

          {items.map(({ value, label }) => (
            <S.Option key={value} value={value}>
              {label}
            </S.Option>
          ))}
        </S.Options>
      </S.Search>
    </S.Container>
  )
}
