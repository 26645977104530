import residential from './residential'
import commercial from './comercial'
import suseps from './suseps'
import payments from './payments'
import batch from './batch'

export default {
  ...residential,
  ...commercial,
  ...suseps,
  ...payments,
  ...batch,
}
