import { rem, rgba, size } from 'polished'
import styled, { css } from 'styled-components'
import media from 'styled-media-query'

import { themes } from '@/styles'

import { FeatherIcons, Ionicons } from '@/components/Icons'
import { IBadgeProps } from './interfaces'

export const Container = styled.div<IBadgeProps>`
  padding: 6px 10px;

  display: inline-flex;
  align-items: center;

  font-weight: 500;
  border-radius: 8px;
  font-size: ${rem('7px')};

  ${media.greaterThan('large')`
    font-size: ${rem('8px')};
  `}

  ${media.greaterThan('huge')`
    padding: 4px 8px;

    border-radius: 8px;
    font-size: ${rem('12px')};
  `}

  ${(props) =>
    !props.removeDot &&
    css`
      &:before {
        ${size('4px')}

        margin-right: 4px;

        content: '';

        border-radius: 100%;

        ${media.greaterThan('huge')`
          ${size('6px')}

          margin-right: 6px;
        `}
      }
    `}

  ${(props) =>
    props.kind &&
    {
      info: css`
        color: ${themes.palletes.info};
        background-color: ${rgba(themes.palletes.info, 0.1)};

        &:before {
          background-color: ${themes.palletes.info};
        }
      `,

      success: css`
        color: var(--porto-primitive-green-30);
        background-color: var(--porto-primitive-green-80);

        &:before {
          background-color: var(--porto-primitive-green-30);
        }
      `,

      warning: css`
        color: var(--porto-primitive-yellow-100);
        background-color: var(--porto-primitive-yellow-30);

        &:before {
          background-color: var(--porto-primitive-yellow-100);
        }
      `,

      danger: css`
        color: var(--porto-primitive-red-100);
        background-color: var(--porto-primitive-red-30);

        &:before {
          background-color: var(--porto-primitive-red-100);
        }
      `,

      'success-light': css`
        color: var(--porto-primitive-green-80);
        background-color: var(--porto-primitive-green-30);

        &:before {
          background-color: var(--porto-primitive-green-primary);
        }
      `,
    }[props.kind]}
`

export const Icon = styled(FeatherIcons || Ionicons)`
  margin-right: 4px;
  width: 16px;
`
