import { useHistory } from 'react-router-dom'

import { Box, Flex, Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Button, Icon } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'

const Form = () => {

  const history = useHistory()

  return (
    <Flex
      sx={{
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <Icon
        name="circlecheck"
        size="md"
        color="porto-primitive-green-primary"
      />
      <Typography
        variant="porto-title-3-semibold"
        content="Senha alterada com sucesso"
      />
      <Box sx={{ marginTop: "1rem" }} >
        <Button
          type="submit"
          size="large"
          onClick={() => history.push('/')}
        >
          Entrar na minha conta
        </Button>
      </Box>
    </Flex>
  )
}

export default Form
