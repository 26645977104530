import { Grid, Info } from '@/components'
import { api } from '@/services'
import {
  PaymentStatus,
  ProcessStatus,
  RentCollectionListItem,
} from '@/services/api/rentCollection'
import { useDebounce } from '@/tools'
import { capitalize } from '@brazilian-utils/brazilian-utils'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import {
  Loader,
  Notification,
  Icon as PortoIcon,
  Tag,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import {
  DataTable,
  Dropdown,
  Icon as SystemIcon,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/system'
import {
  format,
  formatRelative,
  isAfter,
  isThisMonth,
  isToday,
  isValid,
  subMonths,
} from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { PageContainer } from '../../components'
import { SetPaidButton } from './SetPaidButton'
import {
  BackButton,
  Content,
  DropdownItem,
  DropdownItemWrapper,
  DropdownWrapper,
  Filter,
  Header,
  Input,
  LoaderWrapper,
  NewButton,
  ReloadButton,
  TableWrapper,
  UpdatedAt,
} from './styles'

function renderTag(processStatus: ProcessStatus, paymentStatus: PaymentStatus) {
  if (paymentStatus === PaymentStatus.Paid) {
    return (
      <Tag color="green" size="small">
        Pago
      </Tag>
    )
  }

  switch (processStatus) {
    case ProcessStatus.Error:
      return (
        <Tag color="red" size="small">
          Erro
        </Tag>
      )
    case ProcessStatus.PendingSync:
      return (
        <Tag color="yellow" size="small">
          Em processamento
        </Tag>
      )
    case ProcessStatus.Synced:
      return (
        <Tag color="yellow" size="small">
          Pendente
        </Tag>
      )
    case ProcessStatus.PendingZipcode:
      return (
        <Tag color="red" size="small">
          CEP pendente
        </Tag>
      )
  }
}

function listItemToDataTableItem(
  item: RentCollectionListItem,
  isLoading: boolean,
  onClick: () => void
) {
  const createdAt = new Date(item.created_at)
  const dueDate = new Date(item.dueDate)
  const INVALID_DATE = 'Data inválida'

  const name = item.tenant
  const socialName = item.tenantSocialName
  const hasSocialName = !!socialName

  return {
    tenant: (
      <Grid>
        <Grid columns="repeat(2, max-content)" gap="8px">
          <Typography variant="porto-text-body-2-regular">
            {capitalize(socialName || name || '-')}
          </Typography>
          {hasSocialName && (
            <Info content="Este é o nome social do cliente e deve ser utilizado em todas as comunicações. O nome de registro, abaixo, é apenas para consulta." />
          )}
        </Grid>
        {hasSocialName && (
          <Typography
            variant="porto-text-label-regular"
            color="system-text-tertiary"
          >
            {capitalize(name)}
          </Typography>
        )}
      </Grid>
    ),
    product: item.product,
    created_at: isValid(createdAt)
      ? format(createdAt, 'dd/MM/yyyy', {
          locale: ptBR,
        })
      : INVALID_DATE,
    dueDate: isValid(new Date(dueDate))
      ? format(dueDate, 'dd/MM/yyyy', { locale: ptBR })
      : INVALID_DATE,
    process_status: renderTag(item.process_status, item.payment_status),
    edit: item.payment_status === PaymentStatus.Paid || (
      <SetPaidButton isLoading={isLoading} onClick={onClick} />
    ),
  }
}

enum CreatedDateFilterOptions {
  Today = 'Hoje',
  ThisMonth = 'Este mês',
  Last3Months = 'Últimos 3 meses',
  Last6Months = 'Últimos 6 meses',
}

enum StatusFilterOption {
  All = 'Todos',
  Paid = 'Pago',
  Pending = 'Pendente',
}

const fetchRentCollection = () => api.rentCollection.list()

const setAsPaid = (rentCollectionItem: RentCollectionListItem) =>
  api.rentCollection.setAsPaid(rentCollectionItem.id)

const TABLE_COLUNS = [
  { label: 'Proponente', prop: 'tenant' },
  { label: 'Produto', prop: 'product' },
  { label: 'Criado em', prop: 'created_at' },
  { label: 'Vencimento do aluguel', prop: 'dueDate' },
  { label: 'Status', prop: 'process_status' },
  { label: '', prop: 'edit' },
]

const ListRentCollections = () => {
  const { push } = useHistory()
  const { path } = useRouteMatch()
  const queryClient = useQueryClient()
  const { state } = useLocation<{ includeSuccessMessage: string }>()
  const [isVisible, setIsVisible] = useState(true)
  const [filters, setFilters] = useState({
    statusFilter: StatusFilterOption.All,
    createdDateFilter: CreatedDateFilterOptions.ThisMonth,
    textFilter: '',
  })
  const debouncedFilterText = useDebounce(filters.textFilter, 1000)

  const {
    refetch,
    isFetching: isLoading,
    data: list = [],
    dataUpdatedAt,
  } = useQuery(['fetchRentCollection'], () => fetchRentCollection(), {
    refetchOnWindowFocus: false,
  })

  const {
    isLoading: isMutationLoading,
    mutate,
    variables: mutationVariables,
  } = useMutation(setAsPaid, {
    onSuccess: (_, rentCollectionItem) => {
      const index = list.indexOf(rentCollectionItem)
      const updatedList = [...list]

      updatedList.splice(index, 1, {
        ...rentCollectionItem,
        payment_status: PaymentStatus.Paid,
      })

      queryClient.setQueryData(['fetchRentCollection'], updatedList)
    },
  })

  const isAllPaid = list?.every(
    (item) => item.payment_status === PaymentStatus.Paid
  )

  const sortedList = list?.slice()?.sort((itemA, itemB) => {
    if (itemA.created_at > itemB.created_at) {
      return -1
    } else if (itemA.created_at < itemB.created_at) {
      return 1
    } else {
      return 0
    }
  })

  return (
    <PageContainer>
      <Header>
        <BackButton onClick={() => push('/sinistro')}>
          <PortoIcon size={20} name="arrowleft" />
        </BackButton>
        <Typography
          variant="porto-title-4-bold"
          color="system-primitive-neutral-900"
          content="Assessoria de cobrança"
        />
        <UpdatedAt>
          <Typography
            sx={{ display: 'block' }}
            color="system-primitive-neutral-700"
            variant="porto-text-caption-regular"
            content="Atualizado"
          />
          <Typography
            sx={{ display: 'block' }}
            color="system-primitive-neutral-700"
            variant="porto-text-caption-regular"
            content={`${formatRelative(dataUpdatedAt, new Date(), {
              locale: ptBR,
            })}`}
          />
        </UpdatedAt>
        <ReloadButton onClick={() => refetch()}>
          <SystemIcon
            color="system-primitive-blue-500"
            size={20}
            name="refresh"
          />
        </ReloadButton>
        <NewButton
          icon={isAllPaid ? 'plus' : undefined}
          kind={!isAllPaid ? 'ghost' : undefined}
          onClick={() => push(`${path}/nova`, { isUpdating: !isAllPaid })}
        >
          {isAllPaid ? 'Nova cobrança' : 'Atualizar cobranças'}
        </NewButton>
      </Header>
      <Filter>
        <DropdownWrapper>
          <Dropdown
            label={`Criado: ${filters.createdDateFilter}`}
            renderChildren={({ closeDropdown }) =>
              Object.values(CreatedDateFilterOptions).map((option) => (
                <DropdownItemWrapper
                  onClick={() => {
                    closeDropdown()
                    setFilters((prevFilter) => ({
                      ...prevFilter,
                      createdDateFilter: option,
                    }))
                  }}
                >
                  <DropdownItem>{option}</DropdownItem>
                </DropdownItemWrapper>
              ))
            }
          />
        </DropdownWrapper>
        <DropdownWrapper>
          <Dropdown
            label={`Status: ${filters.statusFilter}`}
            renderChildren={({ closeDropdown }) =>
              Object.values(StatusFilterOption).map((status) => (
                <DropdownItemWrapper
                  onClick={() => {
                    closeDropdown()
                    setFilters((prevFilter) => ({
                      ...prevFilter,
                      statusFilter: status,
                    }))
                  }}
                >
                  <DropdownItem>{status}</DropdownItem>
                </DropdownItemWrapper>
              ))
            }
          />
        </DropdownWrapper>
        <Input
          placeholder="CPF, CNPJ, nome, proposta..."
          error={false}
          value={filters.textFilter}
          onChange={(e) =>
            setFilters((prev) => ({ ...prev, textFilter: e.target.value }))
          }
        />
      </Filter>
      {state?.includeSuccessMessage && isVisible && (
        <Notification
          variant="success"
          icon="circlecheck"
          description={state?.includeSuccessMessage}
          onClose={() => setIsVisible(false)}
          showCloseButton
        />
      )}
      <Content>
        {isLoading ? (
          <LoaderWrapper>
            <Loader size="2rem" />
          </LoaderWrapper>
        ) : (
          <>
            {isAllPaid && (
              <Typography
                color="system-primitive-neutral-400"
                variant="porto-title-4-medium"
                content="Não há cobranças em andamento"
                sx={{ padding: '80px 0' }}
              />
            )}
            {list?.length > 0 && (
              <TableWrapper>
                <DataTable
                  actions={[]}
                  columns={TABLE_COLUNS}
                  data={sortedList
                    .filter((item) =>
                      [item.document, item.tenant.toLocaleLowerCase()].some(
                        (value) =>
                          value.includes(
                            debouncedFilterText.toLocaleLowerCase()
                          )
                      )
                    )
                    .filter((item) => {
                      switch (filters.createdDateFilter) {
                        case CreatedDateFilterOptions.Today:
                          return isToday(item.created_at)
                        case CreatedDateFilterOptions.ThisMonth:
                          return isThisMonth(item.created_at)
                        case CreatedDateFilterOptions.Last3Months:
                          return isAfter(
                            item.created_at,
                            subMonths(new Date(), 3)
                          )
                        case CreatedDateFilterOptions.Last6Months:
                          return isAfter(
                            item.created_at,
                            subMonths(new Date(), 6)
                          )
                        default:
                          return false
                      }
                    })
                    .filter((item) => {
                      switch (filters.statusFilter) {
                        case StatusFilterOption.All:
                          return true
                        case StatusFilterOption.Paid:
                          return item.payment_status === PaymentStatus.Paid
                        case StatusFilterOption.Pending:
                          return item.payment_status === PaymentStatus.Pending
                        default:
                          return false
                      }
                    })
                    .map((item) => {
                      const isSetPaidLoading =
                        isMutationLoading && mutationVariables?.id === item.id

                      return listItemToDataTableItem(
                        item,
                        isSetPaidLoading,
                        () => mutate(item)
                      )
                    })}
                />
              </TableWrapper>
            )}
          </>
        )}
      </Content>
    </PageContainer>
  )
}

export default ListRentCollections
