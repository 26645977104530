import breakpoints from '@pol-npm/riscos-financeiros-dashboard-ui/dist/theme/breakpoints'
import styled from 'styled-components'

export const SixthSectionContainer = styled.div`
  padding: 32px 108px 96px;

  @media (max-width: calc(${breakpoints.sm} - 1px)) {
    padding: 64px 12px;
  }
`
