import { Conditional } from '@/components'
import { Container, Header, Counter, Wrapper, Content, Text } from './styles'

interface CardProps {
  icon?: string
  title?: string
  children: Array<String>
  background?: string
  colorLink?: string
  border?: string
}

const Card = ({ title, children, background, border }: CardProps) => {
  return (
    <Container style={{ background: `${background}` }} border={border}>
      <Content>
        <div>
          <Conditional when={!!title}>
            <Header>{title}</Header>
          </Conditional>
          {children.map((item, key) => {
            return (
              <Wrapper key={key}>
                <Counter>{key + 1}</Counter>
                <Text>{item}</Text>
              </Wrapper>
            )
          })}
        </div>
      </Content>
    </Container>
  )
}

export default Card
