import { useState, useEffect, ElementType } from 'react'

export function useTabs(
  items: {
    key: string
    title: string
    render: ElementType
  }[],
  options?: {
    activeAnchor?: boolean
  }
) {
  const [current, setCurrent] = useState(items[0])

  const handleCurrent = (tab) => {
    setCurrent(tab)

    if (options?.activeAnchor) window.location.hash = tab.key
  }

  useEffect(() => {
    if (options?.activeAnchor) {
      const hash = window.location.hash.replace('#', '')
      const item = items.find((item) => item.key === hash)

      if (item) handleCurrent(item)
    }
  }, [])

  return {
    items,
    current,
    setCurrent: handleCurrent,
    Tab: current.render,
  }
}
