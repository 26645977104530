export const isImobiliariaEnabled = (profile) => {
  if (profile.type === 'BROKER') return true
  return profile.partnerships.some(
    (partnership) =>
      partnership.settings['porto_imobiliaria.residential.enabled'] ||
      partnership.settings['porto_imobiliaria.commercial.enabled']
  )
}

export const isImobiliariaBatchEnabled = (profile) => {
  if (profile.type === 'BROKER') return true
  return profile.partnerships.some(
    (partnership) => partnership.settings['porto_imobiliaria.batch.enabled']
  )
}

export const isImobiliariaResidentialEnabled = ({ profile, susep }) => {
  if (profile.type === 'BROKER') return true

  if (!susep) {
    return profile.partnerships.some(
      (partnership) =>
        partnership.settings['porto_imobiliaria.residential.enabled']
    )
  }

  const partnership = profile.partnerships.find((p) => p.suseps.includes(susep))
  return partnership.settings['porto_imobiliaria.residential.enabled']
}

export const isImobiliariaCommercialEnabled = ({ profile, susep }) => {
  if (profile.type === 'BROKER') return true

  if (!susep) {
    return profile.partnerships.some(
      (partnership) =>
        partnership.settings['porto_imobiliaria.commercial.enabled']
    )
  }

  const partnership = profile.partnerships.find((p) => p.suseps.includes(susep))
  return partnership.settings['porto_imobiliaria.commercial.enabled']
}
