import styled, { css } from 'styled-components'
import { darken, rem, size } from 'polished'
import { Link } from 'react-router-dom'
import media from 'styled-media-query'

import { helpers, themes } from '@/styles'

export const Container = styled.div`
  position: sticky;
  top: 64px;
  display: none;

  width: 50px;
  height: 100vh;
  padding: 28px 0;

  flex-direction: column;

  text-align: center;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    width: 150%;
    height: 100vh;

    content: '';
    background-color: ${themes.palletes.primary};
  }

  ${media.greaterThan('large')`
    top: 0;
    display: flex;
  `}
`

export const Organizations = styled.div`
  position: relative;

  > *:not(:last-child) {
    margin-bottom: 12px;
  }
`

export const Divider = styled.hr`
  width: 20px;
  border-color: #fff;
  margin: 12px auto;
  opacity: 0.3;
  border-width: 1.5px;
`

export const OrganizationContent = styled.button`
  all: unset;

  overflow: hidden;

  cursor: pointer;
  border-radius: 8px;

  ${size('38px')}
`

export const Initials = styled.div`
  color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: bold;

  font-size: ${rem('11px')};
  font-family: ${themes.fonts.title};
  background-color: ${darken(0.2, themes.palletes.primary)};

  ${size('100%')}
`

export const Logo = styled.img`
  ${size('100%')}

  object-fit: contain;
`

export const Tip = styled.div`
  max-width: 250px;

  small {
    display: block;

    font-size: ${rem('10px')};
    color: ${themes.text.low};
  }

  span {
    display: block;

    font-weight: bold;
    font-size: ${rem('12px')};
  }
`

export const Organization = styled.div<{ active?: boolean }>`
  position: relative;

  width: 100%;

  ${({ active }) =>
    active
      ? css`
          &:before {
            position: absolute;
            top: 2px;
            left: 0;

            width: 3px;
            height: 90%;

            content: '';
            background-color: #fff;
            border-radius: 0 30px 30px 0;
          }
        `
      : css`
          ${Initials} {
            &:hover {
              transition: 0.3s;
              background-color: ${darken(0.25, themes.palletes.primary)};
            }
          }

          ${Logo} {
            &:hover {
              opacity: 0.7;
            }
          }
        `}
`

export const Footer = styled.div`
  margin-top: auto;

  text-align: center;

  > *:not(:last-child) {
    margin-bottom: 12px;
  }
`

export const User = {
  Box: styled(Link)`
    all: unset;

    margin: 0 auto;

    display: block;

    cursor: pointer;
    overflow: hidden;
    transition: 0.3s;
    border-radius: 8px;

    ${size('34px')}

    &:hover {
      opacity: 0.8;
    }
  `,

  Avatar: styled.img`
    ${helpers.fitImageToBox}
  `,
}

export const Back = styled.button`
  all: unset;

  color: #fff;
  font-size: 20px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 0.6;
  }
`
