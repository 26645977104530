import {
  BoxCards,
  Faq,
  AlsoOffer,
  Comparison,
  Header,
  Anchors,
  InfoAndActionSection,
} from '@/modules/static-page/components'
import {
  isImobiliariaBatchEnabled,
  isImobiliariaEnabled,
} from '@/tools/partnership'

export const seguroIncendioContent = [
  {
    Component: Header,
    props: {
      title: 'Seguro Incêndio',
      subtitle:
        'O seguro obrigatório que oferece tranquilidade para inquilinos e proprietários.',
      description:
        'Além das garantias do seguro, como coberturas para incêndio e perda de aluguel, o Seguro Incêndio da Porto Seguro conta com uma série de facilidades para a manutenção do imóvel alugado para fins residenciais ou não.',
      shortcuts: [
        {
          label: 'Nova Proposta',
          color: 'seguros',
          icon: 'add',
          url: '/contratos/novo/imobiliaria',
          hide: (p) => !isImobiliariaEnabled(p),
        },
        {
          label: 'Renovação',
          color: 'default',
          icon: 'reload',
          url: '/renovacoes/novo/imobiliaria',
          hide: (p) => !isImobiliariaEnabled(p),
        },
        {
          label: 'Orçamento em lote',
          color: 'default',
          icon: 'cube',
          url: '/orcamento',
          hide: (p) => !isImobiliariaBatchEnabled(p),
        },
      ],
      side: [
        {
          Component: Anchors,
          props: {
            title: 'Navegue pelos tópicos',
            values: [
              {
                label: 'Benefícios',
                anchor: '#benefits',
              },
              {
                label: 'Compare os planos',
                anchor: '#compare',
              },
              {
                label: 'Coberturas que protegem seus clientes e o imóvel',
                anchor: '#coverages',
              },
              {
                label: 'Tire suas dúvidas',
                anchor: '#faq',
              },
            ],
          },
        },
      ],
    },
  },
  {
    Component: BoxCards,
    props: {
      variant: 'secondary',
      title: 'Benefícios',
      anchor_id: 'benefits',
      values: [
        {
          cards: [
            {
              icon: 'residence',
              title: 'Garantia para imóveis residenciais ou comerciais',
            },
            {
              icon: 'reload24h',
              title: 'Serviços emergenciais 24h',
            },
            {
              icon: 'receiptcheck',
              title:
                'Flexibilidade de contratação de coberturas de acordo com a necessidade',
            },
          ],
        },
      ],
    },
  },
  {
    Component: Comparison,
    props: {
      variant: 'primary',
      title: 'Compare os planos',
      anchor_id: 'compare',
      values: [
        {
          image: {
            src: '/images/produtos/incendio-plano-01.jpg',
            alt: 'Imagem de um chaveiro',
          },
          title: 'Plano padrão gratuito',
          benefits: ['Chaveiro', 'Reparos hidráulicos'],
        },
        {
          image: {
            src: '/images/produtos/incendio-plano-02.jpg',
            alt: 'Imagem de uma chave de fenda',
          },
          title: 'Plano intermediário',
          benefits: [
            'Chaveiro',
            'Reparos hidráulicos',
            'Reparos elétricos',
            'Help desk para smartphones, notebooks e tablets',
          ],
        },
        {
          image: {
            src: '/images/produtos/incendio-plano-03.jpg',
            alt: 'Imagem de uma caixa de ferramentas',
          },
          title: 'Plano total',
          benefits: [
            'Chaveiro comum',
            'Troca de segredo de fechadura',
            'Instalação de fechadura e trava tetra',
            'Reparos elétricos',
            'Reparos hidráulicos',
            'Desentupimento',
            'Reparos em linha branca',
            'Reparos em ar-condicionado',
            'Substituição de telhas e cumeeiras',
            'Assistência à bike',
            'Assistência em antenas',
            'Help desk - Atendimento telefônico',
            'Instalação e reinstalação',
            'Instalação de kit de fixação',
            'Limpeza e desentupimento de calhas e dutos',
            'Ventilador de teto',
            'Central telefônica, interfone e porteiro eletrônico',
            'Conectividade',
            'Mudança de mobiliário',
            'Reversão de gás para fogão',
          ],
        },
      ],
    },
  },
  {
    Component: BoxCards,
    props: {
      variant: 'primary',
      anchor_id: 'coverages',
      title: 'Coberturas que protegem seus clientes e o imóvel',
      values: [
        {
          columns: 4,
          cards: [
            {
              icon: 'fireflame',
              title: 'Incêndio, explosão e fumaça',
              subtitle:
                'Cobertura para danos materiais causados por incêndio, explosão e fumaça.',
            },
            {
              icon: 'home',
              title: 'Perda de aluguel do imóvel',
              subtitle:
                'Garante os valores de aluguel e despesas prediais, em caso de sinistro coberto por incêndio ou explosão.',
            },
            {
              icon: 'flash',
              title: 'Danos elétricos',
              subtitle:
                'Cobertura dos danos elétricos causados a instalações eletrônicas ou elétricas, inclusive conduítes e material de acabamento, devido a variações anormais de tensão, curto-circuito, calor gerado acidentalmente por eletricidade, descargas elétricas, bem como os danos causados pela queda de raio.',
            },
            {
              icon: 'injuredperson',
              title: 'Responsabilidade civil',
              subtitle:
                'Cobertura da responsabilização civil do segurado por danos corporais e materiais.',
            },
            {
              icon: 'caralert',
              title: 'Impacto de veículos',
              subtitle:
                'Garante a cobertura dos danos materiais causados ao imóvel por queda de aeronaves e impacto de veículos.',
            },
            {
              icon: 'group',
              title: 'Tumulto',
              subtitle:
                'Cobertura dos danos materiais causados ao imóvel por tumulto, exceto quebra de vidros. Essa cobertura é válida apenas para imóveis não-residenciais.',
            },
            {
              icon: 'wind',
              title: 'Vendaval, furacão, ciclone, tornado e queda de granizo',
              subtitle:
                'Cobre, entre outros prejuízos, o destelhamento do imóvel causado por vendavais.',
            },
          ],
        },
      ],
    },
  },
  {
    Component: InfoAndActionSection,
    props: {
      variant: 'primary',
      title: 'Condições gerais',
      anchor_id: 'conditions',
      values: {
        info: 'Acesse os documentos que apresentam as condições de contratação.',
        action: {
          type: 'link',
          label: 'Abrir condições gerais',
          icon: 'externalLink',
          url: 'https://www.portoseguro.com.br/seguro-imobiliaria',
        },
      },
    },
  },
  {
    Component: Faq,
    props: {
      variant: 'primary',
      title: 'Tire suas dúvidas',
      anchor_id: 'faq',
      values: [
        {
          question: 'O que é o Seguro Imobiliária?',
          answer:
            'Este seguro oferece uma série de facilidades que complementam a cobertura obrigatória por lei contra incêndio para imóveis alugados, com fins residenciais ou não. <br /> Além disso, permite reunir diversas propostas de seguros de incêndio em um único “lote”, facilitando o dia a dia das imobiliárias e administradoras de imóveis que movimentam um grande volume desse tipo de apólices por mês em cumprimento de lei.',
        },
        {
          question: 'Quais as principais coberturas do Seguro Imobiliária?',
          answer:
            'Cobertura básica de incêndio, explosão, fumaça, queda de raio no imóvel segurado e queda de aeronave ou engenhos aéreos, garantindo proteção exclusiva ao imóvel. <br /> E, é concedido um percentual adicional de 10% do valor contratado na cobertura básica com a finalidade de cobrir eventuais danos ao conteúdo referente aos bens do inquilino. <br /> <br /> Além disso, temos as coberturas adicionais, como: <br /> - Danos Elétricos; <br /> - Perda de Aluguel; <br /> - Impacto de Veículos; <br /> - Responsabilidade Civil, entre outras.',
        },
        {
          question: 'O seguro para imóvel alugado é obrigatório?',
          answer:
            'Isso mesmo, a contratação do seguro de incêndio é definida como obrigação nos artigos 22 e 23 da Lei do Inquilinato (Lei n.º8.245/1991).',
        },
        {
          question:
            'Quais os principais benefícios que o Seguro Imobiliária oferece?',
          answer:
            'O Seguro Imobiliária evita possíveis prejuízos aos proprietários e gastos inesperados aos inquilinos, oferecendo coberturas e serviços gratuitos de assistência para o imóvel. <br/> Tudo isso com um ótimo custo-benefício e, ainda, acesso ao PortoPlus com benefícios exclusivos. <br/> <br/> E para te oferecer ainda mais facilidades, para complementar sua proteção em caso de incêndio, no Seguro Incêndio você ainda pode escolher planos de assistências que vão facilitar seu dia a dia, como chaveiro, encanador, eletricista, reparos em eletrodomésticos e muito mais.',
        },
      ],
    },
  },
  {
    Component: AlsoOffer,
    props: {
      variant: 'primary',
      title: 'Ofereça também',
      values: [
        {
          icon: 'shield',
          upperTitle: 'Garantia de aluguel',
          title: 'Fiança Locatícia',
          description:
            'Substituiu o fiador, tem a vigência do contrato de locação e serviços assistenciais para o imóvel.',
          href: '/produtos/fianca-locaticia',
        },
        {
          icon: 'ticket',
          upperTitle: 'Garantia de aluguel',
          title: 'Título de Capitalização',
          description:
            'Substituiu o fiador, não precisa de comprovação de renda e o inquilino resgata o valor no final do contrato.',
          href: '/produtos/titulo-de-capitalizacao',
        },
      ],
    },
  },
]
