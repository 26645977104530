import { useCallback, useEffect, useRef } from "react"

import { Box, Flex, Typography } from "@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base"
import { Loader } from "@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto"

import { api } from "@/services"
import { toast } from "@/components"

import { actionTypes, useOnboarding } from "../context"
import { Card } from "./../ui"

const PartnerIntegration = ({
  wizard,
}) => {

  const onboarding = useOnboarding()
  const initialized = useRef(false)

  const createPartner = useCallback(async () => {
    const [partner, brokers] = await Promise.all([
      onboarding.getters.partnerShape(),
      onboarding.getters.selectedBrokers()
    ])

    try {
      const errors = []

      for (let counter = 0; counter < brokers.length; counter++) {
        const payload = {
          ...partner,
          broker: brokers[counter].susep
        }

        const data = await api.users.newAccount.createPartner(payload)
        if (data.errorCode) errors.push(data.errorMessage)
      }

      if (errors.length) throw new Error(errors.toString())

      wizard.next()
    } catch (e) {
      toast('Ocorreu um erro inesperado. Por favor, tente novamente...', { type: 'error' })
      wizard.previous()
    }
  }, [onboarding, wizard])

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true

      onboarding.dispatch({
        type: actionTypes.UPDATE_BEHAVIOR,
        payload: {
          selectedBrokers: []
        }
      })

      createPartner()
    }
  }, [createPartner, onboarding])

  return (
    <Card>
      <Flex
        sx={{
          gap: "2rem",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "3rem"
          }}
        >
          <Loader
            size="3rem"
            color="porto-banking-primary"
          />
        </Box>
        <Typography
          variant="porto-title-5-semibold"
          content={`
            Aguarde, estamos enviando seus dados para o credenciamento
            na Porto.
          `}
        />
      </Flex>
    </Card>
  )
}

export default PartnerIntegration
