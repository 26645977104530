import React from 'react'

import * as S from './styles'
import { InputCodeProps } from './types'

const InputCode = (props: InputCodeProps) => {
  return (
    <S.Container>
      <S.Field {...props} />
    </S.Container>
  )
}

export default InputCode
