import styled from 'styled-components'
import { Link } from 'react-router-dom'
import media from 'styled-media-query'

export const Container = styled.div``

export const ContainerButton = styled.div`
  margin-top: 40px;
  align-items: center;
  display: flex;
  button {
    ${media.lessThan('small')`
  
  margin-bottom: 25px;
  width: 100%;
`}
  }
`
export const ContainerLink = styled(Link)`
  margin-right: 24px;
  button {
    height: 50px;
  }
`
export const ContainerRedirect = styled.a`
  margin-right: 24px;
`
