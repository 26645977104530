import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { Link } from 'react-router-dom'
import { rem, rgba } from 'polished'

import { Icons } from '@/components'
import { themes } from '@/styles'

export const Icon = styled(Icons.Ionicons)``

export const Head = styled.div`
  margin-bottom: 16px;

  ${Icon} {
    padding: 8px;

    display: inline-block;

    color: #ccc;
    border-radius: 8px;
    font-size: ${rem('24px')};
    background-color: ${rgba('#ccc', 0.1)};

    ${media.greaterThan('large')`
      padding: 8px;

      font-size: ${rem('22px')};
    `}

    ${media.greaterThan('huge')`
      padding: 12px;

      font-size: ${rem('32px')};
    `}
  }
`

export const Details = styled.div`
  margin-bottom: 24px;
`

export const Title = styled.h3`
  font-size: ${rem('12px')};

  ${media.greaterThan('large')`
    font-size: ${rem('14px')};
  `}

  ${media.greaterThan('huge')`
    font-size: ${rem('20px')};
  `}
`

export const Description = styled.p`
  min-height: 40px;
  padding-top: 8px;

  font-size: ${rem('8px')};
  color: ${themes.text.medium};

  ${media.greaterThan('large')`
    min-height: 50px;

    font-size: ${rem('10px')};
  `}

  ${media.greaterThan('huge')`
    font-size: ${rem('12px')};
  `}
`

export const Footer = styled.div`
  text-align: right;
`

export const Action = styled.button`
  all: unset;

  font-size: ${rem('16px')};
  color: ${themes.palletes.primary};

  ${media.greaterThan('large')`
    font-size: ${rem('24px')};
  `}
`

export const Container = styled(Link)`
  all: unset;

  padding: 16px;

  cursor: pointer;
  transition: 0.3s;
  border-radius: 8px;
  background-color: ${themes.page.colors.background};

  ${media.greaterThan('large')`
    padding: 24px;
  `}

  &:hover {
    transition: 0.3s;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.05);
  }

  ${(props) =>
    props.color &&
    css`
      ${Head} {
        ${Icon} {
          color: ${props.color};
          background-color: ${rgba(props.color, 0.1)};
        }
      }
    `}
`
