import styled, { css } from 'styled-components'
import { rgba, rem } from 'polished'

import { Actions as ActionsComponent } from '@/components'
import { themes } from '@/styles'

export const List = styled.div``

export const Card = {
  Item: styled.div<{ add?: boolean }>`
    ${({ add }) => css`
      padding: 16px;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      border-radius: 8px;
      border: 1px dashed ${rgba(themes.palletes.primary, 0.3)};

      ${!add &&
      css`
        border-style: solid;
        background-color: ${rgba(themes.palletes.primary, 0.02)};
      `}

      ${add &&
      css`
        cursor: pointer;
        align-items: center;
        justify-content: center;

        &:hover {
          background-color: ${rgba(themes.palletes.primary, 0.04)};
        }
      `}
    `}
  `,

  Detail: styled.div``,

  Title: styled.h5`
    font-weight: 500;
  `,

  Description: styled.p`
    margin-top: 4px;

    font-size: ${rem('12px')};
    color: ${themes.text.high};
  `,

  Actions: styled.div`
    margin-top: 16px;

    display: flex;
  `,

  Action: styled(ActionsComponent.Button).attrs(() => ({
    size: 'small',
    type: 'button',
  }))`
    flex: 1;

    &:not(:last-of-type) {
      margin-right: 6px;
    }
  `,
}
