import { Grid } from '@/components'
import { Form as FormFormik } from 'formik'
import styled from 'styled-components'

export const Budgets = styled.div`
  overflow-x: auto;
  display: flex;
  gap: 16px;
  padding-bottom: 16px;

  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  scroll-snap-align: center;

  mask-image: linear-gradient(
    to right,
    transparent,
    white 0.5rem,
    white calc(100% - 0, 5rem),
    transparent
  );
`

export const Form = styled(FormFormik)`
  display: grid;
  gap: 16px;
`

export const ActionGrid = styled(Grid)`
  justify-content: space-between;
  align-items: center;
`

export const FormActions = styled.div`
  display: flex;
  justify-content: stretch;
`
