import styled, { css } from 'styled-components'

export const Button = styled.button`
  background: #fff;
  border: 1px solid #0046C0;
  border-radius: 4px;
  color: #0046C0;
  cursor: pointer;
  font-size: 0.889rem;
  font-weight: 700;
  padding: 12px 16px;
  width: 100%;

  ${({ disabled }) => disabled && css`
    cursor: wait;
  `}

  ${({ variant }: { variant?: string }) => variant === 'filled' && css`
    background: #0046C0;
    color: #fff;
  `}
`

export const Modal = styled.div`
  background: #fff;
  border-radius: 16px;
  display: flex;
  flex-flow: column nowrap;
  padding: 32px;
  width: 942px;
`

export const Overlay = styled.div`
  align-items: center;
  background: rgba(0, 0, 0, .5);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 5;
`

export const ShapeStatus = styled.div`
  border-radius: 4px;
  font-size: .667rem;
  font-weight: 700;
  padding: 3px 8px;

  ${({ bgColor, fontColor }: { bgColor: string; fontColor: string; }) => (bgColor && fontColor) && css`
    background-color: ${bgColor};
    color: ${fontColor};
  `}
`

export const TableWrapper = styled.div`
 max-height: 400;
 width: 100%;
`
