import styled, { css } from 'styled-components'

export { ActionContainer, Item } from '../Table/styles'

export const Details = styled.div<{ isOpen?: boolean; bgColor: string }>`
  display: flex;
  gap: 1rem;
  flex-direction: column;

  cursor: pointer;
  grid-column: 1 / 9;
  overflow-y: auto;
  transition: all 0.2s;
  border-bottom: 1px solid var(--system-border-neutral);
  border-top: 1px solid var(--system-border-neutral);

  padding: 1rem;

  background-color: ${({ bgColor }) => `var(--${bgColor})`};

  ${({ isOpen }) =>
    !isOpen &&
    css`
      border-top: none;
      padding: 0 1rem;
      height: 0;
    `}
`

export const DetailsContainer = styled.div`
  display: flex;
  gap: 1rem;
`

export const DetailsItem = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
`

export const DetailsActions = styled.div`
  display: flex;
  gap: 0.5rem;
`

export const LoaderContainer = styled.div`
  padding: 0.75rem 1.25rem;
`
