export const isAluguelEnabled = (profile) => {
  if (profile.type === 'BROKER') return true
  return profile.partnerships.some(
    (partnership) => (
      partnership.settings['porto_aluguel.basic.enabled'] ||
      partnership.settings['porto_aluguel.traditional.enabled'] ||
      partnership.settings['porto_aluguel.university.enabled']
    )
  )
}
