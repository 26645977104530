import { Actions } from '@/components'
import { pxToRem } from '@/styles/sheets/helpers'
import breakpoints from '@pol-npm/riscos-financeiros-dashboard-ui/dist/theme/breakpoints'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const FirstSection = styled.div`
  align-items: center;
  background-color: #0a0047;
  border-radius: ${pxToRem(16)};
  display: flex;
  justify-content: space-between;
  padding: 0 ${pxToRem(32)};

  @media (max-width: ${breakpoints.md}) {
    padding: ${pxToRem(24)};
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(16)};
`

export const Image = styled.img`
  @media (max-width: ${breakpoints.md}) {
    display: none;
  }
`

export const LoaderContainer = styled.div`
  margin: ${pxToRem(96)} 0 0 ${pxToRem(32)};
`

export const MonthContainer = styled.div<{
  bonusRows: number[]
  expiredRows: number[]
  awardedRow?: number
}>`
  border-radius: ${pxToRem(12)};
  border: ${pxToRem(1)} solid #e0e0e0;
  display: flex;
  flex-direction: column;
  margin-top: ${pxToRem(48)};
  max-width: ${pxToRem(870)};
  padding: ${pxToRem(32)};

  @media (max-width: ${breakpoints.md}) {
    padding: ${pxToRem(16)};
  }

  tbody {
    > tr {
      border-bottom: ${pxToRem(1)} solid #e0e0e0;

      ${props =>
        props.bonusRows.map(
          line => css`
            &:nth-child(${line}) {
              > td > div {
                color: #2c3da8;
              }
            }
          `,
        )}

      ${props =>
        props.expiredRows.map(
          line => css`
            &:nth-child(${line}) {
              > td > div {
                color: #999999;
              }
            }
          `,
        )}

      ${props =>
        props.awardedRow &&
        css`
          &:nth-child(${props.awardedRow}) {
            > td > div {
              color: #29684e;
              font-weight: bold;
            }
          }
        `}

      > td {
        padding: ${pxToRem(10)} ${pxToRem(12)} !important;
      }
    }
  }
`

export const ScrollableWrapper = styled.div`
  padding: ${pxToRem(1)};
  overflow: auto;
`

export const Regulation = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(16)};
  margin-top: ${pxToRem(64)};
`

export const Button = styled(Actions.Button)`
  align-self: flex-start;
`

export const Questions = styled.div`
  margin-top: ${pxToRem(96)};

  #faq {
    padding: 0;
    white-space: pre-line;
  }
`
