import { BRLMoneyToNumber, formatMoneyBRL } from '@/tools'
import * as yup from 'yup'

export default yup.object().shape({
  loan_value: yup
    .string()
    .required('Este campo precisa ser preenchido.')
    .test('max_loan_value', '', (val, schema) => {
      const { max_loan_value: maxLoanValue, property_loan_percent: propertyLoanPercent } = schema.parent

      if (typeof val !== 'undefined' && BRLMoneyToNumber(val) > maxLoanValue) {
        return schema.createError({
          message: `Empréstimo disponível até ${formatMoneyBRL(
            maxLoanValue
          )} (${propertyLoanPercent}% do valor do imóvel).`,
        })
      }

      return true
    })
    .test('min_loan_value', '', (val, schema) => {
      const minLoanValue = 30000

      if (typeof val !== 'undefined' && BRLMoneyToNumber(val) < minLoanValue) {
        return schema.createError({
          message: `Empréstimo disponível a partir de ${formatMoneyBRL(
            minLoanValue
          )}.`,
        })
      }

      return true
    }),
  usage: yup.string().required('Informe o motivo do empréstimo.'),
  grace_period_days: yup.string().required('Informe a carência'),
  deadline_months: yup
    .number()
    .required('Informe o prazo em meses')
    .min(36, 'O prazo mínimo é 36 meses.')
    .test('dead_line_by_property_type', '', (val, schema) => {
      const { property_type_selected: propertyTypeSelected } = schema.parent

      const deadLinesByProperty: {
        value: string
        label: string
        deadLineLimit: number
      }[] = [
        {
          value: 'Casa residencial',
          label: 'Casa residencial',
          deadLineLimit: 240,
        },
        {
          value: 'Apartamento residencial',
          label: 'Apartamento residencial',
          deadLineLimit: 240,
        },
        { value: 'Terreno', label: 'Terreno', deadLineLimit: 120 },
        { value: 'Comercial', label: 'Comercial', deadLineLimit: 180 },
      ]

      const deadLineProperty = deadLinesByProperty.find(
        (propertyDeadLine) => propertyDeadLine.value === propertyTypeSelected
      )

      if (
        typeof val !== 'undefined' &&
        deadLineProperty &&
        deadLineProperty.deadLineLimit < val
      ) {
        return schema.createError({
          message: `O prazo máximo para ${deadLineProperty.label} é ${deadLineProperty.deadLineLimit} meses.`,
        })
      }

      return true
    }),
})
