import React from 'react'
import { pascalCase } from 'change-case'
import * as FeatherIcons from 'react-feather'

import { IFeatherProps } from './interfaces'

const Feather: React.FC<IFeatherProps> = (props) => {
  const Icon = FeatherIcons[pascalCase(props.name)] || React.Fragment

  return <Icon {...props} />
}

Feather.defaultProps = {
  name: 'Activity',
}

export default Feather
