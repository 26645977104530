import { Container, Content, Icon, PaginationButton } from './styles'

const Paginator = (props) => {
  return (
    <Container>
      <Content>
        <PaginationButton
          onClick={props.onPrevious}
          disabled={props.numberPage === 1}
        >
          <Icon name="chevron-left"></Icon>
          Anterior
        </PaginationButton>
        <PaginationButton
          onClick={props.onNext}
          disabled={props.limitPage < 10}
        >
          Próximo
          <Icon name="chevron-right"></Icon>
        </PaginationButton>
      </Content>
    </Container>
  )
}

export default Paginator
