import styled from 'styled-components'
import media from 'styled-media-query'
import { rem } from 'polished'

import { themes } from '@/styles'

const arrowColor = themes.palletes.primary

export const Container = styled.aside`
  position: relative;

  width: 100%;
  padding: 24px;

  border-radius: 8px;
  background-color: ${themes.page.colors.background};

  ${media.greaterThan('huge')`
    padding: 32px;
  `}

  ${media.lessThan('large')`
    padding: 16px;

    display: flex;
    align-items: center;
    margin-bottom: 3px;
  `}

  &:before {
    position: absolute;
    z-index: -1;

    content: '';
    border-style: solid;

    ${media.greaterThan('large')`
      top: 3px;
      left: -3px;

      border-width: 6px 5px 6px 0;
      border-color: transparent ${arrowColor} transparent transparent;
    `}

    ${media.greaterThan('huge')`
      top: 6px;
      left: -6px;

      border-width: 12px 8px 12px 0;
    `}

    ${media.lessThan('large')`
      bottom: -8px;
      left: 50%;
      transform: translateX(-50%);

      border-width: 8px 10px 0 10px;
      border-color: ${arrowColor} transparent transparent transparent;
    `}
  }
`

export const Icon = styled.div`
  font-size: ${rem('16px')};
  color: ${themes.palletes.primary};

  ${media.greaterThan('large')`
    font-size: ${rem('24px')};
  `}

  ${media.greaterThan('huge')`
    font-size: ${rem('32px')};
  `}

  ${media.lessThan('large')`
    margin-top: 2px;
    margin-right: 8px;
  `}
`

export const Content = styled.article`
  font-size: ${rem('12px')};
  color: ${themes.text.medium};

  ${media.greaterThan('large')`
    font-size: ${rem('9px')};
  `}

  ${media.greaterThan('huge')`
    font-size: ${rem('13px')};
  `}
`
