import styled, { keyframes } from 'styled-components'
import media from 'styled-media-query'

export const Content = styled.div`
  height: auto;
  overflow-y: auto;

  transition: all 0.5s;
`

export const ExpandedLabel = styled.label`
  display: none;
  width: 100%;
  padding: 16px;
  color: var(--system-text-highlight);

  ${media.lessThan('medium')`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `}
`

export const ExpandController = styled.input`
  display: none;

  ${media.lessThan('medium')`
    &:not(:checked) {
      ~ ${Content} {
        height: 0;
      }

      ~ ${ExpandedLabel} {
        > svg {
          transform: rotate(180deg);
        }
      }
    }
  `}
`

export const Section = styled.div`
  padding: 16px;
  overflow: hidden;

  & + & {
    border-top: 1px solid var(--porto-primitive-black-15);
  }

  > :first-child {
    margin-bottom: 16px;
  }
`

export const SectionRow = styled.div`
  display: flex;
  gap: 0.25rem;

  white-space: nowrap;

  & + & {
    margin-top: 8px;
  }
`
