import { api } from '@/services'
import { useQuery } from 'react-query'

export function useEnums() {
  const query = useQuery(['capitalizacao', 'data', 'enums'], api.capitalizacao.getEnums, {
    refetchOnWindowFocus: false,
  })

  return query
}
