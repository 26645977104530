import api from '..'
import { BrokerRealEstatePartnerships, PartnershipSuseps } from './types'

export async function loadBrokerRealEstatesPartnerships(
  brokerId: string
): Promise<BrokerRealEstatePartnerships> {
  try {
    const response = await api.instance.v2.get(
      `partnerships/broker/${brokerId}/real-estates`
    )
    return response.data
  } catch (error) {
    throw error
  }
}

export async function loadBrokerPartnershipSuseps(
  partnershipId: string
): Promise<PartnershipSuseps> {
  try {
    const response = await api.instance.v2.get(
      `partnerships/${partnershipId}/broker-suseps`
    )
    return response.data
  } catch (error) {
    throw error
  }
}

export async function loadRealEstateProductPartnershipSuseps(
  product: string = 'any'
): Promise<PartnershipSuseps> {
  try {
    const response = await api.instance.v2.get(
      `partnerships/real-estate/suseps/${product}`
    )
    return response.data
  } catch (error) {
    throw error
  }
}
