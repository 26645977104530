import { useUnit } from 'effector-react'
import { useQuery } from 'react-query'

import { api } from '@/services'

import { contractStatus as capContractStatus } from '@/data/capitalizacao'
import { Products } from '@/screens/Contracts/Products/shared/entities'
import * as actions from './actions'
import { Contract } from './interface'
import { ContractStore } from './store'
import { useParams } from 'react-router-dom'

const shouldRefetch = (contract?: Contract<Products>) => {
  if (!contract?.product) {
    return false
  }

  if (
    contract?.product === Products.CAPITALIZACAO &&
    contract?.metadata?.status === capContractStatus.DRAFT
  ) {
    return false
  }

  if (
    contract.status !== 'PENDING' &&
    !['@aluguel/biometry-review', '@essencial/biometry-review'].includes(
      contract.metadata?.status
    )
  )
    return false

  return true
}

export function useContract<T extends Products>() {
  // @ts-ignore
  const contract = useUnit<Contract<T>>(ContractStore)
  const params = useParams<{ id?: string }>()

  const { status: queryStatus, isFetching } = useQuery(
    ['contracts', params.id],
    () => api.contracts.get(params.id),
    {
      enabled: !!params.id && !!contract.id && params.id === contract.id,
      onSuccess: (data) => {
        if (contract.status !== data.status || queryStatus === 'loading') {
          actions.updateAll(data)
          return
        }

        if (
          JSON.stringify(contract.metadata) !== JSON.stringify(data.metadata)
        ) {
          actions.updateAll({ ...contract, metadata: data.metadata })
        }
      },
      refetchInterval: (data) => shouldRefetch(data) && 10000,
      refetchOnWindowFocus: false,
    }
  )

  return {
    ...contract,
    ...actions,
    queryStatus,
    isFetching,
  }
}
