import { useEffect, useState } from 'react'

import { Actions, Grid, Modals } from '@/components'
import { api } from '@/services'
import { Form, FormikProvider, useFormik } from 'formik'
import { useHistory, useLocation } from 'react-router-dom'

import { BRLMoneyToNumber, useGTM } from '@/tools'

import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Card, Icon } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'

import { usePartnershipSettings } from '@/modules'
import { Contract } from '@/modules/contract/interface'
import ErrorFocus from '@/tools/utils/focusError'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { LayoutEssencial } from '../../../../../layouts/LayoutEssencial'
import { Organization } from '../../shared'
import { Products } from '../../shared/entities'
import * as Steps from './Steps'
import { FormSchemaType, useInitialStateForm, validationSchema } from './form'
import * as S from './styles'

const parseEssencialPayload = payload => {
  const customers = payload.customers.map(customer => {
    return {
      ...customer,
      document: customer.document.replace(/\D/g, ''),
      birthdate: customer.birthdate.split('/').reverse().join('-'),
      email: customer.email,
      phone: customer.phone.replace(/\D/g, ''),
    }
  })

  const coverages_main = { rent: 0 }
  Object.entries(payload.coverages?.coverages_main).forEach(([key, value]: [string, string]) => {
    coverages_main[key] = !!value ? BRLMoneyToNumber(value) : undefined
  })

  return {
    customers,
    address: payload.address,
    rent_due_date: payload.rent_due_date,
    coverages: {
      rent: coverages_main.rent,
      coverages_main: {
        ...coverages_main,
      },
      coverages_additionals: payload.coverages?.coverages_additionals,
    },
    period: {
      start: dayjs(payload.contract_period_start).format('YYYY-MM-DD'),
      end: dayjs(payload.contract_period_end).format('YYYY-MM-DD'),
    },
    periodContractTerm: {
      start: dayjs(payload.validity_period_start).format('YYYY-MM-DD'),
      end: dayjs(payload.validity_period_end).format('YYYY-MM-DD'),
    },
    readjustment: payload.estate_readjustment,
    payment: {
      comission: payload.comission,
    },
  }
}

const Budgets = () => {
  const history = useHistory()
  const { setCustomData, setDataLayer } = useGTM()
  const { resend, initialValues } = useInitialStateForm()
  const { state: editContract } = useLocation<Contract<Products.ESSENCIAL>>()

  const [valuesPartner, setValuesPartner] = useState({ id: '', name: '' })

  useEffect(() => {
    setCustomData({
      page: {
        name: 'Portal Imobiliária - Orçamento - Seguro Fianca Essencial',
        product: 'aluguel-fianca',
      },
      site: {
        portal: 'imobiliarias',
      },
    })

    setDataLayer({
      event: 'step_change',
      titulo: 'Portal Imobiliária - Seguro Fianca Essencial',
      etapa: '/area-logada/orcamentos/seguro-fianca-essencial',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const form = useFormik<FormSchemaType>({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: async values => {
      const hasCoverages = Object.keys(values.coverages.coverages_main).some(
        key =>
          key !== 'rent' &&
          !!values.coverages.coverages_main[key] &&
          !!BRLMoneyToNumber(values.coverages.coverages_main[key]),
      )
      const hasCoveragesAditionals = Object.keys(values.coverages.coverages_additionals).some(
        key => !!values.coverages.coverages_additionals[key],
      )

      if (!hasCoverages && !hasCoveragesAditionals) {
        const sendAnyway = await Modals.Confirmation({
          content:
            'Nenhuma cobertura adicional foi selecionada. Em caso de sinistro, esses itens não serão indenizados.',
          title: 'Nenhuma cobertura adicional selecionada',
          icon: 'alert-triangle',
          submitText: 'Continuar sem coberturas adicionais',
          dismissText: 'Voltar',
        })

        if (!sendAnyway) {
          history.push('#coberturas-adicionais')
          return
        }
      }

      try {
        const payload = {
          ...values,
          partner: valuesPartner.id || values.partner,
        }

        let contract

        if (resend) {
          contract = await api.contracts.update(editContract.id, parseEssencialPayload(payload))
          await api.contracts.calculate(editContract.id)
        } else {
          contract = await api.contracts.create('essencial', payload)
        }

        setDataLayer({
          event: 'solicitacao-servico',
          nome_servico: 'seguro-fianca-essencial',
          tipo_servico: 'calcular-orcamento',
          retorno: 'sucesso',
          descricao: 'Sucesso ao calcular orçamento',
        })
        history.push(`/contratos/${contract.id}`)
      } catch (error: AxiosError | any) {
        setDataLayer({
          event: 'solicitacao-servico',
          nome_servico: 'seguro-fianca-essencial',
          tipo_servico: 'calcular-orcamento',
          retorno: 'erro',
          descricao: 'Ocorreu um erro ao calcular o orçamento',
          erro: {
            codigo: error.response?.status,
            servico: 'calculo de orçamento Fiança Locatícia Essencial',
            mensagem: error.response?.data?.message,
          },
        })
      }
    },
  })

  const settings = usePartnershipSettings(
    form.values.susep,
    valuesPartner.id || form.values.partner,
  )

  useEffect(() => {
    // Setta os valores de comissão no formuário para ser enviado durante calculo de orçamento
    form.setFieldValue('comission', settings?.porto_aluguel?.traditional?.comission)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings?.porto_aluguel?.traditional?.comission])

  return (
    <LayoutEssencial currentStep={1}>
      <FormikProvider value={form}>
        <Form>
          <Grid gap="16px">
            {!resend && (
              <Card
                sx={{
                  background: 'var(--system-primitive-off-white)',
                  border: 0,
                }}
              >
                <Grid gap="12px" columns="max-content 1fr">
                  <Icon name="home" color="system-text-highlight" />
                  <Grid>
                    <Typography
                      as="span"
                      variant="porto-text-body-1-bold"
                      color="system-text-primary"
                    >
                      Fiança Locatícia para aluguel acima de R$ 12.000?
                    </Typography>
                    <Typography
                      as="span"
                      variant="porto-text-body-2-regular"
                      color="system-text-secondary"
                    >
                      Propostas para aluguéis com valor acima de R$ 12.000 devem ser realizadas no
                      Corretor Online
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            )}

            {!resend && (
              <Card>
                <Grid>
                  <Typography as="span" variant="porto-title-4-semibold">
                    Corretora de Seguros
                  </Typography>
                  <Typography
                    as="span"
                    variant="porto-text-body-1-regular"
                    sx={{ marginBottom: '32px' }}
                  >
                    Selecione a Susep para o corretor prestar suporte na contratação de produtos
                  </Typography>
                  <S.OrganizationWrapper>
                    <Organization
                      product="porto_essencial"
                      valuesPartner={setValuesPartner}
                      gtmSubname="seguro-fianca-essencial"
                      disabled={resend}
                    />
                  </S.OrganizationWrapper>
                </Grid>
              </Card>
            )}

            <Steps.Contract />
            <Steps.Estate />
            <Steps.Coverages />

            <Grid columns={['1fr', 'repeat(2, max-content)']} gap="16px">
              <Actions.Button
                kind="ghost"
                type="button"
                onClick={() => history.push('/')}
                isDisabled={form.isSubmitting}
              >
                Cancelar
              </Actions.Button>
              <Actions.Button type="submit" isLoading={form.isSubmitting}>
                Calcular ofertas
              </Actions.Button>
            </Grid>
          </Grid>
        </Form>
        <ErrorFocus />
      </FormikProvider>
    </LayoutEssencial>
  )
}

export default Budgets
