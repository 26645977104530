import styled from 'styled-components'
import { rem } from 'polished'

export const Content = styled.div``

export const IconContainer = styled.div`
  font-size: ${rem('32px')};
  margin-top: 20px;
`

export const Title = styled.div`
  font-size: ${rem('24px')};
  color: var('--neutras-black-85', #1f1f1f);
`

export const Paragraph = styled.div`
  margin-top: ${rem('24px')};
  font-size: ${rem('14px')};
  color: var(--neutras-black-75, #404040);
`
