export const generateRangeNumbers = (
  start: number,
  end: number,
  length = end - start + 1
) => Array.from({ length }, (_, i) => start + i)

export const getRandomNumberBetween = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export const convertPercentToDecimal = (percent: number): number => {
  return percent / 100;
}

export const convertDecimalToPercent = (decimal: number): number => {
  return decimal * 100;
}