import { Modals } from '@/components'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Icon as PortoIcon } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import * as S from './styles'

const Modal = (props) => {
  return (
    <Modals.Content
      onSubmit={{ text: 'Ok, entendi', onClick: props.onDismiss }}
      onDismiss={props.onDismiss}
    >
      <S.Header>
        <PortoIcon size={20} color="porto-primitive-yellow-80" name="paper" />

        <Typography
          variant="porto-title-6-semibold"
          color="porto-primitive-black-100"
        >
          Atualização do contrato de estipulação
        </Typography>
      </S.Header>

      <S.Content>
        <Typography
          color="#0A0047"
          variant="porto-text-body-1-regular"
          content="Os novos contratos já estão sendo enviados e a "
        />
        <Typography
          color="#0A0047"
          variant="porto-text-body-1-bold"
          content="assinatura do documento é obrigatória para todos os corretores e
        imobiliárias credenciadas."
        />
        <br />
        <br />
        <Typography
          color="#0A0047"
          variant="porto-text-body-1-regular"
          content="Para receber o contrato, é importante que o e-mail da imobiliária esteja
        atualizado. Caso deseje atualizar, é necessário encaminhar um e-mail para
        credenciamento.fiancaimob@portoseguro.com.br, mencionando o código da
        imobiliária, CPF/CNPJ e o novo endereço de e-mail."
        />
      </S.Content>
    </Modals.Content>
  )
}

export const ContractUpdateModal = Modals.Promised.createModal(Modal)
