import styled from 'styled-components'
import media from 'styled-media-query'
import { rem, size } from 'polished'

import { Actions as ActionsComponent } from '@/components'
import { FeatherIcons } from '@/components/Icons'
import { themes } from '@/styles'

export const Description = styled.p`
  margin: ${rem('30px')} 0 ${rem('8px')} ${rem('8px')};

  text-align: center;

  font-weight: 600;
  font-size: ${rem('14px')};
  line-height: 1.25rem;

  color: ${themes.text.medium};

  ${media.lessThan('huge')`
    font-size: ${rem('14px')};
  `}
`

export const Grids = styled.div`
  margin-top: 32px;
  display: grid;
  align-items: center;
  justify-content: center;
`

export const Action = styled(ActionsComponent.Button).attrs({
  type: 'button',
})``

export const IconError = styled(FeatherIcons)`
  ${size('140px')}

  stroke-width: 1px;

  color: #f78fa6;

  align-items: center;
  justify-content: center;
`
