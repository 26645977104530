import { createEffect } from 'effector'

import { api } from '@/services'

import { ContractStore } from '../store'

export const create = createEffect('createContract', {
  handler: async ({ product, payload }) => {
    const contract = await api.contracts.create(product, payload)
    return contract
  },
})

ContractStore.on(create.doneData, (_, contract) => contract)
