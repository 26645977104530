import { Box, Flex } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'

import ExternalLink from '@/components/ExternalLink'

const LoginContent = ({ children }) => {
  return (
    <Flex
      sx={{
        height: "100%",
        flexDirection: "column",
        gap: "3rem",
        padding: { xs: "2rem", lg: "6rem" },
      }}
    >
      {children}
      <Box
        sx={{
          marginTop: "auto",
        }}
      >
        <ExternalLink
          label="Precisa de ajuda?"
          icon="circlequestion"
          linkProps={{
            href: "https://prime.altubots.com/chats/portoassistencia/ae0ef766514ed0b63a1ab527e7989133/index.html?plataforma=Site%20Institucional",
            target: "_blank"
          }}
        />
      </Box>
    </Flex>
  )
}

export default LoginContent
