import { Box } from "@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base"
import { BaseCard } from "../BaseCard"
import { Card } from './Card'
import { IComparisonProps } from "./interfaces"

export const Comparison = ({
  anchor_id,
  title,
  values,
  variant = "primary",
  columns = 3,
}: IComparisonProps) => (
  <BaseCard
    title={title}
    variant={variant}
    id={anchor_id}
  >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { xs: "1fr", lg: `repeat(${columns}, 1fr)` },
          justifyItems: 'center',
          gap: "24px",
        }}
      >
        {values.map((card) => (
          <Card
            key={JSON.stringify(card)}
            {...card}
          />
        ))}
      </Box>
  </BaseCard>
)