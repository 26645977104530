import { Modals } from '@/components'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { FormikProvider, useFormik } from 'formik'
import * as S from './styles'
import * as yup from 'yup'
import { pxToRem } from '@/styles/sheets/helpers'
import Choice from '@/components/Forms/Choice'

const validationSchema = yup.object().shape({
  offers: yup.array().of(yup.string()).min(1, 'Selecione ao menos uma opção'),
  paymentMethods: yup
    .array()
    .of(yup.string())
    .min(1, 'Selecione ao menos uma opção'),
})

const initialValues = { offers: [], paymentMethods: [] }

const selectAllOption = { label: 'Selecionar todos', value: 'all' }

const OffersDownloadModal = ({
  offers,
  paymentMethods,
  ...rest
}: {
  offers: { label: string; value: string }[]
  paymentMethods: { label: string; value: string }[]
}) => {
  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: ({ offers, paymentMethods }) =>
      rest.onSubmit({
        offers: offers.filter((offer) => offer !== 'all'),
        paymentMethods: paymentMethods.filter((method) => method !== 'all'),
      }),
  })

  const handleChange = (e: React.ChangeEvent<any>) => {
    const { name, value, checked } = e.target
    const selected = formik.values[
      name as keyof typeof initialValues
    ] as string[]

    const availableOptions = name === 'offers' ? offers : paymentMethods

    if (value === 'all' && checked) {
      formik.setFieldValue(name, [
        'all',
        ...availableOptions.map((option) => option.value),
      ])
    } else if (selected.includes('all')) {
      formik.setFieldValue(
        name,
        selected.filter((selected) => selected !== 'all' && selected !== value)
      )
    } else {
      formik.setFieldValue(
        name,
        checked
          ? [...selected, value]
          : selected.filter((selected) => selected !== value)
      )
    }
  }

  return (
    <Modals.Content
      onSubmit={{ text: 'Baixar PDF', onClick: () => formik.handleSubmit() }}
      onCancel={{ text: 'Cancelar', onClick: rest.onDismiss }}
      title="Baixar ofertas em PDF"
    >
      <FormikProvider value={formik}>
        <Typography
          color="system-primitive-neutral-800"
          variant="porto-text-body-1-bold"
          content="Selecione as ofertas que deseja baixar."
        />
        <S.Container>
          {[selectAllOption, ...offers].map((option) => (
            <Choice
              key={option.value}
              name="offers"
              type="checkbox"
              value={option.value}
              label={option.label}
              onChange={handleChange}
            />
          ))}
        </S.Container>
        {formik.errors.offers && (
          <Typography
            color="porto-primitive-red-80"
            variant="porto-text-caption-regular"
            content={formik.errors.offers}
            sx={{ marginTop: pxToRem('8px') }}
          />
        )}
        <Typography
          color="system-primitive-neutral-800"
          variant="porto-text-body-1-bold"
          content="Selecione as formas de pagamento que deseja apresentar no documento."
          sx={{ marginTop: pxToRem('24px') }}
        />
        <S.Container>
          {[selectAllOption, ...paymentMethods].map((option) => (
            <Choice
              key={option.value}
              name="paymentMethods"
              type="checkbox"
              value={option.value}
              label={option.label}
              onChange={handleChange}
            />
          ))}
        </S.Container>
        {formik.errors.paymentMethods && (
          <Typography
            color="porto-primitive-red-80"
            variant="porto-text-caption-regular"
            content={formik.errors.paymentMethods}
            sx={{ marginTop: pxToRem('8px') }}
          />
        )}
      </FormikProvider>
    </Modals.Content>
  )
}

export default Modals.Promised.createModal(OffersDownloadModal)
