interface DataTable {
  header: string
  key: string
  width: number
  note: string
  config?: { color: string }
  style?: { numFmt: string }
}

export const dataTable: DataTable[] = [
  {
    header: 'CPF/CNPJ do Proponente',
    key: 'customer_document',
    width: 21,
    note: 'PREENCHIMENTO OBRIGATÓRIO, INSIRA APENAS OS NÚMEROS, SEM PONTUAÇÃO E TRAÇOS.',
    config: { color: 'BLUE' },
    style: { numFmt: '@' },
  },
  {
    header: 'Nome de registro do proponente',
    key: 'customer_name',
    width: 21,
    note: 'PREENCHIMENTO OBRIGATÓRIO.',
    config: { color: 'YELLOW' },
  },
  {
    header: 'Nome Social do proponente',
    key: 'customer_social_name',
    width: 25,
    note: 'PREENCHIMENTO FACULTATIVO',
    config: { color: 'BLUE' },
  },
  {
    header: 'E-mail do proponente',
    key: 'customer_email',
    width: 21,
    note: 'PREENCHIMENTO OBRIGATÓRIO.',
    config: { color: 'BLUE' },
  },
  {
    header: 'DDD',
    key: 'customer_phone_code',
    width: 21,
    note: 'PREENCHIMENTO OBRIGATÓRIO.',
    config: { color: 'BLUE' },
  },
  {
    header: 'Telefone',
    key: 'customer_phone_number',
    width: 21,
    note: 'PREENCHIMENTO OBRIGATÓRIO.',
    config: { color: 'BLUE' },
  },
  {
    header: 'Estado civil',
    key: 'customer_civil_status',
    width: 21,
    note: 'PREENCHIMENTO OBRIGATÓRIO APENAS PARA PROPONENTE PESSOA FÍSICA. DE ACORDO COM A LISTA SUSPENSA',
    config: { color: 'BLUE' },
  },
  {
    header: 'Profissão',
    key: 'customer_occupation',
    width: 21,
    note: 'PREENCHIMENTO OBRIGATÓRIO APENAS PARA PROPONENTE PESSOA FÍSICA. DE ACORDO COM A LISTA INDICADA NA PLANILHA DE "PROFISSÕES"',
    config: { color: 'BLUE' },
  },
  {
    header: 'Faixa de Renda',
    key: 'customer_income',
    width: 21,
    note: 'PREENCHIMENTO OBRIGATÓRIO APENAS PARA PROPONENTE PESSOA FÍSICA. DE ACORDO COM A LISTA SUSPENSA',
    config: { color: 'BLUE' },
  },
  {
    header: 'PEP',
    key: 'customer_pep',
    width: 21,
    note: 'PREENCHIMENTO OBRIGATÓRIO APENAS PARA PROPONENTE PESSOA FÍSICA. DE ACORDO COM A LISTA SUSPENSA',
    config: { color: 'BLUE' },
  },
  {
    header: 'Tipo de Documento',
    key: 'pep_document_type',
    width: 21,
    note: 'PREENCHIMENTO OBRIGATÓRIO APENAS PARA PROPONENTE PESSOA FÍSICA E SELECIONADA A OPÇÃO "SIM” PARA PEP. DE ACORDO COM A LISTA SUSPENSA',
    config: { color: 'BLUE' },
  },
  {
    header: 'Número do Documento',
    key: 'pep_document',
    width: 21,
    note: 'PREENCHIMENTO OBRIGATÓRIO APENAS PARA PROPONENTE PESSOA FÍSICA E SELECIONADA A OPÇÃO "SIM” PARA PEP',
    config: { color: 'BLUE' },
    style: { numFmt: '@' },
  },
  {
    header: 'Órgão Expedidor',
    key: 'pep_document_dispatcher',
    width: 21,
    note: 'PREENCHIMENTO OBRIGATÓRIO APENAS PARA PROPONENTE PESSOA FÍSICA E SELECIONADA A OPÇÃO "SIM” PARA PEP',
    config: { color: 'BLUE' },
  },
  {
    header: 'Data do Documento',
    key: 'pep_document_date',
    width: 21,
    note: 'PREENCHIMENTO OBRIGATÓRIO APENAS PARA PROPONENTE PESSOA FÍSICA E SELECIONADA A OPÇÃO "SIM” PARA PEP”. CONSIDERAR DATA DE EXPEDIÇÃO PARA “RG” E “CLASSE”. CONSIDERAR DATA DE VALIDADE PARA “RNE”, “CNH” E “PASSAPORTE”',
    config: { color: 'BLUE' },
  },
  {
    header: 'Nacionalidade',
    key: 'pep_nationality',
    width: 21,
    note: 'PREENCHIMENTO OBRIGATÓRIO APENAS PARA PROPONENTE PESSOA FÍSICA E SELECIONADA A OPÇÃO "SIM” PARA PEP. DE ACORDO COM A LISTA SUSPENSA',
    config: { color: 'BLUE' },
  },
  {
    header: 'Reside no Brasil',
    key: 'pep_brazilian_resident',
    width: 21,
    note: 'PREENCHIMENTO OBRIGATÓRIO APENAS PARA PROPONENTE PESSOA FÍSICA E SELECIONADA A OPÇÃO "SIM” PARA PEP. DE ACORDO COM A LISTA SUSPENSA',
    config: { color: 'BLUE' },
  },
  {
    header: 'CPF da PEP',
    key: 'pep_cpf',
    width: 21,
    note: 'PREENCHIMENTO OBRIGATÓRIO APENAS PARA PROPONENTE PESSOA FÍSICA E SELECIONADA A OPÇÃO "RELACIONAMENTO PRÓXIMO” PARA PEP. DE ACORDO COM A LISTA SUSPENSA',
    config: { color: 'BLUE' },
    style: { numFmt: '@' },
  },
  {
    header: 'Nome da Pessoa Exposta Politicamente',
    key: 'pep_name',
    width: 21,
    note: 'PREENCHIMENTO OBRIGATÓRIO APENAS PARA PROPONENTE PESSOA FÍSICA E SELECIONADA A OPÇÃO "RELACIONAMENTO PRÓXIMO” PARA PEP',
    config: { color: 'BLUE' },
  },
  {
    header: 'Grau de Relacionamento',
    key: 'pep_relationship_type',
    width: 21,
    note: 'PREENCHIMENTO OBRIGATÓRIO APENAS PARA PROPONENTE PESSOA FÍSICA E SELECIONADA A OPÇÃO "RELACIONAMENTO PRÓXIMO” PARA PEP. DE ACORDO COM A LISTA SUSPENSA',
    config: { color: 'BLUE' },
  },
  {
    header: 'Proprietário do imóvel?',
    key: 'estate_owner',
    width: 21,
    note: 'PREENCHIMENTO OBRIGATÓRIO DE ACORDO COM A LISTA SUSPENSA',
    config: { color: 'BLUE' },
  },
  {
    header: 'CEP',
    key: 'address_zipcode',
    width: 21,
    note: 'PREENCHIMENTO OBRIGATÓRIO, INSIRA APENAS OS NÚMEROS, SEM PONTUAÇÃO E TRAÇOS.',
    config: { color: 'BLUE' },
    style: { numFmt: '@' },
  },
  {
    header: 'Logradouro',
    key: 'address_street',
    width: 21,
    note: 'PREENCHIMENTO OBRIGATÓRIO',
    config: { color: 'BLUE' },
  },
  {
    header: 'Número',
    key: 'address_number',
    width: 21,
    note: 'PREENCHIMENTO OBRIGATÓRIO.',
    config: { color: 'BLUE' },
  },
  {
    header: 'Complemento',
    key: 'address_complement',
    width: 21,
    note: 'PREENCHIMENTO FACULTATIVO',
    config: { color: 'BLUE' },
  },
  {
    header: 'Bairro',
    key: 'address_neighborhood',
    width: 21,
    note: 'PREENCHIMENTO OBRIGATÓRIO',
    config: { color: 'BLUE' },
  },
  {
    header: 'Tipo de imóvel',
    key: 'estate_type',
    width: 21,
    note: 'PREENCHIMENTO OBRIGATÓRIO DE ACORDO COM A LISTA SUSPENSA',
    config: { color: 'BLUE' },
  },
  {
    header: 'Incêndio',
    key: 'fire',
    width: 21,
    note: 'MÍNIMO DE 40 MIL E MÁXIMO DE 2 MILHÕES, PARA AMBOS OS PLANOS SE O "TIPO IMÓVEL" FOR CASA DE MADEIRA O LIMITE É DE 200 MIL',
    config: { color: 'BLUE' },
  },
  {
    header: 'Danos Elétricos',
    key: 'eletrical_damages',
    width: 21,
    note: 'PLANO COMERCIAL: Minimo de R$ 1.000,00 Máximo de 10% da básica limitado a R$ 200.000,00 \n\nPLANO RESIDENCIAL: Mínimo de R$ 1.000,00 Máximo de 10% da básica limitado a R$ 200.000,00',
    config: { color: 'BLUE' },
  },
  {
    header: 'Perda de Aluguel',
    key: 'rental_loss',
    width: 21,
    note: 'SOMENTE QUANDO FOR TIPO DE IMÓVEL OCUPADO. \n\nPLANO COMERCIAL:  Mínimo de R$ 1.000,00 Máximo de 30% da básica limitado a R$ 250.000,00 \n\nPLANO RESIDENCIAL: Mínimo de R$ 3.000,00 Máximo de 20% da básica limitado a R$ 200.000,00',
    config: { color: 'BLUE' },
  },
  {
    header: 'Responsabilidade Civil',
    key: 'civil_responsability',
    width: 21,
    note: 'COBERTURA SÓ HABILITA SE A RESPOSTA PARA PROPRIETÁRIO DO IMÓVEL FOR "NÃO". \n\nPLANO COMERCIAL: Mínimo de R$ 5.000,00 Máximo 50% da básica limitado a R$ 500.000,00 \n\nPLANO RESIDENCIAL: Mínimo de R$ 2.000,00 Máximo 50% da básica limitado a R$ 500.000,00',
    config: { color: 'BLUE' },
  },
  {
    header: 'Impacto de Veículos',
    key: 'vehicle_impacts',
    width: 21,
    note: 'PLANO COMERCIAL: Mínimo de R$ 1.000,00 Máximo 30% da básica limitado a R$ 400.000,00 \n\nPLANO RESIDENCIAL: Mínimo de R$ 1.000,00 Máximo 10% da básica limitado a R$ 200.000,00',
    config: { color: 'BLUE' },
  },
  {
    header: 'Vendaval',
    key: 'gale',
    width: 21,
    note: 'PLANO COMERCIAL: Mínimo de R$ 1.000,00 Máximo 30% da básica limitado a R$ 400.000,00 \n\nPLANO RESIDENCIAL: Mínimo de R$ 1.000,00 Máximo 20% da básica limitado a R$ 200.000,00',
    config: { color: 'BLUE' },
  },
  {
    header: 'Tumulto',
    key: 'riot',
    width: 21,
    note: 'HABILITA SOMENTE PARA PLANO COMERCIAL E Mínimo de R$ 1.000,00 Máximo 10% da básica limitado a R$ 200.000,00',
    config: { color: 'BLUE' },
  },
  {
    header: 'Cláusulas de Serviços (Emergênciais)',
    key: 'contract_services_clauses',
    width: 21,
    note: 'PREENCHIMENTO OBRIGATÓRIO DE ACORDO COM A LISTA SUSPENSA',
    config: { color: 'BLUE' },
  },
  {
    header: 'Cláusula Beneficiária',
    key: 'beneficiary_enabled',
    width: 21,
    note: 'PREENCHIMENTO OBRIGATÓRIO DE ACORDO COM A LISTA SUSPENSA',
    config: { color: 'BLUE' },
  },
  {
    header: 'CPF/CNPJ beneficiário',
    key: 'beneficiary_document',
    width: 21,
    note: 'PREENCHIMENTO OBRIGATÓRIO SOMENTE SE A COLUNA CLÁUSULA BENEFICIÁRIA FOR SIM.',
    config: { color: 'BLUE' },
    style: { numFmt: '@' },
  },
  {
    header: 'Nome social do beneficiário',
    key: 'beneficiary_social_name',
    width: 21,
    note: 'PREENCHIMENTO FACULTATIVO SOMENTE SE A COLUNA CLÁUSULA BENEFICIÁRIA FOR SIM.',
    config: { color: 'BLUE' },
  },
  {
    header: 'Nome de registro do beneficiário',
    key: 'beneficiary_name',
    width: 21,
    note: 'PREENCHIMENTO OBRIGATÓRIO SOMENTE SE A COLUNA CLÁUSULA BENEFICIÁRIA FOR SIM.',
    config: { color: 'YELLOW' },
  },
  {
    header: 'CEP beneficiário',
    key: 'beneficiary_zipcode',
    width: 21,
    note: 'PREENCHIMENTO OBRIGATÓRIO, INSIRA APENAS OS NÚMEROS, SEM PONTUAÇÃO E TRAÇOS SOMENTE SE A COLUNA CLÁUSULA BENEFICIÁRIA FOR SIM.',
    config: { color: 'BLUE' },
    style: { numFmt: '@' },
  },
  {
    header: 'Logradouro beneficiário',
    key: 'beneficiary_street',
    width: 21,
    note: 'PREENCHIMENTO OBRIGATÓRIO SE A COLUNA CLÁUSULA BENEFICIÁRIA FOR SIM.',
    config: { color: 'BLUE' },
  },
  {
    header: 'Número beneficiário',
    key: 'beneficiary_number',
    width: 21,
    note: 'PREENCHIMENTO OBRIGATÓRIO SOMENTE SE A COLUNA CLÁUSULA BENEFICIÁRIA FOR SIM.',
    config: { color: 'BLUE' },
  },
  {
    header: 'Complemento beneficiário',
    key: 'beneficiary_complement',
    width: 21,
    note: 'PREENCHIMENTO OBRIGATÓRIO SOMENTE SE A COLUNA CLÁUSULA BENEFICIÁRIA FOR SIM.',
    config: { color: 'BLUE' },
  },
  {
    header: 'Bairro beneficiário',
    key: 'beneficiary_neighborhood',
    width: 21,
    note: 'PREENCHIMENTO OBRIGATÓRIO SOMENTE SE A COLUNA CLÁUSULA BENEFICIÁRIA FOR SIM.',
    config: { color: 'BLUE' },
  },
  {
    header: '% Desconto Promocional',
    key: 'contract_discount',
    width: 21,
    note: 'PREENCHIMENTO FACULTATIVO',
    config: { color: 'BLUE' },
  },
]

export const brokerDataTable: DataTable[] = [
  {
    header: 'Comissão',
    key: 'contract_operation_code',
    width: 21,
    note: 'PREENCHIMENTO OBRIGATÓRIO.',
    config: { color: 'BLUE' },
  },
  {
    header: 'Pró Labore',
    key: 'contract_prolabore',
    width: 21,
    note: 'PREENCHIMENTO FACULTATIVO',
    config: { color: 'BLUE' },
  },
]

export const Colors = {
  BLUE: {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FF9CC2E5' },
    bgColor: { argb: '00000000' },
  },
  GRAY: {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFDADADA' },
    bgColor: { argb: '00000000' },
  },
  YELLOW: {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFFFFF00' },
    bgColor: { argb: '00000000' },
  },
}
