import React, { useState } from 'react'

import { useFormik, FormikProvider } from 'formik'

import { Grid, Conditional, Content } from '@/components'
import { Actions, Action } from '../../../Details/styles'

import validationSchema from './schema'
import { useContract } from '@/modules'
import Features from './Features'
import Budget from './Budget'

import * as S from './styles'

const Budgets = ({ wizard }) => {
  const contract = useContract()

  const [featuresPlanVisibility, setFeaturesPlanVisibility] = useState(false)

  const form = useFormik({
    initialValues: {
      budget: contract.payload.payment?.budget || '',
    },
    validationSchema,
    onSubmit: (plan) => {
      contract.update({
        payment: { budget: plan.budget },
      })
      wizard.next()
    },
  })

  return (
    <FormikProvider value={form}>
      <Conditional when={featuresPlanVisibility}>
        <Features
          plan={contract.payload.contract?.plan}
          onCancel={() => {
            setFeaturesPlanVisibility(false)
          }}
        />
      </Conditional>

      <Content.Message title="Sucesso" kind="success">
        Muito bom! O PAC foi <b>aprovado</b>.
      </Content.Message>

      <S.Description>
        Verifique as
        <S.Link
          onClick={() => {
            setFeaturesPlanVisibility((state) => !state)
          }}
        >
          características do plano
        </S.Link>
      </S.Description>

      <form onSubmit={form.handleSubmit}>
        <S.Budgets>
          {contract.budgets.map((budget) => (
            <Budget
              id={budget.id}
              title={budget.description}
              type={budget.flag}
              benefits={budget.benefits}
              coverages={budget.coverages}
              quotation={budget.quotation}
              active={budget.id === form.values.budget}
              onClick={() => form.setFieldValue('budget', budget.id)}
            />
          ))}
        </S.Budgets>

        <Conditional when={!!form.errors.budget}>
          <Grid space="1rem 0 0">
            <Content.Message title="Atenção" kind="danger">
              {form.errors.budget}
            </Content.Message>
          </Grid>
        </Conditional>

        <Actions>
          <Action icon="ArrowRight" onClick={() => form.handleSubmit()}>
            Próxima Etapa
          </Action>
        </Actions>
      </form>
    </FormikProvider>
  )
}

export default Budgets
