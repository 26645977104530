import {
    Typography
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import Container from '../Container'
import * as S from './styles'

export const CardBanner: React.FC = () => {
  return (
    <Container>
      <S.Grid>
        <S.TextContainer>
          <Typography
            variant="porto-title-3-semibold"
            sx={{ display: 'block', marginBottom: '8px' }}
          >
            Cartão Porto Bank, um cartão de crédito com todo cuidado Porto
          </Typography>
          <Typography
            variant="porto-text-body-1-bold"
            sx={{ display: 'block', marginBottom: '24px' }}
          >
            Todo cuidado é Porto.
          </Typography>
          <Typography
            variant="porto-text-caption-regular"
            sx={{ display: 'block' }}
          >
            Aprovação sujeita a análise de crédito.{' '}
          </Typography>
        </S.TextContainer>

        <S.Image src="/images/produtos/cartao-porto/cartoes.png" />
      </S.Grid>
      </Container>
  )
}
