import { useEffect } from 'react'

import { Modals } from '@/components'
import { useGTM } from '@/tools'

import { CloseButton, Content } from './styles'
import { Icon } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'

const commonData = {
  event: 'modal',
  nome: 'Termo de aceite',
}

const Terms = (props) => {
  const { setDataLayer } = useGTM()

  useEffect(() => {
    setDataLayer({
      acao: 'abrir',
      ...commonData,
    })
    return () =>
      setDataLayer({
        acao: 'fechar',
        ...commonData,
      })
  }, [])

  return (
    <Modals.Content icon="checkmark-done" title="Termo de Uso">
      <Content>
        <CloseButton onClick={props.onDismiss}>
          <Icon name="close" size="16px" color="system-primitive-blue-500" />
        </CloseButton>
        1 - Autorizo a Porto Seguro consultar dados e informações junto a órgãos
        de Proteção ao Crédito como a SERASA, ACSP, SPC e outros, bem como
        declaro, sob as penas da lei, ter autorização para solicitar a consulta
        em nome todo e qualquer pretendente citado nesse formulário.
        <br />
        <br />
        2 - Declaro estar ciente da possibilidade de recusa em função da análise
        do risco e ou restrições cadastrais, ainda que os requisitos de
        comprometimento e comprovação de renda sejam devidamente atendidos.
        <br />
        <br />3 - Declaro que as informações prestadas são a expressão da
        verdade, pelas quais me responsabilizo.
      </Content>
    </Modals.Content>
  )
}

export default Modals.Promised.createModal(Terms)
