import { pxToRem } from '@/styles/sheets/helpers'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${pxToRem('8px')} ${pxToRem('18px')};
  margin-top: ${pxToRem('24px')};
  text-transform: capitalize;
`
