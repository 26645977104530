import { motion } from 'framer-motion'
import styled from 'styled-components'

export const Container = styled(motion.div).attrs({
  transition: { duration: 0.2 },
  variants: {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  },
  exit: 'exit',
  initial: 'initial',
  animate: 'animate',
})`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;

  width: 100%;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--porto-banking-05);
`
