import styled from 'styled-components'
import { rem } from 'polished'

import {
  Grid as GridComponent,
  Icons,
  Actions as ActionsComponent,
} from '@/components'
import { themes } from '@/styles'

export const Container = styled.div``

export const Content = styled.div``

export const Grid = styled(GridComponent).attrs({
  columns: ['auto', 'repeat(3, 1fr)'],
  gap: ['.5rem', '1rem'],
})``

export const Choices = styled.div`
  > label {
    &:not(:last-of-type) {
      margin-right: 16px;
    }
  }
`

export const Progress = styled.p`
  margin-bottom: 16px;

  font-size: ${rem('14px')};
  color: ${themes.text.medium};
`
