import styled from 'styled-components'
import { rem, rgba } from 'polished'
import media from 'styled-media-query'
import { motion } from 'framer-motion'

import { firebaseMedia } from '@/tools'
import { themes, helpers } from '@/styles'

export const Container = styled(motion.div).attrs({
  initial: 'initial',
  animate: 'animate',
  exit: 'initial',
  variants: {
    initial: {
      opacity: 0,
      transition: {
        duration: 0.8,
        ease: [0.16, 1, 0.3, 1],
      },
    },
    animate: {
      opacity: 1,
      transition: {
        duration: 0.8,
        ease: [0.16, 1, 0.3, 1],
      },
    },
  },
})`
  display: flex;
  flex-flow: column nowrap;
  margin: 0 auto;
  max-width: 600px;
  position: relative;

  &::before {
    background-color: ${themes.palletes.screenBackground};
    bottom: 0;
    content: '';
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: -1;
  }
`

export const LogoPortoBank = styled.img.attrs(() =>({
  src: '/logo-portobank-2023.svg'
}))`
  height: 28px;
`

export const LogoPortoSeguro = styled.img.attrs(() => ({
  src: firebaseMedia('logos/Logos_Porto_White.svg'),
}))`
  height: 38px;

  ${media.lessThan('medium')`
    height: 24px;
  `}
`

export const Close = styled.button`
  all: unset;

  line-height: 0;
  cursor: pointer;
  color: ${themes.page.colors.exit};
`

export const Content = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 32px 0 0;
`

export const ContentExit = styled.div`
  color: ${themes.page.colors.exit};
  margin: 0 8px 0 0;
`

export const List = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(1, 1fr);

  ${media.greaterThan('small')`
    grid-template-columns: repeat(2, 1fr);
  `}
`

export const Item = styled.button`
  all: unset;

  width: 100%;

  padding: 24px 16px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  cursor: pointer;
  transition: 0.3s;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: ${themes.page.colors.content};

  &:hover {
    ${helpers.defaultBoxShadow}
  }

  span {
    display: block;

    font-weight: 600;

    &:first-of-type {
      min-height: 60px;

      font-size: ${rem('14px')};
      color: ${themes.text.higher};
    }

    &:last-of-type {
      font-size: ${rem('12px')};
      color: ${themes.palletes.primary};
    }
  }

  &:disabled {
    background: var(--neutras-black-15, #E0E0E0);
    padding: 16px;
    cursor: default;

    span {
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 17px;
      letter-spacing: -0.14px;

      &:first-of-type {
        color: var(--neutras-black-60, #999);
      }
      &:last-of-type {
        color: var(--neutras-black-65, #808080);
      }
    }

    div {
      color: var(--paleta-estendida-red-red-100, #842310);
      font-family: Open Sans;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 0.1px;
    }
  }
`

export const Denied = {
  Wrap: styled.div``,

  Title: styled.h1`
    margin-top: 20px;

    color: #fff;
  `,

  Description: styled.p`
    margin-top: 8px;

    color: ${rgba('#fff', 0.85)};
    font: 500 ${rem('14px')} ${themes.fonts.title};
  `,

  Actions: styled.div`
    margin-top: 40px;
  `,

  Footer: styled.div`
    text-align: center;
    margin-top: 8px;

    color: ${rgba('#fff', 0.85)};
    font: 500 ${rem('14px')} ${themes.fonts.title};
  `,
}

export const Header = styled.div`
  letter-spacing: -0.16px;
  margin: 26px 0 0;
  text-align: center;
`

export const Invites = {
  Wrap: styled.div`
    padding-top: 40px;

    border-top: 1px solid ${rgba('#FFF', 0.3)};
  `,

  List: styled(List)`
    margin-top: 24px;

    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(2, 1fr);
  `,

  Actions: styled.div`
    margin-top: 24px;

    display: grid;
    grid-gap: 8px;
    grid-template-columns: repeat(2, 1fr);
  `,
}

export const TopHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 56px 0 0;
  position: relative;
  width: 100%;
`

export const SpanHeader = styled.span`
  font-size: 0.8888888888888888em;
  font-weight: 700;
`

export const WrapperExit = styled.div`
  cursor: pointer;
  display: flex;
`
