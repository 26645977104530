import { useHistory } from 'react-router-dom'

import { Content, Actions } from '@/components'

import * as S from './styles'

const Error = () => {
  const history = useHistory()

  return (
    <S.Container>
      <Content.Heading
        title="Ocorreu um erro"
        subtitle="Esta página que você tentou acessar não existe ou está fora do ar."
      />

      <Actions.Button icon="arrow-left" onClick={() => history.goBack()}>
        Voltar a página anterior
      </Actions.Button>
    </S.Container>
  )
}

export default Error
