import styled from 'styled-components'
import media from 'styled-media-query'
import { size, rgba } from 'polished'

import { themes } from '@/styles'

import ActionComponent from '../../Actions'

export const Container = styled.div`
  display: flex;
  align-items: center;
`

export const Picture = styled.figure`
  ${size('60px')}

  position: relative;

  overflow: hidden;

  border-radius: 8px;
  box-shadow: 0 6px 10px ${rgba('#000', 0.1)};
  border: 1px solid ${themes.page.colors.background};

  ${media.greaterThan('huge')`
    ${size('80px')}
  `}

  ${media.lessThan('medium')`
    ${size('70px')}
  `}

  &:hover {
    &:before {
      display: flex;
    }
  }
`

export const Image = styled.img`
  width: 100%;
  height: 100%;

  object-fit: contain;
`

export const Actions = styled.div`
  margin-left: 8px;

  flex: 1;

  ${media.greaterThan('huge')`
    margin-left: 16px;
  `}
`

export const Upload = styled.label``

export const Input = styled.input.attrs(() => ({
  type: 'file',
  accept: 'image/*',
}))<any>`
  display: none;
`

export const Button = styled(ActionComponent.Button).attrs(() => ({
  forwardedAs: 'a',
  size: 'small',
  variant: 'action',
}))`
  font-size: 12px;

  &:not(:first-child) {
    margin-left: 8px;
  }

  ${media.lessThan('medium')`
    width: 70%;
    height: 40px;

    &:not(:first-child) {
      margin-top: 8px;
    }
  `}
`
