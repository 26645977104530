import { pxToRem } from '@/styles/sheets/helpers'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import styled, { css } from 'styled-components'
import { Chevron, Label } from '../Button/styles'
import { Container as ButtonContainer } from '../Button/styles'
import breakpoints from '@pol-npm/riscos-financeiros-dashboard-ui/dist/theme/breakpoints'
import { LAYOUT } from '@/constants'

const { CLOSED_MENU_WIDTH, HEADER_HEIGHT, OPENED_MENU_WIDTH } = LAYOUT
const MENU_HORIZONTAL_PADDING = 8
const CONTENT_MARGIN_TOP = 16

export const Footer = styled(Typography)`
  padding: ${pxToRem(8)};
  white-space: nowrap;
  transition: opacity 0.3s;
`

export const Title = styled.div`
  transition: opacity 0.3s;
  padding: ${pxToRem(8)};
`

export const Divider = styled.div`
  border-top: ${pxToRem(1)} solid var(--neutras-black-15, #e0e0e0);
  margin: ${pxToRem(4)} 0;
  transition: width 0.3s;
  width: calc(
    ${pxToRem(OPENED_MENU_WIDTH)} - ${pxToRem(MENU_HORIZONTAL_PADDING * 2)}
  );
`

export const Content = styled.div<{ $isJoyrideRunning: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(4)};
  padding: 0 ${pxToRem(0)} ${pxToRem(16)} ${pxToRem(8)};
  margin-top: ${pxToRem(CONTENT_MARGIN_TOP)};
  width: ${pxToRem(OPENED_MENU_WIDTH)};
  height: calc(100vh - ${pxToRem(HEADER_HEIGHT + CONTENT_MARGIN_TOP + 32)});
  // Joyride scroll override (remover quando o joyride nao for mais necessario)
  overflow-y: ${(props) =>
    props.$isJoyrideRunning ? 'scroll' : 'scroll !important'};

  &::-webkit-scrollbar {
    width: ${pxToRem(8)};
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #8c9cc1;
    border-radius: ${pxToRem(8)};
    border: ${pxToRem(2)} solid #f4f5fb;
  }
`

export const WidthTransitionContainer = styled.div<{
  $isOpen: boolean
  $isJoyrideRunning: boolean
}>`
  bottom: 0;
  // Joyride scroll override (remover quando o joyride nao for mais necessario)
  overflow-x: ${(props) =>
    props.$isJoyrideRunning ? 'hidden' : 'hidden !important'};
  position: fixed;
  top: 0;
  transition: width 0.3s, margin-left 0.3s;
  width: ${pxToRem(CLOSED_MENU_WIDTH)};
  z-index: 7;

  @media (max-width: ${breakpoints.sm}) {
    margin-left: ${(props) =>
      props.$isOpen ? 0 : pxToRem(-OPENED_MENU_WIDTH)};
    width: ${pxToRem(OPENED_MENU_WIDTH)};
  }

  ${(props) =>
    props.$isOpen
      ? css`
          margin-left: 0;
          width: ${pxToRem(OPENED_MENU_WIDTH)};
        `
      : css`
          ${Label}, ${Title}, ${Footer}, ${Chevron} {
            opacity: 0;
          }

          ${ButtonContainer} {
            background-color: initial;
          }

          ${Content} {
            &::-webkit-scrollbar-thumb {
              background-color: transparent;
              border-color: transparent;
            }
          }

          ${Divider} {
            width: calc(
              ${pxToRem(CLOSED_MENU_WIDTH)} -
                ${pxToRem(MENU_HORIZONTAL_PADDING * 2)}
            );
          }
        `}
`

export const Container = styled.div`
  background-color: #f4f5fb;
  border-right: ${pxToRem(1)} solid var(--neutras-black-15, #e0e0e0);
`

export const Header = styled.div`
  align-items: center;
  border-bottom: ${pxToRem(1)} solid var(--neutras-black-15, #e0e0e0);
  display: flex;
  justify-content: space-between;
  padding: ${pxToRem(8)} ${pxToRem(16)};
`

export const Logo = styled.img<{ $hidden: boolean }>`
  height: ${pxToRem(24)};
  visibility: ${(props) => (props.$hidden ? 'hidden' : 'visible')};
`

export const Name = styled(Typography)<{ $hidden: boolean }>`
  width: min-content;
  visibility: ${(props) => (props.$hidden ? 'hidden' : 'visible')};
`

export const Overlay = styled.div`
  display: none;

  @media (max-width: ${breakpoints.sm}) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #0009;
    z-index: 6;
  }
`
