import { useMemo, useState } from 'react'

import { toast } from '@/components'
import { api } from '@/services'

import { useOnboarding } from './../../context'
import { juridicalPersonSchema, legalPersonSchema } from './../../schema'
import { OnboardingWrapper } from './../../ui'

import CityRegistrationModal from './JuridicalPersonForm/CityRegistrationModal'
import JuridicalPersonForm from './JuridicalPersonForm'
import LegalPersonForm from './LegalPersonForm'
import { isCNPJ } from 'brazilian-values'

const RealEstateData = ({ wizard, currentStep }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [cityRegistrationModalIsOpen, setCityRegistrationModalIsOpen] =
    useState(false)

  const onboarding = useOnboarding()

  const { attributes } = onboarding.state.form

  const isJuridicalPerson = useMemo(() => {
    return isCNPJ(onboarding.state.form.attributes.policyholder.document)
  }, [onboarding])

  const handleCityRegistrationModalAccept = () => {
    setCityRegistrationModalIsOpen(false)
    const element: HTMLInputElement = document.querySelector(
      `[name="policyholder.cityRegistration"]`
    )
    element?.focus()
  }

  const submitForm = async (values) => {
    try {
      setIsLoading(true)

      onboarding.dispatch({
        type: 'UPDATE_FORM_ATTRIBUTES',
        payload: values,
      })

      const data = await api.users.newAccount.createOrUpdateLead(
        onboarding.state.form
      )

      onboarding.dispatch({
        type: 'UPDATE_FORM_FROM_API',
        payload: data,
      })

      wizard.next()
    } catch (e) {
      toast(
        `
          Ocorreu um erro inesperado. Por favor, tente novamente...
        `,
        {
          type: 'error',
        }
      )
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <OnboardingWrapper
      currentStep={currentStep}
      wizard={wizard}
      isLoading={isLoading}
      formData={{
        initialValues: attributes,
        validationSchema: isJuridicalPerson
          ? juridicalPersonSchema
          : legalPersonSchema,
        onSubmit: async (values) => {
          if (isJuridicalPerson && !values.policyholder.cityRegistration) {
            setCityRegistrationModalIsOpen(true)
            return
          }

          submitForm(values)
        },
      }}
      children={({ form }) => (
        <>
          {isJuridicalPerson && <JuridicalPersonForm form={form} />}

          {!isJuridicalPerson && <LegalPersonForm form={form} />}

          <CityRegistrationModal
            onAccept={handleCityRegistrationModalAccept}
            onDecline={() => submitForm(form.values)}
            isOpen={cityRegistrationModalIsOpen}
          />
        </>
      )}
    />
  )
}

export default RealEstateData
