import * as S from './styles'

const Banner = ({
  image,
  url,
  title,
  subtitle,
  button,
}: {
  image: string
  url: string
  title: string
  subtitle: string
  button: {
    label: string
    outlined?: boolean
  }
}) => {
  return (
    <S.BannerContainer href={url} target="_blank" rel="noopener noreferer">
      <S.Wrapper>
        <S.Title
          color="porto-primitive-white"
          variant="porto-title-3-bold"
          content={title}
        />
        <S.Subtitle
          color="porto-primitive-white"
          variant="porto-text-body-2-regular"
          content={subtitle}
        />
        <S.Action $outlined={button.outlined}>
          <S.Label
            color={
              button.outlined
                ? 'porto-primitive-white'
                : 'system-primitive-blue-500'
            }
            variant="porto-button"
            content={button.label}
          />
        </S.Action>
      </S.Wrapper>
      <S.Image src={image} />
    </S.BannerContainer>
  )
}

export default Banner
