import React from 'react'

import { Forms, Grid } from '@/components'

const Owner = () => {
  return (
    <Forms.Card step="2" title="Responsável" active>
      <Grid columns={['repeat(2, 1fr)', 'repeat(3, 1fr)']} gap="1rem">
        <Forms.InputGroup
          name="owner.document"
          label="CPF/CNPJ"
          placeholder="Digite o CPF ou CNPJ"
          mask="document"
        />

        <Forms.InputGroup
          name="owner.name"
          label="Nome"
          placeholder="Digite o nome"
        />

        <Forms.InputGroup
          name="email"
          label="E-mail"
          placeholder="Digite o e-mail"
        />
      </Grid>
    </Forms.Card>
  )
}

export default Owner
