import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { themes } from '@/styles'
import { rem } from 'polished'
import { FeatherIcons, Ionicons } from '@/components/Icons'

interface Props {
  erro?: boolean
}

export const Container = styled.div``

export const ContainerInfo = styled.div`
  background-color: #ffff;
  color: #444;
  padding: 24px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  border-radius: 8px;
`

export const ContainerTable = styled.div`
  margin-top: 1rem;
`

export const Value = styled.span`
  display: block;
  margin-top: 8px;

  font-size: 16px;
  color: ${themes.text.high};
`

export const Label = styled.span`
  display: block;

  font-size: ${rem('14px')};
  color: ${themes.text.low};
`

export const InfoContent = styled.p``

export const ItemWrapper = styled.div`
  display: flex;
  align-items: baseline;

  &:not(:last-of-type) {
    margin-right: 56px;
  }
`

export const LabelsErrors = styled.div<Props>`
  color: ${(props) => (props.erro ? '#842310' : '#034D2E')};
`

export const Icon = styled(FeatherIcons && Ionicons)<Props>`
  height: 24px;
  display: flex;
  align-items: center;
  margin-right: 4px;
  color: ${(props) => (props.erro ? '#842310' : '#034D2E')};
  font-size: 24px;

  ${media.greaterThan('huge')`
    height: 24px;
  `}
`
