import styled from 'styled-components'

export const Table = styled.table`
  width: fit-content;
`

export const Header = styled.thead`
  background: var(--system-surface-tertiary);
`

export const Body = styled.tbody``

export const Row = styled.tr``

export const Cell = styled.td`
  padding: 8px;
`
