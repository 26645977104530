

import { Content } from '@/components'
import { SvgIcons } from '@/components/SvgIcons'
import { useCurrentOrganization } from '@/modules'
import { themes } from '@/styles'

import Box from '../components/Box'
import Card from '../components/Card'

import { Container, List } from './styles'
import { Labels } from './utils'
import downloadBatch from './download-batch-sheet'



const BatchBudget = () => {
  const profile = useCurrentOrganization()
  const isBroker = profile.type === 'BROKER'

  return (
    <Container>
      <Content.Heading
        title="Orçamento em lote do Seguro Incêndio"
        subtitle="Siga as etapas abaixo para realizar o seu o orçamento em lote, também conhecido como Portabilidade de carteira."
      />
      <List>
        <Box
          color={themes.palletes.primary}
          actions={() => downloadBatch({ isBroker })}
          to="/orcamento"
          icon={SvgIcons.downLoad}
          title="Baixar planilha modelo"
          description="Já tem todos os dados dos seus clientes? Insira em planilha modelo."
        />
        <Box
          color={themes.palletes.primary}
          to="/orcamento/novo"
          icon={SvgIcons.paperRefresh}
          title="Calcular orçamento em lote"
          description="Já tem as informações do cliente em planilha modelo? Então insira no Portal."
        />
        <Box
          color={themes.palletes.primary}
          to="/orcamento/consulta"
          icon={SvgIcons.consultBudget}
          title="Consultar status do orçamento"
          description="Já inseriu a planilha no Portal? Veja aqui o status de todas as solicitações."
        />
      </List>
      <Card
        title="Como solicitar um orçamento em lote?"
        children={Labels}
        background="white"
        border="8px"
      />
    </Container>
  )
}

export default BatchBudget
