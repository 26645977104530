import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import theme from 'styled-theming'
import { rem, rgba } from 'polished'
import { motion } from 'framer-motion'

import { themes, layers } from '@/styles'

import { Ionicons, Loader as LoaderComponent } from '../../Icons'

type Props = {
  visible: boolean
}

export const Field = styled.div`
  position: relative;
`

export const Loader = styled(LoaderComponent)`
  position: absolute;
  bottom: 15px;
  right: 16px;

  ${media.greaterThan('huge')`
    bottom: 20px;
  `}
`

export const Dropdown = {
  Wrapper: styled(motion.div)`
    position: absolute;
    top: 63px;
    left: 0;
    z-index: ${layers.autoCompleteDropdown};

    width: 100%;
    padding: 12px;
    overflow-y: auto;

    border-radius: 0 0 8px 8px;
    border: 1px solid ${themes.utilities.border};
    box-shadow: 0 20px 25px -5px ${rgba('#000', 0.05)};

    background-color: ${theme('mode', {
      light: '#fbfeff',
      dark: '#1c1f25',
    })};

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${themes.text.low};
    }

    ${media.greaterThan('huge')`
      top: 81px;
    `}
  `,

  Item: styled.button.attrs(() => ({ type: 'button' }))`
    all: unset;

    width: 100%;
    padding: 12px 16px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    cursor: pointer;
    font-weight: 600;
    border-radius: 8px;
    box-sizing: border-box;
    font-size: ${rem('10px')};
    font-family: ${themes.fonts.text};

    color: ${themes.text.medium};
    -webkit-text-fill-color: ${themes.text.medium};

    ${media.greaterThan('huge')`
      font-size: ${rem('13px')};
    `}

    &:hover {
      background-color: #eff4f6;

      color: ${themes.text.higher};
      -webkit-text-fill-color: ${themes.text.higher};

      > div {
        opacity: 1;
        transition: 0.15s;
      }
    }

    > div {
      transform: translateY(2px);

      display: flex;
      align-items: center;

      opacity: 0;
      font-size: 20px;
      transition: 0.15s;
      color: ${themes.text.low};

      &:before {
        margin-right: 8px;

        content: 'Selecionar';
        font-size: ${rem('12px')};
        color: ${themes.text.low};
        -webkit-text-fill-color: ${themes.text.low};
      }
    }
  `,

  Icon: styled(Ionicons).attrs(() => ({ name: 'push' }))``,

  Empty: styled.p`
    font-weight: 600;
    font-size: ${rem('10px')};
    color: ${themes.text.medium};
    font-family: ${themes.fonts.text};

    ${media.greaterThan('huge')`
      font-size: ${rem('13px')};
    `}
  `,
}

export const Container = styled.div<Props>`
  position: relative;

  ${({ visible }) =>
    visible &&
    css`
      ${Field} {
        > input {
          border-radius: 8px 8px 0 0;
        }
      }
    `}
`
