//export planilha excel (json to xlsx)
import XLSX from 'xlsx'

const Export = (data) => {
  const worksheet = XLSX.utils.json_to_sheet(data)

  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet)

  XLSX.writeFile(workbook, 'Sem Título.xlsx')
}

export default Export
