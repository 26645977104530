import styled from 'styled-components'
import breakpoints from '@pol-npm/riscos-financeiros-dashboard-ui/dist/theme/breakpoints.js'
import { centeredContainer } from '@/styles/mixins'
import { pxToRem } from '@/styles/sheets/helpers'
import { LAYOUT } from '@/constants'

const { CLOSED_MENU_WIDTH } = LAYOUT

export const Content = styled.div`
  ${centeredContainer}

  padding: ${pxToRem(84)} ${pxToRem(30)};
`

export const Main = styled.main`
  margin-left: auto;
  margin-top: ${pxToRem(80)};

  @media (min-width: ${breakpoints.lg}) {
    margin-top: 0;
    width: calc(100% - ${pxToRem(CLOSED_MENU_WIDTH)});
  }

  @media (min-width: ${breakpoints.md}) {
    margin-left: ${pxToRem(`${CLOSED_MENU_WIDTH}px`)};
  }

  @media (min-width: 820px) {
    margin-left: ${pxToRem(`${CLOSED_MENU_WIDTH}px`)};
  }
`
