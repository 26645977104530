import { useContract } from '@/modules'
import { Flex, Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Button } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { Icon as SystemIcon } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/system'
import { rem } from 'polished'
import { useHistory } from 'react-router-dom'
import * as S from './styles'

export const PACErrorOfferCapitalizacao = () => {
  const history = useHistory()
  const contract = useContract()

  const handleRedirect = () => {
    const values = {
      payload: {
        customer: {
          birthdate: contract.payload.customers[0].birthdate,
          document: contract.payload.customers[0].document,
          email: contract.payload.customers[0].email,
          name: contract.payload.customers[0].name,
          socialName: contract.payload.customers[0].socialName,
          phone: contract.payload.customers[0].phone,
        },
        contract: {
          monthly_value: contract.payload.coverages.rent,
        },
      },
    }

    return history.push({
      pathname: '/contratos/novo/capitalizacao',
      state: {
        type: 'essencial-pac-refused',
        contract: values,
      },
    })
  }

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        gap: '1rem',
      }}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          backgroundColor: 'system-primitive-neutral-50',
          padding: '1rem',
          borderRadius: '8px',
          alignItems: 'flex-start',
        }}
      >
        <Flex
          sx={{
            flexDirection: 'grid',
            gap: '.5rem',
          }}
        >
          <div style={{ fontSize: rem('30px') }}>
            <SystemIcon name="alert" color="porto-banking-65" />
          </div>
          <Typography
            content="Não é possível seguir com a contratação do Fiança Locatícia"
            variant="porto-title-6-bold"
            sx={{
              marginTop: rem('4px'),
              fontSize: rem('16px'),
              lineHeight: rem('20px'),
              paddingLeft: rem('7px'),
            }}
          />
        </Flex>
        <Flex
          sx={{
            flexDirection: 'column',
            paddingLeft: rem('45px'),
          }}
        >
          <S.RefusedTextContainer>
            <Typography
              content="O cliente não foi aprovado na análise de crédito. Confira abaixo uma oportunidade que dispensa análise."
              variant="porto-text-body-2-regular"
              color="porto-primitive-black-85"
              sx={{
                fontSize: rem('14px'),
                lineHeight: rem('20px'),
              }}
            />
          </S.RefusedTextContainer>
        </Flex>
      </Flex>

      <S.ProposalContainer>
        <Flex
          sx={{
            flexDirection: 'column',
            gap: '1.2rem',
            alignItems: 'flex-start',
          }}
        >
          <Typography
            content="TÍTULO DE CAPITALIZAÇÃO"
            variant="porto-text-body-2-bold"
            color="porto-primitive-black-70"
          />
          <Typography
            content="Aproveite e faça uma proposta, sem comprovação de renda"
            variant="porto-title-6-bold"
          />
          <Typography
            content="O Título de Capitalização é uma reserva financeira, com valor acordado entre o inquilino e o proprietário, usada como garantia na locação. E o melhor: não precisa comprovar renda e o inquilino ainda recebe até 100% do valor no final do contrato."
            variant="porto-text-body-2-regular"
            color="porto-primitive-black-85"
          />
        </Flex>

        <S.KeyImageContainer>
          <S.KeyImage src="/images/keys.svg" alt="Porto Seguro Bank" />
        </S.KeyImageContainer>

        <Button onClick={handleRedirect}>Fazer proposta</Button>
      </S.ProposalContainer>
    </Flex>
  )
}
