import { useField } from 'formik'
import { AnimatePresence } from 'framer-motion'
import React, { InputHTMLAttributes, useCallback, useEffect, useMemo, useState } from 'react'

import Forms from '../index'

import { addons, theMask } from './mask'

import { IInputGroupProps } from './interfaces'

import { Tooltip } from '@/components/Utilities'
import dayjs from 'dayjs'
import MaskedInput from 'react-text-mask'
import customMasks from './ApplicationMask'
import { Container, Field, Icon, Icons, Loader, Message, Toggle } from './styles'

const masks = {
  document: ['###.###.###-##', '##.###.###/####-##'],
  phone: ['(++) #####-####', '(++) ####-####'],
  cellphone: ['(++) #####-####'],
  cpf: '###.###.###-##',
  cnpj: '##.###.###/####-##',
  creditCard: '#### #### #### ####',
  zipcode: '#####-###',
  date: '##/##/####',
  shortDate: '##/####',
  susep: 'xxxxxx',
  cvv: '####',
  integer: '+###############',
} as const

const InputGroup: React.FC<IInputGroupProps> = ({
  size,
  maskGuide,
  resetValueOnUnmount,
  ...props
}) => {
  const [field, meta, { setValue: setFieldValue }] = useField(props.name)
  const [inputType, setInputType] = useState(props.type)

  const customMask = useMemo(() => {
    if (!props.mask) return

    const mask = customMasks.get(props.mask)

    return mask
  }, [props.mask])

  useEffect(() => {
    /**
     * Aplica máscara sempre que houver uma alteração no valor do input.
     * ? É feito dessa forma para que a máscara seja aplicada no valor inicial do formulário
     * ? e sempre que o valor mudar
     */

    if (typeof field.value === 'string' && customMask) {
      setFieldValue(customMask.applyMask(field.value))
    }
  }, [customMask, field.value, setFieldValue])

  const handleBlur: InputHTMLAttributes<HTMLInputElement>['onBlur'] = (...params) => {
    field.onBlur(...params)

    if (props.onBlur) props.onBlur(...params)
  }

  const handleChange: InputHTMLAttributes<HTMLInputElement>['onChange'] = (...params) => {
    // Chamamos o onChange do Formik para persistir o estado nele
    field.onChange(...params)

    // Chamamos o onChange passado ao componente para aplicar as alterações que o usuário deseja
    if (props.onChange) props.onChange(...params)
  }

  const autoCompleteOffWhen = ['zipcode'].includes(props.mask) ? 'off' : 'on'

  const handlePasswordVisibility = () => {
    setInputType(state => (state === 'password' ? 'text' : 'password'))
  }

  const handleMask = useCallback(
    value => {
      if (!masks[props.mask] || !value) {
        return false
      }

      if (props.mask === 'document' && value.replace(/\D/g, '').length > 12) {
        return theMask(value, masks[props.mask][1] || props.mask)
      }

      return theMask(value, masks[props.mask] || props.mask)
    },
    [props.mask],
  )

  const maskProps = useMemo(() => {
    if (customMask) return

    return {
      mask:
        {
          money: addons.money,
          percentage: addons.percentage,
        }[props.mask] || handleMask,

      pipe:
        {
          date: addons.date,
          shortDate: addons.shortDate,
        }[props.mask] || false,
    }
  }, [customMask, handleMask, props.mask])

  const parseValue = useMemo(() => {
    if (props.mask === 'date' && dayjs(field.value, 'YYYY-MM-DD').isValid()) {
      return dayjs(field.value, 'YYYY-MM-DD').format('DD/MM/YYYY')
    }

    return field.value
  }, [field.value, props.mask])

  return (
    <Container size={size}>
      {props.label && <Forms.Label showRequired={props.showRequired}>{props.label}</Forms.Label>}

      <Field>
        <Forms.Input
          {...props}
          {...field}
          {...maskProps}
          as={!!props.mask && !customMask && MaskedInput}
          value={parseValue}
          type={inputType}
          guide={maskGuide}
          onBlur={handleBlur}
          onChange={handleChange}
          disabled={props.readOnly}
          keepCharPositions={true}
          autoComplete={autoCompleteOffWhen}
          error={(meta.touched && !!meta.error) || undefined}
        />

        {props.loading && <Loader />}

        {props.action && (
          <Toggle onClick={props.action.handler}>
            <Icon name={props.action.icon} />
          </Toggle>
        )}

        {props.type === 'password' && field.value.length > 0 && (
          <Toggle onClick={handlePasswordVisibility}>
            <Icon name={inputType === 'password' ? 'eye' : 'eye-off'} />
          </Toggle>
        )}
        {props.tip && (
          <Icons>
            <Tooltip placement="top" content={props.tip}>
              <Icon name="info" />
            </Tooltip>
          </Icons>
        )}
      </Field>
      <AnimatePresence>
        {meta.touched && !!meta.error && <Message kind="danger">{meta.error}</Message>}
      </AnimatePresence>
    </Container>
  )
}

InputGroup.defaultProps = {
  type: 'text',
  maskGuide: false,
  resetValueOnUnmount: true,
}

export default InputGroup
