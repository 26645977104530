import { BRLMoneyToNumber, onlyNumbers } from '@/tools'
import { isCEP, isCNPJ, isCPF, isCPFOrCNPJ, isPhone, parseToNumber } from 'brazilian-values'
import dayjs from 'dayjs'
import * as yup from 'yup'

export function parseToNumericValue(value: string) {
  const numericValue = Number(value?.replace('%', '').replace(',', '') || '0')

  return numericValue
}

const address = yup.object({
  zipcode: yup
    .string()
    .min(9, 'Por favor insira um CEP válido.')
    .test({
      message: 'Por favor insira um CEP válido.',
      test: value => !!value && isCEP(value),
    })
    .required('Campo CEP é obrigatório.'),
  street: yup.string().required('Por favor confirme a rua.'),
  number: yup.string().required('Campo número é obrigatório.'),
  complement: yup.string(),
  neighborhood: yup.string().required('Por favor confirme o bairro.'),
  city: yup.string().required('Por favor confirme a cidade.'),
  state: yup.string().required('Por favor confirme o estado.'),
  type: yup.string().required('Tipo de endereço é obrigatório'),
})

const socios = yup.object({
  document: yup
    .string()
    .test({
      test: value => !!value && isCPFOrCNPJ(value),
      message: 'Documento inválido',
    })
    .trim(),
  name: yup.string().required('Nome do sócio é obrigatório').trim(),
  role: yup
    .string()
    .required('Papel do sócio é um campo obrigatório')
    .equals(['39', '30', '24'], 'Selecione um papel para esse sócio'),
  stake: yup
    .string()
    .transform((value: string) => parseToNumber(value.replace('%', '') || '0').toString())
    .required('É obrigatório informar a participação do sócio')
    .test('min', 'O sócio precisa ter ao menos 0,01% de participação', value => {
      const numericValue = parseToNumericValue(value || '0')

      return !(numericValue < 0.01)
    })
    .test('max', 'O sócio não pode ter mais que 100% de participação', value => {
      const numericValue = parseToNumericValue(value || '0')

      return !(numericValue > 100)
    }),
  address,
})

export type SociosCapitalizacao = yup.TypeOf<typeof socios>

function transformRevenue<T = unknown>(value: T) {
  const trueValue = value || 0

  if (typeof trueValue === 'number') return (trueValue || '').toString()

  if (typeof value === 'string') {
    const parsedValue = parseToNumber(value.replace('R', '').replace('$', '').replace(' ', ''))
    return (parsedValue || '').toString()
  }

  return value
}

export const firstStepSchema = yup.object().shape({
  susep: yup.string().required('Campo Susep - Corretora de Seguros é obrigatório.'),
  partner: yup.string().required('Campo Imobiliaria - Corretora de Seguros é obrigatório.'),
  customer: yup
    .object({
      name: yup.string().required('Campo nome é obrigatório.'),
      document: yup
        .string()
        .required('Campo obrigatório.')
        .test(
          'document-test',
          'Por favor insira um documento válido.',
          value => !!value && isCPFOrCNPJ(value),
        )
        .test(
          'document-test',
          'O documento deve ser diferente do documento do locador.',
          (value, context) => {
            const landlordDocument = context.options.context.landlordDocument
            const customerDocument = onlyNumbers(value)

            if (!!landlordDocument) {
              return customerDocument !== landlordDocument
            }

            return true
          },
        ),
      phone: yup
        .string()
        .required('Campo telefone/celular é obrigatório.')
        .test('phone', 'Informe um número válido', value => !!value && isPhone(value)),
      email: yup
        .string()
        .email('Por favor insira um e-mail válido.')
        .required('Campo e-mail é obrigatório.'),
    })
    .when({
      is: value => !!value?.document && isCPF(value?.document),
      then: schema => {
        return schema.shape({
          birthdate: yup
            .string()
            .required('Data de nascimento obrigatório.')
            .test('invalid-date', 'Data de nascimento inválida.', value => {
              if (!value?.length) return false

              const formattedDate = value.split('/').reverse().join('-')
              const date = new Date(formattedDate)

              if (!dayjs(date).isValid()) return false

              return dayjs(date).isBefore(dayjs()) && dayjs(date).year() >= 1900
            }),
          pep: yup.object().shape({
            name: yup.string().test('required', 'Campo nome é obrigatório.', (value, context) => {
              if (context.parent.expose_person_flag === 'NO_BUT_CLOSE_RELATIONSHIP') {
                return value?.length > 1
              }

              return true
            }),
            document: yup
              .string()
              .test('required', 'Campo documento é obrigatório.', (value, context) => {
                if (context.parent.expose_person_flag === 'NO_BUT_CLOSE_RELATIONSHIP') {
                  return value?.length > 0
                }

                return true
              })
              .test('document-test', 'Por favor insira um CPF válido.', (value, context) => {
                if (context.parent.expose_person_flag === 'NO_BUT_CLOSE_RELATIONSHIP') {
                  return !!value && isCPF(value)
                }

                return true
              }),
          }),
          occupation: yup.string().required('Campo profissão é obrigatório.'),
          nationality: yup.string().required('Campo nacionalidade é obrigatório.'),
          complement_document: yup
            .string()
            .matches(/^[a-zA-Z0-9]+$/, 'Apenas letras ou números.')
            .min(4, 'Digite um número de documento válido')
            .max(20, 'Digite um número de documento válido')
            .required('Campo número do documento é obrigatório.'),
          complement_document_emission: yup
            .string()
            .required('Data de emissão obrigatória.')
            .test('invalid-date', 'Data de emissão inválida.', value => {
              if (value?.length !== 10) return false
              const formattedDate = value.split('/').reverse().join('-')
              const date = new Date(formattedDate)

              if (!dayjs(date).isValid()) return false

              return dayjs(date).isBefore(dayjs()) && dayjs(date).year() >= 1900
            }),
          complement_document_issuer: yup
            .string()
            .matches(/^[a-zA-Z]+$/, 'Apenas letras.')
            .min(1, 'Informe um orgão emissor válido')
            .max(20, 'Informe um orgão emissor válido')
            .required('Campo orgão emissor é obrigatório.'),
          country_complement_document_id: yup
            .string()
            .test('conditionally-required', 'Campo país é obrigatório.', (value, context) => {
              if (context.parent.complement_document_type_id === 'RNE') {
                return value?.length > 0
              }

              return true
            }),
        })
      },
    })
    .when({
      is: value => !!value?.document && isCNPJ(value?.document),
      then: schema => {
        return schema
          .shape({
            municipalRegistration: yup
              .string()
              .min(5, 'O campo não pode ter menos que 5 caracteres')
              .max(20, 'O campo não pode ter mais que 20 caracteres')
              .required('Inscrição municipal é um campo obrigatório'),
            revenue: yup
              .string()
              .transform(transformRevenue)
              .test({
                message: 'Valor de faturamento acima do permitido',
                test: value => {
                  const parsedValue = transformRevenue(value)

                  const numericValue = Number(parsedValue)

                  if (numericValue > 10000000000000) return false

                  return true
                },
              })
              .required('É necessário informar o faturamento da empresa'),
            socios: yup
              .array()
              .of(socios)
              .nullable()
              .test({
                test: (value, context) => {
                  const total = value?.reduce((currentValue, socio) => {
                    const numericValue = parseToNumericValue(socio?.stake || '0')

                    return currentValue + numericValue
                  }, 0)

                  if (total < 100) {
                    if (value.length < 2)
                      return context.createError({
                        message:
                          'Para empresas que têm um único sócio, informe a participação de 100% para ele. Se houver mais sócios, você deverá adicioná-los abaixo, ajustando a participação para cada um deles.',
                        path: 'stake',
                      })

                    return context.createError({
                      message:
                        'Para empresas com dois ou mais sócios, você deverá adicionar cada um deles, ajustando suas participações até que totalizem 100%.',
                      path: 'stake',
                    })
                  }

                  return true
                },
              })
              .test({
                test: (value, context) => {
                  const total = value?.reduce((currentValue, socio) => {
                    const numericValue = parseToNumericValue(socio?.stake || '0')

                    return currentValue + numericValue
                  }, 0)

                  if (total > 100) {
                    const path = `customer.socios.${value.length - 1}.stake`

                    return context.createError({
                      message: 'Ajuste as participações para que o total não ultrapasse 100%.',
                      path,
                    })
                  }

                  return true
                },
              })
              .test({
                test: (value, context) => {
                  if (typeof value?.length === 'number' && value?.length < 2) return true

                  const total = value?.reduce((currentValue, socio) => {
                    const numericValue = parseToNumericValue(socio?.stake || '0')

                    return currentValue + numericValue
                  }, 0)

                  if (total > 100) {
                    return context.createError({
                      message:
                        'Para empresas com dois ou mais sócios, você deverá adicionar cada um deles, ajustando suas participações até que totalizem 100%.',
                      path: 'stake',
                    })
                  }

                  return true
                },
              })
              .test({
                test: (value, context) => {
                  const errorIndex = value?.findIndex((findValue, findIndex, customersArray) => {
                    const duplicateIndex = customersArray.findIndex(
                      value => value?.document === findValue?.document,
                    )

                    return findIndex !== duplicateIndex
                  })

                  if (errorIndex > -1) {
                    return context.createError({
                      path: `customer.socios.${errorIndex}.document`,
                      message: 'Este documento já está em uso por um dos Sócios',
                    })
                  }

                  return true
                },
              }),
          })
          .when({
            is: value => !value?.stateRegistrationIsent,
            then: schema => {
              return schema.shape({
                stateRegistration: yup
                  .string()
                  .min(5, 'O campo não pode ter menos que 5 caracteres')
                  .max(20, 'O campo não pode ter mais que 20 caracteres')
                  .required('Inscrição estadual é obrigatório'),
              })
            },
          })
      },
    }),
  contract: yup.object().shape({
    total_value: yup
      .string()
      .required('Campo valor do título é obrigatório.')
      .test(
        'min',
        'O valor mínimo do título é de R$ 1.000.',
        value => BRLMoneyToNumber(value) >= 1000,
      ),
    monthly_value: yup
      .string()
      .required('Campo valor do aluguel é obrigatório.')
      .test(
        'min',
        'O valor mínimo do aluguel é de R$ 100.',
        value => BRLMoneyToNumber(value) >= 100,
      )
      .test(
        'max',
        'O valor máximo do aluguel é de R$ 10.000.000.',
        value => BRLMoneyToNumber(value) <= 10000000,
      ),
  }),
})
