// @ts-nocheck
type FormatDateOptions = {
  dateStyle?: 'long' | 'short'
  timeStyle?: 'long' | 'short'
  timeZone?: string;
} & Intl.DateTimeFormatOptions

export function formatDate(date: string, options?: FormatDateOptions) {
  return (
    date &&
    Intl.DateTimeFormat('pt-br', {
      ...options,
      dateStyle: 'long',
    }).format(new Date(date))
  )
}

export function isValidDate(date: Date | any): boolean {
  return date instanceof Date && isFinite(date)
}
