import { Grid } from '@/components/Utilities'
import styled from 'styled-components'
import Choice from '../Choice'
import InputGroup from '../InputGroup'

export const Toggle = styled(Choice)``

export const TextField = styled(InputGroup)``

export const StyledGrid = styled(Grid)`
  margin-top: 16px;
`
