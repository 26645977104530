import styled from 'styled-components'
import { rem } from 'polished'

import { themes } from '@/styles'

export const Text = styled.p`
  margin-bottom: 32px;

  font-size: ${rem('14px')};
  color: ${themes.text.high};
`

export const Field = styled.div``
