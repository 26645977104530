import styled, { css } from 'styled-components'
import { size, rem, rgba } from 'polished'
import media from 'styled-media-query'

import { themes } from '@/styles'

import { FeatherIcons } from '../../Icons'

import { ICardProps } from './interfaces'

export const Content = styled.div`
  width: 100%;
  padding: 8px 16px;

  flex: 1;

  border-radius: 8px;
  background-color: ${themes.page.colors.background};

  ${media.greaterThan('large')`
    padding: 8px 24px;
  `}

  ${media.greaterThan('huge')`
    padding: 16px 32px;
  `}

  ${media.lessThan('large')`
    order: 2;
  `}
`

export const Head = styled.header`
  display: flex;
  align-items: center;
`

export const Step = styled.div`
  ${size('30px')}

  margin-right: 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 8px;
  background-color: ${themes.page.colors.content};

  color: ${themes.text.low};
  font: bold ${rem('16px')} ${themes.fonts.title};

  ${media.greaterThan('huge')`
    ${size('34px')}

    font-size: ${rem('20px')};
  `}
`

export const Title = styled.h5`
  color: ${themes.text.higher};
  font-size: ${rem('20px')};
`

export const Validated = styled(FeatherIcons).attrs(() => ({
  name: 'check-square',
}))`
  ${size('12px')}
  margin-left: 8px;

  cursor: help;
  color: ${themes.palletes.success};

  ${media.greaterThan('huge')`
    ${size('16px')}
  `}
`

export const Body = styled.div`
  padding: 8px;

  ${media.greaterThan('large')`
    padding: 16px 16px 8px;
  `}

  ${media.greaterThan('huge')`
    padding: 24px 24px 16px;
  `}
`

export const Aside = styled.aside`
  ${media.greaterThan('large')`
    position: sticky;
    top: 5px;

    width: 200px;
    margin-left: 4px;
  `}

  ${media.greaterThan('huge')`
    width: 280px;
    margin-left: 8px;
  `}

  ${media.lessThan('large')`
    width: 96%;
    margin: 0 auto 4px;

    order: 1;
  `}
`

export const Container = styled.div<
  Pick<ICardProps, 'active' | 'validated'> & {
    checked?: boolean
  }
>`
  display: flex;
  align-items: flex-start;

  &:not(:last-of-type) {
    margin-bottom: 16px;
  }

  ${media.lessThan('large')`
    flex-direction: column;
  `}

  ${(props) =>
    props.active &&
    css`
      ${Content} {
        padding: 16px;

        ${media.greaterThan('large')`
          padding: 24px;
        `}

        ${media.greaterThan('huge')`
          padding: 32px;
        `}
      }

      ${Step} {
        color: ${themes.palletes.primary};
        background-color: ${rgba(themes.palletes.primary, 0.1)};
      }

      ${Title} {
        color: ${themes.palletes.primary};
      }
    `}

  ${(props) =>
    props.checked &&
    css`
      margin-left: 16px;
    `}
`
