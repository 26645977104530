import { Modals } from '@/components'
import { Content, IconContainer, Paragraph, Title } from './styles'
import { Icon } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/system'
import { TCreateModal } from '@/components/Modals/promised'

interface SetActiveOrganizationErrorModalInterface extends TCreateModal {
  errorTitle: string
  errorMessage: string
  buttonText: string
}

const SetActiveOrganizationErrorModal: React.FC<
  SetActiveOrganizationErrorModalInterface
> = props => {
  return (
    <Modals.Content
      icon=""
      title=""
      onSubmit={{
        text: props.buttonText,
        onClick: e => {
          return props.onSubmit(e)
        },
      }}
      size="small"
    >
      <Content>
        <IconContainer>
          <Icon {...{ name: 'alert', color: '#842310' }} />
        </IconContainer>

        <Title>{props.errorTitle}</Title>

        <Paragraph dangerouslySetInnerHTML={{ __html: props.errorMessage }} />
      </Content>
    </Modals.Content>
  )
}

export default Modals.Promised.createModal<SetActiveOrganizationErrorModalInterface>(
  SetActiveOrganizationErrorModal,
)
