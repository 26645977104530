import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div``

export const Board = styled.div``

export const SettingsWrapper = styled.div`
  max-width: 900px;

  margin: 0 auto;
`
