import { useEnums, usePlans } from '@/tools/hooks/capitalizacao'
import { useCountries } from '@/tools/hooks/capitalizacao/useCountries'
import { Error, Loading } from '../components'
import { BudgetsScreen } from './Budget'

export const Budgets = () => {
  const enums = useEnums()
  const plans = usePlans()
  const countries = useCountries()

  if (enums.isError || plans.isError || countries.isError) {
    return (
      <Error error={enums?.error?.message || plans?.error?.message || countries?.error?.message} />
    )
  }

  return (
    <Loading isLoading={enums.isLoading || plans.isLoading || countries.isLoading}>
      <BudgetsScreen enums={enums.data} plans={plans.data} />
    </Loading>
  )
}
