import * as Steps from '.'

export default [
  {
    id: 'budgets',
    icon: 'list',
    title: 'Orçamentos',
    description: 'Selecione o Orçamento desejado.',
    render: Steps.Budgets,
  },

  {
    id: 'landlord',
    icon: 'user',
    title: 'Locador',
    description: 'Cadastre as informações do dono do imóvel a ser locado.',
    render: Steps.Landlord,
  },

  {
    id: 'payments',
    icon: 'dollar',
    title: 'Formas de pagamento',
    description: 'Selecione a forma de pagamento para transmitir a proposta.',
    render: Steps.Payments,
  },
] as const
