export default {
  tooltip: 70,
  autoCompleteDropdown: 75,
  header: 90,
  aside: 119,
  floatActions: {
    button: 110,
    submit: 115,
  },
  popover: 120,
  offcanvas: 140,
  modals: 150,
  toast: 160,
}
