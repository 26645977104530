import api from '../index'

const transformValuesIntoObject = values =>
  Object.keys(values).map(innerKey => ({
    value: innerKey,
    label: values[innerKey],
  }))

const reduceEnumValuesToObjectShape = values =>
  Object.keys(values).reduce((obj, key) => {
    obj[key] = transformValuesIntoObject(values[key])
    return obj
  }, {})

export const getPlans = async () => {
  try {
    const plans = await api.instance.v2.get('/contracts/products/portocap')
    return plans.data
  } catch (e) {
    console.log(e)
    throw new Error('Ocorreu um erro ao carregar os planos disponíveis.')
  }
}

export const getCountries = async () => {
  const response = await api.instance.v2.get('/contracts/products/portocap/countries')

  return response.data
}

export const getEnums = async () => {
  try {
    const enums = await api.instance.v2.get('/contracts/products/portocap/enums')

    return reduceEnumValuesToObjectShape(enums.data)
  } catch (e) {
    console.log(e)
    throw new Error('Ocorreu um erro ao carregar os dados de capitalização.')
  }
}

export const getOccupations = async text => {
  try {
    const occupations = await api.instance.v2.get('/contracts/products/portocap/occupations', {
      params: { text },
    })
    return occupations.data.map(occupation => ({
      value: occupation,
      label: occupation,
    }))
  } catch (e) {
    console.log(e)
    throw new Error('Ocorreu um erro ao carregar a lista de profissões.')
  }
}
