import * as yup from 'yup'

export default yup.object().shape({
  zipcode: yup
    .string()
    .min(9, 'Por favor insira um CEP válido.')
    .required('Campo CEP é obrigatório.'),
  street: yup.string().required('Por favor confirme a rua.'),
  number: yup
    .string()
    .matches(/^[0-9]*$/g, 'Utilize o campo de complemento para inserir letras.')
    .required('Por favor insira o número.'),
  complement: yup.string(),
  neighborhood: yup.string().required('Por favor confirme o bairro.'),
  city: yup.string().required('Por favor confirme a cidade.'),
  state: yup.string().required('Por favor confirme o estado.'),
})
