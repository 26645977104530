import { useState } from 'react'

import { Conditional, Icons, Loading, Modals, Tooltip } from '@/components'
import { useContract } from '@/modules'
import { api } from '@/services'
import { dashText, formatMoneyBRL, useGTM } from '@/tools'
import dayjs from 'dayjs'
import { clausesParse, includedServicesByClause } from '../../Budgets/utils'

import * as S from './styles'

function formatMoney(money) {
  const isNaN = !money && money !== 0
  if (isNaN) return '-'

  return formatMoneyBRL(money)
}

const Policy = () => {
  const contract = useContract()
  const [status, setStatus] = useState<'printing' | ''>('')
  const { setDataLayer, setCustomData } = useGTM()

  if (!contract.policy) return null

  const isBoleto = ['11', '31', '55', '81'].includes(
    contract.payload?.payment?.method
  )

  const handlePrint = async (type: string, budget?: string) => {
    try {
      setStatus('printing')
      await api.contracts.print(contract.id, type, budget)
      setDataLayer({
        event: 'solicitacao_servico',
        nome_servico: 'seguro-incendio',
        tipo_servico: 'impressão',
        retorno: 'sucesso',
        descricao: 'Documento gerado com sucesso.',
      })
    } catch (error) {
      setStatus('')

      setDataLayer({
        event: 'solicitacao_servico',
        nome_servico: 'seguro-incendio',
        tipo_servico: 'impressão',
        retorno: 'erro',
        descricao: 'Documento não foi gerado.',
        erro: {
          mensagem: 'Documento não foi gerado.',
        },
      })

      if (type === 'ticket') {
        handleInformationTicket()
      }
    } finally {
      setStatus('')
    }
  }

  function handleInformationTicket() {
    Modals.Information({
      icon: 'hourglass-outline',
      title: 'O boleto está sendo gerado.',
      content: `Por favor, tente novamente mais tarde.`,
    })
  }

  const paymentBudget = contract.budgets.find((budget) => {
    return budget.id === contract.payload?.payment?.budget
  })

  const [defaultBudget] = contract.budgets

  const budget = paymentBudget || defaultBudget

  const method = budget?.payment_methods.find((method) => {
    return method.id === contract.payload?.payment?.method
  })

  const installment = method?.installments.find((installment) => {
    return installment.id === contract.payload.payment?.installment
  })

  return (
    <S.Container>
      <Conditional when={status === 'printing'}>
        <Loading message="Aguarde, gerando PDF." />
      </Conditional>

      <S.Title status="1">{contract.policy.status}</S.Title>

      <S.Divider>
        <S.Icon>
          <Icons.FeatherIcons name="hash" />
        </S.Icon>
        Documentos
      </S.Divider>

      <S.Items>
        <S.Item.Wrap>
          <S.Content>
            <S.Item.Label>Nº do orçamento</S.Item.Label>

            <Tooltip content="Gerar PDF do orçamento">
              <S.Icon
                data-gtm-type="click"
                data-gtm-clicktype="button"
                data-gtm-name={dashText('Gerar PDF do orçamento')}
                onClick={() => {
                  handlePrint('budget', contract.policy.budget.toString())
                }}
              >
                <Icons.FeatherIcons name="printer" />
              </S.Icon>
            </Tooltip>
          </S.Content>
          <S.Item.Title>{contract.policy.budget}</S.Item.Title>
        </S.Item.Wrap>

        <S.Item.Wrap>
          <S.Content>
            <S.Item.Label>Nº da proposta</S.Item.Label>

            <Tooltip content="Gerar PDF da proposta">
              <S.Icon
                data-gtm-type="click"
                data-gtm-clicktype="button"
                data-gtm-name={dashText('Gerar PDF da proposta')}
                onClick={() => {
                  handlePrint('proposal')
                }}
              >
                <Icons.FeatherIcons name="printer" />
              </S.Icon>
            </Tooltip>
          </S.Content>
          <S.Item.Title>{contract.policy.proposal}</S.Item.Title>
        </S.Item.Wrap>

        <S.Item.Wrap>
          <S.Content>
            <S.Item.Label>Nº da apólice</S.Item.Label>

            <Conditional
              when={!!contract.policy.id && contract.policy.id !== '0'}
            >
              <Tooltip content="Gerar PDF da apólice">
                <S.Icon
                  data-gtm-type="click"
                  data-gtm-clicktype="button"
                  data-gtm-name={dashText('Gerar PDF da apólice')}
                  onClick={() => {
                    handlePrint('policy')
                  }}
                >
                  <Icons.FeatherIcons name="printer" />
                </S.Icon>
              </Tooltip>
            </Conditional>
          </S.Content>
          <S.Item.Title>
            {!!contract.policy.id && contract.policy.id !== '0'
              ? contract.policy.id
              : 'Aguardando emissão'}
          </S.Item.Title>
        </S.Item.Wrap>
      </S.Items>

      <S.Divider>
        <S.Icon>
          <Icons.FeatherIcons name="dollar-sign" />
        </S.Icon>
        Informações de cobrança
      </S.Divider>
      <S.Coverages.Section>
        <S.Coverages.List>
          <thead>
            <tr>
              <th>Forma de pagamento</th>
              <th>Valor da parcela</th>
              <Conditional when={isBoleto}>
                <th>Gerar boleto</th>
              </Conditional>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>{installment?.description}</td>
              <td>{formatMoney(installment?.first)}</td>
              <Conditional when={isBoleto}>
                <td>
                  <S.Icon
                    data-gtm-type="click"
                    data-gtm-clicktype="button"
                    data-gtm-name={dashText('Gerar boleto')}
                    onClick={() => {
                      handlePrint('ticket')
                    }}
                  >
                    <Icons.FeatherIcons name="printer" />
                  </S.Icon>
                </td>
              </Conditional>
            </tr>
          </tbody>
        </S.Coverages.List>
      </S.Coverages.Section>

      <S.Divider>
        <S.Icon>
          <Icons.FeatherIcons name="file-text" />
        </S.Icon>
        Dados da apólice
      </S.Divider>

      <S.Items>
        <S.Item.Wrap>
          <S.Item.Label>Data da transmissão</S.Item.Label>
          <S.Item.Title>
            {dayjs(contract.policy.created_at).format('DD/MM/YYYY [às] HH:mm')}
          </S.Item.Title>
        </S.Item.Wrap>

        <S.Item.Wrap>
          <S.Item.Label>Ramo</S.Item.Label>
          <S.Item.Title>{contract.policy?.branch}</S.Item.Title>
        </S.Item.Wrap>

        <S.Item.Wrap>
          <S.Item.Label>Tipo</S.Item.Label>
          <S.Item.Title>{contract.policy?.type}</S.Item.Title>
        </S.Item.Wrap>

        <S.Item.Wrap>
          <S.Item.Label>Sucursal</S.Item.Label>
          <S.Item.Title>-</S.Item.Title>
        </S.Item.Wrap>
      </S.Items>

      <Conditional when={contract.payload.contract.beneficiaries.length > 0}>
        <S.Divider>
          <S.Icon>
            <Icons.FeatherIcons name="user" />
          </S.Icon>
          Beneficiários
        </S.Divider>

        <S.Items>
          <S.Coverages.List>
            <thead>
              <tr>
                <th>Nome de registro</th>
                <th>Nome Social</th>
                <th>CPF</th>
              </tr>
            </thead>

            <tbody>
              {contract.payload.contract.beneficiaries.map((item: any) => (
                <tr>
                  <td>{item.name}</td>
                  <td>{item.socialName || '-'}</td>
                  <td>{item.document}</td>
                </tr>
              ))}
            </tbody>
          </S.Coverages.List>
        </S.Items>
      </Conditional>

      <S.Divider>
        <S.Icon>
          <Icons.FeatherIcons name="list" />
        </S.Icon>
        Coberturas
      </S.Divider>

      <S.Coverages.Section>
        <S.Coverages.List>
          <thead>
            <tr>
              <th>Cobertura</th>
              <th>Valor contratado</th>
              <th>Valor líquido</th>
            </tr>
          </thead>

          <tbody>
            {contract.budgets[0]?.coverages.map((item: any) => (
              <tr>
                <td>{item.description}</td>
                <td>{formatMoney(item.value)}</td>
                <td>{formatMoney(item.prize)}</td>
              </tr>
            ))}
          </tbody>
        </S.Coverages.List>
      </S.Coverages.Section>

      <S.Divider>
        <S.Icon>
          <Icons.FeatherIcons name="dollar-sign" />
        </S.Icon>
        Valor do Seguro
      </S.Divider>

      <S.Items>
        <S.Item.Wrap>
          <S.Item.Label>Valor total líquido</S.Item.Label>
          <S.Item.Title>
            {formatMoney(budget?.quotation?.prize?.net)}
          </S.Item.Title>
        </S.Item.Wrap>
        <S.Item.Wrap>
          <S.Item.Label>Cláusulas de serviço</S.Item.Label>
          <S.Item.Title>
            {formatMoney(budget?.quotation?.prize?.clauses)}
          </S.Item.Title>
        </S.Item.Wrap>

        <S.Item.Wrap>
          <S.Item.Label>IOF</S.Item.Label>
          <S.Item.Title>{formatMoney(installment?.iof)}</S.Item.Title>
        </S.Item.Wrap>

        <S.Item.Wrap>
          <S.Item.Label>Valor total bruto</S.Item.Label>
          <S.Item.Title>
            {formatMoney(budget?.quotation?.prize?.total)}
          </S.Item.Title>
        </S.Item.Wrap>
      </S.Items>

      <S.Divider>
        <S.Icon>
          <Icons.FeatherIcons name="tool" />
        </S.Icon>
        Serviços emergenciais
      </S.Divider>
      <S.Items>
        <S.Item.Wrap>
          <S.Item.Label>Plano contratado</S.Item.Label>
          <S.Item.Title>
            {clausesParse[contract.payload.contract.service_clause]}
          </S.Item.Title>
        </S.Item.Wrap>

        <S.Item.Wrap>
          <S.Item.Label>Serviços inclusos</S.Item.Label>

          <S.Services.List>
            {includedServicesByClause(
              contract.payload.contract.service_clause
            ).map((service) => (
              <S.Services.Item>{service}</S.Services.Item>
            ))}
          </S.Services.List>
        </S.Item.Wrap>
      </S.Items>

      <S.Divider>
        <S.Icon>
          <Icons.FeatherIcons name="briefcase" />
        </S.Icon>
        Dados do corretor
      </S.Divider>

      <S.Items>
        <S.Item.Wrap>
          <S.Item.Label>Susep</S.Item.Label>
          <S.Item.Title>{contract.broker.id}</S.Item.Title>
        </S.Item.Wrap>

        <S.Item.Wrap>
          <S.Item.Label>Nome</S.Item.Label>
          <S.Item.Title>{contract.broker.name}</S.Item.Title>
        </S.Item.Wrap>

        <S.Item.Wrap>
          <S.Item.Label>E-mail</S.Item.Label>
          <S.Item.Title>{contract.broker.email}</S.Item.Title>
        </S.Item.Wrap>
      </S.Items>

      <S.Divider>
        <S.Icon>
          <Icons.FeatherIcons name="home" />
        </S.Icon>
        Dados da imobiliária
      </S.Divider>

      <S.Items>
        <S.Item.Wrap>
          <S.Item.Label>Código</S.Item.Label>
          <S.Item.Title>{contract.partner.id}</S.Item.Title>
        </S.Item.Wrap>

        <S.Item.Wrap>
          <S.Item.Label>Nome</S.Item.Label>
          <S.Item.Title>{contract.partner.name}</S.Item.Title>
        </S.Item.Wrap>

        <S.Item.Wrap>
          <S.Item.Label>E-mail</S.Item.Label>
          <S.Item.Title>{contract.partner.email}</S.Item.Title>
        </S.Item.Wrap>
      </S.Items>
    </S.Container>
  )
}

export default Policy
