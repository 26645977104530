import React from 'react'

import { IMessageProps } from './interfaces'

import { Container, Text } from './styles'

const Message: React.FC<IMessageProps> = (props) => (
  <Container
    transition={{ ease: [0.22, 1, 0.36, 1] }}
    initial={{ y: -10 }}
    animate={{ y: 0 }}
    exit={{ opacity: 0 }}
    {...props}
  >
    <Text>{props.children}</Text>
  </Container>
)

export default Message
