import React from 'react'

import { AnimatePresence, motion } from 'framer-motion'
import { AnimateVerticalShowProps } from './types'

export const AnimateVerticalShow: React.FC<AnimateVerticalShowProps> = ({
  isOpen,
  children,
  duration = 0.2,
}) => {
  return (
    <AnimatePresence initial={false}>
      {isOpen && (
        <motion.div
          transition={{ type: 'just', duration }}
          style={{
            overflowY: 'auto',
            scrollbarWidth: 'none',
          }}
          initial={{ height: 0 }}
          animate={{ height: 'unset' }}
          exit={{ height: 0 }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  )
}
