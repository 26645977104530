import { Box, Flex } from "@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base"
import { Stepper } from "@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto"

export const HomeEquityStepper = ({
  currentStep,
  steps = [],
}) => {
  
  return (
    <Flex
      sx={{
        maxWidth: "880px",
        width: "100%",
        flexDirection: "column",
        gap: "1rem"
      }}
    >
      <Box
        sx={{
          padding: "1rem 1rem",
          backgroundColor: "system-surface-primary"
        }}
      >
        <Stepper currentStep={currentStep} steps={steps} />
      </Box>
    </Flex>
  )
}