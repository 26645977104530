import { Flex, Typography } from "@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base"
import { Icon } from "@pol-npm/riscos-financeiros-dashboard-ui/dist/components/system"
import { IBenefitProps } from "./interfaces"

export const Benefit = ({
  title,
  description,
  icon,
  action,
  onClickAction,
  rescuePercentage
}: IBenefitProps) => {
  return (
    <Flex
      sx={{
        gap: '1rem',
      }}
    >
      <Icon
        size="1.5rem"
        color="porto-banking-primary"
        name={icon}
      />
      <Flex
        sx={{
          flexDirection: 'column',
          gap: '.25rem',
          alignItems: 'flex-start',
        }}
      >
        <Typography
          content={title.replace('{percent}', rescuePercentage)}
          variant="porto-text-body-2-bold"
          color="porto-primitive-black-85"
        />
        <Typography
          content={description.replace('{percent}', rescuePercentage)}
          variant="porto-text-body-2-regular"
          color="porto-primitive-black-70"
        />
        {action && (
          <Typography
            role="button"
            variant="porto-text-link-body-2-bold"
            onClick={() => onClickAction()} content={action.label}
            color="porto-seguros-100"
            sx={{ cursor: "pointer" }}
          />
        )}
      </Flex>
    </Flex>
  )
}