import React, { Suspense } from 'react'
import { Route, useRouteMatch } from 'react-router-dom'

import Head from './Head'

import { ITabsProps } from './interfaces'

import { Container, Body, Message } from './styles'

const Tabs: React.FC<ITabsProps> = (props) => {
  const match = useRouteMatch()

  return (
    <Container>
      <Head items={props.items} />

      {props.items.map((item) => (
        <Body>
          <Suspense
            fallback={
              <Message>
                {!!props.noLoading ? '' : 'Aguarde, carregando...'}
              </Message>
            }
          >
            <Route
              exact
              path={match.path + item.path}
              component={item.content as any}
            />
          </Suspense>
        </Body>
      ))}
    </Container>
  )
}

Tabs.defaultProps = {
  items: [],
}

export default Tabs
