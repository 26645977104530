import { IResultCardRightInfoProps } from './interfaces'
import {
  ResultBaseCardFlex,
  ResultLeftBox,
  ResultRightBox,
  ResultRightBoxLine,
} from '../../../styles'
import {
  Box,
  Typography,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'

export const ResultCardRightInfo = ({
  leftItens,
  rightItens,
}: IResultCardRightInfoProps) => {
  return (
    <ResultRightBox>
      <ResultBaseCardFlex>
        <ResultLeftBox>
          {leftItens.map((leftItem, key) => {
            return (
              <ResultRightBoxLine>
                <Box
                  sx={{
                    display: 'grid',
                    gridColumn: { xs: '1', lg: '1/3' },
                  }}
                >
                  <Typography
                    content={leftItem.label}
                    variant="porto-text-label-regular"
                    color="porto-primitive-black-70"
                  />
                </Box>
                <Box
                  sx={{
                    display: 'grid',
                    gridColumn: { xs: '1', lg: '1/3' },
                  }}
                >
                  <Typography
                    content={leftItem.value}
                    variant="porto-title-7-semibold"
                    color="porto-primitive-black-85"
                  />
                </Box>
              </ResultRightBoxLine>
            )
          })}
        </ResultLeftBox>
        <ResultRightBox>
          {rightItens.map((rightItem, key) => {
            return (
              <ResultRightBoxLine>
                <Box
                  sx={{
                    display: 'grid',
                    gridColumn: { xs: '1', lg: '1/3' },
                  }}
                >
                  <Typography
                    content={rightItem.label}
                    variant="porto-text-label-regular"
                    color="porto-primitive-black-70"
                  />
                </Box>
                <Box
                  sx={{
                    display: 'grid',
                    gridColumn: { xs: '1', lg: '1/3' },
                  }}
                >
                  <Typography
                    content={rightItem.value}
                    variant="porto-title-7-semibold"
                    color="porto-primitive-black-85"
                  />
                </Box>
              </ResultRightBoxLine>
            )
          })}
        </ResultRightBox>
      </ResultBaseCardFlex>
    </ResultRightBox>
  )
}
