import React, { memo, useEffect, useState } from 'react'

import { Icons, Conditional, Loading, Tooltip } from '@/components'

import { IBudgetProps } from './interfaces'
import { formatMoneyBRL, handleInstallments } from '@/tools'
import * as schema from './schema'
import Details from '../Details'

import {
  Container,
  Header,
  Title,
  Body,
  Content,
  Coverages,
  CoverageItem,
  Link,
  Badge,
  Text,
  Icon,
  Prices,
  Price,
  Print,
  Actions,
  Check,
  Footer,
  NumberBudget,
} from './styles'
import { api } from '@/services'
import { useContract } from '@/modules'
import { useQuery } from 'react-query'

const Budget: React.FC<IBudgetProps> = (props) => {
  const [detailsVisibility, setDetailsVisibility] = useState(false)
  const [installments, setInstallments] = useState<number>()
  const [status, setStatus] = useState<'printing' | ''>('')

  const contract = useContract()

  const orgQuerySettings = useQuery(
    ['@org/id/part/id', contract.broker.document, contract.partner.id],
    () => api.instance.v1.get(`/organizations/${contract.broker.document}/partners/${contract.partner.id}`)
  )

  const handlePrint = async (type: string, budget: string) => {
    try {
      setStatus('printing')
      await api.contracts.print(contract.id, type, budget)
    } finally {
      setStatus('')
    }
  }

  useEffect(() => {
    if(!orgQuerySettings) return 
    const { settings } = orgQuerySettings?.data?.data || false
    if (!settings) return
    setInstallments(handleInstallments(settings))
  }, [orgQuerySettings])

  return (
    <>
      <Conditional when={detailsVisibility}>
        <Details
          details={props}
          onCancel={() => {
            setDetailsVisibility(false)
          }}
        />
      </Conditional>

      <Conditional when={status === 'printing'}>
        <Loading message="Aguarde, gerando PDF." />
      </Conditional>

      <Container
        onClick={props.onClick && props.onClick}
        active={props.active}
        title="Clique para selecionar"
      >
        <Content>
          <Header>
            <Actions>
              <Check.Box>
                <Check.Icon />
              </Check.Box>
            </Actions>
            <Title>
              {props.title}
              <Conditional when={!!props.type}>
                <Badge type={props.type}>{props.type}</Badge>
              </Conditional>
            </Title>
            <NumberBudget>Orçamento: {props.id}</NumberBudget>
          </Header>

          <Body>
            <Coverages>
              {props.coverages.map((item: any) => (
                <CoverageItem>
                  <Icon>
                    <Icons.Ionicons name={schema.icons[item.id]} />
                  </Icon>
                  <Text>
                    {item.description}: {formatMoneyBRL(item.value)}
                  </Text>
                </CoverageItem>
              ))}
            </Coverages>
          </Body>

          {
            installments ?
              <Prices>
                <Price.Highlight>
                  <Price.Small>
                    {installments}x de {formatMoneyBRL(props.quotation.prize.total / installments)}
                    <Price.SmallFess> Sem juros</Price.SmallFess>
                  </Price.Small>
                </Price.Highlight>

                <Price.Muted>
                  Valor total de {formatMoneyBRL(props.quotation.prize.total)}
                </Price.Muted>
              </Prices>
            : ''
          }
        </Content>

        <Footer>
          <Link
            onClick={() => {
              setDetailsVisibility((state) => !state)
            }}
          >
            Mais informações
          </Link>

          <Print
            onClick={() => {
              handlePrint('budget', props.id)
            }}
          >
            <Tooltip content="Gerar PDF do orçamento">
              <Icons.Ionicons name="print-outline" />
            </Tooltip>
          </Print>
        </Footer>
      </Container>
    </>
  )
}

export default memo(Budget)
