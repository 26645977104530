import { useState } from 'react'
import novaJornadaFianca from '@/assets/images/news/nova-jornada-fianca.png'
import contratoDeEstipulacao from '@/assets/images/news/contrato-de-estipulacao.png'
import emprestimoComGarantia from '@/assets/images/news/emprestimo-com-garantia-de-imovel.png'
import assessoriaDeCobranca from '@/assets/images/news/assessoria-de-cobranca.png'
import tituloDeCapitalizacao from '@/assets/images/news/titulo-de-capitalizacao.jpeg'
import biometriaNoFianca from '@/assets/images/news/biometria-no-fianca.jpeg'

const defaults = {
  newsCards: [
    {
      image: novaJornadaFianca,
      title: 'Nova jornada do Fiança Locatícia',
      description: 'Novo layout com menos telas. Uma jornada completa em um único lugar.',
      tag: 'Novo',
      buttonLabel: 'Criar proposta',
      url: '/contratos/novo/essencial',
    },
    {
      image: contratoDeEstipulacao,
      title: 'Contrato de Estipulação atualizado',
      description:
        'Em breve você receberá a versão mais recente do contrato de estipulação destinado às Imobiliárias, para assinatura via ClickSign. Esta atualização apresenta cláusulas relacionadas à Lei Geral de Proteção de Dados (LGPD) e à estrutura de remuneração. A assinatura do novo documento é obrigatória para todos os Corretores e Imobiliárias credenciadas. Em caso de dúvidas ou esclarecimentos, consulte seu Corretor.',
      tag: 'Novo',
    },
    {
      image: emprestimoComGarantia,
      title: 'Empréstimo com Garantia de Imóvel',
      description:
        'Seus clientes pagam taxas menores e contam com condições de pagamento diferenciadas. Imobiliária e Corretor ganham comissão a cada novo contrato!',
      buttonLabel: 'Criar proposta',
      url: '/home-equity/estate-guarantee',
    },
    {
      image: assessoriaDeCobranca,
      title: 'Chegou a Assessoria de cobrança',
      description:
        'Uma maneira mais fácil e conveniente de cobrar o aluguel atrasado dos seus clientes que contrataram o Fiança Locatícia, a fim de evitar a abertura de sinistros',
      buttonLabel: 'Acessar',
      url: '/sinistro/assessoria-de-cobranca',
      isRealEstateOnly: true,
    },
    {
      image: tituloDeCapitalizacao,
      title: 'O Título de Capitalização chegou por aqui!',
      description:
        'Não precisa de comprovação de renda e o inquilino resgata o valor no final do contrato.',
      buttonLabel: 'Criar proposta',
      url: '/contratos/novo/capitalizacao',
    },
    {
      image: biometriaNoFianca,
      title: 'Biometria facial no Fiança Locatícia Essencial',
      description: 'Tecnologia gratuita que garante a segurança dos seus negócios.',
      buttonLabel: 'Criar proposta',
      url: '/contratos/novo/essencial',
    },
  ] as NewsCardData[],
  newsBanners: [
    {
      image: '/images/thumbnail_NovaJornadaFianca_banner_PortaldasImobiliarias-20230807-115744.png',
      isRealEstateOnly: false,
    },
    {
      image: '/images/news_bannernovidades_EmprestimoGarantiadeCobranca.png',
      isRealEstateOnly: false,
    },
    {
      image:
        '/images/AssessoriadeCobranca_bannernovidades_PortaldasImobiliarias-20230809-193617.png',
      isRealEstateOnly: true,
    },
  ] as NewsBannersData[],
}

export const useRemoteConfig = () => {
  const [isLoading] = useState(true)
  const [values] = useState(defaults)

  return {
    isLoading,
    values,
  }
}
