import { Modals } from '@/components'
import { Content } from './styles'

const NewFormModal = (props) => {
  return (
    <Modals.Content
      icon="warning-outline"
      title="Novo Fiança Locatícia"
      colorIcon="warning"
      onSubmit={{
        text: 'Ir para o novo formulário',
        onClick: () => props.onSubmit(true),
      }}
      onCancel={{
        text: 'Continuar no formulário atual',
        onClick: props.onDismiss,
      }}
      onDismiss={props.onDismiss}
    >
      <Content>
        <b>Agora a contratação do Fiança Locatícia está ainda mais fácil!</b>
        <br />
        <br />O{' '}
        <b>novo formulário para contratos com IS de aluguel até R$ 12.000 </b>
        conta com:
        <ul>
          <li>Informações solicitadas mais objetivas;</li>
          <li>Mais opções de planos disponíveis;</li>
          <li>Sistema mais rápido que permite maior agilidade.</li>
        </ul>
        <br />
        Importante: contratos com IS de aluguel acima de R$ 12.000 devem ser
        efetuados no formulário atual.
      </Content>
    </Modals.Content>
  )
}

export default Modals.Promised.createModal(NewFormModal)
