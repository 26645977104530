import {
  Box,
  Flex,
  Typography,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import React from 'react'
import { TextContent } from '../TextContent'
import { ITextContentGroup } from './interfaces'
import { dashText } from '@/tools'

const Component = ({ title, values }: ITextContentGroup) => (
  <Flex sx={{ gap: '1.5rem', flexDirection: 'column' }} data-test-id={dashText(title)}>
    {title && <Typography content={title} variant="porto-title-5-bold" />}
    {values.map(content => (
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', lg: 'repeat(3, 1fr)' },
          gap: '1rem',
        }}
      >
        {content.filter(Boolean).map((data, index) => (
          <TextContent
            title={data?.title}
            description={data?.description}
            key={`${title}-${data?.title}_${data?.description}-${index}`}
          />
        ))}
      </Box>
    ))}
  </Flex>
)

export const TextContentGroup = React.memo(Component)
