import styled from 'styled-components'
import { rem, size } from 'polished'
import media from 'styled-media-query'
import { Actions } from '@/components'

import { themes } from '@/styles'

interface Props {
  backgroundNone?: boolean;
   disabled?: boolean;
   isActive?: boolean;
 }

export const Content = styled.div`
  line-height: 180%;
  font-size: ${rem('8px')};
  color: ${themes.text.higher};

  ${media.greaterThan('huge')`
    font-size: ${rem('12px')};
  `}
`
export const TitleHead = styled.h4`
  font-family: 'Porto Roobert';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin-top: 12px;

  letter-spacing: 0.02em;

  color: #2f2663;
  ${media.lessThan('medium')`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;


  letter-spacing: 0.025em;


  `}
`

export const Title = styled.div`
  border-bottom: 1px solid rgba(179, 179, 179, 0.5);
  padding: 0 18px;

  display: flex;
  align-items: center;

  justify-content: space-between;
  p {
    width: 48px;
    height: 48px;
  }

  div {
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${media.lessThan('medium')`
    padding: 0 8px ;
  
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    letter-spacing: 0.025em;


    color: #2F2663;

    p {
      display: none;
    }
              `}
`

export const TitleLabel = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;

  text-align: center;
  letter-spacing: 0.02em;

  color: #2f2663;

  padding: 18px 12px;
  display: 2;

  ${media.lessThan('medium')`
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 28px;

letter-spacing: 0.03em;


color: #2F2663;
          `}
`

export const FormWrapper = styled.div`
  height: 149px;

  padding: 16px 24px 0;

  @media only screen and (min-width: 1000px) and (max-width: 1675px) {
    height: 148px;
  }
  ${media.lessThan('medium')`
    
height: 489px;
     `}
`

export const ButtonClose = styled.div`
  cursor: pointer;
  font-size: 16px;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;

  &:hover {
    height: 48px;
    background: #f7f7f7;
    border-radius: 30px;
  }
`

export const Icon = styled.div<Props>`
  ${size('16px')}

  svg {
    fill: ${(props) => (props.disabled ? 'silver' : '#f6f7fb')};
    ${size('16px')}
  }
  transition: 0.3s;
  pointer-events: none;
  margin-right: 8px;
`

export const TypeOrder = styled.div`
  display: flex;
  gap: 10px;

  margin-top: 16px;
  margin-bottom: 56px;
`

export const Button = styled.button<Props>`
  padding: 6px 12px;
  cursor: pointer;
  width: 185px;
  height: 32px;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  letter-spacing: 0.025em;

  color: #2c3da8;

  background: ${(props) => props.isActive ? '#eff4ff' : "#ffffff" } ;

  border: 1px solid #2c3da8;
  border-radius: 30px;

  &:focus {
    background-color: #eff4ff;
  }

  &:hover {
    background-color: #eff4ff;
  }
`

export const ButtonFilter = styled.div`
  padding: 24px;
  display: flex;
  border-top: 1px solid rgba(179, 179, 179, 0.5);
`
