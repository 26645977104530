import React, { useState, useMemo, useEffect } from 'react'

import { Conditional } from '@/components'
import { formatMoneyBRL } from '@/tools'

import * as S from './styles'

const Payments = (props) => {
  const [selected, setSelected] = useState({
    method: {
      id: '',
      installments: [],
    },
    installment: '',
  })

  const selectedInstallment = useMemo(
    () =>
      selected.installment &&
      selected.method.installments.find(
        ({ id }) => id === selected.installment
      ),
    [selected.installment]
  )

  const handleSelected = (key, value) => {
    setSelected((state) => ({ ...state, [key]: value }))

    if (key === 'method') {
      setSelected((state) => ({
        ...state,
        installment: '',
      }))
    }
  }

  useEffect(() => {
    props.onChange &&
      props.onChange({
        method: selected.method.id,
        installment: selected.installment,
      })
  }, [selected])

  const isCreditCardInstallment = ['70', '98'].includes(
    selected.installment.substring(0, 2)
  )

  const isCreditCardMethod = ['62', '97'].includes(selected.method.id)

  const isDebitAccount = ['52', '55'].includes(selected.method.id)

  return (
    <S.Container>
      <S.Content>
        <S.List>
          {props.payments?.map((payment) => {
            const active = selected.method.id === payment.id

            return (
              <S.Item key={payment.id} active={active}>
                <S.Heading onClick={() => handleSelected('method', payment)}>
                  <S.Pin />

                  <S.Title>{payment.description}</S.Title>
                </S.Heading>
              </S.Item>
            )
          })}
        </S.List>
        <Conditional when={!!selected.method.id}>
          <S.Resume>
            <S.Select
              onChange={(event) =>
                handleSelected('installment', event.target.value)
              }
              value={selected.installment}
            >
              <option value="">Selecione as parcelas</option>

              {selected.method.installments.map((installment) => (
                <option value={installment.id}>
                  {installment.description}{' '}
                  {installment.amount > 1 ? 'de' : 'por'}{' '}
                  {formatMoneyBRL(installment.first)}
                </option>
              ))}
            </S.Select>
            <Conditional when={selectedInstallment}>
              <S.Note.List>
                <S.Note.Item>
                  <S.Note.Label>I.O.F</S.Note.Label>
                  <S.Note.Value>
                    {formatMoneyBRL(selectedInstallment.iof)}
                  </S.Note.Value>
                </S.Note.Item>
                <S.Note.Item>
                  <S.Note.Label>Prêmio total</S.Note.Label>
                  <S.Note.Value>
                    {formatMoneyBRL(selectedInstallment.total)}
                  </S.Note.Value>
                </S.Note.Item>
              </S.Note.List>

              <Conditional when={isDebitAccount}>
                <S.Message>
                  O CPF/CNPJ informado deve pertencer obrigatoriamente ao
                  titular da conta corrente. <br />O Banco poderá solicitar ao
                  cliente correntista a autorização prévia do débito. Essa
                  autorização deverá ser efetuada por meio dos canais de
                  atendimento do próprio Banco para cada um dos contratos e/ou
                  apólices.
                </S.Message>
              </Conditional>

              <Conditional when={isCreditCardInstallment && isCreditCardMethod}>
                <S.Message>
                  Informamos que o lançamento das parcelas será realizado
                  mensalmente no cartão de crédito informado abaixo. Caso o
                  lançamento da parcela não seja autorizado, um boleto será
                  gerado e enviado para o e-mail do inquilino informado no PAC,
                  com objetivo de regularização da parcela.
                </S.Message>
              </Conditional>
            </Conditional>
          </S.Resume>
        </Conditional>
      </S.Content>
    </S.Container>
  )
}

export default Payments
