import { useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { Profile, personalizationStore, useAuth, useCurrentUser, useOrganizations } from '@/modules'
import { RouteTypeRenderer } from '@/router/components'
import privateRoutes from '@/router/routes/private'
import publicRoutes from '@/router/routes/public'
import { CompleteRegister } from '@/screens/FirstAccess'
import Onboarding from '@/screens/Onboarding'
import Registration from '@/screens/Registration'
import { useUnit } from 'effector-react'

const isPrivateRoute = (pathname: string) => privateRoutes.some(route => route.path === pathname)

const isPublicRoute = (pathname: string) => publicRoutes.some(route => route.path === pathname)

export const Renderer = () => {
  const { push, replace } = useHistory()
  // TODO Definir o type do location
  const location = useLocation<any>()
  const { url_prefix, loading } = useUnit(personalizationStore)

  const user = useCurrentUser()
  const { isAuthenticated } = useAuth()
  const organizations = useOrganizations()

  const activeOrgId = organizations.store.active?.id

  const redirectRoute = useMemo(() => {
    return location?.state?.from?.pathname
  }, [location?.state?.from?.pathname])

  useEffect(() => {
    if (!isAuthenticated && !url_prefix && !loading && !isPublicRoute(location.pathname)) {
      replace('/')
    }
  }, [isAuthenticated, replace, url_prefix, loading, location.pathname])

  useEffect(() => {
    if (!isAuthenticated && isPrivateRoute(location.pathname)) {
      replace('/')
    }

    if (isAuthenticated && location.pathname === '/login') {
      replace('/')
    }
  }, [replace, isAuthenticated, location.pathname])

  useEffect(() => {
    if (isAuthenticated && !!redirectRoute && !user.store.isAdmin) {
      push(redirectRoute)
    }
  }, [isAuthenticated, push, redirectRoute, user.store.isAdmin])

  if (!isAuthenticated) {
    return <RouteTypeRenderer type="public" layout="Blank" />
  }

  if (user.store.isAdmin && !activeOrgId) {
    return <RouteTypeRenderer type="administrator" layout="Administrator" />
  }

  if (user.store.pending_accreditation) return <Onboarding />
  if (user.store.pending_registration) return <Registration />
  if (!user.store.missing_user && !user.store.document) return <CompleteRegister />

  if (!activeOrgId) return <Profile.Selector />

  return <RouteTypeRenderer type="private" layout="Dashboard" />
}
