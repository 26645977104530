import { isCNPJ, isCPF } from 'brazilian-values'
import cardValidator from 'card-validator'
import * as yup from 'yup'

const documentValidation = (value: string) => {
  if (value.length <= 14) return isCPF(value)

  return isCNPJ(value)
}

export const paymentsSchema = yup.object().shape({
  method: yup.string().required(),
  installment: yup.string().required('Selecione a parcela para continuar.'),

  card: yup.object().when('method', {
    is: (value: string) => value === '62' || value === '97',
    then: yup
      .object()
      .shape({
        number: yup
          .string()
          .required('Campo obrigatório.')
          .test(
            'card_number-test',
            'Por favor insira um cartão válido.',
            (value = '') => cardValidator.number(value).isValid
          ),
        expiration_date: yup
          .string()
          .required('Campo obrigatório.')
          .min(7, 'Por favor a data completa.'),
      })
      .optional(),
    otherwise: yup.object().strip(),
  }),

  debit: yup.object().when('method', {
    is: (value: string) => value === '52' || value === '55',
    then: yup
      .object()
      .shape({
        document: yup
          .string()
          .required('Campo CPF/CNPJ é obrigatório.')
          .test(
            'document-test',
            'Por favor insira um CPF/CNPJ válido.',
            (value) => !!value && documentValidation(value)
          ),
        agency: yup.string().required('Campo obrigatório.'),
        account: yup.string().required('Campo obrigatório.'),
        digit: yup.string().required('Campo obrigatório.'),
        bank: yup.string().required('Campo obrigatório.'),
      })
      .optional(),
    otherwise: yup.object().strip(),
  }),
})
