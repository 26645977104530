import { IReferralsBrokersSolicitations } from '@/interfaces'
import api from '../index'

export async function createReferralsBrokersSolicitations(body: Omit<IReferralsBrokersSolicitations, 'created_at' | 'id' | 'status' | 'updated_at'>) {
  const { data } = await api.instance.v2.post('brokers/referral-brokers-solicitations', body)
  return data
}

export async function get(document: string) {
  const response = await api.instance.v2.get('/brokers', {
    params: {
      document,
    },
  })

  return response.data[0]
}

export async function getReferralsBrokersSolicitations({ broker, creci }: { broker?: string; creci?: string }) {
  const { data } = await api.instance.v2.post('/brokers/referral-brokers-solicitations/search', {
    broker,
    creci
  })
  return data
}

export async function searchBySusep(susep: string) {
  const response = await api.instance.v2.get(`/brokers?susep=${susep}`)
  return response.data
}

export async function searchByDocument(document: string) {
  const response = await api.instance.v2.get(`/brokers?document=${document}`)
  return response.data
}

export async function searchByZipcode(zipcode: string) {
  const response = await api.instance.v2.get(`/brokers?zipcode=${zipcode}`)
  return response.data
}

export async function getRecommendedBroker() {
  const response = await api.instance.v2.get(`/brokers?type=referrals&limit=1`)
  return response.data[0]
}
