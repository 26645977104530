import { contractStatus } from '@/data/capitalizacao'
import { useContract } from '@/modules'
import { isCPF } from 'brazilian-values'
import { useEffect, useMemo, useState } from 'react'
import NewError from '../../shared/NewError'
import { Products } from '../../shared/entities'
import { First, Second, Third } from '../Steps'
import { Wrapper } from '../components'

const parseErrorMessage = {
  '@cap/unknown-integration-error': {
    title: '',
    message: 'Ocorreu um erro ao tentar se comunicar com o servidor',
  },
}

export const DetailsScreen = ({ enums, plans }) => {
  const [step, setStep] = useState<string | number>(0)
  const contract = useContract<Products.CAPITALIZACAO>()
  const _contractStatus = contract?.metadata?.status

  const shouldShowStepper = useMemo(() => {
    if (step === 1) return true

    if (step === 2) return true

    if (step === 3 && _contractStatus === contractStatus.AWAITING_PAYMENT) return true

    return false
  }, [_contractStatus, step])

  useEffect(() => {
    if (_contractStatus === contractStatus.DRAFT) {
      setStep(2)
    }

    if (_contractStatus && _contractStatus !== contractStatus.DRAFT) {
      setStep(3)
    }
    if (contract?.metadata?.error?.code === contractStatus.INTEGRATION_ERROR) setStep('error')
  }, [_contractStatus, contract?.metadata?.error?.code])

  const error = useMemo(() => {
    if (!contract.metadata.error) return

    if (typeof step == 'number' && [1, 3].includes(step)) return

    if (contract?.metadata?.error?.code === '@cap/integration-error') {
      const errors = contract?.metadata?.error?.cause?.erros

      if (Array.isArray(errors)) {
        const message = errors.map(value => value.mensagem as string)
        return { title: 'Crítica de negócio', message }
      }
    }

    const message = parseErrorMessage[contract?.metadata?.error?.code] || {}

    return message
  }, [contract?.metadata?.error, step])

  const headerButtonValues = {
    1: {
      icon: 'externalLink',
      kind: 'ghost',
      onClick: () =>
        window.open(
          'https://www.portoseguro.com.br/titulo-de-capitalizacao-para-aluguel#condicoes-gerais',
          '_blank',
        ),
      label: 'Condições Gerais',
    },
    2: {
      icon: 'edit',
      kind: 'ghost',
      onClick: () => {
        setStep(1)
      },
      label: 'Editar Orçamento',
    },
    error: {
      icon: 'edit',
      kind: 'ghost',
      onClick: () => {
        setStep(1)
      },
      label: 'Editar Orçamento',
    },
  }

  return (
    <Wrapper
      headerProps={{
        title: 'Título de Capitalização',
        subtitle: contract?.policy?.proposal && `Proposta nº ${contract.policy.proposal}`,
        // TODO: Será contemplado na tarefa CDR-1871
        action: isCPF(contract?.payload?.customer?.document) && headerButtonValues[step],
      }}
      showResume={step !== 3}
      stepperProps={shouldShowStepper && { currentStep: step }}
    >
      {step === 1 && (
        <First
          tenantProps={{ enums }}
          contractProps={{ plans }}
          setStep={setStep}
          isInDetailsScreen
        />
      )}
      {step === 2 && (
        <Second
          propertyProps={{ addressType: enums?.addressType }}
          ownerProps={{ addressType: enums?.addressType }}
          setStep={setStep}
        />
      )}
      {step === 3 && <Third tenantProps={{ pepEnum: enums?.pep }} />}
      {!!error && (
        <NewError product="Capitalização" errorTitle={error.title} errorMessage={error.message} />
      )}
    </Wrapper>
  )
}
