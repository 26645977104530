const economics = [
  {
    id: '10',
    description: 'ABRASIVOS - FABRICAS - COM INFLAMAVEIS',
  },
  {
    id: '11',
    description: 'ABRASIVOS FABRICAS - COM OU SEM INFLAMAVEIS',
  },
  {
    id: '20',
    description:
      'ACADEMIA DE GINASTICA/DANCAS/LUTAS/ESCOLA DE NATACAO OU ESPORTES',
  },
  {
    id: '30',
    description: 'ACOUGUE',
  },
  {
    id: '40',
    description: 'ACUCAR - DEPOSITOS',
  },
  {
    id: '45',
    description: 'AGENCIAS BANCARIAS (BANCOS)',
  },
  {
    id: '46',
    description: 'AGENCIA DE EMPREGOS OU TURISMO OU VIAGEM - TERREO OU SOBRADO',
  },
  {
    id: '48',
    description: 'AGENCIA DE EMPREGOS OU TURISMO OU VIAGEM - A PARTIR 1 ANDAR',
  },
  {
    id: '50',
    description: 'AGUAS MINERAIS OU AGUAS GASOSAS - DEPOSITO OU LOJA',
  },
  {
    id: '55',
    description: 'AGUAS MINERAIS OU AGUAS GASOSAS - ENGARRAFAMENTO',
  },
  {
    id: '60',
    description:
      'ANUNCIOS LUMINOSOS,LETREIROS OU PAINEIS - FABRICA E/OU OFICINAS',
  },
  {
    id: '65',
    description: 'ANTIGUIDADES, LOJAS DE',
  },
  {
    id: '70',
    description: 'APARELHOS ORTOPEDICOS -  DEPOSITO OU LOJAS',
  },
  {
    id: '80',
    description: 'ARMARINHOS - DEPOSITOS SEM VENDAS',
  },
  {
    id: '81',
    description: 'ARMARINHOS - LOJAS',
  },
  {
    id: '85',
    description:
      'ARTIGOS P/ PRESENTES: LOUCAS, PORCELANAS, CRISTAIS OU UT. DOMESTICAS',
  },
  {
    id: '86',
    description: 'ARMAS ( LOJAS OU DEPOSITOS )',
  },
  {
    id: '87',
    description: 'ARMAS (FABRICAS)',
  },
  {
    id: '90',
    description:
      'ARROZ-DEPOSITO C/ BENEF S/ PROCESSO DE DESCASCAGEM E/OU SECAGEM A FOGO',
  },
  {
    id: '100',
    description: 'ARTIGOS RELIGIOSOS (EXCLUIDO MATERIAIS EXPLOSIVOS)',
  },
  {
    id: '101',
    description: 'ASFALTO (PREPARACAO E DEPOSITO)',
  },
  {
    id: '102',
    description: 'ASILOS',
  },
  {
    id: '104',
    description: 'AUTOMOVEIS - LOCADORA COM AUTOMOVEIS',
  },
  {
    id: '105',
    description: 'AUTOMOVEIS (EXCLUSIVAMENTE CONCESSIONARIA)',
  },
  {
    id: '106',
    description: 'AUTOMOVEIS - LOJA (EXCLUIDO CONCESSIONARIA)',
  },
  {
    id: '107',
    description:
      'AUTOMOVEIS,OFICINA.MEC,FUNILARIA,ELETRICA,CENTROS AUTOM/INSTALACOES',
  },
  {
    id: '108',
    description:
      'AUTOMOVEIS - AUTO ELETRICO, PERMITINDO VENDA DE PECAS DE AUTO-ELETRICO',
  },
  {
    id: '109',
    description:
      'AUTOMOVEIS -COMPONENTES OU ACESSORIOS,LOJA OU DEPOSITO(NAO PERM.INST.)',
  },
  {
    id: '110',
    description: 'AVES OU OVOS - LOJAS (RELATIVO A ABATEDOURO OU COMERCIO)',
  },
  {
    id: '120',
    description: 'AVICULTURA  - DEPOSITOS OU LOJAS',
  },
  {
    id: '130',
    description: 'BALAS, BOMBONS, CARAMELOS OU CHOCOLATES - FABRICAS',
  },
  {
    id: '131',
    description: 'BALAS, BOMBONS, CARAMELOS OU CHOCOLATES - DEPOSITO OU LOJA',
  },
  {
    id: '132',
    description: 'BALAS, BOMBONS, CARAMELOS E CHOCOLATES, IND E COM',
  },
  {
    id: '140',
    description: 'BANHA (DEPOSITOS E LOJAS)',
  },
  {
    id: '150',
    description: 'BAR, BOTEQUIM, LANCHONETE E PASTELARIA',
  },
  {
    id: '151',
    description: 'BARES, LANCHONETES, PADARIAS, PIZZARIAS E RESTAURANTES',
  },
  {
    id: '155',
    description: 'COZINHA INDUSTRIAL OU SALAO DE BUFFET',
  },
  {
    id: '160',
    description: 'BEBIDAS - DEPOSITOS SEM VENDA',
  },
  {
    id: '161',
    description: 'BEBIDAS - DEPOSITOS COM VENDA (DISTRIBUIDORES)',
  },
  {
    id: '162',
    description: 'BEBIDAS - LOJAS (ADEGAS)',
  },
  {
    id: '165',
    description: 'BEBIDAS ALCOOLICAS - FABRICAS',
  },
  {
    id: '170',
    description: 'BICICLETAS - DEPOSITOS OU LOJAS (SEM OFICINAS DE CONSERTOS)',
  },
  {
    id: '180',
    description:
      'BIJOUTERIAS-FABRICA (S/EMPREGO DE METAIS PRECIOSOS OU SEMIPRECIOSOS)',
  },
  {
    id: '181',
    description:
      'BIJOUTERIAS-LOJAS OU DEPOSITOS (S/METAIS PRECIOSOS OU SEMIPRECIOSOS)',
  },
  {
    id: '182',
    description: 'BIJOUTERIAS (INDUSTRIA E COMERCIO)',
  },
  {
    id: '190',
    description: 'BILHARES OU BOLICHES, SALOES',
  },
  {
    id: '200',
    description:
      'BOLSAS, CINTOS, MALAS OU CONGENERES - DEP OU LOJAS SEM OFICINA',
  },
  {
    id: '201',
    description:
      'BOLSAS, CINTOS, MALAS OU CONGENERES - DEP OU LOJAS COM OFICINA',
  },
  {
    id: '202',
    description: 'BOLSAS, CINTOS, MALAS OU CONGENERES - FABRICA',
  },
  {
    id: '203',
    description: 'BOLSAS, CINTOS, MALAS E CONGENERES, IND E COM DE',
  },
  {
    id: '205',
    description: 'BORRACHEIRO (SEM RECAUCHUTAGEM)',
  },
  {
    id: '206',
    description: 'BORRACHA, LOJA DE PNEUS COM OFICINA',
  },
  {
    id: '207',
    description: 'BORRACHA - LOJA OU DEPOSITO DE ARTIGOS',
  },
  {
    id: '208',
    description: 'BORRACHA, RECAUCHUTAGEM DE PNEUS',
  },
  {
    id: '209',
    description: 'BORRACHA - FABRICA DE ARTIGOS',
  },
  {
    id: '210',
    description: 'BOTOES - FABRICA (EXCLUIDO DE MADEIRA)',
  },
  {
    id: '220',
    description: 'BRINQUEDOS - FABRICA DE (SEM EMPREGO DE MADEIRA)',
  },
  {
    id: '221',
    description: 'BRINQUEDOS - LOJAS OU DEPOSITOS',
  },
  {
    id: '222',
    description: 'BRINQUEDOS, IND E COM DE (S/EMPREGO DE MADEIRA)',
  },
  {
    id: '230',
    description: 'CABELEIREIROS, SALAO DE BELEZA E/OU ESTETICA',
  },
  {
    id: '240',
    description:
      'CAFE - DEPOSITO S/VENDA (INCLUSIVE BENEFICIAMENTO,EXCLUIDO TORREFACAO)',
  },
  {
    id: '241',
    description:
      'CAFE - DEPOSITO C/VENDA (INCLUSIVE BENEFICIAMENTO,EXCLUIDO TORREFACAO)',
  },
  {
    id: '250',
    description: 'CALCADOS - FABRICA',
  },
  {
    id: '251',
    description: 'CALCADOS - DEPOSITOS OU LOJAS',
  },
  {
    id: '252',
    description: 'CALCADOS (SAPATEIRO) - OFICINA',
  },
  {
    id: '253',
    description: 'CALCADOS, IND E COM',
  },
  {
    id: '260',
    description: 'CARIMBOS - DEPOSITOS OU LOJAS',
  },
  {
    id: '261',
    description: 'CARIMBOS - FABRICAS OU OFICINAS',
  },
  {
    id: '262',
    description: 'CARIMBOS, IND. E COM DE',
  },
  {
    id: '270',
    description: 'CARTORIOS',
  },
  {
    id: '280',
    description: 'CASAS DE BANHO (SAUNAS)',
  },
  {
    id: '282',
    description: 'PRODUTO PROPRIO (HOTEIS E POUSADAS)',
  },
  {
    id: '290',
    description: 'CHAPEUS - LOJAS OU DEPOSITOS (EXCLUIDO DE PALHA)',
  },
  {
    id: '291',
    description: 'CHAPEUS - FABRICA (EXCLUIDO DE PALHA)',
  },
  {
    id: '292',
    description: 'CHAPEUS EXCETO DE PALHA, IND E COM',
  },
  {
    id: '300',
    description: 'CHAVEIRO',
  },
  {
    id: '302',
    description: 'CEMITERIOS/VELORIOS/CREMATORIOS',
  },
  {
    id: '305',
    description: 'CERVEJA - FABRICA',
  },
  {
    id: '310',
    description: 'CIMENTO - DEPOSITO OU LOJAS DE ARTIGOS DE CIMENTO',
  },
  {
    id: '311',
    description: 'CIMENTO - FABRICA DE ARTIGOS DE CIMENTO',
  },
  {
    id: '312',
    description: 'CIMENTO - DEPOSITO OU LOJA DE CIMENTO',
  },
  {
    id: '320',
    description: 'CINEMAS',
  },
  {
    id: '330',
    description:
      'CIRURGICO,HOSPIT,DENTARIO,ARTS(EXCETO MEDICAMENTOS/EQP.ELETR) DEP/LOJA',
  },
  {
    id: '331',
    description:
      'CIRURGICOS, HOSPITALARES OU DENTARIOS - FABRICA DE ARTIGOS DE METAL',
  },
  {
    id: '335',
    description: 'COLCHOES - DEPOSITOS OU LOJAS SEM OFICINA',
  },
  {
    id: '337',
    description: 'CLUBE RECREATIVO',
  },
  {
    id: '340',
    description: 'CONFEITARIA',
  },
  {
    id: '343',
    description: 'COLAS (ENVASAMENTO E EMBALAGEM)',
  },
  {
    id: '345',
    description: 'COLCHOES, FABRICA DE',
  },
  {
    id: '350',
    description:
      'CONSERVAS ALIMENTICIAS DE ORIGEM VEGETAL - DEPOSITOS OU LOJAS',
  },
  {
    id: '351',
    description: 'CONSERVAS ALIMENTICIAS DE ORIGEM VEGETAL - FABRICAS',
  },
  {
    id: '360',
    description:
      'CONSULTORIO/CLINICA MEDICA OU DENTARIA (PAVTO TERREO OU SOBRADO)',
  },
  {
    id: '361',
    description: 'CONSULTORIO/CLINICA DENTARIA (PAVTO TERREO OU SOBRADO)',
  },
  {
    id: '362',
    description: 'CONSULTORIO/CLINICA MEDICA OU DENTARIA (A PARTIR DO 1 ANDAR)',
  },
  {
    id: '363',
    description: 'CONSULTORIO/CLINICA DENTARIA (A PARTIR DO 1 ANDAR)',
  },
  {
    id: '365',
    description: 'CONVENTOS/MOSTEIROS',
  },
  {
    id: '370',
    description:
      'COPIADORA PERMITINDO-SE ENCADERNACAO, PLASTIFICACAO E OUTROS INERENTES',
  },
  {
    id: '380',
    description: 'CORDOARIA (CORDAS, CORDOES) - DEPOSITO',
  },
  {
    id: '381',
    description: 'CORDOARIA (CORDAS, CORDOES) - FABRICAS',
  },
  {
    id: '382',
    description: 'CORREIO',
  },
  {
    id: '390',
    description: 'CORTICA - DEPOSITO OU LOJA DE ARTIGOS',
  },
  {
    id: '400',
    description: 'COUROS OU PELES - DEPOSITO OU LOJAS DE ARTIGOS',
  },
  {
    id: '401',
    description: 'COUROS OU PELES - FABRICA DE ARTIGOS SEM TRABALHO DE MADEIRA',
  },
  {
    id: '402',
    description: 'COUROS E PELES, IND. E COMERCIO',
  },
  {
    id: '403',
    description: 'DELIVERY - ENTREGAS EXPRESSAS DE REFEICOES',
  },
  {
    id: '404',
    description:
      'DEFENSIVOS AGRICOLAS,DEP. OU  LOJAS (EXCLUI SEMENTES, GRAOS E SIMILAR)',
  },
  {
    id: '405',
    description:
      'DESOCUPADO - IMOVEL COMERCIAL/INDUSTRIAL TOTALMENTE DESOCUPADO',
  },
  {
    id: '410',
    description: 'DESPACHANTES (ESCRITORIO)',
  },
  {
    id: '411',
    description: 'DESENTUPIDORA',
  },
  {
    id: '420',
    description: 'CDs, DVDs E SIMILARES - LOJAS',
  },
  {
    id: '421',
    description: 'DIVERSOES PREMIADAS',
  },
  {
    id: '430',
    description:
      'DOCES - LOJA E DEPOSITO (EXCLUIDO BALAS,CHOCOLATES OU CONFEITARIAS)',
  },
  {
    id: '431',
    description: 'DOCES, BALAS, CHOCOLATES OU CONFEITARIAS, FABRICA',
  },
  {
    id: '432',
    description:
      'DOCES, BALAS, CHOCOLATES - LOJAS / DEPï¿¿SITOS / DISTRIBUIDORAS',
  },
  {
    id: '433',
    description:
      'DOCES - DISTRIBUIDORA (EXCLUIDO BALAS, CHOCOLATES OU CONFEITARIAS)',
  },
  {
    id: '434',
    description: 'DOCES, IND. E COM.',
  },
  {
    id: '437',
    description: 'DRIVE-IN',
  },
  {
    id: '440',
    description: 'ELETRICOS OU DE ELETRICIDADE - DEPOSITO OU LOJAS DE ARTIGOS',
  },
  {
    id: '450',
    description: 'ELETRODOMESTICOS - LOJA E DEPOSITO',
  },
  {
    id: '451',
    description: 'ELETRODOMESTICOS, LOJAS / DEPOSITOS',
  },
  {
    id: '452',
    description: 'ELETRODOMESTICOS - (OFICINA DE CONSERTO)',
  },
  {
    id: '460',
    description:
      'ELETROELETRONICOS - DEPOSITO (EXCLUIDO EQUIP.DE INFORMAT OU TELEFONES)',
  },
  {
    id: '461',
    description:
      'ELETROELETRONICOS - LOJA/DEP.C/OFIC.CONS. EQUIP(EXCLU. BENS TERCEIROS)',
  },
  {
    id: '462',
    description: 'ELETROELETRONICOS - FABRICAS DE PECAS E EQUIPAMENTOS',
  },
  {
    id: '463',
    description:
      'ELETROELETRONICOS-LOJAS E DEP.C/OFIC.CONSERTO(EXCLUIDO EQ.INFO/TELEFS)',
  },
  {
    id: '470',
    description: 'EMBARCACOES - DEPOSITOS (SEM VENDA DE MOTORES DE POPA)',
  },
  {
    id: '471',
    description: 'EMBARCACOES (LOJA / DEPOSITO - SEM VENDA DE MOTORES DE POPA)',
  },
  {
    id: '475',
    description: 'EMPRESAS DE ONIBUS E/OU TAXIS',
  },
  {
    id: '476',
    description: 'EQUIPAMENTOS DE SEGURANCA/PROTECIONAIS - LOJA/DEPOSITO',
  },
  {
    id: '477',
    description: 'ENCOMENDAS EXPRESSAS - TERREO',
  },
  {
    id: '478',
    description: 'ENCOMENDAS EXPRESSAS (A PARTIR 1.ANDAR)',
  },
  {
    id: '479',
    description: 'ESCOLA PUBLICA',
  },
  {
    id: '480',
    description: 'ESCOLAS PARTICULARES - INFORMATICA',
  },
  {
    id: '481',
    description: 'ESCOLAS PARTICULARES - OUTRAS (INCLUSIVE AUTO ESCOLA)',
  },
  {
    id: '482',
    description:
      'ESCRITORIOS - LIMPEZA,VIGILANC,COBRANCA,ENGENH,CONSTRUT-TERREO/SOBRADO',
  },
  {
    id: '484',
    description:
      'ESCRITORIOS DEMAIS - TERREO/SOBRADOS(EXCETO INFORMATICA/PUBLICIDADE)',
  },
  {
    id: '485',
    description:
      'ESCRIT ENGENHARIA/SERV LIMPEZA, VIGILANCIA, COBRANCA- A PARTIR 1. AND',
  },
  {
    id: '487',
    description:
      'ESCRITORIOS DEMAIS- A PARTIR 1 AND.(EXCETO INFORMATICA OU PUBLICIDADE)',
  },
  {
    id: '488',
    description:
      'ESCRITORIOS DE SERV INFORMATICA OU PUBLIC - TERREO OU SOBRADO',
  },
  {
    id: '489',
    description: 'AGENCIA DE TURISMO E VIAGENS (TERREO/SOBRADO)',
  },
  {
    id: '490',
    description: 'SERV.TEMP, COBR, PREST.SERV DE VIG/CONST(TERREO/SOBRADO)',
  },
  {
    id: '491',
    description: 'DEMAIS ESCRIT, EXCETO DESP,BCO,SINDICATO (TERREO/SOBRADO)',
  },
  {
    id: '492',
    description: 'AGENCIA DE TURISMO E VIAGENS (A PARTIR 1.ANDAR)',
  },
  {
    id: '493',
    description: 'SERV.TEMP,COB,PREST.SERVICO,VIG,CONSTR.(A PARTIR 1.ANDAR)',
  },
  {
    id: '494',
    description: 'DEMAIS ESCRIT,EXCETO DESP,BCO,SINDICATO(A PARTIR 1.ANDAR)',
  },
  {
    id: '495',
    description: 'ESCRITORIOS DE SERVICOS DE INFORMATICA',
  },
  {
    id: '496',
    description: 'ESCRIT DE SERV DE INFO OU PUBLIC - A PARTIR DO 1ï¿¿ANDAR',
  },
  {
    id: '497',
    description:
      'ESCRIT DE SERV DE INFO OU PUBLIC.TERREO/SOBRA.(EXCL ESTAB LOC HR/TEMPO',
  },
  {
    id: '500',
    description:
      'ESCRIT (EXCETO MICROS/PERIF.) DEP E LOJAS C/EXISTENCIA DE MAQ ART',
  },
  {
    id: '501',
    description:
      'ESCRIT (EXCETO MICROS/PERIF.) DEP E LOJAS S/EXISTENCIA DE MAQ ART',
  },
  {
    id: '505',
    description:
      'ESTACIONAMENTO E/OU LAVA RAPIDO, SEM OFICINA OU ABASTECIMENTO',
  },
  {
    id: '510',
    description:
      'ESPORTE (DEPOSITO OU LOJA, EXCLUINDO ARMAS E MUNICOES ), ARTIGOS DE',
  },
  {
    id: '520',
    description: 'ESTABELECIMENTOS HIDROTERAPICOS',
  },
  {
    id: '521',
    description: 'ESTAMPARIA DE TECIDOS E/OU ROUPAS',
  },
  {
    id: '523',
    description:
      'ESTACAO RETRANSMISSORA DE RADIO OU TELEVISAO / TORRE DE RETRANSMISSAO',
  },
  {
    id: '525',
    description: 'EXTINTORES C/OFIC. DE RECARGA E LOJAS, DEPOSITO',
  },
  {
    id: '526',
    description: 'ESTUDIO DE GRAVACAO DE SOM E IMAGEM',
  },
  {
    id: '530',
    description: 'FARINHAS, DEPOSITOS OU LOJAS',
  },
  {
    id: '535',
    description: 'EXPOSICOES E SIMILARES',
  },
  {
    id: '540',
    description: 'FARMACIAS OU DROGARIAS (DEPOSITOS)',
  },
  {
    id: '541',
    description: 'FARMACIAS OU DROGARIAS (LOJAS)',
  },
  {
    id: '542',
    description: 'FARMACEUTICOS, PRODUTOS - FABRICA',
  },
  {
    id: '550',
    description: 'FERRAGENS OU FERRAMENTAS, DEPOSITO DE',
  },
  {
    id: '551',
    description:
      'FERRAGENS OU FERRAMENTAS (VENDA), SEM VENDA DE MATERIAL DE CONSTRUCAO',
  },
  {
    id: '560',
    description: 'FITAS,RENDAS,LINHA, C/FIBRAS NATURAIS, FABRICA',
  },
  {
    id: '561',
    description:
      'FITAS,RENDAS,BORDADOS OU LINHAS C/FIBRAS ARTIFICIAIS, FABRICA',
  },
  {
    id: '562',
    description: 'FITAS, RENDAS, BORDADOS OU LINHAS, LOJAS OU DEPOSITOS DE',
  },
  {
    id: '570',
    description: 'FLORES NATURAIS, LOJAS DE',
  },
  {
    id: '571',
    description: 'FLORES ARTIFICIAIS, FABRICA OU LOJAS',
  },
  {
    id: '580',
    description: 'ESTUDIO FOTOGRAFICO, FOTOGRAFO, LABORATORIO FOTOGRAFICO',
  },
  {
    id: '581',
    description: 'FOTOGRAFOS',
  },
  {
    id: '582',
    description: 'FOTOGRAFICO, LABORATORIO',
  },
  {
    id: '583',
    description: 'FOTOGRAFICO, DEPOSITO OU LOJA DE ARTIGO',
  },
  {
    id: '590',
    description: 'FRIGORIFICO (MERC. EM CAMARAS DE REFRIG. EXCLUSIVAMENTE DEP)',
  },
  {
    id: '591',
    description: 'FRIOS, SEM MATADOURO (CAMARAS DE REFRIGERAC), FABRICA DE',
  },
  {
    id: '600',
    description: 'FRUTAS, DEPOSITOS OU LOJAS',
  },
  {
    id: '610',
    description: 'FUMOS,CHARUTOS/CIGARROS (LOJAS PERMITINDO-SE ART.P/FUMANTES)',
  },
  {
    id: '620',
    description: 'FUNEBRES (LOJAS S/OFIC. DE ARMADORES, S/ESTOFAMENTO), ART',
  },
  {
    id: '621',
    description: 'FUNEBRES (LOJAS C/OFIC. DE ARMADORES, S/ESTOFAMENTO), ART',
  },
  {
    id: '630',
    description: 'GELO (SEM EMPREGO DE MAT. IMFLAMAVEL), FAB DEP OU LOJAS DE',
  },
  {
    id: '640',
    description: 'GRAFICAS OU TIPOGRAFIAS (COM OU SEM ROTOGRAVURA)',
  },
  {
    id: '641',
    description: 'GRAFICAS OU TIPOGRAFIAS COM ROTOGRAVURA',
  },
  {
    id: '650',
    description: 'GUARDA-CHUVAS, GUARDA-SOIS OU BENGALAS - LOJAS OU OFICINAS',
  },
  {
    id: '651',
    description:
      'GUARDA-CHUVAS,GUARDA-SOIS OU BENGALAS - FABRICA DE (EXCLUINDO MADEIRA)',
  },
  {
    id: '652',
    description: 'GUARDA-CHUVAS, GUARDA-SOIS E BENGALAS, IND E COMERCIO DE',
  },
  {
    id: '655',
    description: 'HANGARES',
  },
  {
    id: '660',
    description: 'HOSPITAIS',
  },
  {
    id: '665',
    description: 'PRODUTO PROPRIO - HOTEIS E POUSADAS',
  },
  {
    id: '668',
    description: 'IGREJAS',
  },
  {
    id: '669',
    description: 'IMOBILIARIA',
  },
  {
    id: '670',
    description: 'INSTRUMENTOS MUSICAIS, PERMITINDO-SE OFICINA, DEP OU LOJA',
  },
  {
    id: '671',
    description: 'INFORMATICA - ESCRITORIO DE SERVICOS DE INFORMATICA',
  },
  {
    id: '672',
    description: 'INFORMATICA - LOJA OU DEPOSITO SEM OFICINA DE CONSERTO',
  },
  {
    id: '673',
    description:
      'INFORMATICA - FABRICA OU MONTAGEM DE PECAS E/OU EQUIP. DE INFORMATICA',
  },
  {
    id: '674',
    description: 'INFORMATICA - PROVEDORES DE INTERNET',
  },
  {
    id: '675',
    description: 'JOIAS, FABRICA DE',
  },
  {
    id: '676',
    description: 'JOIAS, COM OU SEM OFICINA, LOJA DE',
  },
  {
    id: '677',
    description:
      'INFORMATICA LOJA/DEPOSITO C/ OU S/ OFICINA (EXCLUI BENS DE TERCEIROS)',
  },
  {
    id: '678',
    description: 'JOIAS, FABRICA DE (PREDIO E MAQUINISMO)',
  },
  {
    id: '679',
    description: 'JOIAS, COM OU SEM OFICINA, LOJA DE (PREDIO E MAQUINISMO)',
  },
  {
    id: '680',
    description:
      'JORNAIS OU REVISTAS, EXCLUINDO BANCAS DE VENDA (LOJAS OU DEPOSITOS)',
  },
  {
    id: '687',
    description: 'KARTODROMO',
  },
  {
    id: '690',
    description:
      'LAB. DE PESQUISAS OU ANALISE, CLINICAS, QUIMICAS OU FARMACEUTICAS',
  },
  {
    id: '700',
    description: 'LADRILHOS OU CERAMICAS, FABRICA DE',
  },
  {
    id: '701',
    description: 'LADRILHOS OU CERAMICAS, DEPOSITOS OU LOJAS',
  },
  {
    id: '702',
    description: 'LADRILHOS E CERAMICAS, IND E COMERCIO',
  },
  {
    id: '710',
    description: 'LATICINIOS, DEPOSITO DE',
  },
  {
    id: '711',
    description: 'LATICINIOS, LOJAS / DEPOSITOS',
  },
  {
    id: '712',
    description: 'LATICINIOS, FABRICA',
  },
  {
    id: '713',
    description:
      'LAN HOUSE/CYBER CAFE (EXCLUSIVAMENTE EM SHOP.CENTER OU HIPERMERCADOS)',
  },
  {
    id: '714',
    description:
      'LAN HOUSE/CYBER CAFE (FORA DE SHOPPING CENTERS OU HIPERMERCADOS)',
  },
  {
    id: '720',
    description: 'LAVANDERIAS, SEM TINTURARIA (EXCETO INDUSTRIAL)',
  },
  {
    id: '721',
    description: 'LAV. INDL (LAV.,DESBOT.,TING DE ROUP.FIOS E TECID)VIDE TINTU',
  },
  {
    id: '730',
    description: 'LIMPEZA, LOJAS OU DEPOSITOS DE ARTIGOS DE',
  },
  {
    id: '731',
    description: 'LIMPEZA, FABRICA DE ARTIGOS DE',
  },
  {
    id: '740',
    description: 'LIVROS, DEPOSITO DE',
  },
  {
    id: '741',
    description:
      'LIVROS, REVISTAS E JORNAIS, LOJAS/DEPOSITOS (EXCETO BANCA DE JORNAIS)',
  },
  {
    id: '742',
    description: 'LOCADORA',
  },
  {
    id: '750',
    description: 'SHOPPINGS, LOJAS EM - ENQUADR. P/ ATIVIDADE PRINCIPAL DESC',
  },
  {
    id: '760',
    description: 'LONA, FABRICAS DE ARTIGOS DE',
  },
  {
    id: '761',
    description: 'LONA, LOJA E DEPOSITO DE ARTIGOS',
  },
  {
    id: '762',
    description: 'LONA, ARTIGOS DE LOJA',
  },
  {
    id: '770',
    description: 'LOTERICAS',
  },
  {
    id: '780',
    description: 'LOUCAS, PORCELANAS, CRISTAIS OU UTIL. DOMESTICAS, FAB DE',
  },
  {
    id: '781',
    description: 'LOUCAS, PORCELANAS, CRISTAIS OU UTIL. DOMESTICAS, DEP DE',
  },
  {
    id: '782',
    description: 'LOUCAS, PORCELANAS, CRISTAIS OU UTIL. DOMESTICAS, LOJAS DE',
  },
  {
    id: '783',
    description: 'LUSTRES - DEPOSITOS OU LOJAS',
  },
  {
    id: '784',
    description: 'LUSTRES - FABRICA',
  },
  {
    id: '785',
    description: 'MADEIRA (LOJA E DEP. DE ARTIGOS)',
  },
  {
    id: '786',
    description: 'MARCENARIA',
  },
  {
    id: '787',
    description: 'MARMORARIA',
  },
  {
    id: '790',
    description:
      'MAQUINAS COMERCIAIS E INDUSTRIAIS - DEPOSITOS OU LOJAS DE MAQUINAS',
  },
  {
    id: '791',
    description: 'MAQ./APARELHOS ELETRO-MECANICOS C/MOTOR INCORP. (LOJAS/DEP.)',
  },
  {
    id: '792',
    description: 'MAQ./APARELHOS DE REFRIGERACAO ( LOJAS OU DEPOSITOS)',
  },
  {
    id: '793',
    description: 'MAQ./APARELHOS PARA COMERCIO OU PREPARO DE ALIMENTOS',
  },
  {
    id: '794',
    description: 'MAQ./APARELHOS P/IND. DE ROUPAS, CALCADOS OU ART. DE COURO',
  },
  {
    id: '795',
    description: 'MAQ./APARELHOS DE MEDIDA, REGULACAO, CONTROLE, PESAGEM ETC.',
  },
  {
    id: '796',
    description: 'MAQUINAS OU IMPLEMENTOS AGRICOLAS',
  },
  {
    id: '797',
    description: 'MAQUINAS LEVES P/TRABALHOS PROFISSIONAIS (FURADEIRAS,ETC)',
  },
  {
    id: '798',
    description: 'MAQUINAS DEMAIS, DEPOSITO OU LOJA',
  },
  {
    id: '799',
    description: 'MARMORE - LOJA OU DEPOSITO DE ARTIGOS',
  },
  {
    id: '810',
    description: 'MASSAS ALIMENTICIAS, INCLUSIVE ROTISSERIE, FABRICAS OU LOJAS',
  },
  {
    id: '820',
    description: 'MATERIAL PARA CONSTRUCAO (DEPOSITO OU LOJA)',
  },
  {
    id: '830',
    description: 'MEIAS, FABRICA DE',
  },
  {
    id: '831',
    description: 'MEIAS, DEPOSITOS E/OU LOJAS DE',
  },
  {
    id: '832',
    description: 'MEIAS, LOJAS DE',
  },
  {
    id: '840',
    description:
      'METAL, FABRICA DE (FERRO OU ACO EM BLOCOS, VERGALHOES OU LAMINADOS)',
  },
  {
    id: '841',
    description:
      'METAL (EXCLUIDO PRECIOSO, SEMIPRECIOSO E SUCATAS), DEPOSITO DE',
  },
  {
    id: '842',
    description:
      'METAL (EXCLUIDO PRECIOSO, SEMIPRECIOSO E SUCATAS)FAB PECAS, ART E MAQ.',
  },
  {
    id: '843',
    description: 'METAL - FABRICA DE ARTIGOS E MOTORES ELETRICOS, E OUTROS',
  },
  {
    id: '844',
    description:
      'FABR - COMP MAQS P/IND ROUPA/ALIMENTO/ARTIGO COURO/GRAFICA - METAL',
  },
  {
    id: '845',
    description:
      'FABR - FERRAG, FERRAMENT E ROLAMENT - (PANELA,TALHER,CABO ACO)-METAL',
  },
  {
    id: '846',
    description:
      'FABR - PECAS P/ELETRODOMEST/FOGAO/GELAD-(INCLUSIVE INDUSTR) - METAL',
  },
  {
    id: '847',
    description:
      'FABR - PECAS E MAQ LEVES(FURAD/LIXAD/MAQ SOLDA/EXTINTOR) - METAL',
  },
  {
    id: '848',
    description:
      'FABR - ARTIGO ORTOPE/AP CIENTIF/MEDIC/PRECIS/INSTR MUSICAIS - METAL',
  },
  {
    id: '850',
    description: 'MOLDURAS, DEPOSITO OU LOJA COM OU SEM OFICINA',
  },
  {
    id: '851',
    description: 'MOLDURAS SEM OFICINA, DEPOSITO OU LOJAS DE',
  },
  {
    id: '855',
    description: 'MOTOCICLETAS (EXCLUSIVAMENTE CONCESSIONARIAS), LOJAS DE',
  },
  {
    id: '859',
    description: 'MOVEIS COM OFICINA, DEPOSITOS OU LOJAS DE (PERMITINDO OFIC)',
  },
  {
    id: '860',
    description: 'MOVEIS SEM OFICINA, DEPOSITOS OU LOJAS DE',
  },
  {
    id: '861',
    description: 'MOVEIS, FABRICA COM ESTOFAMENTO',
  },
  {
    id: '862',
    description: 'MOVEIS, FABRICA SEM ESTOFAMENTO',
  },
  {
    id: '863',
    description: 'ONGS / ENTIDADES FILANTROPICAS',
  },
  {
    id: '870',
    description: 'ELETROMECANICOS, OFICINA DE CONSERTO DE EQUIPAMENTOS',
  },
  {
    id: '880',
    description: 'OFIC. MECANICA E/OU ELETRICA S/VENDA DE PECAS E ACESSORIOS',
  },
  {
    id: '890',
    description: 'OLEOS VEGETAIS, DEPOSITOS OU LOJAS DE',
  },
  {
    id: '891',
    description: 'FABRICA',
  },
  {
    id: '900',
    description: 'OTICA (SEM VENDA DE JOIAS OU RELOGIOS) C/OU S/OFIC, ARTIGOS',
  },
  {
    id: '910',
    description: 'PADARIAS',
  },
  {
    id: '920',
    description: 'PAPELARIA, ARTIGOS DE OU DEPOSITO OU LOJA DE PAPEL',
  },
  {
    id: '925',
    description:
      'PAPEL/PAPELAO-FAB DE ART (S/PROC RECICLAGEM E/OU UTILIZ APARAS/SUCATA)',
  },
  {
    id: '930',
    description: 'PECAS E ACESSORIOS P/VEICULOS (EXCETO MOTOC) DEP OU LOJAS',
  },
  {
    id: '931',
    description: 'VEDACOES, FABRICAS (EXCLUSIVAMENTE METAL - VIDE METAL)',
  },
  {
    id: '940',
    description: 'PEIXARIA',
  },
  {
    id: '950',
    description: 'PERFUMARIA (DEPOSITOS OU LOJAS)',
  },
  {
    id: '951',
    description: 'PERFUMARIA (INDUSTRIA SEM FABRICACAO DE SABONETES OU VERNIZ)',
  },
  {
    id: '960',
    description:
      'PLASTICOS (SEM CELULOIDE) DEPOSITO OU LOJA - EXCLUINDO SUCATAS',
  },
  {
    id: '961',
    description:
      'PLASTICOS (SEM CELULOIDE), FABRICA DE ARTIGOS - EXCLUINDO SUCATAS',
  },
  {
    id: '962',
    description:
      'PLASTICOS (S/CELULOIDE), IND E COM DE ARTIGOS - EXCETO SUCATAS',
  },
  {
    id: '963',
    description:
      'PNEUS - DEPOSITO OU LOJA (PNEUS NOVOS, RECAUCHUTADOS OU VULCANIZADOS)',
  },
  {
    id: '964',
    description: 'PET SHOP',
  },
  {
    id: '965',
    description: 'POSTOS DE GASOLINA / SERVICOS',
  },
  {
    id: '970',
    description: 'INSTRUMENTOS PRECISAO OU MEDICAO (LOJAS VIDE RUBRICA MAQ.',
  },
  {
    id: '975',
    description: 'FARMACEUTICOS PROD, - LOJAS/DEP.VIDE FARMCAIAS E DROGARIA',
  },
  {
    id: '977',
    description: 'PRODUTOS QUIMICOS, LOJA/DEPOSITO, SEM MANIPULACAO',
  },
  {
    id: '978',
    description: 'PRODUTOS QUIMICOS, FABRICA',
  },
  {
    id: '979',
    description: 'PRODUTOS QUIMICOS, DEPOSITO DE PRODUTOS',
  },
  {
    id: '980',
    description: 'PROTESE, OFICINAS',
  },
  {
    id: '990',
    description: 'QUITANDAS',
  },
  {
    id: '993',
    description: 'TORRE DE RETRANSMISSAO',
  },
  {
    id: '995',
    description: 'RACOES, BALANC.P/ AGROP FABRICA DE, SEM MOINHO (FORRAGENS)',
  },
  {
    id: '996',
    description:
      'RACOES BALANCEADAS PARA AGROPECUARIA (FABRICA COM OU SEM MOINHO)',
  },
  {
    id: '997',
    description: 'RACOES, BALANCEADAS P/ AGROPECUARIA DEPOSITO OU LOJA DE',
  },
  {
    id: '998',
    description: 'RESTAURANTE, CHOPERIA, CHURRASCARIA, PIZZARIA',
  },
  {
    id: '999',
    description: 'RESIDENCIA',
  },
  {
    id: '1000',
    description: 'ROUPAS, FABRICAS OU OFICINAS DE',
  },
  {
    id: '1001',
    description: 'ROUPAS, LOJAS DE',
  },
  {
    id: '1002',
    description: 'ROUPAS, DEPOSITO DE',
  },
  {
    id: '1003',
    description: 'ROUPAS (ALUGUEL COM OU SEM FABRICACAO NO LOCAL)',
  },
  {
    id: '1004',
    description: 'ROUPAS (ALUGUEL SEM FABRICACAO NO LOCAL)',
  },
  {
    id: '1005',
    description: 'ROUPAS, IND. E COM DE',
  },
  {
    id: '1006',
    description: 'RECARGA DE CARTUCHOS DE IMPRESSAO',
  },
  {
    id: '1009',
    description: 'SABAO OU SABONETE, FABRICA',
  },
  {
    id: '1010',
    description: 'SABAO OU SABONETE, DEPOSITOS OU LOJAS DE',
  },
  {
    id: '1011',
    description: 'SACOLAO',
  },
  {
    id: '1020',
    description: 'SAL, DEPOSITO DE',
  },
  {
    id: '1030',
    description: 'SANITARIOS, DEPOSITOS OU LOJAS DE ARTIGOS',
  },
  {
    id: '1040',
    description:
      'SERRALHERIAS - FAB PORTOES,GRADES,ESQUADRIAS,CAIXILHOS, EXCETO MADEIRA',
  },
  {
    id: '1041',
    description: 'SHOPPING CENTER',
  },
  {
    id: '1042',
    description:
      'SILK SCREEN/ESTAMPARIA DE ROUPAS E TECIDOS-PERMITINDO A VENDA ARTIGOS',
  },
  {
    id: '1043',
    description: 'SILK SCREEN - DE ROUPAS',
  },
  {
    id: '1045',
    description: 'SINDICATO DE EMPRESAS E/OU TRABALHADORES (SEM MARCENARIA)',
  },
  {
    id: '1050',
    description: 'SORVETERIA',
  },
  {
    id: '1060',
    description: 'SECOS E MOLHADOS (SUPERMERCADO, MERCEARIA), LOJA (SEM VENDA)',
  },
  {
    id: '1061',
    description:
      'SUPERMERCADOS,MERCEARIAS/EMPORIOS (SECOS E MOLHADOS) - LOJAS/DEPOSITOS',
  },
  {
    id: '1070',
    description:
      'TAPETES,CARPETES,FORRACOES OU CORTINAS (EXCLUINDO IMP.) FABR. DE',
  },
  {
    id: '1071',
    description:
      'TAPETES,CARPETES,FORRACOES OU CORTINAS (EXCLUINDO IMP.) DEP. OU LOJA',
  },
  {
    id: '1072',
    description: 'TAPETES, CARPETES OU CORTINAS (EXCETO IMP) LOJAS SEM OFICI',
  },
  {
    id: '1073',
    description: 'TAPETES, CARPETES OU CORTINAS (EXCETO IMP) LOJAS COM OFICI',
  },
  {
    id: '1074',
    description: 'TAPETES, CARPETES E CORTINAS (EXCETO IMP) IND E COM',
  },
  {
    id: '1078',
    description:
      'TEATROS, SALOES DE CONCERTOS OU CINEMAS(EXCLUIDOS CIRCOS OU SIMILARES)',
  },
  {
    id: '1080',
    description: 'TECIDOS OU FIOS (FIACAO OU TECELAGEM COM FIBRAS NATURAIS)',
  },
  {
    id: '1081',
    description: 'TECIDOS OU FIOS (FIACAO OU TECELAGEM COM FIBRAS ARTIFICIAIS)',
  },
  {
    id: '1082',
    description: 'TECIDOS OU FIOS, DEPOSITOS DE',
  },
  {
    id: '1083',
    description:
      'TECIDOS OU FIOS (PERMITE ARTIGOS CAMA/MESA/BANHO)-LOJA/DEPOSITO',
  },
  {
    id: '1084',
    description: 'TECIDOS, IND E COM DE',
  },
  {
    id: '1085',
    description: 'TELEFONES, INCLUSIVE CELULARES - DEPOSITO',
  },
  {
    id: '1086',
    description:
      'TELEFONES, INCLUSIVE CELULARES - LOJA (EXCLUSIVAMENTE EM SHOPPINGS)',
  },
  {
    id: '1087',
    description: 'TELEFONES, INCLUSIVE CELULARES - FABRICA',
  },
  {
    id: '1090',
    description: 'TINTAS SEM MANIPULACAO, DEPOSITO DE',
  },
  {
    id: '1091',
    description:
      'TINTAS, LOJA/DEPOSITO, S/ MANIP, PERMITINDO-SE MAQUINA P/MISTURA CORES',
  },
  {
    id: '1092',
    description: 'TINTAS, FABRICAS',
  },
  {
    id: '1100',
    description: 'TINTURARIAS DE FIOS OU TECIDOS, INCLUSIVE INDUSTRIAIS',
  },
  {
    id: '1101',
    description:
      'TINTURARIAS (INCLUSIVE LAVANDERIAS INDUSTRIAIS) DE ROUPAS/FIOS/TECIDOS',
  },
  {
    id: '1102',
    description: 'TORRE DE TRANSMISSAO',
  },
  {
    id: '1110',
    description:
      'TRANSPORTADORA, EXCLUIDO DE INFLAMAVEIS, TOXICOS OU CORROSIVOS',
  },
  {
    id: '1111',
    description: 'VETERINARIO',
  },
  {
    id: '1119',
    description: 'VIDROS E/OU ARTIGOS DE VIDROS, FABRICA',
  },
  {
    id: '1120',
    description: 'VIDROS OU ESPELHOS (VIDRACEIRO), LOJAS OU OFICINAS',
  },
  {
    id: '1121',
    description: 'TRANSPORTE DE PASSAGEIROS',
  },
  {
    id: '1123',
    description: 'FATURAMENTO VIDA - DIVERSAS ATIVIDADES',
  },
  {
    id: '1125',
    description: 'VINAGRE (FABRICA, ENGARRAFAMENTO E DEPOSITO)',
  },
  {
    id: '1130',
    description: 'SEGURO PARA EDIFICIO COM VARIAS EMPRESAS',
  },
  {
    id: '1140',
    description: 'CONSULTORIA TECNICA EM ENGENHARIA',
  },
  {
    id: '1150',
    description: 'TELECOMUNICACOES',
  },
  {
    id: '1900',
    description: 'ACORDOS ESPECIAIS',
  },
  {
    id: '1901',
    description: 'ACORDO METROPOLITANA PMC - ESCOLAS',
  },
  {
    id: '1902',
    description: 'ACORDO METROPOLITANA PMC - FUNDACOES CULTURAIS',
  },
  {
    id: '1903',
    description: 'ACORDO METROPOLITANA PMC - ARMAZENS',
  },
  {
    id: '1904',
    description: 'ACORDO METROPOLITANA PMC - ESCRITORIOS/ADMINISTRACOES',
  },
  {
    id: '1905',
    description: 'ACORDOS ESPECIAIS - JANI OTACILIO MENDES E CIA LTDA',
  },
  {
    id: '1906',
    description: 'ACORDOS ESPECIAIS - TECNOSEG TECNOLOGIA EM SERVICOS',
  },
  {
    id: '1907',
    description: 'ACORDOS ESPECIAIS - TOTAL LIMPEZA',
  },
  {
    id: '1908',
    description: 'ACORDOS ESPECIAIS - ENFORCE',
  },
  {
    id: '1909',
    description: 'ACORDOS ESPECIAIS - CASTILHO',
  },
  {
    id: '1910',
    description: 'ACORDOS ESPECIAIS - SMI',
  },
  {
    id: '1911',
    description: 'ACORDOS ESPECIAIS - NEW LINE',
  },
  {
    id: '1912',
    description: 'ACORDOS ESPECIAIS - KRATF & CIA',
  },
  {
    id: '1913',
    description: 'ACORDOS ESPECIAIS - COOP. VIGILANTES DE MT',
  },
  {
    id: '1914',
    description: 'ACORDOS ESPECIAIS - SEGURANCA ELETRONICA FOX',
  },
  {
    id: '1915',
    description: 'ACORDOS ESPECIAIS - SENA SEGURANCA ELETRONICA LTDA',
  },
  {
    id: '1916',
    description: 'ACORDO ESPECIAL - SEGVEL - SEG. E VIGILANCIA ELETRONICA',
  },
  {
    id: '1917',
    description: 'ACORDO ESPECIAL - STORAGE CAMPINAS',
  },
  {
    id: '1918',
    description: 'ACORDOS ESPECIAIS - KRONOS SEGURANCA',
  },
  {
    id: '1919',
    description: 'ACORDOS ESPECIAIS - RODRIGO AUGUSTO',
  },
  {
    id: '1920',
    description: 'ACORDOS ESPECIAIS - KRONOS SEGURANCA',
  },
  {
    id: '1921',
    description: 'ACORDOS ESPECIAIS - GENTLEMAN SEGURANCA',
  },
  {
    id: '1922',
    description: 'ACORDOS ESPECIAIS - FORT SUL ALARME',
  },
  {
    id: '1923',
    description: 'ACORDOS ESPECIAIS - G5 SISTEMA INTEG DE SEGURANCA LTDA.',
  },
  {
    id: '1924',
    description: 'ACORDOS ESPECIAIS - SANTOS & SANTOS ALARMES E SERVICOS LTDA',
  },
  {
    id: '1925',
    description: 'ACORDOS ESPECIAIS - CONTE E LUCHESE LTDA',
  },
  {
    id: '1926',
    description: 'ACORDOS ESPECIAIS - PBM ALARMES MONITORADOS',
  },
  {
    id: '1927',
    description: 'ACORDOS ESPECIAIS - HAROLDO JOSE DA SILVA',
  },
  {
    id: '1928',
    description: 'ACORDOS ESPECIAIS - DIVE PARCEL COM LTDA',
  },
  {
    id: '1929',
    description: 'ACORDOS ESPECIAIS - SENTINELA ELETRONICA LTDA',
  },
  {
    id: '1930',
    description:
      'ACORDOS ESPECIAIS - MULTI SERVICE SERVICOS DE VIGILANCIA LTDA',
  },
  {
    id: '1931',
    description: 'ACORDOS ESPECIAIS - P P ALARMES E SEGURANCA ELETRONICA',
  },
  {
    id: '1932',
    description: 'ACORDOS ESPECIAIS - OSMAR HENRIQUE MULLER & CIA LTDA',
  },
  {
    id: '1933',
    description: 'ACORDOS ESPECIAIS - LINCE SERVICOS DE GUARDA EXTENSIVA LTDA',
  },
  {
    id: '1934',
    description: 'ACORDOS ESPECIAIS - MJB COM EQUIP ELETR E GEST PESSOAS',
  },
  {
    id: '1935',
    description: 'ACORDOS ESPECIAIS - ELO SISTEMAS',
  },
  {
    id: '1936',
    description: 'ACORDOS ESPECIAIS - MJB  VIGILANCIA E SEGURANCA LTDA',
  },
  {
    id: '1937',
    description: 'ACORDOS ESPECIAIS  - ALARMES MONITORADOS',
  },
  {
    id: '1938',
    description: 'ACORDOS ESPECIAIS - NEW LINE (ESCOLAS)',
  },
  {
    id: '1939',
    description:
      'ACORDO ESPECIAL - MASP MARANHENSE SERVICOS PROFISSIONAIS LTDA',
  },
  {
    id: '1940',
    description: 'ACORDO ESPECIAL - DELTASEG LTDA',
  },
  {
    id: '1941',
    description: 'ACORDO ESPECIAL - VALORIZA COMERCIO E LIMPEZA LTDA',
  },
  {
    id: '1942',
    description: 'ACORDO ESPECIAIS - A NACIONAL VIG E SEGURANCA',
  },
  {
    id: '1943',
    description: 'ACORDO ESPECIAIS - CORAL SATY',
  },
  {
    id: '1944',
    description: 'ACORDOS ESPECIAIS - NORTEC EQUIPTOS ELETRONICOS',
  },
  {
    id: '1945',
    description: 'ACORDOS ESPECIAIS - FEDERAL SEGURANCA',
  },
  {
    id: '1946',
    description: 'ACORDOS ESPECIAIS - RETAGUARDA SISTEMA DE SEGURANCA',
  },
  {
    id: '1947',
    description: 'ACORDO ESPECIAL - EBV EMPRESA BRASILEIRA DE VIGILANCIA',
  },
  {
    id: '1948',
    description: 'ACORDO ESPECIAL - ATUS VIGILANCIA LTDA.',
  },
  {
    id: '1949',
    description: 'ACORDO ESPECIAL - VIGILÂNCIA TRIÂNGULO LTDA',
  },
  {
    id: '1950',
    description: 'ACORDOS ESPECIAIS - PADRAO PORTARIA E CONSERV.',
  },
  {
    id: '1951',
    description: 'ACORDO ESPECIAL - COSIL METROPOLIS LTDA',
  },
  {
    id: '1952',
    description: 'ACORDO ESPECIAL - FARIAS DA ROSA & VIEIRA LTDA ME',
  },
  {
    id: '1953',
    description: 'ACORDO ESPECIAL - HIGH LINK SEGURANCA ELETRONICA LTDA',
  },
  {
    id: '1954',
    description: 'ACORDO ESPECIAL - ELITE SISTEMAS ELETRONICOS',
  },
  {
    id: '1955',
    description: 'ACORDO ESPECIAL - PORTO SEGURO MONITORAMENTO',
  },
  {
    id: '1956',
    description: 'ACORDO ESPECIAL - PERES E JESUS LTDA ME',
  },
  {
    id: '1957',
    description: 'ACORDO ESPECIAIS - ARMAZENAQUI LOCACAO DE BOX LTDA',
  },
  {
    id: '1958',
    description: 'ACORDO ESPECIAL - ONIX MONITORAMENTO DE ALARMES LTDA',
  },
  {
    id: '1959',
    description: 'ACORDO ESPECIAL - CENTRALARME SERVICOS DE SEGURANCA LTDA',
  },
  {
    id: '1960',
    description: 'ACORDO ESPECIAL - GUARDEAQUI PARTICIPACOES LTDA',
  },
  {
    id: '1961',
    description: 'ACORDO ESPECIAL - UNIAO CENTRO OESTE BRASILEIRA DA IG',
  },
  {
    id: '1962',
    description: 'ACORDO ESPECIAL - UNIDOS SELF STORAGE',
  },
  {
    id: '1963',
    description: 'ACORDO ESPECIAL - CABE AQUI ARMAZENAGEM',
  },
  {
    id: '1964',
    description: 'ACORDO ESPECIAL - GUARDIAN SYSTEM (PLANO A)',
  },
  {
    id: '1965',
    description: 'ACORDO ESPECIAL - GUARDIAN SYSTEM (PLANO B)',
  },
  {
    id: '1966',
    description: 'ACORDO ESPECIAL - GUARDIAN SYSTEM (PLANO C)',
  },
  {
    id: '2000',
    description: 'VIDA',
  },
  {
    id: '2100',
    description: 'CONSULTORIOS, ESCOLAS, ESCRITORIOS E HOSPITAIS',
  },
  {
    id: '2200',
    description: 'COMERCIO E SERVICOS',
  },
  {
    id: '2300',
    description: 'INDUSTRIAS',
  },
  {
    id: '2400',
    description: 'IMOVEIS DESOCUPADOS NAO LOCADOS',
  },
  {
    id: '2500',
    description: 'COMERCIO - 2',
  },
  {
    id: '2600',
    description: 'INDUSTRIAS',
  },
  {
    id: '3000',
    description: 'IMOVEIS NAO RESIDENCIAIS - COM SERVS EMERGENCIAIS',
  },
  {
    id: '4000',
    description: 'ORGAO PUBLICO',
  },
  {
    id: '5000',
    description: 'CONDOMINIO',
  },
  {
    id: '5001',
    description: 'TRANSPORTADORA',
  },
  {
    id: '5002',
    description: 'EMBARCADOR',
  },
  {
    id: '5003',
    description: 'CONSTRUCAO CIVIL',
  },
  {
    id: '5004',
    description: 'ENTIDADE ASSISTENCIAL - FILANTROPIA',
  },
  {
    id: '5005',
    description: 'CLUBE RECREATIVO',
  },
  {
    id: '5006',
    description: 'VISAO MONITORAMENTO DE ALARMES LTDA EPP',
  },
  {
    id: '5007',
    description: 'ACORDO ESPECIAL - GUARDE BEM - PORTO ALEGRE',
  },
  {
    id: '5008',
    description: 'GUARDA BEM ADMINISTRACAO - SAO PAULO',
  },
  {
    id: '5009',
    description: 'FINNET COMERCIO E SERVICOS DE TELEINFORMATICA',
  },
  {
    id: '5010',
    description: 'ACORDO ESPECIAL - LOCALBOX PARTICIPACOES LTDA',
  },
  {
    id: '5011',
    description: 'RESERVADO',
  },
  {
    id: '5012',
    description: 'LOTERICAS - (ACORDO CEF)',
  },
  {
    id: '5013',
    description: 'FAZENDA AGRICOLA E DE REFLORESTAMENTO',
  },
]

export default economics
