import { useSelector } from 'react-redux'

import { useGTM } from '@/tools'
import { IRootState } from '@/store'

import { Illustration } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import firstSectionPicture from '@/assets/images/lp-01.png'
import { PortoBankTypoLogo } from '..'
import {
  GridContainer,
  ImagesWrapper,
  Image,
  KeyChainContainer,
  KeyChainWrapper,
  Text,
  Action,
  Title,
  FirstSectionContainer,
} from './styles'
import { useHistory } from 'react-router-dom'


const FirstSection = () => {
  const { push } = useHistory()
  const { setCustomData } = useGTM()

  const { customData } = useSelector(({ analytics }: IRootState) => analytics)

  const goToLogin = () => {
    setCustomData(customData)
    push('/login')
  }

  return (
    <FirstSectionContainer>
      <PortoBankTypoLogo />
      <Title
        color="system-primitive-neutral-900"
        variant="porto-title-3-bold"
        content="Portal das Imobiliárias"
      />
      <GridContainer>
        <ImagesWrapper>
          <Image alt="" src={firstSectionPicture} />
          <KeyChainContainer>
            <KeyChainWrapper>
              <Illustration name="Keychain" />
            </KeyChainWrapper>
          </KeyChainContainer>
        </ImagesWrapper>
        <Text
          variant="porto-text-body-1-regular"
          content="Estamos de cara nova! E com mais ferramentas, benefícios e soluções personalizadas para a sua imobiliária."
        />
        <Action onClick={goToLogin}>Acesse o Portal</Action>
      </GridContainer>
    </FirstSectionContainer>
  )
}

export default FirstSection
