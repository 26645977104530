import { useAuth, useCurrentOrganization } from '@/modules'

import { Actions, Content } from '@/components'

import { contentLabels, links } from '../../utils'

import { Cards } from '../../components'
import {
  Container,
  ContainerButton,
  ContainerLink,
  ContainerRedirect,
} from './styles'
import { useState } from 'react'

const QuerySinister = () => {
  const profile = useCurrentOrganization()
  const isBroker = profile.type === 'BROKER'
  const { refreshToken } = useAuth()
  const [isLoading, setIsLoading] = useState(false)

  const handleClick = async () => {
    setIsLoading(true)
    const { access: accessToken } = await refreshToken()
    setIsLoading(false)

    const { link } = isBroker
      ? links().corretorOnline
      : links(accessToken, profile.id).querySinister

    window.open(link, '_blank', 'noopener,noreferrer')
  }

  return (
    <Container>
      <Content.Heading
        title="Consulta de Sinistro"
        subtitle="Consulte o sinistro realizado do Fiança Locatícia Essencial."
      />
      <Cards
        icon="consultin"
        isSvgIcons
        title={contentLabels.querySinister.title}
        children={contentLabels.querySinister.children}
      />
      <ContainerButton>
        <ContainerLink to="/sinistro">
          <Actions.Button size="large" type="button" kind="ghost">
            Voltar
          </Actions.Button>
        </ContainerLink>
        <ContainerRedirect>
          <Actions.Button
            onClick={handleClick}
            size="large"
            type="button"
            icon="arrow-up-right"
            isLoading={isLoading}
          >
            Consultar sinistro
          </Actions.Button>
        </ContainerRedirect>
      </ContainerButton>
    </Container>
  )
}
export default QuerySinister
