import dayjs from 'dayjs'

import { Forms } from '@/components'
import { PeriodsInfo } from '../../styles'

const Periods = () => {
  const maxStartDate = new Date(dayjs(new Date()).add(2, 'month').format())

  return (
    <Forms.Card step="2" title="Vigência" active>
      <Forms.Datepicker
        name="period_start"
        label="Início de vigência"
        placeholderText="Selecione a data inicial"
        showYearDropdown={true}
        minDate={new Date()}
        maxDate={maxStartDate}
      />

      <PeriodsInfo>
        *Será considerada a vigência de 12 meses a contar da data inicial de
        vigência informada, com exceção de imóveis desocupados que sempre terão
        6 meses de vigência.
      </PeriodsInfo>
    </Forms.Card>
  )
}

export default Periods
