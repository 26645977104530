import { clipText, pxToRem } from '@/styles/sheets/helpers'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import breakpoints from '@pol-npm/riscos-financeiros-dashboard-ui/dist/theme/breakpoints'
import styled from 'styled-components'

export const MenuIcon = styled.div`
  cursor: pointer;
  display: none;
  padding: ${pxToRem(10)};

  @media (max-width: ${breakpoints.sm}) {
    display: flex;
  }
`

export const Container = styled.div`
  align-items: center;
  background-color: var(--porto-primitive-white);
  border-bottom: ${pxToRem(1)} solid var(--system-primitive-neutral-100);
  display: flex;
  left: 0;
  padding: ${pxToRem(12)} ${pxToRem(24)} ${pxToRem(12)} ${pxToRem(16)};
  position: fixed;
  right: 0;
  top: 0;
  z-index: 8;

  @media (max-width: ${breakpoints.sm}) {
    padding: ${pxToRem(12)} ${pxToRem(16)} ${pxToRem(12)} ${pxToRem(6)};
    z-index: 1;
  }
`

export const Brand = styled.div`
  align-items: center;
  display: flex;
  gap: ${pxToRem(16)};

  @media (max-width: ${breakpoints.sm}) {
    display: none;
  }
`

export const Divider = styled.div`
  align-self: stretch;
  border-left: ${pxToRem(1)} solid var(--system-primitive-neutral-200);
`

const Menu = `
  background: white;
  border-radius: ${pxToRem(4)};
  bottom: 0;
  box-shadow: 0 0 ${pxToRem(8)} 0 #e0e0e0;
  display: block;
  padding: ${pxToRem(8)} 0;
  position: absolute;
  right: 0;
  transform: translateY(calc(100%));
`

export const OrganizationMenu = styled.div<{ $isVisible: boolean }>`
  ${Menu}
  opacity: ${(props) => (props.$isVisible ? 1 : 0)};

  @media (max-width: ${breakpoints.sm}) {
    right: 50%;
    transform: translate(50%, calc(100%));
  }
`

export const AccountMenu = styled.div<{ $isVisible: boolean }>`
  ${Menu}

  opacity: ${(props) => (props.$isVisible ? 1 : 0)};
`

export const MenuButton = styled.div`
  display: flex;
  gap: ${pxToRem(16)};
  padding: ${pxToRem(12)} ${pxToRem(16)};
  min-width: max-content;

  &:hover {
    background-color: var(--system-primitive-neutral-50);
  }
`

export const MenuDivider = styled.div`
  border-bottom: ${pxToRem(1)} solid var(--system-primitive-neutral-200);
  margin: ${pxToRem(8)} 0;
  width: 100%;
`

export const MenuTitle = styled(Typography)`
  padding: ${pxToRem(14)} ${pxToRem(16)};
`

export const Name = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  margin: 0 ${pxToRem(16)} 0 auto;
  max-width: ${pxToRem(270)};
  padding: ${pxToRem(4)} ${pxToRem(8)};
  position: relative;

  &:hover {
    background-color: var(--system-primitive-neutral-50);
  }

  @media (max-width: ${breakpoints.sm}) {
    margin: 0 auto;
  }
`

export const Text = styled(Typography)`
  ${clipText}
`

export const IconContainer = styled.div`
  display: flex;
  margin-left: ${pxToRem(8)};
`

export const Avatar = styled.div`
  align-items: center;
  background: var(--porto-bank-porto-bank-30, #bfccec);
  border-radius: 50%;
  border: ${pxToRem(1)} solid var(--neutras-black-15, #e0e0e0);
  cursor: pointer;
  display: flex;
  height: ${pxToRem(32)};
  justify-content: center;
  width: ${pxToRem(32)};
  position: relative;

  &:hover {
    ${AccountMenu} {
      display: block;
    }
  }
`
