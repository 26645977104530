import { DataGrid, GridColDef, ptBR } from '@mui/x-data-grid'
import {
  Box,
  Typography,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { InstallmentsTableSummaryRowData } from './interfaces'
import { InstallmentsTableRowData } from '../InstallmentsTable/interfaces'

const getInstallmentValue = (
  installmentsList: InstallmentsTableRowData[],
  position: number
): string => {
  return installmentsList[position]?.installmentValue || '0'
}

const getFirstInstallmentValue = (
  installmentsList: InstallmentsTableRowData[]
): string => {
  const firstInstallmentsPosition = 1

  return getInstallmentValue(installmentsList, firstInstallmentsPosition)
}

const getLastInstallmentValue = (
  installmentsList: InstallmentsTableRowData[]
): string => {
  const lastInstallmentsPosition = installmentsList.length - 1

  return getInstallmentValue(installmentsList, lastInstallmentsPosition)
}

export const InstallmentsTableSummary = ({
  installmentsData = {},
}: {
  installmentsData: InstallmentsTableSummaryRowData
}) => {
  const defaultColumnProps = {
    sortable: false,
    filterable: false,
    hideable: false,
    groupable: false,
    editable: false,
    resizable: false,
  }

  const columns: GridColDef[] = [
    {
      ...defaultColumnProps,
      field: 'depreciationSystem',
      headerName: 'Tabela',
      flex: 1,
    },
    {
      ...defaultColumnProps,
      field: 'firstInstallmentValue',
      headerName: 'Valor da primeira parcela',
      flex: 1,
    },
    {
      ...defaultColumnProps,
      field: 'lastInstallmentValue',
      headerName: 'Valor da última parcela',
      flex: 1,
    },
  ]

  const rows = [
    {
      id: 'SAC',
      depreciationSystem: 'SAC',
      firstInstallmentValue: getFirstInstallmentValue(
        installmentsData.SAC?.installmentsList || []
      ),
      lastInstallmentValue: getLastInstallmentValue(
        installmentsData.SAC?.installmentsList || []
      ),
    },
    {
      id: 'PRICE',
      depreciationSystem: 'PRICE',
      firstInstallmentValue: getFirstInstallmentValue(
        installmentsData.PRICE?.installmentsList || []
      ),
      lastInstallmentValue: getLastInstallmentValue(
        installmentsData.PRICE?.installmentsList || []
      ),
    },
  ]

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: { xs: '1fr', lg: '60%' },
      }}
    >
      <Typography
        content="<b>Na tabela SAC</b> as prestações são mais altas no início e menores no final, amortizando mensalmente o valor financiado. O valor da parcela vai caindo, da primeira até a última, com diminuição progressiva dos juros."
        variant="porto-text-body-2-regular"
        sx={{
          color: 'var(--neutras-black-75, #404040)',
        }}
      />

      <Typography
        content="<b>Na tabela PRICE</b>, as prestações terão sempre o mesmo valor, do começo ao fim, o que varia é o valor de amortização, que vai aumentando com o tempo. Como o saldo cai mais lentamente, os juros a demoram mais para diminuir."
        variant="porto-text-body-2-regular"
        sx={{
          color: 'var(--neutras-black-75, #404040)',
        }}
      />

      <Typography
        content="Confira abaixo o comparativo das parcelas:"
        variant="porto-text-body-2-regular"
        sx={{
          marginTop: '26px',
          paddingBottom: '16px',
          color: 'var(--neutras-black-75, #404040)',
        }}
      />

      <DataGrid
        {...{
          localeText: ptBR.components.MuiDataGrid.defaultProps.localeText,
          hideFooterPagination: true,
          hideFooterSelectedRowCount: true,
          hideFooter: true,
          checkboxSelection: false,
          disableColumnMenu: true,
          columns,
          rows,
          sx: {
            '.MuiDataGrid-cell': {
              fontSize: '.778rem',
            },
            '.MuiDataGrid-columnHeaders': {
              background: '#EBF1EC',
            },
            '.MuiDataGrid-columnHeaderTitle': {
              fontSize: '.778rem',
              fontWeight: 'bold',
            },
            '.MuiDataGrid-root': {
              borderRadius: 4,
            },
            '.MuiDataGrid-virtualScroller': {
              minHeight: 40,
            },
          },
        }}
      />
    </Box>
  )
}
