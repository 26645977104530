import styled from 'styled-components'
import { rem, size } from 'polished'
import { motion } from 'framer-motion'

import { themes, helpers } from '@/styles'
import { TooltipPropsStyle } from './types'
import media from 'styled-media-query'
import { FeatherIcons, Ionicons } from '@/components/Icons'

export const Content = styled.div`
  padding: 12px;

  line-height: 140%;
  font-size: ${rem('13px')};
  font-family: ${themes.fonts.title};
  overflow: auto;

  p {
    color: #5f5f5f;
  }
`

export const Arrow = styled.div<TooltipPropsStyle>`
  ${size('8px')}
  position: absolute;

  &:before {
    ${size('8px')}

    position: absolute;
    transform: rotate(45deg);

    content: '';
    background-color: ${(props) =>
      props.backgroundArrow ? props.backgroundArrow : themes.text.higher};
  }
`

export const Children = styled.div`
  cursor: pointer;
`
export const TitleStatus = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.03em;

  color: #842310;
`
export const Icon = styled(FeatherIcons && Ionicons)`
  height: 12px;
  display: flex;
  align-items: center;
  margin-right: 4px;
  font-size: 16px;

  ${media.greaterThan('huge')`
    height: 24px;
  `}
`

export const ContainerHead = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`
export const ContainerButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 14px;
  border-top: 1px solid #e0e0e0;

  button {
    background: transparent;
    border: none;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    letter-spacing: 0.025em;

    /* Seguros/Porto Seguros 100% */

    color: #0046c0;
  }
`

export const Tooltip = styled(motion.div).attrs(() => ({
  tabIndex: -1,
}))`
  ${helpers.defaultBoxShadow}

  border-radius: 8px;
  color: ${themes.page.colors.background}; //texto
  background-color: ${themes.text.higher}; //back

  &[data-placement^='bottom'] > ${Arrow} {
    top: -4px;
  }
  &[data-placement^='right'] > ${Arrow} {
    left: -4px;
  }
  &[data-placement^='left'] > ${Arrow} {
    right: -4px;
  }
  &[data-placement^='top'] > ${Arrow} {
    bottom: -4px;
  }
`
