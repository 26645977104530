import * as yup from 'yup'
import cardValidator from 'card-validator'

import { contractStore } from '@/modules'
import { isCNPJ, isCPF } from 'brazilian-values'

const documentValidation = (value) => {
  if (value.length <= 14) return isCPF(value)

  return isCNPJ(value)
}

export const address = yup.object().shape({
  zipcode: yup
    .string()
    .min(9, 'Por favor insira um CEP válido.')
    .required('Campo CEP é obrigatório.'),
  street: yup.string().required('Por favor confirme a rua.'),
  number: yup.string().required('Campo número é obrigatório.'),
  complement: yup.string(),
  neighborhood: yup.string().required('Por favor confirme o bairro.'),
  city: yup.string().required('Por favor confirme a cidade.'),
  state: yup.string().required('Por favor confirme o estado.'),
})

export const landlordSchema = yup.object().shape({
  document: yup
    .string()
    .required('Campo CPF/CNPJ é obrigatório.')
    .test(
      'document-test',
      'Por favor insira um CPF/CNPJ válido.',
      (value) => !!value && documentValidation(value)
    ),
  name: yup.string().required('Campo nome é obrigatório.'),

  birthdate: yup.string().when('document', {
    is: (val: string) => isCPF(val),
    then: yup.string().required('Campo data de nascimento é obrigatório.'),
    otherwise: yup.string().strip(),
  }),

  company_type: yup.string().when('document', {
    is: (val: string) => isCPF(val),
    then: yup.string().strip(),
  }),

  net_equity: yup.string().when('document', {
    is: (val: string) => isCPF(val),
    then: yup.string().strip(),
  }),

  gross_income: yup.string().when('document', {
    is: (val: string) => isCPF(val),
    then: yup.string().strip(),
  }),

  company_controllers: yup.string().when('document', {
    is: (val: string) => isCPF(val),
    then: yup.string().strip(),
  }),

  economic_activity: yup.string().when('document', {
    is: (val: string) => isCPF(val),
    then: yup.string().strip(),
    otherwise: yup
      .string()
      .required('Campo atividade econômica é obrigatório.'),
  }),

  nacionality: yup.string().when('document', {
    is: (val: string) => isCPF(val),
    then: yup
      .string()
      .notOneOf([''], 'Campo nacionalidade é obrigatório.')
      .required('Campo nacionalidade é obrigatório.'),
    otherwise: yup.string().strip(),
  }),

  pep: yup.string().when('document', {
    is: (val: string) => isCPF(val),
    then: yup
      .string()
      .notOneOf([''], 'Campo pessoa politicamente exposta é obrigatório.')
      .required('Campo pessoa politicamente exposta é obrigatório.'),
    otherwise: yup.string().strip(),
  }),

  occupation: yup.string().when('document', {
    is: (val: string) => isCPF(val),
    then: yup.string().optional(),
    otherwise: yup.string().strip(),
  }),

  email: yup
    .string()
    .email('Por favor insira um e-mail válido.')
    .required('Campo e-mail é obrigatório.'),
  phone: yup
    .string()
    .matches(/\(\d{2,}\) \d{4,}\-\d{4}/g, 'Por favor insira um número válido.')
    .required('Campo telefone/celular é obrigatório.'),
  emigrant: yup.bool(),
  residence_country: yup.string().when('emigrant', {
    is: true,
    then: yup
      .string()
      .notOneOf([''], 'Campo país residente é obrigatório.')
      .required('Campo país residente é obrigatório.'),
    otherwise: yup.string().strip(),
  }),
  addresses: yup.object().shape({
    useSameAddress: yup.boolean(),
    main: address,
    billing: yup.object().when('useSameAddress', {
      is: false,
      then: address,
      otherwise: yup.object().strip(),
    }),
  }),
})

export const paymentsSchema = yup.object().shape({
  method: yup.string().required(),
  installment: yup.string().required(),

  card: yup.object().when('method', {
    is: (value: string) => value === '62' || value === '97',
    then: yup
      .object()
      .shape({
        number: yup
          .string()
          .required('Campo obrigatório.')
          .test(
            'card_number-test',
            'Por favor insira um cartão válido.',
            (value = '') => cardValidator.number(value).isValid
          ),
        expiration_date: yup
          .string()
          .required('Campo obrigatório.')
          .min(7, 'Por favor a data completa.'),
      })
      .optional(),
    otherwise: yup.object().strip(),
  }),

  debit: yup.object().when('method', {
    is: (value: string) => value === '52' || value === '55',
    then: yup
      .object()
      .shape({
        document: yup
          .string()
          .required('Campo CPF/CNPJ é obrigatório.')
          .test(
            'document-test',
            'Por favor insira um CPF/CNPJ válido.',
            (value) => !!value && documentValidation(value)
          )
          .test(
            'document-debit-invalid',
            'CPF da conta deve ser o mesmo que o do inquilino ou locador.',
            (document = '') => {
              const documents = contractStore
                .getState()
                .payload.customers.map(({ document }) =>
                  document.replace(/\.|-|\//g, '')
                )

              const documentLandlord =
                contractStore.getState().payload.landlord.document

              const documentInput = document.replace(/\.|-|\//g, '')

              return (
                documents.includes(documentInput) ||
                documentLandlord === documentInput
              )
            }
          ),
        agency: yup.string().required('Campo obrigatório.'),
        account: yup.string().required('Campo obrigatório.'),
        digit: yup.string().required('Campo obrigatório.'),
        bank: yup.string().required('Campo obrigatório.'),
      })
      .optional(),
    otherwise: yup.object().strip(),
  }),
})
