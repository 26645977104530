import React from 'react'
import { isMobile } from 'react-device-detect'
import { useTable, useGlobalFilter } from 'react-table'

import Floating from '../Actions/Floating'

import { ITableProps } from './interfaces'

import { Container, Head, Search, Actions, Action, Content } from './styles'

const Table: React.FC<ITableProps> = (props) => {
  const columns = React.useMemo(() => props.columns, [])

  const data = React.useMemo(() => props.data, [props.data])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    setGlobalFilter,

    rows,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter
  )

  const handleFilter = (event) => {
    setGlobalFilter(event.target.value)
  }

  return (
    <Container {...getTableProps()}>
      <Head>
        <Search
          placeholder={props.searchPlaceholder || 'Pesquisar'}
          onChange={handleFilter}
        />

        {props.actions && (
          <Actions>
            {isMobile ? (
              <Floating
                fixed
                actions={props.actions.map((action) => ({
                  icon: action.icon,
                  onClick: action.onClick,
                }))}
              />
            ) : (
              props.actions.filter(Boolean).map((action, index) => (
                <Action key={index} {...action} onClick={action.onClick}>
                  {action.label}
                </Action>
              ))
            )}
          </Actions>
        )}
      </Head>

      <Content>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr key={index} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps([
                    {
                      style: column.style,
                    },
                  ])}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows.length < 1 && (
            <tr>
              <td colSpan={columns.length}>
                {props.emptyMessage || 'Nenhum resultado encontrado.'}
              </td>
            </tr>
          )}

          {rows.map((row, index) => {
            prepareRow(row)

            return (
              <tr key={index} {...row.getRowProps()}>
                {row.cells.map((cell, index) => (
                  <td
                    key={index}
                    {...cell.getCellProps([
                      {
                        style: cell.column.style,
                      },
                    ])}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </Content>
    </Container>
  )
}

Table.defaultProps = {
  data: [],
  columns: [],
}

export default React.memo(Table)
