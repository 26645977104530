import styled from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div``

export const ContainerButton = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  margin-right: 24px;
  margin-top: 32px ;

  ${media.lessThan('medium')`
    flex-direction: column;
    align-items: stretch;
    margin-right: 0px;
    margin-top: 32px ;
  `}
`;

export const Link = styled.a`
  margin-right: 24px;
  ${media.lessThan('medium')`
  margin-right: 4px;
    
  `}

  button {
    padding: 12px 12px 12px 20px;
    ${media.lessThan('small')`
    width: 100%;
    margin-bottom: 16px;
  `}
  }
`;