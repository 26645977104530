import React from 'react'
import Conditional from '../Conditional'

import { IBadgeProps } from './interfaces'

import { Container, Icon } from './styles'

const Badge: React.FC<IBadgeProps> = (props) => (
  <Container kind={props.kind} removeDot={props.removeDot} {...props}>
    <Conditional when={!!props.icon}>
      <Icon name={props.icon} />
    </Conditional>
    {props.children}
  </Container>
)

Badge.defaultProps = {
  kind: 'info',
  removeDot: false,
  icon: '',
}

export default Badge
