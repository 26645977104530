import React, { useState, useEffect } from 'react'
import { useFormikContext, FormikProps } from 'formik'

import { Forms, Grid, Modals, Address as AddressBox } from '@/components'
import { useDebounce } from '@/tools'

type Address = {
  zipcode: string
  street: string
  number: string
  complement: string
  neighborhood: string
  city: string
  state: string
}

const Address = () => {
  const {
    values,
    errors,
    setFieldValue,
  }: FormikProps<{
    address: Address
  }> = useFormikContext()

  const [zipcode, setZipcode] = useState('')
  const debouncedZipcode = useDebounce(zipcode)

  const fetchAddress = async (zipcode) => {
    const address = (await Modals.Address({ zipcode })) as Address

    if (!address) {
      setFieldValue('address.zipcode', '')
      setFieldValue('address.street', '')
      setFieldValue('address.number', '')
      setFieldValue('address.complement', '')
      setFieldValue('address.neighborhood', '')
      setFieldValue('address.city', '')
      setFieldValue('address.state', '')

      return
    }

    setFieldValue('address.zipcode', address.zipcode)
    setFieldValue('address.street', address.street)
    setFieldValue('address.number', address.number)
    setFieldValue('address.complement', address.complement)
    setFieldValue('address.neighborhood', address.neighborhood)
    setFieldValue('address.city', address.city)
    setFieldValue('address.state', address.state)
  }

  useEffect(() => {
    if (debouncedZipcode && !errors?.address?.zipcode) {
      fetchAddress(debouncedZipcode)
    }
  }, [debouncedZipcode])

  return (
    <>
      <Forms.Card step="3" title="Endereço da organização" active>
        <Grid columns={['repeat(2, 1fr)', 'repeat(3, 1fr)']} gap="1rem">
          <Forms.InputGroup
            name="address.zipcode"
            type="tel"
            label="CEP"
            placeholder="Digite o CEP"
            mask="zipcode"
            autoComplete="off"
            onKeyUp={(event) => setZipcode(event.target.value)}
          />
        </Grid>

        {values.address.street && (
          <Grid columns={['auto', 'repeat(2, 1fr)']} space="2rem 0 0">
            <AddressBox {...values.address} />
          </Grid>
        )}
      </Forms.Card>
    </>
  )
}

export default Address
