export namespace PortoImobiliaria {
    export const Plan = {
        'RESIDENTIAL': 'Residencial',
        'COMMERCIAL': 'Comercial',
    }

    export const ServiceClause = {
        '479': 'Padrão gratuíto',
        '553': 'Intermediário rede referenciada',
        '485': 'Intermediário livre escolha',
        '480': 'Total rede referenciada',
        '548': 'Total livre escolha',
    }
  }
  