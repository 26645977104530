const Logo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="107"
      height="22"
      viewBox="0 0 107 22"
      fill="none"
    >
      <path
        d="M18.4128 11.7789C16.5608 9.51459 10.4546 10.699 5.69684 12.2159C5.63707 12.2363 5.57485 12.1963 5.57649 12.1383C5.58386 11.8989 5.58713 11.6563 5.58713 11.4121C5.58713 10.0823 5.47824 8.93872 5.28338 7.81312C5.27601 7.7731 5.30303 7.73552 5.34642 7.72409C9.2846 6.29136 17.2166 5.84781 18.5741 11.7143L18.5749 11.7151C18.597 11.8009 18.4693 11.8516 18.4128 11.7789ZM14.4263 18.3609C14.2961 18.4573 14.1258 18.2988 14.2372 18.1853C16.1539 16.2306 17.7292 13.1291 3.84074 19.4375C3.75559 19.4759 3.67044 19.3877 3.72694 19.3182C4.49246 18.2269 5.1753 16.4683 5.48397 13.9753C5.48724 13.9451 5.50771 13.9181 5.538 13.9059C14.2413 10.4172 24.0704 10.5454 14.4263 18.3609ZM2.80748 2.75936C2.72807 2.71934 2.76163 2.60988 2.85169 2.60988C5.25963 2.61723 12.5129 2.43753 16.2513 7.19069C16.3021 7.27237 16.1858 7.35569 16.1113 7.29034C14.9831 6.31096 11.9292 4.65197 4.8912 6.1043C4.84207 6.11655 4.79131 6.0896 4.77903 6.04467C4.21982 4.25744 3.50751 3.29112 2.80748 2.75936ZM18.2753 0.5H2.46115C1.10203 0.5 0 1.61579 0 2.99216V19.0078C0 20.3842 1.10203 21.5 2.46115 21.5H18.2761C19.6352 21.5 20.7372 20.3842 20.7372 19.0078V2.99216C20.7364 1.61579 19.6344 0.5 18.2753 0.5Z"
        fill="#2C3DA8"
      />
      <path
        d="M24.2578 4.52051H29.3447C31.9876 4.52051 33.5621 6.14601 33.5621 8.43968C33.5621 10.7334 31.9868 12.3589 29.3447 12.3589H26.6658V17.1627H24.2578V4.52051ZM29.2358 10.3543C30.6481 10.3543 31.227 9.45174 31.227 8.43968C31.227 7.42844 30.6481 6.52502 29.2358 6.52502H26.6649V10.3535H29.2358V10.3543ZM38.3771 7.91609C41.1469 7.91609 43.1381 9.90263 43.1381 12.6113C43.1381 15.3199 41.1469 17.3064 38.3771 17.3064C35.5893 17.3064 33.5981 15.3199 33.5981 12.6113C33.5981 9.90263 35.5901 7.91609 38.3771 7.91609ZM38.3771 15.3926C39.8795 15.3926 40.8931 14.2547 40.8931 12.6113C40.8931 10.9678 39.8795 9.82993 38.3771 9.82993C36.8567 9.82993 35.8431 10.9678 35.8431 12.6113C35.8431 14.2547 36.8567 15.3926 38.3771 15.3926ZM44.1517 11.3109C44.1517 9.23364 45.3283 8.05985 47.4104 8.05985H49.4556V9.91978H47.7542C46.8675 9.91978 46.3787 10.4254 46.3787 11.31V17.161H44.1526V11.3109H44.1517ZM50.5781 5.31529H52.8043V8.06067H55.3383V9.9206H52.8043V13.8937C52.8043 14.8151 53.2931 15.2839 54.1257 15.2839H55.3383V17.1618H53.8719C51.8807 17.1618 50.5773 16.006 50.5773 13.9835V5.31529H50.5781ZM60.7879 7.91609C63.5577 7.91609 65.5489 9.90263 65.5489 12.6113C65.5489 15.3199 63.5577 17.3064 60.7879 17.3064C58 17.3064 56.0088 15.3199 56.0088 12.6113C56.0088 9.90263 58 7.91609 60.7879 7.91609ZM60.7879 15.3926C62.2903 15.3926 63.3039 14.2547 63.3039 12.6113C63.3039 10.9678 62.2903 9.82993 60.7879 9.82993C59.2675 9.82993 58.2539 10.9678 58.2539 12.6113C58.253 14.2547 59.2675 15.3926 60.7879 15.3926Z"
        fill="#2C3DA8"
      />
      <path
        d="M67.6797 4.52051H72.5856C74.9027 4.52051 76.55 5.67633 76.55 7.77151C76.55 8.96327 75.7894 9.86669 75.0116 10.2637C76.2061 10.7154 77.1837 11.7266 77.1837 13.424C77.1837 15.7716 75.4275 17.1627 72.7666 17.1627H67.6797V4.52051ZM72.4227 9.46889C73.6901 9.46889 74.3238 8.92733 74.3238 7.95203C74.3238 6.97673 73.6901 6.43517 72.3867 6.43517H70.0336V9.46889H72.4227ZM72.5127 15.0854C74.124 15.0854 74.8838 14.4712 74.8838 13.2974C74.8838 12.1416 74.1232 11.4914 72.5127 11.4914H70.0328V15.0854H72.5127Z"
        fill="black"
      />
      <path
        d="M82.1447 9.57746C80.9682 9.57746 80.2624 10.3175 80.2256 11.1303H78.2344C78.3433 9.32424 79.8817 7.91602 82.2528 7.91602C84.5699 7.91602 86.1992 9.25236 86.1992 11.3116V17.1626H84.009V15.8083C83.6291 16.7109 82.5787 17.3072 81.2752 17.3072C79.465 17.3072 78.2344 16.1513 78.2344 14.5806C78.2344 12.8105 79.6099 11.7094 81.6559 11.7094H84.009V11.3304C84.009 10.2097 83.3213 9.57746 82.1447 9.57746ZM81.8008 15.6637C83.1944 15.6637 84.009 14.6884 84.009 13.3161V13.2075H82.0718C81.0222 13.2075 80.3885 13.6951 80.3885 14.4899C80.3885 15.194 80.9682 15.6637 81.8008 15.6637Z"
        fill="black"
      />
      <path
        d="M87.7734 8.06063H89.9996V9.46967C90.5064 8.4764 91.538 7.91687 92.8415 7.91687C94.9047 7.91687 96.245 9.39779 96.245 11.5289V17.1634H94.0188V11.9986C94.0188 10.7521 93.2582 9.88544 92.1185 9.88544C90.8691 9.88544 90.0004 10.8607 90.0004 12.1971V17.1634H87.7743V8.06063H87.7734Z"
        fill="black"
      />
      <path
        d="M98.0938 17.1626V4.15942H100.32V11.3835L103.814 8.06063H106.637L102.927 11.6367L106.945 17.1626H104.248L101.406 13.0989L100.32 14.146V17.1618H98.0938V17.1626Z"
        fill="black"
      />
    </svg>
  )
}

export default Logo
