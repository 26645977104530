export enum Products {
  ALUGUEL = 'PORTO_ALUGUEL',
  ESSENCIAL = 'PORTO_ESSENCIAL',
  IMOBILIARIA = 'PORTO_IMOBILIARIA',
  CAPITALIZACAO = 'PORTO_CAPITALIZACAO',
}

export interface IMetadata {
  error?: {
    code?: string
    message?: string
  }
  statusPac?: string
  status?: string
  product?: string
}

export interface IPolicy {
  id?: string
  branch?: number
  filiation?: number
  endorsement?: string
  done?: boolean
  description?: string
  status?: string
  created_at?: Date
  budget?: number
  proposal?: string | number
  source?: string
  sucursal?: string
  type?: string
}

export interface IAddress {
  zipcode: string
  street: string
  number: string
  complement?: string
  neighborhood: string
  city: string
  state: string
  country?: string
  coords?: {
    latitude: number
    longitude: number
  }
}

export interface IPayment {
  budget?: string
  method: string
  comission?: string | number
  installment: string
  addresses?: object
  card?: {
    number: string
    expiration_date: string
  }
  debit?: {
    document: ''
    agency: ''
    account: ''
    digit: ''
    bank: ''
  }
}
