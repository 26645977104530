import Tippy from '@tippyjs/react'
import { useSpring } from 'framer-motion'

import { TooltipProps } from './types'
import * as S from './styles'

const Tooltip = ({
  placement = 'top',
  content,
  delay,
  children,
  maxWidth,
  maxHeight,
  status,
}: TooltipProps) => {
  const opacity = useSpring(0)
  // const scale = useSpring(0.9)

  const onHide = () => {
    // scale.set(0.9)
    opacity.set(0)
  }

  const onMount = () => {
    // scale.set(1)
    opacity.set(1)
  }

  if (!content) return <S.Children>{children}</S.Children>

  return (
    <Tippy
      animation
      onHide={onHide}
      onMount={onMount}
      placement={placement}
      delay={delay}
      render={(attrs) => (
        <S.Tooltip
          style={{
            // scale,
            opacity,
          }}
          {...attrs}
        >
          <S.Content
            style={{
              maxWidth: maxWidth ?? 'auto',
              maxHeight: maxHeight ?? 'auto',
            }}
          >
            {status && <S.TitleStatus>{status}</S.TitleStatus>}
            {typeof content === 'function' ? content() : content}
          </S.Content>

          <S.Arrow data-popper-arrow />
        </S.Tooltip>
      )}
    >
      <S.Children>{children}</S.Children>
    </Tippy>
  )
}

export default Tooltip
