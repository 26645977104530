import styled from 'styled-components'
import media from 'styled-media-query'
import { rem, size } from 'polished'
import { Link, NavLink } from 'react-router-dom'
import { Form as FormikForm } from 'formik'

import { themes } from '@/styles'
import { Actions, Content as ContentComponent } from '@/components'

export const Container = styled.div``

export const Message = styled(ContentComponent.Message)`
  margin-bottom: 8px;

  background-color: #fff;
`

export const Form = styled(FormikForm)`
  display: grid;

  grid-template-columns: 1fr;

  gap: 24px;

  margin-top: 32px;
`

export const Aside = {
  Box: styled.aside`
    ${media.greaterThan('medium')`
      padding: 24px;
      width: 200px;
      border-right: 1px solid ${themes.utilities.border};
    `}

    ${media.greaterThan('huge')`
      width: 250px;
      padding: 32px;
    `}
  `,

  Title: styled(Link)`
    margin-bottom: 24px;
    display: inline-flex;
    align-items: center;

    font-weight: 600;
    line-height: 28px;
    font-size: 20px;
    color: ${themes.palletes.terciary};
    font-family: ${themes.fonts.title};

    &:hover {
      color: ${themes.text.medium};
    }

    > svg {
      ${size('20px')}

      margin-right: 8px;
      color: ${themes.palletes.primary};
    }
  `,

  Links: styled.div`
    ${media.lessThan('medium')`
      display: flex;
      justify-content: space-evenly;
      gap: 8px;
    `}
  `,

  Link: styled(NavLink)`
    position: relative;

    display: block;

    font-size: ${rem('14px')};
    color: ${themes.text.medium};

    ${media.greaterThan('huge')`
      font-size: ${rem('14px')};
    `}

    &:before {
      position: absolute;
      top: 50%;
      right: -24px;
      transform: translateY(-50%);

      width: 2px;
      height: 24px;

      content: '';
      border-radius: 2px 0 0 2px;
      background-color: transparent;
    }

    &.active,
    &:hover {
      color: ${themes.palletes.primary};

      ${media.greaterThan('medium')`
        &:before {
          background-color: ${themes.palletes.primary};
        }
      `}
    }

    &:not(:last-of-type) {
      ${media.greaterThan('medium')`
        margin-bottom: 10px;
      `}
    }
  `,
}

export const Content = styled.div`
  ${media.greaterThan('huge')`
    padding: 32px;
  `}

  ${media.lessThan('medium')`
    margin-top: 10px;

    border-radius: 8px;
    background-color: #FFF;
  `}
`

export const Nav = {
  List: styled.nav`
    width: 100%;
  `,

  Item: styled(Link)`
    display: block;
    padding: 15px 8px;

    font-size: ${rem('10px')};
    color: ${themes.text.high};
    transition: padding-left 0.15s;
    border-bottom: 1px solid ${themes.utilities.border};

    ${media.greaterThan('huge')`
      font-size: ${rem('14px')};
    `}

    &:first-of-type {
      padding-top: 0;
    }

    &:hover {
      padding-left: 12px;

      color: ${themes.text.medium};
      transition: padding-left 0.15s;
    }
  `,
}

export const Action = styled(Actions.Button)`
  &:not(:last-of-type) {
    margin-right: 24px;
  }

  height: 48px;
  font-size: 16px;

  ${media.lessThan('small')`
    width: 100%;

    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  `}
`

export const Footer = styled.div`
  display: flex;
  gap: 24px;

  justify-self: end;

  ${media.lessThan('medium')`
  gap: 16px;
    justify-self: unset;
    justify-content: stretch;
    flex-direction: column;
  `}
`
