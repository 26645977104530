import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { Link } from 'react-router-dom'
import { size, rem } from 'polished'

import { themes } from '@/styles'

export const Icon = styled.div<{ color: string }>`
  color: ${(props) => props.color};

  display: flex;
  justify-content: center;
  align-items: center;
  height: 65%;

  & svg {
    /* width: clamp(30px, 50px, 75px); */
  }
`

export const Initials = styled.div<{ color: string }>`
  ${size('32px')}

  margin-bottom: 5px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  border-radius: 8px;

  color: #ccc;
  -webkit-text-fill-color: #ccc;

  font: bold ${rem('10px')} ${themes.fonts.title};

  background-color: ${(props) => props.color};

  ${media.greaterThan('large')`
    ${size('50px')}
    width:100%;

    font-size: ${rem('20px')};
  `}

  ${media.greaterThan('huge')`
    ${size('55px')}
    width:100%;

    font-size: ${rem('24px')};
  `}
`

export const Title = styled.h4`
  padding-bottom: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: 0.025em;

  /* Porto Banking 85% */

  color: ${themes.text.higher};

  ${media.greaterThan('large')`
    width:100%;

    font-size: ${rem('15px')};
  `}

  ${media.greaterThan('huge')`
    font-size: ${rem('17px')};
    width:100%;

  `}
`

export const Description = styled.p`
  width: 100%;
  text-align: initial;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  margin-bottom: 24px;
  letter-spacing: 0.025em;

  color: #5f5f5f;

  ${media.greaterThan('large')`
    font-size: ${rem('14px')};
  `}

  ${media.greaterThan('huge')`
    font-size: ${rem('14px')};
  `}
`

export const Container = styled(Link)`
  all: unset;

  position: relative;

  padding: 8px 20px 0px 20px;

  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  ${media.lessThan('small')`
    width:100%;
  `}

  cursor: pointer;
  transition: 0.3s;
  border-radius: 8px;
  text-align: center;
  box-sizing: border-box;
  background-color: ${themes.page.colors.background};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);

  &:before {
    position: absolute;
    top: 0;
    right: 0;

    width: 0;
    height: 2px;

    content: '';
    pointer-events: none;
    transition: 0.6s cubic-bezier(0.87, 0, 0.13, 1);
  }

  &:hover {
    transition: 0.3s;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.05);

    &:before {
      left: 0;

      width: 100%;

      transition: 0.6s cubic-bezier(0.87, 0, 0.13, 1);
    }
  }

  ${(props) =>
    props.color &&
    css`
      &:before {
        background-color: ${(props) => props.color};
      }
    `}
`

export const ContentLink = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  margin-top: 16px;

  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: 0.025em;

  color: #2c3da8;

  svg {
    margin-left: 8px;
    height: 14px;
  }
`
