import { RouteType } from '@/router/routes/types'
import * as Screens from '@/screens'

const routes: readonly RouteType[] = [
  {
    path: '/',
    exact: true,
    component: Screens.Dashboard,
  },

  {
    path: '/contratos',
    exact: true,
    component: Screens.Contracts.Listing,
  },

  {
    path: '/contratos/novo/imobiliaria',
    component: Screens.Contracts.Imobiliaria.Budgets,
  },

  {
    path: '/contratos/novo/essencial',
    component: Screens.Contracts.Essencial.Budgets,
  },

  {
    path: '/contratos/novo/capitalizacao',
    component: Screens.Contracts.Capitalizacao.Budgets,
  },

  {
    path: '/renovacoes/novo/imobiliaria',
    component: Screens.Renovations.Imobiliaria,
  },

  {
    path: '/consultas',
    component: Screens.Budgets.List,
  },

  {
    path: '/minha-organizacao',
    component: Screens.Organizations.Details,
  },

  {
    path: '/minha-conta',
    component: Screens.Users.Account.Edit,
  },

  {
    path: '/assinatura-eletronica',
    component: Screens.EletronicSignature,
  },
  {
    path: '/sinistro',
    exact: true,
    component: Screens.Sinistro.Sinistro,
  },
  {
    path: '/sinistro/parcelamento-aluguel',
    component: Screens.Sinistro.RentInstallment,
  },
  {
    path: '/sinistro/comunicado-sinistro',
    component: Screens.Sinistro.OminousStatement,
  },
  {
    path: '/sinistro/consulta-sinistro',
    component: Screens.Sinistro.QuerySinister,
  },
  {
    path: '/sinistro/assessoria-de-cobranca',
    exact: true,
    component: Screens.Sinistro.ListRentCollections,
  },
  {
    path: '/sinistro/assessoria-de-cobranca/nova',
    component: Screens.Sinistro.NewRentCollection,
  },
  {
    path: '/orcamento',
    exact: true,
    component: Screens.BatchBudget.Options,
  },
  {
    path: '/orcamento/novo',
    component: Screens.BatchBudget.CalculateBatch,
  },
  {
    path: '/orcamento/detalhes',
    component: Screens.BatchBudget.Details,
  },
  {
    path: '/orcamento/consulta',
    component: Screens.BatchBudget.Listing,
  },

  {
    path: '/novidades',
    component: Screens.News,
  },
  {
    path: '/home-equity/estate-guarantee',
    component: Screens.HomeEquity.HomeEquity,
  },
  {
    path: '/produtos/cartao-porto/indicar',
    component: Screens.PortoCard.LeadTable,
    conditional: profile => {
      return profile.type !== 'BROKER'
    },
  },

  // ? Rotas dinâmicas sempre devem ser alocadas após as fixas
  {
    path: '/produtos/:id',
    component: Screens.Products,
  },
  {
    path: '/contratos/:id/pagamento',
    component: Screens.Contracts.Essencial.Payments,
  },
  {
    path: '/contratos/:id',
    exact: true,
    component: Screens.Contracts.Details,
  },
  {
    path: '/consultas/:id',
    component: Screens.Consultations.Details,
  },
  {
    path: '/orcamento/:id',
    component: Screens.BatchBudget.DetailsList,
  },
  {
    path: '/campanhas/parceria-premiada',
    component: Screens.ParceriaPremiada,
    conditional: () => !!process.env.REACT_APP_ENABLE_PARCERIA_PREMIADA,
  },
] as const

export default routes
