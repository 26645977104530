import styled from 'styled-components'
import media from 'styled-media-query'

import { Grid as GridComponent } from '@/components'
import { themes } from '@/styles'
import { pxToRem } from '@/styles/sheets/helpers'

export const Grid = styled(GridComponent).attrs({
  columns: ['auto', 'repeat(3, 1fr)'],
  gap: ['.5rem', '1rem'],
})``

export const ToggleWrapper = styled.div`
  padding-top: 38px;
`

export const Choices = styled.div`
  > label {
    display: inline-block;

    &:not(:last-of-type) {
      margin-right: 16px;
      margin-bottom: 10px;
    }

    padding-top: 8px;

    ${media.greaterThan('medium')`
      padding-top: 38px;
    `}

    ${media.lessThan('huge')`
      padding-top: 33px;
    `}
  }
`

export const TermsButton = styled.button`
  background: none;
  border: none;
  color: var(--system-primitive-blue-500);
  cursor: pointer;
  font-family: ${themes.fonts.text};
  font-size: ${pxToRem('16px')};
  margin: 0 ${pxToRem('4px')};
  text-decoration: underline;
`

export const ISContainer = styled.div`
  grid-area: 2 / 1 / 3 / -1;
`
