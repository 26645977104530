import React from 'react'

import { Container, Icon } from './styles'

const Link = (props) => (
  <Container to={props.to} {...props}>
    {props.icon && <Icon name={props.icon} />}

    {props.children}
  </Container>
)

Link.defaultProps = {
  children: 'Link',
}

export default Link
