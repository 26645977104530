import styled from 'styled-components'
import { themes } from '@/styles'

const ExpirationDate = styled.span`
  position: absolute;
  top: 12px;
  right: 32px;
  font-size: 10px;

  color: ${themes.text.medium};
  z-index: 100;
`

export default ExpirationDate
