export const isRentInstallmentEnabled = (profile) =>
  profile.partnerships.every(
    (partnership) =>
      partnership.settings[`porto_essencial.claim.rentInstallment`]
  )

export const isHandoutEnabled = (profile) =>
  profile.partnerships.every(
    (partnership) => partnership.settings[`porto_essencial.claim.handout`]
  )

export const isBatchEnabled = (profile) => {
  if (profile.type === 'BROKER') return true
  profile.partnerships.some(
    (partnership) => partnership.settings[`porto_imobiliaria.batch.enabled`]
  )
}

export const contentLabels = {
  rentInstallment: {
    title:
      'Ainda não comunicou o sinistro? Então parcele o aluguel do seu inquilino!',
    children:
      'Os clientes dos Seguros Fiança e Essencial podem fazer o <b>pagamento no cartão de crédito</b> à vista ou parcelado, de aluguéis e encargos vencidos e a vencer e, demais encargos <b>desde que não tenha sinistro aberto</b>.<br/><br/>  O valor poderá ser pago no cartão de crédito em até <b>10 vezes sem juros</b>. E, independente da forma de pagamento escolhida pelo inquilino, <b>a imobiliária receberá o valor à vista</b>. <br/><br/> O parcelamento também pode ser oferecido no momento da rescisão para pagamento dos débitos finais.',
  },
  ominousStatement: {
    title:
      'Seu inquilino não pagou o aluguel e não deseja parcelar? Comunique para a gente!',
    children:
      'O sinistro deverá ser comunicado imediatamente <b> após o vencimento do 2º aluguel </b> e ou encargo não pago.  ',
  },
  ominousStatementBroker: {
    children:
      'O sinistro deverá ser comunicado imediatamente <b> após o vencimento do 2º aluguel </b> e ou encargo não pago. Acesse o COL e faça a abertura do sinistro.',
  },
  querySinister: {
    title:
      'Abriu o sinistro e não sabe o status? Acompanhe na plataforma da Porto.',
    children:
      'Acesse a plataforma da Porto e consulte os sinistros que foram abertos.',
  },
}

export const links = (userToken?: string, profileId?: string) => {
  return {
    rentInstallment: {
      link: 'http://docs.google.com/forms/d/124DPbOkm-PrCm9yFx0KmIYlfQuMthncXdjflHaFpq7c/edit',
    },
    ominousStatement: {
      link: `${process.env.REACT_APP_SINISTRO_URL}/#/acesso?usrtip=I&token=${userToken}&partner=${profileId}`,
    },
    corretorOnline: {
      link: `https://corretor.portoseguro.com.br/corretoronline/`,
    },
    querySinister: {
      link: `${process.env.REACT_APP_SINISTRO_URL}/#/acesso?usrtip=I&token=${userToken}&partner=${profileId}&go=consulta`,
    },
  }
}
