import styled from 'styled-components'

export const Container = styled.div`
  min-width: max-content;

  > * {
    height: 100%;
  }

  &[aria-disabled='false'] {
    > * {
      transition: all 0.2s;
    }

    &:hover {
      cursor: pointer;

      > * {
        border-color: var(--system-text-info);
      }
    }
  }

  &[aria-disabled='true'] {
    opacity: 0.3;

    &:hover {
      cursor: not-allowed;
    }
  }

  &[aria-checked='true'] {
    > * {
      border-color: var(--system-text-info);
    }
  }
`

export const Col = styled.div`
  display: flex;
  flex-direction: column;

  gap: 24px;
`

export const Warranty = styled.div`
  display: flex;
  flex-direction: column;

  > :first-child {
    margin-bottom: 8px;
  }
`

export const Benefits = styled.div`
  *:first-child {
    margin-bottom: 8px;
  }
`
