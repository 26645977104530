import { useState, useEffect, useMemo } from 'react'
import { useFormik, FormikProvider } from 'formik'

import { Content, Grid, Forms, Conditional, Address, Modals } from '@/components'
import { IAddressValuesProps } from '@/components/Utilities/Address/interfaces'

import { useDebounce, countries, economics, occupations, removeSpecialChars } from '@/tools'

import { useContract } from '@/modules'

import * as S from '../styles'
import { Actions, Action } from '../../Details/styles'

import { landlordSchema } from '../schema'

import { api } from '@/services'
import dayjs from 'dayjs'
import { formatToCPFOrCNPJ, formatToPhone, isCNPJ, isCPF } from 'brazilian-values'

const Landlord = ({ wizard }) => {
  const contract = useContract()

  const landlord = contract.payload.landlord
  const main = contract.payload.landlord?.addresses.main
  const billing = contract.payload.landlord?.addresses.billing

  const address = {
    city: '',
    state: '',
    number: '',
    street: '',
    zipcode: '',
    complement: '',
    neighborhood: '',
  }

  const initialValues = {
    document: formatToCPFOrCNPJ(landlord?.document || ''),
    name: landlord?.name || '',
    birthdate: dayjs(landlord?.birthdate).format('DD/MM/YYYY') || '',
    email: landlord?.email || '',
    phone: !!landlord?.phone ? formatToPhone(landlord?.phone) : '',
    nacionality: landlord?.nacionality || '1',
    emigrant: landlord?.emigrant || false,
    residence_country: landlord?.residence_country || '',
    company_type: landlord?.company_type || '',
    net_equity: landlord?.net_equity || '',
    gross_income: landlord?.gross_income || '',
    company_controllers: landlord?.company_controllers || '',
    economic_activity: landlord?.economic_activity || '',
    pep: landlord?.pep || '2',
    occupation: landlord?.occupation || '',
    addresses: {
      useSameAddress: landlord?.addresses.useSameAddress ?? true,
      main: {
        zipcode: main?.zipcode || '',
        street: main?.street || '',
        number: main?.number || '',
        complement: main?.complement || '',
        neighborhood: main?.neighborhood || '',
        city: main?.city || '',
        state: main?.state || '',
      },
      billing: {
        zipcode: billing?.zipcode || '',
        street: billing?.street || '',
        number: billing?.number || '',
        complement: billing?.complement || '',
        neighborhood: billing?.neighborhood || '',
        city: billing?.city || '',
        state: billing?.state || '',
      },
    },
  }

  const form = useFormik({
    initialValues,
    validationSchema: landlordSchema,
    onSubmit: values => {
      const landlord = landlordSchema.cast(values)
      if (!!landlord.birthdate) {
        landlord.birthdate = landlord.birthdate.split('/').reverse().join('-')
      }
      landlord.document = landlord.document?.replace(/\.|-|\//g, '')
      landlord.phone = landlord.phone?.replace(/\D/g, '')
      contract.update({ landlord })
      wizard.next()
    },
  })

  const zipcodeMain = useDebounce(form.values.addresses.main.zipcode)
  const zipcodeBilling = useDebounce(form.values.addresses.billing.zipcode)

  const [status, setStatus] = useState<'fetchingCustomer' | 'loaded' | ''>('')
  const [suitors] = useState(contract.payload.customers)
  const [documentDuplicated, setDocumentDuplicated] = useState<boolean>(false)

  const document = useDebounce(form.values.document)
  const useSameAddress = useDebounce(form.values.addresses.useSameAddress)

  const includeCompanyInfos = useMemo(
    () => form.values.company_type === '1',
    [form.values.company_type],
  )

  const fetchAddress = async (
    type: 'main' | 'billing',
    zipcode?: string,
    initialValues?: IAddressValuesProps,
  ) => {
    const address: any = await Modals.Address({
      zipcode,
      initialValues,
    })

    if (!address) return

    for (const [key, value] of Object.entries(address)) {
      form.setFieldValue(`addresses.${type}.${key}`, value)
    }
  }

  const handleBlurMain = () => {
    if (!!form.values.addresses.main.zipcode && !form.errors.addresses?.main?.zipcode) {
      fetchAddress('main', form.values.addresses.main.zipcode)
    }
  }

  const handleBlurBilling = () => {
    if (!!form.values.addresses.billing.zipcode && !form.errors.addresses?.billing?.zipcode) {
      fetchAddress('billing', form.values.addresses.billing.zipcode)
    }
  }

  const fetchCustomer = async documentType => {
    try {
      setStatus('fetchingCustomer')
      if (documentType === 'CPF') {
        const validate = suitors.find(suitor => {
          return suitor.document === document
        })

        setDocumentDuplicated(!!validate)

        if (validate) return false
        form.resetForm({
          values: {
            ...initialValues,
            document,
            email: '',
            addresses: {
              useSameAddress: true,
              main: address,
              billing: address,
            },
          },
        })

        const customer = await api.customers.getByDocument(document)

        form.setFieldValue('name', customer.name)
        form.setFieldValue('phone', formatToPhone(customer.phone))
        form.setFieldValue('birthdate', dayjs(customer.birthdate).format('DD/MM/YYYY'))
      } else {
        const validate = suitors.find(suitor => {
          return suitor.document === document
        })

        setDocumentDuplicated(!!validate)
        const documentParse = document.replace(/\.|-|\//g, '')

        if (validate) return false
        form.resetForm({
          values: {
            ...initialValues,
            document,
            email: '',
            addresses: {
              useSameAddress: true,
              main: address,
              billing: address,
            },
          },
        })

        const customer = await api.customers.getByDocument(documentParse)
        form.setFieldValue('name', customer.companyName)
        form.setFieldValue('phone', formatToPhone(customer.phone))
      }
    } finally {
      setStatus('loaded')
    }
  }

  const handleBlurDocument = () => {
    if (document !== '') {
      if (isCPF(document)) fetchCustomer('CPF')
      if (isCNPJ(document)) fetchCustomer('CNPJ')
    }
  }

  useEffect(() => {
    if (useSameAddress) form.setFieldValue('addresses.billing', address)
  }, [useSameAddress])

  useEffect(() => {
    if (!!contract.payload.landlord?.document) setStatus('loaded')
  }, [contract.payload.landlord?.document])

  return (
    <FormikProvider value={form}>
      <Grid columns="repeat(3, 1fr)" gap="1rem">
        <Forms.InputGroup
          name="document"
          label="CPF/CNPJ"
          placeholder="Digite o CPF/CNPJ"
          mask="document"
          readOnly={status === 'fetchingCustomer'}
          onBlur={handleBlurDocument}
          onKeyUp={() => setStatus('')}
        />
      </Grid>

      <Conditional when={documentDuplicated}>
        <Grid space="1rem 0">
          <Content.Message title="Atenção" kind="danger">
            O CPF não pode ser igual ao do pretendente.
          </Content.Message>
        </Grid>
      </Conditional>

      <Conditional when={status === 'fetchingCustomer'}>
        <Content.Loader alignment="left" message="Aguarde, estamos buscando os dados do cliente." />
      </Conditional>

      <Conditional
        when={
          (status === 'loaded' && !!form.values.document) || !!contract.payload.landlord?.document
        }
      >
        <Conditional
          when={(isCPF(document) || isCNPJ(document)) && status === 'loaded' && !documentDuplicated}
        >
          <Grid columns="repeat(3, 1fr)" gap="1rem" space="1rem 0">
            <Forms.InputGroup
              name="name"
              label="Nome Completo / Razão Social"
              placeholder="Digite o nome completo"
            />
            <Conditional when={isCPF(document)}>
              <Forms.InputGroup
                name="birthdate"
                label="Data de nascimento"
                placeholder="Digite a data de nascimento"
                mask="date"
              />
            </Conditional>

            <Conditional when={isCPF(document)}>
              <Forms.SelectGroup
                name="nacionality"
                label="Nacionalidade"
                defaultValue="1"
                options={[
                  {
                    label: 'Brasileira',
                    value: '1',
                  },
                  {
                    label: 'Estrangeira',
                    value: '2',
                  },
                ]}
              />
            </Conditional>

            <Forms.InputGroup
              name="phone"
              label="Telefone / Celular"
              placeholder="Digite o telefone/celular"
              mask="phone"
            />

            <Forms.InputGroup name="email" label="E-mail" placeholder="Digite o e-mail" />
            <Conditional when={isCPF(document)}>
              <Forms.Autocomplete
                name="occupation"
                label="Atividade profissional (opcional)"
                emptyMessage="Nenhuma atividade encontrada"
                initialValue={
                  occupations.find(occupation => occupation.id === landlord?.occupation)
                    ?.description ?? ''
                }
                onSearch={async term =>
                  occupations
                    .filter(occupation =>
                      removeSpecialChars(occupation.description)
                        .toLowerCase()
                        .includes(removeSpecialChars(term).toLocaleLowerCase()),
                    )
                    .map(occupation => ({
                      label: occupation.description,
                      value: occupation.id,
                    }))
                }
              />
            </Conditional>
          </Grid>

          <Grid columns="repeat(3, 1fr)" gap="1rem" space="1rem 0">
            <Conditional when={isCNPJ(document)}>
              <Forms.SelectGroup
                name="company_type"
                label="Tipo de empresa"
                options={[
                  {
                    label: 'Selecione o tipo de empresa',
                    value: '',
                  },
                  {
                    label: 'Empresa Privada',
                    value: '1',
                  },
                  {
                    label: 'Órgãos/ Empresas Públicas',
                    value: '2',
                  },
                  {
                    label: 'Embaixadas / Consulados',
                    value: '3',
                  },
                ]}
              />
              <Conditional when={includeCompanyInfos}>
                <Forms.SelectGroup
                  name="net_equity"
                  label="Patrimônio líquido"
                  options={[
                    {
                      label: 'Não desejo informar',
                      value: '6',
                    },
                    {
                      label: 'Sem patrimônio líquido',
                      value: '1',
                    },
                    {
                      label: 'Até 1.199.999',
                      value: '2',
                    },
                    {
                      label: 'De 1.200.000 a 10.499.999',
                      value: '3',
                    },
                    {
                      label: 'De 10.500.000 a 60.000.000',
                      value: '4',
                    },
                    {
                      label: 'Acima de 60.000.000',
                      value: '5',
                    },
                  ]}
                />

                <Forms.SelectGroup
                  name="gross_income"
                  label="Receita operacional bruta anual"
                  options={[
                    {
                      label: 'Não desejo informar',
                      value: '0',
                    },
                    {
                      label: 'Sem receita operacional bruta anual',
                      value: '1',
                    },
                    {
                      label: 'Até 1.199.999',
                      value: '2',
                    },
                    {
                      label: 'De 1.200.000 a 10.499.999',
                      value: '3',
                    },
                    {
                      label: 'De 10.500.000 a 60.000.000',
                      value: '4',
                    },
                    {
                      label: 'Acima de 60.000.000',
                      value: '5',
                    },
                  ]}
                />

                <Forms.SelectGroup
                  name="company_controllers"
                  label="Controladores"
                  options={[
                    {
                      label: 'Não desejo informar os administradores, controladores e procuradores',
                      value: '3',
                    },
                    {
                      label: 'Há administradores, controladores e procuradores',
                      value: '1',
                    },
                    {
                      label: 'Não há administradores, controladores e procuradores',
                      value: '2',
                    },
                  ]}
                />
              </Conditional>

              <Forms.Autocomplete
                name="economic_activity"
                label="Atividade Econômica"
                emptyMessage="Nenhuma atividade encontrada"
                initialValue={
                  economics.find(economic => economic.id === landlord?.economic_activity)
                    ?.description ?? ''
                }
                onSearch={async term =>
                  economics
                    .filter(
                      economic =>
                        removeSpecialChars(economic.description)
                          .toLowerCase()
                          .includes(removeSpecialChars(term).toLocaleLowerCase()) ||
                        economic.id.includes(term),
                    )
                    .map(economic => ({
                      label: economic.description,
                      value: economic.id,
                    }))
                }
              />
            </Conditional>
          </Grid>
          <Conditional when={isCPF(document)}>
            <Grid columns="repeat(3, 1fr)" gap="1rem" space="1rem 0">
              <Forms.SelectGroup
                name="pep"
                label="Pessoa Politicamente Exposta"
                defaultValue="1"
                options={[
                  {
                    label: 'Não',
                    value: '2',
                  },
                  {
                    label: 'Sim',
                    value: '1',
                  },
                ]}
              />
              <S.ToggleWrapper>
                <Forms.Toggle name="emigrant" label="Não reside no brasil" />
              </S.ToggleWrapper>
            </Grid>
          </Conditional>

          <Conditional when={form.values.emigrant && isCPF(document)}>
            <Grid columns="repeat(3, 1fr)" gap="1rem">
              <Forms.SelectGroup
                name="residence_country"
                label="País residente"
                options={[
                  {
                    label: 'Selecione o país residente',
                    value: '',
                  },
                  ...countries.map(({ code, name }) => ({
                    label: name,
                    value: code,
                  })),
                ]}
              />
            </Grid>
          </Conditional>

          <Grid columns="repeat(3, 1fr)" gap="1rem" space="1rem 0 0">
            <Forms.InputGroup
              name="addresses.main.zipcode"
              label="CEP"
              placeholder="Digite o CEP"
              mask="zipcode"
              onBlur={handleBlurMain}
            />

            <S.ToggleWrapper>
              <Forms.Toggle
                name="addresses.useSameAddress"
                label="Utilizar mesmo endereço para cobrança"
                data-gtm-type="select"
                data-gtm-name="utilizar-mesmo-endereco-para-cobranca"
                data-gtm-subname="locador"
              />
            </S.ToggleWrapper>

            <Conditional when={!form.values.addresses.useSameAddress}>
              <Forms.InputGroup
                name="addresses.billing.zipcode"
                label="CEP de cobrança"
                placeholder="Digite o CEP de cobrança"
                mask="zipcode"
                onBlur={handleBlurBilling}
              />
            </Conditional>
          </Grid>

          <Grid columns="repeat(2, 1fr)" gap="1rem" space="2rem 0 0">
            <Address
              fetchAddress={initialValues => fetchAddress('main', zipcodeMain, initialValues)}
              title="Endereço principal"
              {...form.values.addresses.main}
            />

            <Address
              fetchAddress={initialValues => fetchAddress('billing', zipcodeBilling, initialValues)}
              title="Endereço de cobrança"
              {...form.values.addresses.billing}
            />
          </Grid>
        </Conditional>
      </Conditional>

      <Actions>
        <Action icon="ArrowLeft" onClick={wizard.previous} kind="ghost">
          Voltar
        </Action>

        <Action icon="ArrowRight" onClick={() => form.handleSubmit()}>
          Próxima etapa
        </Action>
      </Actions>
    </FormikProvider>
  )
}

export default Landlord
