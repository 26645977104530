import api from '..'

export interface RentCollection {
  partner: string
  phone: string
  rentItems: RentItem[]
}

export interface RentItem {
  address?: string
  city?: string
  complement?: string
  debits: number
  document: string
  dueDate: Date
  number: number
  phones: string[]
  tenant: string
  tenantSocialName?: string
  uf?: string
  zipcode: string
}

export enum ProcessStatus {
  Error = 'error',
  PendingZipcode = 'pending-zipcode',
  PendingSync = 'pending-sync',
  Synced = 'synced',
}
export enum PaymentStatus {
  Pending = 'pending',
  Paid = 'paid',
}

export interface RentCollectionListItem {
  id: string
  partner: string
  partner_phone: string
  phones: string[]
  document: string
  tenant: string
  tenantSocialName?: string
  number: number
  address?: string
  complement?: string
  city?: string
  uf?: string
  dueDate: Date
  debits: number
  zipcode: string
  product: string
  process_status: ProcessStatus
  payment_status: PaymentStatus
  created_at: Date
  updated_at: Date
  error: any[]
}

export interface RentCollectionCreateItem {
  number: number
  product: 'Fiança'
  partner: string
  zipcode: string
  city?: string
  complement?: string
  address?: string
  document: string
  partner_phone: string
  tenant: string
  tenantSocialName?: string
  phones: string[]
  uf?: string
  dueDate: Date
  debits: number
}

export async function upload(rentCollection: RentCollection) {
  const rentCollectionBase64 = btoa(
    encodeURIComponent(JSON.stringify(rentCollection))
  )

  const { data } = await api.instance.v2.post('/rent-collection/upload', {
    rent_collection: rentCollectionBase64,
  })

  return data
}

export async function list(): Promise<RentCollectionListItem[]> {
  const { data } = await api.instance.v2.get<RentCollectionListItem[]>(
    `/rent-collection/partner`
  )

  return data.map((data) => ({
    ...data,
    dueDate: new Date(data.dueDate),
    created_at: new Date(data.created_at),
    updated_at: new Date(data.updated_at),
  }))
}

export async function setAsPaid(rentCollectionId: string) {
  await api.instance.v2.put(`/rent-collection/${rentCollectionId}/set-paid`)
}

export async function create(rentCollection: RentCollectionCreateItem) {
  await api.instance.v2.post(`/rent-collection`, rentCollection)
}
