export const coveragesParse = {
  rent: 'Aluguel',
  iptu: 'IPTU',
  condominium: 'Condomínio',
  water: 'Água',
  gas: 'Gás',
  electricity: 'Luz',
  property_damages: 'Danos ao imóvel',
  recision_fine: 'Rescisão de contrato',
  external_paint: 'Pintura externa',
  internal_paint: 'Pintura interna',
}

export const statusParse = {
  0: 'Proposta emitida',
  1: 'Proposta cancelada',
}
