import { Modals } from '@/components'

import { Content, IconContainer, Paragraph, Title } from './styles'
import { Icon } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { TCreateModal } from '@/components/Modals/promised'

interface ResultModalInterface extends TCreateModal {
  proposalDepreciationSystem: string
}

const ResultModal: React.FC<TCreateModal> = (props) => {
  return (
    <Modals.Content
      icon=""
      title=""
      onSubmit={{
        text: 'Voltar',
        onClick: (e) => {
          return props.onSubmit(e)
        },
      }}
      onCancel={{
        text: 'Fazer nova simulação',
        onClick: (e) => {
          return props.onDismiss(e)
        },
      }}
    >
      <Content>
        <IconContainer>
          <Icon
            {...{ name: 'circlecheck', color: 'porto-primitive-green-primary' }}
          />
        </IconContainer>

        <Title>
          Pré-proposta enviada com sucesso!
        </Title>

        <Paragraph>
          Seu cliente irá receber um SMS para confirmar as informações
          fornecidas no cadastro de pré-proposta. Após essa confirmação, em até
          24 horas, o time comercial do Banco Bari irá entrar em contato com seu
          cliente para dar seguimento ao processo.
        </Paragraph>

        <Paragraph>
          Caso precise de mais informações sobre o andamento de alguma proposta
          cadastrada, entre em contato com seu Correspondente ou com a Mesa de
          Atendimento Porto através do e-mail{' '}
          <b>emprestimo.garantiaimovel@portoseguro.com.br</b> ou através do
          WhatsApp <b>(11) 98595-0083</b>.
        </Paragraph>
      </Content>
    </Modals.Content>
  )
}

export default Modals.Promised.createModal<ResultModalInterface>(ResultModal)
