import React, { useMemo, useState } from 'react'

import { IBudgetProps } from './interfaces'
import data from './plans.data.json'

import * as S from './styles'

import { Forms } from '@/components'
import { useContract, useCurrentOrganization } from '@/modules'
import { formatMoneyBRL, generateRangeNumbers } from '@/tools'
import { usePaymentEnable } from '@/tools/hooks/usePaymentEnable'
import { capitalize } from '@brazilian-utils/brazilian-utils'
import {
  RadioButton,
  Typography
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Card } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { useFormikContext } from 'formik'
import { ModalPaymentsDetails } from '../../../../components/ModalPaymentsDetails'
import { Accordion } from '../Accordion'

const Budget: React.FC<IBudgetProps> = ({ id }) => {
  const [showPayments, setShowPaymnents] = useState(false)
  const [disabledSelect, setDisabledSelect] = useState(false)

  const contract = useContract()
  const form = useFormikContext<{ budget?: keyof typeof data }>()

  const paymentsEnable = usePaymentEnable('porto_essencial', id)
  const profile = useCurrentOrganization()

  const plan = useMemo(
    () => contract.budgets.find((e) => e.id === id),
    [contract.budgets, id]
  )

  const installment = useMemo(() => {
    if (!plan) return

    const payment = plan.payment_methods[plan.payment_methods.length - 1]

    if (!payment) return

    const prices = payment.installments[payment.installments.length - 1]

    return prices
  }, [plan])

  const labels = data[id]

  const handleClick = () => {
    if (!plan || disabledSelect) return

    form.setFieldValue('budget', id)
  }

  const toggleShowPayments = () => {
    setShowPaymnents(!showPayments)
  }

  const comissionValues = useMemo(() => {
    return [10, ...generateRangeNumbers(15, 35)].map((value) => ({
      label: `${value}%`,
      value,
    }))
  }, [])

  return (
    <>
      <S.Container
        aria-disabled={!plan}
        aria-checked={form.values.budget === id}
        onClick={handleClick}
      >
        <Card>
          <S.Col>
            <RadioButton
              checked={form.values.budget === id}
              value=""
              onClick={() => {}}
              title="Selecione"
            />

            <Typography as="span" variant="porto-title-5-semibold">
              {plan?.description ? capitalize(plan.description) : labels.title}
            </Typography>

            <Typography
              as="strong"
              variant="porto-title-4-bold"
              color="system-text-info"
            >
              {formatMoneyBRL(installment?.price)}{' '}
              <Typography
                as="span"
                variant="porto-text-body-2-regular"
                color="system-primitive-neutral-800"
              >
                /mês
              </Typography>
            </Typography>

            <Typography
              as="span"
              color="system-text-info"
              variant="porto-text-body-1-bold"
              onClick={toggleShowPayments}
              onMouseEnter={() => setDisabledSelect(true)}
              onMouseLeave={() => setDisabledSelect(false)}
            >
              Ver opções de parcelamento
            </Typography>

            <S.Warranty>
              <Typography as="span" variant="porto-title-6-medium">
                Garantias
              </Typography>
              {labels.warranty.map((value) => (
                <Typography as="span" variant="porto-text-body-2-regular">
                  <strong>{value.amount}</strong> {value.value}
                </Typography>
              ))}
            </S.Warranty>

            <S.Benefits>
              <Typography as="span" variant="porto-title-6-medium">
                Benefícios
              </Typography>

              {/* Essa condicional é necessária para exibir um 
              texto personalizado para benefícios, até que a API do
               produto retorne com valores mais completos   */}
              {id !== '0008' &&
                plan.benefits.map((b) => (
                  <Accordion
                    label={b.title}
                    onMouseEnter={() => setDisabledSelect(true)}
                    onMouseLeave={() => setDisabledSelect(false)}
                  >
                    <Typography variant="porto-text-body-2-regular">
                      {b.description}
                    </Typography>
                  </Accordion>
                ))}

              {/* Essa condicional é necessária para exibir um 
              texto personalizado para benefícios, até que a API do
               produto retorne com valores mais completos   */}
              {id === '0008' && (
                <Accordion
                  label={'Serviços para residência'}
                  onMouseEnter={() => setDisabledSelect(true)}
                  onMouseLeave={() => setDisabledSelect(false)}
                >
                  <Typography variant="porto-text-body-2-regular">
                    {
                      'Assistência em antenas, chaveiro, reparo máquina de lavar roupa, reparos de fogão cook top e forno, reparos de forno microondas, reparos elétricos, reparos hidráulicos, assistência bike, help desk celular ou smartphone, help desk equipamento Apple, help desk smart TV, help desk tablet, help desk telefônico micro residencial, help desk video game'
                    }
                  </Typography>
                </Accordion>
              )}
            </S.Benefits>

            {id === '0008' && profile?.type === 'BROKER' && (
              <Forms.InputGroup
                name="comission"
                label="Comissão parametrizada"
                readOnly
              />
            )}

            <Typography variant="porto-text-body-2-regular">
              Valor total: {formatMoneyBRL(installment?.total)}
            </Typography>
          </S.Col>
        </Card>
      </S.Container>
      {showPayments && (
        <ModalPaymentsDetails
          onDismiss={toggleShowPayments}
          paymentMethods={paymentsEnable}
        />
      )}
    </>
  )
}

export default Budget
