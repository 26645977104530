import dot from 'dot-object'

import { generateRangeNumbers } from '@/tools'

export const commission = (options: number[], disabled: any = false) => ({
  type: 'select',
  title: 'Comissão',
  description: 'Selecione a porcentagem de comissão.',
  disabled,
  options: options.map((value) => ({
    label: `${value}%`,
    value: value,
  })),
})

export const suseps = (key: string) => ({
  type: 'checkbox',
  title: 'Suseps',
  description: 'Selecione as suseps vinculadas.',
  options: ({ details, organization, getValue }) => {
    const isRealEstate = ['REAL_ESTATE'].includes(organization.type)
    const suseps = isRealEstate ? getValue(key) : details.suseps

    return suseps.map((susep) => ({
      label: susep,
      value: susep,
    }))
  },
})

export const prolabore = (
  disabledRef: string,
  fieldPreffix: string,
  maxPercentageRef: string,
  hasVariable: boolean = true
) => {
  const result = [
    {
      heading: 'Pró-labore',
      description: 'Ajuste os valores do pró-labore.',
      fields: {
        [fieldPreffix + '.enabled']: {
          type: 'toggle',
          title: 'Habilitar',
          disabled: ({ getValue }) => !getValue(disabledRef),
        },
        [fieldPreffix + '.percentage']: {
          type: 'select',
          title: 'Porcentagem',
          description: 'Valor máximo de acordo com a comissão.',
          disabled: ({ getValue }) => !getValue(fieldPreffix + '.enabled'),
          options: ({ getValue }) => {
            const comission = !Number.isNaN(Number(getValue(maxPercentageRef)))
              ? getValue(maxPercentageRef)
              : getValue(maxPercentageRef)?.percentage

            const max = 70

            if (Number(comission) === max) {
              return [
                {
                  label: '0%',
                  value: 0,
                },
              ]
            }

            return generateRangeNumbers(0, max - comission).map((value) => ({
              label: `${value}%`,
              value,
            }))
          },
        },
      },
    },
  ];

  if (hasVariable) {
    result[0].fields[fieldPreffix + '.variable'] = {
      type: 'toggle',
      title: 'Variável',
      disabled: ({ getValue }) => !getValue(fieldPreffix + '.enabled'),
    }
  }

  return result;
}

export const options = (
  key: string,
  options: {
    value: string
    label: string
    disabled?: boolean|((args:any)=>boolean)
  }[]
) => {
  return ({ details, organization }) => {
    const values = dot.pick(key, details.settings)
    const isRealEstate = ['REAL_ESTATE'].includes(organization.type)

    return isRealEstate
      ? options.filter(({ value }) => values.includes(value))
      : options
  }
}
