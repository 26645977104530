import { css } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/_shared'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import breakpoints from '@pol-npm/riscos-financeiros-dashboard-ui/dist/theme/breakpoints'
import styled from 'styled-components'

export const SecondSectionContainer = styled.div`
  padding: 64px 108px;

  @media (max-width: calc(${breakpoints.sm} - 1px)) {
    padding: 64px 12px;
  }
`

export const Title = styled(Typography)(
  css({
    sx: {
      marginTop: '64px',
    },
  }),
)

export const Description = styled(Typography)(
  css({
    sx: {
      marginTop: '16px',
      maxWidth: '912px',
    },
  }),
)

export const Container = styled.div`
  margin-top: 32px;
`
