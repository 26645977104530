import {
  Box,
  Flex,
  Typography,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'

import { Notification } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { Link } from '../components'

const FormSent = (props: { email: string }) => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        gap: '1rem',
      }}
    >
      <Link label="Voltar" icon="arrowleft" to="/login" />
      <Typography
        color="system-primitive-neutral-900"
        variant="porto-title-5-medium"
        content="Estamos enviando as instruções em seu e-mail"
      />
      <Typography
        variant="porto-text-body-1-regular"
        content={`
          Acesse a mensagem que enviamos para <b>${props?.email}</b> e
          valide seu e-mail para continuar a criação da conta.
        `}
      />
      <Box sx={{ marginTop: '1rem' }}>
        <Notification
          title="O e-mail não chegou?"
          description="Primeiro, confira a caixa de spam. Em seguida, entre em contato com seu consultor ou acesse nosso chat;"
          icon="circlequestion"
        />
      </Box>
    </Flex>
  )
}

export default FormSent
