import { themes } from '@/styles';
import styled from 'styled-components';
import media from 'styled-media-query';

interface Props {
  colorLink?: string;
  border?: string;
}

export const Container = styled.div<Props>`
  display: flex;
  width:100%;
  height: 100%;
  border-radius: ${(props) => props.border ? props.border : "8px"};

  margin-bottom: 16px;
  ${media.lessThan('medium')`
  margin-bottom: 12px;
    
  `}
`;

export const Icon = styled.div`
${media.lessThan('medium')`
    display: none ;
  `}
  color: ${themes.text.higher};
  margin-right: 20px;
  display: flex;

  svg {
    width: 20px;
    color: #94621F;
  }
`

export const Header = styled.div`
    display: flex;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height, or 140% */

    letter-spacing: 0.02em;

    color: #2F2663;
    margin-bottom: 26px;

    ${media.lessThan('medium')`
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */

    letter-spacing: 0.025em;   
  `}
`

export const Wrapper = styled.span<Props>`

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    letter-spacing: 0.025em;


    color: #000000;

   
    a {
    display: inline-block;
    position: relative;
    font-weight: bold;
    color: ${(props) => props.colorLink ? props.colorLink : '#2C3DA8'}

  }

  a:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: ${themes.palletes.primaryHover};
    transform-origin: bottom left;
    transition: transform 0.25s ease-out;
  }

  a:hover:after {
    transform: scaleX(1.0);
    transform-origin: bottom left;
  }
    
    ${media.lessThan('medium')`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;


    letter-spacing: 0.03em;
  `}
`

export const Content = styled.div`
    display: flex;
    padding: 16px 28px 24px;
    ${media.lessThan('medium')`
    padding: 11px 6px 32px;
   
  `}

`