import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { rem, size } from 'polished'

import { Icons } from '@/components'

import { themes } from '@/styles'

export const Container = styled.div`
  ${media.greaterThan('large')`
    top: 10px;
  `}
`

export const Panel = styled.div`
  border-radius: 0 0 8px 8px;
  background-color: ${themes.page.colors.background};

  ${media.greaterThan('large')`
    border-radius: 8px;
  `}
`

export const Toggle = {
  Box: styled.div<{ expanded?: boolean }>`
    padding: 8px 0;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 8px;
    background-color: ${themes.page.colors.background};

    ${(props) =>
      props.expanded &&
      css`
        border-radius: 8px 8px 0 0;
      `}
  `,

  Action: styled.button`
    all: unset;

    display: flex;
    align-items: center;

    font-size: ${rem('10px')};
    color: ${themes.text.high};

    > svg {
      ${size('12px')}

      margin-left: 4px;

      color: ${themes.text.medium};
    }
  `,
}

export const Head = styled.header`
  padding: 0 24px;
  padding-bottom: 0;
  margin-bottom: 24px;

  display: flex;
  align-items: center;

  color: ${themes.text.high};
  font: 500 ${rem('12px')} ${themes.fonts.title};

  ${media.greaterThan('large')`
    padding: 24px;
    padding-bottom: 0;
  `}

  ${media.greaterThan('huge')`
    padding: 32px;
    padding-bottom: 0;
    margin-bottom: 32px;

    font-size: ${rem('16px')};
  `}

  > svg {
    ${size('14px')}

    margin-left: 8px;

    color: ${themes.text.medium};

    ${media.greaterThan('huge')`
      ${size('18px')}
    `}
  }
`

export const Icon = styled(Icons.FeatherIcons)`
  color: ${themes.palletes.primary};
  ${size('18px')}
`

export const Group = styled.div`
  position: relative;

  padding: 24px;

  ${media.greaterThan('huge')`
    padding: 32px;
  `}

  &:before,
  &:after {
    ${size('12px')}

    position: absolute;

    content: '';

    border-radius: 100%;
    background-color: ${themes.page.colors.content};

    ${media.greaterThan('huge')`
      ${size('14px')}
    `}
  }

  &:before {
    bottom: -6px;
    left: -6px;

    ${media.greaterThan('huge')`
      bottom: -7px;
      left: -7px;
    `}
  }

  &:after {
    bottom: -6px;
    right: -6px;

    ${media.greaterThan('huge')`
      bottom: -7px;
      right: -7px;
    `}
  }

  &:first-of-type {
    padding-top: 0;
  }

  &:not(:last-of-type) {
    border-bottom: 1px dashed ${themes.utilities.border};
  }
`

export const Title = styled.h5`
  margin-bottom: 16px;

  display: flex;
  align-items: center;

  font-weight: 500;
  font-size: ${rem('11px')};
  color: ${themes.palletes.primary};

  ${media.greaterThan('huge')`
    font-size: ${rem('14px')};
  `}

  svg {
    ${size('11px')}

    margin-right: 8px;

    ${media.greaterThan('huge')`
      ${size('14px')}
    `}
  }
`

export const Item = styled.div`
  display: flex;
  align-items: flex-start;

  font-size: ${rem('9px')};
  color: ${themes.text.medium};

  ${media.greaterThan('huge')`
    font-size: ${rem('13px')};
  `}

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }
`

export const Label = styled.span`
  width: 60px;
  padding-right: 8px;
  font-weight: 600;

  word-break: keep-all;
  color: ${themes.text.high};

  ${media.greaterThan('huge')`
    width: 90px;
  `}
`

export const Value = styled.span`
  padding-right: 8px;

  flex: 1;

  ${media.greaterThan('huge')`
    padding-right: 16px;
  `}
`

export const Action = styled.button`
  all: unset;

  cursor: pointer;
  color: ${themes.palletes.primary};

  &:hover {
    text-decoration: underline;
  }
`
