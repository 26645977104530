import styled, { css } from 'styled-components'
import media from 'styled-media-query'

import themes from '@/styles/themes'

import { FeatherIcons, Loader as LoaderComponent } from '../../Icons'
import MessageComponent from '../Message'

import { IInputGroupProps } from './interfaces'

export const Container = styled.div<IInputGroupProps>`
  position: relative;

  display: inline-block;

  ${({ size }) =>
    size &&
    {
      block: css`
        width: 100%;
      `,
    }[size]}
`

export const Field = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  input {
    margin-bottom: 0px;
  }
`

export const Icon = styled(FeatherIcons)`
  height: 14px;

  transition: 0.3s;
  color: ${themes.palletes.primary};

  ${media.greaterThan('huge')`
    height: 18px;
  `}
`

export const Toggle = styled.button.attrs(() => ({
  type: 'button',
}))`
  all: unset;

  position: absolute;
  top: 50%;
  right: 3px;
  transform: translateY(-50%);

  width: 20px;
  height: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  border-radius: inherit;
  transition: 0.3s;

  &:hover {
    transition: 0.3s;
    background-color: #dcf0f8;

    ${Icon} {
      transition: 0.3s;
      color: ${themes.text.high};
    }
  }

  ${media.greaterThan('huge')`
    width: 26px;
    height: 26px;
  `}
`

export const Message = styled(MessageComponent).attrs(() => ({
  kind: 'danger',
}))``

export const Loader = styled(LoaderComponent)`
  position: absolute;
  top: 50%;
  right: 16px;

  ${media.greaterThan('huge')`
    top: 20px;
  `}
`

export const Icons = styled.div`
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  margin-left: 10px;
`
