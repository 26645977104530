import React from 'react'

import { ILabelProps } from './interfaces'

import { Asterisk, Container } from './styles'

const Label: React.FC<ILabelProps> = (props) => (
  <Container {...props}>
    {props.children}
    {props.showRequired && <Asterisk>*</Asterisk>}
  </Container>
)

export default Label
