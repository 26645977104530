import styled, { css } from 'styled-components'

import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import media from 'styled-media-query'
import { BiometryStatus } from '.'
import ActionsComponent from '../Actions'
import Button from '../Actions/Button'
import { Loader } from '../Icons'

export const Container = styled.div``

export const StatusCard = styled.div<{ status: BiometryStatus }>`
  width: 100%;
  padding: 16px;
  margin: 16px 0;
  border-radius: 8px;
  display: flex;
  transition: all 0.5s;
  overflow-y: auto;
  gap: 16px;

  ${({ status }) => {
    switch (status) {
      case 'pending':
        return css`
          color: var(--porto-primitive-yellow-80);
          background-color: var(--porto-primitive-yellow-30);
        `

      case 'done':
        return css`
          background-color: var(--porto-primitive-green-30);
          color: var(--porto-primitive-green-80);
        `

      default:
        return css`
          background-color: var(--porto-primitive-red-30);
          color: var(--porto-primitive-red-80);
        `
    }
  }}
`

export const Header = styled.h1`
  display: flex;
  font-family: 'Porto Roobert';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 8px;

  letter-spacing: 0.02em;

  color: #0a0047;
`

export const TitleCard = styled(Header)`
  margin-left: 40px;
`

export const Icon = styled.div`
  margin-right: 16px;
`

export const Description = styled.div`
  font-family: 'Porto Roobert';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-left: 40px;

  letter-spacing: 0.03em;

  color: #5f5f5f;
`

export const DescriptionContainer = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.lessThan('large')`
    flex-direction: column;
    align-self: unset;
  `}
`

export const TextBody = styled(Typography)`
  max-width: 664px;
`

export const DownloadButton = styled(Button)`
  align-self: flex-end;
  padding: 4px 8px;
  white-space: nowrap;
  font-weight: 500;

  ${Loader} {
    border: 1px solid inherit;
    border-left-color: inherit;
  }
`

export const CardNumber = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  margin-top: 16px;

  background: var(--porto-primitive-off-white);

  border-radius: 8px;
`

export const ContainerButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 24px;
  gap: 12px;
`

export const InfoContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  &:not(:last-child) {
    margin: 8px 0;
  }
`

export const InfoItem = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

export const BottomStyle = styled.div`
  margin-top: 16px;

  margin-left: 40px;
`

export const TopStyle = styled.div`
  margin-bottom: 24px;
`

export const Action = styled(ActionsComponent.Button).attrs({
  type: 'button',
})`
  &:last-of-type {
    float: right;
  }

  margin-top: 32px;
`
