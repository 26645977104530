/**
 * Shortcut for firebase public media
 *
 * @param path Including nested folder and file extension
 * @example firebaseMedia('images/severino.jpg')
 */
export function firebaseMedia(path: string) {
  // return `https://firebasestorage.googleapis.com/v0/b/static-porto-seguro-r-financeiros-stg/o/${encodeURIComponent(
  //   path
  // )}?alt=media`
  return `/${path}`
}
