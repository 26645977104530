import { Conditional, Forms, Grid, Modals } from '@/components'
import { useOrganizations } from '@/modules'
import { FormikProvider, useFormik } from 'formik'
import schema from './schema'
import { Content } from './styles'
namespace Filter {
  export type Values = Partial<{
    start: string
    end: string
    broker: string
    partner: string
  }>
  export type Props = Modals.Promised.PromisedModalProps<Filter.Values> & Filter.Values
  const Component = (props: Filter.Props) => {
    const {
      store: { active: currentProfile },
    } = useOrganizations()
    const form = useFormik<Filter.Values>({
      initialValues: {
        start: props.start || '',
        end: props.end || '',
        partner: props.partner || '',
      },
      validationSchema: schema,
      onSubmit: props.onSubmit,
    })
    return (
      <Modals.Content
        title="Filtros"
        icon="information-circle-outline"
        size="small"
        colorIcon="primary"
        onSubmit={{ text: 'Filtrar', onClick: () => form.handleSubmit() }}
        onDismiss={props.onDismiss}
        onCancel={{ text: 'Remover Filtros', onClick: () => props.onDismiss() }}
        onDismiss={props.onDismiss}
      >
        <Content>
          <FormikProvider value={form}>
            <Grid columns="repeat(1, 1fr)" gap="1rem">
              <Forms.Datepicker
                name="start"
                label="Início de vigência da renovação (Data Inicial)"
                placeholderText="Digite a data inicial"
              />
              <Forms.Datepicker
                name="end"
                label="Início de vigência da renovação (Data Final)"
                placeholderText="Digite a data final"
              />
              <Conditional when={currentProfile.type === 'BROKER'}>
                <Forms.InputGroup
                  name="partner"
                  label="Imobiliária"
                  placeholder="Digite o nome da imobiliária"
                />
              </Conditional>
            </Grid>
          </FormikProvider>
        </Content>
      </Modals.Content>
    )
  }
  export const openModal = Modals.Promised.createModal<Filter.Props>(Component)
}
export default Filter
