import { Flex } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'

import { usePagination } from '@/tools'

import { Actions } from '@/components'
import Dropdown from '../Dropdown'
import { Container } from './styles'

export default function Pagination() {
  const {
    quantity,
    isNextDisabled,
    isPreviousDisabled,

    onNextPage,
    onPreviousPage,

    onChangeQuantity,
  } = usePagination()

  return (
    <Container>
      <Flex
        sx={{
          gap: '1rem',
          justifyContent: 'center',
        }}
      >
        <Actions.Button
          kind="simple"
          icon="chevron-left"
          iconLeft
          isDisabled={isPreviousDisabled}
          onClick={() => onPreviousPage()}
        >
          Anteriror
        </Actions.Button>

        <Actions.Button
          kind="simple"
          icon="chevron-right"
          isDisabled={isNextDisabled}
          onClick={() => onNextPage()}
        >
          Próxima
        </Actions.Button>
      </Flex>

      <Dropdown value={quantity} onSelectOption={onChangeQuantity} />
    </Container>
  )
}
