import { useContext } from 'react'

import { SettingsContext } from '../../ui'

import * as S from './styles'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'

const SettingsHeading: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const context = useContext(SettingsContext)

  return (
    <S.Container>
      <S.First>
        <Typography variant="porto-title-5-bold">
          {context.isDefaultSettings
            ? 'Configurações padrão'
            : `${context.details.name} #${context.details.id}`}
        </Typography>
        <S.ButtonsContainer>{children}</S.ButtonsContainer>
      </S.First>

      <Typography variant="porto-text-body-1-regular">
        {context.isDefaultSettings
          ? 'Configure os produtos que todos os seus parceiros possuem acesso'
          : 'Configure os produtos que este parceiro possui acesso.'}
      </Typography>
    </S.Container>
  )
}

export default SettingsHeading
