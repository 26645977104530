import media from 'styled-media-query'
import styled from 'styled-components';
import { rem, size } from 'polished'

import { themes } from '@/styles'
import { FeatherIcons } from '@/components/Icons';

export const Container = styled.div`
  background: #F7F7F7;
  padding: 16px 23px;
`
export const Title = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 0px 0px 16px 0px;
  letter-spacing: 0.025em;
  color: #2F2663;
`
export const Content = styled.div`
  line-height: 180%;
  font-size: ${rem('8px')};
  color: ${themes.text.higher};
  ${media.greaterThan('huge')`
      font-size: ${rem('12px')};
  `}
  `
export const Link = styled.a`
  cursor: pointer;
  color: ${themes.palletes.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
      color: ${themes.palletes.primaryHover};
  }
`
export const Coverages = {
  Section: styled.div`
    margin-bottom: 32px;
  `,
  List: styled.table`
    width: 100%;
    margin-bottom: 10px;
    ${media.lessThan('medium')`
      width: 100%;
    `}
    text-align: left;
    .button-modal {
      div {
        display: flex ;
        justify-content: center;
        }
    }
    > thead {
      th {
        padding: 0 8px 8px;
        font-weight: 800;
        font-size: ${rem('12px')};
        color: #000;
        ${media.greaterThan('large')`
            font-size: ${rem('12px')};
          `}
        ${media.greaterThan('huge')`
            font-size: ${rem('14px')};
        `}
      }
    }
    > tbody {
      > tr {
        &:nth-child(odd) {
          background-color: #ffff;
        }
        > td {
          padding: 8px;
          font-size: ${rem('12px')};
          color: ${themes.text.high};
          ${media.greaterThan('large')`
              font-size: ${rem('12px')};
            `}
          ${media.greaterThan('huge')`
              padding: 8px 16px;

              font-size: ${rem('14px')};
            `}
        }
      }
    }
  `,
}
export const Button = styled.button`
  display: flex;
  justify-content: center;
  cursor: pointer;
  height: 32px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.025em;
  color: #0046C0;
  border: none;
  background: none ;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 8px;
`
export const Box = styled.div`
  ${media.lessThan('medium')`
    flex-direction: column ;
  `}
`
export const ContainerButton = styled.div`
  display: flex;
    ${media.lessThan('medium')`
    flex-direction: column ;
  `}
`
export const IconLink = styled(FeatherIcons)`
  ${size('18px')}
  color: '#0046C0';
  ${media.greaterThan('huge')`
    ${size('18px')}
  `}
`
export const Icon = styled(FeatherIcons)`
  ${size('18px')}
  margin-left: 8px;
  color: '#0046C0';
  pointer-events: none;
  ${media.greaterThan('huge')`
    ${size('18px')}
  `}
`