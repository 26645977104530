import { createEffect } from 'effector'

import { api } from '@/services'
import { toast } from '@/components'

import { ContractStore } from '../store'

export const sync = createEffect('syncContract', {
  handler: async () => {
    const payload = ContractStore.getState().payload
    const contract = await api.contracts.update(
      ContractStore.getState().id,
      payload
    )
    return contract
  },
})

ContractStore.on(sync.pending, (state, pending) => ({
  ...state,
  _status: pending ? 'syncing' : 'fulfilled',
}))
  .on(sync.fail, (state) => {
    toast('Ocorreu um erro ao tentar atualizar o contrato.', {
      type: 'error',
    })
    return state
  })
  .on(sync.doneData, (_, contract) => {
    toast('Contrato atualizado com sucesso!', {
      type: 'success',
    })
    return contract
  })
