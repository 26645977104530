import { Flex, Typography } from "@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base"
import { IAnchorsProps } from "./interfaces"
import * as S from './styles'

export const Anchors = ({
  title,
  values
}: IAnchorsProps) => (
  <Flex
    sx={{
      flexDirection: "column",
      width: "100%",
      maxWidth: "286px",
      gap: "1rem",
    }}
  >
    <Typography
      content={title}
      variant="porto-title-6-regular"
    />
    <ul
      style={{
        marginLeft: "2rem",
      }}
    >
      {values.map(({ label, anchor }) => (
        <S.ListItem
          key={`${label}_${anchor}`}
        >
          <Typography
            content={
              `<a href=${anchor}>${label}</a>`
            }
            variant="porto-text-body-2-regular"
          />
        </S.ListItem>
      ))}
    </ul>
  </Flex>
)