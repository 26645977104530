import { Flex, Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Icon } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'

const ExternalLink = (props: {
  label: string,
  icon?: string,
  linkProps: object,
}) => {
  return (
    <a {...props?.linkProps}>
      <Flex
        sx={{
          alignItems: "center",
          gap: ".25rem",
        }}
      >
        {props?.icon && <Icon
          name={props.icon}
          color="system-text-highlight"
        />}
        <Typography
          variant="porto-text-link-body-2-bold"
          color="system-text-highlight"
          content={props.label}
        />
      </Flex>
    </a>
  )
}

export default ExternalLink
