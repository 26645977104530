import { IFloatingComboOption } from '@/components/FloatingCombo/interfaces'
import { DEFAULT_BUDGET_STATUS_KEYS, ESSENCIAL_BUDGET_STATUS_KEYS } from '../../data/status.data'
import { BudgetStatus, BudgetType } from '../../enums'

export const createdAtFilterCustom = 'custom'
export const createdAtFilterOptions: IFloatingComboOption[] = [
  { label: 'Últimos 7 dias', value: 7 },
  { label: 'Últimos 28 dias', value: 28 },
  { label: 'Últimos 6 meses', value: 180 },
  { label: 'Últimos 3 meses', value: 90 },
  { label: 'Últimos 12 meses', value: 365 },
  { label: 'Personalizado', value: createdAtFilterCustom },
]

export const statusFilterOptions: (IFloatingComboOption & {
  products: string[]
})[] = [
  {
    label: DEFAULT_BUDGET_STATUS_KEYS[BudgetStatus.BUDGET],
    value: BudgetStatus.BUDGET,
    products: [BudgetType.INCENDIO],
  },
  {
    label: DEFAULT_BUDGET_STATUS_KEYS[BudgetStatus.CAP_AWAITING_PAYMENT],
    value: BudgetStatus.CAP_AWAITING_PAYMENT,
    products: [BudgetType.CAPITALIZCAO],
  },
  {
    label: DEFAULT_BUDGET_STATUS_KEYS[BudgetStatus.CAP_REGISTERING],
    value: BudgetStatus.CAP_REGISTERING,
    products: [BudgetType.CAPITALIZCAO],
  },
  {
    label: DEFAULT_BUDGET_STATUS_KEYS[BudgetStatus.CAP_REGISTERING_ERROR],
    value: BudgetStatus.CAP_REGISTERING_ERROR,
    products: [BudgetType.CAPITALIZCAO],
  },
  {
    label: DEFAULT_BUDGET_STATUS_KEYS[BudgetStatus.REFUSED],
    value: BudgetStatus.REFUSED,
    products: [BudgetType.INCENDIO],
  },
  {
    label: DEFAULT_BUDGET_STATUS_KEYS[BudgetStatus.BIOMETRY_APPROVED],
    value: BudgetStatus.BIOMETRY_APPROVED,
    products: [BudgetType.ESSENCIAL],
  },
  {
    label: ESSENCIAL_BUDGET_STATUS_KEYS[BudgetStatus.REFUSED],
    value: BudgetStatus.REFUSED,
    products: [BudgetType.ESSENCIAL],
  },
  {
    label: DEFAULT_BUDGET_STATUS_KEYS[BudgetStatus.BIOMETRY_EXPIRED],
    value: BudgetStatus.BIOMETRY_EXPIRED,
    products: [BudgetType.ESSENCIAL],
  },
  {
    label: DEFAULT_BUDGET_STATUS_KEYS[BudgetStatus.BIOMETRY_REFUSED],
    value: BudgetStatus.BIOMETRY_REFUSED,
    products: [BudgetType.ESSENCIAL],
  },
  {
    label: DEFAULT_BUDGET_STATUS_KEYS[BudgetStatus.BIOMETRY_REVIEW],
    value: BudgetStatus.BIOMETRY_REVIEW,
    products: [BudgetType.ESSENCIAL],
  },
  {
    label: DEFAULT_BUDGET_STATUS_KEYS[BudgetStatus.POLICY],
    value: BudgetStatus.POLICY,
    products: [BudgetType.INCENDIO, BudgetType.ALUGUEL, BudgetType.ESSENCIAL],
  },
  {
    label: DEFAULT_BUDGET_STATUS_KEYS[BudgetStatus.CANCELED],
    value: BudgetStatus.CANCELED,
    products: [BudgetType.ESSENCIAL],
  },
  {
    label: DEFAULT_BUDGET_STATUS_KEYS[BudgetStatus.POLICY_REFUSED],
    value: BudgetStatus.POLICY_REFUSED,
    products: [BudgetType.INCENDIO],
  },
  {
    label: DEFAULT_BUDGET_STATUS_KEYS[BudgetStatus.CAP_ERROR],
    value: BudgetStatus.CAP_ERROR,
    products: [BudgetType.CAPITALIZCAO],
  },
  {
    label: DEFAULT_BUDGET_STATUS_KEYS[BudgetStatus.ERROR],
    value: BudgetStatus.ERROR,
    products: [BudgetType.INCENDIO, BudgetType.ALUGUEL, BudgetType.ESSENCIAL],
  },
  {
    label: DEFAULT_BUDGET_STATUS_KEYS[BudgetStatus.PAC_REFUSED],
    value: BudgetStatus.PAC_REFUSED,
    products: [BudgetType.ALUGUEL],
  },
  {
    label: DEFAULT_BUDGET_STATUS_KEYS[BudgetStatus.PAC_APPROVED],
    value: BudgetStatus.PAC_APPROVED,
    products: [BudgetType.ALUGUEL],
  },
  {
    label: DEFAULT_BUDGET_STATUS_KEYS[BudgetStatus.CAP_ISSUED],
    value: BudgetStatus.CAP_ISSUED,
    products: [BudgetType.CAPITALIZCAO],
  },
]
