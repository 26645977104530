import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { rem } from 'polished'

import { Icons } from '@/components'
import { themes } from '@/styles'

export const Container = styled.div``

export const Card = styled.div`
  border-radius: 8px;
  background-color: ${themes.page.colors.background};

  ${media.greaterThan('large')`
    display: flex;
  `}
`

export const Navigation = styled.div`
  padding: 16px;
  padding-right: 0;

  border-right: 1px solid ${themes.utilities.border};

  ${media.greaterThan('large')`
    min-width: 180px;

    padding: 24px;
    padding-right: 0;
    margin-right: 16px;
  `}

  ${media.greaterThan('huge')`
    min-width: 250px;

    padding: 32px;
    padding-right: 0;
    margin-right: 24px;
  `}
`

export const Item = styled.button<{ active?: boolean }>`
  all: unset;

  position: relative;

  width: 100%;

  display: flex;
  align-items: center;

  cursor: pointer;
  transition: 0.15s;
  font-size: ${rem('8px')};
  color: ${themes.text.high};

  ${media.greaterThan('large')`
    font-size: ${rem('10px')};
  `}

  ${media.greaterThan('huge')`
    font-size: ${rem('14px')};
  `}

  &:hover {
    transition: 0.15s;
    color: ${themes.palletes.primary};
  }

  &:not(:last-of-type) {
    margin-bottom: 12px;

    ${media.greaterThan('huge')`
      margin-bottom: 16px;
    `}
  }

  &:before {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);

    width: 2px;
    height: 150%;

    content: '';
    border-radius: 30px 0 0 30px;
    background-color: transparent;
  }

  ${(props) =>
    props.active &&
    css`
      color: ${themes.palletes.primary};

      &:before {
        background-color: ${themes.palletes.primary};
      }
    `}
`

export const Icon = styled(Icons.Ionicons)`
  margin-right: 16px;

  font-size: ${rem('8px')};

  ${media.greaterThan('large')`
    font-size: ${rem('12px')};
  `}

  ${media.greaterThan('huge')`
    font-size: ${rem('18px')};
  `}
`

export const Inside = styled.div`
  padding: 16px;

  flex: 1;

  ${media.greaterThan('large')`
    padding: 24px;
  `}

  ${media.greaterThan('huge')`
    padding: 32px;
  `}
`

export const Head = styled.div`
  margin-bottom: 8px;

  ${media.greaterThan('large')`
    margin-bottom: 16px;
  `}

  ${media.greaterThan('huge')`
    margin-bottom: 24px;
  `}
`

export const Title = styled.p`
  font-weight: bold;
  font-size: ${rem('10px')};
  color: ${themes.text.high};

  ${media.greaterThan('large')`
    font-size: ${rem('14px')};
  `}

  ${media.greaterThan('huge')`
    font-size: ${rem('18px')};
  `}
`

export const Description = styled.p`
  padding-top: 4px;

  font-size: ${rem('8px')};
  color: ${themes.text.medium};

  ${media.greaterThan('large')`
    font-size: ${rem('10px')};
  `}

  ${media.greaterThan('huge')`
    font-size: ${rem('14px')};
  `}
`
