import { Actions } from "@/components"
import { api } from "@/services"
import { usePagination } from "@/tools"

interface IUpdateContracts {
  handleLastSynced: (param: Date) => void
}

export const UpdateContracts: React.FC<IUpdateContracts> = ({ handleLastSynced }) => {
  const { contracts, pushUpdate, finishUpdate, updateContractData } =
    usePagination()

  const onClick = () => {
    const ids = contracts.map((e) => e.id)

    handleLastSynced(new Date())

    ids.forEach((id) => {
      pushUpdate(id)
    })

    api.instance.v2
      .post(`/contracts/bulk-sync`, { contracts: ids })
      .then(({ data }) => {
        data && data.map(contract => {
          updateContractData(contract)
        })
      })
      .finally(() => {
        ids.forEach((id) => {
          finishUpdate(id)
        })
      })
  }

  return (
    <Actions.Button
      onClick={onClick}
      size="small"
      kind="simple"
      icon="rotateCw"
    />
  )
}
