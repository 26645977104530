import styled from 'styled-components'
import { rem, size } from 'polished'
import { motion } from 'framer-motion'

import { themes, helpers } from '@/styles'

export const Content = styled.div`
  padding: 12px;

  line-height: 140%;
  font-size: ${rem('13px')};
  font-family: ${themes.fonts.title};
  overflow: auto;
`

export const Arrow = styled.div`
  ${size('8px')}
  position: absolute;

  &:before {
    ${size('8px')}

    position: absolute;
    transform: rotate(45deg);

    content: '';
    background-color: ${themes.text.higher};
  }
`

export const Children = styled.div`
  cursor: pointer;
`
export const TitleStatus = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.03em;

  color: #842310;
`

export const Tooltip = styled(motion.div).attrs(() => ({
  tabIndex: -1,
}))`
  ${helpers.defaultBoxShadow}

  border-radius: 8px;
  color: ${themes.page.colors.background};
  background-color: ${themes.text.higher};

  &[data-placement^='bottom'] > ${Arrow} {
    top: -4px;
  }
  &[data-placement^='right'] > ${Arrow} {
    left: -4px;
  }
  &[data-placement^='left'] > ${Arrow} {
    right: -4px;
  }
  &[data-placement^='top'] > ${Arrow} {
    bottom: -4px;
  }
`
