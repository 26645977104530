import { themes } from '@/styles'
import { rem, rgba } from 'polished'
import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.fieldset`
  border: 0;
`

export const MuiInputRoot = styled.div<{ readOnly: boolean; error: unknown }>`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;

  transition: 0.3s;

  border: 1px solid ${themes.utilities.border};

  border-radius: 4px;

  margin-top: 8px;

  padding-inline: 16px;

  &,
  * {
    color: ${themes.text.high};
    -webkit-text-fill-color: ${themes.text.high};
    font-weight: 600;
    font-size: ${rem('11px')};
    font-family: ${themes.fonts.text};
  }

  &::placeholder {
    font-weight: 500;
    color: ${themes.text.low};
    -webkit-text-fill-color: ${themes.text.low};
  }

  &:focus-within {
    transition: 0.3s;
    border-color: ${themes.palletes.primary};
    box-shadow: 0 0 0 3px ${rgba(themes.palletes.primary, 0.12)};

    ${media.greaterThan('huge')`
      box-shadow: 0 0 0 4px ${rgba(themes.palletes.primary, 0.12)};
    `}
  }

  ${media.greaterThan('huge')`
    height: 55px;

    font-size: ${rem('13px')};
  `}

  ${(props) =>
    props.readOnly &&
    css`
      cursor: not-allowed;
      border-color: ${themes.utilities.border};
      background-color: ${themes.utilities.border};
    `}

    ${(props) =>
    props.error &&
    css`
      border-radius: 4px 4px 0 0;
      border-color: ${rgba(themes.palletes.danger, 0.5)};

      background-color: ${rgba(themes.palletes.danger, 0.02)};

      &:focus-within {
        border-color: ${themes.palletes.danger};
        background-color: ${rgba(themes.palletes.danger, 0.03)};
        box-shadow: 0 0 0 3px ${rgba(themes.palletes.danger, 0.1)};

        ${media.greaterThan('huge')`
          box-shadow: 0 0 0 4px ${rgba(themes.palletes.danger, 0.1)};
        `}
      }
    `}
`

export const MuiInputElement = styled.input`
  height: 100%;
  width: 100%;

  border: 0;
  outline: 0;

  margin-left: 4px;
  background-color: transparent;
`

export const MuiButtons = styled.span`
  display: none;
`
