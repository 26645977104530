import { api } from '@/services'
import { FormikProvider, useFormik } from 'formik'

import { Conditional, Content, Forms, Grid, Modals, toast } from '@/components'
import { SocialNameField } from '@/components/Forms/SocialNameField'
import { useFeatureEnable } from '@/tools'
import { isCEP, isCPF, isCPFOrCNPJ } from 'brazilian-values'
import { useMutation } from 'react-query'
import { validationSchema } from './schema'

const initialValues = {
  name: '',
  document: '',
  address: {
    zipcode: '',
    street: '',
    number: '',
    complement: '',
    neighborhood: '',
    city: '',
    state: '',
  },
}
namespace Register {
  export interface Values {
    initialValues: {
      name: string
      document: string
      address: {
        zipcode: string
        street: string
        number: string
        complement: string
        neighborhood: string
        city: string
        state: string
      }
    }
  }

  export type Props = Modals.Promised.PromisedModalProps<Register.Values> & Register.Values

  const Component = (props: Register.Props) => {
    const isEditing = !!props.initialValues

    const form = useFormik<typeof initialValues>({
      initialValues: props.initialValues || initialValues,
      validationSchema,
      onSubmit: (values: any) => {
        props.onSubmit(values)
      },
    })

    const fetchCustomerMutation = useMutation(async () => {
      if (form.errors?.document || !isCPFOrCNPJ(form.values.document))
        throw new Error('Documento inválido')

      const customer = await api.customers
        .getByDocument(form.values.document)
        .catch(() => undefined)

      form.setFieldValue('name', customer?.name || '')
    }, {})

    const addressMutate = useMutation({
      mutationFn: async () => {
        if (form.errors?.address?.zipcode || !isCEP(form.values?.address?.zipcode))
          throw new Error('CEP inválido')

        const address = await api.addresses
          .getByZipcode(form.values.address.zipcode)
          .catch(() => undefined)

        await Promise.all([
          form.setFieldValue('address.street', address?.street || '', false),
          form.setFieldValue('address.neighborhood', address?.neighborhood || '', false),
          form.setFieldValue('address.city', address?.city || '', false),
          form.setFieldValue('address.state', address?.state || '', false),
        ])

        return address
      },
      onError() {
        toast('Por favor insira um CEP válido.', {
          type: 'warning',
        })
      },
    })

    const featureEnable = useFeatureEnable()

    return (
      <Modals.Content
        icon="person"
        title={form.values.name || 'Cadastrar beneficiário'}
        onCancel={{
          text: 'Cancelar',
          onClick: () => props.onDismiss(),
        }}
        onDismiss={props.onDismiss}
        onSubmit={{
          text: isEditing ? 'Salvar edição' : 'Cadastrar beneficiário',
          onClick: () => form.handleSubmit(),
        }}
      >
        <FormikProvider value={form}>
          <Grid gap="2rem">
            <Forms.InputGroup
              resetValueOnUnmount={false}
              name="document"
              label="CPF/CNPJ"
              placeholder="Digite o CPF ou CNPJ"
              mask="document"
              onBlur={() => fetchCustomerMutation.mutateAsync()}
              readOnly={fetchCustomerMutation.isLoading}
            />

            {fetchCustomerMutation.isLoading ? (
              <Content.Loader
                alignment="left"
                message="Aguarde, estamos buscando os dados do cliente."
              />
            ) : (
              <>
                <Forms.InputGroup
                  resetValueOnUnmount={false}
                  name="name"
                  label="Nome Completo / Razão Social"
                  placeholder="Digite o nome"
                />

                {featureEnable.socialNameProducts.socialNameImobiliaria &&
                  isCPF(form.values.document) && (
                    <SocialNameField
                      textFieldGrid="1fr"
                      textFieldName="socialName"
                      toggleName="hasSocialName"
                    />
                  )}
              </>
            )}

            <Forms.InputGroup
              resetValueOnUnmount={false}
              name="address.zipcode"
              label="CEP"
              placeholder="Digite o CEP"
              mask="zipcode"
              onBlur={() => addressMutate.mutateAsync()}
            />
          </Grid>

          <Conditional when={addressMutate.isLoading}>
            <Content.Loader alignment="left" message="Buscando endereço, aguarde..." />
          </Conditional>

          <Conditional when={!!form.values.address.city}>
            <Grid columns={['auto', 'auto .2fr .5fr']} gap="1rem" space={['1rem 0', '2rem 0']}>
              <Forms.InputGroup
                resetValueOnUnmount={false}
                name="address.street"
                label="Logradouro"
                placeholder="Confirme a rua"
                readOnly={
                  !!addressMutate.data?.street ||
                  (form.values.address.street && !addressMutate.isSuccess)
                }
              />

              <Forms.InputGroup
                resetValueOnUnmount={false}
                name="address.number"
                label="Número"
                placeholder="Ex: 130"
              />

              <Forms.InputGroup
                resetValueOnUnmount={false}
                name="address.complement"
                label="Complemento"
                placeholder="Ex.: Bloco 10; Apto 172"
              />
            </Grid>

            <Grid columns={['repeat(2, 1fr)', '1fr 1fr .5fr']} gap="1rem">
              <Forms.InputGroup
                resetValueOnUnmount={false}
                name="address.neighborhood"
                label="Bairro"
                placeholder="Confirme o bairro"
                readOnly={
                  !!addressMutate.data?.neighborhood ||
                  (form.values.address.neighborhood && !addressMutate.isSuccess)
                }
              />

              <Forms.InputGroup
                resetValueOnUnmount={false}
                name="address.city"
                label="Cidade"
                placeholder="Confirme a cidade"
                readOnly={
                  !!addressMutate.data?.city ||
                  (form.values.address.city && !addressMutate.isSuccess)
                }
              />

              <Forms.InputGroup
                resetValueOnUnmount={false}
                name="address.state"
                label="Estado"
                placeholder="Confirme o estado"
                readOnly={
                  !!addressMutate.data?.state ||
                  (form.values.address.state && !addressMutate.isSuccess)
                }
              />
            </Grid>
          </Conditional>
        </FormikProvider>
      </Modals.Content>
    )
  }

  export const openModal = Modals.Promised.createModal<Register.Props>(Component)
}

export default Register
