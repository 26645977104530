import styled from 'styled-components'
import media from 'styled-media-query'

import { themes } from '@/styles'

export const Container = styled.div`
  position: relative;

  border-radius: 20px;
  background-color: ${themes.page.colors.content};

  ${media.greaterThan('large')`
    border-radius: 30px;
  `}
`
