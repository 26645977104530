import type { TagColor, TagType } from '../components/Status'
import { BudgetStatus, BudgetType } from '../enums'

type StatusBadgeType = {
  [key in BudgetStatus | string]: {
    color: TagColor
    type: TagType
  }
}

type BudgetStatusKeys = {
  [key in BudgetStatus]: string
}

type ProductStatusKeys = {
  [key in BudgetType]: BudgetStatusKeys
}

export const STATUS_TYPE: StatusBadgeType = {
  [BudgetStatus.BUDGET]: { color: 'blue-bank', type: 'soft' },
  [BudgetStatus.REFUSED]: { color: 'red', type: 'soft' },
  [BudgetStatus.CANCELED]: { color: 'red', type: 'soft' },
  [BudgetStatus.POLICY]: { color: 'green', type: 'hard' },
  [BudgetStatus.POLICY_REFUSED]: { color: 'gray', type: 'soft' },
  [BudgetStatus.PROPOSAL]: { color: 'violet', type: 'soft' },
  [BudgetStatus.PAC_APPROVED]: { color: 'green', type: 'soft' },
  [BudgetStatus.PAC_REFUSED]: { color: 'red', type: 'soft' },
  [BudgetStatus.PAC_REVIEW]: { color: 'yellow', type: 'soft' },
  [BudgetStatus.ERROR]: { color: 'red', type: 'soft' },
  [BudgetStatus.BIOMETRY_REVIEW]: { color: 'blue-bank', type: 'soft' },
  [BudgetStatus.BIOMETRY_APPROVED]: { color: 'green', type: 'soft' },
  [BudgetStatus.BIOMETRY_EXPIRED]: { color: 'red', type: 'soft' },
  [BudgetStatus.BIOMETRY_REFUSED]: { color: 'red', type: 'soft' },
  [BudgetStatus.CAP_DRAFT]: { color: 'blue-bank', type: 'soft' },
  [BudgetStatus.CAP_REGISTERING]: { color: 'yellow', type: 'soft' },
  [BudgetStatus.CAP_REGISTERING_ERROR]: { color: 'red', type: 'soft' },
  [BudgetStatus.CAP_AWAITING_PAYMENT]: { color: 'violet', type: 'soft' },
  [BudgetStatus.CAP_AWAITING_PAYMENT_LINK]: { color: 'violet', type: 'soft' },
  [BudgetStatus.CAP_ISSUED]: { color: 'green', type: 'hard' },
  [BudgetStatus.CAP_CANCELED]: { color: 'gray', type: 'soft' },
  [BudgetStatus.CAP_ERROR]: { color: 'red', type: 'soft' },
  [BudgetStatus.CAP_RENEWAL]: { color: 'yellow', type: 'soft' },
  [BudgetStatus.PENDING]: { color: 'yellow', type: 'soft' },
  [BudgetStatus.CAP_REDEMPTION_PERFORMED]: { color: 'gray', type: 'soft' },
  [BudgetStatus.CAP_CLOSED]: { color: 'gray', type: 'soft' },
  [BudgetStatus.MISSING_DOCUMENT]: { color: 'yellow', type: 'soft' },
}

export const DEFAULT_BUDGET_STATUS_KEYS: BudgetStatusKeys = {
  [BudgetStatus.BUDGET]: 'Calculado',
  [BudgetStatus.REFUSED]: 'Proposta recusada',
  [BudgetStatus.CANCELED]: 'Apólice cancelada',
  [BudgetStatus.POLICY]: 'Apólice emitida',
  [BudgetStatus.POLICY_REFUSED]: 'Apólice cancelada',
  [BudgetStatus.PROPOSAL]: 'Pagamento pendente',
  [BudgetStatus.PAC_APPROVED]: 'PAC aprovado',
  [BudgetStatus.PAC_REFUSED]: 'PAC recusado',
  [BudgetStatus.PAC_REVIEW]: 'PAC em análise',
  [BudgetStatus.ERROR]: 'Falha na Integração',
  [BudgetStatus.BIOMETRY_REVIEW]: 'Biometria em andamento',
  [BudgetStatus.BIOMETRY_APPROVED]: 'Análise aprovada',
  [BudgetStatus.BIOMETRY_EXPIRED]: 'Biometria expirada',
  [BudgetStatus.BIOMETRY_REFUSED]: 'Biometria recusada',
  [BudgetStatus.CAP_DRAFT]: 'Rascunho',
  [BudgetStatus.CAP_REGISTERING]: 'Em transmissão',
  [BudgetStatus.CAP_REGISTERING_ERROR]: 'Erro no cadastro',
  [BudgetStatus.CAP_AWAITING_PAYMENT]: 'Pagamento pendente',
  [BudgetStatus.CAP_AWAITING_PAYMENT_LINK]: 'Pagamento pendente',
  [BudgetStatus.CAP_ISSUED]: 'Título ativo',
  [BudgetStatus.CAP_CANCELED]: 'Título Cancelado',
  [BudgetStatus.CAP_ERROR]: 'Falha na integração',
  [BudgetStatus.CAP_RENEWAL]: 'Em Renovação',
  [BudgetStatus.CAP_REDEMPTION_PERFORMED]: 'Resgate realizado',
  [BudgetStatus.CAP_CLOSED]: 'Título encerrado',
  [BudgetStatus.FAILURE]: 'Erro',
  [BudgetStatus.PENDING]: 'Calculando',
  [BudgetStatus.MISSING_DOCUMENT]: 'Documentação pendente',
}

export const ESSENCIAL_BUDGET_STATUS_KEYS: BudgetStatusKeys = {
  ...DEFAULT_BUDGET_STATUS_KEYS,
  [BudgetStatus.REFUSED]: 'Análise recusada',
}

export const BUDGET_TEXT_KEYS: ProductStatusKeys = {
  [BudgetType.ALUGUEL]: DEFAULT_BUDGET_STATUS_KEYS,
  [BudgetType.CAPITALIZCAO]: DEFAULT_BUDGET_STATUS_KEYS,
  [BudgetType.ESSENCIAL]: ESSENCIAL_BUDGET_STATUS_KEYS,
  [BudgetType.INCENDIO]: DEFAULT_BUDGET_STATUS_KEYS,
}

function getAluguelStatus(contract) {
  if (Number(contract.policy?.id)) return BudgetStatus.POLICY
  if (contract.policy?.proposal) return BudgetStatus.PROPOSAL

  if (contract.metadata?.error?.code === '@aluguel/missing-documents')
    return BudgetStatus.MISSING_DOCUMENT

  if (
    contract.metadata?.statusPac === '@aluguel/pac-refused' ||
    contract.metadata?.error?.code === '@aluguel/pac-refused'
  )
    return BudgetStatus.PAC_REFUSED

  if (
    contract.metadata?.statusPac === '@aluguel/pac-review' ||
    contract.metadata?.error?.code === '@aluguel/pac-review'
  )
    return BudgetStatus.PAC_REVIEW

  if (
    contract.metadata?.statusPac === '@aluguel/approved' &&
    contract.budgets?.length &&
    contract.status === 'DONE'
  )
    return BudgetStatus.PAC_APPROVED

  return BudgetStatus.ERROR
}

function getCapitalizacaoStatus(contract) {
  return contract.metadata.status || contract.status
}

function getEssencialStatus(contract) {
  if (contract.policy?.status === '0') return BudgetStatus.POLICY

  if (contract.metadata?.status === '@essencial/biometry-review')
    return BudgetStatus.BIOMETRY_REVIEW
  if (contract.metadata?.status === '@essencial/approved') return BudgetStatus.BIOMETRY_APPROVED
  if (contract.metadata?.status === '@essencial/biometry-expired')
    return BudgetStatus.BIOMETRY_EXPIRED
  if (contract.metadata?.status === '@essencial/biometry-refused')
    return BudgetStatus.BIOMETRY_REFUSED

  if (contract.policy?.status === '1') return BudgetStatus.POLICY_REFUSED

  if (contract.metadata?.error?.code === '@essencial/pac-refused') return BudgetStatus.REFUSED
  if (contract.metadata?.error?.code === '@essencial/recalculation-error')
    return BudgetStatus.BUDGET

  if (contract.policy?.proposal && !contract?.metadata?.error) return BudgetStatus.BUDGET

  if (contract.policy?.id) return BudgetStatus.PROPOSAL

  return BudgetStatus.ERROR
}

function getIncendioStatus(contract) {
  if (contract.policy?.status === 'Recusada') return BudgetStatus.REFUSED
  if (contract.policy?.status === 'Cancelado') return BudgetStatus.CANCELED

  if (contract.policy?.id) return BudgetStatus.POLICY
  if (contract.policy?.proposal) return BudgetStatus.PROPOSAL

  if (contract?.budgets?.length > 0) return BudgetStatus.BUDGET

  return BudgetStatus.ERROR
}

const GET_STATUS = {
  [BudgetType.ALUGUEL]: getAluguelStatus,
  [BudgetType.CAPITALIZCAO]: getCapitalizacaoStatus,
  [BudgetType.ESSENCIAL]: getEssencialStatus,
  [BudgetType.INCENDIO]: getIncendioStatus,
}

export function getContractStatus(contract) {
  const getStatus = GET_STATUS[contract.product]
  return getStatus(contract)
}
