import basic from './basic'
import traditional from './traditional'
import university from './university'
import suseps from './suseps'

export default {
  ...basic,
  ...traditional,
  ...university,
  ...suseps,
}
