import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import { Icons } from '@/components'
import { themes } from '@/styles'
import { size, rem, rgba } from 'polished'
import ActionComponent from '../Actions'

interface UploadProps {
  isDragActive: boolean
  isDragReject: boolean
  refKey?: string
  [key: string]: any
  type?: 'error' | 'success' | 'default'
}

const dragActive = css`
  border-color: #12a454;
`

const dragReject = css`
  border-color: #e83f5b;
`

export const DropContainer = styled.div.attrs({
  className: 'dropzone',
})`
  border: 1.5px dashed #c1c6d9;
  border-radius: 5px;
  cursor: pointer;
  background-color: ${rgba('#d7e0f5', 0.3)};

  transition: height 0.2s ease;

  ${(props: UploadProps): false | FlattenSimpleInterpolation =>
    props.isDragActive && dragActive}

  ${(props: UploadProps): false | FlattenSimpleInterpolation =>
    props.isDragReject && dragReject}
`

const messageColors = {
  default: '#3c3a5e',
  error: '#e83f5b',
  success: '#12a454',
}

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
`

export const UploadMessage = styled.p`
  color: ${({ type }: UploadProps) => messageColors[type || 'default']};

  font-size: 16px;
  line-height: 24px;
  padding: 24px 0;
  text-align: center;
`

export const Icon = styled.p`
  ${size('60px')}
  color: ${themes.palletes.primary};
`

export const Button = styled(ActionComponent.Button).attrs(() => ({
  forwardedAs: 'a',
  icon: 'plus-circle',
}))``
