import { isCPF } from 'brazilian-values'
import * as yup from 'yup'

export const initialValues = {
  name: '',
  cpf: '',
}

export type FormShape = typeof initialValues

export const completeRegisterSchema: yup.SchemaOf<FormShape> = yup
  .object()
  .shape({
    name: yup
      .string()
      .matches(
        /(?=^.{2,60}$)^[a-zàáâãèéêìíóôõùúüç]+(?:[ ](?:das?|dos?|de|e|[a-zàáâãèéêìíóôõùúüç]+))*$/gi,
        'Por favor insira um nome válido'
      )
      .required('Campo nome é obrigatório.'),
    cpf: yup
      .string()
      .required('Campo CPF é obrigatório.')
      .test(
        'cpf-test',
        'Por favor insira um CPF válido.',
        (value) => !!value && isCPF(value)
      ),
  })
