import React, { useState, useRef } from 'react'
import Ink from 'react-ink'

import { useOutsideClick } from '@/tools'

import { IFloatingProps } from './interfaces'

import { Container, Toggle, Icon, Actions, Action } from './styles'

const Floating: React.FC<IFloatingProps> = (props) => {
  const actionsRef = useRef()

  const [visible, setVisible] = useState(false)

  const toggle = () => {
    setVisible((state) => !state)
  }

  useOutsideClick(actionsRef, toggle)

  return (
    <Container direction={props.direction} fixed={props.fixed}>
      <Toggle onClick={props.toggle.onClick || toggle}>
        <Ink />
        <Icon name={props.toggle.icon} />
      </Toggle>

      {props.actions && visible && (
        <Actions ref={actionsRef}>
          {props.actions.map((action, index) => (
            <Action key={index} onClick={toggle}>
              <Icon name={action.icon} onClick={action.onClick} />
            </Action>
          ))}
        </Actions>
      )}
    </Container>
  )
}

Floating.defaultProps = {
  direction: 'up',
  toggle: {
    icon: 'more-vertical',
  },
}

export default Floating
