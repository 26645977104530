
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Button, Icon } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { Link } from 'react-router-dom'

export const LeadSuccess: React.FC = () => {
  return (
    <>
      <Typography
        variant="porto-title-4-bold"
        as="h1"
        sx={{ display: 'block', marginBottom: '42px' }}
      >
        Cartão de Crédito Porto Bank
      </Typography>

      <Typography
        variant="porto-title-5-semibold"
        as="h2"
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
          marginBottom: '16px',
        }}
      >
        <Icon name="circlecheck" color="porto-primitive-green-primary" />
        Dados enviados com sucesso
      </Typography>
      <Typography
        variant="porto-text-body-1-regular"
        as="p"
        sx={{ display: 'block', marginBottom: '40px' }}
      >
        Os clientes selecionados receberão um SMS com o convite para solicitar o
        Cartão de Crédito Porto Bank.
      </Typography>

      <Button as={Link} to='/produtos/cartao-porto' kind='ghost' >Finalizar</Button>
    </>
  )
}
