import styled from 'styled-components'
import media from 'styled-media-query'
import { rgba, rem, lighten } from 'polished'

import { themes } from '@/styles'

import MessageComponent from '../Message'
import { ToggleProps } from './types'

export const Container = styled.label`
  cursor: pointer;
  user-select: none;
`

export const Field = styled.div`
  display: inline-flex;
  align-items: center;
`

export const Thumb = styled.div`
  position: relative;

  width: 38px;
  height: 22px;
  padding: 2px;

  border: 2px solid transparent;
  display: inline-flex;

  cursor: pointer;
  border-radius: 8px;
  background-color: ${rgba(themes.palletes.primary, 0.3)};

  ${media.greaterThan('huge')`
    width: 48px;
    height: 28px;
    padding: 3px;
  `}
`

export const Pin = styled.div`
  --ionicon-stroke-width: 50px;

  width: 18px;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 12px;
  border-radius: 6px;
  background-color: #fff;
  color: ${rgba(themes.palletes.primary, 0.5)};

  ${media.greaterThan('huge')`
    width: 22px;

    font-size: 16px;
  `}
`

export const Label = styled.p`
  margin-left: 6px;

  font-weight: 600;
  font-size: ${rem('8px')};
  color: ${themes.text.medium};
  font-family: ${themes.fonts.text};

  ${media.greaterThan('huge')`
    font-size: ${rem('12px')};
  `}
`

export const Input = styled.input.attrs(() => ({ type: 'checkbox' }))<
  Pick<ToggleProps, 'disabled'>
>`
  display: none;

  &:focus + ${Thumb} {
    border-color: ${lighten(0.25, themes.palletes.primary)};
    box-shadow: 0 0 0 3px ${rgba(themes.palletes.primary, 0.12)};
  }

  &:checked + ${Thumb} {
    justify-content: flex-end;

    background-color: ${themes.palletes.primary};
  }

  &:checked + ${Thumb} > ${Pin} {
    color: ${themes.palletes.primary};
  }

  &:checked + ${Thumb} + ${Label} {
    color: ${themes.palletes.primary};
  }
`

export const Message = styled(MessageComponent)`
  margin-top: 16px;

  border-radius: 8px;
`
