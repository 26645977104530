import { useEffect, useMemo } from 'react'

import { useField } from 'formik'

import { Forms, Tooltip } from '../../index'
import Message from '../Message'

import { IFieldToggler } from './interfaces'

import { Container, Content, Field, Icon, Icons, Inner, Value } from './styles'

const FieldToggler: React.FC<IFieldToggler> = (props) => {
  const [field, meta, helpers] = useField(props.name)

  const handleChange = (event, maskedValue, floatValue) => {
    field.onChange(event)
    helpers.setError(false)
    helpers.setTouched(false)
    helpers.setValue(floatValue, true)
  }

  const active = useMemo(() => props.required || !!field.value, [field.value])

  useEffect(() => {
    if (props.defaultValue) {
      helpers.setValue(props.defaultValue)
    }
  }, [props.defaultValue])

  useEffect(() => {
    return () => {
      helpers.setValue(0)
    }
  }, [])

  return (
    <Container>
      <Content readOnly={props.readOnly}>
        <Forms.Label showRequired={props.showRequired}>
          {props.label}
        </Forms.Label>
        <Inner readOnly={props.readOnly} error={meta.touched && !!meta.error}>
          <Field>
            <Value
              {...field}
              {...{
                ...(props['data-gtm-form'] && {
                  'data-gtm-form': props['data-gtm-form'],
                }),
                ...(props['data-gtm-name'] && {
                  'data-gtm-name': props['data-gtm-name'],
                }),
              }}
              placeholder="Digite aqui"
              disabled={props.readOnly}
              onChangeEvent={handleChange}
            />
          </Field>
        </Inner>
        {meta.touched && !!meta.error && (
          <Message kind="danger">{meta.error}</Message>
        )}
      </Content>

      {(props.tip || props.readOnly) && (
        <Icons>
          <Tooltip placement="top" content={props.tip}>
            {props.readOnly && active && <Icon name="info" />}
          </Tooltip>
        </Icons>
      )}
    </Container>
  )
}

FieldToggler.defaultProps = {
  label: 'Label',
}

export default FieldToggler
