import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useFormik, FormikProvider } from 'formik'

import { api } from '@/services'
import { Content, Actions } from '@/components'

import { Informations, Owner, Address } from './Steps'

import validationSchema from './validations'

import { Container } from './styles'

const Register = () => {
  const { Heading } = Content

  const history = useHistory()

  const [status, setStatus] = useState('')

  const onSubmit = async (values) => {
    try {
      setStatus('loading')

      const { id } = await api.organizations.create(values)

      history.push(`/organizacoes/${id}/parceiros`)
    } catch (error) {
      setStatus('error')
    }
  }

  const form = useFormik({
    initialValues: {
      document: '',
      name: '',
      email: '',
      type: '',
      address: {
        zipcode: '',
        street: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        state: '',
      },
      owner: {
        document: '',
        name: '',
      },
      realEstate: {
        code: '',
      },
      susep: {
        porto: '',
        official: '',
        extras: [],
      },
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  })

  return (
    <Container>
      <Heading
        title="Organizações"
        subtitle="Cadastre aqui uma nova organização."
      />

      <FormikProvider value={form}>
        <Informations />
        <Owner />
        <Address />
      </FormikProvider>

      <Actions.Form
        isLoading={status === 'loading'}
        onSubmit={{
          icon: 'check',
          label: 'Cadastrar organização',
          onClick: () => form.handleSubmit(),
        }}
        onCancel={{
          label: 'Cancelar',
          to: '/organizacoes',
        }}
      />
    </Container>
  )
}

export default Register
