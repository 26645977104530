import { css } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/_shared'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import styled from 'styled-components'

export const Card = styled.div`
  gap: 24px;
  padding: 20px 16px;
  max-width: 288px;
  margin: 0 auto;
`

export const CardImage = styled.img<{ bgColor?: string }>`
  border-radius: 8px;
  aspect-ratio: 1;
  object-fit: contain;
  padding: 16px;
  width: 100%;
  max-width: 80px;
  background-color: ${({ bgColor }) => bgColor || 'var(--porto-primitive-off-white)'};
`

export const CardTitle = styled(Typography)(
  css({
    sx: {
      marginTop: '32px',
    },
  }),
)

export const CardText = styled(Typography)(
  css({
    sx: {
      marginTop: '4px',
    },
  }),
)
