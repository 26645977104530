import React from 'react'
import { ThemeProvider } from 'styled-components'

import { Toasted, Modals } from '@/components'

import { GlobalStyles } from './sheets'

interface IProviderProps {
  children: React.ReactNode
}

const Provider: React.FC<IProviderProps> = (props) => {
  return (
    <ThemeProvider
      theme={{
        mode: 'light',
        layout: 'default',
      }}
    >
      <GlobalStyles />

      <Modals.Promised.Provider />

      <Toasted />

      {props.children}
    </ThemeProvider>
  )
}

export default Provider
