import React, { ReactElement, SVGProps } from 'react'

type GradientLogoProps = Omit<
  SVGProps<SVGSVGElement>,
  'fill' | 'xmlns' | 'viewBox'
>

const GradientLogo = (props: GradientLogoProps): ReactElement => {
  return (
    <svg
      viewBox="0 0 683 644"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity="0.15"
        d="M670.378 389C642.21 354.174 574.608 346.313 538.554 346.313C386.453 346.313 239.983 374.395 105.908 426.07C107.035 386.753 105.908 346.313 101.402 308.119C203.93 278.911 384.199 254.197 502.502 264.309C614.043 274.418 655.729 336.201 670.378 389ZM620.802 287.898C590.383 258.69 566.721 238.472 472.081 224.989C378.567 211.509 180.27 231.73 92.3889 254.197C85.627 219.373 77.7406 185.672 66.4755 151.972C126.189 141.862 188.157 136.246 251.251 136.246C312.092 136.246 372.932 141.862 431.521 151.972C556.581 172.193 596.016 240.717 620.802 287.898ZM0 0.32007C318.851 -7.54378 497.994 131.753 534.048 169.947C502.502 144.11 442.786 121.641 390.959 112.655C327.865 100.298 262.518 94.6815 194.916 94.6815C145.343 94.6815 96.8946 98.0521 49.5755 104.793C38.308 72.2151 15.773 29.5276 0 0.32007ZM620.802 590.078C672.636 484.485 660.237 465.386 558.835 477.744C402.227 496.842 205.056 564.241 55.2079 644C82.2484 591.201 95.7675 538.404 101.402 491.223C245.616 428.317 404.48 392.369 570.102 389C744.749 385.63 679.389 518.185 620.802 590.078Z"
        fill="url(#paint0_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="0"
          y1="0"
          x2="481.388"
          y2="491.368"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default GradientLogo
