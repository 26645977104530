import styled from 'styled-components'
import Slider from 'react-slick'
import { Icons } from '..'
import { themes } from '@/styles'
import breakpoints from '@pol-npm/riscos-financeiros-dashboard-ui/dist/theme/breakpoints'

export enum Direction {
  Left,
  Right,
}

export const Arrow = styled.div<{ $direction: Direction }>`
  align-items: center;
  background: var(--system-primitive-blue-50);
  border-radius: 50%;
  bottom: 6px;
  cursor: pointer;
  display: flex;
  height: 48px;
  justify-content: center;
  position: absolute;
  right: ${(props) => (props.$direction === Direction.Left ? 'unset' : 0)};
  transform: translateY(calc(100% + 16px));
  width: 48px;
  z-index: 5;
`

export const Icon = styled(Icons.FeatherIcons)`
  color: ${themes.palletes.action};
  font-size: 26px;
`

export const Dot = styled.div<{ $selected: boolean }>`
  background: ${({ $selected }) =>
    $selected
      ? 'var(--system-primitive-blue-500)'
      : 'var(--system-primitive-neutral-100)'};
  border-radius: 50%;
  height: 8px;
  margin-top: 32px;
  width: 8px;

  @media (max-width: ${breakpoints.sm}) {
    margin-top: 16px;
  }
`

export const SliderContainer = styled(Slider)`
  .slick-track {
    display: flex;
  }

  .slick-slide {
    display: flex;
    height: auto;
    justify-content: center;
  }
`
