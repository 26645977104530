import { IRealEstatePartnershipCombo } from './interfaces'
import { useEffect, useState } from 'react'
import { loadBrokerRealEstatesPartnerships } from '@/services/api/partnership'
import { useCurrentOrganization } from '@/modules'
import {
  BrokerRealEstatePartnership,
  BrokerRealEstatePartnerships,
} from '@/services/api/partnership/types'
import { AutocompleteLocal } from '../AutocompleteLocal'
import { useField } from 'formik'
import { useMutation } from 'react-query'

export const RealEstatePartnershipCombo = ({
  sx,
  selectionCallback = () => {},
  error = false,
  errorMessage = '',
  disabled = false,
}: IRealEstatePartnershipCombo) => {
  const [options, setOptions] = useState<BrokerRealEstatePartnerships>([])
  const [value, setValue] = useState(null)
  const profile = useCurrentOrganization()
  const requiredProfile = 'BROKER'

  const [field, meta, helpers] = useField('partner')

  const handleChange = (newValue: BrokerRealEstatePartnership) => {
    setValue(newValue)
    selectionCallback(newValue)
    helpers.setValue(newValue.id)
  }

  const loadOptionsMutation = useMutation(async (brokerId: string) => {
    const realEstateOptions = await loadBrokerRealEstatesPartnerships(brokerId)

    setOptions(() => realEstateOptions)

    if (field.value) {
      const selected = realEstateOptions.find((e) => e.id === field.value)
      handleChange(selected)
    }
  })

  useEffect(() => {
    if (profile.type !== requiredProfile) return

    loadOptionsMutation.mutate(profile.id)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile.id, profile.type])

  return (
    <AutocompleteLocal<BrokerRealEstatePartnership>
      label="Imobiliária"
      id="real-estate-partnership-combo"
      getOptionLabel={(option) => `${option.name} - ${option.id}`}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      selectionCallback={handleChange}
      error={error}
      errorMessage={errorMessage}
      options={options}
      loading={loadOptionsMutation.isLoading}
      sx={sx}
      value={value}
      disabled={disabled}
    ></AutocompleteLocal>
  )
}
