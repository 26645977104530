import styled from 'styled-components'
import { Grid as GridComponent } from '@/components'
import breakpoints from '@pol-npm/riscos-financeiros-dashboard-ui/dist/theme/breakpoints'

export const Container = styled.div`
  padding: 1.5rem 1rem;

  @media screen and (min-width: ${breakpoints.md}) {
    padding: 1.75rem 2rem;
  }

  @media screen and (min-width: ${breakpoints.lg}) {
    padding: 1.75rem 4rem;
  }
`

export const Grid = styled(GridComponent)`
  align-items: center;
`

export const Image = styled.img<{ objectFit?: 'cover' | 'contain' }>`
  width: 100%;
  max-height: 216;
  object-fit: ${({ objectFit = 'cover' }) => objectFit};
  border-radius: 12px;

  @media screen and (min-width: ${breakpoints.sm}) {
    max-height: 188px;
  }
`
