import { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { useCurrentOrganization } from '@/modules'
import { toast } from '@/components'
import { api } from '@/services'

import FilterBatchs from './Consultations/Listing/Components/FilterBatchs'
import FilterBudgets from './Consultations/Listing/Components/FilterBudgets'
import { Contract } from '@/modules/contract/interface'
import { Products } from '../Contracts/Products/shared/entities'

function usePagination() {
  const [page, setPage] = useState(1)

  return {
    get() {
      return page
    },
    set() {
      setPage(page)
    },
    next() {
      setPage((prev) => prev + 1)
    },
    prev() {
      setPage((prev) => prev - 1)
    },
  }
}

function useFilters<T>(initialValues?: T) {
  const [filters, setFilters] = useState<Partial<T>>(initialValues || {})

  return {
    get(): Partial<T> {
      return filters
    },
    add(params: Partial<T>) {
      setFilters((prev) => Object.assign({}, prev, params))
    },
    apply(params: Partial<T>) {
      setFilters(params)
    },
  }
}

export function useBatchesList(search: string) {
  const filters = useFilters<FilterBatchs.Values>()
  const page = usePagination()
  const profile = useCurrentOrganization()
  const isBroker = profile.type === 'BROKER'

  const { data, isFetching } = useQuery(
    ['batchesList', filters.get(), page.get(), search],
    () =>
      api.batchs.getBatchesList(
        filters.get(),
        page.get(),
        isBroker,
        search,
        profile.id
      ),
    {
      staleTime: 1000 * 75,
      refetchOnWindowFocus: false,
      onError: () => {
        toast('Ocorreu um erro ao buscar os contratos.', {
          type: 'error',
        })
      },
    }
  )

  return {
    filters,
    page,
    isBroker,
    profile,
    isFetching,
    batches: data || [],
  }
}

const onBudgetsSyncError = () => {
  toast('Ocorreu um erro ao buscar os contratos.', {
    type: 'error',
  })
}

export function useBudgetsBatch(id) {
  const filters = useFilters<FilterBudgets.Values>()

  const { data: budgets = [], ...budgetsQuery } = useQuery<
    Contract<Products>[]
  >(
    ['budgetsBatch', filters.get(), id],
    () => api.batchs.getBudgetsBatch(filters.get(), id),
    {
      refetchOnWindowFocus: false,
      onError: onBudgetsSyncError,
    }
  )

  const syncMutation = useMutation(async () => {
    await Promise.all(
      budgets.map((budget) =>
        api.contracts.sync({ id: budget.id }).catch(() => null)
      )
    )
    budgetsQuery.refetch()
  })

  return {
    filters,
    isFetching: budgetsQuery.isFetching || syncMutation.isLoading,
    budgets,
    syncBudgets: syncMutation.mutateAsync,
  }
}

export function useCountBudgets(budgets, status) {
  return budgets?.filter((budget) => budget.external_status === status)?.length
}
