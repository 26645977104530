import axios from 'axios'

export enum HTTP_STATUS_CODE {
  NOT_FOUND = 404,
  BAD_GATWAY = 502,
  INTERNAL_SERVER_ERROR = 500,
}

const v1 = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/v1`,
})

const v2 = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/v2`,
})

export { v1, v2 }
