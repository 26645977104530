import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Conditional, Content } from '@/components'
import { useContract } from '@/modules'

import { Action, Description, Grids, IconError } from './styles'
import { IErrorProps } from './interfaces'

const Error = ({
  product,
  code,
  message,
  title,
  subtitle,
  icon,
}: IErrorProps) => {
  const history = useHistory()
  const contract = useContract()

  const [isRecalculation, setIsRecalculation] = useState(false)
  const [isCalculated, setIsCalculated] = useState(false)

  const handleDescription = () => {
    if (product) {
      return history.push(`/contratos/novo/${product}`)
    }

    return history.push(`/contratos`)
  }

  const handleCalculate = async () => {
    try {
      await contract.calculate(contract.id)
    } catch (error) {
      throw error
    } finally {
      setIsCalculated(true)
    }
  }

  useEffect(() => {
    if (isCalculated && contract.external_status === 'DONE') {
      history.push(`/contratos/${contract.id}/pagamento`)
    }
  }, [contract.external_status, isCalculated])

  useEffect(() => {
    if (code === '@essencial/recalculation-error')
      return setIsRecalculation(true)
  }, [])

  return (
    <>
      <Content.Message
        title={title || 'Atenção'}
        kind={icon === 'search' ? 'warning' : 'danger'}
      >
        {subtitle}
      </Content.Message>

      <Conditional when={!isRecalculation}>
        <Grids>
          <IconError name={icon || 'frown'} />
        </Grids>
      </Conditional>

      <Description>
        {message ||
          'Lamentamos o inconveniente. Clique no botão para realizar um novo orçamento.'}
      </Description>

      <Grids>
        <Conditional when={!isRecalculation}>
          <Action onClick={handleDescription} icon="plus">
            Novo Orçamento
          </Action>
        </Conditional>

        <Conditional when={isRecalculation}>
          <Action onClick={handleCalculate} icon="repeat">
            Reenviar
          </Action>
        </Conditional>
      </Grids>
    </>
  )
}

export default Error
