import styled from "styled-components"

export const List = styled.ul`
  list-style: none;
`

export const ListItem = styled.li`

  display: flex;
  align-items: center;
  gap: .5rem;

  &::before {
    content: "•";
  }
`
