import { combineReducers, configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'

import {
  aluguel,
  analytics,
  batches
} from './ducks'

const reducer = combineReducers({
  aluguel,
  analytics,
  batches
})

export const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer,
  middleware: [thunk]
})

export type IRootState = ReturnType<typeof reducer>
