import styled from 'styled-components'

// necessário para ocultar a interface do componente Organization
export const OrganizationWrapper = styled.div`
  & header, & aside {
    display: none;
  }

  & input {
    width: 100%;
  }

  & > div > div {
    padding: 0 !important;

    & > div {
      padding: 0 !important;
    }
  }
`