import { rem, rgba, size } from 'polished'
import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import theme from 'styled-theming'

import { themes } from '@/styles'

import { FeatherIcons } from '../../Icons'

import { IFieldToggler } from './interfaces'

export const Container = styled.div`
  display: flex;
`
export const Content = styled.div<Pick<IFieldToggler, 'readOnly' | 'error'>>`
  width: 100%;

  ${(props) =>
    props.readOnly &&
    css`
      opacity: 0.6;
    `}
`

export const Icons = styled.div`
  align-items: center;
  justify-content: center;
  margin-top: 2.8rem;
  margin-left: 10px;

  ${media.lessThan('huge')`
    margin-top: 2.3rem;
  `}
`

export const Icon = styled(FeatherIcons)`
  ${size('12px')}

  cursor: help;
  color: ${themes.palletes.primary};

  &:not(:last-of-type) {
    margin-right: 8px;
  }

  ${media.greaterThan('large')`
    ${size('16px')}
  `}

  ${media.greaterThan('huge')`
    ${size('18px')}
  `}
`

export const Field = styled.div`
  flex: 1;
`

export const Value = styled.div.attrs(() => ({
  prefix: 'R$ ',
  decimalSeparator: ',',
  thousandSeparator: '.',
  type: 'tel',
}))`
  all: unset;

  width: 100%;

  display: block;

  font: 600 ${rem('8px')} ${themes.fonts.text};
  color: ${themes.text.high};
  -webkit-text-fill-color: ${themes.text.high};

  &::placeholder {
    color: ${themes.text.low};
    -webkit-text-fill-color: ${themes.text.low};
  }

  ${media.greaterThan('large')`
    font-size: ${rem('10px')};
  `}

  ${media.greaterThan('huge')`
    font-size: ${rem('13px')};
  `}

  ${(props) =>
    props.readOnly &&
    css`
      cursor: not-allowed;
    `}
`

export const Inner = styled.label<Pick<IFieldToggler, 'readOnly' | 'error'>>`
  position: relative;
  padding: 0 16px;

  width: 100%;
  height: 45px;

  display: inline-flex;
  align-items: center;

  cursor: text;
  user-select: none;
  border-radius: 8px;
  border: 1px solid ${themes.utilities.border};
  background-color: ${theme('mode', {
    light: '#fbfeff',
    dark: '#1c1f25',
  })};

  ${media.greaterThan('large')`
    height: 45px;
  `}

  ${media.greaterThan('huge')`
    height: 55px;
    padding: 0 24px;
  `}

  ${(props) =>
    props.readOnly &&
    css`
      ${Value} {
        cursor: not-allowed;
      }
    `}

  ${(props) =>
    props.error &&
    css`
      border-radius: 8px 8px 0 0;
      border-color: ${rgba(themes.palletes.danger, 0.5)};
      background-color: ${rgba(themes.palletes.danger, 0.02)};

      ${Icon} {
        color: ${themes.palletes.danger};
      }
    `}
`
