import { pxToRem } from '@/styles/sheets/helpers'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const Chevron = styled.div`
  display: flex;
  margin-left: ${pxToRem(8)};
  opacity: 1;
`

export const Container = styled.div<{ $isOpen: boolean; $isActive?: boolean }>`
  border-radius: ${pxToRem(4)};
  background-color: ${({ $isOpen, $isActive }) => {
    if ($isOpen) {
      return '#dfe2f2'
    } else if ($isActive) {
      return '#bfccec'
    } else {
      return 'initial'
    }
  }};
  cursor: pointer;
  position: relative;

  ${(props) =>
    !props.$isActive &&
    css`
      &:hover {
        background-color: #dfe2f2;
      }
    `}

  ${({ $isOpen, $isActive }) =>
    ($isOpen || $isActive) &&
    css`
      &:before {
        content: '';
        background-color: ${$isActive ? '#bfccec' : '#dfe2f2'};
        width: ${pxToRem(40)};
        position: absolute;
        left: 0;
        bottom: 0;
        top: 0;
        border-radius: ${pxToRem(4)};
        z-index: 0;
      }
    `}
`

export const Label = styled(Typography)`
  margin-left: ${pxToRem(12)};
  opacity: 1;
  transition: opacity 0.2s;
  width: 100%;
`

export const SubItemsContainer = styled.div`
  display: flex;
  gap: ${pxToRem(8)};
  flex-direction: column;
  padding: ${pxToRem(4)};
`

export const Item = styled(Link)<{
  $isSubItem?: boolean
  $isActive?: boolean
  $isMenuOpen?: boolean
}>`
  align-items: center;
  background-color: ${({ $isActive, $isMenuOpen }) =>
    $isActive && $isMenuOpen ? '#bfccec' : 'initial'};
  border-radius: ${pxToRem(4)};
  display: flex;
  padding: ${pxToRem(8)};
  position: relative;
  z-index: 0;

  ${(props) =>
    props.$isSubItem &&
    css`
      &:hover {
        background-color: #bfccec;
      }

      > ${Label} {
        margin-left: ${pxToRem(16)};
      }
    `}

  ${({ $isMenuOpen, $isActive, $isSubItem }) =>
    $isSubItem &&
    !$isMenuOpen &&
    $isActive &&
    css`
      &:before {
        content: '';
        background-color: #bfccec;
        width: ${pxToRem(32)};
        position: absolute;
        left: 0;
        bottom: 0;
        top: 0;
        border-radius: ${pxToRem(4)};
        z-index: -1;
      }
    `}
`

export const Tag = styled.div`
  background-color: #2c3da8;
  border-radius: ${pxToRem(8)};
  display: flex;
  padding: ${pxToRem(4)};
`
