import { css } from 'styled-components'
import media from 'styled-media-query'

import themes from '../themes'

export default css`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    font-size: 16px;
  }

  a {
    color: initial;
    text-decoration: none;
  }

  button {
    &:active {
      -webkit-tap-highlight-color: transparent;
    }
  }

  img {
    display: inline-block;
  }

  hr {
    all: unset;

    margin: 1.5rem 0;
    display: block;

    border-top: 1px solid ${themes.utilities.border};

    ${media.greaterThan('huge')`
      margin: 3rem 0;
    `}
  }

  input,
  select,
  textarea {
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      box-shadow: 0 0 0 30px white inset !important;
    }
  }
`
