export default {
  aluguel: '#7945E9',
  essencial: '#2ACFB2',
  imobiliaria: '#4586E9',
  primary: '#2C3DA8',
  primaryHover: '#2E3185',
  screenBackground: 'rgb(223, 226, 242)',
  terciary: '#0A0047',
  action: '#0046C0',
  info: '#2662C9',
  warning: '#94621F',
  danger: '#842310',
  success: '#034D2E',
} as const
