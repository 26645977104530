import { useEffect } from 'react'
import { useFormik, FormikProvider } from 'formik'

import customParseFormat from 'dayjs/plugin/customParseFormat'
import dayjs from 'dayjs'

import { Conditional, Content } from '@/components'
import { useContract } from '@/modules'
import { useGTM } from '@/tools'

import { Actions, Action } from '../../../Details/styles'
import { Grid } from '../../styles'
import { customersSchema } from '../../schema'

import * as S from './styles'
import TenantRegister from './Register'
import { formatToCPF, formatToPhone } from 'brazilian-values'

dayjs.extend(customParseFormat)

const Tenants = ({ wizard }) => {
  const contract = useContract()

  const form = useFormik({
    initialValues: {
      customers: [],
    },
    validationSchema: customersSchema,
    onSubmit: async ({ customers }) => {
      contract.update({ customers })
      wizard.next()
    },
  })

  const { setDataLayer } = useGTM()

  const edit = async (customer) => {
    const edited: any = await TenantRegister.openModal({
      initialValues: customer,
    })

    if (!edited) return

    edited.birthdate = dayjs(edited.birthdate, 'DD/MM/YYYY').format(
      'YYYY-MM-DD'
    )

    const target = form.values.customers.find(({ id }) => id === customer.id)
    const merge = Object.assign({}, target, edited)

    form.setFieldValue('customers', [
      ...form.values.customers.filter(({ id }) => id !== merge.id),
      merge,
    ])

    setDataLayer({
      event: 'solicitacao-servico',
      nome_servico: 'seguro-fianca-essencial',
      tipo_servico: 'salvar-alteracoes-inquilinos',
      retorno: 'sucesso',
      descricao: 'Sucesso ao editar inquilino',
    })
  }

  useEffect(() => {
    if (contract.payload.customers) {
      const customers = contract.payload.customers.map((customer) => ({
        ...customer,
        id: Math.random(),
        phone: formatToPhone(customer.phone),
      }))

      form.setFieldValue('customers', customers)
    }
  }, [])

  return (
    <Conditional when={contract.external_status !== 'PENDING'}>
      <FormikProvider value={form}>
        <form onSubmit={form.handleSubmit}>
          <Grid space={['1rem 0', '2rem 0']}>
            {form.values.customers.map((customer, index) => (
              <S.Card.Item
                key={customer.id}
                error={!!form?.errors?.customers?.[index]}
              >
                <S.Card.Detail>
                  <S.Card.Title>{customer.name}</S.Card.Title>
                  <S.Card.Description>
                    CPF: {formatToCPF(customer.document)}
                  </S.Card.Description>
                </S.Card.Detail>

                <S.Card.Actions>
                  <S.Card.Action
                    kind="ghost"
                    onClick={() => edit(customer)}
                    data-gtm-type="click"
                    data-gtm-clicktype="button"
                    data-gtm-name="editar-cadastro"
                  >
                    Editar cadastro
                  </S.Card.Action>
                </S.Card.Actions>
              </S.Card.Item>
            ))}
          </Grid>

          <Conditional when={!!form.errors.customers}>
            <Content.Message title="Atenção" kind="danger">
              Complete o cadastro dos inquilinos para continuar.
            </Content.Message>
          </Conditional>

          <Actions>
            <Action icon="ArrowLeft" onClick={wizard.previous} kind="smooth">
              Voltar
            </Action>

            <Action icon="ArrowRight" onClick={() => form.handleSubmit()}>
              Próxima etapa
            </Action>
          </Actions>
        </form>
      </FormikProvider>
    </Conditional>
  )
}

export default Tenants
