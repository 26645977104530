import { Box } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'

const SplitScreen = ({ children }) => {
  return (
    <Box
      sx={{
        height: { xs: "auto", lg: "100vh" },
        display: "grid",
        gridTemplateColumns: {
          xs: "100%",
          lg: "40% 60%",
          xl: "50% 50%",
        },
        alignItems: "flex-start",
      }}
    >{children}</Box>
  )
}

export default SplitScreen
