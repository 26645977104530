import { Icons } from '@/components'
import { Base } from '@/components/Modals'
import { capitalize } from '@brazilian-utils/brazilian-utils'
import {
  Flex,
  Typography,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Card } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'

import * as S from './styles'
import { formatMoneyBRL } from '@/tools'
import { PaymentMethod } from '@/modules/@types/Budget'

type Props = {
  paymentMethods: PaymentMethod[]
  onDismiss: () => void
}

export const ModalPaymentsDetails: React.FC<Props> = ({
  paymentMethods,
  onDismiss,
}) => {
  return (
    <Base onDismiss={onDismiss}>
      <Card>
        <Flex sx={{ justifyContent: 'space-between' }}>
          <Typography as="span" variant="porto-text-body-2-regular">
            Confira as opções de parcelamento
          </Typography>
          <Icons.FeatherIcons onClick={onDismiss} name="x" />
        </Flex>

        <S.PaymentGrid>
          {paymentMethods.map((pay) => (
            <S.PaymentContainer>
              <Typography as="span" variant="porto-title-6-medium">
                {capitalize(pay.description)}
              </Typography>

              <S.PaymentList>
                {pay.installments.map((i) => (
                  <S.PaymentListItem>
                    <Typography as="span" variant="porto-text-body-2-regular">
                      {i.description}
                    </Typography>
                    <Typography as="span" variant="porto-text-body-2-bold">
                      {formatMoneyBRL(i.price)}
                    </Typography>
                  </S.PaymentListItem>
                ))}
              </S.PaymentList>
            </S.PaymentContainer>
          ))}
        </S.PaymentGrid>
      </Card>
    </Base>
  )
}
