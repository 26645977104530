import styled from 'styled-components'
import { themes } from '@/styles'
import palletes from '@/styles/themes/palletes'

export const ModalSectionTitle = styled.h6`
  font-style: normal;
  font-weight: bold;
  color: ${themes.palletes.terciary};

  font-size: 20px;
  line-height: 130%;
  margin-block: 8px;
`

export const ModalSectionDescription = styled.p`
  color: ${themes.text.medium};

  font-size: 14px;
  line-height: 140%;
  text-align: center;
  padding: 32px 24px 0;

  &:last-of-type {
    padding-bottom: 32px;
  }

  a {
    font-weight: bold;
    color: ${palletes.primary};
  }
`
