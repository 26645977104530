import { lazy, useState, useEffect } from 'react'
import { Route, useHistory } from 'react-router-dom'

import { api } from '@/services'
import { Content, Tabs, Modals, toast } from '@/components'
import { useCurrentUser, useOrganizations, Settings } from '@/modules'

import Register from './components/Members/Register'

import { Container, Board, SettingsWrapper } from './styles'

const initialValues = {
  createdAt: new Date(),
  name: '',
  document: '',
  type: '',
  owner: {
    name: '',
    document: '',
  },
  address: {
    street: '',
    number: '',
    neighborhood: '',
    city: '',
    state: '',
    zipcode: '',
  },
}

const tabs = {
  Details: lazy(() => import('./components/Details')),
  Members: lazy(() => import('./components/Members')),
  Partners: lazy(() => import('./components/Partners')),
}

const Details = () => {
  const { Heading, Loader } = Content

  const history = useHistory()

  const user = useCurrentUser()
  const organization = useOrganizations()

  const organizationId = organization.store.active.id

  const [status, setStatus] = useState<'loading' | 'error' | ''>('')

  const [details, setDetails] = useState<typeof initialValues>(initialValues)

  const [members, setMembers] = useState([])
  const [invites, setInvites] = useState([])
  const [partners, setPartners] = useState([])

  const [currentUser, setCurrentUser] = useState({})

  const fetchOrganization = async () => {
    try {
      setStatus('loading')

      const [details, invites, partners] = await Promise.all([
        api.organizations.get(organizationId),
        api.organizations.invites(organizationId),
        api.organizations.partnerships.list({
          [organization.store.active.type === 'BROKER' ? 'broker' : 'partner']: organizationId,
        }),
      ])

      const { members, ...org } = details

      setDetails({
        ...org,
        type: {
          BROKER: 'BROKER',
          REAL_ESTATE: 'REAL_ESTATE',
        }[org.type],
        createdAt: org.created_at,
      })

      setCurrentUser(members.find(member => member.email === user.store.email))

      setMembers(
        members.sort((a, b) => {
          return Number(new Date(b.joined_at)) - Number(new Date(a.joined_at))
        }),
      )

      setInvites(
        invites.sort((a, b) => {
          return Number(new Date(b.created_at)) - Number(new Date(a.created_at))
        }),
      )

      setPartners(partners)

      setStatus('')
    } catch (error) {
      history.push('/organizacoes')

      toast('Esta organização que você tentou acessar não existe ou está fora do ar.', {
        type: 'error',
      })
    }
  }

  const handleNewMember = async () => {
    const response = await Register(organizationId)

    if (response) {
      setInvites(state => [...state, response])
    }
  }

  const removeMember = async (email: string) => {
    const confirm = await Modals.Confirmation({
      title: 'Remover integrante',
      content: 'Deseja realmente remover este integrante da organização?',
      icon: 'x',
      submitText: 'Remover integrante',
    })

    if (!confirm) return

    try {
      setMembers(state => state.filter(member => member.email !== email))

      await api.organizations.members.remove(organizationId, email)

      toast('integrante removido com sucesso!', {
        type: 'success',
      })
    } catch (error) {
      toast(
        'Ocorreu um erro ao tentar remover o integrante da organização, por favor tente novamente',
        {
          type: 'error',
        },
      )
    }
  }

  const promoteAdminRole = async (email: string) => {
    const confirm = await Modals.Confirmation({
      title: 'Promover integrante à Administrador?',
      content:
        'Deseja realmente promover este integrande como administrador? Um administrador pode adicionar ou remover membros da organização.',
      icon: 'x',
      submitText: 'Promover',
    })

    if (!confirm) return

    await api.organizations.members.updateRole(organizationId, email, 'admin')

    setMembers(e => {
      const index = e.findIndex(e => e.email === email)
      e[index].role = 'admin'
      return [...e]
    })
  }

  const removeAdminRole = async (email: string) => {
    const confirm = await Modals.Confirmation({
      title: 'Remover permissões de administrador?',
      content:
        'Deseja realmente remover permissões de administrador desse integrante? Um administrador pode adicionar ou remover membros da organização.',
      icon: 'x',
      submitText: 'Remover',
    })

    if (!confirm) return

    await api.organizations.members.updateRole(organizationId, email, 'member')

    setMembers(e => {
      const index = e.findIndex(e => e.email === email)
      e[index].role = 'member'
      return [...e]
    })
  }

  const transferOrganization = async (email: string) => {
    const confirm = await Modals.Confirmation({
      title: 'Promover usuário a proprietário?',
      content:
        'Deseja mesmo promover este usuário à proprietário da organização? Ao transferir a propriedade da organização,você deixa de ser proprietário.',
      icon: 'x',
      submitText: 'Promover',
    })

    if (!confirm) return

    await api.organizations.members.updateRole(organizationId, email, 'owner')

    setCurrentUser(e => ({ ...e, role: 'member' }))
    setMembers(e => {
      const index = e.findIndex(e => e.email === email)
      e[index].role = 'owner'
      return [...e]
    })
  }

  const cancelInvite = async invite => {
    const confirm = await Modals.Confirmation({
      title: 'Cancelar convite',
      content: 'Deseja realmente cancelar o convite desse integrante da organização?',
      icon: 'delete',
      submitText: 'Cancelar convite',
    })

    if (!confirm) return

    try {
      setInvites(state => state.filter(member => member.id !== invite.id))

      await api.invites.refuse(invite.id)

      toast('Convite cancelado com sucesso!', {
        type: 'success',
      })
    } catch (error) {
      toast(
        'Ocorreu um erro ao cancelar o convite do integrante selecionado, por favor tente novamente.',
        {
          type: 'error',
        },
      )
    }
  }

  useEffect(() => {
    fetchOrganization()
  }, [organizationId])

  return (
    <Container>
      <Heading
        title={details.name || 'Organizações'}
        subtitle="Ajuste aqui todas as informações da sua organização."
      />

      {status === 'loading' ? (
        <Loader message="Aguarde, buscando informações da organização." />
      ) : (
        <Board>
          <Tabs
            items={[
              {
                path: '/informacoes',
                icon: 'list',
                title: 'Informações',
                content: () => <tabs.Details currentUser={currentUser} {...details} />,
              },
              {
                path: '/integrantes',
                icon: 'people',
                title: 'Integrantes',
                content: () => (
                  <tabs.Members
                    members={members}
                    invites={invites}
                    currentUser={currentUser}
                    removeMember={removeMember}
                    cancelInvite={cancelInvite}
                    promoteAdminRole={promoteAdminRole}
                    removeAdminRole={removeAdminRole}
                    transferOrganization={transferOrganization}
                    handleNewMember={handleNewMember}
                  />
                ),
              },
              {
                path: '/parceiros',
                icon: 'git-merge',
                title: 'Parceiros',
                content: () => (
                  <tabs.Partners
                    partners={partners}
                    currentUser={currentUser}
                    type={details.type}
                  />
                ),
              },
            ]}
            noLoading={true}
          />

          <SettingsWrapper>
            <Route
              path="/minha-organizacao/parceiros/configuracoes/:partner"
              component={Settings}
            />
          </SettingsWrapper>
        </Board>
      )}
    </Container>
  )
}

export default Details
