export default [
  {
    id: '1',
    code: 'AFG',
    name: 'AFEGANISTAO',
  },
  {
    id: '2',
    code: 'ZAF',
    name: 'AFRICA DO SUL',
  },
  {
    id: '3',
    code: 'ALB',
    name: 'ALBANIA',
  },
  {
    id: '4',
    code: 'DEU',
    name: 'ALEMANHA',
  },
  {
    id: '5',
    code: 'AND',
    name: 'ANDORRA',
  },
  {
    id: '6',
    code: 'AGO',
    name: 'ANGOLA',
  },
  {
    id: '7',
    code: 'AIA',
    name: 'ANGUILLA',
  },
  {
    id: '8',
    code: 'ATA',
    name: 'ANTARCTICA',
  },
  {
    id: '9',
    code: 'ATG',
    name: 'ANTIGUA E BARBUDA',
  },
  {
    id: '10',
    code: 'ANT',
    name: 'ANTILHAS HOLANDESAS',
  },
  {
    id: '11',
    code: 'SAU',
    name: 'ARABIA SAUDITA',
  },
  {
    id: '12',
    code: 'DZA',
    name: 'ARGELIA',
  },
  {
    id: '13',
    code: 'ARG',
    name: 'ARGENTINA',
  },
  {
    id: '14',
    code: 'ARM',
    name: 'ARMENIA',
  },
  {
    id: '15',
    code: 'ABW',
    name: 'ARUBA',
  },
  {
    id: '16',
    code: 'AUS',
    name: 'AUSTRALIA',
  },
  {
    id: '17',
    code: 'AUT',
    name: 'AUSTRIA',
  },
  {
    id: '18',
    code: 'AZE',
    name: 'AZERBAIDJAO',
  },
  {
    id: '19',
    code: 'BHS',
    name: 'BAHAMAS',
  },
  {
    id: '20',
    code: 'BGD',
    name: 'BANGLADESH',
  },
  {
    id: '21',
    code: 'BRB',
    name: 'BARBADOS',
  },
  {
    id: '22',
    code: 'BHR',
    name: 'BAREIN',
  },
  {
    id: '23',
    code: 'BLR',
    name: 'BELARUS',
  },
  {
    id: '24',
    code: 'BEL',
    name: 'BELGICA',
  },
  {
    id: '25',
    code: 'BLZ',
    name: 'BELIZE',
  },
  {
    id: '26',
    code: 'BEN',
    name: 'BENIN',
  },
  {
    id: '27',
    code: 'BMU',
    name: 'BERMUDA',
  },
  {
    id: '28',
    code: 'BOL',
    name: 'BOLIVIA',
  },
  {
    id: '29',
    code: 'BIH',
    name: 'BOSNIA-HERZEGOVINA',
  },
  {
    id: '30',
    code: 'BWA',
    name: 'BOTSWANA',
  },
  {
    id: '32',
    code: 'BRN',
    name: 'BRUNEI',
  },
  {
    id: '33',
    code: 'BGR',
    name: 'BULGARIA',
  },
  {
    id: '34',
    code: 'BFA',
    name: 'BURKINA FASSO',
  },
  {
    id: '35',
    code: 'BDI',
    name: 'BURUNDI',
  },
  {
    id: '36',
    code: 'BTN',
    name: 'BUTAO',
  },
  {
    id: '37',
    code: 'CPV',
    name: 'CABO VERDE',
  },
  {
    id: '38',
    code: 'CMR',
    name: 'CAMAROES',
  },
  {
    id: '39',
    code: 'KHM',
    name: 'CAMBOJA',
  },
  {
    id: '40',
    code: 'CAN',
    name: 'CANADA',
  },
  {
    id: '41',
    code: 'QAT',
    name: 'CATAR',
  },
  {
    id: '42',
    code: 'KAZ',
    name: 'CAZAQUISTAO',
  },
  {
    id: '43',
    code: 'TCD',
    name: 'CHADE',
  },
  {
    id: '44',
    code: 'CHL',
    name: 'CHILE',
  },
  {
    id: '45',
    code: 'CHN',
    name: 'CHINA',
  },
  {
    id: '46',
    code: 'CYP',
    name: 'CHIPRE',
  },
  {
    id: '47',
    code: 'SGP',
    name: 'CINGAPURA',
  },
  {
    id: '48',
    code: 'COL',
    name: 'COLOMBIA',
  },
  {
    id: '49',
    code: 'COM',
    name: 'COMORES',
  },
  {
    id: '50',
    code: 'COG',
    name: 'CONGO',
  },
  {
    id: '51',
    code: 'PRK',
    name: 'COREIA DO NORTE',
  },
  {
    id: '52',
    code: 'KOR',
    name: 'COREIA DO SUL',
  },
  {
    id: '53',
    code: 'CIV',
    name: 'COSTA DO MARFIM',
  },
  {
    id: '54',
    code: 'CRI',
    name: 'COSTA RICA',
  },
  {
    id: '55',
    code: 'HRV',
    name: 'CROACIA',
  },
  {
    id: '56',
    code: 'CUB',
    name: 'CUBA',
  },
  {
    id: '57',
    code: 'DNK',
    name: 'DINAMARCA',
  },
  {
    id: '58',
    code: 'DJI',
    name: 'DJIBUTI',
  },
  {
    id: '59',
    code: 'DMA',
    name: 'DOMINICA',
  },
  {
    id: '60',
    code: 'EGY',
    name: 'EGITO',
  },
  {
    id: '61',
    code: 'SLV',
    name: 'EL SALVADOR',
  },
  {
    id: '62',
    code: 'ARE',
    name: 'EMIRADOS ARABES UNIDOS',
  },
  {
    id: '63',
    code: 'ECU',
    name: 'EQUADOR',
  },
  {
    id: '64',
    code: 'ERI',
    name: 'ERITREIA',
  },
  {
    id: '65',
    code: 'SVK',
    name: 'ESLOVAQUIA',
  },
  {
    id: '66',
    code: 'SVN',
    name: 'ESLOVENIA',
  },
  {
    id: '67',
    code: 'ESP',
    name: 'ESPANHA',
  },
  {
    id: '68',
    code: 'USA',
    name: 'ESTADOS UNIDOS DA AMERICA',
  },
  {
    id: '69',
    code: 'EST',
    name: 'ESTONIA',
  },
  {
    id: '70',
    code: 'ETH',
    name: 'ETIOPIA',
  },
  {
    id: '71',
    code: 'RUS',
    name: 'FEDERACAO RUSSA',
  },
  {
    id: '72',
    code: 'FJI',
    name: 'FIJI',
  },
  {
    id: '73',
    code: 'PHL',
    name: 'FILIPINAS',
  },
  {
    id: '74',
    code: 'FIN',
    name: 'FINLANDIA',
  },
  {
    id: '75',
    code: 'FRA',
    name: 'FRANCA',
  },
  {
    id: '76',
    code: 'FXX',
    name: 'FRANCA METROPOLITANA',
  },
  {
    id: '77',
    code: 'GAB',
    name: 'GABAO',
  },
  {
    id: '78',
    code: 'GMB',
    name: 'GAMBIA',
  },
  {
    id: '79',
    code: 'GHA',
    name: 'GANA',
  },
  {
    id: '80',
    code: 'GEO',
    name: 'GEORGIA',
  },
  {
    id: '81',
    code: 'GIB',
    name: 'GIBRALTAR',
  },
  {
    id: '82',
    code: 'GBR',
    name: 'GRA-BRETANHA',
  },
  {
    id: '83',
    code: 'GRD',
    name: 'GRANADA',
  },
  {
    id: '84',
    code: 'GRC',
    name: 'GRECIA',
  },
  {
    id: '85',
    code: 'GRL',
    name: 'GROENLANDIA',
  },
  {
    id: '86',
    code: 'GLP',
    name: 'GUADALUPE',
  },
  {
    id: '87',
    code: 'GUM',
    name: 'GUAM',
  },
  {
    id: '88',
    code: 'GTM',
    name: 'GUATEMALA',
  },
  {
    id: '89',
    code: 'GUY',
    name: 'GUIANA',
  },
  {
    id: '90',
    code: 'GUF',
    name: 'GUIANA FRANCESA',
  },
  {
    id: '91',
    code: 'GIN',
    name: 'GUINE',
  },
  {
    id: '92',
    code: 'GNQ',
    name: 'GUINE EQUATORIAL',
  },
  {
    id: '93',
    code: 'GNB',
    name: 'GUINE-BISSAU',
  },
  {
    id: '94',
    code: 'HTI',
    name: 'HAITI',
  },
  {
    id: '95',
    code: 'NLD',
    name: 'HOLANDA',
  },
  {
    id: '96',
    code: 'HND',
    name: 'HONDURAS',
  },
  {
    id: '97',
    code: 'HKG',
    name: 'HONG KONG',
  },
  {
    id: '98',
    code: 'HUN',
    name: 'HUNGRIA',
  },
  {
    id: '99',
    code: 'YEM',
    name: 'IEMEN',
  },
  {
    id: '100',
    code: 'BVT',
    name: 'ILHA BOUVET',
  },
  {
    id: '101',
    code: 'CXR',
    name: 'ILHA CHRISTMAS',
  },
  {
    id: '102',
    code: 'NFK',
    name: 'ILHA NORFOLK',
  },
  {
    id: '103',
    code: 'CYM',
    name: 'ILHAS CAYMAN',
  },
  {
    id: '104',
    code: 'CCK',
    name: 'ILHAS COCOS',
  },
  {
    id: '105',
    code: 'COK',
    name: 'ILHAS COOK',
  },
  {
    id: '106',
    code: 'ILHAS',
    name: 'DE GUERNSEY',
  },
  {
    id: '107',
    code: 'ILHAS',
    name: 'DE JERSEY 	108',
  },
  {
    id: '108',
    code: 'FRO',
    name: 'ILHAS FAROE',
  },
  {
    id: '109',
    code: 'SGS',
    name: 'ILHAS GEORGIA DO SUL E ILHAS SANDWICH DO',
  },
  {
    id: '110',
    code: 'HMD',
    name: 'ILHAS HEARD E MAC DONALD',
  },
  {
    id: '111',
    code: 'FLK',
    name: 'ILHAS MALVINAS',
  },
  {
    id: '112',
    code: 'MNP',
    name: 'ILHAS MARIANA',
  },
  {
    id: '113',
    code: 'MHL',
    name: 'ILHAS MARSHALL',
  },
  {
    id: '114',
    code: 'PCN',
    name: 'ILHAS PITCAIRN',
  },
  {
    id: '115',
    code: 'REU',
    name: 'ILHAS REUNIAO',
  },
  {
    id: '116',
    code: 'SLB',
    name: 'ILHAS SALOMAO',
  },
  {
    id: '117',
    code: 'SHN',
    name: 'ILHAS SANTA HELENA',
  },
  {
    id: '118',
    code: 'SJM',
    name: 'ILHAS SVALBARD E JAN MAYEN',
  },
  {
    id: '119',
    code: 'TKL',
    name: 'ILHAS TOKELAU',
  },
  {
    id: '120',
    code: 'TCA',
    name: 'ILHAS TURKS E CAICOS',
  },
  {
    id: '121',
    code: 'VIR',
    name: 'ILHAS VIRGENS',
  },
  {
    id: '122',
    code: 'VGB',
    name: 'ILHAS VIRGENS BRITANICAS',
  },
  {
    id: '123',
    code: 'WLF',
    name: 'ILHAS WALLIS E FUTUNA',
  },
  {
    id: '124',
    code: 'IND',
    name: 'INDIA',
  },
  {
    id: '125',
    code: 'IDN',
    name: 'INDONESIA',
  },
  {
    id: '126',
    code: 'IRN',
    name: 'IRA',
  },
  {
    id: '127',
    code: 'IRQ',
    name: 'IRAQUE',
  },
  {
    id: '128',
    code: 'IRL',
    name: 'IRLANDA',
  },
  {
    id: '129',
    code: 'ISL',
    name: 'ISLANDIA',
  },
  {
    id: '130',
    code: 'ISR',
    name: 'ISRAEL',
  },
  {
    id: '131',
    code: 'ITA',
    name: 'ITALIA',
  },
  {
    id: '132',
    code: 'YUG',
    name: 'IUGOSLAVIA',
  },
  {
    id: '133',
    code: 'JAM',
    name: 'JAMAICA',
  },
  {
    id: '134',
    code: 'JPN',
    name: 'JAPAO',
  },
  {
    id: '135',
    code: 'JOR',
    name: 'JORDANIA',
  },
  {
    id: '136',
    code: 'KIR',
    name: 'KIRIBATI',
  },
  {
    id: '137',
    code: 'KWT',
    name: 'KUWEIT',
  },
  {
    id: '138',
    code: 'LAO',
    name: 'LAOS',
  },
  {
    id: '139',
    code: 'LSO',
    name: 'LESOTO',
  },
  {
    id: '140',
    code: 'LVA',
    name: 'LETONIA',
  },
  {
    id: '141',
    code: 'LBN',
    name: 'LIBANO',
  },
  {
    id: '142',
    code: 'LBR',
    name: 'LIBERIA',
  },
  {
    id: '143',
    code: 'LBY',
    name: 'LIBIA',
  },
  {
    id: '144',
    code: 'LIE',
    name: 'LIECHTENSTEIN',
  },
  {
    id: '145',
    code: 'LTU',
    name: 'LITUANIA',
  },
  {
    id: '146',
    code: 'LUX',
    name: 'LUXEMBURGO',
  },
  {
    id: '147',
    code: 'MAC',
    name: 'MACAU',
  },
  {
    id: '148',
    code: 'MKD',
    name: 'MACEDONIA',
  },
  {
    id: '149',
    code: 'MDG',
    name: 'MADAGASCAR',
  },
  {
    id: '150',
    code: 'MYS',
    name: 'MALASIA',
  },
  {
    id: '151',
    code: 'MWI',
    name: 'MALAUI',
  },
  {
    id: '152',
    code: 'MDV',
    name: 'MALDIVAS',
  },
  {
    id: '153',
    code: 'MLI',
    name: 'MALI',
  },
  {
    id: '154',
    code: 'MLT',
    name: 'MALTA',
  },
  {
    id: '155',
    code: 'MAR',
    name: 'MARROCOS',
  },
  {
    id: '156',
    code: 'MTQ',
    name: 'MARTINICA',
  },
  {
    id: '157',
    code: 'MUS',
    name: 'MAURICIO',
  },
  {
    id: '158',
    code: 'MRT',
    name: 'MAURITANIA',
  },
  {
    id: '159',
    code: 'MYT',
    name: 'MAYOTTE',
  },
  {
    id: '160',
    code: 'MEX',
    name: 'MEXICO',
  },
  {
    id: '161',
    code: 'MMR',
    name: 'MIANMAR',
  },
  {
    id: '162',
    code: 'FSM',
    name: 'MICRONESIA',
  },
  {
    id: '163',
    code: 'MOZ',
    name: 'MOCAMBIQUE',
  },
  {
    id: '164',
    code: 'MDA',
    name: 'MOLDAVIA',
  },
  {
    id: '165',
    code: 'MCO',
    name: 'MONACO',
  },
  {
    id: '166',
    code: 'MNG',
    name: 'MONGOLIA',
  },
  {
    id: '167',
    code: 'MSR',
    name: 'MONTSERRAT',
  },
  {
    id: '168',
    code: 'NAM',
    name: 'NAMIBIA',
  },
  {
    id: '169',
    code: 'NRU',
    name: 'NAURU',
  },
  {
    id: '170',
    code: 'NPL',
    name: 'NEPAL',
  },
  {
    id: '171',
    code: 'NIC',
    name: 'NICARAGUA',
  },
  {
    id: '172',
    code: 'NER',
    name: 'NIGER',
  },
  {
    id: '173',
    code: 'NGA',
    name: 'NIGERIA',
  },
  {
    id: '174',
    code: 'NIU',
    name: 'NIUE',
  },
  {
    id: '175',
    code: 'NOR',
    name: 'NORUEGA',
  },
  {
    id: '176',
    code: 'NCL',
    name: 'NOVA CALEDONIA',
  },
  {
    id: '177',
    code: 'NZL',
    name: 'NOVA ZELANDIA',
  },
  {
    id: '178',
    code: 'OMN',
    name: 'OMA',
  },
  {
    id: '179',
    code: 'PLW',
    name: 'PALAU',
  },
  {
    id: '180',
    code: 'PAN',
    name: 'PANAMA',
  },
  {
    id: '181',
    code: 'PNG',
    name: 'PAPUA NOVA GUINE',
  },
  {
    id: '182',
    code: 'PAK',
    name: 'PAQUISTAO',
  },
  {
    id: '183',
    code: 'PRY',
    name: 'PARAGUAI',
  },
  {
    id: '184',
    code: 'PER',
    name: 'PERU',
  },
  {
    id: '185',
    code: 'PYF',
    name: 'POLINESIA FRANCESA',
  },
  {
    id: '186',
    code: 'POL',
    name: 'POLONIA',
  },
  {
    id: '187',
    code: 'PRI',
    name: 'PORTO RICO',
  },
  {
    id: '188',
    code: 'PRT',
    name: 'PORTUGAL',
  },
  {
    id: '189',
    code: 'KEN',
    name: 'QUENIA',
  },
  {
    id: '190',
    code: 'KGZ',
    name: 'QUIRGUIZIA',
  },
  {
    id: '191',
    code: 'CAF',
    name: 'REPUBLICA CENTRO-AFRICANA',
  },
  {
    id: '192',
    code: 'DOM',
    name: 'REPUBLICA DOMINICANA',
  },
  {
    id: '193',
    code: 'CZE',
    name: 'REPUBLICA TCHECA',
  },
  {
    id: '194',
    code: 'ROU',
    name: 'ROMENIA',
  },
  {
    id: '195',
    code: 'RWA',
    name: 'RUANDA',
  },
  {
    id: '196',
    code: 'ESH',
    name: 'SAHARA OCIDENTAL',
  },
  {
    id: '197',
    code: 'ASM',
    name: 'SAMOA AMERICANA',
  },
  {
    id: '198',
    code: 'WSM',
    name: 'SAMOA OCIDENTAL',
  },
  {
    id: '199',
    code: 'SMR',
    name: 'SAN MARINO',
  },
  {
    id: '200',
    code: 'LCA',
    name: 'SANTA LUCIA',
  },
  {
    id: '201',
    code: 'KNA',
    name: 'SAO CRISTOVAO E NEVIS',
  },
  {
    id: '202',
    code: 'SPM',
    name: 'SAO PIERRE E MIQUELON',
  },
  {
    id: '203',
    code: 'STP',
    name: 'SAO TOME E PRINCIPE',
  },
  {
    id: '204',
    code: 'VCT',
    name: 'SAO VICENTE E GRANADINAS',
  },
  {
    id: '205',
    code: 'SYC',
    name: 'SEICHELES',
  },
  {
    id: '206',
    code: 'SEN',
    name: 'SENEGAL',
  },
  {
    id: '207',
    code: 'SLE',
    name: 'SERRA LEOA',
  },
  {
    id: '208',
    code: 'SYR',
    name: 'SIRIA',
  },
  {
    id: '209',
    code: 'SOM',
    name: 'SOMALIA',
  },
  {
    id: '210',
    code: 'LKA',
    name: 'SRI LANKA',
  },
  {
    id: '211',
    code: 'SWZ',
    name: 'SUAZILANDIA',
  },
  {
    id: '212',
    code: 'SDN',
    name: 'SUDAO',
  },
  {
    id: '213',
    code: 'SWE',
    name: 'SUECIA',
  },
  {
    id: '214',
    code: 'CHE',
    name: 'SUICA',
  },
  {
    id: '215',
    code: 'SUR',
    name: 'SURINAME',
  },
  {
    id: '216',
    code: 'TJK',
    name: 'TADJIQUISTAO',
  },
  {
    id: '217',
    code: 'THA',
    name: 'TAILANDIA',
  },
  {
    id: '218',
    code: 'TWN',
    name: 'TAIWAN',
  },
  {
    id: '219',
    code: 'TZA',
    name: 'TANZANIA',
  },
  {
    id: '220',
    code: 'ATF',
    name: 'TERRITORIOS FRANCESES MERIDIONAIS',
  },
  {
    id: '221',
    code: 'TMP',
    name: 'TIMOR LESTE',
  },
  {
    id: '222',
    code: 'TGO',
    name: 'TOGO',
  },
  {
    id: '223',
    code: 'TON',
    name: 'TONGA',
  },
  {
    id: '224',
    code: 'TTO',
    name: 'TRINIDAD E TOBAGO',
  },
  {
    id: '225',
    code: 'TUN',
    name: 'TUNISIA',
  },
  {
    id: '226',
    code: 'TKM',
    name: 'TURCOMENIA',
  },
  {
    id: '227',
    code: 'TUR',
    name: 'TURQUIA',
  },
  {
    id: '228',
    code: 'TUV',
    name: 'TUVALU',
  },
  {
    id: '229',
    code: 'UKR',
    name: 'UCRANIA',
  },
  {
    id: '230',
    code: 'UGA',
    name: 'UGANDA',
  },
  {
    id: '231',
    code: 'URY',
    name: 'URUGUAI',
  },
  {
    id: '232',
    code: 'UZB',
    name: 'UZBEQUISTAO',
  },
  {
    id: '233',
    code: 'VUT',
    name: 'VANUATU',
  },
  {
    id: '234',
    code: 'VAT',
    name: 'VATICANO',
  },
  {
    id: '235',
    code: 'VEN',
    name: 'VENEZUELA',
  },
  {
    id: '236',
    code: 'VNM',
    name: 'VIETNA',
  },
  {
    id: '237',
    code: 'ZMB',
    name: 'ZAMBIA',
  },
  {
    id: '238',
    code: 'ZWE',
    name: 'ZIMBABUE',
  },
]
