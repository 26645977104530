import React from 'react'
import { Link } from 'react-router-dom'

import { ICategoryProps } from './interfaces'

import {
  Action, Container, Description, Details,
  Footer, Head,
  Icon, Title
} from './styles'

const Category: React.FC<ICategoryProps> = (props) => {
  return (
    <Container color={props.color} as={props.to ? Link : 'button'} to={props.to} onClick={props.onClick}>
      <Head>
        <Icon name={props.icon} />
      </Head>

      <Details>
        <Title>{props.title}</Title>

        <Description>{props.description}</Description>
      </Details>

      <Footer>
        <Action>
          <Icon name="arrow-forward" />
        </Action>
      </Footer>
    </Container>
  )
}

export default Category
