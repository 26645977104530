import { Container, Title, Text, Image } from './styles'

const ThirdSectionBanner = ({
  backgroundColor,
  title,
  text,
  image,
}: {
  backgroundColor: string
  title: string
  text: string
  image: string
}) => {
  return (
    <Container $backgroundColor={backgroundColor}>
      <Title variant="porto-title-6-bold" content={title} />
      <Text
        color="system-primitive-neutral-800"
        variant="porto-text-body-1-regular"
        content={text}
      />
      <Image src={image} />
    </Container>
  )
}

export default ThirdSectionBanner
