import { generateRangeNumbers } from '@/tools'

import { commission, options } from '../commons'

export default {
  'porto_aluguel.basic.enabled': {
    type: 'toggle',
    title: 'Disponibilizar Fiança Locatícia',
    description: 'Habilitar',
  },
  'porto_aluguel.basic.comission': commission(
    [10, ...generateRangeNumbers(15, 20)],
    ({ getValue }) => !getValue('porto_aluguel.basic.enabled')
  ),
  'porto_aluguel.basic.coverages': {
    type: 'checkbox',
    title: 'Coberturas',
    description: 'Selecione as coberturas disponíveis.',
    disabled: ({ getValue }) => !getValue('porto_aluguel.basic.enabled'),
    options: options('porto_aluguel.basic.coverages', [
      {
        value: 'rent',
        label: 'Aluguel',
        disabled: ({ user }) => !user.store.isAdmin,
      },
      {
        value: 'iptu',
        label: 'IPTU',
      },
      {
        value: 'condominium',
        label: 'Condomínio',
      },
    ]),
  },
}
