import styled from 'styled-components'

export const AlertContainer = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  gap: 12px;
  padding: 12px 16px;

  background-color: var(--porto-primitive-yellow-60);
  height: fit-content;
  width: 100%;
  align-items: center;
`

export const AlertCloseButton = styled.button`
  all: unset;

  cursor: pointer;
  padding: 4px;
  display: block;
`
