import { Forms, toast } from '@/components'
import { SocialNameField } from '@/components/Forms/SocialNameField'
import { AnimateVerticalShow } from '@/components/Utilities/Animations/AnimateVerticalShow'
import { useCurrentUser, useOrganizations } from '@/modules'
import { api } from '@/services'
import { BRLMoneyToNumber, useFeatureEnable } from '@/tools'
import { isValidCEP, isValidCNPJ, isValidCPF } from '@brazilian-utils/brazilian-utils'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { isCPF } from 'brazilian-values'
import { isBefore, parse, startOfToday } from 'date-fns'
import { FieldArray, FormikProvider, useFormik } from 'formik'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import Info from './Info'
import { Action, AddFieldButton, FormContainer } from './styles'

const FIELD_LIMIT = 4

interface Fields {
  document: string
  name: string
  socialName?: string
  zipcode: string
  number: ''
  phones: string[]
  dueDate: string
  debits: string
}

const initialValues = {
  document: '',
  name: '',
  zipcode: '',
  number: '',
  phones: [''],
  dueDate: '',
  debits: null,
}

const documentValidation = value => {
  if (value.length <= 14) return isValidCPF(value)

  return isValidCNPJ(value)
}

const validationSchema = yup.object().shape({
  document: yup
    .string()
    .required('Campo CPF/CNPJ é obrigatório.')
    .test(
      'document-test',
      'Por favor insira um CPF/CNPJ válido.',
      value => value && documentValidation(value),
    ),
  name: yup.string().required('Campo obrigatório'),
  zipcode: yup
    .string()
    .required('Campo obrigatório')
    .test('zipcode', 'Informe um cep válido', value => !!value && isValidCEP(value)),
  number: yup
    .string()
    .matches(/^[0-9]*$/g, 'Apenas números')
    .required('Campo obrigatório'),
  phones: yup.array().of(
    yup
      .string()
      .required('Campo obrigatório')
      .test('phone', 'Informe um número válido', value => {
        const valueDigits = value?.replace(/\D/g, '')
        return valueDigits?.length === 10 || valueDigits?.length === 11
      }),
  ),
  dueDate: yup
    .string()
    .required('Campo obrigatório')
    .min(10, 'Data inválida (formato dd/mm/aaaa)')
    .test('dueDate', 'Vencimento inválido', value => {
      const date = parse(value, 'dd/MM/yyyy', new Date())

      return isBefore(date, startOfToday())
    }),
  debits: yup
    .string()
    .min(1, 'Campo obrigatório')
    .required('Campo obrigatório')
    .transform(value => {
      const numberValue = value ? BRLMoneyToNumber(value) : null
      return isNaN(numberValue) ? undefined : `${numberValue}`
    }),
})

const Form = () => {
  const history = useHistory()
  const featureEnable = useFeatureEnable()
  const [isLoading, setIsLoading] = useState(false)
  const { store: organizationStore } = useOrganizations()
  const { store: currentUserStore } = useCurrentUser()

  const handleSubmit = async (values: Fields) => {
    setIsLoading(true)

    try {
      await api.rentCollection.create({
        document: values.document,
        tenant: values.name,
        tenantSocialName: values.socialName,
        zipcode: values.zipcode,
        phones: values.phones,
        dueDate: parse(values.dueDate, 'dd/MM/yyyy', new Date()),
        debits: BRLMoneyToNumber(values.debits),
        number: Number(values.number),
        partner: organizationStore.active.id,
        partner_phone: currentUserStore.phone,
        product: 'Fiança',
      })

      history.replace('/sinistro/assessoria-de-cobranca', {
        includeSuccessMessage: 'Cobrança registrada com sucesso!',
      })
    } catch (error) {
      toast('Ocorreu um erro, tente novamente mais tarde.', { type: 'error' })
    } finally {
      setIsLoading(false)
    }
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  })

  return (
    <FormikProvider value={formik}>
      <FormContainer>
        <Typography
          variant="porto-text-body-1-regular"
          color="system-primitive-neutral-900"
          content="Informe os dados do contrato e do cliente:"
        />
        <Forms.InputGroup
          size="block"
          name="document"
          mask="document"
          label="CPF/CNPJ"
          placeholder="000.000.000-00"
        />
        <Forms.InputGroup size="block" name="name" label="Nome completo" placeholder="Digite" />
        {featureEnable.socialNameProducts.socialNameAssessoriaDeCobranca && (
          <AnimateVerticalShow isOpen={isCPF(formik.values.document)}>
            <SocialNameField textFieldGrid="1fr" textFieldName="socialName" />
          </AnimateVerticalShow>
        )}
        <Forms.InputGroup
          size="block"
          name="zipcode"
          mask="zipcode"
          label="CEP"
          placeholder="Digite"
        />
        <Forms.InputGroup name="number" type="number" label="Número" placeholder="Ex: 130" />
        <FieldArray
          name="phones"
          render={arrayHelpers => {
            const hasReachedLimit = formik.values.phones.length >= FIELD_LIMIT
            return (
              <>
                {formik.values.phones.map((_, index) => (
                  <Forms.InputGroup
                    key={index}
                    size="block"
                    name={`phones.${index}`}
                    mask="phone"
                    label={`Celular ${index > 0 ? index + 1 : ''}`}
                    placeholder="(00) 90000-0000"
                  />
                ))}
                {!hasReachedLimit ? (
                  <AddFieldButton type="button" onClick={() => arrayHelpers.push('')}>
                    <Typography
                      variant="porto-text-link-body-2-bold"
                      color="system-text-highlight"
                      content="Adicionar outro celular"
                    />
                  </AddFieldButton>
                ) : null}
              </>
            )
          }}
        />
        <Forms.InputGroup
          name="dueDate"
          mask="date"
          label="Vencimento do aluguel"
          placeholder="dd/mm/aaaa"
        />
        <Forms.InputGroup name="debits" label="Valor dos débitos" mask="money" placeholder="R$" />
      </FormContainer>
      <Info />
      <Action isLoading={isLoading} onClick={formik.submitForm}>
        Iniciar comunicação de atraso
      </Action>
    </FormikProvider>
  )
}

export default Form
