import styled from 'styled-components'
import { rem } from 'polished'

import { themes } from '@/styles'

export const Container = styled.div``

export const Content = styled.div``

export const Progress = styled.p`
  margin-bottom: 16px;

  font-size: ${rem('14px')};
  color: ${themes.text.medium};
`

export const ToggleWrapper = styled.div`
  padding-top: 38px;
`

export const Choices = styled.div`
  > label {
    display: inline-block;

    &:not(:last-of-type) {
      margin-right: 16px;
      margin-bottom: 10px;
    }
  }
`
