export const formatMoneyBRL = value => {
  if (value == undefined) return 'R$ -'

  return parseFloat(value).toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'currency',
    currency: 'BRL',
  })
}

export const isBRLMoneyMasked = (value: string): boolean => {
  const isBRL = /^R\$\s/

  return isBRL.test(value)
}

export const BRLMoneyToNumber = (value: string) => {
  return isBRLMoneyMasked(value)
    ? Number(
        value
          .replaceAll(/[^0-9.,]/g, '') // Remove tudo que NÃO é um número, ponto ou vírgula
          .replace(/\./g, '')
          ?.replace(',', '.'),
      )
    : 0
}
