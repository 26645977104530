import styled from 'styled-components'
import { rem } from 'polished'
import media from 'styled-media-query'

import { themes } from '@/styles'

export const Content = styled.div`
  line-height: 180%;
  font-size: ${rem('8px')};
  color: ${themes.text.higher};

  ${media.greaterThan('huge')`
    font-size: ${rem('12px')};
  `}
`

export const Coverages = {
  Section: styled.div`
    margin-bottom: 32px;
  `,

  List: styled.table`
    width: 100%;
    margin-bottom: 10px;

    text-align: left;

    > thead {
      th {
        padding: 0 8px 8px;

        font-weight: 500;
        font-size: ${rem('8px')};
        color: ${themes.palletes.primary};

        ${media.greaterThan('large')`
            font-size: ${rem('10px')};
          `}

        ${media.greaterThan('huge')` 
            font-size: ${rem('14px')};
        `}
      }
    }

    > tbody {
      > tr {
        &:nth-child(odd) {
          background-color: ${themes.page.colors.content};
        }

        > td {
          padding: 8px;

          font-size: ${rem('7px')};
          color: ${themes.text.high};

          ${media.greaterThan('large')`
              font-size: ${rem('9px')};
            `}

          ${media.greaterThan('huge')`
              padding: 8px 16px;
  
              font-size: ${rem('14px')};
            `}
        }
      }
    }
  `,
}
