import { useEffect, useMemo, useState } from 'react'

import { Conditional, Content, Forms, Grid } from '@/components'
import { useContract } from '@/modules'
import { FormikProvider, useFormik } from 'formik'
import { useHistory } from 'react-router-dom'

import { dashText, formatMoneyBRL, useGTM } from '@/tools'

import validationSchema from './schema'

import { api } from '@/services'
import { usePaymentEnable } from '@/tools/hooks/usePaymentEnable'
import { capitalize } from '@brazilian-utils/brazilian-utils'
import {
  RadioButton,
  Typography,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import {
  Button,
  Card,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { useQuery } from 'react-query'
import { ModalPaymentsDetails } from '../components/ModalPaymentsDetails'
import * as S from './styles'

const Payments = () => {
  const [showPayments, setShowPaymnents] = useState(false)

  const contract = useContract()
  const history = useHistory()

  const { setCustomData, setDataLayer } = useGTM()

  const budget = useMemo(
    () =>
      contract.budgets.find(
        (budget) => budget.id === contract.payload?.payment?.budget
      ),
    [contract.budgets, contract.payload?.payment?.budget]
  )

  const methodEnabled = usePaymentEnable('porto_essencial', budget?.id)

  useEffect(() => {
    setCustomData({
      page: {
        product: 'aluguel-fianca',
        name: 'Portal Imobiliária - Orçamento - Seguro Fianca Essencial Resumo Formas de Pagamento',
      },
      site: {
        brand: 'portoseguro',
        portal: 'imobiliarias',
      },
      orcamento: {
        protocolo: contract.policy?.proposal,
        tipo: contract.product,
        parceiro_id: contract.partner?.id,
        susep: contract.broker?.id,
        plano: budget?.description,
      },
    })

    setDataLayer({
      event: 'step_change',
      titulo:
        'Portal Imobiliária - Seguro Fianca Essencial Status Forma de Pagamentos',
      etapa:
        '/area-logada/orcamentos/seguro-fianca-essencial/resumo-formas-de-pagamento',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const form = useFormik({
    initialValues: {
      method: '',
      installment: '',
      card: {
        number: '',
        expiration_date: '',
      },
    },
    validationSchema,
    onSubmit: async (values) => {
      const payment = validationSchema.cast(values)
      // contract.update({ payment: { budget: budget.id, ...payment } })
      try {
        await api.contracts.update(contract.id, {
          payment: { budget: budget.id, ...payment },
        })
        const order = await api.contracts.order(contract.id, {
          ...contract.payload,
          payment: { budget: budget.id, ...payment },
        })
        contract.updateAll({
          ...contract,
          policy: order.policy,
          payload: {
            ...contract.payload,
            payment: { budget: budget.id, ...payment },
          },
        })

        const orderBudget = order.budgets.find(
          (budget) => budget.id === order.payload.payment?.budget
        )

        const method = orderBudget.payment_methods.find(
          (method) => method.id === order.payload.payment.method
        )

        const installment = method.installments.find(
          (installment) => installment.id === order.payload.payment.installment
        )

        const variant = orderBudget.coverages.map((coverage) =>
          dashText(coverage.description)
        )

        setDataLayer({
          event: 'transacao',
          retorno: 'sucesso',
          descricao: 'pagamento essencial aprovado com sucesso',
          ecommerce: {
            purchase: {
              actionField: {
                option: dashText(method.description),
                id: order.policy.id,
                revenue: installment.total,
                products: [
                  {
                    id: order.id,
                    name: `Seguro fianca Essencial | ${orderBudget.description}`,
                    brand: 'porto-seguro',
                    price: installment.net,
                    valor_parcela: installment.price,
                    valor_aluguel: order.payload.coverages.rent,
                    variant,
                  },
                ],
              },
            },
          },
        })

        history.push(`/contratos/${contract.id}`)
      } catch (error) {
        setDataLayer({
          event: 'transacao',
          retorno: 'erro',
          descricao: 'pagamento essencial não aprovado',
          ecommerce: {
            purchase: {
              actionField: {
                products: [
                  {
                    id: contract?.id,
                    name: 'Seguro fianca Essencial',
                    brand: 'porto-seguro',
                    valor_aluguel: contract?.payload?.coverages?.rent,
                  },
                ],
              },
            },
          },
          erro: {
            codigo: error?.response?.status,
            servico: 'Pagamento Seguro fianca Essencial',
            mensagem: error?.response?.data?.message,
          },
        })
      }
    },
  })

  useQuery(
    ['elegibility', 'verifyElegibility', contract.id],
    () =>
      api.elegibility.verifyElegibilityFromContract({
        contractId: contract.id,
      }),
    {
      enabled: !!contract.id,
      refetchOnWindowFocus: false,
    }
  )

  const isCreditCardMethod = ['CC', 'CP'].includes(form.values.method)

  const installments = useMemo(() => {
    const selectedMethod = methodEnabled?.find(
      (payment) => payment.id === form.values.method
    )
    return selectedMethod?.installments || []
  }, [form.values.method, methodEnabled])

  useEffect(() => {
    const method = form.values.method

    form.resetForm({
      values: {
        method,
        installment: '',
        card: undefined,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.method])

  function toggleShowPaymentsDetails() {
    setShowPaymnents((value) => !value)
  }

  return (
    <>
      <Card>
        <Grid gap="16px">
          <Typography as="span" variant="porto-title-4-semibold">
            Pagamento
          </Typography>

          <Conditional when={contract._status === 'pending'}>
            <Content.Loader message="Aguarde, estamos atualizando as formas de pagamento." />
          </Conditional>

          <Conditional
            when={['syncing', 'ordering'].includes(contract._status)}
          >
            <Content.Loader message="Aguarde, transmitindo a proposta." />
          </Conditional>

          <Conditional
            when={
              contract.external_status === 'DONE' &&
              ['fulfilled'].includes(contract._status)
            }
          >
            <FormikProvider value={form}>
              <form onSubmit={form.handleSubmit}>
                <Grid gap="16px">
                  <Typography as="span" variant="porto-title-6-medium">
                    Forma de pagamento
                  </Typography>
                  <Typography
                    as="strong"
                    variant="porto-text-caption-bold"
                    color="system-text-link"
                    onClick={toggleShowPaymentsDetails}
                    sx={{
                      width: 'fit-content',
                      cursor: 'pointer',
                    }}
                  >
                    Ver opções de pagamento
                  </Typography>

                  <Grid>
                    {methodEnabled?.map((payment) => (
                      <RadioButton
                        key={payment.id}
                        title={payment.description}
                        value={payment.id}
                        checked={form.values.method === payment.id}
                        onClick={() => form.setFieldValue('method', payment.id)}
                      />
                    ))}
                  </Grid>

                  <Conditional when={!!form.values.method}>
                    <Grid gap="1rem" columns={['auto', '1fr 1fr 1fr']}>
                      <Conditional when={isCreditCardMethod}>
                        <Forms.InputGroup
                          name="card.number"
                          label="Número do cartão"
                          placeholder="Digite o número do cartão"
                          mask="creditCard"
                          data-gtm-form="input"
                          data-gtm-name="numero-do-cartao"
                        />

                        <Forms.InputGroup
                          name="card.expiration_date"
                          label="Validade"
                          placeholder="00/0000"
                          mask="shortDate"
                          data-gtm-form="input"
                          data-gtm-name="validade-do-cartao"
                        />
                      </Conditional>
                      <Forms.SelectGroup
                        name="installment"
                        label="Nº de parcelas"
                        options={[
                          { label: 'Selecione...', value: '' },
                          ...installments.map((installment) => ({
                            label: `${capitalize(
                              installment.description
                            )} - ${formatMoneyBRL(installment.price)}`,
                            value: installment.id,
                          })),
                        ]}
                      />
                    </Grid>
                  </Conditional>

                  <S.Actions>
                    <Button
                      type="submit"
                      onClick={() => form.handleSubmit()}
                      isDisabled={form.isSubmitting}
                      isLoading={form.isSubmitting}
                      data-gtm-type="click"
                      data-gtm-clicktype="button"
                      data-gtm-name="transmitir-proposta"
                    >
                      Transmitir proposta
                    </Button>
                  </S.Actions>
                </Grid>
              </form>
            </FormikProvider>
          </Conditional>
        </Grid>
      </Card>
      {showPayments && (
        <ModalPaymentsDetails
          onDismiss={toggleShowPaymentsDetails}
          paymentMethods={methodEnabled}
        />
      )}
    </>
  )
}

export default Payments
