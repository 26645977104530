import styled, { css } from 'styled-components'
import { rem, rgba } from 'polished'

import { Actions as ActionsComponent } from '@/components'
import { themes } from '@/styles'

export const Card = {
  Item: styled.div<{ error?: boolean }>`
    padding: 16px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    border-radius: 8px;
    border: 1px solid ${rgba(themes.palletes.primary, 0.3)};
    background-color: ${rgba(themes.palletes.primary, 0.02)};

    ${(props) =>
      props.error &&
      css`
        border-radius: 8px 8px 0 0;
        border-color: ${rgba(themes.palletes.danger, 0.5)};
        background-color: ${rgba(themes.palletes.danger, 0.02)};

        &:focus {
          border-color: ${themes.palletes.danger};
          background-color: ${rgba(themes.palletes.danger, 0.03)};
          box-shadow: 0 0 0 3px ${(props) => rgba(themes.palletes.danger, 0.1)};
        }
      `}
  `,

  Detail: styled.div``,

  Title: styled.h5`
    font-weight: 500;
  `,

  Description: styled.p`
    margin-top: 4px;

    font-size: ${rem('12px')};
    color: ${themes.text.high};
  `,

  Actions: styled.div`
    margin-top: 16px;

    display: flex;
  `,

  Action: styled(ActionsComponent.Button).attrs(() => ({
    size: 'small',
    type: 'button',
  }))`
    flex: 1;

    &:not(:last-of-type) {
      margin-right: 6px;
    }
  `,
}
