export default [
  {
    id: '1',
    description: 'ABATEDOR',
  },
  {
    id: '2',
    description: 'AÇOUGUEIRO',
  },
  {
    id: '3',
    description: 'ACROBATA',
  },
  {
    id: '4',
    description: 'ACUPUNTURISTA',
  },
  {
    id: '5',
    description: 'ADESTRADOR DE ANIMAIS',
  },
  {
    id: '6',
    description: 'ADMINISTRADOR DE EMPRESAS',
  },
  {
    id: '7',
    description: 'ADVOGADO',
  },
  {
    id: '8',
    description: 'AGENCIADOR DE PROPAGANDA',
  },
  {
    id: '9',
    description: 'AGENTE DE INTELIGÊNCIA',
  },
  {
    id: '11',
    description: 'AGENTE DE SAÚDE',
  },
  {
    id: '13',
    description: 'AGENTE DE VIAGEM',
  },
  {
    id: '14',
    description: 'AGENTE FISCAL',
  },
  {
    id: '15',
    description: 'AGENTE FUNERÁRIO',
  },
  {
    id: '16',
    description: 'AJUDANTE DE MOTORISTA',
  },
  {
    id: '17',
    description: 'AJUSTADOR FERRAMENTEIRO',
  },
  {
    id: '18',
    description: 'AJUSTADOR MECÂNICO',
  },
  {
    id: '19',
    description: 'AJUSTADOR NAVAL',
  },
  {
    id: '20',
    description: 'ALFAIATE',
  },
  {
    id: '21',
    description: 'ALMOXARIFE',
  },
  {
    id: '22',
    description: 'ANALISTA ADMINISTRATIVO',
  },
  {
    id: '23',
    description: 'ANALISTA DE RECURSOS HUMANOS',
  },
  {
    id: '24',
    description: 'ANALISTA, ASSISTENTE OU TÉCNICO DA ÁREA FINANCEIRA',
  },
  {
    id: '25',
    description: 'ÂNCORA DE RÁDIO E TELEVISÃO',
  },
  {
    id: '26',
    description: 'ANTROPÓLOGO',
  },
  {
    id: '27',
    description: 'APICULTOR',
  },
  {
    id: '28',
    description: 'APONTADOR DE MÃO-DE-OBRA',
  },
  {
    id: '29',
    description: 'APONTADOR DE PRODUÇÃO',
  },
  {
    id: '30',
    description: 'APOSENTADO (POR INVALIDEZ - ACIDENTE)',
  },
  {
    id: '31',
    description: 'APOSENTADO (POR INVALIDEZ - DOENÇA)',
  },
  {
    id: '32',
    description: 'APOSENTADO (POR TEMPO DE SERVIÇO)',
  },
  {
    id: '33',
    description: 'APRESENTADOR',
  },
  {
    id: '34',
    description: 'ÁRBITRO ESPORTIVO',
  },
  {
    id: '35',
    description: 'ARMAZENISTA',
  },
  {
    id: '36',
    description: 'AROMISTA',
  },
  {
    id: '37',
    description: 'ARQUEÓLOGO',
  },
  {
    id: '38',
    description: 'ARQUITETO',
  },
  {
    id: '39',
    description: 'ARQUIVISTA',
  },
  {
    id: '40',
    description: 'ARTESÃO',
  },
  {
    id: '41',
    description: 'ASCENSORISTA',
  },
  {
    id: '42',
    description: 'ASSESSOR DE IMPRENSA',
  },
  {
    id: '43',
    description: 'ASSESSOR OU ASSISTENTE PARLAMENTAR',
  },
  {
    id: '44',
    description: 'ASSISTENTE ADMINISTRATIVO',
  },
  {
    id: '45',
    description: 'ASSISTENTE COMERCIAL',
  },
  {
    id: '46',
    description: 'ASSISTENTE DE SEGUROS',
  },
  {
    id: '47',
    description: 'ASSISTENTE SOCIAL',
  },
  {
    id: '48',
    description: 'ASTRÓLOGO',
  },
  {
    id: '49',
    description: 'ASTRÔNOMO',
  },
  {
    id: '50',
    description: 'ATENDENTE DE JUDICIÁRIO',
  },
  {
    id: '51',
    description: 'ATENDENTE OU BALCONISTA',
  },
  {
    id: '52',
    description: 'ATLETA PROFISSIONAL',
  },
  {
    id: '53',
    description: 'ATOR',
  },
  {
    id: '54',
    description: 'ATUÁRIO',
  },
  {
    id: '55',
    description: 'AUDITOR',
  },
  {
    id: '56',
    description: 'AUDITOR FISCAL PÚBLICO',
  },
  {
    id: '57',
    description: 'AUTOR OU ROTEIRISTA',
  },
  {
    id: '58',
    description: 'AUXILIAR ADMINISTRATIVO',
  },
  {
    id: '59',
    description: 'AUXILIAR DE MANUTENÇÃO PREDIAL',
  },
  {
    id: '60',
    description: 'AUXILIAR DE SERVIÇOS DE BIBLIOTECA, DOCUMENTAÇÃO OU CORREIOS',
  },
  {
    id: '61',
    description: 'AUXILIAR DE VETERINÁRIO',
  },
  {
    id: '62',
    description: 'AUXILIAR DO JUDICIÁRIO',
  },
  {
    id: '64',
    description: 'AUXILIAR OU TÉCNICO DA EXTRAÇÃO FLORESTAL',
  },
  {
    id: '65',
    description: 'AUXILIAR OU TÉCNICO DA INDÚSTRIA DE PAPEL OU PAPELÃO',
  },
  {
    id: '66',
    description: 'AUXILIAR OU TÉCNICO DA INDÚSTRIA METALÚRGICA',
  },
  {
    id: '67',
    description: 'AUXILIAR OU TÉCNICO DE ENFERMAGEM',
  },
  {
    id: '68',
    description: 'AUXILIAR OU TÉCNICO NA CONSTRUÇÃO CIVIL',
  },
  {
    id: '69',
    description: 'AUXILIAR OU TÉCNICO NA EXTRAÇÃO MINERAL',
  },
  {
    id: '70',
    description: 'AUXILIAR OU TÉCNICO NA INDÚSTRIA DE ALIMENTOS OU BEBIDAS',
  },
  {
    id: '72',
    description: 'AUXILIAR OU TÉCNICO NOS SERVIÇOS DE SAÚDE',
  },
  {
    id: '73',
    description: 'AVALIADOR DE BENS',
  },
  {
    id: '74',
    description: 'AVICULTOR',
  },
  {
    id: '75',
    description: 'BABÁ',
  },
  {
    id: '76',
    description: 'BAILARINO',
  },
  {
    id: '77',
    description: 'BALANCEIRO',
  },
  {
    id: '78',
    description: 'BARBEIRO',
  },
  {
    id: '79',
    description: 'BARISTA',
  },
  {
    id: '80',
    description: 'BARMAN',
  },
  {
    id: '81',
    description: 'BIBLIOTECÁRIO',
  },
  {
    id: '82',
    description: 'BIÓLOGO',
  },
  {
    id: '83',
    description: 'BIOMÉDICO',
  },
  {
    id: '84',
    description: 'BOIADEIRO',
  },
  {
    id: '86',
    description: 'BORRACHEIRO',
  },
  {
    id: '87',
    description: 'CABELEIREIRO',
  },
  {
    id: '88',
    description: 'CAIXA DE BANCO',
  },
  {
    id: '89',
    description: 'CAIXA, BILHETEIROS E AFINS',
  },
  {
    id: '90',
    description: 'CALDEIREIRO',
  },
  {
    id: '91',
    description: 'CAMAREIRO',
  },
  {
    id: '92',
    description: 'CARPINTEIRO',
  },
  {
    id: '93',
    description: 'CARREGADOR',
  },
  {
    id: '94',
    description: 'CARTEIRO',
  },
  {
    id: '95',
    description: 'CASEIRO',
  },
  {
    id: '96',
    description: 'CENÓGRAFO',
  },
  {
    id: '97',
    description: 'CERAMISTA',
  },
  {
    id: '98',
    description: 'CERIMONIALISTA',
  },
  {
    id: '99',
    description: 'CHAPEADOR',
  },
  {
    id: '100',
    description: 'CHAPELEIRO',
  },
  {
    id: '101',
    description: 'CHAVEIRO',
  },
  {
    id: '102',
    description: 'CHEFE DE BAR',
  },
  {
    id: '103',
    description: 'CHEFE DE CONFEITARIA',
  },
  {
    id: '104',
    description: 'CHEFE DE COZINHA',
  },
  {
    id: '105',
    description: 'CHURRASQUEIRO',
  },
  {
    id: '106',
    description: 'CIENTISTA POLÍTICO',
  },
  {
    id: '107',
    description: 'COBRADOR DE TRANSPORTES COLETIVOS',
  },
  {
    id: '108',
    description: 'COMENTARISTA DE RÁDIO E TELEVISÃO',
  },
  {
    id: '109',
    description: 'COMERCIANTE',
  },
  {
    id: '110',
    description: 'COMISSÁRIO OU AEROMOÇA',
  },
  {
    id: '111',
    description: 'MÉDICO',
  },
  {
    id: '112',
    description: 'VETERINÁRIO',
  },
  {
    id: '113',
    description: 'COMUNICÓLOGO',
  },
  {
    id: '114',
    description: 'CONDUTOR DE MÁQUINAS',
  },
  {
    id: '115',
    description: 'CONFECCIONADOR DE PRODUTOS DE PAPEL OU PAPELÃO',
  },
  {
    id: '116',
    description: 'CONFEITEIRO',
  },
  {
    id: '117',
    description: 'CONFERENTE DE CARGA E DESCARGA',
  },
  {
    id: '118',
    description: 'CONSELHEIRO TUTELAR',
  },
  {
    id: '119',
    description: 'CONSULTOR JURÍDICO',
  },
  {
    id: '120',
    description: 'CONTADOR, TÉCNICO OU ANALISTA EM CONTABILIDADE',
  },
  {
    id: '121',
    description: 'CONTÍNUO',
  },
  {
    id: '122',
    description: 'CONTORCIONISTA',
  },
  {
    id: '123',
    description: 'CONTROLADOR DE TRÁFEGO AÉREO',
  },
  {
    id: '124',
    description: 'COORDENADOR PEDAGÓGICO',
  },
  {
    id: '125',
    description: 'COPEIRO',
  },
  {
    id: '126',
    description: 'COREÓGRAFO',
  },
  {
    id: '127',
    description: 'CORRETOR DE IMÓVEIS',
  },
  {
    id: '128',
    description: 'CORRETOR DE SEGUROS',
  },
  {
    id: '129',
    description: 'COSTUREIRA',
  },
  {
    id: '130',
    description: 'COVEIRO OU SEPULTADOR',
  },
  {
    id: '131',
    description: 'COZINHEIRO OU AUXILIAR DE COZINHA',
  },
  {
    id: '132',
    description: 'CUIDADOR',
  },
  {
    id: '133',
    description: 'CUMIM',
  },
  {
    id: '134',
    description: 'DANÇARINO',
  },
  {
    id: '135',
    description: 'DATILÓGRAFO',
  },
  {
    id: '136',
    description: 'DECORADOR',
  },
  {
    id: '137',
    description: 'DEFENSOR PÚBLICO',
  },
  {
    id: '138',
    description: 'DEGUSTADOR',
  },
  {
    id: '140',
    description: 'DEMOLIDOR DE EDIFICAÇÕES',
  },
  {
    id: '142',
    description: 'DEPUTADO',
  },
  {
    id: '143',
    description: 'DESENHISTA',
  },
  {
    id: '144',
    description: 'DESENHISTA INDUSTRIAL',
  },
  {
    id: '145',
    description: 'DESENHISTA PROJETISTA',
  },
  {
    id: '146',
    description: 'DESENHISTA TÉCNICO',
  },
  {
    id: '147',
    description: 'DESIGNER',
  },
  {
    id: '148',
    description: 'DESPACHANTE',
  },
  {
    id: '149',
    description: 'DETETIVE PROFISSIONAL',
  },
  {
    id: '150',
    description: 'DIGITADOR',
  },
  {
    id: '151',
    description: 'DIRETOR DE CINEMA',
  },
  {
    id: '152',
    description: 'DIRETOR DE EMPRESA',
  },
  {
    id: '153',
    description: 'DIRETOR DE FOTOGRAFIA',
  },
  {
    id: '154',
    description: 'DIRETOR DE INSTITUIÇÃO EDUCACIONAL',
  },
  {
    id: '155',
    description: 'DIRETOR DE RÁDIO',
  },
  {
    id: '156',
    description: 'DIRETOR DE TELEVISÃO',
  },
  {
    id: '157',
    description: 'DIRETOR TEATRAL',
  },
  {
    id: '158',
    description: 'DIRIGENTE DE ÓRGÃO PÚBLICO',
  },
  {
    id: '159',
    description: 'DIRIGENTE DE PARTIDO POLÍTICO, SINDICATO OU COOPERATIVA',
  },
  {
    id: '160',
    description: 'DJ (DISC JOCKEY)',
  },
  {
    id: '161',
    description: 'DO LAR',
  },
  {
    id: '162',
    description: 'DOMADOR DE ANIMAIS',
  },
  {
    id: '163',
    description: 'DOULA',
  },
  {
    id: '164',
    description: 'ECONOMISTA',
  },
  {
    id: '165',
    description: 'EDITOR',
  },
  {
    id: '166',
    description: 'ELETRICISTA DE INSTALAÇÕES E MANUTENÇÃO',
  },
  {
    id: '167',
    description: 'EMBAIXADOR, DIPLOMATA OU CONSUL',
  },
  {
    id: '168',
    description: 'EMBALADOR OU ALIMENTADOR DE PRODUÇÃO',
  },
  {
    id: '169',
    description: 'EMBALSAMADOR',
  },
  {
    id: '170',
    description: 'EMPREGADO DOMÉSTICO',
  },
  {
    id: '171',
    description: 'EMPRESÁRIO',
  },
  {
    id: '172',
    description: 'ENCANADOR',
  },
  {
    id: '173',
    description: 'ENFERMEIRO',
  },
  {
    id: '206',
    description: 'ENGRAXATE',
  },
  {
    id: '207',
    description: 'ENÓLOGO',
  },
  {
    id: '208',
    description: 'ENTREVISTADOR',
  },
  {
    id: '209',
    description: 'EQUILIBRISTA',
  },
  {
    id: '210',
    description: 'ESCREVENTE',
  },
  {
    id: '211',
    description: 'ESCRITOR',
  },
  {
    id: '213',
    description: 'ESPECIALISTA EM METROLOGIA',
  },
  {
    id: '214',
    description: 'ESTAGIÁRIO, BOLSISTA OU TRAINEE',
  },
  {
    id: '215',
    description: 'ESTATÍSTICO',
  },
  {
    id: '216',
    description: 'ESTETICISTA',
  },
  {
    id: '217',
    description: 'ESTIVADOR',
  },
  {
    id: '218',
    description: 'ESTOFADOR',
  },
  {
    id: '219',
    description: 'ESTUDANTE',
  },
  {
    id: '220',
    description: 'FARMACÊUTICO',
  },
  {
    id: '221',
    description: 'FAXINEIRO',
  },
  {
    id: '222',
    description: 'FEIRANTE',
  },
  {
    id: '223',
    description: 'FILÓLOGO',
  },
  {
    id: '224',
    description: 'FILÓSOFO',
  },
  {
    id: '225',
    description: 'FISCAL DE TRANSPORTES COLETIVOS',
  },
  {
    id: '226',
    description: 'FÍSICO',
  },
  {
    id: '227',
    description: 'FISIOTERAPEUTA',
  },
  {
    id: '228',
    description: 'FONOAUDIÓLOGO',
  },
  {
    id: '229',
    description: 'FORJADOR',
  },
  {
    id: '230',
    description: 'FORNEIRO',
  },
  {
    id: '231',
    description: 'FOTÓGRAFO',
  },
  {
    id: '232',
    description: 'FRENTISTA',
  },
  {
    id: '233',
    description: 'FUNCIONÁRIO OU SERVIDOR DE ÓRGÃO PÚBLICO',
  },
  {
    id: '234',
    description: 'FUNDIDOR',
  },
  {
    id: '235',
    description: 'FUNILEIRO',
  },
  {
    id: '236',
    description: 'GANDULA',
  },
  {
    id: '237',
    description: 'GARAGISTA',
  },
  {
    id: '238',
    description: 'GARÇOM',
  },
  {
    id: '239',
    description: 'GARIMPEIRO',
  },
  {
    id: '240',
    description: 'GEOFÍSICO',
  },
  {
    id: '241',
    description: 'GEÓGRAFO',
  },
  {
    id: '242',
    description: 'GEÓLOGO',
  },
  {
    id: '243',
    description: 'GEOQUÍMICO',
  },
  {
    id: '244',
    description: 'GERENTE ADMINISTRATIVO',
  },
  {
    id: '245',
    description: 'GERENTE COMERCIAL OU DE VENDAS',
  },
  {
    id: '246',
    description: 'GERENTE DE PRODUÇÃO OU OPERAÇÕES',
  },
  {
    id: '247',
    description: 'GESSEIRO',
  },
  {
    id: '248',
    description: 'GOVERNADOR',
  },
  {
    id: '249',
    description: 'GRAVADOR',
  },
  {
    id: '250',
    description: 'GUARDA-CIVIL MUNICIPAL',
  },
  {
    id: '251',
    description: 'GUIA DE TURISMO',
  },
  {
    id: '252',
    description: 'GUIA FLORESTAL',
  },
  {
    id: '253',
    description: 'GUINCHEIRO',
  },
  {
    id: '254',
    description: 'HIDROGEÓLOGO',
  },
  {
    id: '255',
    description: 'HIGIENISTA OCUPACIONAL',
  },
  {
    id: '256',
    description: 'INSPETOR DE ALUNOS',
  },
  {
    id: '257',
    description: 'INSPETOR DE SOLDAGEM',
  },
  {
    id: '258',
    description: 'INSPETOR DE TERMINAL',
  },
  {
    id: '259',
    description: 'INSPETOR NAVAL',
  },
  {
    id: '260',
    description: 'INSTALADOR DE PRODUTOS OU ACESSÓRIOS',
  },
  {
    id: '261',
    description: 'INSTALADOR DE TUBULAÇÕES',
  },
  {
    id: '262',
    description:
      'INSTALADOR OU REPARADOR DE LINHAS E CABOS ELÉTRICOS E DE COMUNICAÇÕES',
  },
  {
    id: '263',
    description: 'INSTRUMENTADOR CIRÚRGICO',
  },
  {
    id: '264',
    description: 'INSTRUTOR DE AUTOESCOLA',
  },
  {
    id: '265',
    description: 'INSTRUTOR DE CURSOS LIVRES',
  },
  {
    id: '266',
    description: 'INSTRUTOR DE VOO',
  },
  {
    id: '267',
    description: 'INTÉRPRETE',
  },
  {
    id: '269',
    description: 'JARDINEIRO',
  },
  {
    id: '270',
    description: 'JOALHEIRO',
  },
  {
    id: '271',
    description: 'JORNALEIRO',
  },
  {
    id: '272',
    description: 'JORNALISTA',
  },
  {
    id: '273',
    description: 'JUIZ DE DIREITO',
  },
  {
    id: '274',
    description: 'LAMINADOR',
  },
  {
    id: '275',
    description: 'LAPIDADOR',
  },
  {
    id: '276',
    description: 'LAVADEIRO, EM GERAL',
  },
  {
    id: '277',
    description: 'LAVADOR DE ROUPAS',
  },
  {
    id: '278',
    description: 'LEILOEIRO',
  },
  {
    id: '279',
    description: 'LEITURISTA',
  },
  {
    id: '280',
    description: 'LIMPADOR DE FACHADAS',
  },
  {
    id: '281',
    description: 'LIMPADOR DE PISCINAS',
  },
  {
    id: '282',
    description: 'LIMPADOR DE VIDROS',
  },
  {
    id: '283',
    description: 'LIXEIRO OU CATADOR DE LIXO',
  },
  {
    id: '284',
    description: 'LOCUTOR DE RÁDIO E TELEVISÃO',
  },
  {
    id: '285',
    description: 'LUBRIFICADOR',
  },
  {
    id: '286',
    description: 'LUSTRADOR',
  },
  {
    id: '287',
    description: 'MÁGICO',
  },
  {
    id: '288',
    description: 'MAÎTRE',
  },
  {
    id: '289',
    description: 'MALABARISTA',
  },
  {
    id: '290',
    description: 'MANICURE',
  },
  {
    id: '291',
    description: 'MANOBRISTA OU GUARDADOR DE VEÍCULOS',
  },
  {
    id: '292',
    description: 'MANTENEDOR ELETROMECÂNICO',
  },
  {
    id: '293',
    description: 'MAQUETISTA',
  },
  {
    id: '294',
    description: 'MAQUIADOR',
  },
  {
    id: '295',
    description: 'MAQUINISTA OU FOGUISTA',
  },
  {
    id: '296',
    description: 'MARCENEIRO OU TRABALHADOR DA INDÚSTRIA DE MADEIRA',
  },
  {
    id: '298',
    description: 'MATEMÁTICO',
  },
  {
    id: '299',
    description: 'MECÂNICO DE AERONAVES',
  },
  {
    id: '300',
    description: 'MECÂNICO DE EQUIPAMENTOS NAVAIS',
  },
  {
    id: '301',
    description:
      'MECÂNICO DE MANUTENÇÃO DE MÁQUINAS OU EQUIPAMENTOS INDUSTRIAIS, COMERCIAIS OU RESIDENCIAIS',
  },
  {
    id: '302',
    description: 'MECÂNICO DE VEÍCULOS',
  },
  {
    id: '304',
    description:
      'MEMBRO SUPERIOR DO PODER LEGISLATIVO, EXECUTIVO OU JUDICIÁRIO',
  },
  {
    id: '305',
    description: 'MERGULHADOR',
  },
  {
    id: '306',
    description: 'METEOROLOGISTA',
  },
  {
    id: '308',
    description: 'MINISTRO DE CULTO RELIGIOSO',
  },
  {
    id: '309',
    description: 'MINISTRO DE ESTADO',
  },
  {
    id: '310',
    description: 'MINISTRO DE TRIBUNAL SUPERIOR',
  },
  {
    id: '311',
    description: 'MISSIONÁRIO',
  },
  {
    id: '312',
    description: 'MODELO',
  },
  {
    id: '313',
    description: 'MOLDADOR',
  },
  {
    id: '314',
    description: 'MONITOR DE TELEATENDIMENTO',
  },
  {
    id: '315',
    description: 'MONITOR DE TRANSPORTE ESCOLAR',
  },
  {
    id: '316',
    description: 'MONITOR OU INSTRUTOR DE MENORES (FUNDAÇÃO CASA E SIMILARES)',
  },
  {
    id: '317',
    description: 'MONTADOR DE MÁQUINAS',
  },
  {
    id: '318',
    description: 'MONTADOR OU AJUSTADOR DE INSTRUMENTOS DE PRECISÃO',
  },
  {
    id: '319',
    description: 'MONTADOR OU AJUSTADOR DE INSTRUMENTOS MUSICAIS',
  },
  {
    id: '320',
    description:
      'MONTADOR OU INSTALADOR DE EQUIPAMENTOS ELETROELETRÔNICOS EM GERAL',
  },
  {
    id: '321',
    description: 'MORDOMO OU GOVERNANTA',
  },
  {
    id: '322',
    description: 'MOTOBOY',
  },
  {
    id: '323',
    description: 'MOTORISTA DE CAMINHÃO',
  },
  {
    id: '324',
    description: 'MOTORISTA DE CARRO, FURGÃO OU VEÍCULO SIMILAR',
  },
  {
    id: '325',
    description: 'MOTORISTA DE GUINCHO',
  },
  {
    id: '328',
    description: 'MOTOTAXISTA',
  },
  {
    id: '329',
    description: 'MUSEÓLOGO',
  },
  {
    id: '330',
    description: 'MÚSICO',
  },
  {
    id: '331',
    description: 'NEUROPSICÓLOGO',
  },
  {
    id: '332',
    description: 'NUMERÓLOGO',
  },
  {
    id: '333',
    description: 'NUTRICIONISTA',
  },
  {
    id: '334',
    description: 'OCEANÓGRAFO',
  },
  {
    id: '336',
    description: 'OFICIAL DE REGISTRO',
  },
  {
    id: '337',
    description: 'OFICIAL DO EXÉRCITO, MARINHA OU AERONÁUTICA',
  },
  {
    id: '338',
    description: 'OLEIRO',
  },
  {
    id: '339',
    description: 'OPERADOR DE EQUIPAMENTOS DE ELEVAÇÃO',
  },
  {
    id: '340',
    description: 'OPERADOR DE FORNO (SERVIÇOS FUNERÁRIOS)',
  },
  {
    id: '341',
    description: 'OPERADOR DE MÁQUINAS AGRÍCOLAS',
  },
  {
    id: '342',
    description: 'OPERADOR DE ROBÔS E EQUIPAMENTOS ESPECIAIS',
  },
  {
    id: '343',
    description: 'OPERADOR DE TELEMARKETING',
  },
  {
    id: '344',
    description: 'OPERADOR EM INDÚSTRIAS QUÍMICAS OU PETROQUÍMICAS',
  },
  {
    id: '345',
    description:
      'OPERADOR NA GERAÇÃO E DISTRIBUIÇÃO DE ENERGIA (HIDRELÉTRICAS, TERMELÉTRICAS OU NUCLEARES)',
  },
  {
    id: '346',
    description: 'ORGANIZADOR DE EVENTO',
  },
  {
    id: '347',
    description: 'ORIENTADOR EDUCACIONAL',
  },
  {
    id: '348',
    description: 'OUVIDOR',
  },
  {
    id: '349',
    description: 'PADEIRO',
  },
  {
    id: '350',
    description: 'PALEONTÓLOGO',
  },
  {
    id: '351',
    description: 'PALHAÇO',
  },
  {
    id: '352',
    description: 'PARAMÉDICO',
  },
  {
    id: '353',
    description: 'PASSADEIRA',
  },
  {
    id: '354',
    description: 'PEÃO DE RODEIO',
  },
  {
    id: '355',
    description: 'PEDAGOGO',
  },
  {
    id: '356',
    description: 'PEDICURE',
  },
  {
    id: '357',
    description: 'PEDREIRO',
  },
  {
    id: '358',
    description: 'PENSIONISTA',
  },
  {
    id: '359',
    description: 'PERFUMISTA',
  },
  {
    id: '361',
    description: 'PERITO OU VISTORIADOR EM SEGUROS',
  },
  {
    id: '362',
    description: 'PESCADOR OU CAÇADOR',
  },
  {
    id: '363',
    description: 'PESQUISADOR',
  },
  {
    id: '364',
    description: 'PILOTO DE AVIÃO',
  },
  {
    id: '365',
    description: 'PILOTO DE COMPETIÇÃO AUTOMOBILÍSTICA',
  },
  {
    id: '366',
    description: 'PILOTO DE HELICÓPTERO',
  },
  {
    id: '368',
    description: 'PIPOQUEIRO AMBULANTE',
  },
  {
    id: '369',
    description: 'PIROTÉCNICO',
  },
  {
    id: '370',
    description: 'PIZZAIOLO',
  },
  {
    id: '371',
    description: 'PLATAFORMISTA (PETRÓLEO)',
  },
  {
    id: '372',
    description: 'PODÓLOGO',
  },
  {
    id: '373',
    description: 'POETA',
  },
  {
    id: '377',
    description: 'PORTEIRO',
  },
  {
    id: '378',
    description: 'PRÁTICO',
  },
  {
    id: '379',
    description: 'PREFEITO',
  },
  {
    id: '380',
    description: 'PRESIDENTE DA REPÚBLICA',
  },
  {
    id: '381',
    description: 'PRESIDENTE OU VICE PRESIDENTE ADMINISTRATIVO',
  },
  {
    id: '382',
    description: 'PROCURADOR',
  },
  {
    id: '383',
    description: 'PRODUTOR AGRÍCOLA',
  },
  {
    id: '384',
    description: 'PRODUTOR CULTURAL',
  },
  {
    id: '385',
    description: 'PRODUTOR DE TEXTO',
  },
  {
    id: '386',
    description: 'PRODUTOR EM PECUÁRIA',
  },
  {
    id: '387',
    description: 'PROFESSOR',
  },
  {
    id: '388',
    description: 'PROFESSOR EDUCAÇÃO FÍSICA',
  },
  {
    id: '390',
    description: 'PROGRAMADOR',
  },
  {
    id: '391',
    description: 'PROMOTOR DE JUSTIÇA',
  },
  {
    id: '392',
    description: 'PROMOTOR DE VENDAS',
  },
  {
    id: '393',
    description: 'PROTÉTICO DENTÁRIO',
  },
  {
    id: '394',
    description: 'PSICANALISTA',
  },
  {
    id: '395',
    description: 'PSICÓLOGO',
  },
  {
    id: '396',
    description: 'PSICOPEDAGOGO',
  },
  {
    id: '397',
    description: 'PUBLICITÁRIO',
  },
  {
    id: '398',
    description: 'QUÍMICO',
  },
  {
    id: '399',
    description: 'REBITADOR',
  },
  {
    id: '400',
    description: 'RECEPCIONISTA',
  },
  {
    id: '401',
    description: 'RECREADOR',
  },
  {
    id: '402',
    description: 'REDATOR',
  },
  {
    id: '403',
    description: 'RELAÇÕES PÚBLICAS',
  },
  {
    id: '404',
    description: 'RELOJOEIRO',
  },
  {
    id: '405',
    description: 'REPARADOR DE INSTRUMENTOS OU EQUIPAMENTOS DE PRECISÃO',
  },
  {
    id: '406',
    description: 'REPÓRTER',
  },
  {
    id: '407',
    description: 'REPOSITOR DE MERCADORIAS',
  },
  {
    id: '408',
    description: 'REPRESENTANTE COMERCIAL',
  },
  {
    id: '409',
    description: 'RESTAURADOR DE INSTRUMENTOS MUSICAIS',
  },
  {
    id: '410',
    description: 'RESTAURADOR DE LIVROS',
  },
  {
    id: '411',
    description: 'REVISOR DE TEXTO',
  },
  {
    id: '412',
    description: 'SACRISTÃO',
  },
  {
    id: '413',
    description: 'SALVA-VIDAS',
  },
  {
    id: '414',
    description: 'SAPATEIRO',
  },
  {
    id: '415',
    description: 'SECRETÁRIA',
  },
  {
    id: '417',
    description: 'SECURITÁRIO',
  },
  {
    id: '418',
    description: 'SENADOR',
  },
  {
    id: '419',
    description: 'SERRALHEIRO',
  },
  {
    id: '420',
    description: 'SINDICANTE OU INVESTIGADOR EM SEGUROS',
  },
  {
    id: '421',
    description: 'SOCIÓLOGO',
  },
  {
    id: '422',
    description: 'SOCORRISTA',
  },
  {
    id: '423',
    description: 'SOLDADOR',
  },
  {
    id: '424',
    description: 'SUBPROCURADOR',
  },
  {
    id: '425',
    description: 'SUPERVISOR DA INDÚSTRIA TÊXTIL',
  },
  {
    id: '426',
    description: 'SUPERVISOR DE MANUTENÇÃO PREDIAL',
  },
  {
    id: '427',
    description: 'SUPERVISOR OU COORDENADOR ADMINISTRATIVO',
  },
  {
    id: '428',
    description:
      'SUPERVISOR OU COORDENADOR DA EXTRAÇÃO MINERAL OU DA CONSTRUÇÃO CIVIL',
  },
  {
    id: '429',
    description: 'SUPERVISOR OU COORDENADOR DA INDÚSTRIA DE BEBIDAS',
  },
  {
    id: '430',
    description: 'SUPERVISOR OU COORDENADOR DA INDÚSTRIA DE FUMO',
  },
  {
    id: '431',
    description: 'SUPERVISOR OU COORDENADOR DA MECÂNICA DE PRECISÃO',
  },
  {
    id: '432',
    description: 'SUPERVISOR OU COORDENADOR DE ATENDIMENTO AO PÚBLICO',
  },
  {
    id: '433',
    description: 'SUPERVISOR OU COORDENADOR DE CARGA E DESCARGA',
  },
  {
    id: '434',
    description: 'SUPERVISOR OU COORDENADOR DE DIGITAÇÃO E OPERAÇÃO',
  },
  {
    id: '435',
    description: 'SUPERVISOR OU COORDENADOR DE EMBALAGEM E ETIQUETAGEM',
  },
  {
    id: '436',
    description: 'SUPERVISOR OU COORDENADOR DE ENSINO',
  },
  {
    id: '437',
    description: 'SUPERVISOR OU COORDENADOR DE EXPLORAÇÃO AGROPECUÁRIA',
  },
  {
    id: '438',
    description:
      'SUPERVISOR OU COORDENADOR DE FABRICAÇÃO DE INSTRUMENTOS MUSICAIS',
  },
  {
    id: '439',
    description: 'SUPERVISOR OU COORDENADOR DE JOALHERIA',
  },
  {
    id: '440',
    description: 'SUPERVISOR OU COORDENADOR DE MANUTENÇÃO ELETROMECÂNICA',
  },
  {
    id: '441',
    description:
      'SUPERVISOR OU COORDENADOR DE MONTAGEM E INSTALAÇÃO ELETROELETRÔNICA',
  },
  {
    id: '442',
    description:
      'SUPERVISOR OU COORDENADOR DE PRODUÇÃO DA INDÚSTRIA ALIMENTÍCIA',
  },
  {
    id: '443',
    description:
      'SUPERVISOR OU COORDENADOR DE PRODUÇÃO EM INDÚSTRIAS QUÍMICAS OU PETROQUÍMICAS',
  },
  {
    id: '444',
    description:
      'SUPERVISOR OU COORDENADOR DE PRODUÇÃO EM INDÚSTRIAS SIDERÚRGICAS',
  },
  {
    id: '445',
    description: 'SUPERVISOR OU COORDENADOR DE SERVIÇOS',
  },
  {
    id: '446',
    description: 'SUPERVISOR OU COORDENADOR DE VENDAS',
  },
  {
    id: '447',
    description: 'SUPERVISOR OU COORDENADOR NA EXPLORAÇÃO FLORESTAL OU PESCA',
  },
  {
    id: '448',
    description: 'SUPERVISOR OU COORDENADOR NA INDÚSTRIA METALÚRGICA',
  },
  {
    id: '449',
    description: 'SUSHIMAN',
  },
  {
    id: '450',
    description: 'TABELIÃO',
  },
  {
    id: '451',
    description: 'TAIFEIRO (EXCETO MILITARES)',
  },
  {
    id: '452',
    description: 'TAQUÍGRAFO',
  },
  {
    id: '454',
    description: 'TAXIDERMISTA',
  },
  {
    id: '456',
    description: 'TECELÃO',
  },
  {
    id: '457',
    description: 'TÉCNICO ADMINISTRATIVO',
  },
  {
    id: '458',
    description: 'TÉCNICO DA PRODUÇÃO AGROPECUÁRIA',
  },
  {
    id: '459',
    description: 'TÉCNICO DE BIOQUÍMICA OU DA BIOTECNOLOGIA',
  },
  {
    id: '460',
    description: 'TÉCNICO DE SONORIZAÇÃO, CENOGRAFIA OU PROJEÇÃO',
  },
  {
    id: '461',
    description: 'TÉCNICO EM BIOLOGIA',
  },
  {
    id: '462',
    description: 'TÉCNICO EM CIÊNCIAS FÍSICAS OU QUÍMICAS',
  },
  {
    id: '463',
    description: 'TÉCNICO EM ELETROELETRÔNICA',
  },
  {
    id: '464',
    description: 'TÉCNICO EM EXTRAÇÃO MINERAL OU GEOLOGIA',
  },
  {
    id: '465',
    description: 'TÉCNICO EM LABORATÓRIO',
  },
  {
    id: '466',
    description: 'TÉCNICO EM MINERALOGIA OU GEOLOGIA',
  },
  {
    id: '467',
    description: 'TÉCNICO EM NAVEGAÇÃO AÉREA, MARÍTIMA OU FLUVIAL',
  },
  {
    id: '468',
    description:
      'TÉCNICO EM OPERAÇÃO DE CÂMARA FOTOGRÁFICA , CINEMA E DE TELEVISÃO',
  },
  {
    id: '470',
    description:
      'TÉCNICO EM OPERAÇÃO DE RÁDIO, TELEVISÃO OU DE PRODUTORAS DE VÍDEO',
  },
  {
    id: '471',
    description: 'TÉCNICO EM OPERAÇÕES INDUSTRIAIS',
  },
  {
    id: '472',
    description: 'TÉCNICO EM SEGURANÇA DO TRABALHO',
  },
  {
    id: '473',
    description: 'TÉCNICO EM TRANSPORTES (LOGÍSTICA)',
  },
  {
    id: '474',
    description: 'TÉCNICO MECATRÔNICO OU ELETROMECÂNICO',
  },
  {
    id: '475',
    description: 'TÉCNICO OU ANALISTA EM INFORMÁTICA',
  },
  {
    id: '476',
    description: 'TECNÓLOGO EM ALIMENTOS',
  },
  {
    id: '477',
    description: 'TECNÓLOGO EM GASTRONOMIA',
  },
  {
    id: '478',
    description: 'TECNÓLOGO EM MEIO AMBIENTE',
  },
  {
    id: '479',
    description: 'TECNÓLOGO EM ROCHAS ORNAMENTAIS',
  },
  {
    id: '481',
    description: 'TECNÓLOGO EM TELECOMUNICAÇÕES',
  },
  {
    id: '482',
    description: 'TELEFONISTA',
  },
  {
    id: '483',
    description: 'TEÓLOGO',
  },
  {
    id: '484',
    description: 'TERAPEUTA',
  },
  {
    id: '485',
    description: 'TESOUREIRO',
  },
  {
    id: '486',
    description: 'TINTUREIRO',
  },
  {
    id: '487',
    description: 'TOPÓGRAFO',
  },
  {
    id: '488',
    description: 'TOSADOR DE ANIMAIS',
  },
  {
    id: '489',
    description: 'TRABALHADOR DA AGROPECUÁRIA',
  },
  {
    id: '490',
    description: 'TRABALHADOR DA CONFECÇÃO DE CALÇADOS',
  },
  {
    id: '491',
    description: 'TRABALHADOR DA INDÚSTRIA GRÁFICA',
  },
  {
    id: '492',
    description: 'TRABALHADOR DA INDÚSTRIA TÊXTIL OU CONFECÇÃO DE ROUPA',
  },
  {
    id: '493',
    description: 'TRABALHADOR NA INDÚSTRIA DE FUMO E CIGARROS',
  },
  {
    id: '494',
    description: 'TRADUTOR',
  },
  {
    id: '495',
    description: 'TRAPEZISTA',
  },
  {
    id: '496',
    description: 'TRATADOR DE ANIMAIS',
  },
  {
    id: '498',
    description: 'TROPEIRO',
  },
  {
    id: '499',
    description: 'URBANISTA',
  },
  {
    id: '501',
    description: 'VENDEDOR',
  },
  {
    id: '502',
    description: 'VENDEDOR AMBULANTE',
  },
  {
    id: '503',
    description: 'VEREADOR',
  },
  {
    id: '505',
    description: 'VICE-GOVERNADOR',
  },
  {
    id: '506',
    description: 'VICE-PREFEITO',
  },
  {
    id: '507',
    description: 'VICE-PRESIDENTE DA REPÚBLICA',
  },
  {
    id: '508',
    description: 'VIDRACEIRO',
  },
  {
    id: '509',
    description: 'VIDREIRO',
  },
  {
    id: '510',
    description: 'VIGILANTE OU SEGURANÇA',
  },
  {
    id: '511',
    description: 'VISTORIADOR NAVAL',
  },
  {
    id: '512',
    description: 'ZELADOR DE EDIFÍCIO',
  },
  {
    id: '513',
    description: 'ZOOTECNISTA',
  },
  {
    id: '514',
    description: 'AUXILIAR DE SUPERVISOR ESCOLAR',
  },
  {
    id: '518',
    description: 'COMPOSITOR',
  },
  {
    id: '519',
    description: 'COMPRADOR',
  },
  {
    id: '520',
    description: 'ADMINISTRADOR HOSPITALAR',
  },
  {
    id: '521',
    description: 'AGENCIADOR E PRODUTOR DE SEGUROS',
  },
  {
    id: '522',
    description: 'ANALISTA DE SISTEMAS',
  },
  {
    id: '523',
    description: 'POLICIAL FEDERAL, FERROVIÁRIO E RODOVIÁRIO ',
  },
  {
    id: '524',
    description: 'POLICIAL CIVIL - AGENTE DE SEGURANÇA PENITENCIÁRIA',
  },
  {
    id: '525',
    description: 'ARTISTA PLÁSTICO',
  },
  {
    id: '526',
    description: 'BIOQUÍMICO',
  },
  {
    id: '527',
    description:
      'BOMBEIROS: COMANDANTE,  CORONEL, TENENTE, MAJOR, CAPITÃO, SUBTENENTE',
  },
  {
    id: '528',
    description: 'CABO, MARINHEIRO E TAIFEIRO DAS FORÇAS ARMADAS',
  },
  {
    id: '529',
    description: 'CANTOR',
  },
  {
    id: '530',
    description: 'CORRETOR DE BOLSA DE MERCADORIAS E VALORES',
  },
  {
    id: '531',
    description: 'ENGENHEIRO DE MINAS, FLORESTAL, AGRÔNOMO E NUCLEAR',
  },
  {
    id: '532',
    description: 'ENGENHEIRO, EXCETO MINAS, FLORESTAL, AGRÔNOMO E NUCLEAR',
  },
  {
    id: '533',
    description: 'ESCRIVÃO, EXCETO POLICIAL CIVIL',
  },
  {
    id: '534',
    description: 'ESCULTOR',
  },
  {
    id: '535',
    description: 'ESTILISTA',
  },
  {
    id: '536',
    description: 'ESTOQUISTA',
  },
  {
    id: '537',
    description: 'MAESTRO',
  },
  {
    id: '538',
    description: 'MASSAGISTA OU MASSOTERAPEUTA',
  },
  {
    id: '539',
    description: 'MESTRE DE OBRAS E CONTRAMESTRE',
  },
  {
    id: '540',
    description: 'MOTORISTA DE ÔNIBUS URBANOS, METROPOLITANOS E RODOVIÁRIOS',
  },
  {
    id: '541',
    description: 'MOTORISTA DE TRANSPORTE ESCOLAR',
  },
  {
    id: '542',
    description: 'OPERADOR DE EQUIPAMENTOS DE RAIOX, RESSONÂNCIA E TOMOGRAFIA',
  },
  {
    id: '543',
    description: 'PINTOR COM EQUIPAMENTO DE SEGURANÇA',
  },
  {
    id: '544',
    description: 'PINTOR SEM EQUIPAMENTO DE SEGURANÇA',
  },
  {
    id: '545',
    description: 'POLICIAL CIVIL, EXCETO AGENTE PENITENCIÁRIO ',
  },
  {
    id: '546',
    description: 'POLICIAL LEGISLATIVO E DO SENADO FEDERAL ',
  },
  {
    id: '547',
    description:
      'POLICIAL MILITAR: COMANDANTE,  CORONEL, TENENTE, MAJOR, CAPITÃO, SUBTENENTE',
  },
  {
    id: '548',
    description: 'PROFISSIONAL DE SAÚDE NAS FORÇAS ARMADAS ',
  },
  {
    id: '549',
    description: 'SARGENTO, SOLDADO, CABO E AGENTE DA POLÍCIA MILITAR',
  },
  {
    id: '550',
    description: 'SARGENTO, SOLDADO, CABO E AGENTE DOS BOMBEIROS',
  },
  {
    id: '551',
    description: 'TATUADOR',
  },
  {
    id: '552',
    description: 'TAXISTA E SIMILARES',
  },
  {
    id: '553',
    description: 'TECNÓLOGO EM SECRETARIADO ',
  },
  {
    id: '554',
    description: 'TREINADOR EDUCAÇÃO FÍSICA (PERSONAL TRAINER)',
  },
  {
    id: '555',
    description: 'TRIPULANTE DE EMBARCAÇÃO',
  },
  {
    id: '1177',
    description: 'DENTISTA',
  },
]
