import { themes } from '@/styles';
import styled from 'styled-components';
import media from 'styled-media-query';

interface Props {
  border?: string;
}

export const Container = styled.div<Props>`
  display: flex;
  width:100%;
  height: 100%;
  border-radius: ${(props) => props.border ? props.border : "8px"};
  margin-top: 24px;
  margin-bottom: 16px;
  ${media.lessThan('medium')`
  margin-bottom: 12px;
    
  `}
`;

export const Icon = styled.div`
${media.lessThan('medium')`
    display: none ;
  `}
  color: ${themes.text.higher};
  margin-right: 20px;
  display: flex;

  svg {
    width: 20px;
    color: #94621F;
  }
`

export const Header = styled.div`
    display: flex;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height, or 140% */

    letter-spacing: 0.02em;

    color: #2F2663;
    margin-bottom: 26px;

    ${media.lessThan('medium')`
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */

    letter-spacing: 0.025em;   
  `}
`

export const Wrapper = styled.div`
   display: flex ;
   align-items: center;
   margin-bottom: 16px;
   
`

export const Content = styled.div`
    display: flex;
    padding: 20px 28px 24px;
    ${media.lessThan('medium')`
    padding: 11px 6px 32px;
   
  `}

`

export const Counter = styled.div`
  display: flex;
  min-width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;

  background: #EFF4FF;
  border-radius: 8px;

  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;

  letter-spacing: 0.02em;

  color: ${themes.text.higher};

`

export const Text = styled.p`
    display: flex ;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-left: 16px ;

    letter-spacing: 0.025em;


    
    color: ${themes.text.high};

   
    
    ${media.lessThan('medium')`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;


    letter-spacing: 0.03em;
  `}
`